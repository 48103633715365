/* eslint-disable */
import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Box,
  Button,
  MenuList,
  Dialog,
  Stack,
  DialogContent,
  Typography
} from '@material-ui/core';
import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@material-ui/styles';
import { useEffect, useState, useRef } from 'react';
import theme from 'theme';
import { useSelector, useDispatch } from 'react-redux';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  currentGroup,
  selectMonitoringGroup,
  fetchGroupMonitor,
  fetchGroupMonitor2
} from 'feactures/monitoring/monitoringSlice';
import {
  changePageCount,
  groupListLength,
  groupPageCount,
  groups,
  groupStatus,
  fetchGroups,
  fetchGroupAsinDataList
} from 'feactures/monitoring/groupSlice';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
  customSelect: {
    color: 'black',
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      backgroundColor: '#EFEFEF'
    }
  },
  formControl: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.grey[400],
        borderWidth: 1
      }
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[400]
    }
  }
});

const GroupSelectPulldown = ({ pathName }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const groupList = useSelector(groups);
  const groupLength = useSelector(groupListLength);
  const group = useSelector(currentGroup);
  const pageCount = useSelector(groupPageCount);
  const isLoading = useSelector(groupStatus);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [open, setOpen] = useState(false);

  const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef(null);

  useEffect(() => {
    dispatch(fetchGroups());
    if (group === '' || pathName === 'monitoring') {
      setSelectedGroup('');
    } else {
      setSelectedGroup(group.id);
    }
  }, [group]);

  const handleChange = (e) => {
    if (e.target.value === '') {
      navigate('/app/monitoring');
    } else {
      dispatch(fetchGroupMonitor());
      dispatch(fetchGroupMonitor2());
      dispatch(fetchGroupAsinDataList({ searchword: '' }));
      // 全体のKPIサマリでプルダウンから選択した際は、グループ別KPIサマリに遷移する
      if (pathName !== 'monitoringGroup') {
        navigate('/app/monitoring/group');
      }
    }

    handleClose();
  };

  const handleClick = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      // console.log('rect', rect);
      setButtonPosition({ top: rect.bottom, left: rect.left });
      handleOpen();
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <FormControl className={classes.formControl}>
        <Stack sx={{ position: 'relative' }}>
          <LoadingButton
            ref={buttonRef}
            loading={isLoading}
            variant="outlined"
            size="small"
            sx={{
              backgroundColor: '#EFEFEF',
              borderColor: '#BDBDBD',
              width: '140px',
              height: '22px'
            }}
            color="inherit"
            onClick={handleClick}
          >
            <Typography
              sx={{
                fontSize: '14px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
              }}
            >
              {selectedGroup == '' ? '全商品' : group.name}
            </Typography>
          </LoadingButton>
          <Dialog
            open={open}
            onClose={handleClose}
            sx={{
              '& .MuiPaper-root': {
                transform: 'none', // ダイアログが中央に表示されるスタイルを解除,
                position: 'fixed',
                top: `${buttonPosition.top}px`,
                left: `${buttonPosition.left - 30}px`,
                margin: 0
              },
              '& .MuiBackdrop-root': {
                bgcolor: 'rgba(0,0,0,0)'
              }
            }}
          >
            <DialogContent
              sx={{
                width: 'fit-content',
                maxHeight: '50vh',
                p: 1
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Button
                  value={''}
                  disabled={isLoading || groupList.length === 0}
                  color="inherit"
                  fontSize="14px"
                  onClick={(e) => {
                    dispatch(
                      selectMonitoringGroup({
                        id: '',
                        name: ''
                      })
                    );
                    handleChange(e);
                  }}
                >
                  全商品
                </Button>
                {/* <MenuItem
                value={'all'}
                onClick={() => {
                  dispatch(
                    selectMonitoringGroup({
                      id: 'all',
                      name: 'all-group'
                    })
                  );
                  // setSelectedGroup('all');
                }}
              >
                全グループ
              </MenuItem> */}
                {groupList.map((item, index) => (
                  <Button
                    key={`${item.code} + ${index}`}
                    value={item.id}
                    disabled={isLoading || groupList.length === 0}
                    color="inherit"
                    fontSize="16px"
                    onClick={(e) => {
                      dispatch(
                        selectMonitoringGroup({
                          id: item.id,
                          name: item.name
                        })
                      );
                      handleChange(e);
                    }}
                  >
                    {item.name}
                  </Button>
                ))}
              </Box>
              {open && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Typography>
                    {(pageCount - 1) * 50 + 1} ~{' '}
                    {(pageCount - 1) * 50 + 50 > groupLength
                      ? groupLength
                      : (pageCount - 1) * 50 + 50}{' '}
                    / {groupLength}
                    件の結果
                  </Typography>
                  <Button
                    color="inherit"
                    variant="contained"
                    disabled={pageCount <= 1}
                    onClick={() => {
                      dispatch(changePageCount(-1));
                      dispatch(fetchGroups());
                    }}
                    sx={{
                      height: '20px',
                      borderRadius: 160,
                      minWidth: '30px',
                      p: 0,
                      ml: 1
                    }}
                  >
                    <ArrowBackIosNewIcon
                      sx={{ height: '13px', width: '13px' }}
                    />
                  </Button>
                  <Button
                    color="inherit"
                    variant="contained"
                    disabled={pageCount >= groupLength / 50}
                    onClick={() => {
                      dispatch(changePageCount(1));
                      dispatch(fetchGroups());
                    }}
                    sx={{
                      height: '20px',
                      borderRadius: 160,
                      minWidth: '30px',
                      p: 0,
                      ml: 1
                    }}
                  >
                    <ArrowForwardIosIcon
                      sx={{ height: '13px', width: '13px' }}
                    />
                  </Button>
                </Box>
              )}
            </DialogContent>
          </Dialog>
        </Stack>
      </FormControl>
    </>
  );
};

export default GroupSelectPulldown;
