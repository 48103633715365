/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const fetchAsyncTrackingWords = createAsyncThunk(
  'tracking/get',
  async (arg, thunkAPI) => {
    const { graphTitle } = arg;
    const { term, start, end } = await thunkAPI.getState().date;
    const { channel } = thunkAPI.getState().currentchannel;
    const { accountId, accountName, userId, isLoggedIn, userName } =
      thunkAPI.getState().user;
    const { checkGroupList } = thunkAPI.getState().group;
    const res = await client.post(`${apiUrl}tracking`, {
      start: start,
      end: end,
      term: term,
      channel: channel,
      graph_name: graphTitle,
      account_id: accountId,
      group_list: checkGroupList
    });
    return res.data;
  }
);

export const registerTrackingWords = createAsyncThunk(
  'tracking/register',
  async (arg, thunkAPI) => {
    const { asin_code, keyword, graphTitle } = arg;
    const { alignment, startDate, endDate } = thunkAPI.getState().asinDate;
    const { channel } = thunkAPI.getState().currentchannel;
    const { accountId, accountName, userId, isLoggedIn, userName } =
      thunkAPI.getState().user;
    const res = await client.post(`${apiUrl}tracking/register`, {
      start: startDate,
      end: endDate,
      term: alignment,
      channel: channel,
      asin_code: asin_code,
      seo_type: graphTitle,
      keyword: keyword,
      account_id: accountId
    });
    return res.data;
  }
);

const fetchTrackingSlice = createSlice({
  name: 'myproducts',
  initialState: {
    all: {
      num: '',
      rate: '',
      graph: []
    },
    AmazonTrackingData: [
      // {
      //   asin: '',
      //   describe: '',
      //   keywordnum: '',
      //   rank_change: { stay: '', up: '', down: '' },
      //   keywords: [
      //     {
      //       keyword: '',
      //       rank: '',
      //       page: ''
      //     }
      //   ]
      // },
      // {
      //   asin: '',
      //   describe: '',
      //   keywordnum: '',
      //   rank_change: { stay: '', up: '', down: '' },
      //   keywords: [
      //     {
      //       keyword: '',
      //       rank: '',
      //       page: ''
      //     }
      //   ]
      // }
    ],
    RakutenTrackingData: [],
    YahooTrackingData: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAsyncTrackingWords.fulfilled, (state, action) => {
      state.all.num = action.payload.all.num;
      state.all.rate = action.payload.all.rate;
      state.all.graph = action.payload.all.graph;
      state.AmazonTrackingData = action.payload.AmazonTrackingData;
      state.isLoading = false;
    });
    builder.addCase(fetchAsyncTrackingWords.pending, (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    });
    builder.addCase(fetchAsyncTrackingWords.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    });
    builder.addCase(registerTrackingWords.fulfilled, (state, action) => {
      return {
        ...state,
        registerloading: false
      };
    });
    builder.addCase(registerTrackingWords.pending, (state, action) => {
      return {
        ...state,
        registerloading: true
      };
    });
    builder.addCase(registerTrackingWords.rejected, (state, action) => {
      return {
        ...state,
        registerloading: false
      };
    });
  }
});

export const amazonTrackingData = (state) => state.tracking.AmazonTrackingData;
export const trackingAllData = (state) => state.tracking.all;

export const trackingStatus = (state) => state.tracking.isLoading;
export const registerStatus = (state) => state.tracking.registerloading;

export default fetchTrackingSlice.reducer;
