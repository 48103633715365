/* eslint-disable*/
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  IconButton,
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  useTheme,
  FormGroup
} from '@material-ui/core';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  fetchRegister,
  fetchUser,
  registerLogin
} from 'feactures/user/userSlice';
import { errorResMessage, errorResStatus } from 'feactures/errorSlice';
import { makeStyles } from '@material-ui/styles';
import TosDialog from 'components/atoms/modal/TosDialog';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: 4,
    right: 4
  }
}));

const Register = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const errorMessage = useSelector(errorResMessage);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [readFlag, setReadFlag] = useState(false);

  // パスワードの表示・非表示処理
  const [showPassword, setShowPassword] = useState(false);
  const [showComfirmPassword, setShowComfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowComfirmPassword = () =>
    setShowComfirmPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownComfirmPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (values) => {
    setError(null);
    await dispatch(fetchRegister(values))
      .then(unwrapResult) //unwrapResult で,dispatch結果がfulfilledかrejectedかで条件分岐できる
      .then(() => {
        navigate('/register/complete', { replace: false });
        dispatch(
          registerLogin({
            userId: values.userId
          })
        );
        setTimeout(function () {
          navigate('/app/monitoring');
        }, 3000);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const handleClick = () => {
    setModalOpen(true);
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const passwordExp =
    /^(?=.*?[a-z])(?=.*?\d)(?=.*?[!-\/:-@[-`{-~])[!-~]{8,100}$/i;

  const RequiredBox = () => {
    return (
      <Box
        color="#FFFFFF"
        bgcolor="#F26B6B"
        borderRadius={5}
        fontSize="12px"
        fontWeight="bold"
        px="8px"
        mb="1px"
        ml="8px"
      >
        必須
      </Box>
    );
  };
  const OptionBox = () => {
    return (
      <Box
        color="#ffffff"
        bgcolor="#669AF5"
        borderRadius={5}
        fontSize="12px"
        fontWeight="bold"
        px="8px"
        mb="1px"
        ml="8px"
      >
        任意
      </Box>
    );
  };

  return (
    <>
      <TosDialog
        open={modalOpen}
        setOpen={setModalOpen}
        setFlag={setReadFlag}
      />
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="md">
          <Formik
            initialValues={{
              lastName: '',
              firstName: '',
              userId: '',
              confirmPassword: '',
              company: '',
              tel: '',
              mobileNumber: '',
              url: '',
              accountName: '', //ショップ名
              amazonSellerType: '',
              itemUrl: '',
              ecMall: [],
              ecMallOther: '',
              otherTools: '',
              background: [],
              backgroundOther: '',
              source: [],
              sourceOther: '',
              policy: false,
              privacyPolicy: false,
              importantSubjects: false
            }}
            validationSchema={Yup.object().shape({
              company: Yup.string().max(255).required('会社名が未入力です。'),
              url: Yup.string().max(255).required('企業URLが未入力です。'),
              accountName: Yup.string()
                .max(255)
                .required('ショップ名が未入力です。'),
              lastName: Yup.string()
                .max(16, '16文字以内で入力してください。')
                .required('姓が未入力です。'),
              firstName: Yup.string()
                .max(16, '16文字以内で入力してください。')
                .required('名が未入力です。'),
              userId: Yup.string()
                .required('メールアドレスが未入力です。')
                .email('メールアドレスの形式が正しくありません。')
                .max(255),
              tel: Yup.string().matches(
                phoneRegExp,
                '電話番号の形式が正しくありません。'
              ),
              mobileNumber: Yup.string()
                .matches(phoneRegExp, '電話番号の形式が正しくありません。')
                .required('携帯電話番号が未入力です。'),
              itemUrl: Yup.string()
                .max(255, '255文字以内で入力してください。')
                .required('商品URLが未入力です。'),
              otherTools: Yup.string()
                .max(255)
                .required(
                  'Oxcim以外でECモール分析に使用しているツールが未入力です。'
                ),
              background: Yup.array()
                .min(1, 'お申込み背景を選択してください')
                .of(Yup.string().required()),
              source: Yup.array()
                .min(1, '選択してください')
                .of(Yup.string().required()),
              ecMall: Yup.array()
                .min(1, '選択してください')
                .of(Yup.string().required()),
              // amazonPlan: Yup.string().required('選択してください。'),
              // rakutenPlan: Yup.string().required('選択してください。'),
              policy: Yup.boolean().oneOf([true], '利用規約の同意が必要です。'),
              privacyPolicy: Yup.boolean().oneOf(
                [true],
                'プライバシーポリシーの同意が必要です。'
              ),
              importantSubjects: Yup.boolean().oneOf(
                [true],
                '重要事項への同意が必要です。'
              )
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Box sx={{ mb: 3, mt: 3, width: 600 }}>
                    <Typography color="textPrimary" variant="h2">
                      【新規開設】
                      <br />
                      Oxcim利用申し込みフォーム
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      <br />
                      ・お申込み受領後、3営業日以内にカスタマーサポ―トからご案内いたします。
                      <br />
                      ・営業時間は平日9:30～18:00です。
                      <br />
                      ・お申込み後、審査に約3営業日（土日祝除く）かかります。
                      <br />
                      ・ご不明な点がございましたら下記までご連絡ください。
                      <br />
                      　introduction_oxcim@mg.opt.ne.jp
                    </Typography>
                  </Box>
                  <Box width="600px">
                    <InputLabel>
                      <Box display="flex">
                        名前
                        <RequiredBox />
                      </Box>
                    </InputLabel>
                    <Box display="flex" mb={1} justifyContent={'space-between'}>
                      {/* 苗字　lastName */}
                      <TextField
                        error={Boolean(touched.lastName && errors.lastName)}
                        fullWidth
                        helperText={touched.lastName && errors.lastName}
                        name="lastName"
                        placeholder="姓"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.lastName}
                        variant="outlined"
                        size="small"
                        sx={{ backgroundColor: '#fff' }}
                      />
                      {/* 名前　firstName */}
                      <TextField
                        error={Boolean(touched.firstName && errors.firstName)}
                        fullWidth
                        helperText={touched.firstName && errors.firstName}
                        placeholder="名"
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.firstName}
                        variant="outlined"
                        size="small"
                        sx={{ ml: 1, backgroundColor: '#fff' }}
                      />
                    </Box>
                    <InputLabel>
                      <Box display="flex">
                        会社メールアドレス
                        <RequiredBox />
                      </Box>
                    </InputLabel>
                    <TextField
                      sx={{ mb: 1, backgroundColor: '#fff' }}
                      error={Boolean(touched.userId && errors.userId)}
                      fullWidth
                      helperText={touched.userId && errors.userId}
                      name="userId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.userId}
                      variant="outlined"
                      size="small"
                      autoComplete="new-user-id"
                      inputProps={{ autoComplete: 'off' }}
                    />
                    <InputLabel>
                      <Box display="flex">
                        会社名
                        <RequiredBox />
                      </Box>
                    </InputLabel>
                    <TextField
                      error={Boolean(touched.company && errors.company)}
                      fullWidth
                      helperText={touched.company && errors.company}
                      name="company"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.company}
                      variant="outlined"
                      size="small"
                      sx={{ mb: 1, backgroundColor: '#fff' }}
                    />
                    <InputLabel>
                      <Box display="flex">
                        電話番号
                        <OptionBox />
                      </Box>
                      <Typography fontSize={'12px'}>
                        ※ハイフンなしで数字のみ入力してください。
                      </Typography>
                    </InputLabel>
                    <TextField
                      error={Boolean(touched.tel && errors.tel)}
                      fullWidth
                      helperText={touched.tel && errors.tel}
                      name="tel"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.tel}
                      variant="outlined"
                      size="small"
                      sx={{ mb: 1, backgroundColor: '#fff' }}
                    />
                    <InputLabel>
                      <Box display="flex">
                        携帯電話番号
                        <RequiredBox />
                      </Box>
                      <Typography fontSize={'12px'}>
                        ※ハイフンなしで数字のみ入力してください。
                      </Typography>
                    </InputLabel>
                    <TextField
                      error={Boolean(
                        touched.mobileNumber && errors.mobileNumber
                      )}
                      fullWidth
                      helperText={touched.mobileNumber && errors.mobileNumber}
                      name="mobileNumber"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.mobileNumber}
                      variant="outlined"
                      size="small"
                      sx={{ mb: 1, backgroundColor: '#fff' }}
                    />
                    <InputLabel>
                      <Box display="flex">
                        企業URL
                        <RequiredBox />
                      </Box>
                    </InputLabel>
                    <TextField
                      error={Boolean(touched.url && errors.url)}
                      fullWidth
                      helperText={touched.url && errors.url}
                      name="url"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.url}
                      variant="outlined"
                      size="small"
                      sx={{ mb: 1, backgroundColor: '#fff' }}
                    />
                    <InputLabel>
                      <Box display="flex">
                        ショップ名
                        <RequiredBox />
                      </Box>
                    </InputLabel>
                    <Box display="flex" mb={1} justifyContent={'space-between'}>
                      <TextField
                        error={Boolean(
                          touched.accountName && errors.accountName
                        )}
                        helperText={touched.accountName && errors.accountName}
                        fullWidth
                        name="accountName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.accountName}
                        variant="outlined"
                        size="small"
                        sx={{ mb: 1, backgroundColor: '#fff' }}
                      />
                    </Box>
                    <InputLabel>
                      <Box display="flex">
                        商品URL
                        <RequiredBox />
                      </Box>
                    </InputLabel>
                    <TextField
                      error={Boolean(touched.itemUrl && errors.itemUrl)}
                      fullWidth
                      helperText={touched.itemUrl && errors.itemUrl}
                      sx={{ mb: 1, backgroundColor: '#fff' }}
                      name="itemUrl"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.itemUrl}
                      variant="outlined"
                      size="small"
                    />
                    <InputLabel>
                      <Box display="flex" alignItems="center">
                        出店ECモール <RequiredBox />
                        {touched.ecMall && errors.ecMall && (
                          <FormHelperText sx={{ m: 0, ml: 1 }} error>
                            {errors.ecMall}
                          </FormHelperText>
                        )}
                      </Box>
                    </InputLabel>
                    <FormControl
                      error={Boolean(touched.ecMall && errors.ecMall)}
                      sx={{ mb: 1 }}
                    >
                      <Box display="flex">
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="1"
                              onChange={handleChange}
                              name="ecMall"
                            />
                          }
                          label="Amazon"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="2"
                              onChange={handleChange}
                              name="ecMall"
                            />
                          }
                          label="楽天"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="3"
                              onChange={handleChange}
                              name="ecMall"
                            />
                          }
                          label="Yahoo"
                        />
                      </Box>
                      <Box display="flex" alignItems="start">
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="4"
                              onChange={handleChange}
                              name="ecMall"
                            />
                          }
                          label="その他"
                        />
                        {values.ecMall.includes('4') && (
                          <TextField
                            sx={{ backgroundColor: '#fff', width: '470px' }}
                            placeholder="その他の場合はこちらにご記入ください。"
                            name="ecMallOther"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.ecMallOther}
                            variant="outlined"
                            size="small"
                          />
                        )}
                      </Box>
                    </FormControl>
                    <InputLabel>
                      <Box display="flex">
                        Oxcim以外でECモール分析に使用しているツール
                        <RequiredBox />
                      </Box>
                    </InputLabel>
                    <Typography fontSize={'12px'}>
                      ※無い場合「なし」とご入力ください。
                    </Typography>
                    <TextField
                      error={Boolean(touched.otherTools && errors.otherTools)}
                      fullWidth
                      helperText={touched.otherTools && errors.otherTools}
                      sx={{ mb: 1, backgroundColor: '#fff' }}
                      name="otherTools"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.otherTools}
                      variant="outlined"
                      size="small"
                    />
                    <Box mb={3}>
                      <InputLabel>
                        <Box display="flex" alignItems="center">
                          お申込み背景
                          <RequiredBox />
                          {touched.background && errors.background && (
                            <FormHelperText sx={{ m: 0, ml: 1 }} error>
                              {errors.background}
                            </FormHelperText>
                          )}
                        </Box>
                      </InputLabel>
                      <FormControl component="fieldset">
                        <FormGroup sx={{ width: '100%' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="background"
                                value="1"
                                onChange={handleChange}
                              />
                            }
                            label="ECモールの市場調査・競合分析がしたい"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="background"
                                value="2"
                                onChange={handleChange}
                              />
                            }
                            label="複数モールの売り上げデータを一元管理したい"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="background"
                                value="3"
                                onChange={handleChange}
                              />
                            }
                            label="運用をアウトソースしたい"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="background"
                                value="4"
                                onChange={handleChange}
                              />
                            }
                            label="売り上げ拡大のコンサルティングをしてほしい"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="background"
                                value="5"
                                onChange={handleChange}
                              />
                            }
                            label="その他"
                          />
                        </FormGroup>
                      </FormControl>
                      {values.background.includes('5') && (
                        <TextField
                          name="backgroundOther"
                          placeholder="その他の内容を記入してください"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.backgroundOther}
                          variant="outlined"
                          size="small"
                          fullWidth
                          sx={{ backgroundColor: '#fff', mt: 1 }}
                        />
                      )}
                      <InputLabel sx={{ mt: 1 }}>
                        <Box display="flex" alignItems="center">
                          Oxcimを何で知りましたか？
                          <RequiredBox />
                          {touched.source && errors.source && (
                            <FormHelperText sx={{ m: 0, ml: 1 }} error>
                              {errors.source}
                            </FormHelperText>
                          )}
                        </Box>
                      </InputLabel>
                      <FormControl component="fieldset">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="source"
                                value="1"
                                onChange={handleChange}
                              />
                            }
                            label="Web検索"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="source"
                                value="2"
                                onChange={handleChange}
                              />
                            }
                            label="SNS"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="source"
                                value="3"
                                onChange={handleChange}
                              />
                            }
                            label="Webニュース、プレスリリース"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="source"
                                value="4"
                                onChange={handleChange}
                              />
                            }
                            label="外部メディア（記事掲載等）"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="source"
                                value="5"
                                onChange={handleChange}
                              />
                            }
                            label="知人からの紹介"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="source"
                                value="6"
                                onChange={handleChange}
                              />
                            }
                            label="その他"
                          />
                        </FormGroup>
                      </FormControl>
                      {values.source.includes('6') && (
                        <TextField
                          name="sourceOther"
                          placeholder="その他の内容を記入してください"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.sourceOther}
                          variant="outlined"
                          size="small"
                          fullWidth
                          sx={{ backgroundColor: '#fff', mt: 1 }}
                        />
                      )}
                    </Box>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        ml: -1
                      }}
                    >
                      <Checkbox
                        disabled={!readFlag}
                        checked={values.policy}
                        name="policy"
                        onChange={handleChange}
                        sx={{ py: 1 }}
                      />
                      <Typography color="textSecondary" variant="body1">
                        <Button
                          onClick={handleClick}
                          sx={{
                            position: 'relative',
                            p: 0,
                            m: 0,
                            minWidth: '48px',
                            maxHeight: '18px',
                            textTransform: 'none', // textTransform: 'none' to keep the text case as is
                            backgroundColor: 'transparent',
                            color: '#1574D1', // Use a lighter color initially
                            '&:hover': {
                              color: '#1A68C7', // Darker color on hover
                              backgroundColor: 'transparent',
                              '&::before': {
                                borderColor: '#1A68C7' // underline color on hover
                              }
                            },
                            '&::before': {
                              content: '""',
                              position: 'absolute',
                              left: 0,
                              bottom: 2,
                              width: '100%',
                              height: '1px', // Adjust the height of the underline
                              backgroundColor: '#9DC5ED', // underline color
                              borderBottom: '1px solid #9DC5ED',
                              pointerEvents: 'none' // Ensure the underline does not interfere with pointer events
                            }
                          }}
                        >
                          利用規約
                        </Button>
                        に同意します。
                      </Typography>
                      {!readFlag && (
                        <FormHelperText error>
                          利用規約を開いてご確認ください。
                        </FormHelperText>
                      )}
                      {Boolean(touched.policy && errors.policy) && (
                        <FormHelperText error>{errors.policy}</FormHelperText>
                      )}
                    </Box>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        ml: -1
                      }}
                    >
                      <Checkbox
                        checked={values.privacyPolicy}
                        name="privacyPolicy"
                        onChange={handleChange}
                        sx={{ py: 0 }}
                      />
                      <Typography color="textSecondary" variant="body1">
                        <Link
                          color="primary"
                          target="_blank"
                          href="https://www.opt.ne.jp/privacy/"
                          underline="always"
                          variant="h6"
                        >
                          プライバシーポリシー
                        </Link>
                        に同意します。
                      </Typography>
                      {Boolean(
                        touched.privacyPolicy && errors.privacyPolicy
                      ) && (
                        <FormHelperText error>
                          {errors.privacyPolicy}
                        </FormHelperText>
                      )}
                    </Box>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        ml: -1
                      }}
                    >
                      <Checkbox
                        checked={values.importantSubjects}
                        name="importantSubjects"
                        onChange={handleChange}
                        sx={{ py: 1 }}
                      />
                      <Typography color="textSecondary" variant="body1">
                        下記の重要事項に同意します。
                      </Typography>
                      {Boolean(
                        touched.importantSubjects && errors.importantSubjects
                      ) && (
                        <FormHelperText error>
                          {errors.importantSubjects}
                        </FormHelperText>
                      )}
                    </Box>
                    <Box
                      sx={{ border: 1, p: 1, backgroundColor: '#fff' }}
                      overflow="scroll"
                      height="calc(46vh - 100px)"
                      width="100%"
                    >
                      <Typography variant="body2" gutterBottom>
                        ・利用者は、当社が付与したアカウントのID及び自らが設定したパスワードを第三者に開示、貸与又は共有しないとともに、第三者に漏洩することのないよう厳重に管理するものとします。
                        <br />
                        ・Oxcimを利用する上で必要な貴社ECモールのアカウントデータを収集いたします。
                        <br />
                        ・販売データについては、本サービスの提供に必要な範囲において利用するほか、OPTグループ内でも利用する可能性があります。いずれの場合も利用者が特定又は推測できないデータとして加工を施した上で利用いたします。
                        <br />
                        ・一部のデータについては、弊社独自集計により実際の数値と乖離がある可能性がございます。
                        <br />
                        ・事前に通知することなく、サービスの変更/追加/中断の可能性がございます。
                        <br />
                        ・ご利用企業様として会社ロゴを使用させていただく可能性がございます。
                        <br />
                      </Typography>
                    </Box>
                    {/* <Typography variant="body1">
                  ※登録ボタンを押したら以上の内容に同意したと見なします。
                </Typography> */}
                    <Box
                      border={2}
                      borderColor="red"
                      mt={2}
                      p={1}
                      borderRadius={3}
                    >
                      <Typography fontWeight="bold">
                        お申込み完了後、「【Oxcim】アカウント申込み完了のお知らせ」という件名の
                        メールが送付されます。
                        <br />
                        ※メールが届いていない場合は、一度迷惑メールフォルダをご確認頂きそれでも届いていない場合は、
                        <br />
                        　お手数ですが再度登録をお願いします。
                      </Typography>
                    </Box>
                    <Box sx={{ py: 2 }} onSubmit={handleSubmit}>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        申し込む
                      </Button>
                      {error && (
                        <Alert severity="error">
                          登録済みのアカウントがあります
                        </Alert>
                      )}
                    </Box>
                    {/* <Typography variant="body1" sx={{ color: '#FC1F1E' }}>
                  Amazonセラーは一時的にデータ接続ができない状況となっております。
                  <br />
                  お申し込みをいただいた方は問題解消後に通知させて頂きます。
                  </Typography> */}
                    {/* <Typography color="textSecondary" variant="body1">
                  すでにアカウントをお持ちの方は{' '}
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                    underline="hover"
                  >
                    こちら
                  </Link>
                </Typography> */}
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Register;
