/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const checkSurvey = createAsyncThunk(
  'survey_login/post',
  async (arg, thunkAPI) => {
    const { userId, accountId } = thunkAPI.getState().user;
    const { channel } = thunkAPI.getState().currentchannel;

    const res = await client.post(`${apiUrl}check_login_info`, {
      user_id: userId
    });
    return res.data;
  }
);
export const sendSurvey = createAsyncThunk(
  'survey_send/post',
  async (arg, thunkAPI) => {
    const { userId, accountId } = thunkAPI.getState().user;
    const { channel } = thunkAPI.getState().currentchannel;
    let reason = '';
    reason = arg.reason.replace(/`/g, '');
    reason = reason.replace(/'/g, '');
    reason = reason.replace(/"/g, '');

    // if (arg.reason.includes('`')) {
    //   reason = arg.reason.replace(/`/g, "");
    // } else {
    //   reason = arg.reason;
    // }

    console.log(reason);

    const res = await client.post(`${apiUrl}save_survey_result`, {
      user_id: userId,
      loyality: arg.value,
      reason
    });
    return res.data;
  }
);

const survey = createSlice({
  name: 'survey',
  initialState: {
    displayFlag: true,
    onceOpened: false,
    loading: false,
    error: null
  },
  reducers: {
    setDisplayFlag(state, action) {
      return {
        ...state,
        displayFlag: action.payload
      };
    },
    setOnceOpenedFlag(state, action) {
      return {
        ...state,
        onceOpened: true
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(checkSurvey.fulfilled, (state, action) => {
      return {
        displayFlag: action.payload.result,
        onceOpened: action.payload.result,
        loading: false,
        error: false
      };
    });
    builder.addCase(checkSurvey.pending, (state, action) => {
      return { ...state, loading: true };
    });
    builder.addCase(checkSurvey.rejected, (state, action) => {
      return { ...state, loading: false, error: true };
    });
    builder.addCase(sendSurvey.fulfilled, (state, action) => {
      if (action.payload.message == 'error') {
        return { ...state, loading: false, error: true };
      } else {
        return { ...state, loading: false, error: false };
      }
    });
    builder.addCase(sendSurvey.pending, (state, action) => {
      return { ...state, loading: true, error: null };
    });
    builder.addCase(sendSurvey.rejected, (state, action) => {
      return { ...state, loading: false, error: true };
    });
  }
});

export const { setDisplayFlag, setOnceOpenedFlag } = survey.actions;
export const displayFlag = (state) => state.survey.displayFlag;
export const onceOpened = (state) => state.survey.onceOpened;
export const loading = (state) => state.survey.loading;
export const error = (state) => state.survey.error;
export default survey.reducer;
