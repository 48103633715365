/* eslint-disable*/
import {
  Link as RouterLink,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { useEffect, useState, memo, useRef } from 'react';
import { Box, Typography, Link, Button } from '@material-ui/core'; // eslint-disable-line
import { useSelector } from 'react-redux';
import { amazonStatus } from 'feactures/plan/premiumRegister';

const MeasuresTrialRegisterComplete = memo(() => {
  const [searchParams] = useSearchParams();
  const channel = searchParams.get('ch');
  const seller_type = searchParams.get('st');
  const amazonDataStatus = useSelector(amazonStatus);

  let info = '';
  if (channel == 'amazon' && amazonDataStatus) {
    info = 'トライアル開始の準備が整い次第、弊社からご連絡します。';
  } else if (channel == 'amazon' && !amazonDataStatus) {
    info = '引き続きデータ接続の対応をお願いします。';
  } else if (channel == 'rakuten') {
    info = 'データ接続完了時に弊社からご連絡します。';
  }

  const handleClick = () => {
    window.open(
      'https://sellercentral-japan.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.cfdea873-d3d0-427e-a6df-1b8cd26baf93'
    );
  };

  return (
    <>
      <Box xs={12} textAlign="center" backgroundColor="#F7FCFF" pt={5}>
        <Typography variant="h1" fontWeight={'bold'}>
          お申し込みありがとうございました
        </Typography>
        {/* <Typography variant="h4" mb={3}>
          {info}
        </Typography> */}
        {/* {channel == 'amazon' &&
          !amazonDataStatus &&
          seller_type == 'seller' && (
            <Box mb={3}>
              <Button onClick={handleClick} variant="contained">
                Amazon認証URL
              </Button>
              <Typography variant="h4" mt={2}>
                データ接続方法については
                <Link
                  target="_blank"
                  href="https://www.oxcim.net/amazondata/seller"
                  variant="h4"
                  underline="hover"
                >
                  こちら
                </Link>
                をご確認下さい。
              </Typography>
            </Box>
          )}
        {channel == 'amazon' &&
          !amazonDataStatus &&
          seller_type == 'vender' && (
            <Typography variant="h4" mb={3}>
              データ接続方法については
              <Link
                target="_blank"
                href="https://www.oxcim.net/amazondata#vendor"
                variant="h4"
                underline="hover"
              >
                こちら
              </Link>
              をご確認下さい。
            </Typography>
          )} */}
        <Typography variant="h6" mb={3}>
          ログイン画面は
          <Link
            component={RouterLink}
            to="/login"
            variant="h5"
            underline="hover"
          >
            こちら
          </Link>
        </Typography>
      </Box>
    </>
  );
});

export default MeasuresTrialRegisterComplete;
