/* eslint-disable */
import {
  Box,
  TextField,
  Typography,
  Card,
  IconButton
} from '@material-ui/core';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { editGroup, addAsinToEditGroup } from 'feactures/monitoring/groupSlice';
import GroupTextField from './GroupTetxField';
import { HelpOutlined } from '@material-ui/icons';
import { styled } from '@material-ui/styles';

//「？」マークにカーソルが当たったときの吹き出し
export const HtmlTooltip = styled(({ className, placement, ...props }) => (
  <Tooltip
    {...props}
    arrow
    placement={placement}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#6b778c',
    backgroundColor: '#ffffff'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    color: '#6b778c',
    fontSize: '13px',
    border: '1px solid #dadde9',
    maxWidth: 280
  }
}));

const GroupEditField = ({ channel: channel }) => {
  const dispatch = useDispatch();
  const currentEditGroup = useSelector(editGroup);
  const amazonAsinList = currentEditGroup.amazon;
  const rakutenAsinList = currentEditGroup.rakuten;
  const yahooAsinList = currentEditGroup.yahoo;

  let asinList = [];
  if (channel === 'Amazon') {
    asinList = amazonAsinList;
  } else if (channel === '楽天') {
    asinList = rakutenAsinList;
  } else if (channel === 'Yahoo') {
    asinList = yahooAsinList;
  }

  const [fieldValue, setFieldValue] = useState(asinList);

  const handleInputBlur = (value, channel) => {
    const filteredValue = value.filter((item) => item !== '');
    dispatch(addAsinToEditGroup({ channel: channel, asinList: filteredValue }));
  };

  const info = 'https://item.rakuten.co.jp/ショップコード/商品管理番号/';
  let channelMessage = '';
  if (channel == 'Amazon') {
    channelMessage = '※ASINを登録';
  } else if (channel == '楽天') {
    channelMessage = '※商品管理番号を登録';
  } else if (channel == 'Yahoo') {
    channelMessage = '※開発中';
  }

  return (
    <Box sx={{ width: '33%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          fontSize={'16px'}
          color={'#595959'}
          fontWeight={'bold'}
          sx={{ mr: 2 }}
        >
          {channel}商品
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography fontSize={'14px'}>{channelMessage}</Typography>
          {channel == '楽天' && (
            <HtmlTooltip
              placement="top"
              title={<span style={{ whiteSpace: 'pre-line' }}>{info}</span>}
            >
              <span>
                <IconButton sx={{ p: 0, ml: 1 }}>
                  <HelpOutlined style={{ fontSize: '15px' }} />
                </IconButton>
              </span>
            </HtmlTooltip>
          )}
        </Box>
      </Box>
      <Card
        sx={{
          minHeight: '200px'
        }}
      >
        <TextField
          multiline
          fullWidth
          value={fieldValue.join('\n')}
          rows={10}
          id={'asinList'}
          name={'asinList'}
          placeholder={'改行区切りで複数登録可能'}
          onBlur={() => handleInputBlur(fieldValue, channel)}
          onChange={(e) => {
            const strData = e.target.value;
            let ary = [];
            if (strData !== '') {
              ary = strData.split(/[\n]+/);
            }
            setFieldValue(ary);
          }}
          disabled={channel === 'Yahoo'}
        />
        {/* {asinList.map((item, index) => (
          <GroupTextField
            onBlur={(event) => handleInputBlur(index, event, channel)}
            index={index}
            preValue={item}
          />
        ))} */}
      </Card>
    </Box>
  );
};

export default GroupEditField;
