/* eslint-disable  */

import { useDispatch, useSelector } from 'react-redux';
import { currentchannel } from 'feactures/channel/channelSlice';
import {
  amazonCategory,
  rakutenCategory,
  yahooCategory,
  fetchAsyncCategory2,
  fetchAsyncCategory3,
  fetchAsyncCategory4,
  fetchAsyncCategory5,
  fetchAsyncCategory6,
  fetchAsyncLastCategory,
  fetchOwnLowerCategory,
  changeCurrentCategory
} from 'feactures/category/categorySlice';
import {
  currentAmazonCategoryList,
  currentRakutenCategoryList,
  currentYahooCategoryList,
  changeAcate1,
  changeAcate2,
  changeAcate3,
  changeAcate4,
  changeAcate5,
  changeAcate6,
  changeRcate1,
  changeRcate2,
  changeRcate3,
  changeRcate4,
  changeYcate1,
  changeYcate2,
  changeYcate3,
  changeYcate4,
  changeRcate5
} from 'feactures/category/categoryGraphSlice';
import { setSelectedFavoriteCategory } from 'feactures/category/favoriteCategorySlice';
import ComboBox from 'components/atoms/select/ComboBox';

const CategoryForm = ({ page }) => {
  const dispatch = useDispatch();

  //ここでデフォルトを渡すようにする
  const allAmazonCategories = useSelector(amazonCategory);
  const allRakutenCategories = useSelector(rakutenCategory);
  const allYahooCategories = useSelector(yahooCategory);

  const currentSelectAmazonCategories = useSelector(currentAmazonCategoryList);
  const currentSelectRakutenCategories = useSelector(
    currentRakutenCategoryList
  );
  const currentSelectYahooCategories = useSelector(currentYahooCategoryList);

  const channel = useSelector(currentchannel);

  let categories = [];
  let currentSelectCategoryList = [];

  if (channel == 1) {
    categories = allAmazonCategories;
    currentSelectCategoryList = currentSelectAmazonCategories;
  } else if (channel == 2) {
    categories = allRakutenCategories;
    currentSelectCategoryList = currentSelectRakutenCategories;
  } else if (channel == 3) {
    categories = allYahooCategories;
    currentSelectCategoryList = currentSelectYahooCategories;
  }

  const onCategory1ComboBoxChangeHandler = (selected) => {
    if (page === 'brandComparison') {
      dispatch(
        // １つ下の階層をlevelに指定する
        fetchOwnLowerCategory({ selected_category: selected.value, level: 2 })
      );
    } else {
      dispatch(fetchAsyncCategory2({ category1Id: selected.value, channel }));
    }
    // 現在の階層をlevelに指定する
    dispatch(changeCurrentCategory({ id: selected.value, level: 1 }));

    if (channel === 1) {
      dispatch(changeAcate1(selected)); //selected:{label:'カテゴリ名', value:'categoryId'}
    } else if (channel === 2) {
      dispatch(changeRcate1(selected));
    } else if (channel === 3) {
      dispatch(changeYcate1(selected));
    }
    // お気に入りカテゴリが選択されていたらそれも空にする
    dispatch(setSelectedFavoriteCategory({ cate: '', channel }));
  };
  const onCategory2ComboBoxChangeHandler = (selected) => {
    if (page === 'brandComparison') {
      dispatch(
        fetchOwnLowerCategory({ selected_category: selected.value, level: 3 })
      );
    } else {
      dispatch(fetchAsyncCategory3({ category2Id: selected.value, channel }));
    }

    // 現在の階層をlevelに指定する
    dispatch(changeCurrentCategory({ id: selected.value, level: 2 }));

    if (channel === 1) {
      dispatch(changeAcate2(selected));
    } else if (channel === 2) {
      dispatch(changeRcate2(selected));
    } else if (channel === 3) {
      dispatch(changeYcate2(selected));
    }
    // お気に入りカテゴリが選択されていたらそれも空にする
    dispatch(setSelectedFavoriteCategory({ cate: '', channel }));
  };
  const onCategory3ComboBoxChangeHandler = (selected) => {
    if (page === 'brandComparison') {
      dispatch(
        fetchOwnLowerCategory({ selected_category: selected.value, level: 4 })
      );
    } else {
      dispatch(fetchAsyncCategory4({ category3Id: selected.value, channel }));
    }
    // 現在の階層をlevelに指定する
    dispatch(changeCurrentCategory({ id: selected.value, level: 3 }));
    if (channel === 1) {
      dispatch(changeAcate3(selected));
    } else if (channel === 2) {
      dispatch(changeRcate3(selected));
    } else if (channel === 3) {
      dispatch(changeYcate3(selected));
    }
    // お気に入りカテゴリが選択されていたらそれも空にする
    dispatch(setSelectedFavoriteCategory({ cate: '', channel }));
  };
  const onCategory4ComboBoxChangeHandler = (selected) => {
    if (page === 'brandComparison') {
      dispatch(
        fetchOwnLowerCategory({ selected_category: selected.value, level: 5 })
      );
    } else {
      dispatch(fetchAsyncCategory5({ category4Id: selected.value, channel }));
    }

    // 現在の階層をlevelに指定する
    dispatch(changeCurrentCategory({ id: selected.value, level: 4 }));
    if (channel === 1) {
      dispatch(changeAcate4(selected));
    } else if (channel === 2) {
      dispatch(changeRcate4(selected));
    } else if (channel === 3) {
      dispatch(changeYcate4(selected));
    }
    // お気に入りカテゴリが選択されていたらそれも空にする
    dispatch(setSelectedFavoriteCategory({ cate: '', channel }));
  };
  const onCategory5ComboBoxChangeHandler = (selected) => {
    if (page === 'brandComparison') {
      dispatch(
        fetchOwnLowerCategory({ selected_category: selected.value, level: 6 })
      );
    } else {
      dispatch(fetchAsyncCategory6({ category5Id: selected.value, channel }));
    }
    // 現在の階層をlevelに指定する
    dispatch(changeCurrentCategory({ id: selected.value, level: 5 }));
    if (channel === 1) {
      dispatch(changeAcate5(selected));
    } else if (channel === 2) {
      dispatch(changeRcate5(selected));
    }
    // お気に入りカテゴリが選択されていたらそれも空にする
    dispatch(setSelectedFavoriteCategory({ cate: '', channel }));
  };
  const onCategory6ComboBoxChangeHandler = (selected) => {
    // if (page === 'brandComparison') {
    //   dispatch(
    //     fetchOwnLowerCategory({ selected_category: selected.value, level: 7 })
    //   );
    // } else {
    //   dispatch(
    //     fetchAsyncLastCategory({ category6Id: selected.value, channel })
    //   );
    // }
    // 現在の階層をlevelに指定する
    dispatch(changeCurrentCategory({ id: selected.value, level: 6 }));
    if (channel === 1) {
      dispatch(changeAcate6(selected));
    }
    // お気に入りカテゴリが選択されていたらそれも空にする
    dispatch(setSelectedFavoriteCategory({ cate: '', channel }));
  };

  /* eslint-disable */
  return (
    <>
      <ComboBox
        inputLabel="カテゴリ1"
        items={categories.category1}
        onChange={(selected) => onCategory1ComboBoxChangeHandler(selected)}
        channel={channel}
        defau={
          currentSelectCategoryList[0] != 0 ? currentSelectCategoryList[0] : ''
        }
      />
      <ComboBox
        inputLabel="カテゴリ2"
        items={categories.category2}
        channel={channel}
        onChange={(selected) => onCategory2ComboBoxChangeHandler(selected)}
        defau={
          currentSelectCategoryList[1] != 0 ? currentSelectCategoryList[1] : ''
        }
      />
      <ComboBox
        inputLabel="カテゴリ3"
        items={categories.category3}
        // value={categories.category3}
        channel={channel}
        onChange={(selected) => onCategory3ComboBoxChangeHandler(selected)}
        defau={
          currentSelectCategoryList[2] != 0 ? currentSelectCategoryList[2] : ''
        }
      />
      <ComboBox
        inputLabel="カテゴリ4"
        items={categories.category4}
        channel={channel}
        onChange={(selected) => onCategory4ComboBoxChangeHandler(selected)}
        defau={
          currentSelectCategoryList[3] != 0 ? currentSelectCategoryList[3] : ''
        }
      />
      <ComboBox
        inputLabel="カテゴリ5"
        items={categories.category5}
        channel={channel}
        onChange={(selected) => onCategory5ComboBoxChangeHandler(selected)}
        defau={
          currentSelectCategoryList[4] != 0 ? currentSelectCategoryList[4] : ''
        }
      />
      {channel === 1 ? (
        <ComboBox
          inputLabel="カテゴリ6"
          items={categories.category6}
          channel={channel}
          onChange={(selected) => onCategory6ComboBoxChangeHandler(selected)}
          defau={
            currentSelectCategoryList[5] != 0
              ? currentSelectCategoryList[5]
              : ''
          }
        />
      ) : (
        ''
      )}
    </>
  );
};

export default CategoryForm;
