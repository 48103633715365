/* eslint-disable */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import client from 'index.js';
import { apiUrl } from 'App.js';
import { FlareSharp } from '@material-ui/icons';

export const fetchAsyncAsin = createAsyncThunk(
  'asin/post',
  async (arg, thunkAPI) => {
    const { asinTitle, pageNum } = arg;
    // const { term, start, end } = await thunkAPI.getState().date;
    const { userId, isLoggedIn, accountId, accountName, userName } =
      thunkAPI.getState().user;
    const { alignment, startDate, endDate } = thunkAPI.getState().asinDate;
    const { channel } = thunkAPI.getState().currentchannel;
    const { searchAsin } = thunkAPI.getState().asins;
    const { checkGroupList } = thunkAPI.getState().group;

    const res = await client.post(`${apiUrl}asin`, {
      start: startDate,
      end: endDate,
      term: alignment,
      channel: channel,
      graph_name: asinTitle,
      user_id: userId,
      page: pageNum,
      searchAsin: searchAsin,
      group_list: checkGroupList
    });
    return res.data;
  }
);

export const fetchAsinList = createAsyncThunk(
  'asin_list/post',
  async (arg, thunkApi) => {
    const { channel } = thunkApi.getState().currentchannel;
    const { accountId } = thunkApi.getState().user;
    const { pageCount } = thunkApi.getState().asins;
    const {
      code,
      name,
      channel: asinChannel
    } = thunkApi.getState().monitoring.currentAsin;

    const res = await client.post(`${apiUrl}asin_list`, {
      channel: channel,
      account_id: accountId,
      pageCount: pageCount,
      current_asin_code: code,
      current_asin_name: name,
      current_asin_channel: asinChannel
    });
    return res.data;
  }
);

export const searchAsin = createAsyncThunk(
  'search_asin/post',
  async (arg, thunkAPI) => {
    const { asin, asinTitle } = arg;
    const { userId, isLoggedIn, accountId, accountName, userName } =
      thunkAPI.getState().user;
    const { alignment, startDate, endDate } = thunkAPI.getState().asinDate;
    const { channel, channelName } = thunkAPI.getState().currentchannel;

    const res = await client.post(`${apiUrl}search_asin`, {
      start: startDate,
      end: endDate,
      term: alignment,
      channel: channel, //検討
      graph_name: asinTitle,
      user_id: userId,
      channel_name: channelName,
      asin: asin
    });
    return res.data;
  }
);

const fetchAsinSlice = createSlice({
  name: 'asins',
  initialState: {
    searchAsin: '',
    asins: [],
    total: 0,
    pageCount: 1,
    asinListLength: ''
  },
  reducers: {
    resetAsin(state, action) {
      return {
        ...state,
        searchAsin: '',
        asins: [],
        total: 0,
        isLoading: true,
        error: false
      };
    },
    changePageCount(state, action) {
      state.pageCount += action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAsyncAsin.fulfilled, (state, action) => {
      return {
        ...state,
        // searchAsin:
        asins: action.payload.asin,
        total: action.payload.total,
        isLoading: false,
        error: false
      };
    });
    builder.addCase(fetchAsyncAsin.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        error: false
      };
    });
    builder.addCase(searchAsin.fulfilled, (state, action) => {
      return {
        ...state,
        searchAsin: action.payload.asin[0].asin,
        asins: action.payload.asin,
        total: action.payload.total,
        isLoading: false,
        error: false
      };
    });
    builder.addCase(searchAsin.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        error: false
      };
    });
    builder.addCase(fetchAsinList.fulfilled, (state, action) => {
      return {
        ...state,
        asins: action.payload.asin_list,
        asinListLength: action.payload.asin_list_length,
        isLoading: false,
        error: false
      };
    });
    builder.addCase(fetchAsinList.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        error: false
      };
    });
    builder.addCase(fetchAsinList.rejected, (state, action) => {
      return {
        ...state,
        isLoading: FlareSharp,
        error: true
      };
    });
  }
});

export const selectAsins = (state) => state.asins.asins;
export const asinTotal = (state) => state.asins.total;
export const asinStatus = (state) => state.asins.isLoading;
export const searchedAsin = (state) => state.asins.searchAsin;
export const asinListPageCount = (state) => state.asins.pageCount;
export const asinListLength = (state) => state.asins.asinListLength;

export const { resetAsin, changePageCount } = fetchAsinSlice.actions;

export default fetchAsinSlice.reducer;
