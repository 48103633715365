/* eslint-disable */
//閲覧数画面で表示するグラフ数値を受け取る
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const fetchViewsPost = createAsyncThunk(
  'views/post',
  async (arg, thunkAPI) => {
    const { term, start, end } = await thunkAPI.getState().date;
    const { userId, isLoggedIn, accountId, accountName, userName } =
      thunkAPI.getState().user;
    const { channel, benchmark } = thunkAPI.getState().currentchannel;
    const { checkGroupList } = thunkAPI.getState().group;

    const res = await client.post(`${apiUrl}views`, {
      start: start,
      end: end,
      term: term,
      channel: channel, //検討
      user_id: userId,
      group_list: checkGroupList,
      comparing_type: Number(benchmark)
    });
    // console.log(res.data);
    return res.data;
  }
);

const viewsSlice = createSlice({
  name: 'views',
  initialState: {
    searchRankOrganicGraph: [],
    searchRankAdvertisementGraph: [],
    ctrGraph: [],
    impressionGraph: [],
    pvNumGraph: [],
    clickNumGraph: [],
    brandWordGraph: [],
    bargainGraph: [],
    priceDiscountRateGraph: [],
    rankingGraph: [],
    rankingWinning: [],
    newRecognizedNumber: [],
    rate: [],
    num: [],

    //競合
    g_priceDiscountRateGraph: [],
    g_rankingGraph: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchViewsPost.fulfilled, (state, action) => {
      // console.log(action.payload);
      return {
        ...state,
        searchRankOrganicGraph: action.payload.views.searchRankOrganicGraph,
        searchRankAdvertisementGraph:
          action.payload.views.searchRankAdvertisementGraph,
        ctrGraph: action.payload.views.ctrGraph,
        pvNumGraph: action.payload.views.pvNumGraph,
        clickNumGraph: action.payload.views.clickNumGraph,
        impressionGraph: action.payload.views.impressionGraph,
        brandWordGraph: action.payload.views.brandWordGraph,
        bargainGraph: action.payload.views.bargainGraph,
        priceDiscountRateGraph: action.payload.views.priceDiscountRateGraph,
        rankingGraph: action.payload.views.rankingGraph,
        rankingWinning: action.payload.views.rankingWinning,
        newRecognizedNumber: action.payload.views.newRecognizedNumber,
        rate: action.payload.views.rate,
        num: action.payload.views.num,
        isLoading: false,
        error: false,

        //競合
        g_priceDiscountRateGraph:
          action.payload.group_views.priceDiscountRateGraph,
        g_rankingGraph: action.payload.group_views.rankingGraph
      };
    });
    builder.addCase(fetchViewsPost.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        error: false
      };
    });
  }
});

export const searchRankOrganicGraph = (state) =>
  state.views.searchRankOrganicGraph;
export const searchRankAdvertisementGraph = (state) =>
  state.views.searchRankAdvertisementGraph;
export const ctrGraph = (state) => state.views.ctrGraph;
export const pvNumGraph = (state) => state.views.pvNumGraph;
export const clickNumGraph = (state) => state.views.clickNumGraph;
export const impressionGraph = (state) => state.views.impressionGraph;
export const brandWordGraph = (state) => state.views.brandWordGraph;
export const bargainGraph = (state) => state.views.bargainGraph;
export const priceDiscountRateGraph = (state) =>
  state.views.priceDiscountRateGraph;
export const rankingGraph = (state) => state.views.rankingGraph;
export const rankingWinningGraph = (state) => state.views.rankingWinning;
export const newRecognizedNumberGraph = (state) =>
  state.views.newRecognizedNumber;

export const groupPriceDiscountRateGraph = (state) =>
  state.views.g_priceDiscountRateGraph;
export const groupRankingGraph = (state) => state.views.g_rankingGraph;

export const viewrate = (state) => state.views.rate;
export const viewnum = (state) => state.views.num;
export const viewStatus = (state) => state.views.isLoading;

export default viewsSlice.reducer;
