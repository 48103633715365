/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiUrl } from 'App';

export const fetchEnv = createAsyncThunk('env/post', async (arg, thunkAPI) => {
  const response = await axios.post(`${apiUrl}env`);
  return response.data;
});

const envSlice = createSlice({
  name: 'env',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEnv.fulfilled, (state, action) => {
      return {
        envList: action.payload.env_list
      };
    });
  }
});

export const envState = (state) => state.env.envList;

export default envSlice.reducer;
