/* eslint-disable*/
import {
  Card,
  CardContent,
  Box,
  Container,
  Grid,
  Typography,
  CircularProgress,
  useTheme
} from '@material-ui/core'; /* eslint-disable*/
import { useEffect, useState, createContext, memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import KpiSummaryGraph from '../../organisms/content/kpi_view/KpiSummaryGraph';

import { monitorStatus } from 'feactures/monitoring/monitoringSlice';
import { errorResMessage } from 'feactures/errorSlice';
import Collapse from '@mui/material/Collapse';

const themes = [
  {
    foreground: '#EB9940',
    background: '#fff8ec'
  },
  {
    foreground: '#EB9940',
    background: '##fff8ec'
  },
  {
    foreground: '#EB4159',
    background: '#F6D4D7'
  },
  {
    foreground: '#00C367',
    background: '#222222'
  }
];
export const themeContext = createContext(themes[0].background);
const kpiThemeColor = {
  initial: 'text.secondary',
  sales: '#718CC6',
  views: '#FCB542',
  cvr: '#E47F88',
  price: '#AC97D3'
};
export const kpithemeContext = createContext(kpiThemeColor.initial);
export const termContext = createContext([]);

export const KpiMonitoring = memo(({ term, page }) => {
  const loading = useSelector(monitorStatus);
  const errorMessage = useSelector(errorResMessage);

  return (
    <>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {errorMessage ? (
            <Typography variant="h5" sx={{ textAlign: 'center' }}>
              {errorMessage}
            </Typography>
          ) : (
            <>
              <Box container display="flex" flexDirection="column">
                <Collapse in={true}>
                  <termContext.Provider value={term}>
                    <KpiSummaryGraph page={page} />
                  </termContext.Provider>
                </Collapse>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
});

export default KpiMonitoring;
