/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';
export const fetchCategoryShareGraphData = createAsyncThunk(
  'categoryshare/post',
  async (_, thunkAPI) => {
    const {
      a_cate1,
      a_cate2,
      a_cate3,
      a_cate4,
      a_cate5,
      a_cate6,
      r_cate1,
      r_cate2,
      r_cate3,
      r_cate4,
      r_cate5,
      y_cate1,
      y_cate2,
      y_cate3,
      y_cate4
    } = thunkAPI.getState().categorygraph;
    const { yearMonth } = thunkAPI.getState().currentsection;
    const { channel, benchmark } = thunkAPI.getState().currentchannel;
    const res = await client.post(`${apiUrl}category/sub_category_sales`, {
      amazon_category: [a_cate1, a_cate2, a_cate3, a_cate4, a_cate5, a_cate6],
      rakuten_category: [r_cate1, r_cate2, r_cate3, r_cate4, r_cate5],
      yahoo_category: [y_cate1, y_cate2, y_cate3, y_cate4],
      term: yearMonth,
      channel: channel
    });
    return res.data;
  }
);

const fetchCategoryShareSlice = createSlice({
  name: 'categoryshare',
  initialState: {
    amazon_graphdata: {
      graphdata: {
        percentage: [],
        sales: [],
        label: [],
        cumulative_percentages: []
      }
    },
    rakuten_graphdata: {
      graphdata: {
        percentage: [],
        sales: [],
        label: [],
        cumulative_percentages: []
      }
    },
    yahoo_graphdata: {
      graphdata: {
        percentage: [],
        sales: [],
        label: [],
        cumulative_percentages: []
      }
    }
  },
  reducers: {
    resetAgraph(state, action) {
      return {
        ...state,
        amazon_graphdata: {
          graphdata: {
            percentage: [],
            sales: [],
            label: [],
            cumulative_percentages: []
          }
        }
      };
    },
    resetRgraph(state, action) {
      return {
        ...state,
        rakuten_graphdata: {
          graphdata: {
            percentage: [],
            sales: [],
            label: [],
            cumulative_percentages: []
          }
        }
      };
    },
    resetYgraph(state, action) {
      return {
        ...state,
        yahoo_graphdata: {
          graphdata: {
            percentage: [],
            sales: [],
            label: [],
            cumulative_percentages: []
          }
        }
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategoryShareGraphData.fulfilled, (state, action) => {
      if (action.payload.channel == 1) {
        state.amazon_graphdata['graphdata'] = action.payload.data;
        state.isLoading = false;
      } else if (action.payload.channel == 2) {
        state.rakuten_graphdata['graphdata'] = action.payload.data;
        state.isLoading = false;
      } else if (action.payload.channel == 3) {
        state.yahoo_graphdata['graphdata'] = action.payload.data;
        state.isLoading = false;
      }
    });
    builder.addCase(fetchCategoryShareGraphData.pending, (state, action) => {
      return {
        ...state,
        isLoading: true
        // error: false
      };
    });
    builder.addCase(fetchCategoryShareGraphData.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false
        // error: false
      };
    });
  }
});

export const selectAmazonCategoryShareGraph = (state) =>
  state.categoryshare.amazon_graphdata;
export const selectRakutenCategoryShareGraph = (state) =>
  state.categoryshare.rakuten_graphdata;
export const selectYahooCategoryShareGraph = (state) =>
  state.categoryshare.yahoo_graphdata;

export const categorygraphShareStatus = (state) =>
  state.categoryshare.isLoading;

export const { resetAgraph, resetRgraph, resetYgraph } =
  fetchCategoryShareSlice.actions;
export default fetchCategoryShareSlice.reducer;
