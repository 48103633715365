/* eslint-disable*/

import { Container, Typography } from '@material-ui/core'; // eslint-disable-line
import MalltrendsGraph from 'components/molecules/dashboard/MalltrendsGraph';
import { sales_by_mall_graph } from 'feactures/overview/overviewSlice';
import { useSelector } from 'react-redux';

const SalestrendsByMall = (props) => {
  const { yokoziku } = props;
  const graphdata = useSelector(sales_by_mall_graph);

  return (
    <Container maxWidth={false}>
      <Typography fontSize="12px" fontWeight="bold" sx={{ paddingTop: '8px' }}>
        モール別売上推移
      </Typography>
      <MalltrendsGraph graphdata={graphdata} />
    </Container>
  );
};

export default SalestrendsByMall;
