/* eslint-disable*/
import {
  Box,
  CircularProgress,
  Dialog,
  Typography,
  DialogContent,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@material-ui/styles/makeStyles';
import { useState, useEffect, useRef, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  itemDetail,
  itemDetailDisplay,
  itemDetailLoading,
  setItemDetailDisplayFlag
} from 'feactures/product/productAnalysisSlice';
import { currentchannel } from 'feactures/channel/channelSlice';
import RankingItemDetailgraph from 'components/molecules/dashboard/RankingItemDetailgraph';

const ItemAnalysisDetailDialog = (props) => {
  const { yokoziku } = props;
  const openFlag = useSelector(itemDetailDisplay);
  const data = useSelector(itemDetail);
  const channel = useSelector(currentchannel);
  const loading = useSelector(itemDetailLoading);
  const dispatch = useDispatch();
  let graphData = data.graphData;
  let yoko = [...yokoziku];
  let targetDate = '';

  if (channel == 1) {
    // 楽天は2023/7以前は表示させない
    targetDate = '2023/2';
  } else if (channel == 2) {
    // 楽天は2023/7以前は表示させない
    targetDate = '2023/7';
  }
  const index = yokoziku.findIndex((date) => date === targetDate);

  if (index !== -1 && data.graphData.length > 0) {
    yoko.splice(0, index + 1);
    let newData = JSON.parse(JSON.stringify(data.graphData)); // オブジェクト全体をコピー
    newData.splice(0, index + 1); // graphdataを変更
    graphData = newData; // 新しいオブジェクトを代入
  }

  // ×ボタンクリックでポップアップを非表示にする。
  const handleClick = () => {
    dispatch(setItemDetailDisplayFlag(false));
  };

  const tableStyles = makeStyles({
    tableHead: {
      height: 44
    }
  });

  const classes = tableStyles();

  const url = 'https://m.media-amazon.com/images/I/';

  return (
    <>
      {openFlag && (
        <Dialog open={openFlag} onClose={handleClick} maxWidth="100%">
          <DialogContent sx={{ display: 'flex', width: '1200px' }}>
            <Box
              sx={{ padding: '10px 40px' }}
              display="flex"
              flexDirection="column"
              my={1}
              width="100%"
            >
              <TableContainer sx={{ maxHeight: 550 }}>
                <Table stickyHeader size="small" aria-label="a dense table">
                  <TableHead sx={{ maxHeight: '38px' }}>
                    <TableRow>
                      {/* <TableCell sx={{ width: '62px' }} component="th" scope="row"> */}
                      <TableCell
                        align="center"
                        component="th"
                        scope="row"
                        sx={{ minWidth: '65px' }}
                      >
                        順位
                      </TableCell>
                      <TableCell sx={{ width: '70px' }}></TableCell>
                      {channel == 1 ? (
                        <TableCell align="center">ASIN</TableCell>
                      ) : (
                        <TableCell align="center">
                          ショップ名
                          <br />
                          商品管理番号
                        </TableCell>
                      )}
                      <TableCell align="center">商品名</TableCell>
                      <TableCell align="center" sx={{ minWidth: '120px' }}>
                        価格
                      </TableCell>
                      <TableCell align="center" sx={{ minWidth: '120px' }}>
                        販売個数
                      </TableCell>
                      <TableCell align="center">売上</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': {
                          border: 0
                        },
                        height: 100
                      }}
                      className={classes.tableHead}
                    >
                      <TableCell align="center">{data.rank}位</TableCell>
                      <TableCell align="center" maxwidth={40}>
                        <Link
                          href={
                            channel === 1
                              ? `https://www.amazon.co.jp/dp/${data.asin}`
                              : `https://item.rakuten.co.jp/${data.shopCode}/${
                                  data.asin.split(':')[1]
                                }/`
                          }
                          target="_blank"
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <Box
                              sx={{
                                height: 70,
                                width: 70,
                                display: 'inline-block'
                              }}
                            >
                              <img
                                src={channel == 1 ? url + data.img : data.img}
                                height="100%"
                                loading="lazy"
                                width="100%"
                              />
                            </Box>
                          </Box>
                        </Link>
                      </TableCell>
                      <TableCell align="center">
                        {channel == 1 ? (
                          <Box>{data.asin}</Box>
                        ) : (
                          <>
                            <Box>{data.shopCode}</Box>
                            <br />
                            <Box>{data.asin.split(':')[1]}</Box>
                          </>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        <Link
                          underline="hover"
                          href={
                            channel === 1
                              ? `https://www.amazon.co.jp/dp/${data.asin}`
                              : `https://item.rakuten.co.jp/${data.shopCode}/${
                                  data.asin.split(':')[1]
                                }/`
                          }
                          target="_blank"
                          rel="noopener"
                        >
                          <Box
                            sx={{
                              height: 60,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'left',
                              lineHeight: '15px'
                              // width: 170,
                              // display: 'inline-block'
                            }}
                            component="div"
                            my={2}
                            textOverflow="ellipsis"
                            overflow="hidden"
                            bgcolor="background.paper"
                          >
                            {data.item_name}
                          </Box>
                        </Link>
                      </TableCell>
                      <TableCell align="right">
                        {data.price == 0
                          ? '-'
                          : '¥' + data.price.toLocaleString()}
                      </TableCell>
                      <TableCell align="right">
                        {data.unit_sold == 0
                          ? '-'
                          : data.unit_sold.toLocaleString()}
                      </TableCell>
                      <TableCell align="right">
                        ¥{data.sales.toLocaleString()}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              {loading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height={270}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <RankingItemDetailgraph graphdata={graphData} yokoziku={yoko} />
              )}
            </Box>
            <CloseIcon
              onClick={handleClick}
              sx={{ alignSelf: 'flex-start', cursor: 'pointer' }}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default ItemAnalysisDetailDialog;
