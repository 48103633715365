/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';
import * as moment from 'moment/moment';

moment.locale('ja');

export const registerTrackingProduct = createAsyncThunk(
  'tracking_product/register',
  async (arg, thunkAPI) => {
    const { accountId } = thunkAPI.getState().user;
    const { channel } = thunkAPI.getState().currentchannel;
    const { item_id, shop_code, item_name } = arg;
    const res = await client.post(`${apiUrl}tracking_product/register`, {
      accountId: accountId,
      channel: channel,
      itemId: item_id,
      shopCode: shop_code,
      itemName: item_name
    });
    return res.data;
  }
);

export const deleteTrackingProduct = createAsyncThunk(
  'tracking_product/delete',
  async (arg, thunkAPI) => {
    const { accountId } = thunkAPI.getState().user;
    const { deleteList } = thunkAPI.getState().newMeasureAnalysis;
    const { channel } = thunkAPI.getState().currentchannel;
    const res = await client.post(`${apiUrl}tracking_product/delete`, {
      accountId: accountId,
      deleteList: deleteList,
      channel: channel
    });
    return res.data;
  }
);

export const fetchKeywordList = createAsyncThunk(
  'keyword/get',
  async (arg, thunkAPI) => {
    const { accountId } = thunkAPI.getState().user;
    const { channel } = thunkAPI.getState().currentchannel;
    const res = await client.post(`${apiUrl}get_seo_keyword`, {
      accountId: accountId,
      channel: channel
    });
    return res.data;
  }
);

export const deleteKeyword = createAsyncThunk(
  'keyword/delete',
  async (arg, thunkAPI) => {
    const { accountId } = thunkAPI.getState().user;
    const { channel } = thunkAPI.getState().currentchannel;
    const { id } = arg;
    const res = await client.post(`${apiUrl}seo_keyword_delete`, {
      id: id,
      accountId: accountId,
      channel: channel
    });
    return res.data;
  }
);

export const registerKeyword = createAsyncThunk(
  'keyword/register',
  async (arg, thunkAPI) => {
    const { accountId } = thunkAPI.getState().user;
    const { channel } = thunkAPI.getState().currentchannel;
    const { keyword, item_id, shop_code } = arg;
    const res = await client.post(`${apiUrl}seo_keywoerd_register`, {
      accountId: accountId,
      channel: channel,
      keyword: keyword,
      itemId: item_id,
      shopCode: shop_code
    });
    return res.data;
  }
);

export const fetchMeasureAnalysisData = createAsyncThunk(
  'measure_analysis/get',
  async (arg, thunkAPI) => {
    const { accountId } = thunkAPI.getState().user;
    const { start, end } = thunkAPI.getState().date;
    const { channel } = thunkAPI.getState().currentchannel;
    const res = await client.post(`${apiUrl}measure_analysis/report`, {
      accountId: accountId,
      start: start,
      end: end,
      channel: channel
    });
    return res.data;
  }
);

export const getRankingChangeGraphData = createAsyncThunk(
  'measures/rankinggraph',
  async (arg, thunkAPI) => {
    const { accountId } = thunkAPI.getState().user;
    const { channel } = thunkAPI.getState().currentchannel;
    const { start, end } = thunkAPI.getState().date;
    const { measure } = thunkAPI.getState().newMeasureAnalysis;
    const res = await client.post(`${apiUrl}measures/rankinggraph`, {
      accountId: accountId,
      channel: channel,
      start_date: start,
      end_date: end,
      measure: measure
    });
    return res.data;
  }
);

export const getSeoGraphData = createAsyncThunk(
  'measures/seograph',
  async (arg, thunkAPI) => {
    const { accountId } = thunkAPI.getState().user;
    const { channel } = thunkAPI.getState().currentchannel;
    const { start, end } = thunkAPI.getState().date;
    const { item_id, shop_code } = arg;
    const res = await client.post(`${apiUrl}measures/seograph`, {
      accountId: accountId,
      channel: channel,
      start_date: start,
      end_date: end,
      item_id: item_id,
      shop_code: shop_code
    });
    return res.data;
  }
);

export const fetchRankingProductList = createAsyncThunk(
  'measures/rankigProduct',
  async (arg, thunkAPI) => {
    const { date, category, pageCount, keyword } =
      thunkAPI.getState().newMeasureAnalysis.rankingProduct;
    const { channel } = thunkAPI.getState().currentchannel;
    const res = await client.post(`${apiUrl}measures/rankingProduct`, {
      date: date,
      categoryList: category,
      pageCount: pageCount,
      keyword: keyword,
      channel: channel
    });
    return res.data;
  }
);

const today = moment().format('YYYY-MM-DD');
const yesterday = moment().subtract(1, 'days').format('YYYY-MM-DD');

const newMeasureSlice = createSlice({
  name: 'newMeasureAnalysis',
  initialState: {
    measure: 'rank',
    graphTitle: 'ランキング',
    productList: [],
    keywordList: [],
    deleteList: [],
    reportData: [],
    productIsLoading: false,
    reportIsLoading: false,
    isLoading: false,
    rankingChangeGraph: {
      data: [],
      isLoading: false
    },
    seoGraph: {
      data: [],
      isLoading: false
    },
    rankingProduct: {
      data: [],
      isLoading: false,
      pageCount: 1,
      date: yesterday,
      category: [
        {
          level: 1,
          categoryId: '',
          name: ''
        }
      ],
      keyword: '',
      length: 0
    }
  },
  reducers: {
    changeMeasure(state, action) {
      state.measure = action.payload.measure;
      state.graphTitle = action.payload.title;
    },
    addDeleteList(state, action) {
      state.deleteList = [...state.deleteList, action.payload];
    },
    removeDeleteList(state, action) {
      state.deleteList = state.deleteList.filter(
        (item) => item !== action.payload
      );
    },
    changeRankingProductPage(state, action) {
      state.rankingProduct.pageCount =
        state.rankingProduct.pageCount + action.payload;
    },
    resetRankingProductPage(state, action) {
      state.rankingProduct.pageCount = 1;
      state.rankingProduct.data = [];
      state.rankingProduct.length = 0;
    },
    changeRankingProductDate(state, action) {
      state.rankingProduct.date = action.payload;
    },
    changeRankingProductSearchword(state, action) {
      state.rankingProduct.keyword = action.payload;
    },
    changeSelectedCategory(state, action) {
      state.rankingProduct.category = action.payload;
    },
    resetSelectedCategory(state, action) {
      state.rankingProduct.category = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(registerTrackingProduct.fulfilled, (state, action) => {
      return {
        ...state,
        productIsLoading: false
      };
    });
    builder.addCase(registerTrackingProduct.pending, (state, action) => {
      return {
        ...state,
        productIsLoading: true
      };
    });
    builder.addCase(registerTrackingProduct.rejected, (state, action) => {
      return {
        ...state,
        productIsLoading: false
      };
    });
    builder.addCase(deleteTrackingProduct.fulfilled, (state, action) => {
      return {
        ...state,
        productIsLoading: false,
        productList: action.payload.tracking_list,
        deleteList: []
      };
    });
    builder.addCase(deleteTrackingProduct.pending, (state, action) => {
      return {
        ...state,
        productIsLoading: true
      };
    });
    builder.addCase(deleteTrackingProduct.rejected, (state, action) => {
      return {
        ...state,
        productIsLoading: false
      };
    });
    builder.addCase(fetchKeywordList.fulfilled, (state, action) => {
      return {
        ...state,
        keywordList: action.payload.keyword_list,
        isLoading: false
      };
    });
    builder.addCase(fetchKeywordList.pending, (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    });
    builder.addCase(fetchKeywordList.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    });
    builder.addCase(deleteKeyword.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        keywordList: action.payload.keyword_list
      };
    });
    builder.addCase(deleteKeyword.pending, (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    });
    builder.addCase(deleteKeyword.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    });
    builder.addCase(registerKeyword.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        keywordList: action.payload.keyword_list
      };
    });
    builder.addCase(registerKeyword.pending, (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    });
    builder.addCase(registerKeyword.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    });
    builder.addCase(fetchMeasureAnalysisData.fulfilled, (state, action) => {
      return {
        ...state,
        reportIsLoading: false,
        reportData: action.payload.data
      };
    });
    builder.addCase(getRankingChangeGraphData.fulfilled, (state, action) => {
      return {
        ...state,
        rankingChangeGraph: { data: action.payload.data, isLoading: false }
      };
    });
    builder.addCase(getRankingChangeGraphData.pending, (state, action) => {
      return {
        ...state,
        rankingChangeGraph: {
          data: state.rankingChangeGraph.data,
          isLoading: true
        }
      };
    });
    builder.addCase(getRankingChangeGraphData.rejected, (state, action) => {
      return {
        ...state,
        rankingChangeGraph: {
          data: state.rankingChangeGraph.data,
          isLoading: false
        }
      };
    });
    builder.addCase(fetchMeasureAnalysisData.pending, (state, action) => {
      return {
        ...state,
        reportIsLoading: true
      };
    });
    builder.addCase(fetchMeasureAnalysisData.rejected, (state, action) => {
      return {
        ...state,
        reportIsLoading: false
      };
    });
    builder.addCase(getSeoGraphData.fulfilled, (state, action) => {
      return {
        ...state,
        seoGraph: { data: action.payload.data, isLoading: false }
      };
    });
    builder.addCase(getSeoGraphData.pending, (state, action) => {
      return {
        ...state,
        seoGraph: {
          data: state.seoGraph.data,
          isLoading: true
        }
      };
    });
    builder.addCase(getSeoGraphData.rejected, (state, action) => {
      return {
        ...state,
        seoGraph: {
          data: state.seoGraph.data,
          isLoading: false
        }
      };
    });
    builder.addCase(fetchRankingProductList.fulfilled, (state, action) => {
      return {
        ...state,
        rankingProduct: {
          ...state.rankingProduct,
          isLoading: false,
          data: action.payload.data,
          length: action.payload.length
        }
      };
    });
    builder.addCase(fetchRankingProductList.pending, (state, action) => {
      return {
        ...state,
        rankingProduct: {
          ...state.rankingProduct,
          isLoading: true
        }
      };
    });
    builder.addCase(fetchRankingProductList.rejected, (state, action) => {
      return {
        ...state,
        rankingProduct: {
          ...state.rankingProduct,
          isLoading: false
        }
      };
    });
  }
});

export const {
  changeMeasure,
  addDeleteList,
  removeDeleteList,
  changeRankingProductPage,
  changeRankingProductDate,
  changeRankingProductSearchword,
  changeSelectedCategory,
  resetSelectedCategory,
  resetRankingProductPage
} = newMeasureSlice.actions;
export const selectedMeasure = (state) => state.newMeasureAnalysis.measure;
export const graphTitle = (state) => state.newMeasureAnalysis.graphTitle;
export const keywordList = (state) => state.newMeasureAnalysis.keywordList;
export const deleteList = (state) => state.newMeasureAnalysis.deleteList;
export const keywordIsLoading = (state) => state.newMeasureAnalysis.isLoading;
export const productList = (state) => state.newMeasureAnalysis.productList;
export const reportData = (state) => state.newMeasureAnalysis.reportData;
export const productIsLoading = (state) =>
  state.newMeasureAnalysis.productIsLoading;
export const rankingChangeGraphData = (state) =>
  state.newMeasureAnalysis.rankingChangeGraph.data;
export const rankingChangeGraphLoading = (state) =>
  state.newMeasureAnalysis.rankingChangeGraph.isLoading;
export const seoGraphData = (state) => state.newMeasureAnalysis.seoGraph.data;
export const seoGraphLoading = (state) =>
  state.newMeasureAnalysis.seoGraph.isLoading;
export const reportIsLoading = (state) =>
  state.newMeasureAnalysis.reportIsLoading;
export const rankingProductPageCount = (state) =>
  state.newMeasureAnalysis.rankingProduct.pageCount;
export const rankingProductSelectedDate = (state) =>
  state.newMeasureAnalysis.rankingProduct.date;
export const rankingProductData = (state) =>
  state.newMeasureAnalysis.rankingProduct.data;
export const rankingProductLength = (state) =>
  state.newMeasureAnalysis.rankingProduct.length;
export const rankingProductIsLoading = (state) =>
  state.newMeasureAnalysis.rankingProduct.isLoading;
export const rankingProductSearchword = (state) =>
  state.newMeasureAnalysis.rankingProduct.keyword;
export const rankingProductCategory = (state) =>
  state.newMeasureAnalysis.rankingProduct.category;

export default newMeasureSlice.reducer;
