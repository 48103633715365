/* eslint-disable */
import { Button, Box, Typography } from '@material-ui/core';
import GroupListTable from 'components/molecules/dashboard/GroupListTable';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useState } from 'react';

const AddKpiGroupButton = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        sx={{
          width: '98%',
          bgcolor: 'white',
          borderRadius: 2,
          px: 2,
          border: '1px solid #D9D9D9',
          display: 'flex',
          justifyContent: 'start'
        }}
      >
        <AddCircleIcon sx={{ color: '#A6A6A6', fontSize: '24px', mr: 2 }} />
        <Typography sx={{ color: '#A6A6A6', fontSize: '12px' }}>
          グループを追加
        </Typography>
      </Button>
      <GroupListTable open={open} setOpen={setOpen} />
    </>
  );
};

export default AddKpiGroupButton;
