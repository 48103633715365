/* eslint-disable */
import {
  Box,
  Button,
  Card,
  CardContent,
  Modal,
  Typography,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Snackbar
} from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/styles';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import theme from 'theme';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import GroupEditModal from './GroupEditModal';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  groups,
  changeEditGroup,
  deleteGroup,
  groupSuccessMessage,
  resetSuccessMessage,
  fetchGroupDataList
} from 'feactures/monitoring/groupSlice';
import { fetchOverviewGroupData } from 'feactures/overview/overviewSlice';

const useStyles = makeStyles({
  modalComponent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-43%) translateY(-50%)'
  }
});

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: '16px',
  color: '#A6A6A6',
  textAlign: 'center',
  borderColor: '#A6A6A6',
  width: '23%'
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: '16px',
  color: '#4362A6',
  textAlign: 'center',
  borderColor: '#A6A6A6',
  width: '23%'
}));

const GroupListTable = ({ open: open, setOpen: setOpen }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const groupList = useSelector(groups);

  // useEffect(() => {
  //   dispatch(fetchGroupDataList({ searchword: '' }));
  // }, [group, pageCount]);

  const handleClose = () => {
    setOpen(false);
  };
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false); // 編集用か新規追加用かの判定

  const addButtonClick = () => {
    const data = {
      name: '',
      amazon: [],
      rakuten: [],
      yahoo: []
    };
    dispatch(changeEditGroup(data));
    setIsEdit(false);
    setEditModalOpen(true);
  };

  const editButtonClick = (group) => {
    const data = {
      id: group.id,
      name: group.name,
      amazon: group.amazon,
      rakuten: group.rakuten,
      yahoo: group.yahoo
    };
    dispatch(changeEditGroup(data));
    setIsEdit(true);
    setEditModalOpen(true);
  };

  const handleDelete = (id) => {
    if (window.confirm('グループを削除してよろしいですか？')) {
      dispatch(deleteGroup({ groupId: id, deleteAll: false }));
    }
  };

  const deleteAllClick = () => {
    if (window.confirm('グループをすべて削除してよろしいですか？')) {
      dispatch(deleteGroup({ groupId: 'all', deleteAll: true }));
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Card
          className={classes.modalComponent}
          sx={{
            borderRadius: 6,
            pb: 0,
            my: 2,
            border: '1px solid #A6A6A6',
            minHeight: '350px',
            width: '70%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              px: 2,
              mt: 3
            }}
          >
            <Typography fontSize={'16px'} color={'gray'}>
              登録済み
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <CardContent
            sx={{ width: '100%', maxHeight: '80vh', overflow: 'auto' }}
          >
            <TableContainer sx={{ mb: 3 }}>
              <Table>
                <TableHead sx={{ borderTop: '1px solid #A6A6A6' }}>
                  <TableRow>
                    <HeaderTableCell>グループ</HeaderTableCell>
                    <HeaderTableCell>Amazon商品</HeaderTableCell>
                    <HeaderTableCell>楽天商品</HeaderTableCell>
                    <HeaderTableCell>Yahoo商品</HeaderTableCell>
                    <HeaderTableCell sx={{ width: '5%' }}></HeaderTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupList.map((group, index) => (
                    <TableRow key={`${group.name}-${index}`}>
                      <StyledTableCell>
                        <Button
                          sx={{ color: 'black', fontSize: '16px', py: 0 }}
                          onClick={() => editButtonClick(group)}
                        >
                          <CreateOutlinedIcon
                            sx={{
                              color: '#A6A6A6',
                              mr: 1
                            }}
                          />
                          {group.name}
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell>{group.amazon.length}</StyledTableCell>
                      <StyledTableCell>{group.rakuten.length}</StyledTableCell>
                      <StyledTableCell>{group.yahoo.length}</StyledTableCell>
                      <StyledTableCell sx={{ width: '5%' }}>
                        <IconButton onClick={() => handleDelete(group.id)}>
                          <DeleteOutlineIcon />
                        </IconButton>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <StyledTableCell sx={{ borderBottom: 'none' }}>
                      <IconButton
                        sx={{ color: '#A6A6A6', fontSize: '16px' }}
                        onClick={addButtonClick}
                      >
                        <AddCircleIcon />
                      </IconButton>
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#D9D9D9',
                  color: 'white',
                  borderRadius: 5
                }}
                onClick={deleteAllClick}
              >
                全てのグループを削除
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Modal>

      <GroupEditModal
        open={editModalOpen}
        setOpen={setEditModalOpen}
        isEdit={isEdit}
      />
    </>
  );
};

export default GroupListTable;
