/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const fetchItemDetailGraph = createAsyncThunk(
  'otherproducts/item/post',
  async (arg, thunkAPI) => {
    const { yearMonth } = thunkAPI.getState().currentsection;
    const { channel } = thunkAPI.getState().currentchannel;
    const { accountId } = thunkAPI.getState().user;
    const item_id = arg.asin;
    const res = await client.post(`${apiUrl}other_products/item`, {
      account_id: accountId,
      channel: channel,
      select_month: yearMonth,
      item_id: item_id
      //   shop_code: arg.shopCode,
      //   section: arg.section
    });
    return res.data;
  }
);

const fetchProductAnalysisSlice = createSlice({
  name: 'productAnalysis',
  initialState: {
    itemDetail: {
      rank: '',
      img: '',
      shopCode: '',
      asin: '',
      item_name: '',
      price: '',
      unit_sold: '',
      sales: '',
      graphData: []
    },
    itemDetailLoading: false,
    itemDetailDisplay: false
  },
  reducers: {
    setItemDetailDisplayFlag(state, action) {
      return {
        ...state,
        itemDetailDisplay: action.payload
      };
    },
    setItemDetail(state, action) {
      return {
        ...state,
        itemDetail: {
          rank: action.payload.rank,
          img: action.payload.img,
          shopCode: action.payload.shopCode,
          asin: action.payload.asin,
          item_name: action.payload.item_name,
          price: action.payload.price,
          unit_sold: action.payload.unit_sold,
          sales: action.payload.sales,
          graphData: state.itemDetail.graphData
        }
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchItemDetailGraph.fulfilled, (state, action) => {
      return {
        ...state,
        itemDetail: {
          ...state.itemDetail,
          graphData: action.payload.itemdetail_graphdata
        },
        itemDetailLoading: false
      };
    });
    builder.addCase(fetchItemDetailGraph.pending, (state, action) => {
      return {
        ...state,
        itemDetailLoading: true
      };
    });
    builder.addCase(fetchItemDetailGraph.rejected, (state, action) => {
      return {
        ...state,
        itemDetail: {
          ...state.itemDetail,
          graphData: []
        },
        itemDetailLoading: false
      };
    });
  }
});

export const { setItemDetailDisplayFlag } = fetchProductAnalysisSlice.actions;
export const { setItemDetail } = fetchProductAnalysisSlice.actions;
export const ProductAnalysisStatus = (state) => state.productAnalysis.isLoading;
export const itemDetail = (state) => state.productAnalysis.itemDetail;
export const itemDetailLoading = (state) =>
  state.productAnalysis.itemDetailLoading;
export const itemDetailDisplay = (state) =>
  state.productAnalysis.itemDetailDisplay;

export default fetchProductAnalysisSlice.reducer;
