/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const fetchOwnSales = createAsyncThunk(
  'ownsales/post',
  async (_, thunkAPI) => {
    const {
      a_cate1,
      a_cate2,
      a_cate3,
      a_cate4,
      a_cate5,
      a_cate6,
      r_cate1,
      r_cate2,
      r_cate3,
      r_cate4,
      r_cate5,
      y_cate1,
      y_cate2,
      y_cate3,
      y_cate4
    } = thunkAPI.getState().categorygraph;
    const { ownSelectedBrand } = thunkAPI.getState().brand;
    const { channel } = thunkAPI.getState().currentchannel;
    const { yearMonth } = thunkAPI.getState().currentsection;
    const { accountId } = thunkAPI.getState().user;
    let amazon_brand_id = null;
    let rakuten_brand_id = null;
    let yahoo_brand_id = null;
    if (channel === 1 && ownSelectedBrand.amazon !== '') {
      amazon_brand_id = ownSelectedBrand.amazon.brand;
    } else if (channel === 2 && ownSelectedBrand.rakuten !== '') {
      rakuten_brand_id = ownSelectedBrand.rakuten.id;
    } else if (channel === 3 && ownSelectedBrand.yahoo !== '') {
      yahoo_brand_id = ownSelectedBrand.yahoo.id;
    }

    const res = await client.post(`${apiUrl}measure/get_own_sales`, {
      amazon_category: [a_cate1, a_cate2, a_cate3, a_cate4, a_cate5, a_cate6],
      rakuten_category: [r_cate1, r_cate2, r_cate3, r_cate4, r_cate5],
      yahoo_category: [y_cate1, y_cate2, y_cate3, y_cate4],
      amazon_brand: amazon_brand_id,
      rakuten_brand: rakuten_brand_id,
      yahoo_brand: yahoo_brand_id,
      term: yearMonth,
      channel: channel,
      account_id: accountId
    });
    return res.data;
  }
);
export const getOwnRegisterMeasuresProductSales = createAsyncThunk(
  'getOwnRegisterMeasuresProductSales/post',
  async (arg, thunkAPI) => {
    const { channel } = thunkAPI.getState().currentchannel;
    const { accountId, userId } = thunkAPI.getState().user;
    const { yearMonth } = thunkAPI.getState().currentsection;
    const res = await client.post(
      `${apiUrl}measure/get_own_registered_product_sales`,
      {
        channel: channel,
        account_id: accountId,
        user_id: userId,
        term: yearMonth
      }
    );
    return res.data;
  }
);
export const getOtherRegisterMeasuresProductSales = createAsyncThunk(
  'getOtherRegisterMeasuresProductSales/post',
  async (arg, thunkAPI) => {
    const { channel } = thunkAPI.getState().currentchannel;
    const { accountId, userId } = thunkAPI.getState().user;
    const { yearMonth } = thunkAPI.getState().currentsection;
    const res = await client.post(
      `${apiUrl}measure/get_other_registered_product_sales`,
      {
        channel: channel,
        account_id: accountId,
        user_id: userId,
        term: yearMonth
      }
    );
    return res.data;
  }
);
export const getRegisterMeasuresProduct = createAsyncThunk(
  'getRegisterMeasuresProduct/post',
  async (arg, thunkAPI) => {
    const { channel } = thunkAPI.getState().currentchannel;
    const { accountId, userId } = thunkAPI.getState().user;
    const res = await client.post(`${apiUrl}measure/get_registered_product`, {
      channel: channel,
      account_id: accountId,
      user_id: userId,
      section: arg.section
    });
    return res.data;
  }
);
export const registerMeasuresProduct = createAsyncThunk(
  'registermeasuresproduct/post',
  async (arg, thunkAPI) => {
    const { channel } = thunkAPI.getState().currentchannel;
    const { accountId, userId } = thunkAPI.getState().user;
    const res = await client.post(`${apiUrl}measure/register_product`, {
      channel: channel,
      account_id: accountId,
      product_list: arg.productList,
      user_id: userId,
      section: arg.section
    });
    return res.data;
  }
);
export const deleteMeasuresProduct = createAsyncThunk(
  'deletemeasuresproduct/post',
  async (arg, thunkAPI) => {
    const { channel } = thunkAPI.getState().currentchannel;
    const { accountId, userId } = thunkAPI.getState().user;
    const res = await client.post(`${apiUrl}measure/delete_product`, {
      channel: channel,
      account_id: accountId,
      asin: arg.product,
      user_id: userId,
      section: arg.section
    });
    return res.data;
  }
);
export const searchMeasuresProduct = createAsyncThunk(
  'searchmeasuresproduct/post',
  async (arg, thunkAPI) => {
    const { channel } = thunkAPI.getState().currentchannel;
    const { accountId, userId } = thunkAPI.getState().user;
    const res = await client.post(`${apiUrl}measure/search_product`, {
      channel: channel,
      account_id: accountId,
      searchWord: arg.searchWord,
      section: arg.section,
      page: arg.page
    });
    return res.data;
  }
);

const measureSlice = createSlice({
  name: 'measure',
  initialState: {
    ownSalesGraphdata: {
      amazon: [],
      rakuten: [],
      yahoo: []
    },
    otherSalesGraphdata: {
      amazon: [],
      rakuten: [],
      yahoo: []
    },
    detailOpen: false,
    detail: '',
    detailSection: '',
    detailTerm: '',
    registerOpen: false,
    registerSection: '',
    registeredProduct: [],
    registerInfo: {
      ng_list: [],
      existing: [],
      other_section: [],
      not_in_market: []
    },
    searchResult: [],
    resultLength: 0,
    productLoading: false,
    registerLoading: false,
    searchLoading: false,
    isLoading: false
  },
  reducers: {
    setRegisterSection(state, action) {
      state.registerSection = action.payload;
    },
    setRegisterOpen(state, action) {
      state.registerOpen = action.payload;
    },
    setDetailOpen(state, action) {
      state.detailOpen = true;
      state.detail = action.payload.detail;
      state.detailSection = action.payload.section;
      state.detailTerm = action.payload.term;
    },
    setDetailClose(state, action) {
      state.detailOpen = false;
    },
    setItemDetailOpen(state, action) {
      state.registeredProduct[action.payload.index].detailOpen =
        action.payload.isOpen;
    },
    resetSearchResult(state, action) {
      state.searchResult = [];
      state.resultLength = 0;
    },
    resetAmazonOwnSalesGraph(state, action) {
      state.ownSalesGraphdata.amazon = [];
    },
    resetRakutenOwnSalesGraph(state, action) {
      state.ownSalesGraphdata.rakuten = [];
    },
    resetYahooOwnSalesGraph(state, action) {
      state.ownSalesGraphdata.yahoo = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOwnSales.fulfilled, (state, action) => {
      if (action.payload.channel == 1) {
        state.ownSalesGraphdata.amazon = action.payload.graphdata;
        state.isLoading = false;
      } else if (action.payload.channel == 2) {
        state.ownSalesGraphdata.rakuten = action.payload.graphdata;
        state.isLoading = false;
      } else if (action.payload.channel == 3) {
        state.ownSalesGraphdata.yahoo = action.payload.graphdata;
        state.isLoading = false;
      }
      state.isLoading = false;
    });
    builder.addCase(fetchOwnSales.pending, (state, action) => {
      return {
        ...state,
        isLoading: true
        // error: false
      };
    });
    builder.addCase(fetchOwnSales.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false
        // error: false
      };
    });
    builder.addCase(
      getOwnRegisterMeasuresProductSales.fulfilled,
      (state, action) => {
        if (action.payload.channel == 1) {
          state.ownSalesGraphdata.amazon = action.payload.graphdata;
          state.isLoading = false;
        } else if (action.payload.channel == 2) {
          state.ownSalesGraphdata.rakuten = action.payload.graphdata;
          state.isLoading = false;
        } else if (action.payload.channel == 3) {
          state.ownSalesGraphdata.yahoo = action.payload.graphdata;
          state.isLoading = false;
        }
        state.isLoading = false;
      }
    );
    builder.addCase(
      getOwnRegisterMeasuresProductSales.pending,
      (state, action) => {
        return {
          ...state,
          isLoading: true
        };
      }
    );
    builder.addCase(
      getOwnRegisterMeasuresProductSales.rejected,
      (state, action) => {
        return {
          ...state,
          isLoading: false
        };
      }
    );
    builder.addCase(
      getOtherRegisterMeasuresProductSales.fulfilled,
      (state, action) => {
        if (action.payload.channel == 1) {
          state.otherSalesGraphdata.amazon = action.payload.graphdata;
          state.isLoading = false;
        } else if (action.payload.channel == 2) {
          state.otherSalesGraphdata.rakuten = action.payload.graphdata;
          state.isLoading = false;
        } else if (action.payload.channel == 3) {
          state.otherSalesGraphdata.yahoo = action.payload.graphdata;
          state.isLoading = false;
        }
        state.isLoading = false;
      }
    );
    builder.addCase(
      getOtherRegisterMeasuresProductSales.pending,
      (state, action) => {
        return {
          ...state,
          isLoading: true
        };
      }
    );
    builder.addCase(
      getOtherRegisterMeasuresProductSales.rejected,
      (state, action) => {
        return {
          ...state,
          isLoading: false
        };
      }
    );
    builder.addCase(getRegisterMeasuresProduct.fulfilled, (state, action) => {
      return {
        ...state,
        registeredProduct: action.payload.product_list,
        productLoading: false
      };
    });
    builder.addCase(getRegisterMeasuresProduct.pending, (state, action) => {
      return {
        ...state,
        productLoading: true
      };
    });
    builder.addCase(getRegisterMeasuresProduct.rejected, (state, action) => {
      return {
        ...state,
        productLoading: false
      };
    });
    builder.addCase(registerMeasuresProduct.fulfilled, (state, action) => {
      return {
        ...state,
        registerInfo: action.payload,
        registerLoading: false
      };
    });
    builder.addCase(registerMeasuresProduct.pending, (state, action) => {
      return {
        ...state,
        registerLoading: true
      };
    });
    builder.addCase(registerMeasuresProduct.rejected, (state, action) => {
      return {
        ...state,
        registerLoading: false
      };
    });
    builder.addCase(deleteMeasuresProduct.fulfilled, (state, action) => {
      return {
        ...state,
        productLoading: false
      };
    });
    builder.addCase(deleteMeasuresProduct.pending, (state, action) => {
      return {
        ...state,
        productLoading: true
      };
    });
    builder.addCase(deleteMeasuresProduct.rejected, (state, action) => {
      return {
        ...state,
        productLoading: false
      };
    });
    builder.addCase(searchMeasuresProduct.fulfilled, (state, action) => {
      return {
        ...state,
        searchResult: action.payload.result,
        resultLength: action.payload.result_length,
        searchLoading: false
      };
    });
    builder.addCase(searchMeasuresProduct.pending, (state, action) => {
      return {
        ...state,
        searchLoading: true
      };
    });
    builder.addCase(searchMeasuresProduct.rejected, (state, action) => {
      return {
        ...state,
        searchLoading: false
      };
    });
  }
});

export const {
  setRegisterOpen,
  setRegisterSection,
  setDetailOpen,
  setDetailClose,
  setItemDetailOpen,
  resetSearchResult,
  resetAmazonOwnSalesGraph,
  resetRakutenOwnSalesGraph,
  resetYahooOwnSalesGraph
} = measureSlice.actions;
export const measureSection = (state) => state.measure.registerSection;
export const registerOpen = (state) => state.measure.registerOpen;
export const detailOpen = (state) => state.measure.detailOpen;
export const measureDetailSection = (state) => state.measure.detailSection;
export const measureDetailTerm = (state) => state.measure.detailTerm;
export const measureDetail = (state) => state.measure.detail;
export const measuresProductList = (state) => state.measure.registeredProduct;
export const measuresSearchResult = (state) => state.measure.searchResult;
export const measuresSearchResultLength = (state) => state.measure.resultLength;
export const register_info = (state) => state.measure.registerInfo;
export const ownSalesGraphdata = (state) => state.measure.ownSalesGraphdata;
export const otherSalesGraphdata = (state) => state.measure.otherSalesGraphdata;
export const getMeasuresProductLoading = (state) =>
  state.measure.productLoading;
export const registerMeasuresProductLoading = (state) =>
  state.measure.registerLoading;
export const searchMeasuresProductLoading = (state) =>
  state.measure.searchLoadingLoading;
export const ownSalesLoading = (state) => state.measure.isLoading;

export default measureSlice.reducer;
