/* eslint-disable*/
import { Box, Typography } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { currentchannel } from 'feactures/channel/channelSlice';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const StickyAnnotation = () => {
  const location = useLocation();
  const [content, setContent] = useState('');
  const [isOpen, setIsOpen] = useState(true);
  const channel = useSelector(currentchannel);

  useEffect(() => {
    switch (location.pathname) {
      case '/app/monitoring':
        setContent(
          '・表示速度が遅い場合がありますがご了承ください。(4月中旬解消予定)\n・グループ編集後に即時反映されないことがあります。(解消時期未定)'
        );
        break;
      case '/app/monitoring/asin':
        setContent(
          '楽天 > KPIビュー > 商品別データが表示されていません。 (解消時期未定) '
        );
        break;
      case '/app/numberviews':
        if (channel == 1) {
          setContent(
            '・掲載順位（自然検索）、掲載順位（スポンサー）は現在レポートが正常に取得出来ない問題が発生しております。それに伴い、新規のキーワード登録を一時的に停止をさせて頂いております。\n※解消に向けて動いておりますが、現時点で目途は未定となります。'
          );
        }
        break;
      case '/app/keywords/detail':
        if (channel == 1) {
          setContent(
            '指名検索数の登録時に、2回連続で登録ができない事象が発生しています。一度リロードすると、登録が可能です。（解消時期未定）'
          );
        }
        break;
      case '/app/asin':
        setContent(
          '表示速度が遅い場合がありますがご了承ください。(4月中旬解消予定)'
        );
        break;
      case '/app/newcategoryboard':
        if (channel == 2) {
          setContent(
            '楽天マーケットビューのデータは、毎月中旬に更新されます。'
          );
        }
        break;
      case '/app/brandanalysis':
        if (channel == 2) {
          setContent(
            '楽天マーケットビューのデータは、毎月中旬に更新されます。'
          );
        }
        break;
      case '/app/brandcomparison':
        if (channel == 2) {
          setContent(
            '楽天マーケットビューのデータは、毎月中旬に更新されます。'
          );
        }
        break;
      case '/app/otherproductboard':
        if (channel == 2) {
          setContent(
            '楽天マーケットビューのデータは、毎月中旬に更新されます。'
          );
        }
        break;
      // 削除済機能↓
      // case '/app/ownproductboard':
      //   if (channel == 1) {
      //     setContent(
      //       '・推定値計算のロジックの関係で［単価×個数］が［売上］と完全一致しない場合があります。\n\n※売上は予測値であり、精度は100%ではありません。今後、精度は段階的にアップデートされます。'
      //     );
      //   }
      //   break;
      // case '/app/category1/sales':
      //   if (channel == 1) {
      //     setContent(
      //       '・推定値計算のロジックの関係で［単価×個数］が［売上］と完全一致しない場合があります。\n\n※売上は予測値であり、精度は100%ではありません。今後、精度は段階的にアップデートされます。'
      //     );
      //   } else if (channel == 2) {
      //     setContent(
      //       '・推定値計算のロジックの関係で［単価×個数］が［売上］と完全一致しない場合があります。\n\n※売上は予測値であり、精度は100%ではありません。今後、精度は段階的にアップデートされます。'
      //     );
      //   }
      //   break;
      default:
        break;
    }
  }, [location.pathname, channel]);

  const handleClick = () => {
    setIsOpen(false);
  };

  return (
    <Box sx={{ pt: 1 }}>
      {isOpen && (
        <Box
          py={1}
          px={2}
          mb={1}
          // border={3}
          // borderColor="#ED7D31"
          borderRadius={2}
          bgcolor="rgba(244,204,204,1)"
          display="flex"
          justifyContent="space-between"
        >
          <Typography variant="caption" whiteSpace="pre-wrap" color="#990000">
            {content}
          </Typography>
          <CloseIcon
            onClick={handleClick}
            sx={{
              alignSelf: 'flex-start',
              cursor: 'pointer',
              color: '#990000'
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default StickyAnnotation;
