/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';
import { amber, teal, indigo, cyan } from '@mui/material/colors';

export const fetchAsyncAdvertiseData = createAsyncThunk(
  'advertise/post',
  async (arg, thunkAPI) => {
    const { graphTitle } = arg;
    const { term, start, end } = await thunkAPI.getState().date;
    const { channel, channelName, benchmark } =
      thunkAPI.getState().currentchannel;
    const { checkGroupList } = thunkAPI.getState().group;
    const { accountId, accountName, userId, isLoggedIn, userName } =
      thunkAPI.getState().user;
    const res = await client.post(`${apiUrl}advertise`, {
      start: start,
      end: end,
      term: term,
      channel: channel,
      channel_name: channelName,
      graph_name: graphTitle,
      account_id: accountId,
      group_list: checkGroupList,
      comparing_type: Number(benchmark)
    });
    return res.data;
  }
);
const fetchAdvertiseSlice = createSlice({
  name: 'advertise',
  initialState: {
    all: { num: 0, rate: 0, graph: [] },
    advertiseData: {
      sp: {
        num: 0,
        rate: 0,
        graph: [],
        name: 'スポンサープロダクト',
        color: teal[700]
      },
      sb: {
        num: 0,
        rate: 0,
        graph: [],
        name: 'スポンサーブランド',
        color: indigo[400]
      },
      sd: {
        num: 0,
        rate: 0,
        graph: [],
        name: '商品ディスプレイ',
        color: cyan[300]
      },
      dsp: {
        num: 0,
        rate: 0,
        graph: [],
        name: 'DSP',
        color: amber[700]
      }
    }
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAsyncAdvertiseData.fulfilled, (state, action) => {
      state.all.num = action.payload.all.num;
      state.all.rate = action.payload.all.rate;
      state.all.graph = action.payload.all.graph;

      const s = state.advertiseData;
      const r = action.payload.advertise;
      s.sp.num = r.sp.num;
      s.sb.num = r.sb.num;
      s.sd.num = r.sd.num;
      s.dsp.num = r.dsp.num;
      s.sp.rate = r.sp.rate;
      s.sb.rate = r.sb.rate;
      s.sd.rate = r.sd.rate;
      s.dsp.rate = r.dsp.rate;
      s.sp.graph = r.sp.graph;
      s.sb.graph = r.sb.graph;
      s.sd.graph = r.sd.graph;
      s.dsp.graph = r.dsp.graph;
      state.isLoading = false;
    });
    builder.addCase(fetchAsyncAdvertiseData.pending, (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    });
    builder.addCase(fetchAsyncAdvertiseData.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    });
  }
});

export const advertiseData = (state) => state.advertise.advertiseData;
export const advertiseAllData = (state) => state.advertise.all;
export const advertiseStatus = (state) => state.advertise.isLoading;

export default fetchAdvertiseSlice.reducer;
