/* eslint-disable*/
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useEffect, useState, createContext, useRef } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { styled } from '@mui/material/styles';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  InputBase,
  FormHelperText,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Typography,
  Snackbar,
  Divider
} from '@material-ui/core';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import {
  colors,
  CircularProgress
} from '@material-ui/core'; /* eslint-disable*/
import { fetchChangePass } from 'feactures/user/userSlice';
import { userinfo, fetchChangeRmsKey } from 'feactures/user/userSlice';
import {
  rmsKeyInfo,
  fetchRmsAuthCheck,
  fetchRmsAuthScraping,
  resetRmsStatus,
  rmsAuthStatus
} from 'feactures/user/rmsSlice';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useUpdatedRef } from 'rsuite/esm/utils';
import { store } from 'app/store';

const SettingRms = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const user = useSelector(userinfo);
  const rmsKeyInfos = useSelector(rmsKeyInfo);
  // const rmsAuthCheckStatus = useSelector(rmsAuthStatus);
  // console.log(`${rmsAuthCheckStatus}セレクター`);

  const [err, setErrMessage] = useState(false); //登録できなかったときのエラー
  const [authloading, setAuthloading] = useState(false);

  // const [checkStatus, setCheckStatus] = useState(rmsAuthCheckStatus);
  const { rmsAuthCheckStatus } = store.getState().rmsKeyStatus;

  // refの定義
  const refRmsStatus = useRef(rmsAuthCheckStatus);
  // redux stateが更新されたらrefも更新するようにする
  useEffect(() => {
    refRmsStatus.current = rmsAuthCheckStatus;
  }, [rmsAuthCheckStatus]);

  const snackbarHandleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setErrMessage(false);
  };

  const modoru = () => {
    navigate(-1);
  };

  // useEffect(() => {
  //   //すでに登録済みのキーで初回のみチェック
  //   dispatch(
  //     fetchRmsAuthCheck({
  //       serviceSecret: user.serviceSecret,
  //       licenseKey: user.licenseKey,
  //       rCsvStatus: String(user.rCsvStatus),
  //       rLoginId: user.rLoginId,
  //       rLoginPass: user.rLoginPass
  //     })
  //   );
  // }, [dispatch]);

  const CustomInput = (props) => {
    const { value, item, onChange, name, touched, errors, onBlur, readOnly } =
      props;
    return (
      <Box display="flex" alignItems="center" mb={1}>
        <Box flex="1 1 auto" maxWidth="370px">
          <InputBase
            sx={{
              pl: 2,
              backgroundColor: colors.grey[300]
            }}
            fullWidth
            htmlFor="component-outlined"
            value={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            readOnly={readOnly}
          />
          {Boolean(touched[name]) && errors[name] ? (
            <FormHelperText error={true}>{errors[name]}</FormHelperText>
          ) : null}
        </Box>
      </Box>
    );
  };
  // //APIキー情報認証チェック
  // const authCheckClick = async (values) => {
  //   console.log('認証押下');
  //   // console.log(values);
  //   await dispatch(fetchRmsAuthCheck(values))
  //     .then(unwrapResult)
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  let stopCheckStataus;
  //R-login共通ID＆PASS認証チェック（キー情報チェックとは違い、変更時に発火）
  const authScrapingCheckClick = async (values) => {
    // console.log(values);
    await dispatch(fetchRmsAuthScraping(values))
      .then(unwrapResult)
      .then(
        (stopCheckStataus = setInterval(function () {
          dispatch(fetchRmsAuthCheck(values)).then(unwrapResult);
          console.log('dispatch後', refRmsStatus.current);
          if (refRmsStatus.current === true) {
            clearInterval(stopCheckStataus);
          }
        }, 10000))
      )
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = async (values) => {
    await dispatch(fetchChangeRmsKey(values))
      .then(unwrapResult)
      .then(() => {
        // setTimeout(function () {
        // window.scrollTo(0, 0);
        setSuccess(true);
        // }, 1000);
      })
      .catch((err) => {
        // console.log(err);
        setErrMessage(true);
      });
    authScrapingCheckClick(values);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#FFF8E5',
          height: 'auto',
          minHeight: '100vh',
          width: '100%',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Container sx={{ width: 'auto', py: 3 }}>
          <Typography fontSize="16px" fontWeight="bold">
            楽天RMS連携設定
          </Typography>
          {/* <Typography color="textSecondary" gutterBottom variant="body2">
            楽天RMSアカウントをお持ちの方はRMS
            WEBAPIのサービスシークレットとライセンスキーを入力してください
            <br />
            　*印は必須項目です
          </Typography> */}
          <Formik
            initialValues={{
              serviceSecret: user.serviceSecret ?? '',
              licenseKey: user.licenseKey ?? '',
              rLoginId: user.rLoginId ?? '',
              rLoginPass: user.rLoginPass ?? '',
              rCsvStatus: String(user.rCsvStatus),
              status: user.userStatus ?? ''
            }}
            validationSchema={Yup.object().shape({
              // serviceSecret: Yup.string()
              //   .typeError('半角英数字で入力してください')
              //   .min(0, '0文字以上の英数字を入れてください')
              //   .required('必須項目です'),
              // licenseKey: Yup.string()
              //   .typeError('半角英数字で入力してください')
              //   .min(0, '0文字以上の英数字を入れてください')
              //   .required('必須項目です'),
              rLoginId: Yup.string()
                .typeError('半角英数字で入力してください')
                .min(0, '0文字以上の英数字を入れてください')
                .notOneOf(
                  ['NULL', 'null'],
                  'エラー：この文字列は登録できません'
                )
                .matches(/^\S*$/, 'スペースは入力できません')
                .required('必須項目です'),
              rLoginPass: Yup.string()
                .typeError('半角英数字で入力してください')
                .min(0, '0文字以上の英数字を入れてください')
                .notOneOf(
                  ['NULL', 'null'],
                  'エラー：この文字列は登録できません'
                )
                .matches(/^\S*$/, 'スペースは入力できません')
                .required('必須項目です')
              // rCsvStatus: Yup.string().required('必須項目です')
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              setFieldValue,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              resetForm
            }) => (
              <form onSubmit={handleSubmit}>
                <Card>
                  <CardContent
                    sx={{
                      px: 5
                    }}
                  >
                    <Box my={2}>
                      <Typography
                        paragraph={true}
                        fontWeight="bold"
                        fontSize="13px"
                        sx={{ textDecoration: 'underline', mb: 0 }}
                      >
                        楽天 | R-login共通ID＆PASS
                      </Typography>
                      <FormHelperText error sx={{ fontWeight: 'bold' }}>
                        ※付与していただいた権限以外の情報は確認できませんのでご安心ください
                      </FormHelperText>
                      ・ID*
                      <CustomInput
                        id={'rLoginId'}
                        name={'rLoginId'}
                        value={values.rLoginId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        // readOnly={values.status == 1 ? false : true}
                        readOnly={!rmsAuthCheckStatus}
                      />
                      ・Pass*
                      <CustomInput
                        id={'rLoginPass'}
                        name={'rLoginPass'}
                        value={values.rLoginPass}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        // readOnly={values.status == 1 ? false : true}
                        readOnly={!rmsAuthCheckStatus}
                      />
                    </Box>
                  </CardContent>
                </Card>
                {!rmsAuthCheckStatus && (
                  <Box textAlign="center" mt={3}>
                    <Typography color="red">
                      「R-login共通ID&PASSの認証」を行っています。
                      <br />
                      認証完了後、再度変更が可能になります。
                      <br />
                      *通常は約10秒で完了します。
                    </Typography>
                  </Box>
                )}
                {values.status == 1 ? (
                  <Box
                    sx={{ py: 4, display: 'flex', justifyContent: 'center' }}
                  >
                    <Button
                      sx={{ mr: 2, height: '36px' }}
                      type="reset"
                      variant="outlined"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        resetForm();
                      }}
                    >
                      キャンセル
                    </Button>
                    <Button
                      sx={{ width: '150px', height: '36px' }}
                      color="primary"
                      size="midium"
                      // disabled={isSubmitting}
                      disabled={
                        !values.rLoginId ||
                        values.rLoginId === 'NULL' ||
                        values.rLoginId === 'null' ||
                        values.rLoginId.includes(' ') ||
                        values.rLoginId.includes('　') ||
                        !values.rLoginPass ||
                        values.rLoginPass === 'NULL' ||
                        values.rLoginPass === 'null' ||
                        values.rLoginPass.includes(' ') ||
                        values.rLoginPass.includes('　') ||
                        !values.rLoginPass ||
                        !rmsAuthCheckStatus
                      }
                      type="button"
                      variant="contained"
                      onClick={() => {
                        const err = Object.keys(errors);
                        if (err.length) {
                          const input = document.querySelector(
                            `input[name=${err[0]}]`
                          );

                          input.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                            inline: 'start'
                          });
                        } else {
                          onSubmit(values);
                        }
                      }}
                    >
                      {isSubmitting ? (
                        <CircularProgress size={22} />
                      ) : (
                        '変更を保存する'
                      )}
                    </Button>
                    <br />
                  </Box>
                ) : null}
              </form>
            )}
          </Formik>
          <Typography color="textSecondary" variant="body1">
            <button onClick={() => modoru()}>←戻る</button>
          </Typography>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={success || err}
            severity={success ? 'success' : 'error'}
            autoHideDuration={4000}
            onClose={snackbarHandleClose}
            message={err ? '変更できませんでした' : '利用情報を変更しました'}
          />
        </Container>
      </Box>
    </>
  );
};

export default SettingRms;
