/* eslint-disable */
//CVR画面で表示するグラフ数値を受け取る
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const fetchCVRPost = createAsyncThunk(
  'cvr/post',
  async (arg, thunkAPI) => {
    const { term, start, end } = await thunkAPI.getState().date;
    const { userId } = thunkAPI.getState().user;
    const { channel, benchmark } = thunkAPI.getState().currentchannel;
    const { checkGroupList } = thunkAPI.getState().group;

    const res = await client.post(`${apiUrl}cvr`, {
      start: start,
      end: end,
      term: term,
      channel: channel, //検討
      user_id: userId,
      group_list: checkGroupList,
      comparing_type: Number(benchmark)
    });
    // console.log(res.data);
    return res.data;
  }
);

const CVRSlice = createSlice({
  name: 'cvr',
  initialState: {
    priceDiscountRate: [],
    imageNum: [],
    reviewNum: [],
    reviewScore: [],
    aPlus: [],
    withdrawalRate: [],
    deliveryDays: [],
    variation: [],
    rate: [],
    num: [],

    //競合
    g_priceDiscountRate: [],
    g_imageNum: [],
    g_reviewNum: [],
    g_reviewScore: [],
    g_aPlus: [],
    g_withdrawalRate: [],
    g_deliveryDays: [],
    g_variation: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCVRPost.fulfilled, (state, action) => {
      return {
        ...state,
        priceDiscountRate: action.payload.cvr.priceDiscountRate,
        imageNum: action.payload.cvr.imageNum,
        reviewNum: action.payload.cvr.reviewNum,
        reviewScore: action.payload.cvr.reviewScore,
        aPlus: action.payload.cvr.aPlus,
        withdrawalRate: action.payload.cvr.withdrawalRate,
        deliveryDays: action.payload.cvr.deliveryDays,
        variation: action.payload.cvr.variation,

        //競合
        g_priceDiscountRate: action.payload.group_cvr.priceDiscountRate,
        g_imageNum: action.payload.group_cvr.imageNum,
        g_reviewNum: action.payload.group_cvr.reviewNum,
        g_reviewScore: action.payload.group_cvr.reviewScore,
        g_aPlus: action.payload.group_cvr.aPlus,
        g_withdrawalRate: action.payload.group_cvr.withdrawalRate,
        g_deliveryDays: action.payload.group_cvr.deliveryDays,
        g_variation: action.payload.cvr.group_variation,

        rate: action.payload.cvr.rate,
        num: action.payload.cvr.num,
        isLoading: false,
        error: false
      };
    });
    builder.addCase(fetchCVRPost.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        error: false
      };
    });
  }
});

export const priceDiscountRateGraph = (state) => state.cvr.priceDiscountRate;
export const imageNumGraph = (state) => state.cvr.imageNum;
export const reviewNumGraph = (state) => state.cvr.reviewNum;
export const reviewScoreGraph = (state) => state.cvr.reviewScore;
export const aPlusGraph = (state) => state.cvr.aPlus;
export const withdrawalRateGraph = (state) => state.cvr.withdrawalRate;
export const deliveryDaysGraph = (state) => state.cvr.deliveryDays;
export const variationGraph = (state) => state.cvr.variation;

export const groupPriceDiscountRateGraph = (state) =>
  state.cvr.g_priceDiscountRate;
export const groupImageNumGraph = (state) => state.cvr.g_imageNum;
export const groupReviewNumGraph = (state) => state.cvr.g_reviewNum;
export const groupReviewScoreGraph = (state) => state.cvr.g_reviewScore;
export const groupAPlusGraph = (state) => state.cvr.g_aPlus;
export const groupWithdrawalRateGraph = (state) => state.cvr.g_withdrawalRate;
export const groupDeliveryDaysGraph = (state) => state.cvr.g_deliveryDays;
export const groupVariationGraph = (state) => state.cvr.g_variation;

export const cvrrate = (state) => state.cvr.rate;
export const cvrnum = (state) => state.cvr.num;
export const cvrStatus = (state) => state.cvr.isLoading;

export default CVRSlice.reducer;
