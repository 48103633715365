/* eslint-disable */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as moment from 'moment/moment';
import 'moment/locale/ja';
import { Today } from '@material-ui/icons';
// import { apiUrl } from 'App.js';

//今日
const today = moment().format('YYYY-MM-DD');
//昨日
const yesterday = moment().add(-1, 'days').format('YYYY-MM-DD');
//昨日から30日前
const thirtyDaysAgo = moment(yesterday)
  .subtract(30, 'days')
  .format('YYYY-MM-DD');
//当月初
const monthFirstday = moment()
.add('months')
.startOf('month')
.format('YYYY-MM-DD');
//当月末
const monthLastday = moment().add('months').endOf('month').format('YYYY-MM-DD');

const initialState = {
  alignment: 'month',
  startDate: monthFirstday,
  endDate: monthLastday
};

const selectAsinDate = createSlice({
  name: 'asinDate',
  initialState: initialState,
  reducers: {
    changeAsinTerm(state, action) {
      // console.log(action.payload.alignment);
      return {
        ...state,
        alignment: action.payload.alignment,
        startDate: action.payload.start,
        endDate: action.payload.end
      };
    },
    resetAsinTerm(state, action) {
      return initialState;
    }
  }
});

export const selectAsinStartDate = (state) => state.asinDate.startDate;
export const selectAsinEndDate = (state) => state.asinDate.endDate;
export const selectAsinAlignment = (state) => state.asinDate.alignment;

export const { changeAsinTerm, resetAsinTerm } = selectAsinDate.actions;

export default selectAsinDate.reducer;
