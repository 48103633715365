/* eslint-disable*/
import { Typography } from '@material-ui/core';
import { otherSelectedBrand } from 'feactures/brand/brandSlice';
import {
  selectAmazonCategoryGraph,
  selectRakutenCategoryGraph,
  selectYahooCategoryGraph
} from 'feactures/category/categoryGraphSlice';
import { currentchannel } from 'feactures/channel/channelSlice';
import { useSelector } from 'react-redux';

const BrandItemShareLabel = () => {
  /* eslint-disable*/
  const channel = useSelector(currentchannel);
  const selectBrand = useSelector(otherSelectedBrand);

  const amazonCate = useSelector(selectAmazonCategoryGraph);
  const rakutenCate = useSelector(selectRakutenCategoryGraph);
  const yahooCate = useSelector(selectYahooCategoryGraph);

  let label = '';

  if (channel === 1) {
    if (selectBrand.amazon === '') {
      label = 'ブランドが選択されていません';
    } else {
      label = selectBrand.amazon.brand;
    }
  } else if (channel === 2) {
    if (selectBrand.rakuten === '') {
      label = '店舗が選択されていません';
    } else {
      label = selectBrand.rakuten.brand;
    }
  } else if (channel === 3) {
    if (selectBrand.yahoo === '') {
      label = 'ブランドが選択されていません';
    } else {
      label = selectBrand.yahoo.brand;
    }
  }

  return (
    <>
      <Typography fontSize="14px" fontWeight="bold">
        商品別売上シェア：{label}
      </Typography>
    </>
  );
};

export default BrandItemShareLabel;
