/* eslint-disable*/
import {
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { userinfo } from 'feactures/user/userSlice';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  trialRegisterTiming,
  trialMallList
} from 'feactures/plan/premiumRegister';

const SettingPlan = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = useSelector(userinfo);
  const userPlan = userInfo.plan;
  const trial_mall_list = useSelector(trialMallList);

  useEffect(() => {
    dispatch(trialRegisterTiming());
  }, []);

  // 通常の申し込みボタンのURL
  const handleChangePlan = () => {
    window.open('https://www.oxcim.net/plan-change');
  };

  // トライアル期間中の申込ボタンのURL
  const handleChangeTrialPremiumButton = (channel) => {
    if (trial_mall_list) {
      navigate('/new_trial_premium_register');
    } else {
      navigate(`/trial_premium_register/prev?trial=${channel}`);
    }
  };

  const modoru = () => {
    navigate(-1);
  };

  const GoriyouBox = () => (
    <Box
      bgcolor="#FFCFC9"
      color="#B10202"
      width="140px"
      py="6px"
      px="16px"
      margin="auto"
      fontWeight="bold"
      borderRadius={1}
    >
      ご利用中
    </Box>
  );

  const OmousikomityuuBox = () => (
    <Box
      bgcolor="#FFF2CC"
      color="#d15501"
      width="140px"
      py="6px"
      px="16px"
      margin="auto"
      fontWeight="bold"
      borderRadius={1}
    >
      お申込中
    </Box>
  );

  const MibaraiBox = () => (
    <Box
      bgcolor="#FFCFC9"
      color="#B10202"
      width="140px"
      py="6px"
      px="16px"
      margin="auto"
      fontWeight="bold"
      borderRadius={1}
    >
      未払い
    </Box>
  );

  const ChanghePlanButton = () => (
    <Button variant="outlined" onClick={handleChangePlan}>
      プラン変更お申込み
    </Button>
  );

  const ChangeTrialCampaignPremiumButton = ({ channel }) => (
    <Button
      variant="contained"
      color="error"
      sx={{
        bgcolor: '#B10202'
      }}
      onClick={() => {
        handleChangeTrialPremiumButton(channel);
      }}
    >
      特別価格でお申込み
    </Button>
  );

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#fff8e5',
          height: 'auto',
          minHeight: '100vh',
          width: '100%'
          // display: 'flex',
          // alignItems: 'center'
        }}
      >
        <Container sx={{ width: 'auto', py: 3 }}>
          <Typography fontSize="16px" fontWeight="bold">
            プラン
          </Typography>
          <Box mb={3} mt={1}>
            <TableContainer
              sx={{
                boxShadow: 0,
                border: 2,
                borderColor: '#E0E0E0'
              }}
              component={Paper}
            >
              <Table stickyHeader aria-label="a dense table" size="small">
                <TableHead>
                  {/* プラン */}
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        bgcolor: '#F1F5F6',
                        fontWeight: 'bold',
                        fontSize: '14px',
                        width: '200px'
                      }}
                    >
                      プラン
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderLeft: 2,
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        bgcolor: '#E1E6E9',
                        fontWeight: 'bold',
                        fontSize: '14px',
                        width: '200px'
                      }}
                    >
                      お試しプラン
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        bgcolor: '#D6D9DC',
                        fontWeight: 'bold',
                        fontSize: '14px',
                        width: '200px'
                      }}
                    >
                      基本活用プラン
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        bgcolor: '#FEF5EF',
                        fontWeight: 'bold',
                        fontSize: '14px',
                        width: '200px'
                      }}
                    >
                      広告販促おまかせプラン
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        bgcolor: '#FFEBF5',
                        fontWeight: 'bold',
                        fontSize: '14px',
                        width: '200px'
                      }}
                    >
                      戦略パートナープラン
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* 料金 */}
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px',
                        fontWeight: 'bold'
                      }}
                    >
                      <Typography fontSize="20px" fontWeight="bold">
                        料金
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderLeft: 2,
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px',
                        fontWeight: 'bold'
                      }}
                    >
                      <Typography fontSize="20px" fontWeight="bold">
                        無料
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px',
                        fontWeight: 'bold'
                      }}
                    >
                      <Typography fontSize="20px" fontWeight="bold">
                        有料
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px',
                        fontWeight: 'bold'
                      }}
                    >
                      <Typography fontSize="20px" fontWeight="bold">
                        有料
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px',
                        fontWeight: 'bold'
                      }}
                    >
                      <Typography fontSize="20px" fontWeight="bold">
                        有料
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {/* ユーザー数 */}
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      ログイン可能ユーザー数
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderLeft: 2,
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      制限なし
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      制限なし
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      制限なし
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      制限なし
                    </TableCell>
                  </TableRow>
                  {/* KPIビュー */}
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      KPIビュー
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderLeft: 2,
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      〇
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      〇
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      〇
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      〇
                    </TableCell>
                  </TableRow>
                  {/* マーケットビュー */}
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      マーケットビュー
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderLeft: 2,
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      ×
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      〇
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      〇
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      〇
                    </TableCell>
                  </TableRow>
                  {/* 広告・販促施策実行支援 */}
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      広告・販促施策実行支援
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderLeft: 2,
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      ×
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      ×
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      〇
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      〇
                    </TableCell>
                  </TableRow>
                  {/* データ分析・戦略立案支援 */}
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      データ分析・戦略立案支援
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderLeft: 2,
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      ×
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      ×
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      ×
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: 2,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      〇
                    </TableCell>
                  </TableRow>
                  {/* お申し込み */}
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      Amazon
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderLeft: 2,
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      {userPlan.amazon.plan == 'ご利用なし' ||
                      userPlan.amazon.plan == 'トライアルプラン' ? (
                        <GoriyouBox />
                      ) : (
                        <ChanghePlanButton />
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      {userPlan.amazon.plan == 'トライアルプラン' && (
                        <Typography>
                          トライアル中
                          <br />※
                          {userPlan.amazon.end_date
                            .replace('-', '/')
                            .replace('-', '/')}
                          まで
                        </Typography>
                      )}
                      {userPlan.amazon.plan == '基本活用プラン' ? (
                        userPlan.amazon.status == 0 ? (
                          <OmousikomityuuBox />
                        ) : userPlan.amazon.status == -1 ? (
                          <MibaraiBox />
                        ) : (
                          <GoriyouBox />
                        )
                      ) : userPlan.amazon.plan == 'トライアルプラン' ? (
                        <ChangeTrialCampaignPremiumButton channel={'amazon'} />
                      ) : (
                        <ChanghePlanButton />
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      {userPlan.amazon.plan == '広告販促おまかせプラン' ? (
                        userPlan.amazon.status == 0 ? (
                          <OmousikomityuuBox />
                        ) : userPlan.amazon.status == -1 ? (
                          <MibaraiBox />
                        ) : (
                          <GoriyouBox />
                        )
                      ) : (
                        <ChanghePlanButton />
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: 1,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      {userPlan.amazon.plan == '戦略パートナープラン' ? (
                        userPlan.amazon.status == 0 ? (
                          <OmousikomityuuBox />
                        ) : userPlan.amazon.status == -1 ? (
                          <MibaraiBox />
                        ) : (
                          <GoriyouBox />
                        )
                      ) : (
                        <ChanghePlanButton />
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      align="center"
                      sx={{ borderBottom: 0, fontSize: '14px' }}
                    >
                      楽天
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderLeft: 2,
                        borderBottom: 0,
                        borderColor: '#E0E0E0',
                        fontSize: '14px'
                      }}
                    >
                      {userPlan.rakuten.plan == 'ご利用なし' ||
                      userPlan.rakuten.plan == 'トライアルプラン' ? (
                        <GoriyouBox />
                      ) : (
                        <ChanghePlanButton />
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderColor: '#E0E0E0',
                        borderBottom: 0,
                        fontSize: '14px'
                      }}
                    >
                      {userPlan.rakuten.plan == 'トライアルプラン' && (
                        <Typography>
                          トライアル中
                          <br />※
                          {userPlan.rakuten.end_date
                            .replace('-', '/')
                            .replace('-', '/')}
                          まで
                        </Typography>
                      )}
                      {userPlan.rakuten.plan == '基本活用プラン' ? (
                        userPlan.rakuten.status == 0 ? (
                          <OmousikomityuuBox />
                        ) : userPlan.rakuten.status == -1 ? (
                          <MibaraiBox />
                        ) : (
                          <GoriyouBox />
                        )
                      ) : userPlan.rakuten.plan == 'トライアルプラン' ? (
                        <ChangeTrialCampaignPremiumButton channel={'rakuten'} />
                      ) : (
                        <ChanghePlanButton />
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        borderRight: 2,
                        borderColor: '#E0E0E0',
                        borderBottom: 0,
                        fontSize: '14px'
                      }}
                    >
                      {userPlan.rakuten.plan == '広告販促おまかせプラン' ? (
                        userPlan.rakuten.status == 0 ? (
                          <OmousikomityuuBox />
                        ) : userPlan.rakuten.status == -1 ? (
                          <MibaraiBox />
                        ) : (
                          <GoriyouBox />
                        )
                      ) : (
                        <ChanghePlanButton />
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ borderBottom: 0, fontSize: '14px' }}
                    >
                      {userPlan.rakuten.plan == '戦略パートナープラン' ? (
                        userPlan.rakuten.status == 0 ? (
                          <OmousikomityuuBox />
                        ) : userPlan.rakuten.status == -1 ? (
                          <MibaraiBox />
                        ) : (
                          <GoriyouBox />
                        )
                      ) : (
                        <ChanghePlanButton />
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Typography color="textSecondary" variant="body1">
            <button onClick={() => modoru()}>←戻る</button>
          </Typography>
        </Container>
      </Box>
      {/* <PlanChangeModal plan_name={modalPlan} plan_id={modalPlanId} /> */}
    </>
  );
};

export default SettingPlan;
