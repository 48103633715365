/* eslint-disable*/
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { styled } from '@mui/material/styles';
import {
  Paper,
  Card,
  CardContent,
  Box,
  Typography,
  CircularProgress,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  TableSortLabel
} from '@material-ui/core'; /* eslint-disable*/
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  currentchannel,
  benchmark,
  switchBenchmark
} from 'feactures/channel/channelSlice';
import { checkGroup } from 'feactures/monitoring/groupSlice';
import {
  fetchMonitorPost,
  a_asin_summary,
  r_asin_summary,
  y_asin_summary
} from 'feactures/monitoring/monitoringSlice';
import { fetchProcessKPIPost } from 'feactures/monitoring/processKPISlice';
import {
  amazonItems,
  rakutenItems
} from 'components/organisms/content/processkpi/RatingSetting';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    verticalAlign: 'center',
    maxHeight: '20px',
    paddingTop: '1px',
    paddingBottom: '1px',
    fontSize: '11px',
    fontWeight: '600',
    border: '0px',

    // backgroundColor: theme.palette.common.black,
    backgroundColor: '#F2F2F2',
    color: theme.palette.text.secondary
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.text.secondary,
    fontSize: '11px'
  }
}));

const ViewsTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    verticalAlign: 'center',
    maxHeight: '20px',
    paddingTop: '1px',
    paddingBottom: '1px',
    fontSize: '11px',
    border: '0px',
    color: theme.palette.text.secondary,
    backgroundColor: '#FFE2B6'
  }
}));
const CvrTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    verticalAlign: 'center',
    maxHeight: '20px',
    paddingTop: '1px',
    paddingBottom: '1px',
    fontSize: '11px',
    border: '0px',
    backgroundColor: '#F8A8A4',
    color: '#fff'
  }
}));
const PriceTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    verticalAlign: 'center',
    maxHeight: '20px',
    paddingTop: '1px',
    paddingBottom: '1px',
    fontSize: '11px',
    border: '0px',
    backgroundColor: '#C8BAE2',
    color: '#fff'
  }
}));
const SubKPITableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    verticalAlign: 'center',
    maxHeight: '20px',
    paddingTop: '1px',
    paddingBottom: '1px',
    fontSize: '11px',
    fontWeight: '600',
    border: '0px',

    // backgroundColor: theme.palette.common.black,
    backgroundColor: '#FFE2B6',
    color: theme.palette.text.secondary
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.text.secondary,
    fontSize: '11px',
    padding: 0,
    paddingTop: '1px',
    border: 0
  }
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginRight: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, channel, bench } = props;
  const createSortHandler = (property) => (event) => {
    // console.log(event, property);
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <StyledTableCell align="center">No</StyledTableCell>
        <StyledTableCell align="left"></StyledTableCell>
        {/* <StyledTableCell align="center">商品</StyledTableCell> */}
        <StyledTableCell align="center">
          {channel == 1 ? 'ASIN' : '商品管理番号'}
        </StyledTableCell>
        <StyledTableCell align="center">商品タイトル</StyledTableCell>
        <StyledTableCell align="center">
          <TableSortLabel
            style={{ color: 'text.secondary' }}
            active={true}
            direction={order}
            onClick={createSortHandler('sales')}
            IconComponent={ArrowDropDownIcon}
          >
            売上
          </TableSortLabel>
        </StyledTableCell>
        <StyledTableCell align="center">
          {bench == 1 ? '期間比較' : '競合比較'}
        </StyledTableCell>
        <StyledTableCell align="center">閲覧数</StyledTableCell>
        <StyledTableCell align="center">
          {bench == 1 ? '期間比較' : '競合比較'}
        </StyledTableCell>

        <StyledTableCell align="center">CVR</StyledTableCell>
        <StyledTableCell align="center">
          {bench == 1 ? '期間比較' : '競合比較'}
        </StyledTableCell>
        <StyledTableCell align="center">販売単価</StyledTableCell>
        <StyledTableCell align="center">
          {bench == 1 ? '期間比較' : '競合比較'}
        </StyledTableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired
};

//table body （親テーブルのheadと子テーブル）
function Row(props) {
  const { row, bench, index, rateItems } = props;
  const [checked, setChecked] = useState(false);

  let asinChildRowArr = [];
  const subKPITitleArr = Object.keys(row.subKPI);
  subKPITitleArr.map((t, index) => {
    const asinInfo = {};
    asinInfo['kpi'] = rateItems[t].kpi;
    asinInfo['title'] = rateItems[t].jaTitle;
    asinInfo['sihyou'] = rateItems[t].sihyou;
    asinInfo['tanni'] = rateItems[t].tanni;
    asinInfo['num'] = row.subKPI[t].num;
    asinInfo['rate'] = row.subKPI[t].rate;
    asinInfo['pre'] = row.subKPI[t].pre;
    asinChildRowArr.push(asinInfo);
  });

  let viewsArr = asinChildRowArr.filter(function (asinRow) {
    return asinRow.kpi == '閲覧数';
  });
  let cvrArr = asinChildRowArr.filter(function (asinRow) {
    return asinRow.kpi == 'CVR';
  });
  let priceArr = asinChildRowArr.filter(function (asinRow) {
    return asinRow.kpi == '販売価格';
  });

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <>
      {/* <TableHead> */}
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset' } }}
        key={index}
        border={0}
      >
        <StyledTableCell align="center">{index + 1}</StyledTableCell>
        <StyledTableCell align="left" sx={{ p: '0px' }}>
          <ExpandMore
            expand={checked}
            onClick={handleChange}
            aria-expanded={checked}
            aria-label="show more"
            sx={{ alignSelf: 'flex-end' }}
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </StyledTableCell>
        {/* <StyledTableCell align="center">
          {row.image}
          noimage
          <img src={url + row.image} width="20%" height="20%" />
        </StyledTableCell> */}
        <StyledTableCell align="center">{row.asin}</StyledTableCell>
        <StyledTableCell align="center">
          <Box
            sx={{
              maxHeight: '30px',
              maxWidth: '115px',
              fontSize: '10px',
              display: 'inline-block'
            }}
            component="div"
            textOverflow="ellipsis"
            overflow="hidden"
            bgcolor="background.paper"
          >
            {row.title}
          </Box>
        </StyledTableCell>

        <StyledTableCell align="center">
          ￥{row.sales.toLocaleString()}
        </StyledTableCell>
        <StyledTableCell align="center">
          <Box display="flex" flexWrap="wrap" flexDirection="column">
            <Typography
              m={0.2}
              p={0.2}
              px={1}
              variant="h6"
              color={row.salesRate > 0 ? '#4BACC6' : '#D02C3A'}
            >
              {row.salesRate > 0 && '+'}
              {row.salesRate}%
            </Typography>
            ({bench != 2 ? '前期間 ' : '競合 '} ￥
            {row.preSales.toLocaleString()})
          </Box>
        </StyledTableCell>
        <StyledTableCell align="center">
          {row.views.toLocaleString()}
        </StyledTableCell>
        <StyledTableCell align="center">
          <Box display="flex" flexWrap="wrap" flexDirection="column">
            <Typography
              m={0.2}
              p={0.2}
              px={1}
              variant="h6"
              color={row.viewsRate > 0 ? '#4BACC6' : '#D02C3A'}
            >
              {row.viewsRate > 0 && '+'}
              {row.viewsRate}%
            </Typography>
            ({bench != 2 ? '前期間 ' : '競合 '} {row.preViews.toLocaleString()})
          </Box>
        </StyledTableCell>
        <StyledTableCell align="center">{row.cvr}</StyledTableCell>
        <StyledTableCell align="center">
          <Box display="flex" flexWrap="wrap" flexDirection="column">
            <Typography
              m={0.2}
              p={0.2}
              px={1}
              variant="h6"
              color={row.cvrRate > 0 ? '#4BACC6' : '#D02C3A'}
            >
              {row.cvrRate > 0 && '+'}
              {row.cvrRate}%
            </Typography>
            ({bench != 2 ? '前期間 ' : '競合 '} {row.preCvr}%)
          </Box>
        </StyledTableCell>
        <StyledTableCell align="center">
          ￥{row.price.toLocaleString()}
        </StyledTableCell>
        <StyledTableCell align="center">
          <Box display="flex" flexWrap="wrap" flexDirection="column">
            <Typography
              m={0.2}
              p={0.2}
              px={1}
              variant="h6"
              color={row.priceRate > 0 ? '#4BACC6' : '#D02C3A'}
            >
              {row.priceRate > 0 && '+'}
              {row.priceRate}%
            </Typography>
            ({bench != 2 ? '前期間' : '競合'} ￥{row.prePrice.toLocaleString()})
          </Box>
        </StyledTableCell>
      </TableRow>
      <TableRow sx={{ verticalAlign: 'inherit' }}>
        {/* <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}> */}
        <TableCell style={{ padding: 0, paddingRight: '1px' }} colSpan={6}>
          <Collapse in={checked} timeout="auto" unmountOnExit>
            <Table
              stickyHeader
              size="small"
              aria-label="purchases"
              sx={{ minWidth: 'max-content' }}
            >
              <TableHead>
                <TableRow>
                  <ViewsTableCell align="center">閲覧数 Sub KPI</ViewsTableCell>
                  <ViewsTableCell align="center">Sub KPI指標</ViewsTableCell>
                  <ViewsTableCell align="center">
                    {bench == 1 ? '期間比較' : '競合比較'}
                  </ViewsTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {viewsArr.map((ch_row, index) => {
                  return (
                    <TableRow key={index}>
                      <SubKPITableCell align="center">
                        {ch_row.title}
                      </SubKPITableCell>
                      <SubKPITableCell>
                        {ch_row.sihyou}
                        <Typography fontSize="13px" component="span" mx="2px">
                          {ch_row.num.toLocaleString()}
                        </Typography>
                        {ch_row.tanni}
                      </SubKPITableCell>
                      <SubKPITableCell align="center">
                        <Box
                          display="flex"
                          flexWrap="wrap"
                          alignItems="baseline"
                        >
                          <Typography
                            m={0.2}
                            p={0.2}
                            px={1}
                            variant="h6"
                            color={ch_row.rate > 0 ? '#4BACC6' : '#D02C3A'}
                          >
                            {ch_row.rate > 0 && '+'}
                            {ch_row.rate}%
                          </Typography>
                          ({bench != 2 ? '前期間 ' : '競合 '}
                          {ch_row.pre.toLocaleString()}
                          {ch_row.tanni})
                        </Box>
                      </SubKPITableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
        <TableCell style={{ padding: 0, paddingRight: '1px' }} colSpan={4}>
          <Collapse in={checked} timeout="auto" unmountOnExit>
            <Table
              stickyHeader
              size="small"
              aria-label="purchases"
              sx={{ minWidth: 'max-content' }}
            >
              <TableHead>
                <TableRow>
                  <CvrTableCell align="center">CVR Sub KPI</CvrTableCell>
                  <CvrTableCell align="center">Sub KPI指標</CvrTableCell>
                  <CvrTableCell align="center" sx={{ borderRight: '2px' }}>
                    {bench == 1 ? '期間比較' : '競合比較'}
                  </CvrTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cvrArr.map((ch_row, index) => {
                  return (
                    <TableRow key={index}>
                      <SubKPITableCell align="center">
                        {ch_row.title}
                      </SubKPITableCell>
                      <SubKPITableCell>
                        {ch_row.sihyou}
                        <Typography fontSize="13px" component="span" mx="2px">
                          {ch_row.num}
                        </Typography>
                        {ch_row.tanni}
                      </SubKPITableCell>
                      <SubKPITableCell align="center">
                        <Box
                          display="flex"
                          flexWrap="wrap"
                          alignItems="baseline"
                        >
                          <Typography
                            m={0.2}
                            p={0.2}
                            px={1}
                            variant="h6"
                            color={ch_row.rate > 0 ? '#4BACC6' : '#D02C3A'}
                          >
                            {ch_row.rate > 0 && '+'}
                            {ch_row.rate}%
                          </Typography>
                          ({bench != 2 ? '前期間 ' : '競合 '}
                          {ch_row.pre.toLocaleString()}
                          {ch_row.tanni})
                        </Box>
                      </SubKPITableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
        <TableCell style={{ padding: 0 }} colSpan={3}>
          <Collapse in={checked} timeout="auto" unmountOnExit>
            <Table
              stickyHeader
              size="small"
              aria-label="purchases"
              sx={{ minWidth: 'max-content' }}
            >
              <TableHead>
                <TableRow>
                  <PriceTableCell align="center">
                    販売価格 Sub KPI
                  </PriceTableCell>
                  <PriceTableCell align="center">Sub KPI指標</PriceTableCell>
                  <PriceTableCell align="center">
                    {bench == 1 ? '期間比較' : '競合比較'}
                  </PriceTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {priceArr.map((ch_row, index) => {
                  return (
                    <TableRow key={index}>
                      <SubKPITableCell align="center">
                        {ch_row.title}
                      </SubKPITableCell>
                      <SubKPITableCell align="center">
                        {ch_row.sihyou}
                        <Typography fontSize="13px" component="span" mx="2px">
                          {ch_row.num}
                        </Typography>
                        {ch_row.tanni}
                      </SubKPITableCell>
                      <SubKPITableCell align="center">
                        <Box
                          display="flex"
                          flexWrap="wrap"
                          alignItems="baseline"
                        >
                          <Typography
                            m={0.2}
                            p={0.2}
                            px={1}
                            variant="h6"
                            color={ch_row.rate > 0 ? '#4BACC6' : '#D02C3A'}
                          >
                            {ch_row.rate > 0 && '+'}
                            {ch_row.rate}%
                          </Typography>
                          ({bench != 2 ? '前期間 ' : '競合 '}
                          {ch_row.pre.toLocaleString()}
                          {ch_row.tanni})
                        </Box>
                      </SubKPITableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const ASINSummaryCollapse = () => {
  const dispatch = useDispatch();
  const channel = useSelector(currentchannel);
  const bench = useSelector(benchmark);
  const amazonAsinSummaries = useSelector(a_asin_summary);
  const rakutenAsinSummaries = useSelector(r_asin_summary);
  const yahooAsinSummaries = useSelector(y_asin_summary);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('sales');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  let ASINSummaryInfo = [];
  let rateItems = [];
  if (channel == 1) {
    ASINSummaryInfo = amazonAsinSummaries;
    rateItems = amazonItems;
  } else if (channel == 2) {
    ASINSummaryInfo = rakutenAsinSummaries;
    rateItems = rakutenItems;
    // } else if (channel == 3) {
    //   ASINSummaryInfo = yahooAsinSummaries;
  }

  // console.log(ASINSummaryInfo);

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          width: '100%',
          overflow: 'auto',
          alignItems: 'center',
          borderRadius: '0px',
          boxShadow: '0'
        }}
      >
        <Table
          aria-label="collapsible table"
          size="small"
          stickyHeader
          sx={{ minWidth: 'max-content', alignItems: 'center' }}
        >
          <EnhancedTableHead
            channel={channel}
            bench={bench}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />

          <TableBody>
            {ASINSummaryInfo.length !== 0 ? (
              // ASINSummaryInfo.map((row, index) => {
              stableSort(ASINSummaryInfo, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <Row
                      bench={bench}
                      index={index}
                      row={row}
                      key={index}
                      rateItems={rateItems}
                    />
                  );
                }
              )
            ) : (
              <TableRow border={0} sx={{ color: 'text.secondary' }}>
                <StyledTableCell>-</StyledTableCell>
                <StyledTableCell align="center">-</StyledTableCell>
                <StyledTableCell align="center">-</StyledTableCell>
                <StyledTableCell align="center">-</StyledTableCell>
                <StyledTableCell>-</StyledTableCell>
                <StyledTableCell>-</StyledTableCell>
                <StyledTableCell>-</StyledTableCell>
                <StyledTableCell align="center">-</StyledTableCell>
                <StyledTableCell align="center">-</StyledTableCell>
                <StyledTableCell>-</StyledTableCell>
                <StyledTableCell>-</StyledTableCell>
                <StyledTableCell align="center">-</StyledTableCell>
                <StyledTableCell align="center">-</StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default ASINSummaryCollapse;
