/* eslint-disable */
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  useTheme,
  colors,
  Typography
} from '@material-ui/core';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useSelector } from 'react-redux';
import { currentmonth } from 'feactures/section/sectionSlice';

Chart.register(ChartDataLabels);

const CategorySharegraph = (props) => {
  const { graphdata } = props; // eslint-disable-line
  const yearmonth = useSelector(currentmonth);
  let monthlySales = 0;
  let calcTotal = 0;
  let tanni = '';
  if (graphdata.total >= 1000000000000) {
    calcTotal = graphdata.total / 1000000000000;
    monthlySales = calcTotal.toFixed(1);
    tanni = '兆円';
  } else if (graphdata.total >= 100000000) {
    monthlySales = Math.round(graphdata.total / 100000000);
    tanni = '億円';
  } else if (graphdata.total >= 10000) {
    monthlySales = Math.round(graphdata.total / 10000);
    tanni = '万円';
  } else if (graphdata.total < 10000) {
    monthlySales = 1;
    tanni = '万円';
  } else if ((graphdata.total = 0)) {
    monthlySales = 0;
    tanni = '円';
  }

  const theme = useTheme();
  const data = {
    datasets: [
      {
        data: graphdata.percentage.filter((num) => num > 0),
        backgroundColor: [
          '#3B5790',
          '#435F9F',
          '#496DB5',
          '#5C80BE',
          '#718CC6',
          '#84A0CE',
          '#92A7D2',
          '#A4B6D9',
          '#B6C4E1',
          '#BBC9E4',
          '#C1CFE7'
        ],
        // borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: graphdata.label
  };
  const sales = graphdata.sales;
  const cumulative = graphdata.cumulative_percentages;
  const checkEvenOrOdd = (number) => {
    if (number % 2 === 0) {
      return false; //偶数
    } else {
      return true; //奇数
    }
  };
  const options = {
    cutoutPercentage: 90,
    layout: { padding: { top: 30, bottom: 30 } },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    height: '100%',
    tooltips: {
      enabled: false
      // backgroundColor: theme.palette.background.paper,
      // bodyFontColor: theme.palette.text.secondary,
      // borderColor: theme.palette.divider,
      // borderWidth: 1,
      // enabled: true,
      // footerFontColor: theme.palette.text.secondary,
      // intersect: false,
      // mode: 'index',
      // titleFontColor: theme.palette.text.primary
    },
    plugins: {
      legend: {
        display: false,
        position: 'bottom' // 凡例の表示位置を設定
      },
      tooltip: {
        // enabled: false,
        // titleFont: {
        //   family: "'M PLUS 1p', sans-serif"
        // },
        // bodyFont: {
        //   family: "'M PLUS 1p', sans-serif"
        // },
        filter: function (item) {
          return item.dataIndex >= 5;
        },
        callbacks: {
          label: function (tooltipItem, y) {
            let data = tooltipItem.raw;
            if (tooltipItem.dataIndex <= 4) {
              return null;
            } else {
              let label = `${graphdata.label[tooltipItem.dataIndex]} ${data}%`;
              return label;
            }
          }
          // label: function (tooltipItem, y) {
          //   let data = tooltipItem.raw;
          //   let label = `${data}%(¥${parseInt(
          //     sales[tooltipItem.dataIndex]
          //   ).toLocaleString()})`;
          //   return label;
          // }
        }
      },
      datalabels: {
        display: true,
        align: 'end',
        anchor: 'end',
        color: theme.palette.text.secondary,
        font: {
          family: "'M PLUS 1p', sans-serif"
          // size: 10
        },
        connectors: {
          color: 'gray'
        },
        formatter: (value, context) => {
          if (context.dataIndex >= 5) {
            return '';
          }
          // ラベルのフォーマットを指定
          // return `${value}%(¥${parseInt(
          //   sales[context.dataIndex]
          // ).toLocaleString()})`;
          return `${graphdata.label[context.dataIndex]} ${value}%`;
        }
        // 代案
        // labels: {
        //   label1: {
        //     align: 'center',
        //     anchor: 'center',
        //     color: '#FFFFFF',
        //     font: {
        //       family: "'M PLUS 1p', sans-serif",
        //       size: 10
        //     },
        //     formatter: function (value, context) {
        //       if (checkEvenOrOdd(context.dataIndex)) {
        //         let gap = value - graphdata.percentage[context.dataIndex + 1];
        //         if (gap <= 0.5) {
        //           return '';
        //         } else {
        //           return `${value}%`;
        //         }
        //       }
        //       return `${value}%`;
        //     }
        //   },
        //   label2: {
        //     align: 'end',
        //     anchor: 'end',
        //     color: theme.palette.text.secondary,
        //     font: {
        //       family: "'M PLUS 1p', sans-serif",
        //       size: 11
        //     },
        //     formatter: function (value, context) {
        //       let label = graphdata.label[context.dataIndex];
        //       if (checkEvenOrOdd(context.dataIndex)) {
        //         let gap = value - graphdata.percentage[context.dataIndex + 1];
        //         if (gap <= 0.5) {
        //           return '';
        //         } else {
        //           if (label.length > 8) {
        //             label = label.slice(0, 8) + '\n' + label.slice(8);
        //             return label;
        //           }
        //           return label;
        //         }
        //       }
        //       if (label.length > 8) {
        //         label = label.slice(0, 8) + '\n' + label.slice(8);
        //         return label;
        //       }
        //       return label;
        //     }
        // }
        // }
      }
    }
  };

  return (
    <Card {...props} sx={{ padding: 0 }}>
      <CardContent
        sx={{
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          ':last-child': { pb: 0 }
        }}
      >
        <Box
          sx={{
            height: 300,
            width: '100%',
            position: 'relative'
          }}
        >
          <Doughnut
            data={data}
            options={options}
            sx={{
              position: 'absolute', // Doughnutのpositionをabsoluteに指定
              top: '50%', // 上から50%の位置
              left: '50%', // 左から50%の位置
              transform: 'translate(-50%, -50%)' // 自身の50%分移動して最終的に中央に配置
            }}
          />
          <Box
            sx={{
              position: 'absolute', // Boxのpositionをabsoluteに指定
              top: '50%', // 上から50%の位置
              left: '50%', // 左から50%の位置
              transform: 'translate(-50%, -50%)', // 自身の50%分移動して最終的に中央に配置
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography fontSize="14px">
              {yearmonth.replace('-', '年')}月
            </Typography>
            <Box display="flex" alignItems="end" justifyContent="center">
              <Typography fontWeight="bold" color="#7288C5" fontSize="20px">
                {monthlySales.toLocaleString()}
              </Typography>
              <Typography fontSize="14px">{tanni}</Typography>
            </Box>
          </Box>
          {/* <canvas ref={categoryRef} id="categoryShare"></canvas> */}
        </Box>
        {/* <Box display="flex" alignItems="center" flexWrap="wrap">
          {data.labels.map((label, index) => (
            <Box key={index} display="flex" alignItems="center">
              <Box
                bgcolor={
                  data.datasets[0].backgroundColor[
                    index % data.datasets[0].backgroundColor.length
                  ]
                }
                height="10px"
                width="35px"
                mx="5px"
              ></Box>
              <Typography fontSize="12px" color={theme.palette.text.secondary}>
                {label}
              </Typography>
            </Box>
          ))}
        </Box> */}
      </CardContent>
    </Card>
  );
};

export default CategorySharegraph;
