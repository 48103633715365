/* eslint-disable  */
import { useEffect, useState, useCallback, useRef } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {
  Link,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  fabClasses
} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { apiUrl } from 'App.js';
import { fetchUpload, uploadStatus } from 'feactures/monitoring/uploadSlice';
import { fetchMonitorPost } from 'feactures/monitoring/monitoringSlice';

import ColorButton from 'components/atoms/button/ColorButton';
import { makeStyles } from '@material-ui/styles';
import { errorResMessage, resetError } from 'feactures/errorSlice';
import { currentchannel } from 'feactures/channel/channelSlice';
import { useLocation } from 'react-router';

const ChannelStatusDialog = () => {
  const [open, setOpen] = useState(true);

  const channel = useSelector(currentchannel);
  const location = useLocation();

  let displayedChannel = '';
  let url = '';
  let pagename = 'KPIビュー';

  if (channel == 1) {
    displayedChannel = 'Amazon';
    url =
      'https://www.oxcim.net/amazon%E3%83%87%E3%83%BC%E3%82%BF%E6%8E%A5%E7%B6%9A%E6%96%B9%E6%B3%95';
  } else if (channel == 2) {
    displayedChannel = '楽天市場';
    url =
      'https://www.oxcim.net/%E6%A5%BD%E5%A4%A9%E3%83%87%E3%83%BC%E3%82%BF%E6%8E%A5%E7%B6%9A';
  } else if (channel == 3) {
    displayedChannel = 'Yahoo!JAPAN';
    url =
      'https://www.oxcim.net/%E3%83%A4%E3%83%95%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%83%E3%83%94%E3%83%B3%E3%82%B0%E3%83%87%E3%83%BC%E3%82%BF%E6%8E%A5%E7%B6%9A';
  }

  if (location.pathname == '/app/overview') {
    pagename = 'オーバービュー';
  }

  return (
    <Box
      open={open}
      // onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ p: 2 }}
    >
      <DialogTitle id="alert-dialog-title" fontSize="30px" textAlign="center">
        {displayedChannel}の{pagename}について
      </DialogTitle>
      <DialogContent sx={{ px: 10 }}>
        <DialogContentText
          id="alert-dialog-description"
          style={{
            whiteSpace: 'pre-line',
            color: '#575757',
            textAlign: 'center',
            fontSize: '20px'
          }}
        >
          KPIビューの閲覧にはAPIの権限設定が必要です。
          <br />
          接続方法は
          <Link target="_blank" href={url} fontSize="20px" underline="hover">
            こちら
          </Link>
          から
          <br />
        </DialogContentText>
      </DialogContent>
    </Box>
  );
};
export default ChannelStatusDialog;
