/* eslint-disable */
import { useEffect, useState, useRef } from 'react';
import { Button, Stack, CssBaseline, Box } from '@material-ui/core';
import {
  registerAllMall,
  registerAmazon,
  registerRakuten,
  registerYahoo,
  currentchannel
} from 'feactures/channel/channelSlice';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchMonitorPost,
  fetchAsinMonitor,
  fetchGroupMonitor
} from 'feactures/monitoring/monitoringSlice';
import { fetchProcessKPIPost } from 'feactures/monitoring/processKPISlice';
import { fetchViewsPost } from 'feactures/monitoring/viewsSlice';
import { fetchCVRPost } from 'feactures/monitoring/CVRSlice';
import { fetchUnitPricePost } from 'feactures/monitoring/unitPriceSlice';
import { fetchAsyncMyproducts } from 'feactures/product/myproductSlice';
import { fetchAsyncOtherproducts } from 'feactures/product/otherproductSlice';
import {
  resetCheckGroup,
  fetchGroupAsinDataList,
  changeGroupDataSearchword,
  changeGroupAsinSearchword,
  resetGroupDataPage,
  resetGroupAsinPage
} from 'feactures/monitoring/groupSlice';
import {
  fetchAsinList,
  fetchAsyncMonitorAsin,
  setDisplayObject
} from 'feactures/monitoring/monitorAsinSlice';
import {
  fetchAsyncAsinSales,
  fetchOverview,
  resetAsinPage,
  setOvAsinSalesDataSearchword,
  setOvAsinSalesDataPerPage
} from 'feactures/overview/overviewSlice';

const KPIButtongroup = (props) => {
  const { amazonOnly, page } = props; //eslint-disable-line
  const [allMall, setAllMall] = useState(true);
  const [amazon, setAmazon] = useState(false);
  const [rakuten, setRakuten] = useState(false);
  const [yahoo, setYahoo] = useState(false);
  const [color0, setColor0] = useState('KPISelected');
  const [color1, setColor1] = useState('KPINoselected');
  const [color2, setColor2] = useState('KPINoselected');
  const [color3, setColor3] = useState('KPINoselected');
  const [variant0, setVariant0] = useState('contained');
  const [variant1, setVariant1] = useState('text');
  const [variant2, setVariant2] = useState('text');
  const [variant3, setVariant3] = useState('text');
  const channel = useSelector(currentchannel);
  const dispatch = useDispatch();

  const dispatchTable = {
    overview: [
      fetchOverview(),
      setOvAsinSalesDataSearchword(''),
      resetAsinPage(),
      setOvAsinSalesDataPerPage(10),
      fetchAsyncAsinSales({
        searchword: ''
      })
    ],
    monitoring: [
      fetchMonitorPost(),
      changeGroupDataSearchword(''),
      resetGroupDataPage(),
      fetchAsyncAsinSales({
        searchword: ''
      })
    ],
    monitoringAsin: [fetchAsinMonitor()],
    monitoringGroup: [
      changeGroupAsinSearchword(''),
      resetGroupAsinPage(),
      fetchGroupMonitor(),
      fetchGroupAsinDataList({ searchword: '' })
    ],
    sales: [fetchAsinList(), fetchAsyncMonitorAsin({ pageNum: 1 })],
    views: [fetchProcessKPIPost(), fetchViewsPost()],
    cvr: [fetchProcessKPIPost(), fetchCVRPost()],
    customerUnitPrice: [fetchProcessKPIPost(), fetchUnitPricePost()],
    categoryTop100: [],
    categoryNo1: [],
    ownProduct: [fetchAsyncMyproducts()],
    otherProduct: [fetchAsyncOtherproducts()],
    categoryNo1: []
  };

  const [searchByUserFirstNamePromise, setSearchByUserFirstNamePromise] =
    useState();

  //1つ前のリクエストをキャンセルしている
  const dispatchHandle = (currentPage) => {
    //dispatchTableの数だけキャンセル処理を回す
    // console.log(searchByUserFirstNamePromise);
    if (searchByUserFirstNamePromise) {
      // searchByUserFirstNamePromise.map((r, index) => {
      // })
      // console.log('キャンセルID', searchByUserFirstNamePromise.requestId);
      if (
        searchByUserFirstNamePromise &&
        typeof searchByUserFirstNamePromise.abort === 'function'
      ) {
        searchByUserFirstNamePromise.abort();
      }
      // searchByUserFirstNamePromise?.abort();
    }
    if (dispatchTable[currentPage].length != 0) {
      //dispatchTableのreducerの数だけdispatchさせる
      [...dispatchTable[currentPage]].map((r, index) => {
        const promise = dispatch(r);
        setSearchByUserFirstNamePromise(promise);
      });
    }
  };

  const changeHandle = (e) => {
    if (e == 0) {
      changeAllMall();
      dispatch(registerAllMall());
    } else if (e == 1) {
      changeAmazon();
      dispatch(registerAmazon());
    } else if (e == 2) {
      changeRakuten();
      dispatch(registerRakuten());
    } else if (e == 3) {
      changeYahoo();
      dispatch(registerYahoo());
    }
  };

  // チャンネルが変わったら発動 初回レンダリングさせない
  const useEffectCustom = (func, dependencyList) => {
    const fisrtFlgRef = useRef(true);

    useEffect(() => {
      changeHandle(channel);

      if (!fisrtFlgRef.current) {
        func();
      } else {
        fisrtFlgRef.current = false;
      }
    }, dependencyList);
  };

  useEffectCustom(() => {
    if (channel == 0) {
      dispatch(resetCheckGroup());
    }
    if (page) {
      // console.log('変わったのでfetch！ページ', page);
      dispatch(fetchAsinList());
      dispatch(setDisplayObject('top10'));
      window.scrollTo(0, 0);
      dispatchHandle(page);
    }
    // changeHandle(channel);
  }, [channel]);

  const changeAllMall = () => {
    if (allMall) {
      return;
    } else {
      setAllMall(true);
      setAmazon(false);
      setRakuten(false);
      setYahoo(false);
      setColor0('KPISelected');
      setColor1('KPINoselected');
      setColor2('KPINoselected');
      setColor3('KPINoselected');
      setVariant0('contained');
      setVariant1('text');
      setVariant2('text');
      setVariant3('text');
    }
  };
  const changeAmazon = () => {
    if (amazon) {
      return;
    } else {
      setAllMall(false);
      setAmazon(true);
      setRakuten(false);
      setYahoo(false);
      setColor0('KPINoselected');
      setColor1('KPISelected');
      setColor2('KPINoselected');
      setColor3('KPINoselected');
      setVariant0('text');
      setVariant1('contained');
      setVariant2('text');
      setVariant3('text');
    }
  };
  const changeRakuten = () => {
    if (rakuten) {
      return;
    } else {
      setAllMall(false);
      setAmazon(false);
      setRakuten(true);
      setYahoo(false);
      setColor0('KPINoselected');
      setColor1('KPINoselected');
      setColor2('KPISelected');
      setColor3('KPINoselected');
      setVariant0('text');
      setVariant1('text');
      setVariant2('contained');
      setVariant3('text');
    }
  };
  const changeYahoo = () => {
    if (yahoo) {
      return;
    } else {
      setAllMall(false);
      setAmazon(false);
      setRakuten(false);
      setYahoo(true);
      setColor0('KPINoselected');
      setColor1('KPINoselected');
      setColor2('KPINoselected');
      setColor3('KPISelected');
      setVariant0('text');
      setVariant1('text');
      setVariant2('text');
      setVariant3('contained');
    }
  };

  return (
    <>
      <CssBaseline />
      <Box
        bgcolor="#FFFFFF"
        borderRadius={1.5}
        py="3px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        columnGap="5px"
        width="340px"
      >
        <Button
          onClick={() => {
            changeAllMall(), dispatch(registerAllMall());
          }}
          variant={variant0}
          color={color0}
          sx={{
            minWidth: '80px',
            height: '30px',
            borderRadius: 1.5,
            fontWeight: 'bold'
          }}
          value={0}
          disabled={channel !== 0 && !page}
        >
          全体
        </Button>
        <Button
          onClick={() => {
            changeAmazon(), dispatch(registerAmazon());
          }}
          variant={variant1}
          color={color1}
          sx={{
            minWidth: '80px',
            height: '30px',
            borderRadius: 1.5,
            fontWeight: 'bold'
          }}
          value={1}
          disabled={channel !== 1 && !page}
        >
          Amazon
        </Button>
        <Button
          onClick={() => {
            changeRakuten(), dispatch(registerRakuten());
          }}
          // disabled={amazonOnly}
          value={2}
          variant={variant2}
          color={color2}
          sx={{
            minWidth: '80px',
            height: '30px',
            borderRadius: 1.5,
            fontWeight: 'bold'
          }}
          disabled={
            channel !== 2 && !page
            // page == 'categoryNo1' ||
            // page == 'categoryTop100'
          }
        >
          楽天
        </Button>
        <Button
          disabled={
            amazonOnly &&
            page != 'numberviews' &&
            page != 'categoryTop100' &&
            page != 'otherProduct' &&
            page != 'categoryNo1'
          }
          // disabled={page != 'categoryTop100'}
          onClick={() => {
            changeYahoo(), dispatch(registerYahoo());
          }}
          value={3}
          variant={variant3}
          color={color3}
          sx={{
            minWidth: '80px',
            height: '30px',
            borderRadius: 1.5,
            fontWeight: 'bold'
          }}
          // disabled={channel !== 3 && !page}
        >
          ヤフー
        </Button>
      </Box>
    </>
  );
};
export default KPIButtongroup;
