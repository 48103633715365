/* eslint-disable*/
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  Paper,
  Container,
  InputBase,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  tableCellClasses,
  FormHelperText,
  Link,
  TextField,
  Typography,
  Snackbar,
  Alert,
  Divider
} from '@material-ui/core';
import {
  colors,
  CircularProgress
} from '@material-ui/core'; /* eslint-disable*/
// import { tableCellClasses } from '@mui/material/TableCell';
import { fetchChangePass } from 'feactures/user/userSlice';
import { userinfo } from 'feactures/user/userSlice';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useUpdatedRef } from 'rsuite/esm/utils';
import { fetchChangeAdvertiseId } from 'feactures/user/userSlice';

const SettingAdvertise = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const user = useSelector(userinfo);
  const [err, setErrMessage] = useState(false); //登録できなかったときのエラー

  const snackbarHandleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setErrMessage(false);
  };

  const modoru = () => {
    navigate(-1);
  };

  const CustomInput = (props) => {
    const { value, item, onChange, name, touched, errors, onBlur, readOnly } =
      props;
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
      >
        <Typography variant="h6" sx={{ ml: 4 }}>
          {item}
        </Typography>
        <Box flex="1 1 auto" maxWidth="300px">
          <InputBase
            sx={{
              pl: 2,
              backgroundColor: colors.grey[100]
            }}
            fullWidth
            htmlFor="component-outlined"
            value={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            readOnly={readOnly}
          />
          {Boolean(touched[name]) && errors[name] ? (
            <FormHelperText error={true}>{errors[name]}</FormHelperText>
          ) : null}
        </Box>
      </Box>
    );
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14
    }
  }));

  const onSubmit = async (values) => {
    // console.log(values);
    await dispatch(fetchChangeAdvertiseId(values))
      .then(unwrapResult)
      .then(() => {
        // setTimeout(function () {
        window.scrollTo(0, 0);
        setSuccess(true);
        // }, 1000);
      })
      .catch((err) => {
        // console.log(err);
        setErrMessage(true);
      });
  };

  return (
    <>
      <Box
        backgroundColor="background.default"
        display="flex"
        flexDirection="column"
        height="auto"
        minHeight="100vh"
        width="100%"
        justifyContent="center"
        py={3}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              dspId: user.dspId,
              saId: user.saId,
              status: user.userStatus
            }}
            validationSchema={Yup.object().shape({
              dspId: Yup.number()
                .typeError('半角数字で入力してください')
                .integer('整数を入力してください')
                .min(0, '0以上の数字を入れてください'),
              saId: Yup.number()
                .typeError('半角数字で入力してください')
                .integer('整数を入力してください')
                .min(0, '0以上の数字を入れてください')
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              resetForm
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    mb: 3,
                    textAlign: 'center',
                    mx: 'auto'
                  }}
                >
                  <Typography color="textSecondary" variant="h2">
                    広告連携設定
                  </Typography>
                  <Typography
                    gutterBottom
                    color="textSecondary"
                    variant="body2"
                  >
                    ECモールと広告の接続設定に関する画面
                  </Typography>
                </Box>
                <Typography paragraph={true}>Amazon</Typography>
                <Divider />
                <Card sx={{ mb: 3, minHeight: '400px' }}>
                  <CardContent>
                    <Typography paragraph={true}>●Advertising</Typography>
                    ＜スポンサープロダクト＞
                    <CustomInput
                      id={'saId'}
                      name={'saId'}
                      value={values.saId}
                      item={'広告アカウントID'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      readOnly={values.status == 1 ? false : true}
                    />
                    ＜DSP＞
                    <CustomInput
                      id={'dspId'}
                      name={'dspId'}
                      value={values.dspId}
                      item={'広告アカウントID'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      readOnly={values.status == 1 ? false : true}
                    />
                  </CardContent>
                </Card>

                {values.status == 1 ? (
                  <Box
                    sx={{ py: 4, display: 'flex', justifyContent: 'center' }}
                  >
                    <Button
                      sx={{ mr: 2, height: '36px' }}
                      type="reset"
                      variant="outlined"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        resetForm();
                      }}
                    >
                      キャンセル
                    </Button>
                    <Button
                      sx={{ width: '150px', height: '36px' }}
                      color="primary"
                      size="midium"
                      disabled={isSubmitting}
                      type="button"
                      variant="contained"
                      onClick={() => {
                        const err = Object.keys(errors);
                        if (err.length) {
                          const input = document.querySelector(
                            `input[name=${err[0]}]`
                          );

                          input.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                            inline: 'start'
                          });
                        } else {
                          onSubmit(values);
                        }
                      }}
                    >
                      {isSubmitting ? (
                        <CircularProgress size={22} />
                      ) : (
                        '変更を保存する'
                      )}
                    </Button>

                    <br />
                  </Box>
                ) : null}
              </form>
            )}
          </Formik>
          <Typography color="textSecondary" variant="body1">
            <button onClick={() => modoru()}>←戻る</button>
          </Typography>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={success || err}
            severity={success ? 'success' : 'error'}
            autoHideDuration={4000}
            onClose={snackbarHandleClose}
            message={
              err ? '変更できませんでした' : '広告アカウント情報を変更しました'
            }
          />
        </Container>
      </Box>
    </>
  );
};

export default SettingAdvertise;
