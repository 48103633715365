/* eslint-disable*/
import { ComposedChart, Bar, Line, Chart } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  useTheme,
  Typography,
  Button,
  IconButton,
  colors
  // DeleteIcon
} from '@material-ui/core';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { currentchannel } from 'feactures/channel/channelSlice';
import { styled } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import 'chartjs-adapter-moment';
import { useNavigate } from 'react-router-dom';
import {
  fetchCategoryNo1Sales,
  selectAmazonCategoryList,
  selectRakutenCategoryList,
  selectYahooCategoryList,
  amazonCategoryGraphList,
  rakutenCategoryGraphList,
  yahooCategoryGraphList,
  changeCategory,
  resetCategoryLabel,
  categoryNo1SalesStatus,
  categoryNo1Error
} from 'feactures/category/categoryNo1Slice';
import { useTooltipContext } from './CustomTooltip';

const Category1graph = (props) => {
  const { openTooltip, closeTooltip } = useTooltipContext();
  const {
    title,
    color,
    // graphdata,
    graphTitle,
    graphtype,
    term //[,,,]
  } = props; // eslint-disable-line
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const channel = useSelector(currentchannel);

  const amazonCategoryGraphInfo = useSelector(amazonCategoryGraphList); //各カテゴリのグラフ値と画像などの情報
  const rakutenCategoryGraphInfo = useSelector(rakutenCategoryGraphList);
  const yahooCategoryGraphInfo = useSelector(yahooCategoryGraphList);

  let categoryInfoArr = [];
  if (channel == 1) {
    categoryInfoArr = amazonCategoryGraphInfo;
  } else if (channel == 2) {
    categoryInfoArr = rakutenCategoryGraphInfo;
  } else if (channel == 3) {
    categoryInfoArr = yahooCategoryGraphInfo;
  }
  // console.log(categoryInfoArr);

  const graphColorList = [
    colors.purple[500],
    colors.grey[500],
    colors.blue[500],
    colors.red[500],
    colors.yellow[500],
    colors.green[500]
  ];

  const graphData = categoryInfoArr.map(
    (categoryInfo, index) =>
      categoryInfo.category_name !== '-' && {
        label: categoryInfo ? categoryInfo.category_name : '未選択',
        data: categoryInfo.products?.map((p) => p.sales),
        borderColor: graphColorList[index],
        backgroundColor: graphColorList[index],
        value: categoryInfo.products
      }
  );

  const url = 'https://m.media-amazon.com/images/I/';

  //This is where you create content to go inside of the tooltip
  const tooltipContent = (e) => {
    return (
      <Box
        sx={{
          height: 70,
          display: 'inline-block'
        }}
      >
        <img
          src={url + category6Info.products[0].imgUrl}
          height="100%"
          maxwidth="100%"
          loading="lazy"
        />
      </Box>
    );
  };

  const dataArr = graphData.filter(Boolean);
  // console.log(dataArr);

  const data = {
    labels: term,
    datasets: dataArr
  };

  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
      tooltipEl.style.borderRadius = '3px';
      tooltipEl.style.color = 'white';
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-50%, 0)';
      tooltipEl.style.transition = 'all .1s ease';

      const table = document.createElement('table');
      table.style.margin = '0px';

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const dataIndex = tooltip.dataPoints[0].dataIndex;
    const { imgUrl, item_id, price, productName, sales } =
      tooltip.dataPoints[0].dataset.value[dataIndex];

    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map((b) => b.lines);

      const tableHead = document.createElement('thead');
      const trr = document.createElement('tr');
      trr.style.borderWidth = 0;

      titleLines.forEach((title) => {
        const td = document.createElement('td');
        td.style.borderWidth = 0;
        const text = document.createTextNode(title);
        td.appendChild(text);
        trr.appendChild(td);
      });

      bodyLines.forEach((body, i) => {
        const colors = tooltip.labelColors[i];
        const span = document.createElement('span');
        span.style.background = colors.backgroundColor;
        span.style.borderColor = colors.borderColor;
        span.style.borderWidth = '2px';
        span.style.marginRight = '10px';
        span.style.height = '10px';
        span.style.width = '10px';
        span.style.display = 'inline-block';

        const td = document.createElement('td');
        td.style.borderWidth = 0;

        const text = document.createTextNode(body);
        td.appendChild(span);
        td.appendChild(text);
        trr.appendChild(td);
      });

      tableHead.appendChild(trr);

      const tableBody = document.createElement('tbody');

      const selectUrl = 'https://m.media-amazon.com/images/I/' + imgUrl;

      //   // create the img element and append it to the tooltip element.
      const img = document.createElement('img');
      img.src = channel == 1 ? selectUrl : imgUrl;
      tooltip.innerHTML = '';
      img.style.width = '60px';
      img.style.marginRight = '10px';
      const th = document.createElement('th');
      th.style.backgroundColor = 'inherit';
      th.style.borderWidth = 0;

      const tr = document.createElement('tr');
      tr.style.backgroundColor = 'inherit';
      tr.style.borderWidth = 0;

      const td = document.createElement('td');
      td.style.borderWidth = 0;

      const td2 = document.createElement('td');
      td2.style.borderWidth = 0;

      // tr.appendChild(text);
      td.appendChild(tableBody.appendChild(img));
      td2.innerHTML =
        '<ul style="list-style: none;"> <li>' +
        item_id +
        '</li> <li>￥' +
        price +
        '</li> <li> <a style="color:#ffffff" target="_blank" href="https://www.amazon.co.jp/dp/' +
        item_id +
        '">' +
        productName +
        '</a></li> </ul>';
      tr.appendChild(td);
      tr.appendChild(td2);
      tableBody.appendChild(tr);
      const tableRoot = tooltipEl.querySelector('table');

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }

      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);

      const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      tooltipEl.style.zIndex = 10;
      tooltipEl.style.left = positionX + tooltip.caretX + 'px';
      tooltipEl.style.top = positionY + tooltip.caretY + 'px';
      tooltipEl.style.font = tooltip.options.bodyFont.string;
      tooltipEl.style.padding =
        tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
    }
  };

  const options = {
    animation: false,
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: 'nearest',
      intersect: false
    },
    plugins: {
      legend: { position: 'bottom' },
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: externalTooltipHandler
      },
      datalabels: {
        display: false
      }
    },
    scales: {
      x: {
        ticks: {
          fontColor: theme.palette.text.secondary
        },
        grid: {
          display: false,
          drawBorder: false
        }
      },
      y: {
        beginAtZero: true
      }
    }
  };
  //This is where you style the tooltip wrapper
  const tooltipStyle = {
    backgroundColor: 'white',
    borderColor: 'black',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: '1%',
    fontFamily: 'helvetica, sans-serif',
    fontSize: '16px',
    padding: '.5%'
  };

  /* prettier-ignore */
  return (
      <Box
        sx={{
          height: 500
        }}
        id="chart-container"
      >
        {dataArr.length == 0 ?(
        <>カテゴリを選択してください</>)
        :

        <Line
          boxsizing="border-box"
          data={data}
          options={options}
          redraw
        />
        }
        {/* <div id="tooltip-container" ></div> */}
      </Box>
  );
};

export default Category1graph;
