/* eslint-disable  */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  Button,
  TextField,
  Typography,
  CircularProgress,
  Modal,
  Card,
  InputBase,
  IconButton,
  Box,
  Grid,
  Paper
} from '@material-ui/core';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SearchIcon from '@mui/icons-material/Search';
import { currentchannel } from 'feactures/channel/channelSlice';
import { errorResMessage, setError } from 'feactures/errorSlice';
import ColorButton from 'components/atoms/button/ColorButton';
import {
  deleteTrackingProduct,
  productIsLoading,
  deleteList,
  registerTrackingProduct,
  fetchMeasureAnalysisData,
  changeRankingProductSearchword,
  fetchRankingProductList,
  rankingProductSearchword,
  rankingProductCategory,
  resetSelectedCategory,
  changeRankingProductPage,
  resetRankingProductPage,
  rankingProductIsLoading
} from 'feactures/measure/newMeasureSlice';
import RankingProductDatePicker from '../button/RankigProductDatePicker';
import ProductRankingTable from 'components/organisms/content/measure/ProductRankingTable';
import CategoryList from '../list/CategoryList';
import RankingProductPullDown from '../button/RankingProductPullDown';
import CloseIcon from '@mui/icons-material/Close';

const MeasureAnalysisProductRegister = ({ premiumStatus }) => {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState('1');
  const [open, setOpen] = useState(false);
  const [inputItemId, setInputItemId] = useState('');
  const [inputName, setInputName] = useState('');
  const [inputShopCode, setInputShopCode] = useState('');
  const [flag, setFlag] = useState(true);
  const errorMessage = useSelector(errorResMessage); //errorメッセージ
  const channel = useSelector(currentchannel);
  const deleteProductList = useSelector(deleteList);
  const registerLoading = useSelector(productIsLoading);
  const searchword = useSelector(rankingProductSearchword);
  const selectedCategory = useSelector(rankingProductCategory);
  const isLoading = useSelector(rankingProductIsLoading);
  const [itemIdError, setItemIdError] = useState('');
  const [itemNameError, setItemNameError] = useState('');
  const [shopCodeError, setShopCodeError] = useState('');

  useEffect(() => {
    const validateItemId = /[\s\\￥¥]/.test(inputItemId);
    if (validateItemId) {
      setItemIdError('登録できない文字が含まれます');
      setFlag(true);
    } else {
      if (channel == 2 && inputItemId && inputName && inputShopCode) {
        setFlag(false);
      } else if (channel == 1 && inputItemId) {
        setFlag(false);
      } else {
        setFlag(true);
      }
    }
  }, [inputItemId]);

  useEffect(() => {
    const validateItemName = /[\\￥¥]/.test(inputName);
    if (validateItemName) {
      setItemNameError("'\\'や'¥'は入力できません");
      setFlag(true);
    } else {
      if (inputItemId && inputName && inputShopCode) {
        setFlag(false);
      } else {
        setFlag(true);
      }
    }
  }, [inputName]);

  useEffect(() => {
    const validateShopCode = /[\s\\￥¥]/.test(inputShopCode);
    if (validateShopCode) {
      setShopCodeError('登録できない文字が含まれます');
      setFlag(true);
    } else {
      if (inputItemId && inputName && inputShopCode) {
        setFlag(false);
      } else {
        setFlag(true);
      }
    }
  }, [inputShopCode]);

  const changeItemId = (e) => {
    dispatch(setError(''));
    setItemIdError('');
    setInputItemId(e.target.value);
  };

  const changeItemName = (e) => {
    dispatch(setError(''));
    setItemNameError('');
    setInputName(e.target.value);
  };

  const changeShopCode = (e) => {
    dispatch(setError(''));
    setShopCodeError('');
    setInputShopCode(e.target.value);
  };

  const registerHandle = async () => {
    await dispatch(
      registerTrackingProduct({
        item_id: inputItemId,
        item_name: channel == 2 ? inputName : '',
        shop_code: channel == 2 ? inputShopCode : ''
      })
    )
      .then(unwrapResult) //registerProductsで登録が成功（fullfilled）のとき
      .then(() => {
        dispatch(fetchMeasureAnalysisData());
        handleReset();
        handleClose();
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleDeleteProduct = async () => {
    if (
      window.confirm(
        //eslint-disable-line
        '削除しますか？　※選択された商品のデータは今後蓄積されません'
      )
    ) {
      await dispatch(deleteTrackingProduct())
        .then(unwrapResult)
        .then(() => {
          dispatch(fetchMeasureAnalysisData());
        });
    }
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(setError('')); //表示されているエラーをリセット
    dispatch(changeRankingProductPage(1));
    dispatch(changeRankingProductSearchword(''));
    dispatch(resetSelectedCategory());
    dispatch(resetRankingProductPage());
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleReset = () => {
    setInputItemId('');
    setInputName('');
    setInputShopCode('');
    dispatch(setError('')); //表示されているエラーをリセット
  };

  return (
    <>
      <Grid container>
        <Button
          variant="contained"
          color="detailButton"
          onClick={handleClickOpen}
          sx={{ mr: 2 }}
          disabled={!premiumStatus}
        >
          商品登録
        </Button>
        <Button
          disabled={deleteProductList.length == 0}
          variant="contained"
          color="detailButton"
          onClick={handleDeleteProduct}
        >
          商品削除
        </Button>
      </Grid>
      {/* ランキングから商品登録をするモーダル */}
      <Modal open={open} onClose={handleClose}>
        <Card
          sx={{
            width: '90%',
            height: '80%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '10px',
            display: 'flex',
            overflowY: 'hidden' // Card全体にオーバーフローを隠す
          }}
        >
          <Box
            sx={{
              width: '22%',
              p: 3,
              overflowY: 'scroll'
            }}
          >
            <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>
              カテゴリ
            </Typography>
            <CategoryList />
          </Box>
          <Box
            sx={{
              p: 3,
              width: '78%',
              borderLeft: '1px solid lightGray',
              overflowY: 'scroll'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start'
              }}
            >
              <Typography sx={{ fontSize: '16px' }}>
                全ての商品
                {selectedCategory.map(
                  (cate, index) => cate.name !== '' && `＞ ${cate.name}`
                )}
              </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <TabContext value={tabValue}>
              <TabList
                onChange={handleTabChange}
                fullWidth
                indicatorColor="lightGray"
                sx={{
                  bgcolor: 'lightGray',
                  display: 'flex',
                  alignItems: 'center',
                  height: '30px',
                  minHeight: '0',
                  borderRadius: '4px',
                  my: 1
                }}
              >
                <Tab
                  value="1"
                  label="商品を指定した日のランキングから探す"
                  sx={{
                    width: '49%',
                    minWidth: '49%',
                    minHeight: '0',
                    height: '25px',
                    bgcolor: tabValue === '1' ? 'white' : 'lightGray',
                    mx: 'auto',
                    borderRadius: '4px'
                  }}
                />
                <Tab
                  value="2"
                  label="ランキングにない商品を登録する"
                  sx={{
                    width: '49%',
                    minWidth: '49%',
                    minHeight: '0',
                    height: '25px',
                    bgcolor: tabValue === '2' ? 'white' : 'lightGray',
                    mx: 'auto',
                    borderRadius: '4px'
                  }}
                />
              </TabList>
              {/* ランキングから検索して商品登録をする */}
              <TabPanel value="1" sx={{ p: 0 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
                  <Paper
                    component={'div'}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: 300,
                      height: '30px',
                      mr: 2
                    }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="検索"
                      value={searchword}
                      onChange={(e) => {
                        dispatch(
                          changeRankingProductSearchword(e.target.value)
                        );
                      }}
                    />
                    <IconButton
                      type="button"
                      sx={{ p: '5px', mr: 1 }}
                      onClick={() => {
                        dispatch(resetRankingProductPage());
                        // カテゴリ選択済みの場合のみランキング商品を取得する
                        if (
                          selectedCategory.length > 0 &&
                          selectedCategory[0].categoryId !== ''
                        ) {
                          dispatch(fetchRankingProductList());
                        }
                      }}
                      disabled={
                        isLoading ||
                        registerLoading ||
                        selectedCategory.length == 0 ||
                        selectedCategory[0].categoryId == ''
                      }
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                  {channel == 1 ? (
                    <RankingProductPullDown page={'rankingProduct'} />
                  ) : (
                    <RankingProductDatePicker page={'rankingProduct'} />
                  )}
                  <Typography sx={{ ml: 1 }}>の売れ筋商品から選ぶ</Typography>
                  {errorMessage && (
                    <Typography color="red" sx={{ mx: 3 }}>
                      ※{errorMessage}
                    </Typography>
                  )}
                </Box>
                <Typography sx={{ my: 1 }}>
                  ※商品名での検索が可能です
                </Typography>
                <Typography sx={{ my: 1 }}>
                  ※登録できる商品は10個までです
                </Typography>
                {channel == 2 && (
                  <Typography sx={{ my: 1 }}>
                    ※ランキング上位80商品まで表示されます
                  </Typography>
                )}
                <ProductRankingTable handleClose={handleClose} />
              </TabPanel>
              {/* 手動で入力して商品登録をする */}
              <TabPanel value="2">
                <Box>
                  <Typography sx={{ my: 1 }}>
                    ※登録できる商品は10個までです。
                  </Typography>

                  {/* ショップコードの入力エリア(楽天のみ) */}
                  {channel === 2 && (
                    <TextField
                      id="outlined-search"
                      label={'ショップコード'}
                      value={inputShopCode}
                      onChange={changeShopCode}
                      sx={{ my: 1 }}
                      fullWidth
                    />
                  )}

                  {shopCodeError != '' && (
                    <Typography color="red">{shopCodeError}</Typography>
                  )}

                  {/* 商品番号の入力エリア */}
                  <TextField
                    id="outlined-search"
                    label={
                      channel == 1
                        ? 'ASIN'
                        : channel == 2
                        ? '商品管理番号'
                        : '商品番号'
                    }
                    value={inputItemId}
                    onChange={changeItemId}
                    sx={{ my: 1 }}
                    fullWidth
                  />

                  {itemIdError != '' && (
                    <Typography color="red">{itemIdError}</Typography>
                  )}

                  {/* 商品名の入力エリア(楽天のみ) */}
                  {channel == 2 && (
                    <TextField
                      id="outlined-search"
                      label={'商品名'}
                      value={inputName}
                      onChange={changeItemName}
                      sx={{ my: 1 }}
                      fullWidth
                    />
                  )}

                  {itemNameError != '' && (
                    <Typography color="red">{itemNameError}</Typography>
                  )}

                  {errorMessage && (
                    <Typography color="red" sx={{ my: 1 }}>
                      {errorMessage}
                    </Typography>
                  )}

                  <Typography sx={{ my: 1 }}>
                    {channel === 1 && <>※入力例：B2FBDK23</>}
                    {channel === 2 && (
                      <>
                        ▼ショップコード・商品管理番号の確認方法
                        <br />
                        該当の商品ページを表示し、URLをご確認ください。
                        <br />
                        https://item.rakuten.co.jp/&#123;ショップコード&#125;/&#123;商品管理番号&#125;/
                        <br />
                        <br />
                        ▼商品名の記入方法
                        <br />
                        商品名は販売中の商品タイトルを省略せずにそのまま記入してください。
                      </>
                    )}
                  </Typography>
                  <Box sx={{ display: 'flex', my: 2, justifyContent: 'end' }}>
                    {errorMessage === '登録可能な商品数を超えています' ||
                    errorMessage === '商品登録時にエラーが発生しました' ? (
                      <Button onClick={handleReset} color="primary">
                        OK
                      </Button>
                    ) : (
                      <>
                        <Button onClick={handleReset} color="primary">
                          Cancel
                        </Button>
                        <ColorButton disabled={flag} onClick={registerHandle}>
                          {registerLoading ? (
                            <CircularProgress size={15} />
                          ) : (
                            '登録'
                          )}
                        </ColorButton>
                      </>
                    )}
                  </Box>
                </Box>
              </TabPanel>
            </TabContext>
          </Box>
        </Card>
      </Modal>
    </>
  );
};
export default MeasureAnalysisProductRegister;
