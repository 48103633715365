/* eslint-disable */
//CVR画面で表示するグラフ数値を受け取る
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const fetchUnitPricePost = createAsyncThunk(
  'unitPrice/post',
  async (arg, thunkAPI) => {
    const { term, start, end } = await thunkAPI.getState().date;
    const { userId, isLoggedIn, accountId, accountName, userName } =
      thunkAPI.getState().user;
    const { channel, benchmark } = thunkAPI.getState().currentchannel;
    const { checkGroupList } = thunkAPI.getState().group;

    const res = await client.post(`${apiUrl}unit_price`, {
      start: start,
      end: end,
      term: term,
      channel: channel, //検討
      user_id: userId,
      group_list: checkGroupList,
      comparing_type: Number(benchmark)
    });
    // console.log(res.data);
    return res.data;
  }
);

const UnitPriceSlice = createSlice({
  name: 'UnitPrice',
  initialState: {
    // price: [],
    bundledProductNum: [],
    variationNum: [],

    //競合
    g_variationNum: [],

    rate: [],
    num: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUnitPricePost.fulfilled, (state, action) => {
      // console.log(action.payload);
      return {
        ...state,
        // price: action.payload.price.price,
        bundledProductNum: action.payload.price.bundledProductNum,
        variationNum: action.payload.price.variationNum,

        //競合
        g_variationNum: action.payload.group_price.variationNum,

        rate: action.payload.price.rate,
        num: action.payload.price.num,
        isLoading: false,
        error: false
      };
    });
    builder.addCase(fetchUnitPricePost.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        error: false
      };
    });
  }
});

// export const priceGraph = (state) => state.price.price;
export const bundledProductNumGraph = (state) => state.price.bundledProductNum;
export const variationNumGraph = (state) => state.price.variationNum;

export const groupvariationNumGraph = (state) => state.price.g_variationNum;

export const cuprate = (state) => state.price.rate;
export const cupnum = (state) => state.price.num;
export const cupStatus = (state) => state.price.isLoading;

export default UnitPriceSlice.reducer;
