/* eslint-disable*/
import {
  Card,
  CardContent,
  colors,
  Box,
  Container,
  Grid,
  Typography,
  Divider,
  TextField,
  CircularProgress,
  Button,
  Snackbar,
  FormControl,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  List,
  ListItem,
  InputAdornment
} from '@material-ui/core'; /* eslint-disable*/
import * as Yup from 'yup';
import { Formik } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { unwrapResult } from '@reduxjs/toolkit';
import DatePicker from '../../atoms/button/DatePicker';
import Buttongroup from '../../atoms/group/Buttongroup';
import ComfirmDialog from 'components/atoms/modal/ComfirmDialog';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { errorResMessage, resetError } from 'feactures/errorSlice';
import { Monitor as MonitorIcon } from 'react-feather';
import { currentchannel } from 'feactures/channel/channelSlice';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  addGroupPost,
  editGroupPost,
  deleteGroup,
  groupStatus,
  groupError,
  groups,
  campaignsList,
  fetchGroups,
  groupRegisteredASIN,
  groupCanceledASIN,
  resetDisplayedASIN
} from 'feactures/monitoring/groupSlice';
import SearchIcon from '@mui/icons-material/Search';
import { number } from 'prop-types';
import { currentPage } from 'feactures/date/dateSlice';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Tab = styled(TabUnstyled)`
  cursor: pointer;
  font-weight: bold;
  background-color: transparent;
  width: 100px;
  padding: 12px 16px;
  border-radius: 5px 5px 0 0;
  display: flex;
  justify-content: center;

  &.${tabUnstyledClasses.selected} {
    background-color: ${colors.indigo[50]};
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  // font-family: IBM Plex Sans, sans-serif;
  // font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  background-color: transparent;
  border-radius: 8px;
  display: flex;
  align-items: center;
  align-content: space-between;
`;

const useStyles = makeStyles((theme) => ({
  input: {
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper
    // minWidth: 120
  }
}));

const EditGrouping = () => {
  // console.log('editGroup render');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [successState, setSuccessState] = useState(false);
  // const [err, setErr] = useState(''); //登録できなかったとき
  const [dialogOpen, setDialogOpen] = useState(false); //グループエラー
  const groupErrorStatus = useSelector(groupError); //グループエラー
  const errorMessage = useSelector(errorResMessage); //アプリ全体error
  const channel = useSelector(currentchannel);
  const loading = useSelector(groupStatus);
  const allcampaignList = useSelector(campaignsList);
  const [fixFlag, setFixFlag] = useState(false); //新規登録か変更か
  const [selectId, setSelectId] = useState('');
  const [campaignValue, setcampaignValue] = useState([]);
  const groupInfos = useSelector(groups);
  const page = useSelector(currentPage);
  // 検索機能関連
  const [searchText, setSearchText] = useState('');
  const [products, setProducts] = useState([]); //検索してヒットした商品一覧（変数名変える）
  const [amazonBaseURL, setAmazonBaseURL] = useState('');
  const [ownProductsValue, setOwnProductsValue] = useState([]); //自社：登録済み商品 + 検索から追加した商品 + 入力した商品？
  const [compProductsValue, setCompProductsValue] = useState([]); //競合：登録済み商品 + 検索から追加した商品 + 入力した商品？
  const [searchLoading, setSearchLoading] = useState(false);
  const [serchErrorMsg, setSerchErrorMsg] = useState('');

  const [inputFlag, setInputFlag] = useState(true); //登録方法が検索かリスト入力か
  const [newGroupName, setNewGroupName] = useState(''); //新規グループ登録入力欄（登録前）

  const canceledASIN = useSelector(groupCanceledASIN);
  const registeredASIN = useSelector(groupRegisteredASIN);

  const myCallback = (run) => {
    return run();
  };

  //新規登録クリック初期化処理
  const newGroupChecked = (e) => {
    // console.log(e);
    dispatch(resetError());
    setFixFlag(false);
    setSelectId('');
    setcampaignValue([]);
    setOwnProductsValue([]);
    setSearchText('');
    setProducts([]);

    dispatch(resetDisplayedASIN());
    setNewGroupName('');
  };

  let groupInfo = [];

  if (channel == 1) {
    groupInfo = groupInfos.amazon;
  } else if (channel == 2) {
    groupInfo = groupInfos.rakuten;
  } else if (channel == 3) {
    groupInfo = groupInfos.yahoo;
  }

  //作成前確認画面
  const comfirmHandle = () => {
    setDialogOpen(true);
  };

  //作成
  const onSubmit = async (values) => {
    await dispatch(addGroupPost(values))
      .then(unwrapResult)
      .then(() => {
        setCompProductsValue(values.benchAsinList);
        // setFixFlag(false);
        setSuccess(true);
        setSuccessState(true);
        // 第一引数の1はダミー
        radioChecked(1, groupInfo.length);
        // newGroupChecked();
        // setNewGroupName('');
        // setSelectId('');
      })
      .catch((error) => {
        console.log(error);
      });
    window.scrollTo(0, 0);
    setDialogOpen(false);
  };

  const snackbarHandleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
  };

  const radioChecked = (e, index) => {
    dispatch(resetError());
    setFixFlag(true); //ボタンを「変更」にする
    setSelectId(index);

    const result = allcampaignList.filter(
      (x) => groupInfo[index].campaign.filter((y) => y.id === x.id).length > 0
    );
    // console.log('filterresult', result);
    setOwnProductsValue(groupInfo[index].asin);
    setCompProductsValue(groupInfo[index].benchAsin);
    setcampaignValue(result);
    setProducts([]);
    setSearchText('');
    dispatch(resetDisplayedASIN());
    setSuccessState(false);
  };

  // const cancelHandle = () => {
  //   console.log('cancelHHandle');
  //   console.log(selectId);
  //   // setcampaignValue()
  // };

  const deleteHandle = async (groupName, groupId) => {
    if (
      window.confirm(
        //eslint-disable-line
        `【 ${groupName} 】 を削除しますか？` +
          '\r\n ※このグループに登録済みの商品の追跡データが削除されます'
      )
    ) {
      setSelectId('');
      await dispatch(deleteGroup({ groupId }))
        .then(unwrapResult)
        .then(() => {
          setSuccess(true);
        })
        .catch((err) => {
          console.log(err);
        });

      newGroupChecked();
    }
  };

  const editHandle = async (values) => {
    // console.log('hensyuボタン');
    await dispatch(editGroupPost(values))
      .then(unwrapResult)
      .then(() => {
        setCompProductsValue(values.benchAsinList);
        setSuccess(true);
        setSuccessState(true);
        // dispatch(fetchGroups());
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        console.log(err);
      });
    setDialogOpen(false);
  };

  // ↓商品検索↓
  useEffect(() => {
    return () => {
      // 非同期処理のキャンセル処理を行う
    };
  });

  // 検索ボタンを押したときの処理
  const handleSearch = async () => {
    setProducts([]);
    setSerchErrorMsg('');
    setSearchLoading(true);
    if (channel == 1) {
      let keepaUrl = '';
      // console.log('Amazon');
      const api_key =
        'dv6mvb4t9ourbn4o1sirbarm077p1vp24vacpgh1ls56lhfie9id3kbvt38jfo3a';
      const keyword = searchText;
      if (keyword[0] == 'B' && keyword.length == 10) {
        keepaUrl = `https://api.keepa.com/product?key=${api_key}&domain=5&asin=${keyword}&history=1&stats=1&features=1&images=1`;
      } else {
        keepaUrl = `https://api.keepa.com/search?key=${api_key}&domain=5&type=product&term=${keyword}&stats=1`;
      }
      // console.log(keepaUrl);
      try {
        const keepaResponse = await fetch(keepaUrl);
        const keepaData = await keepaResponse.json();
        // console.log(keepaData);
        if (!keepaData.products) {
          // console.log('検索結果０');
          setSerchErrorMsg('商品が見つかりませんでした');
        } else {
          setProducts(keepaData.products);
        }
      } catch (err) {
        console.log(err);
      }
    }
    if (channel == 2) {
      // console.log('楽天');
      let rakutenResponse = '';
      const api_key = '1066411084843296184';
      let keyword = searchText;
      const rakutenUrl = `https://app.rakuten.co.jp/services/api/IchibaItem/Search/20220601?format=json&applicationId=${api_key}`;
      // try {
      if (keyword.includes(':')) {
        keyword = keyword.replace(/:/g, '%3A');
        rakutenResponse = await fetch(`${rakutenUrl}&itemCode=${keyword}`);
      } else {
        rakutenResponse = await fetch(`${rakutenUrl}&keyword=${keyword}`);
      }
      const rakutenData = await rakutenResponse.json();
      if (rakutenData.Items.length == 0) {
        // console.log('検索結果０');
        setSerchErrorMsg('商品が見つかりませんでした');
      } else {
        setProducts(rakutenData.Items);
      }
      // } catch (err) {
      // console.log(err);
      // }
    }
    setSearchLoading(false);
  };

  // useEffect(() => {
  //   if (channel == 1 && products.length > 0) {
  //     // console.log(products);
  //     setAmazonBaseURL(
  //       'https://m.media-amazon.com/images/I/' +
  //         products[0].imagesCSV.split(',')[0]
  //     );
  //   }
  //   // if (channel == 2) {
  //   //    console.log(products);
  //   // }
  // }, [products]);

  // 商品検索欄に文字を入力したときの処理（テスト用）
  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  };

  // 検索した商品をリストに追加する
  const onAddProduct = (groupName, p, flag) => {
    setNewGroupName(groupName); //商品追加を押したときに名前が消えないようにする
    console.log(flag);
    // もし自社だったら
    if (flag === 0) {
      setOwnProductsValue([...ownProductsValue, p]);
      // if (ownProductsValue.length > 0) {
      // 確認用
      // console.log(ownProductsValue);
      // }
    } else {
      // もし競合だったら
      setCompProductsValue([...compProductsValue, p]);
    }
  };
  // ↑商品検索↑

  const handleChangeMethod = () => {
    // console.log('入力方法が切り替わりました');
    setSerchErrorMsg('');
    setInputFlag(!inputFlag);
  };

  const maxLength = 25; // 商品名の最大文字数
  const santenLeader = '...';

  useEffect(() => {
    // console.log('ここでリセットする');
    setSuccessState(false);
    dispatch(resetDisplayedASIN());
  }, [page]);

  // コンポーネント分け
  const InputTab = () => {
    return (
      <Box
        display="flex"
        // justifyContent="space-around"
        height="32px"
        margin="32px 0px 32px 0px"
        width="250px"
      >
        <Button
          style={{
            width: '125px',
            borderRadius: '4px 0px 0px 4px'
            // backgroundColor: '#515152'
          }}
          variant={inputFlag ? 'contained' : 'outlined'}
          color="primary"
          onClick={handleChangeMethod}
        >
          商品検索
        </Button>
        <Button
          style={{
            width: '125px',
            borderRadius: '0px 4px 4px 0px'
            // backgroundColor: '#515152'
          }}
          variant={!inputFlag ? 'contained' : 'outlined'}
          color="primary"
          onClick={handleChangeMethod}
        >
          リスト入力
        </Button>
      </Box>
    );
  };

  const ResisterAsinPop = (props) => {
    const rState = props.resisteredState;
    const cState = props.canceledState;
    return (
      <>
        {successState && (
          <Box width="600px" margin="15px 0px 30px 0px" marginBottom="40px">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around'
              }}
            >
              <Grid
                sx={{
                  backgroundColor: '#dce3de',
                  width: '40%',
                  textAlign: 'center',
                  paddingTop: '5px'
                }}
              >
                <Typography
                  borderBottom="solid 1px"
                  display="inline-block"
                  fontWeight="bold"
                >
                  {channel == 1 ? '未登録ASIN' : '未登録商品番号'}
                </Typography>
                <List>
                  {/* 競合の場合はなし */}
                  {cState.map((cASIN, index) => {
                    return <ListItem key={index}>{cASIN}</ListItem>;
                  })}
                </List>
              </Grid>
              <Grid
                sx={{
                  backgroundColor: '#dce3de',
                  width: '40%',
                  textAlign: 'center',
                  paddingTop: '5px'
                }}
              >
                <Typography
                  borderBottom="solid 1px"
                  display="inline-block"
                  fontWeight="bold"
                >
                  {channel == 1 ? '登録済みASIN' : '登録済み商品番号'}
                </Typography>
                <List>
                  {rState.map((compProValue, index) => {
                    return <ListItem key={index}>{compProValue}</ListItem>;
                  })}
                </List>
              </Grid>
            </Box>
            {cState.length > 0 && (
              <Typography color="red" marginLeft="30px">
                ※登録できなかった商品情報が存在します
              </Typography>
            )}
          </Box>
        )}
      </>
    );
  };

  // 検索欄に1文字しか入力できなくなってしまったので一旦直書きで
  const SearchArea = (props) => {
    const f = props.flag;
    return (
      <>
        {inputFlag && (
          <Box
            sx={{
              '& .MuiTextField-root': {
                backgroundColor: '#ffffff',
                borderRadius: '4px',
                mb: 4
              }
            }}
            noValidate
            autoComplete="off"
          >
            <Box>
              <TextField
                label="商品を検索"
                variant="outlined"
                size="small"
                value={searchText}
                InputProps={{
                  style: {
                    width: 'calc(100% + 40px)'
                  },
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{
                        position: 'absolute',
                        right: 0
                      }}
                    >
                      <Button
                        sx={{
                          height: '40px',
                          borderRadius: '0px 4px 4px 0px',
                          backgroundColor: '#515152',
                          // 効いてないかも
                          padding: '0',
                          minWidth: '50px'
                        }}
                        variant="contained"
                        color="primary"
                        onClick={handleSearch}
                      >
                        <SearchIcon />
                      </Button>
                    </InputAdornment>
                  )
                }}
                onChange={handleInputChange}
                style={{ maxWidth: '100%' }}
              />
            </Box>
            <Box sx={{ marginBottom: '30px' }}>
              <Typography>{serchErrorMsg}</Typography>
              {searchLoading && <CircularProgress size={15} />}
            </Box>
            {products.length > 0 && (
              <List
                sx={{
                  backgroundColor: 'white',
                  width: '600px',
                  marginBottom: '40px',
                  maxHeight: '300px',
                  overflow: 'auto'
                }}
              >
                {products.map((product, index) => {
                  return (
                    <ListItem
                      key={index}
                      sx={{
                        fontSize: '12px',
                        borderBottom: '1px solid #CCCCCC',
                        width: '95%',
                        margin: '0 auto'
                      }}
                    >
                      <Box display="flex">
                        <Grid container>
                          <Grid item xs={3}>
                            <img
                              src={
                                channel == 1
                                  ? 'https://m.media-amazon.com/images/I/' +
                                    product.imagesCSV.split(',')[0]
                                  : product.Item.mediumImageUrls[0].imageUrl
                              }
                              alt="商品画像"
                              style={{
                                width: '100px'
                              }}
                            />
                          </Grid>
                          <Grid item xs={9} paddingLeft="20px">
                            <Typography color="#2586A6" fontSize="14px">
                              {channel == 1
                                ? product.title.slice(0, maxLength) +
                                  santenLeader
                                : product.Item.itemName.slice(0, maxLength) +
                                  santenLeader}
                            </Typography>
                            <Typography color="#CF2F2F" fontSize="12px">
                              ￥
                              {channel == 1 &&
                                myCallback(() => {
                                  for (const [
                                    i,
                                    avg
                                  ] of product.stats.avg.entries()) {
                                    if (avg !== -1) return avg.toLocaleString();
                                  }
                                })}
                              {channel == 2 &&
                                product.Item.itemPrice.toLocaleString()}
                            </Typography>
                            <Box
                              display="flex"
                              sx={{
                                justifyContent: 'space-between'
                              }}
                            >
                              {channel == 1 ? (
                                <Typography color="#999999" fontSize="12px">
                                  ASIN:{product.asin}
                                </Typography>
                              ) : (
                                <Typography color="#999999" fontSize="12px">
                                  商品番号：
                                  {product.Item.itemCode.split(':').pop()}
                                </Typography>
                              )}

                              {channel == 1 && (
                                <Button
                                  size="small"
                                  type="button"
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    onAddProduct(
                                      values.groupName,
                                      product.asin,
                                      f //自社
                                    )
                                  }
                                >
                                  追加
                                </Button>
                              )}
                              {channel == 2 && (
                                <Button
                                  size="small"
                                  type="button"
                                  variant="contained"
                                  color="primary"
                                  onClick={() =>
                                    onAddProduct(
                                      values.groupName,
                                      product.Item.itemCode.split(':').pop(),
                                      f //自社
                                    )
                                  }
                                >
                                  追加
                                </Button>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </ListItem>
                  );
                })}
              </List>
            )}
          </Box>
        )}
      </>
    );
  };

  console.log(products);

  return (
    <>
      <Box
        sx={{
          height: 'auto',
          width: '100%',
          pt: '2px'
        }}
      >
        <Container sx={{ width: 'auto', p: 0 }}>
          <Typography fontSize="16px" fontWeight="bold">
            KPIサマリ
          </Typography>
          {/* <Buttongroup amazonOnly={true} /> */}
          <>
            <button
              style={{ textDecoration: 'none', marginTop: '30px' }}
              onClick={() => navigate(-1)}
            >
              ←戻る
            </button>

            <Formik
              initialValues={{
                groupId: selectId === '' ? 0 : groupInfo[selectId].id, //selectIdが’’のときは新規登録なので空欄にする
                groupName:
                  selectId === '' ? newGroupName : groupInfo[selectId].name,
                // asinList: selectId === '' ? [] : groupInfo[selectId].asin,
                asinList: ownProductsValue,
                // asinList: selectId === '' ? [] : [...addProduct],
                campaignList: campaignValue,
                // campaignList: selectId === '' ? ['新規'] : campaignValue,
                benchAsinList:
                  // selectId === '' ? [] : groupInfo[selectId].benchAsin
                  compProductsValue
              }}
              validationSchema={Yup.object().shape({
                groupName: Yup.string()
                  .max(16, '16文字以内')
                  .required('未入力です')
                  .test(
                    'existingGroup-validation',
                    'このグループ名はすでに存在します',
                    function (value) {
                      // グループ名を順番に確認して同じものがあったら格納する
                      let existingGroup = '';
                      groupInfo.map((group) => {
                        if (group.name === value) {
                          existingGroup = value;
                        }
                      });

                      if (value != existingGroup) {
                        const isValue = value;
                        return isValue;
                      }
                    }
                  )
                  .test(
                    'max-group-limit',
                    '10個以上のグループを作成することはできません',
                    function () {
                      return groupInfo.length < 10;
                    }
                  ),
                asinList: Yup.array()
                  .of(Yup.string().required('未入力です'))
                  .min(1, '未入力です')
                // campaign: Yup.string()
              })}
              // onSubmit={onSubmit}
              enableReinitialize={true} //initialValue を動的に変えられる
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                touched,
                values,
                resetForm,
                isValid
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box>
                    <Container mt={7} maxWidth="md">
                      {/* <Typography variant="h4">グループ登録</Typography> */}
                      <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="body2"
                        mb={3}
                      >
                        ※最大１０グループ作成可能です
                      </Typography>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                        >
                          <FormControlLabel
                            value="新規登録"
                            control={
                              <Radio
                                onClick={(e) => {
                                  newGroupChecked();
                                  resetForm();
                                }}
                              />
                            }
                            label="新規登録"
                            checked={fixFlag == false}
                          />
                          {groupInfo.map((group, index) => {
                            return (
                              <FormControlLabel
                                key={group.id}
                                value={group.id}
                                checked={index === selectId}
                                control={
                                  <Radio
                                    onClick={(e) => {
                                      radioChecked(e, index);
                                      resetForm();
                                    }}
                                  />
                                }
                                label={group.name}
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                      <Card sx={{ backgroundColor: colors.indigo[50] }}>
                        <CardContent
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Typography variant="h5">グループ名：</Typography>
                          <Box
                            sx={{
                              m: 1,
                              '.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input':
                                {
                                  backgroundColor: '#ffffff',
                                  borderRadius: '4px'
                                }
                            }}
                            noValidate
                            autoComplete="off"
                          >
                            <TextField
                              size="small"
                              type="text"
                              value={values.groupName}
                              id={'groupName'}
                              name={'groupName'}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={Boolean(
                                touched.groupName && errors.groupName
                              )}
                              helperText={touched.groupName && errors.groupName}
                            />
                          </Box>
                        </CardContent>
                      </Card>
                      <Box
                        sx={{
                          mt: 6,
                          width: '100%',
                          typography: 'body1'
                        }}
                      >
                        {/* {err && (
                          <Typography
                            variant="h5"
                            sx={{ textAlign: 'center' }}
                            color="error"
                          >
                            {err}
                          </Typography>
                        )} */}
                        {channel == 1 ? (
                          <Typography color="red">
                            ※親ASINではなく、子ASINで登録をお願いします
                          </Typography>
                        ) : (
                          <Typography color="red">
                            ※自社に登録されていない商品は登録することができません
                          </Typography>
                        )}
                        <Typography color="red">
                          ※売上がない商品は登録することができません
                        </Typography>
                        {/* {groupErrorStatus && (
                          <Typography color="red">{errorMessage}</Typography>
                        )} */}
                        <TabsUnstyled defaultValue={0}>
                          <TabsList>
                            <Tab type="button">自社</Tab>
                            <Tab type="button">競合</Tab>
                          </TabsList>
                          <Card
                            sx={{
                              backgroundColor: colors.indigo[50],
                              minHeight: '500px',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center'
                            }}
                          >
                            <TabPanel value={0}>
                              <Grid
                                container
                                direction="column"
                                alignItems="center"
                                p={3}
                              >
                                <Box
                                  maxWidth="sm"
                                  width="600px"
                                  display="flex"
                                  flexDirection="column"
                                >
                                  <InputTab />
                                  {/* 検索機能は取り急ぎここに直書き */}
                                  {/* <SearchArea flag={0} /> */}
                                  {inputFlag && (
                                    <Box
                                      sx={{
                                        '& .MuiTextField-root': {
                                          backgroundColor: '#ffffff',
                                          borderRadius: '4px',
                                          mb: 4
                                        }
                                      }}
                                      noValidate
                                      autoComplete="off"
                                    >
                                      <Box>
                                        <TextField
                                          label="商品を検索"
                                          variant="outlined"
                                          size="small"
                                          value={searchText}
                                          InputProps={{
                                            style: {
                                              width: 'calc(100% + 40px)'
                                            },
                                            endAdornment: (
                                              <InputAdornment
                                                position="end"
                                                style={{
                                                  position: 'absolute',
                                                  right: 0
                                                }}
                                              >
                                                <Button
                                                  sx={{
                                                    height: '40px',
                                                    borderRadius:
                                                      '0px 4px 4px 0px',
                                                    backgroundColor: '#515152',
                                                    // 効いてないかも
                                                    padding: '0',
                                                    minWidth: '50px'
                                                  }}
                                                  variant="contained"
                                                  color="primary"
                                                  onClick={handleSearch}
                                                >
                                                  <SearchIcon />
                                                </Button>
                                              </InputAdornment>
                                            )
                                          }}
                                          onChange={handleInputChange}
                                          style={{ maxWidth: '100%' }}
                                        />
                                      </Box>
                                      <Box sx={{ marginBottom: '30px' }}>
                                        <Typography>{serchErrorMsg}</Typography>
                                        {searchLoading && (
                                          <CircularProgress size={15} />
                                        )}
                                      </Box>
                                      {products.length > 0 && (
                                        <List
                                          sx={{
                                            backgroundColor: 'white',
                                            width: '600px',
                                            marginBottom: '40px',
                                            maxHeight: '300px',
                                            overflow: 'auto'
                                          }}
                                        >
                                          {products.map((product, index) => {
                                            return (
                                              <ListItem
                                                key={index}
                                                sx={{
                                                  fontSize: '12px',
                                                  borderBottom:
                                                    '1px solid #CCCCCC',
                                                  width: '95%',
                                                  margin: '0 auto'
                                                }}
                                              >
                                                <Box display="flex">
                                                  <Grid container>
                                                    {/* {channel == 2 &&
                                                    product.Item.mediumImageUrls
                                                      .length == 0 ? (
                                                      <Grid item xs={3}>
                                                        <Box width="100px"></Box>
                                                      </Grid>
                                                    ) : (
                                                      <Grid item xs={3}>
                                                        <img
                                                          src={
                                                            channel == 1
                                                              ? 'https://m.media-amazon.com/images/I/' +
                                                                product.imagesCSV.split(
                                                                  ','
                                                                )[0]
                                                              : product.Item
                                                                  .mediumImageUrls[0]
                                                                  .imageUrl
                                                          }
                                                          alt="商品画像"
                                                          style={{
                                                            width: '100px'
                                                          }}
                                                        />
                                                      </Grid>
                                                    )} */}
                                                    {channel == 1 &&
                                                      product.imagesCSV ==
                                                        null && (
                                                        <Grid item xs={3}>
                                                          <Box width="100px"></Box>
                                                        </Grid>
                                                      )}
                                                    {channel == 1 &&
                                                      product.imagesCSV !=
                                                        null && (
                                                        <Grid item xs={3}>
                                                          <img
                                                            src={
                                                              'https://m.media-amazon.com/images/I/' +
                                                              product.imagesCSV.split(
                                                                ','
                                                              )[0]
                                                            }
                                                            alt="商品画像"
                                                            style={{
                                                              width: '100px'
                                                            }}
                                                          />
                                                        </Grid>
                                                      )}
                                                    {channel == 2 &&
                                                      product.Item
                                                        .mediumImageUrls
                                                        .length > 0 && (
                                                        <Grid item xs={3}>
                                                          <img
                                                            src={
                                                              product.Item
                                                                .mediumImageUrls[0]
                                                                .imageUrl
                                                            }
                                                            alt="商品画像"
                                                            style={{
                                                              width: '100px'
                                                            }}
                                                          />
                                                        </Grid>
                                                      )}
                                                    {channel == 2 &&
                                                      product.Item
                                                        .mediumImageUrls
                                                        .length == 0 && (
                                                        <Grid item xs={3}>
                                                          <Box width="100px"></Box>
                                                        </Grid>
                                                      )}
                                                    <Grid
                                                      item
                                                      xs={9}
                                                      paddingLeft="20px"
                                                    >
                                                      <Typography
                                                        color="#2586A6"
                                                        fontSize="14px"
                                                      >
                                                        {channel == 1
                                                          ? product.title.slice(
                                                              0,
                                                              maxLength
                                                            ) + santenLeader
                                                          : product.Item.itemName.slice(
                                                              0,
                                                              maxLength
                                                            ) + santenLeader}
                                                      </Typography>
                                                      <Typography
                                                        color="#CF2F2F"
                                                        fontSize="12px"
                                                      >
                                                        ￥
                                                        {channel == 1 &&
                                                          myCallback(() => {
                                                            for (const [
                                                              i,
                                                              avg
                                                            ] of product.stats.avg.entries()) {
                                                              if (avg !== -1)
                                                                return avg.toLocaleString();
                                                            }
                                                          })}
                                                        {channel == 2 &&
                                                          product.Item.itemPrice.toLocaleString()}
                                                      </Typography>
                                                      <Box
                                                        display="flex"
                                                        sx={{
                                                          justifyContent:
                                                            'space-between'
                                                        }}
                                                      >
                                                        {channel == 1 ? (
                                                          <Typography
                                                            color="#999999"
                                                            fontSize="12px"
                                                          >
                                                            ASIN:{product.asin}
                                                          </Typography>
                                                        ) : (
                                                          <Typography
                                                            color="#999999"
                                                            fontSize="12px"
                                                          >
                                                            商品番号：
                                                            {product.Item.itemCode
                                                              .split(':')
                                                              .pop()}
                                                          </Typography>
                                                        )}

                                                        {channel == 1 && (
                                                          <Button
                                                            size="small"
                                                            type="button"
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() =>
                                                              onAddProduct(
                                                                values.groupName,
                                                                product.asin,
                                                                0 //自社
                                                              )
                                                            }
                                                          >
                                                            追加
                                                          </Button>
                                                        )}
                                                        {channel == 2 && (
                                                          <Button
                                                            size="small"
                                                            type="button"
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() =>
                                                              onAddProduct(
                                                                values.groupName,
                                                                product.Item.itemCode
                                                                  .split(':')
                                                                  .pop(),
                                                                0 //自社
                                                              )
                                                            }
                                                          >
                                                            追加
                                                          </Button>
                                                        )}
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                </Box>
                                              </ListItem>
                                            );
                                          })}
                                        </List>
                                      )}
                                    </Box>
                                  )}
                                  {/* 自社 - リストに入力して登録 */}
                                  {!inputFlag && (
                                    <>
                                      <Typography variant="h4">
                                        {channel == 1 && '★ASIN登録'}
                                        {channel == 2 && '★商品管理番号登録'}
                                      </Typography>
                                      <Box
                                        sx={{
                                          '& .MuiTextField-root': {
                                            backgroundColor: '#ffffff',
                                            borderRadius: '4px',
                                            mb: 4
                                          }
                                        }}
                                        noValidate
                                        autoComplete="off"
                                      >
                                        <TextField
                                          multiline
                                          fullWidth
                                          value={values.asinList}
                                          rows={4}
                                          id={'asinList'}
                                          name={'asinList'}
                                          onBlur={handleBlur}
                                          placeholder={
                                            'カンマ, 半角スペース,改行区切りで複数登録可能'
                                          }
                                          error={Boolean(
                                            touched.asinList && errors.asinList
                                          )}
                                          helperText={
                                            touched.asinList && errors.asinList
                                          }
                                          // onChange={handleChange}
                                          onChange={(e) => {
                                            const strData = e.target.value;
                                            const ary =
                                              strData.split(/[,\s\n]+/);
                                            setFieldValue('asinList', ary);
                                          }}
                                        />
                                      </Box>
                                    </>
                                  )}
                                  {/* 自社 - 検索して登録 */}
                                  {inputFlag && (
                                    <>
                                      <Typography variant="h4">
                                        {channel == 1 && '★ASIN登録'}
                                        {channel == 2 && '★商品管理番号登録'}
                                      </Typography>
                                      <Box
                                        sx={{
                                          '& .MuiTextField-root': {
                                            backgroundColor: '#ffffff',
                                            borderRadius: '4px',
                                            mb: 4
                                          }
                                        }}
                                        noValidate
                                        autoComplete="off"
                                      >
                                        <TextField
                                          multiline
                                          fullWidth
                                          value={
                                            values.asinList
                                            // ownProductsValue
                                          }
                                          rows={4}
                                          id={'asinList'}
                                          name={'asinList'}
                                          onBlur={handleBlur}
                                          placeholder={
                                            channel == 1
                                              ? '検索欄から選択して複数登録可能'
                                              : 'カンマ, 半角スペース,改行区切りで複数登録可能'
                                          }
                                          // onChange={handleChange}
                                          error={Boolean(
                                            touched.asinList && errors.asinList
                                          )}
                                          helperText={
                                            touched.asinList && errors.asinList
                                          }
                                          onChange={(e) => {
                                            const strData = e.target.value;
                                            let ary = strData;
                                            // strDataが空の時は配列にしない
                                            if (strData.length !== 0) {
                                              ary = strData.split(/[,\s\n]+/);
                                            }
                                            // setFieldValue('asinList', ary);
                                            setOwnProductsValue(ary);

                                            // setFieldValue(
                                            //   'asinList',
                                            //   ownProductsValue
                                            // );
                                          }}
                                        />
                                      </Box>
                                    </>
                                  )}
                                  <ResisterAsinPop
                                    canceledState={canceledASIN}
                                    resisteredState={registeredASIN}
                                  />
                                  {channel == 1 && (
                                    <>
                                      <Typography variant="h4">
                                        ★[広告](スポンサー)キャンペーン/(DSP)LineItem登録
                                      </Typography>
                                      <>
                                        <Autocomplete
                                          sx={{ backgroundColor: '#fff' }}
                                          multiple
                                          fullWidth
                                          options={allcampaignList}
                                          id={'campaignList'}
                                          name={'campaignList'}
                                          // value={campaignValue}
                                          value={values.campaignList}
                                          onChange={(e, newValue) => {
                                            // console.log(newValue);
                                            // setcampaignValue(newValue);
                                            setFieldValue(
                                              'campaignList',
                                              newValue
                                            );
                                          }}
                                          disableCloseOnSelect
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          renderOption={(
                                            props,
                                            option,
                                            { selected }
                                          ) => (
                                            <li {...props} key={option.id}>
                                              <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                              />
                                              {option.name}
                                            </li>
                                          )}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              placeholder="キャンペーンを選択してください"
                                            />
                                          )}
                                        />
                                      </>
                                    </>
                                  )}
                                </Box>
                              </Grid>
                            </TabPanel>
                            {/* ここから競合 */}
                            <TabPanel value={1}>
                              <Grid
                                container
                                direction="column"
                                alignItems="center"
                                p={3}
                              >
                                <Box
                                  maxWidth="sm"
                                  display="flex"
                                  justifyContent="center"
                                  flexDirection="column"
                                  width="80%"
                                >
                                  <InputTab />
                                  {/* 競合 - リストに入力して登録 */}
                                  {!inputFlag && (
                                    <>
                                      <Typography variant="h4">
                                        {channel == 1 && '★ASIN登録'}
                                        {channel == 2 && '★商品管理番号登録'}
                                      </Typography>
                                      <Box
                                        sx={{
                                          '& .MuiTextField-root': {
                                            backgroundColor: '#ffffff',
                                            borderRadius: '4px',
                                            mb: 4
                                          }
                                        }}
                                        noValidate
                                        autoComplete="off"
                                      >
                                        <TextField
                                          multiline
                                          fullWidth
                                          value={values.benchAsinList}
                                          rows={4}
                                          id={'benchAsinList'}
                                          name={'benchAsinList'}
                                          onBlur={handleBlur}
                                          placeholder={
                                            'カンマ, 半角スペース,改行区切りで複数登録可能'
                                          }
                                          error={Boolean(
                                            touched.benchAsinList &&
                                              errors.benchAsinList
                                          )}
                                          helperText={
                                            touched.benchAsinList &&
                                            errors.benchAsinList
                                          }
                                          // onChange={handleChange}
                                          onChange={(e) => {
                                            const strData = e.target.value;
                                            const ary =
                                              strData.split(/[,\s\n]+/);
                                            setFieldValue('benchAsinList', ary);
                                          }}
                                        />
                                      </Box>
                                    </>
                                  )}
                                  {/* 競合 - 検索して登録 */}
                                  {/* <SearchArea flag={1} /> */}
                                  {inputFlag && (
                                    <Box
                                      sx={{
                                        '& .MuiTextField-root': {
                                          backgroundColor: '#ffffff',
                                          borderRadius: '4px',
                                          mb: 4
                                        }
                                      }}
                                      noValidate
                                      autoComplete="off"
                                    >
                                      <Box>
                                        <TextField
                                          label="商品を検索"
                                          variant="outlined"
                                          size="small"
                                          value={searchText}
                                          InputProps={{
                                            style: {
                                              width: 'calc(100% + 40px)'
                                            },
                                            endAdornment: (
                                              <InputAdornment
                                                position="end"
                                                style={{
                                                  position: 'absolute',
                                                  right: 0
                                                }}
                                              >
                                                <Button
                                                  sx={{
                                                    height: '40px',
                                                    borderRadius:
                                                      '0px 4px 4px 0px',
                                                    backgroundColor: '#515152',
                                                    // 効いてないかも
                                                    padding: '0',
                                                    minWidth: '50px'
                                                  }}
                                                  variant="contained"
                                                  color="primary"
                                                  onClick={handleSearch}
                                                >
                                                  <SearchIcon />
                                                </Button>
                                              </InputAdornment>
                                            )
                                          }}
                                          onChange={handleInputChange}
                                          style={{ maxWidth: '100%' }}
                                        />
                                      </Box>
                                      <Box sx={{ marginBottom: '30px' }}>
                                        <Typography>{serchErrorMsg}</Typography>
                                        {searchLoading && (
                                          <CircularProgress size={15} />
                                        )}
                                      </Box>
                                      {products.length > 0 && (
                                        <List
                                          sx={{
                                            backgroundColor: 'white',
                                            width: '600px',
                                            marginBottom: '40px',
                                            maxHeight: '300px',
                                            overflow: 'auto'
                                          }}
                                        >
                                          {products.map((product, index) => {
                                            return (
                                              <ListItem
                                                key={index}
                                                sx={{
                                                  fontSize: '12px',
                                                  borderBottom:
                                                    '1px solid #CCCCCC',
                                                  width: '95%',
                                                  margin: '0 auto'
                                                }}
                                              >
                                                <Box display="flex">
                                                  <Grid container>
                                                    {channel == 1 &&
                                                      product.imagesCSV ==
                                                        null && (
                                                        <Grid item xs={3}>
                                                          <Box width="100px"></Box>
                                                        </Grid>
                                                      )}
                                                    {channel == 1 &&
                                                      product.imagesCSV !=
                                                        null && (
                                                        <Grid item xs={3}>
                                                          <img
                                                            src={
                                                              'https://m.media-amazon.com/images/I/' +
                                                              product.imagesCSV.split(
                                                                ','
                                                              )[0]
                                                            }
                                                            alt="商品画像"
                                                            style={{
                                                              width: '100px'
                                                            }}
                                                          />
                                                        </Grid>
                                                      )}
                                                    {channel == 2 &&
                                                      product.Item
                                                        .mediumImageUrls
                                                        .length > 0 && (
                                                        <Grid item xs={3}>
                                                          <img
                                                            src={
                                                              product.Item
                                                                .mediumImageUrls[0]
                                                                .imageUrl
                                                            }
                                                            alt="商品画像"
                                                            style={{
                                                              width: '100px'
                                                            }}
                                                          />
                                                        </Grid>
                                                      )}
                                                    {channel == 2 &&
                                                      product.Item
                                                        .mediumImageUrls
                                                        .length == 0 && (
                                                        <Grid item xs={3}>
                                                          <Box width="100px"></Box>
                                                        </Grid>
                                                      )}
                                                    <Grid
                                                      item
                                                      xs={9}
                                                      paddingLeft="20px"
                                                    >
                                                      <Typography
                                                        color="#2586A6"
                                                        fontSize="14px"
                                                      >
                                                        {channel == 1
                                                          ? product.title.slice(
                                                              0,
                                                              maxLength
                                                            ) + santenLeader
                                                          : product.Item.itemName.slice(
                                                              0,
                                                              maxLength
                                                            ) + santenLeader}
                                                      </Typography>
                                                      <Typography
                                                        color="#CF2F2F"
                                                        fontSize="12px"
                                                      >
                                                        ￥
                                                        {channel == 1 &&
                                                          myCallback(() => {
                                                            for (const [
                                                              i,
                                                              avg
                                                            ] of product.stats.avg.entries()) {
                                                              if (avg !== -1)
                                                                return avg.toLocaleString();
                                                            }
                                                          })}
                                                        {channel == 2 &&
                                                          product.Item.itemPrice.toLocaleString()}
                                                      </Typography>
                                                      <Box
                                                        display="flex"
                                                        sx={{
                                                          justifyContent:
                                                            'space-between'
                                                        }}
                                                      >
                                                        {channel == 1 ? (
                                                          <Typography
                                                            color="#999999"
                                                            fontSize="12px"
                                                          >
                                                            ASIN:{product.asin}
                                                          </Typography>
                                                        ) : (
                                                          <Typography
                                                            color="#999999"
                                                            fontSize="12px"
                                                          >
                                                            商品番号：
                                                            {product.Item.itemCode
                                                              .split(':')
                                                              .pop()}
                                                          </Typography>
                                                        )}

                                                        {channel == 1 && (
                                                          <Button
                                                            size="small"
                                                            type="button"
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() =>
                                                              onAddProduct(
                                                                values.groupName,
                                                                product.asin,
                                                                1 //競合
                                                              )
                                                            }
                                                          >
                                                            追加
                                                          </Button>
                                                        )}
                                                        {channel == 2 && (
                                                          <Button
                                                            size="small"
                                                            type="button"
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() =>
                                                              onAddProduct(
                                                                values.groupName,
                                                                product.Item.itemCode
                                                                  .split(':')
                                                                  .pop(),
                                                                1 //競合
                                                              )
                                                            }
                                                          >
                                                            追加
                                                          </Button>
                                                        )}
                                                      </Box>
                                                    </Grid>
                                                  </Grid>
                                                </Box>
                                              </ListItem>
                                            );
                                          })}
                                        </List>
                                      )}
                                    </Box>
                                  )}
                                  {inputFlag && (
                                    <>
                                      <Typography variant="h4">
                                        {channel == 1 && '★ASIN登録'}
                                        {channel == 2 && '★商品管理番号登録'}
                                      </Typography>
                                      <Box
                                        sx={{
                                          '& .MuiTextField-root': {
                                            backgroundColor: '#ffffff',
                                            borderRadius: '4px',
                                            mb: 4
                                          }
                                        }}
                                        noValidate
                                        autoComplete="off"
                                      >
                                        <TextField
                                          multiline
                                          fullWidth
                                          value={
                                            values.benchAsinList
                                            // ownProductsValue
                                          }
                                          rows={4}
                                          id={'benchAsinList'}
                                          name={'benchAsinList'}
                                          onBlur={handleBlur}
                                          placeholder={
                                            channel == 1
                                              ? '検索欄から選択して複数登録可能'
                                              : 'カンマ, 半角スペース,改行区切りで複数登録可能'
                                          }
                                          // onChange={handleChange}
                                          error={Boolean(
                                            touched.benchAsinList &&
                                              errors.benchAsinList
                                          )}
                                          helperText={
                                            touched.benchAsinList &&
                                            errors.benchAsinList
                                          }
                                          onChange={(e) => {
                                            const strData = e.target.value;
                                            let ary = strData;
                                            // strDataが空の時は配列にしない
                                            if (strData.length !== 0) {
                                              ary = strData.split(/[,\s\n]+/);
                                            }
                                            setFieldValue('benchAsinList', ary);
                                            setCompProductsValue(ary);

                                            // setFieldValue(
                                            //   'asinList',
                                            //   ownProductsValue
                                            // );
                                          }}
                                        />
                                      </Box>
                                    </>
                                  )}
                                  <ResisterAsinPop
                                    canceledState={[]}
                                    resisteredState={compProductsValue}
                                  />
                                </Box>
                              </Grid>
                            </TabPanel>
                            <Box
                              sx={{
                                py: 2,
                                display: 'flex',
                                justifyContent: 'center'
                              }}
                            >
                              {fixFlag ? (
                                <>
                                  <Button
                                    sx={{ mr: 2, height: '36px' }}
                                    type="reset"
                                    variant="outlined"
                                    onClick={() => {
                                      window.scrollTo(0, 0);
                                      resetForm();
                                      // cancelHandle();
                                    }}
                                  >
                                    キャンセル
                                  </Button>
                                  <Button
                                    sx={{ mr: 2, height: '36px' }}
                                    type="reset"
                                    variant="outlined"
                                    onClick={() => {
                                      deleteHandle(
                                        values.groupName,
                                        values.groupId
                                      );
                                      window.scrollTo(0, 0);
                                    }}
                                  >
                                    削除
                                  </Button>
                                  <Button
                                    sx={{ width: '150px', height: '36px' }}
                                    color="primary"
                                    size="midium"
                                    type="button"
                                    variant="contained"
                                    onClick={comfirmHandle}
                                  >
                                    {loading ? (
                                      <CircularProgress size={15} />
                                    ) : (
                                      '変更'
                                    )}
                                  </Button>
                                </>
                              ) : (
                                <Box>
                                  <Box>
                                    <Button
                                      sx={{ mr: 2, height: '36px' }}
                                      type="reset"
                                      variant="outlined"
                                      onClick={() => {
                                        window.scrollTo(0, 0);
                                        resetForm();
                                      }}
                                    >
                                      キャンセル
                                    </Button>
                                    <Button
                                      sx={{ width: '150px', height: '36px' }}
                                      disabled={
                                        !isValid || values.groupName == ''
                                      }
                                      color="primary"
                                      size="midium"
                                      type="button"
                                      variant="contained"
                                      // onClick={() => onSubmit(values)}
                                      onClick={() => {
                                        comfirmHandle(values.groupName);
                                      }}
                                    >
                                      {loading ? (
                                        <CircularProgress size={15} />
                                      ) : (
                                        'グループ作成'
                                      )}
                                    </Button>
                                  </Box>
                                  <Box alignContent="right">
                                    {(values.groupName == '' ||
                                      values.asinList.length == 0) && (
                                      <Typography color="red" fontSize="small">
                                        グループ名と自社商品は入力必須です
                                      </Typography>
                                    )}
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          </Card>
                        </TabsUnstyled>
                      </Box>
                    </Container>
                  </Box>
                  {dialogOpen && (
                    <ComfirmDialog
                      open={dialogOpen}
                      fixFlag={fixFlag}
                      setOpen={setDialogOpen}
                      values={values}
                      onSubmit={() => {
                        onSubmit(values);
                      }}
                      editHandle={() => editHandle(values)}
                    />
                  )}
                </form>
              )}
            </Formik>
          </>
        </Container>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={success}
        severity={'success'}
        autoHideDuration={3000}
        onClose={() => {
          window.scrollTo(0, 0);
          snackbarHandleClose();
        }}
        message={
          canceledASIN.length > 0
            ? channel == 1
              ? '登録できないASINが入力されています'
              : '登録できない商品番号が入力されています'
            : 'グループ情報を更新しました'
        }
      />
    </>
  );
};

export default EditGrouping;
