/* eslint-disable*/
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CategoryForm from 'components/molecules/dashboard/CategoryForm';
import { fetchCategoryRankData } from 'feactures/category/categoryRankSlice';
import {
  fetchAsyncCategory1,
  fetchOwnCategory,
  isLoading,
  fetchAmazonOwnCategory,
  fetchRakutenOwnCategory,
  fetchYahooOwnCategory,
  fetchAmazonCategory1,
  fetchRakutenCategory1,
  fetchYahooCategory1
} from 'feactures/category/categorySlice';
import {
  currentAcate1,
  currentAcate2,
  currentAcate3,
  currentAcate4,
  currentAcate5,
  currentAcate6,
  currentRcate1,
  currentRcate2,
  currentRcate3,
  currentRcate4,
  currentRcate5,
  currentYcate1,
  currentYcate2,
  currentYcate3,
  currentYcate4,
  changeAcate1,
  amazonParentFavoriteCategory,
  rakutenParentFavoriteCategory,
  yahooParentFavoriteCategory,
  fetchParentFavoriteCategory,
  changeRcate1,
  changeYcate1,
  fetchCategoryGraphData,
  resetAgraph,
  resetRgraph,
  resetYgraph,
  resetParentFavorite
} from 'feactures/category/categoryGraphSlice';

import { Box, Typography } from '@material-ui/core';
import { fetchCategoryShareGraphData } from 'feactures/category/categoryShareSlice';
import { setSelectedFavoriteCategory } from 'feactures/category/favoriteCategorySlice';
import {
  fetchCategoryBrandGraphData,
  resetAgraph as resetAmazonBrandGraph,
  resetRgraph as resetRakutenBrandGraph,
  resetYgraph as resetYahooBrandGraph
} from 'feactures/brand/categoryBrandGraphSlice';
import {
  fetchCategoryBrandShareGraphData,
  fetchCategoryOwnBrandShareGraphData,
  resetAgraph as resetAmazonBrandShareGraph,
  resetRgraph as resetRakutenBrandShareGraph,
  resetYgraph as resetYahooBrandShareGraph
} from 'feactures/brand/categoryBrandShareSlice';
import { fetchBrandRankData } from 'feactures/brand/brandRankSlice';
import {
  fetchOwnSales,
  resetAmazonOwnSalesGraph,
  resetRakutenOwnSalesGraph,
  resetYahooOwnSalesGraph
} from 'feactures/measure/measureSlice';
import {
  fetchBrandList,
  fetchOwnBrandList,
  resetCheckedBrandList,
  resetSelectedBrandList
} from 'feactures/brand/brandSlice';

const NewDialogSelect = (props) => {
  const { categories, channel, page, graphLoading } = props; // eslint-disable-line
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const loading = useSelector(isLoading);

  const parentFavoriteAmazonCategory = useSelector(
    amazonParentFavoriteCategory
  );
  const parentFavoriteRakutenCategory = useSelector(
    rakutenParentFavoriteCategory
  );
  const parentFavoriteYahooCategory = useSelector(yahooParentFavoriteCategory);

  const amazonCate1 = useSelector(currentAcate1);
  const amazonCate2 = useSelector(currentAcate2);
  const amazonCate3 = useSelector(currentAcate3);
  const amazonCate4 = useSelector(currentAcate4);
  const amazonCate5 = useSelector(currentAcate5);
  const amazonCate6 = useSelector(currentAcate6);

  const rakutenCate1 = useSelector(currentRcate1);
  const rakutenCate2 = useSelector(currentRcate2);
  const rakutenCate3 = useSelector(currentRcate3);
  const rakutenCate4 = useSelector(currentRcate4);
  const rakutenCate5 = useSelector(currentRcate5);

  const yahooCate1 = useSelector(currentYcate1);
  const yahooCate2 = useSelector(currentYcate2);
  const yahooCate3 = useSelector(currentYcate3);
  const yahoooCate4 = useSelector(currentYcate4);

  let categoryLabels = ['全てのカテゴリ'];
  function addToArray(...values) {
    let arr = ['全てのカテゴリ'];
    for (const value of values) {
      if (value !== 0) {
        arr.push(value.label);
      }
    }
    return arr;
  }
  if (channel == 1) {
    categoryLabels = addToArray(
      amazonCate1,
      amazonCate2,
      amazonCate3,
      amazonCate4,
      amazonCate5,
      amazonCate6
    );
  } else if (channel == 2) {
    categoryLabels = addToArray(
      rakutenCate1,
      rakutenCate2,
      rakutenCate3,
      rakutenCate4,
      rakutenCate5
    );
  } else if (channel == 3) {
    categoryLabels = addToArray(
      yahooCate1,
      yahooCate2,
      yahooCate3,
      yahoooCate4
    );
  }

  const dispatchTable = {
    category: [
      fetchBrandList(),
      fetchCategoryGraphData(),
      fetchCategoryRankData(),
      fetchCategoryShareGraphData(),
      resetSelectedBrandList({ channel }),
      resetCheckedBrandList({ channel })
    ],
    brandAnalysis: [
      resetSelectedBrandList({ channel }),
      resetCheckedBrandList({ channel }),
      fetchBrandList(),
      fetchCategoryBrandGraphData(),
      fetchBrandRankData(),
      fetchCategoryBrandShareGraphData()
    ],
    brandComparison: [
      resetSelectedBrandList({ channel }),
      resetCheckedBrandList({ channel }),
      fetchBrandList(),
      fetchOwnBrandList({ searchword: '' })
    ],
    measureAnalysis: [
      resetSelectedBrandList({ channel }),
      resetCheckedBrandList({ channel }),
      fetchBrandList(),
      fetchOwnSales(),
      fetchCategoryBrandGraphData()
    ]
  };

  useEffect(() => {
    if (
      parentFavoriteAmazonCategory.length == 0 &&
      parentFavoriteRakutenCategory.length == 0 &&
      parentFavoriteYahooCategory.length == 0
    )
      return;
    setOpen(true);
  }, [
    parentFavoriteAmazonCategory,
    parentFavoriteRakutenCategory,
    parentFavoriteYahooCategory
  ]);

  useEffect(() => {
    // ページが変わった時はダイアログを開かない
    setOpen(false);
  }, [page]);

  const handleClickOpen = () => {
    setOpen(true);
    if (
      (channel === 1 &&
        (amazonCate1 === 0 || categories.category1.length === 0)) ||
      (channel === 2 &&
        (rakutenCate1 === 0 || categories.category1.length === 0)) ||
      (channel === 3 && (yahooCate1 === 9 || categories.category1.length === 0))
    ) {
      if (page === 'productMeasures') {
        dispatch(setSelectedFavoriteCategory({ cate: '', channel }));
      } else if (page === 'brandComparison') {
        if (channel === 1) {
          dispatch(fetchAmazonOwnCategory());
        } else if (channel === 2) {
          dispatch(fetchRakutenOwnCategory());
        } else if (channel === 3) {
          dispatch(fetchYahooOwnCategory());
        }
        // dispatch(fetchOwnCategory());
      } else {
        if (channel === 1) {
          dispatch(fetchAmazonCategory1());
        } else if (channel === 2) {
          dispatch(fetchRakutenCategory1());
        } else if (channel === 3) {
          dispatch(fetchYahooCategory1());
        }
        // dispatch(fetchAsyncCategory1());
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (page === 'brandComparison') {
      if (channel === 1) {
        dispatch(resetAmazonOwnSalesGraph());
        dispatch(resetAmazonBrandShareGraph());
        dispatch(resetAmazonBrandGraph());
      } else if (channel === 2) {
        dispatch(resetRakutenOwnSalesGraph());
        dispatch(resetRakutenBrandShareGraph());
        dispatch(resetRakutenBrandGraph());
        // 何かしらのカテゴリを選択していれば、自社データを取得する
        if (rakutenCate1 !== 0) {
          dispatch(fetchOwnSales());
          dispatch(fetchCategoryOwnBrandShareGraphData());
        }
      } else if (channel === 3) {
        dispatch(resetYahooOwnSalesGraph());
        dispatch(resetYahooBrandShareGraph());
        dispatch(resetYahooBrandGraph());
      }
    }
    [...dispatchTable[page]].map((r, index) => dispatch(r)); //dispatchTableのreducerの数だけdispatchさせる
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleReset = () => {
    // console.log('入力したカテゴリをリセットします');
    if (page === 'brandComparison') {
      if (channel === 1) {
        dispatch(fetchAmazonOwnCategory());
      } else if (channel === 2) {
        dispatch(fetchRakutenOwnCategory());
      } else if (channel === 3) {
        dispatch(fetchYahooOwnCategory());
      }
      // dispatch(fetchOwnCategory());
    } else {
      if (channel === 1) {
        dispatch(fetchAmazonCategory1());
      } else if (channel === 2) {
        dispatch(fetchRakutenCategory1());
      } else if (channel === 3) {
        dispatch(fetchYahooCategory1());
      }
      // dispatch(fetchAsyncCategory1());
    }
    // お気に入りカテゴリが選択されていたらそれも空にする
    dispatch(setSelectedFavoriteCategory({ cate: '', channel }));
    dispatch(resetParentFavorite());

    // ↓「カテゴリを選択してください」が表示される
    if (channel === 1) {
      dispatch(changeAcate1(0));
      dispatch(resetAgraph());
    } else if (channel === 2) {
      dispatch(changeRcate1(0));
      dispatch(resetRgraph());
    } else if (channel === 3) {
      dispatch(changeYcate1(0));
      dispatch(resetYgraph());
    }
    // 削除した後にカテゴリ[N]を表示したい
    // handleClickOpen();
  };

  return (
    <>
      <Button
        onClick={handleClickOpen}
        variant="outlined"
        size="small"
        disabled={page === 'productMeasures' || loading || graphLoading}
        sx={{
          backgroundColor: '#FFFFFF',
          borderColor: '#BDBDBD',
          fontSize: '14px',
          minWidth: '150px',
          width: '250px',
          height: '30px'
        }}
        color="inherit"
      >
        <Typography
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            fontSize: '14px'
          }}
        >
          {page === 'productMeasures'
            ? 'カテゴリを選択する'
            : categoryLabels.length > 0
            ? categoryLabels[categoryLabels.length - 1]
            : 'カテゴリを選択する'}
        </Typography>
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="200">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pr="24px"
        >
          <DialogTitle>カテゴリを選択する</DialogTitle>
          <AutorenewIcon
            onClick={handleReset}
            sx={{ cursor: 'pointer', ':hover': { opacity: 0.5 } }}
          />
        </Box>
        <Box px="40px" pt="20px">
          <Typography fontSize="12px">
            一部カテゴリはグレーダウンされ、選択不可となっています。
            <br />
            データが充実次第、段階的に利用可能になります。
          </Typography>
        </Box>
        <DialogContent sx={{ pt: '0' }}>
          <CategoryForm categories={categories} channel={channel} page={page} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default NewDialogSelect;
