/* eslint-disable */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@material-ui/styles/makeStyles';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import {
  selectMonitoringAsin,
  selectMonitoringGroup
} from 'feactures/monitoring/monitoringSlice';
import GroupSelectPulldown from 'components/atoms/button/GroupSelectPullDown';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Box,
  Button,
  TextField,
  InputAdornment,
  Typography,
  CircularProgress,
  Paper,
  InputBase
} from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { changeChannel } from 'feactures/channel/channelSlice';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import {
  fetchGroupAsinDataList,
  groupAsinData,
  groupAsinDataLength,
  groupAsinDataOrder,
  groupAsinDataOrderBy,
  groupAsinDataPage,
  groupAsinDataPerpage,
  groupAsinDataLoading,
  changeGroupAsinOrder,
  changeGroupAsinOrderBy,
  changeGroupAsinPageCount,
  changeGroupAsinPerpage,
  resetGroupAsinPage,
  groupAsinDataSearchword,
  changeGroupAsinSearchword
} from 'feactures/monitoring/groupSlice';

const RateDataIcon = ({ rateNum }) => {
  let colorCode;
  if (rateNum === 0) {
    colorCode = '#000000';
  } else if (rateNum > 0) {
    colorCode = '#4C34FF';
  } else if (rateNum < 0) {
    colorCode = '#FF1C17';
  }

  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      {/* {rateNum > 0 && <TrendingUpIcon sx={{ color: colorCode }} />}
    {rateNum == 0 && <TrendingFlatIcon sx={{ color: colorCode }} />}
    {rateNum < 0 && <TrendingDownIcon sx={{ color: colorCode }} />} */}

      <Typography
        variant="h6"
        color={colorCode}
        component="div"
        sx={{ fontSize: '12px', fontWeight: 'bold' }}
      >
        {rateNum > 0 && '↑'}
        {rateNum == 0 && '±'}
        {rateNum < 0 && '↓'}
        {Math.abs(rateNum)}%
      </Typography>
    </Box>
  );
};

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: expand == 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
  padding: 0
}));

const GroupItemSalesList = ({ pathName }) => {
  const items = useSelector(groupAsinData);
  const asinListLength = useSelector(groupAsinDataLength);
  const orderDesc = useSelector(groupAsinDataOrder);
  const checkedColumn = useSelector(groupAsinDataOrderBy);
  const page = useSelector(groupAsinDataPage);
  const rowsPerPage = useSelector(groupAsinDataPerpage);
  const isLoading = useSelector(groupAsinDataLoading);
  const searchword = useSelector(groupAsinDataSearchword);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetGroupAsinPage());
    dispatch(changeGroupAsinSearchword(''));
    dispatch(fetchGroupAsinDataList({ searchword: searchword }));
  }, []);

  const handleChange = (column) => {
    if (column === checkedColumn && orderDesc === 'desc') {
      //最後に並び替えたカラムと同じカラムで、現在の並び順が降順なので昇順に並び替える
      dispatch(changeGroupAsinOrder('asc'));
      dispatch(fetchGroupAsinDataList({ searchword: searchword }));
    } else if (column === checkedColumn && orderDesc === 'asc') {
      //最後に並び替えたカラムと同じカラムで、現在の並び順が昇順なので降順に並び替える
      dispatch(changeGroupAsinOrder('desc'));
      dispatch(fetchGroupAsinDataList({ searchword: searchword }));
    } else {
      // 新しいカラムを降順に並び替える
      dispatch(changeGroupAsinOrderBy(column));
      dispatch(changeGroupAsinOrder('desc'));
      dispatch(fetchGroupAsinDataList({ searchword: searchword }));
    }
  };

  const handleChangePage = (event, newPage) => {
    dispatch(changeGroupAsinPageCount(newPage));
    dispatch(fetchGroupAsinDataList({ searchword: searchword }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(changeGroupAsinPerpage(event.target.value));
    dispatch(fetchGroupAsinDataList({ searchword: searchword }));
  };

  const handleChangeWord = (e) => {
    dispatch(changeGroupAsinSearchword(e.target.value));
  };

  const handleSearch = () => {
    dispatch(resetGroupAsinPage());
    dispatch(
      fetchGroupAsinDataList({
        searchword: searchword
      })
    );
  };

  const asinNameClick = (asin, name, channel) => {
    console.log({ code: asin, name: name, ch: channel });
    if (channel == 'Amazon') {
      dispatch(
        selectMonitoringAsin({
          code: asin,
          name: name,
          channel: 1
        })
      );
      dispatch(
        changeChannel({
          id: 1,
          name: 'amazon'
        })
      );
    } else if (channel == '楽天') {
      dispatch(
        selectMonitoringAsin({
          code: asin,
          name: name,
          channel: 2
        })
      );
      dispatch(
        changeChannel({
          id: 2,
          name: 'rakuten'
        })
      );
    } else if (channel == 'Yahoo') {
      dispatch(
        selectMonitoringAsin({
          code: asin,
          name: name,
          channel: 3
        })
      );
      dispatch(
        changeChannel({
          id: 3,
          name: 'yahoo'
        })
      );
    }
    navigate('/app/monitoring/asin');
  };

  const tableStyles = makeStyles({
    tableHead: {
      height: 44
    },
    container: {
      border: 'none'
    }
  });

  const classes = tableStyles();

  return (
    <>
      {/* <Paper sx={{ width: '100%', overflow: 'hidden' }}> */}
      <Box display="flex" alignItems="center" ml={1} mb={1}>
        <Typography fontWeight="bold" fontSize="14px" mr={2}>
          商品売上
        </Typography>
        <GroupSelectPulldown pathName={'monitoringGroup'} />
        <Paper
          component="div"
          sx={{
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: 300,
            height: '30px',
            ml: 2
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="検索"
            value={searchword}
            onChange={(e) => {
              handleChangeWord(e);
            }}
          />
          <IconButton
            type="button"
            sx={{ p: '5px', mr: 1 }}
            // aria-label="search"
            onClick={() => {
              handleSearch();
            }}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <TableContainer className={classes.container}>
              <Table
                size="small"
                aria-label="a dense table"
                className={classes.container}
                sx={{ border: 'none' }}
              >
                <TableHead sx={{ maxHeight: '38px', bgcolor: '#FFFFFF' }}>
                  <TableRow sx={{ bgcolor: '#D9D9D9' }}>
                    <StyledTableCell sx={{ fontWeight: 'bold', width: '60px' }}>
                      モール
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: 'bold' }}>
                      商品番号
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: 'bold' }}>
                      商品名
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        onClick={() => {
                          handleChange('sales');
                        }}
                        color="inherit"
                        sx={{
                          padding: 0,
                          height: '32.5px',
                          width: '100%',
                          fontWeight: 'bold'
                        }}
                      >
                        売上
                        {checkedColumn === 'sales' && (
                          <ExpandMore
                            expand={orderDesc}
                            aria-expanded={orderDesc}
                            aria-label="sort"
                            sx={{ padding: 0 }}
                          >
                            <ExpandMoreIcon />
                          </ExpandMore>
                        )}
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: 'bold' }}>
                      前期比
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        onClick={() => {
                          handleChange('unit');
                        }}
                        color="inherit"
                        sx={{ fontWeight: 'bold' }}
                      >
                        販売個数
                        {checkedColumn === 'unit' && (
                          <ExpandMore
                            expand={orderDesc}
                            aria-expanded={orderDesc}
                            aria-label="sort"
                            sx={{ padding: 0 }}
                          >
                            <ExpandMoreIcon />
                          </ExpandMore>
                        )}
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: 'bold' }}>
                      前期比
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        onClick={() => {
                          handleChange('views');
                        }}
                        color="inherit"
                        sx={{
                          padding: 0,
                          height: '32.5px',
                          width: '100%',
                          fontWeight: 'bold'
                        }}
                      >
                        閲覧数
                        {checkedColumn === 'views' && (
                          <ExpandMore
                            expand={orderDesc}
                            aria-expanded={orderDesc}
                            aria-label="sort"
                            sx={{ padding: 0, fontWeight: 'bold' }}
                          >
                            <ExpandMoreIcon />
                          </ExpandMore>
                        )}
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: 'bold' }}>
                      前期比
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        onClick={() => {
                          handleChange('cvr');
                        }}
                        color="inherit"
                        sx={{
                          padding: 0,
                          height: '32.5px',
                          width: '100%',
                          fontWeight: 'bold'
                        }}
                      >
                        CVR
                        {checkedColumn === 'cvr' && (
                          <ExpandMore
                            expand={orderDesc}
                            aria-expanded={orderDesc}
                            aria-label="sort"
                            sx={{ padding: 0 }}
                          >
                            <ExpandMoreIcon />
                          </ExpandMore>
                        )}
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: 'bold' }}>
                      前期比
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        onClick={() => {
                          handleChange('price');
                        }}
                        color="inherit"
                        sx={{
                          padding: 0,
                          height: '32.5px',
                          width: '100%',
                          fontWeight: 'bold'
                        }}
                      >
                        販売単価
                        {checkedColumn === 'price' && (
                          <ExpandMore
                            expand={orderDesc}
                            aria-expanded={orderDesc}
                            aria-label="sort"
                            sx={{ padding: 0 }}
                          >
                            <ExpandMoreIcon />
                          </ExpandMore>
                        )}
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: 'bold' }}>
                      前期比
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ border: 'none' }}>
                  {items.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          '&:nth-of-type(even)': { bgcolor: '#E3E8F4' },
                          height: 100
                        }}
                        className={classes.tableHead}
                      >
                        <StyledTableCell>{row.channel}</StyledTableCell>
                        <StyledTableCell>{row.code}</StyledTableCell>
                        <StyledTableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between'
                            }}
                          >
                            <Typography sx={{ textAlign: 'left' }}>
                              {row.name}
                            </Typography>
                            <IconButton
                              onClick={() =>
                                asinNameClick(row.code, row.name, row.channel)
                              }
                            >
                              <OpenInNewIcon sx={{ fontSize: '16px' }} />
                            </IconButton>
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.sales.num == 0
                            ? '-'
                            : '¥' + row.sales.num.toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell>
                          <RateDataIcon rateNum={row.sales.rate} />
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.unit.num == 0
                            ? '-'
                            : row.unit.num.toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell>
                          <RateDataIcon rateNum={row.unit.rate} />
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.views.num == 0
                            ? '-'
                            : row.views.num.toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell>
                          <RateDataIcon rateNum={row.views.rate} />
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.cvr.num == 0
                            ? '-'
                            : row.cvr.num.toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell>
                          <RateDataIcon rateNum={row.cvr.rate} />
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.price.num == 0
                            ? '-'
                            : row.price.num.toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell>
                          <RateDataIcon rateNum={row.price.rate} />
                        </StyledTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        {!isLoading & (items.length == 0) ? (
          <Box pt={1}>
            <Typography>データがありません</Typography>
          </Box>
        ) : (
          <></>
        )}
      </Box>
      {!isLoading && (
        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 25, 100]}
          count={asinListLength}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

export default GroupItemSalesList;
