/* eslint-disable*/
import { Typography } from '@material-ui/core';
import {
  currentAcate1,
  currentAcate2,
  currentAcate3,
  currentAcate5,
  currentAcate6,
  currentRcate1,
  currentRcate2,
  currentRcate3,
  currentRcate4,
  currentRcate5,
  currentYcate1,
  currentYcate2,
  currentYcate3,
  currentYcate4
} from 'feactures/category/categoryGraphSlice';
import { currentchannel } from 'feactures/channel/channelSlice';
import { useSelector } from 'react-redux';

const CategoryShareLabel = () => {
  /* eslint-disable*/
  const channel = useSelector(currentchannel);

  const amazonCate1 = useSelector(currentAcate1);
  const amazonCate2 = useSelector(currentAcate2);
  const amazonCate3 = useSelector(currentAcate3);
  const amazonCate4 = useSelector(currentAcate5);
  const amazonCate5 = useSelector(currentAcate5);
  const amazonCate6 = useSelector(currentAcate6);

  const rakutenCate1 = useSelector(currentRcate1);
  const rakutenCate2 = useSelector(currentRcate2);
  const rakutenCate3 = useSelector(currentRcate3);
  const rakutenCate4 = useSelector(currentRcate4);
  const rakutenCate5 = useSelector(currentRcate5);

  const yahooCate1 = useSelector(currentYcate1);
  const yahooCate2 = useSelector(currentYcate2);
  const yahooCate3 = useSelector(currentYcate3);
  const yahoooCate4 = useSelector(currentYcate4);

  let categoryLabels = ['全てのカテゴリ'];
  function addToArray(...values) {
    let arr = ['全てのカテゴリ'];
    for (const value of values) {
      if (value !== 0) {
        arr.push(value.label);
      }
    }
    return arr;
  }
  if (channel == 1) {
    categoryLabels = addToArray(
      amazonCate1,
      amazonCate2,
      amazonCate3,
      amazonCate4,
      amazonCate5,
      amazonCate6
    );
  } else if (channel == 2) {
    categoryLabels = addToArray(
      rakutenCate1,
      rakutenCate2,
      rakutenCate3,
      rakutenCate4,
      rakutenCate5
    );
  } else if (channel == 3) {
    categoryLabels = addToArray(
      yahooCate1,
      yahooCate2,
      yahooCate3,
      yahoooCate4
    );
  }

  return (
    <>
      <Typography fontSize="14px" fontWeight="bold">
        {/* カテゴリ売上シェア：{categoryLabels[categoryLabels.length - 1]} */}
        カテゴリ売上シェア
      </Typography>
    </>
  );
};

export default CategoryShareLabel;
