/* eslint-disable*/
import { styled } from '@mui/material/styles';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  CircularProgress
} from '@material-ui/core';
import { useState } from 'react';
import MeasureAnalysisTableRow from 'components/molecules/dashboard/MeasureAnalysisTableRow';
import { reportData, reportIsLoading } from 'feactures/measure/newMeasureSlice';
import { useSelector } from 'react-redux';

const HeaderCell = styled(TableCell)(({ theme }) => ({
  fontSize: '12px',
  color: '#ffffff',
  textAlign: 'center',
  padding: '8px 2px',
  wordBreak: 'keep-all'
}));

export default function MeasureAnalysisTable({ premiumStatus }) {
  const data = useSelector(reportData);
  const isLoading = useSelector(reportIsLoading);
  // const isLoading = true;

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            width: '100%',
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper} sx={{ borderRadius: '10px' }}>
          <Table
            sx={{
              tableLayout: 'fixed',
              overflow: 'auto',
              width: '100%'
            }}
          >
            {/* width: autoをつけると、詳細テーブルを開いた時にテーブル幅が広がってしまう */}
            <TableHead
              sx={{ backgroundColor: '#728DC4', height: '60px' }}
              key={'measureAnalysisTableHead'}
            >
              <TableRow key={'measureAnalysis-tableHead'}>
                <HeaderCell width={'35px'}>No</HeaderCell>
                <HeaderCell></HeaderCell>
                <HeaderCell width={'80px'}>商品画像</HeaderCell>
                <HeaderCell width={'25%'}>商品名</HeaderCell>
                <HeaderCell>カテゴリ</HeaderCell>
                <HeaderCell>価格</HeaderCell>
                <HeaderCell>ランキング</HeaderCell>
                {premiumStatus ? (
                  <>
                    <HeaderCell colSpan={2} width={'130px'}>
                      レビュー
                    </HeaderCell>
                  </>
                ) : (
                  <>
                    <HeaderCell>レビュー</HeaderCell>
                  </>
                )}
                <HeaderCell width={'150px'}>SEOランク追跡</HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => (
                <MeasureAnalysisTableRow
                  item={item}
                  premiumStatus={premiumStatus}
                  index={index}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
