/* eslint-disable */
import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  useTheme,
  colors,
  Typography
} from '@material-ui/core';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

const MalltrendsGraph = (props) => {
  const { graphdata } = props; // eslint-disable-line
  const theme = useTheme();
  const hexToRgb = (hex) =>
    hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => '#' + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));

  const calculateLighterColor = (color, weight) => {
    const [r, g, b] = color;
    const w = weight;
    const rw = Math.round((255 - r) * w + r);
    const gw = Math.round((255 - g) * w + g);
    const bw = Math.round((255 - b) * w + b);

    return `rgba(${rw},${gw},${bw},1)`;
  };

  const generateGradientColors = (startColor, steps) => {
    const baseRgb = hexToRgb(startColor);
    const colorArray = [];
    for (let step = 0; step < steps; step++) {
      colorArray.push(calculateLighterColor(baseRgb, step / steps));
    }
    return colorArray;
  };

  const data = {
    labels: graphdata.label,
    datasets: [
      {
        label: 'Amazon',
        data: graphdata.amazon,
        backgroundColor: generateGradientColors('#3B5790', 3)[0],
        stack: 'Stack 1'
      },
      {
        label: '楽天',
        data: graphdata.rakuten,
        backgroundColor: generateGradientColors('#3B5790', 3)[1],
        stack: 'Stack 1'
      },
      {
        label: 'ヤフー',
        data: graphdata.yahoo,
        backgroundColor: generateGradientColors('#3B5790', 3)[2],
        stack: 'Stack 1'
      }
    ]
  };
  const options = {
    scales: {
      x: {
        grid: {
          display: false // x軸の目盛り線を非表示にする
        },
        stacked: true,
        ticks: {
          fontColor: theme.palette.text.secondary,
          font: {
            family: "'M PLUS 1p', sans-serif"
          }
        }
      },
      y: {
        grid: {
          display: false // y軸の目盛り線を非表示にする
        },
        stacked: true,
        ticks: {
          fontColor: theme.palette.text.secondary,
          font: {
            family: "'M PLUS 1p', sans-serif"
          },
          beginAtZero: true,
          min: 1,
          callback: function (value, index, values) {
            let returnValue = value;
            let returnStr = '';
            if (value >= 100000000) {
              returnValue = value / 100000000;
              returnStr = returnValue.toLocaleString() + '億円';
            } else if (value >= 1000000) {
              returnValue = value / 1000000;
              returnStr = returnValue.toLocaleString() + '百万円';
            } else if (value >= 10000) {
              returnValue = value / 10000;
              returnStr = returnValue.toLocaleString() + '万円';
            } else {
              returnValue = value;
              returnStr = returnValue.toLocaleString() + '円';
            }
            // ラベルの前に￥マークを追加
            return returnStr;
          }
        }
      }
    },
    cutoutPercentage: 90,
    // layout: { padding: { top: 30, bottom: 30 } },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    height: '100%',
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    },
    plugins: {
      legend: {
        display: true,
        position: 'top' // 凡例の表示位置を設定
      },
      datalabels: {
        display: false,
        align: 'center',
        anchor: 'center',
        color: '#ffffff',
        connectors: {
          color: 'gray'
        },
        formatter: (value, context) => {
          if (value == 0) {
            return '';
          }
          // ラベルのフォーマットを指定
          return `${value.toFixed(0)}%`;
        }
      }
    }
  };

  return (
    <Card {...props} sx={{ padding: 0 }}>
      <CardContent
        sx={{
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          ':last-child': { pb: 0 }
        }}
      >
        <Box
          sx={{
            height: 180,
            width: '100%'
          }}
        >
          <Bar data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default MalltrendsGraph;
