/* eslint-disable */
import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Box,
  Button,
  MenuList,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Stack,
  Typography
} from '@material-ui/core';
import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@material-ui/styles';
import { useEffect, useState, useRef } from 'react';
import theme from 'theme';
import {
  fetchAsinList,
  selectAsins,
  asinStatus,
  asinListLength
} from 'feactures/monitoring/asinSlice';
import { useSelector, useDispatch } from 'react-redux';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  currentAsin,
  selectMonitoringAsin,
  fetchAsinMonitor,
  fetchAsinMonitor2
} from 'feactures/monitoring/monitoringSlice';
import {
  changePageCount,
  asinListPageCount
} from 'feactures/monitoring/asinSlice';

const useStyles = makeStyles({
  customSelect: {
    color: 'black',
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      backgroundColor: '#EFEFEF'
    }
  },
  formControl: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.grey[400],
        borderWidth: 1
      }
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[400]
    }
  }
});

const AsinSelectPulldown = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const asinList = useSelector(selectAsins);
  const asinLength = useSelector(asinListLength);
  const asin = useSelector(currentAsin);
  const pageCount = useSelector(asinListPageCount);
  const isLoading = useSelector(asinStatus);
  const [selectedAsin, setSelectedAsin] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchAsinList());
    setSelectedAsin(asin.code);
  }, [pageCount]);

  const handleChange = (code, name, channel) => {
    // const asin_name = asinList.filter((item) => item.code === code);
    setSelectedAsin(code);
    dispatch(
      selectMonitoringAsin({
        code: code,
        name: name,
        channel: channel
      })
    );
    dispatch(fetchAsinMonitor());
    dispatch(fetchAsinMonitor2());
  };

  const [buttonPosition, setButtonPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef(null);

  const handleClick = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      console.log('rect', rect);
      setButtonPosition({ top: rect.bottom, left: rect.left });
      handleOpen();
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <FormControl className={classes.formControl}>
        <Stack sx={{ position: 'relative' }}>
          <LoadingButton
            ref={buttonRef}
            loading={isLoading}
            variant="outlined"
            size="small"
            sx={{
              backgroundColor: '#EFEFEF',
              borderColor: '#BDBDBD',

              width: '140px',
              height: '25px'
            }}
            color="inherit"
            onClick={handleClick}
          >
            <Typography
              sx={{
                fontSize: '14px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap'
              }}
            >
              {asin.code == '' ? 'ASINを選択する' : asin.code}
            </Typography>
          </LoadingButton>
          <Dialog
            open={open}
            onClose={handleClose}
            sx={{
              '& .MuiPaper-root': {
                transform: 'none', // ダイアログが中央に表示されるスタイルを解除,
                position: 'fixed',
                top: `${buttonPosition.top}px`,
                left: `${buttonPosition.left - 50}px`,
                margin: 0
              },
              '& .MuiBackdrop-root': {
                bgcolor: 'rgba(0,0,0,0)'
              }
            }}
          >
            <DialogContent
              sx={{
                width: 'fit-content',
                maxHeight: '50vh',
                p: 1
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                {asinList.map((item, index) => (
                  <Button
                    key={`${item.code} + ${index}`}
                    value={item.code}
                    disabled={isLoading || asinLength === 0}
                    color="inherit"
                    fontSize="16px"
                    onClick={() => {
                      handleChange(item.code, item.name, item.channel);
                    }}
                  >
                    {item.code}
                  </Button>
                ))}
              </Box>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Typography>
                  {(pageCount - 1) * 50 + 1} ~{' '}
                  {(pageCount - 1) * 50 + 50 > asinLength
                    ? asinLength
                    : (pageCount - 1) * 50 + 50}{' '}
                  / {asinLength}
                  件の結果
                </Typography>
                <Button
                  color="inherit"
                  variant="contained"
                  disabled={pageCount <= 1}
                  onClick={() => {
                    dispatch(changePageCount(-1));
                  }}
                  sx={{
                    height: '20px',
                    borderRadius: 160,
                    minWidth: '30px',
                    p: 0,
                    ml: 1
                  }}
                >
                  <ArrowBackIosNewIcon sx={{ height: '13px', width: '13px' }} />
                </Button>
                <Button
                  color="inherit"
                  variant="contained"
                  disabled={pageCount >= asinLength / 50}
                  onClick={() => {
                    dispatch(changePageCount(1));
                  }}
                  sx={{
                    height: '20px',
                    borderRadius: 160,
                    minWidth: '30px',
                    p: 0,
                    ml: 1
                  }}
                >
                  <ArrowForwardIosIcon sx={{ height: '13px', width: '13px' }} />
                </Button>
              </Box>
            </DialogContent>
          </Dialog>
        </Stack>
      </FormControl>
    </>
  );
};

export default AsinSelectPulldown;
