/* eslint-disable*/
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState, memo } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Paper,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
  Divider
} from '@material-ui/core'; // eslint-disable-line
import { allNews } from 'feactures/user/newsSlice';
import { AddAPhoto } from '@material-ui/icons';

const News = memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const params = useParams();
  const allNewsInfo = useSelector(allNews);
  const selectNews = allNewsInfo.find((news) => news['id'] == params.id); //選択したお知らせ

  const modoru = () => {
    navigate(-1);
  };

  useEffect(() => {
    // if (document.getElementById('card-content')) {
    const content = document.getElementById('card-content');
    // var div = document.createElement('div');
    // console.log(div);
    // div.style.display = 'none';
    content.innerHTML = selectNews.content; //html要素に変換
    // content.appendChild(div);
    // console.log(content);
    // }
  });

  // doc.appendChild(selectNews.content);
  // cosole.log(doc);
  // var unescapeHtml = function (target) {
  //   if (typeof target !== 'string') return target;

  //   var patterns = {
  //     '&lt;': '<',
  //     '&gt;': '>',
  //     '&amp;': '&',
  //     '&quot;': '"',
  //     '&#x27;': "'",
  //     '&#x60;': '`'
  //   };

  //   return target.replace(/&(lt|gt|amp|quot|#x27|#x60);/g, function (match) {
  //     return patterns[match];
  //   });
  // };
  // var result = unescapeHtml(selectNews.content);
  // console.log(result);

  // function createElementFromHTML(html) {
  //   const tempEl = document.createElement('div');
  //   tempEl.innerHTML = html;
  //   return tempEl.firstElementChild;
  // }
  // // let aaa = createElementFromHTML(selectNews.content);
  // let aaa = createElementFromHTML('<div style="width: 300px;"></div>');
  // console.log(aaa);
  //   const onSubmit = async (values) => {
  //     setError(null);
  //     await dispatch(fetchChangePass(values))
  //       .then(unwrapResult) //unwrapResult で,dispatch結果がfulfilledかrejectedかで条件分岐できる
  //       .then(() => {
  //         setSuccess(true);
  //         setTimeout(function () {
  //           navigate(-1);
  //         }, 1000);
  //       })
  //       .catch((err) => {
  //         setError(err.message);
  //       });
  //   };

  return (
    <>
      <Box
        sx={{
          height: 'auto',
          minHeight: '100vh',
          width: '100%'
        }}
      >
        <Box xs={12} textAlign="center" backgroundColor="#FFF8E5" pt={5}>
          <Typography variant="h1" fontWeight={'bold'}>
            お知らせ
          </Typography>
          <Typography variant="h6" mb={3}>
            TOPICS
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: '#fff',
            // height: '100%',
            justifyContent: 'center',
            py: 3,
            width: '90%',
            margin: 'auto'
          }}
        >
          <Container>
            <Divider />
            <Box py={3}>
              <Typography color="text.secondary" gutterBottom>
                {selectNews.startDate}
              </Typography>
              <Typography
                variant="h3"
                fontWeight={'bold'}
                component="div"
                mb={1}
              >
                {selectNews.title}
              </Typography>

              <CardContent
                sx={{ fontSize: 16 }}
                id="card-content"
              ></CardContent>
            </Box>
            <Divider />
          </Container>
        </Box>
      </Box>
    </>
  );
});

export default News;
