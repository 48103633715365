/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiUrl } from 'App.js';

export const fetchAsyncCategory1 = createAsyncThunk(
  'category1/get',
  async () => {
    const res = await axios.get(`${apiUrl}category1`);
    return res.data;
  }
);

export const fetchAmazonCategory1 = createAsyncThunk(
  'category1/amazon',
  async () => {
    const res = await axios.get(`${apiUrl}category1`);
    return res.data;
  }
);

export const fetchRakutenCategory1 = createAsyncThunk(
  'category1/rakuten',
  async () => {
    const res = await axios.get(`${apiUrl}category1`);
    return res.data;
  }
);

export const fetchYahooCategory1 = createAsyncThunk(
  'category1/yahoo',
  async () => {
    const res = await axios.get(`${apiUrl}category1`);
    return res.data;
  }
);

export const fetchAsyncCategory2 = createAsyncThunk(
  'category1/post',
  async (arg, thunkAPI) => {
    const { category1Id, channel } = arg;
    const categoryid = category1Id;
    const res = await axios.post(`${apiUrl}category1`, {
      post_category1: categoryid,
      channel: channel
    });
    return res.data;
  }
);
export const fetchAsyncCategory3 = createAsyncThunk(
  'category2/post',
  async (arg, thunkAPI) => {
    const { category2Id, channel } = arg;
    const categoryid = category2Id;
    const res = await axios.post(`${apiUrl}category2`, {
      post_category2: categoryid,
      channel: channel
    });
    // console.log(res.data);
    return res.data;
  }
);
export const fetchAsyncCategory4 = createAsyncThunk(
  'category3/post',
  async (arg, thunkAPI) => {
    const { category3Id, channel } = arg;
    const categoryid = category3Id;
    const res = await axios.post(`${apiUrl}category3`, {
      post_category3: categoryid,
      channel: channel
    });
    // console.log(res.data);
    return res.data;
  }
);
export const fetchAsyncCategory5 = createAsyncThunk(
  'category4/post',
  async (arg, thunkAPI) => {
    const { category4Id, channel } = arg;
    const categoryid = category4Id;
    const res = await axios.post(`${apiUrl}category4`, {
      post_category4: categoryid,
      channel: channel
    });
    return res.data;
  }
);
export const fetchAsyncCategory6 = createAsyncThunk(
  'category5/post',
  async (arg, thunkAPI) => {
    const { category5Id, channel } = arg;
    const categoryid = category5Id;
    const res = await axios.post(`${apiUrl}category5`, {
      post_category5: categoryid,
      channel: channel
    });
    // console.log(res.data);
    return res.data;
  }
);
export const fetchAsyncLastCategory = createAsyncThunk(
  'category6/post',
  async (arg, thunkAPI) => {
    const { category6Id, channel } = arg;
    const categoryid = category6Id;
    const res = await axios.post(`${apiUrl}category6`, {
      post_category6: categoryid,
      channel: channel
    });
    return res.data;
  }
);

// お気に入りカテゴリ選択時に一気にカテゴリ情報を取得する
export const categoriesFavoriteSelect = createAsyncThunk(
  'categories/favorite_select',
  async (arg, thunkAPI) => {
    const channel = thunkAPI.getState().currentchannel.channel;
    let categoriesList = [];
    if (channel === 1) {
      categoriesList =
        thunkAPI.getState().categorygraph.parentFavoriteCategoryAmazon;
    } else if (channel === 2) {
      categoriesList =
        thunkAPI.getState().categorygraph.parentFavoriteCategoryRakuten;
    } else if (channel === 3) {
      categoriesList =
        thunkAPI.getState().categorygraph.parentFavoriteCategoryYahoo;
    }

    let category1 = [];
    let category2 = [];
    let category3 = [];
    let category4 = [];
    let category5 = [];
    let category6 = [];

    const category1_result = await axios.get(`${apiUrl}category1`, {});
    if (channel === 1) {
      category1 = category1_result.data.CategoryAmazonResultList;
    } else if (channel === 2) {
      category1 = category1_result.data.CategoryRakutenResultList;
    } else if (channel === 3) {
      category1 = category1_result.data.CategoryYahooResultList;
    }

    if (categoriesList[0] !== 0) {
      const result = await axios.post(`${apiUrl}category1`, {
        post_category1: categoriesList[0].value,
        channel: channel
      });
      if (channel === 1) {
        category2 = result.data.CategoryAmazonResultList;
      } else if (channel === 2) {
        category2 = result.data.CategoryRakutenResultList;
      } else if (channel === 3) {
        category2 = result.data.CategoryYahooResultList;
      }
    }

    if (categoriesList[1] !== 0) {
      const result = await axios.post(`${apiUrl}category2`, {
        post_category2: categoriesList[1].value,
        channel: channel
      });
      if (channel === 1) {
        category3 = result.data.CategoryAmazonResultList;
      } else if (channel === 2) {
        category3 = result.data.CategoryRakutenResultList;
      } else if (channel === 3) {
        category3 = result.data.CategoryYahooResultList;
      }
    }

    if (categoriesList[2] !== 0) {
      const result = await axios.post(`${apiUrl}category3`, {
        post_category3: categoriesList[2].value,
        channel: channel
      });
      if (channel === 1) {
        category4 = result.data.CategoryAmazonResultList;
      } else if (channel === 2) {
        category4 = result.data.CategoryRakutenResultList;
      } else if (channel === 3) {
        category4 = result.data.CategoryYahooResultList;
      }
    }

    if (categoriesList[3] !== 0) {
      const result = await axios.post(`${apiUrl}category4`, {
        post_category4: categoriesList[3].value,
        channel: channel
      });
      if (channel === 1) {
        category5 = result.data.CategoryAmazonResultList;
      } else if (channel === 2) {
        category5 = result.data.CategoryRakutenResultList;
      } else if (channel === 3) {
        category5 = result.data.CategoryYahooResultList;
      }
    }

    if (categoriesList[4] !== 0) {
      const result = await axios.post(`${apiUrl}category5`, {
        post_category5: categoriesList[4].value,
        channel: channel
      });
      if (channel === 1) {
        category6 = result.data.CategoryAmazonResultList;
      } else if (channel === 2) {
        category6 = result.data.CategoryRakutenResultList;
      } else if (channel === 3) {
        category6 = result.data.CategoryYahooResultList;
      }
    }

    const return_data = {
      channel: channel,
      categoryDict: {
        category1: category1,
        category2: category2,
        category3: category3,
        category4: category4,
        category5: category5,
        category6: category6
      }
    };

    return return_data;
  }
);

// 店舗/ブランド比較ページでのカテゴリ選択(自社商品のカテゴリのみ選択)
export const fetchOwnCategory = createAsyncThunk(
  'category_own',
  async (arg, thunkAPI) => {
    const { accountId } = thunkAPI.getState().user;

    const res = await axios.post(`${apiUrl}category/own`, {
      account_id: accountId
    });
    return res.data;
  }
);

export const fetchAmazonOwnCategory = createAsyncThunk(
  'amazon_category_own',
  async (arg, thunkAPI) => {
    const { accountId } = thunkAPI.getState().user;

    const res = await axios.post(`${apiUrl}category/own`, {
      account_id: accountId
    });
    return res.data;
  }
);

export const fetchRakutenOwnCategory = createAsyncThunk(
  'rakuten_category_own',
  async (arg, thunkAPI) => {
    const { accountId } = thunkAPI.getState().user;

    const res = await axios.post(`${apiUrl}category/own`, {
      account_id: accountId
    });
    return res.data;
  }
);

export const fetchYahooOwnCategory = createAsyncThunk(
  'yahoo_category_own',
  async (arg, thunkAPI) => {
    const { accountId } = thunkAPI.getState().user;

    const res = await axios.post(`${apiUrl}category/own`, {
      account_id: accountId
    });
    return res.data;
  }
);

export const fetchOwnLowerCategory = createAsyncThunk(
  'category_own/lower',
  async (arg, thunkAPI) => {
    const { accountId } = thunkAPI.getState().user;
    const { selected_category, level } = arg;
    const { channel } = thunkAPI.getState().currentchannel;

    const res = await axios.post(`${apiUrl}category/own_lower`, {
      selected_category: selected_category,
      level: level,
      account_id: accountId,
      channel: channel
    });
    return res.data;
  }
);

const fetchCategorySlice = createSlice({
  name: 'categories',
  initialState: {
    currentCategory: { id: 'all', level: 0 },
    ownAmazonCategories: {
      category1: [],
      category2: [],
      category3: [],
      category4: [],
      category5: [],
      category6: []
    },
    amazonCategories: {
      category1: [],
      category2: [],
      category3: [],
      category4: [],
      category5: [],
      category6: []
    },
    rakutenCategories: {
      category1: [],
      category2: [],
      category3: [],
      category4: [],
      category5: [],
      category6: []
    },
    yahooCategories: {
      category1: [],
      category2: [],
      category3: [],
      category4: [],
      category5: [],
      category6: []
    },
    test: { value: 'aaa', label: 'bbb' },
    isLoading: false
  },
  reducers: {
    changeCategoryLabel: (state, action) => {
      return {
        ...state.test,
        test: action.payload
      };
    },
    changeCurrentCategory: (state, action) => {
      state.currentCategory = action.payload;
    },
    resetAmazonCategories: (state, action) => {
      state.amazonCategories = {
        category1: state.amazonCategories.category1,
        category2: [],
        category3: [],
        category4: [],
        category5: [],
        category6: []
      };
    },
    resetRakutenCategories: (state, action) => {
      state.rakutenCategories = {
        category1: state.rakutenCategories.category1,
        category2: [],
        category3: [],
        category4: [],
        category5: [],
        category6: []
      };
    },
    resetYahooCategories: (state, action) => {
      state.yahooCategories = {
        category1: state.yahooCategories.category1,
        category2: [],
        category3: [],
        category4: [],
        category5: [],
        category6: []
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAsyncCategory1.fulfilled, (state, action) => {
      return {
        ...state,
        amazonCategories: {
          category1: action.payload.CategoryAmazonResultList,
          category2: [],
          category3: [],
          category4: [],
          category5: [],
          category6: []
        },
        rakutenCategories: {
          category1: action.payload.CategoryRakutenResultList,
          category2: [],
          category3: [],
          category4: [],
          category5: [],
          category6: []
        },
        yahooCategories: {
          category1: action.payload.CategoryYahooResultList,
          category2: [],
          category3: [],
          category4: [],
          category5: [],
          category6: []
        },
        isLoading: false
      };
    });
    builder.addCase(fetchAsyncCategory1.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAsyncCategory1.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(fetchAmazonCategory1.fulfilled, (state, action) => {
      return {
        ...state,
        amazonCategories: {
          category1: action.payload.CategoryAmazonResultList,
          category2: [],
          category3: [],
          category4: [],
          category5: [],
          category6: []
        },
        isLoading: false
      };
    });

    builder.addCase(fetchRakutenCategory1.fulfilled, (state, action) => {
      return {
        ...state,
        rakutenCategories: {
          category1: action.payload.CategoryRakutenResultList,
          category2: [],
          category3: [],
          category4: [],
          category5: [],
          category6: []
        },
        isLoading: false
      };
    });

    builder.addCase(fetchYahooCategory1.fulfilled, (state, action) => {
      return {
        ...state,
        yahooCategories: {
          category1: action.payload.CategoryYahooResultList,
          category2: [],
          category3: [],
          category4: [],
          category5: [],
          category6: []
        },
        isLoading: false
      };
    });

    builder.addCase(fetchAsyncCategory2.fulfilled, (state, action) => {
      if (action.payload.CategoryAmazonResultList) {
        return {
          amazonCategories: {
            ...state.amazonCategories,
            category1: state.amazonCategories.category1,
            category2: action.payload.CategoryAmazonResultList,
            category3: [],
            category4: [],
            category5: [],
            category6: []
          },
          rakutenCategories: { ...state.rakutenCategories },
          yahooCategories: { ...state.yahooCategories }
        };
      } else if (action.payload.CategoryRakutenResultList) {
        return {
          rakutenCategories: {
            ...state.rakutenCategories,
            category1: state.rakutenCategories.category1,
            category2: action.payload.CategoryRakutenResultList,
            category3: [],
            category4: [],
            category5: [],
            category6: []
          },
          amazonCategories: { ...state.amazonCategories },
          yahooCategories: { ...state.yahooCategories }
        };
      } else if (action.payload.CategoryYahooResultList.length !== 0) {
        return {
          yahooCategories: {
            ...state.yahooCategories,
            category1: state.yahooCategories.category1,
            category2: action.payload.CategoryYahooResultList,
            category3: [],
            category4: [],
            category5: [],
            category6: []
          },
          amazonCategories: { ...state.amazonCategories },
          rakutenCategories: { ...state.rakutenCategories }
        };
      }
    });
    builder.addCase(fetchAsyncCategory3.fulfilled, (state, action) => {
      if (action.payload.CategoryAmazonResultList) {
        return {
          amazonCategories: {
            ...state.amazonCategories,
            category1: state.amazonCategories.category1,
            category2: state.amazonCategories.category2,
            category3: action.payload.CategoryAmazonResultList,
            category4: [],
            category5: [],
            category6: []
          },
          rakutenCategories: { ...state.rakutenCategories },
          yahooCategories: { ...state.yahooCategories }
        };
      } else if (action.payload.CategoryRakutenResultList) {
        return {
          rakutenCategories: {
            ...state.rakutenCategories,
            category1: state.rakutenCategories.category1,
            category2: state.rakutenCategories.category2,
            category3: action.payload.CategoryRakutenResultList,
            category4: [],
            category5: [],
            category6: []
          },
          amazonCategories: { ...state.amazonCategories },
          yahooCategories: { ...state.yahooCategories }
        };
      } else if (action.payload.CategoryYahooResultList) {
        return {
          yahooCategories: {
            ...state.yahooCategories,
            category1: state.yahooCategories.category1,
            category2: state.yahooCategories.category2,
            category3: action.payload.CategoryYahooResultList,
            category4: [],
            category5: [],
            category6: []
          },
          amazonCategories: { ...state.amazonCategories },
          rakutenCategories: { ...state.rakutenCategories }
        };
      }
    });
    builder.addCase(fetchAsyncCategory4.fulfilled, (state, action) => {
      if (action.payload.CategoryAmazonResultList) {
        return {
          amazonCategories: {
            ...state.amazonCategories,
            category1: state.amazonCategories.category1,
            category2: state.amazonCategories.category2,
            category3: state.amazonCategories.category3,
            category4: action.payload.CategoryAmazonResultList,
            category5: [],
            category6: []
          },
          rakutenCategories: { ...state.rakutenCategories },
          yahooCategories: { ...state.yahooCategories }
        };
      } else if (action.payload.CategoryRakutenResultList) {
        return {
          rakutenCategories: {
            ...state.rakutenCategories,
            category1: state.rakutenCategories.category1,
            category2: state.rakutenCategories.category2,
            category3: state.rakutenCategories.category3,
            category4: action.payload.CategoryRakutenResultList,
            category5: [],
            category6: []
          },
          amazonCategories: { ...state.amazonCategories },
          yahooCategories: { ...state.yahooCategories }
        };
      } else if (action.payload.CategoryYahooResultList) {
        return {
          yahooCategories: {
            ...state.yahooCategories,
            category1: state.yahooCategories.category1,
            category2: state.yahooCategories.category2,
            category3: state.yahooCategories.category3,
            category4: action.payload.CategoryYahooResultList,
            category5: [],
            category6: []
          },
          amazonCategories: { ...state.amazonCategories },
          rakutenCategories: { ...state.rakutenCategories }
        };
      }
    });
    builder.addCase(fetchAsyncCategory5.fulfilled, (state, action) => {
      if (action.payload.CategoryAmazonResultList) {
        return {
          amazonCategories: {
            ...state.amazonCategories,
            category1: state.amazonCategories.category1,
            category2: state.amazonCategories.category2,
            category3: state.amazonCategories.category3,
            category4: state.amazonCategories.category4,
            category5: action.payload.CategoryAmazonResultList,
            category6: []
          },
          rakutenCategories: { ...state.rakutenCategories },
          yahooCategories: { ...state.yahooCategories }
        };
      } else if (action.payload.CategoryRakutenResultList) {
        return {
          rakutenCategories: {
            ...state.rakutenCategories,
            category1: state.rakutenCategories.category1,
            category2: state.rakutenCategories.category2,
            category3: state.rakutenCategories.category3,
            category4: state.rakutenCategories.category4,
            category5: action.payload.CategoryRakutenResultList,
            category6: []
          },
          amazonCategories: { ...state.amazonCategories },
          yahooCategories: { ...state.yahooCategories }
        };
      } else if (action.payload.CategoryYahooResultList) {
        return {
          yahooCategories: {
            ...state.yahooCategories,
            category1: state.yahooCategories.category1,
            category2: state.yahooCategories.category2,
            category3: state.yahooCategories.category3,
            category4: state.yahooCategories.category4,
            category5: action.payload.CategoryYahooResultList,
            category6: []
          },
          amazonCategories: { ...state.amazonCategories },
          rakutenCategories: { ...state.rakutenCategories }
        };
      }
    });
    builder.addCase(fetchAsyncCategory6.fulfilled, (state, action) => {
      if (action.payload.CategoryAmazonResultList) {
        return {
          amazonCategories: {
            ...state.amazonCategories,
            category1: state.amazonCategories.category1,
            category2: state.amazonCategories.category2,
            category3: state.amazonCategories.category3,
            category4: state.amazonCategories.category4,
            category5: state.amazonCategories.category5,
            category6: action.payload.CategoryAmazonResultList
          },
          rakutenCategories: { ...state.rakutenCategories },
          yahooCategories: { ...state.yahooCategories }
        };
      } else if (action.payload.CategoryRakutenResultList) {
        return {
          rakutenCategories: {
            ...state.rakutenCategories,
            category1: state.rakutenCategories.category1,
            category2: state.rakutenCategories.category2,
            category3: state.rakutenCategories.category3,
            category4: state.rakutenCategories.category4,
            category5: state.rakutenCategories.category5,
            category6: action.payload.CategoryRakutenResultList
          },
          amazonCategories: { ...state.amazonCategories },
          yahooCategories: { ...state.yahooCategories }
        };
      } else if (action.payload.CategoryYahooResultList) {
        return {
          yahooCategories: {
            ...state.yahooCategories,
            category1: state.yahooCategories.category1,
            category2: state.yahooCategories.category2,
            category3: state.yahooCategories.category3,
            category4: state.yahooCategories.category4,
            category5: state.yahooCategories.category5,
            category6: action.payload.CategoryYahooResultList
          },
          amazonCategories: { ...state.amazonCategories },
          rakutenCategories: { ...state.rakutenCategories }
        };
      }
    });
    builder.addCase(fetchAsyncLastCategory.fulfilled, (state, action) => {
      if (action.payload.CategoryAmazonResultList) {
        return {
          amazonCategories: {
            ...state.amazonCategories,
            category1: state.amazonCategories.category1,
            category2: state.amazonCategories.category2,
            category3: state.amazonCategories.category3,
            category4: state.amazonCategories.category4,
            category5: state.amazonCategories.category5,
            category6: action.payload.CategoryAmazonResultList
          },
          rakutenCategories: { ...state.rakutenCategories },
          yahooCategories: { ...state.yahooCategories }
        };
      } else if (action.payload.CategoryRakutenResultList) {
        return {
          rakutenCategories: {
            ...state.rakutenCategories,
            category1: state.rakutenCategories.category1,
            category2: state.rakutenCategories.category2,
            category3: state.rakutenCategories.category3,
            category4: state.rakutenCategories.category4,
            category5: state.rakutenCategories.category5,
            category6: action.payload.CategoryRakutenResultList
          },
          amazonCategories: { ...state.amazonCategories },
          yahooCategories: { ...state.yahooCategories }
        };
      } else if (action.payload.CategoryYahooResultList) {
        return {
          yahooCategories: {
            ...state.yahooCategories,
            category1: state.yahooCategories.category1,
            category2: state.yahooCategories.category2,
            category3: state.yahooCategories.category3,
            category4: state.yahooCategories.category4,
            category5: state.yahooCategories.category5,
            category6: action.payload.CategoryYahooResultList
          },
          amazonCategories: { ...state.amazonCategories },
          rakutenCategories: { ...state.rakutenCategories }
        };
      }
    });
    builder.addCase(categoriesFavoriteSelect.fulfilled, (state, action) => {
      if (action.payload.channel === 1) {
        state.amazonCategories = action.payload.categoryDict;
      } else if (action.payload.channel === 2) {
        state.rakutenCategories = action.payload.categoryDict;
      } else if (action.payload.channel === 3) {
        state.yahooCategories = action.payload.categoryDict;
      }
    });

    builder.addCase(fetchOwnCategory.fulfilled, (state, action) => {
      return {
        ...state,
        amazonCategories: {
          category1: action.payload.CategoryAmazonResultList,
          category2: [],
          category3: [],
          category4: [],
          category5: [],
          category6: []
        },
        rakutenCategories: {
          category1: action.payload.CategoryRakutenResultList,
          category2: [],
          category3: [],
          category4: [],
          category5: [],
          category6: []
        },
        yahooCategories: {
          category1: action.payload.CategoryYahooResultList,
          category2: [],
          category3: [],
          category4: [],
          category5: [],
          category6: []
        },
        isLoading: false
      };
    });
    builder.addCase(fetchOwnCategory.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchOwnCategory.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(fetchOwnLowerCategory.fulfilled, (state, action) => {
      const level = action.payload.level;
      const channel = action.payload.channel;
      const category_level = `category${level}`;
      if (channel === 1) {
        state.amazonCategories[category_level] = action.payload.categories;
      } else if (channel === 2) {
        state.rakutenCategories[category_level] = action.payload.categories;
      } else {
        state.yahooCategories[category_level] = action.payload.categories;
      }
    });

    builder.addCase(fetchAmazonOwnCategory.fulfilled, (state, action) => {
      return {
        ...state,
        amazonCategories: {
          category1: action.payload.CategoryAmazonResultList,
          category2: [],
          category3: [],
          category4: [],
          category5: [],
          category6: []
        },
        isLoading: false
      };
    });
    builder.addCase(fetchRakutenOwnCategory.fulfilled, (state, action) => {
      return {
        ...state,
        rakutenCategories: {
          category1: action.payload.CategoryRakutenResultList,
          category2: [],
          category3: [],
          category4: [],
          category5: [],
          category6: []
        },
        isLoading: false
      };
    });
    builder.addCase(fetchYahooOwnCategory.fulfilled, (state, action) => {
      return {
        ...state,
        yahooCategories: {
          category1: action.payload.CategoryYahooResultList,
          category2: [],
          category3: [],
          category4: [],
          category5: [],
          category6: []
        },
        isLoading: false
      };
    });
  }
});

export const amazonCategory = (state) => state.categories.amazonCategories;
export const rakutenCategory = (state) => state.categories.rakutenCategories;
export const yahooCategory = (state) => state.categories.yahooCategories;
export const testCategory = (state) => state.categories.test;
export const isLoading = (state) => state.categories.isLoading;

export const {
  changeCategoryLabel,
  changeCurrentCategory,
  resetAmazonCategories,
  resetRakutenCategories,
  resetYahooCategories
} = fetchCategorySlice.actions;

export default fetchCategorySlice.reducer;
