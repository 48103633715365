/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';
import { fetchGroupDataList } from 'feactures/monitoring/groupSlice';

export const fetchOverview = createAsyncThunk(
  'overview/post',
  async (arg, thunkAPI) => {
    const { term, start, end } = await thunkAPI.getState().date;
    const { userId } = thunkAPI.getState().user;
    const { channel } = thunkAPI.getState().currentchannel;
    const res = await client.post(`${apiUrl}overview`, {
      start: start,
      end: end,
      term: term,
      channel: channel,
      user_id: userId
    });
    return res.data;
  }
);

// 商品売上テーブルのデータ取得
export const fetchAsyncAsinSales = createAsyncThunk(
  'newSalesByAsin/post',
  async (arg, thunkAPI) => {
    const { searchword } = arg;
    const { userId } = thunkAPI.getState().user;
    const { term, start, end } = await thunkAPI.getState().date;
    const { channel } = thunkAPI.getState().currentchannel;
    const { displayObject } = thunkAPI.getState().monitoringAsin;
    const { existingASIN } = thunkAPI.getState().monitoringAsin;
    const { isGroup } = thunkAPI.getState().group;
    const { page, perpage, orderby, order } =
      thunkAPI.getState().overview.asinSalesData;

    const res = await client.post(`${apiUrl}newSalesByAsin`, {
      start: start,
      end: end,
      term: term,
      channel: channel,
      user_id: userId,
      page: page,
      display_object: displayObject,
      existing_asin: existingASIN,
      per_page: perpage,
      orderby: orderby,
      order: order,
      searchword: searchword,
      display_group: isGroup
    });
    return res.data;
  }
);

// グループごとの売上等のデータ取得
export const fetchOverviewGroupData = createAsyncThunk(
  'overview/group/salesData',
  async (arg, thunkApi) => {
    const { searchword } = arg;
    const { term, start, end } = thunkApi.getState().date;
    const { userId } = thunkApi.getState().user;
    const { pageCount, order, orderby } =
      thunkApi.getState().overview.groupSalesData;

    const res = await client.post(`${apiUrl}newSalesByGroup`, {
      user_id: userId,
      term: term,
      start: start,
      end: end,
      pageCount: pageCount,
      per_page: 8,
      searchword: searchword,
      order: order,
      orderby: orderby
    });
    return res.data;
  }
);

const initialstate = {
  sales: 0,
  views: 0,
  cvr: 0,
  price: 0,
  salesRate: 0,
  viewsRate: 0,
  cvrRate: 0,
  priceRate: 0
};

const overviewSlice = createSlice({
  name: 'overview',
  initialState: {
    data: {
      kpi_dict: {
        all_kpi_dict: initialstate,
        amazon_kpi_dict: initialstate,
        rakuten_kpi_dict: initialstate,
        yahoo_kpi_dict: initialstate
      },
      sales_share_graph: {
        graphdata: [],
        label: ['Amazon', '楽天', 'Yahoo']
      },
      sales_by_mall_graph: { amazon: [], rakuten: [], yahoo: [], label: [] }
    },
    groupSalesData: {
      pageCount: 1,
      length: 0,
      orderby: 'sales',
      order: 'desc',
      data: [],
      share_graph: {
        graphdata: [],
        label: []
      },
      isLoading: false,
      error: false
    },
    asinSalesData: {
      allMall: [],
      amazon: [],
      rakuten: [],
      yahoo: [],
      len: 0,
      page: 0,
      perpage: 10,
      orderby: 'sales',
      order: 'desc',
      searchword: '',
      isLoading: false,
      error: false
    },
    isLoading: false
  },
  reducers: {
    setOvAsinSalesDataPage(state, action) {
      state.asinSalesData.page = action.payload;
    },
    setOvAsinSalesDataPerPage(state, action) {
      state.asinSalesData.perpage = action.payload;
    },
    setOvAsinSalesDataPerOrderby(state, action) {
      state.asinSalesData.orderby = action.payload;
    },
    setOvAsinSalesDataPerOrder(state, action) {
      state.asinSalesData.order = action.payload;
    },
    setOvAsinSalesDataSearchword(state, action) {
      state.asinSalesData.searchword = action.payload;
    },
    // グループ売上テーブルのページネーション用
    changeGroupDataPageCount(state, action) {
      state.groupSalesData.pageCount += action.payload;
    },
    // グループ売上データの並び替え用(基準となるカラムの変更)
    changeOrderBy(state, action) {
      state.groupSalesData.orderby = action.payload;
    },
    // グループ売上データの並び替え用(昇順・降順の変更)
    changeOrder(state, action) {
      state.groupSalesData.order = action.payload;
    },
    resetAsinPage(state, action) {
      state.asinSalesData.page = 0;
    },
    resetGroupPage(state, action) {
      state.groupSalesData.pageCount = 1;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOverview.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    });
    builder.addCase(fetchOverview.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchOverview.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(fetchAsyncAsinSales.fulfilled, (state, action) => {
      if (action.payload.channel == 'allMall') {
        state.asinSalesData.allMall = action.payload.asin;
        state.asinSalesData.len = action.payload.total;
        state.asinSalesData.page = action.payload.page;
        state.asinSalesData.perpage = action.payload.perpage;
        state.asinSalesData.order = action.payload.order;
        state.asinSalesData.orderby = action.payload.orderby;
        state.asinSalesData.searchword = action.payload.searchWord;
        state.asinSalesData.isLoading = false;
      }
      if (action.payload.channel == 'amazon') {
        state.asinSalesData.amazon = action.payload.asin;
        state.asinSalesData.len = action.payload.total;
        state.asinSalesData.page = action.payload.page;
        state.asinSalesData.perpage = action.payload.perpage;
        state.asinSalesData.order = action.payload.order;
        state.asinSalesData.orderby = action.payload.orderby;
        state.asinSalesData.searchword = action.payload.searchWord;
        state.asinSalesData.isLoading = false;
      }
      if (action.payload.channel == 'rakuten') {
        state.asinSalesData.rakuten = action.payload.asin;
        state.asinSalesData.len = action.payload.total;
        state.asinSalesData.page = action.payload.page;
        state.asinSalesData.perpage = action.payload.perpage;
        state.asinSalesData.order = action.payload.order;
        state.asinSalesData.orderby = action.payload.orderby;
        state.asinSalesData.searchword = action.payload.searchWord;
        state.asinSalesData.isLoading = false;
      }
      if (action.payload.channel == 'yahoo') {
        state.asinSalesData.yahoo = action.payload.asin;
        state.asinSalesData.len = action.payload.total;
        state.asinSalesData.page = action.payload.page;
        state.asinSalesData.perpage = action.payload.perpage;
        state.asinSalesData.order = action.payload.order;
        state.asinSalesData.orderby = action.payload.orderby;
        state.asinSalesData.searchword = action.payload.searchWord;
        state.asinSalesData.isLoading = false;
      }
    });
    builder.addCase(fetchAsyncAsinSales.pending, (state, action) => {
      state.asinSalesData.isLoading = true;
    });
    builder.addCase(fetchAsyncAsinSales.rejected, (state, action) => {
      state.asinSalesData.isLoading = false;
      state.asinSalesData.error = true;
    });
    builder.addCase(fetchOverviewGroupData.fulfilled, (state, action) => {
      return {
        ...state,
        groupSalesData: {
          ...state.groupSalesData,
          length: action.payload.length,
          data: action.payload.data,
          share_graph: action.payload.share_graph,
          isLoading: false,
          error: false
        }
      };
    });
    builder.addCase(fetchOverviewGroupData.pending, (state, action) => {
      return {
        ...state,
        groupSalesData: {
          ...state.groupSalesData,
          isLoading: true,
          error: false
        }
      };
    });
    builder.addCase(fetchOverviewGroupData.rejected, (state, action) => {
      return {
        ...state,
        groupSalesData: {
          ...state.groupSalesData,
          isLoading: false,
          error: true
        }
      };
    });
  }
});

export const {
  setOvAsinSalesDataPerOrder,
  setOvAsinSalesDataPage,
  setOvAsinSalesDataPerOrderby,
  setOvAsinSalesDataPerPage,
  setOvAsinSalesDataSearchword,
  changeGroupDataPageCount,
  changeOrder,
  changeOrderBy,
  resetAsinPage,
  resetGroupPage
} = overviewSlice.actions;
export const sales_share_graph = (state) =>
  state.overview.data.sales_share_graph;
export const sales_by_mall_graph = (state) =>
  state.overview.data.sales_by_mall_graph;
export const kpi_dict = (state) => state.overview.data.kpi_dict;
export const ovAllMallAsinList = (state) =>
  state.overview.asinSalesData.allMall;
export const ovAmazonAsinList = (state) => state.overview.asinSalesData.amazon;
export const ovRakutenAsinList = (state) =>
  state.overview.asinSalesData.rakuten;
export const ovYahoonAsinList = (state) => state.overview.asinSalesData.yahoo;
export const ovAsinlen = (state) => state.overview.asinSalesData.len;
export const ovAsinPage = (state) => state.overview.asinSalesData.page;
export const ovAsinPerPage = (state) => state.overview.asinSalesData.perpage;
export const ovAsinOrder = (state) => state.overview.asinSalesData.order;
export const ovAsinOrderby = (state) => state.overview.asinSalesData.orderby;
export const ovAsinSearchword = (state) =>
  state.overview.asinSalesData.searchword;
export const ovAsinLoading = (state) => state.overview.asinSalesData.isLoading;

// オーバービューのグループ売上テーブル表示用
export const groupSalesData = (state) => state.overview.groupSalesData.data;
export const groupSalesDataPage = (state) =>
  state.overview.groupSalesData.pageCount;
export const groupSalesDataLength = (state) =>
  state.overview.groupSalesData.length;
export const groupSalesDataOrderBy = (state) =>
  state.overview.groupSalesData.orderby;
export const groupSalesDataOrder = (state) =>
  state.overview.groupSalesData.order;
export const ovGroupLoading = (state) =>
  state.overview.groupSalesData.isLoading;
export const ovGroupShareGraph = (state) =>
  state.overview.groupSalesData.share_graph;

export default overviewSlice.reducer;
