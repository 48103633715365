/* eslint-disable*/
import { Box, Typography, CircularProgress, useTheme } from '@material-ui/core';
import MeasurePullDown from 'components/atoms/button/MeasurePulldown';
import { useSelector } from 'react-redux';
import { Line } from 'react-chartjs-2';
import { termArray } from 'feactures/date/dateSlice';
import 'chartjs-adapter-moment';
import {
  graphTitle,
  rankingChangeGraphData,
  rankingChangeGraphLoading,
  reportIsLoading,
  reportData,
  selectedMeasure
} from 'feactures/measure/newMeasureSlice';
import { currentchannel } from 'feactures/channel/channelSlice';

const RankingChangeGraph = ({ premiumStatus }) => {
  const graphloading = useSelector(reportIsLoading); // ランキング推移の読み込み中のローディングステータス
  const term = useSelector(termArray);
  const theme = useTheme();
  const title = useSelector(graphTitle);
  const measure = useSelector(selectedMeasure);
  const report_data = useSelector(reportData);
  const channel = useSelector(currentchannel);
  // const graphdata_list = useSelector(rankingChangeGraphData);

  const graphColorList = [
    '#332288',
    '#88CCEE',
    '#44AA99',
    '#117733',
    '#999933',
    '#DDCC77',
    '#CC6677',
    '#882255',
    '#AA4499',
    '#DDDDDD'
  ];

  const data = {
    datasets: report_data.map((data, index) => {
      return {
        label:
          channel == 1 ? data.item_id : `${data.shop_code}:${data.item_id}`,
        radius: 0,
        barThickness: 'flex',
        backgroundColor: graphColorList[index % graphColorList.length],
        hoverBackgroundColor: graphColorList[index % graphColorList.length],
        borderColor: graphColorList[index % graphColorList.length],
        data:
          measure === 'rank'
            ? data.report[measure].map((x) =>
                x == -99 ? (x = '圏外') : x == 0 ? (x = 'データ無し') : (x = x)
              )
            : data.report[measure].map((x) =>
                x == 0 ? (x = 'データ無し') : (x = x)
              ),
        maxBarThickness: 20,
        pointRadius: 4,
        pointStyle: 'circle',
        pointBorderColor: graphColorList[index % graphColorList.length],
        pointBackgroundColor: graphColorList[index % graphColorList.length],
        pointBorderWidth: 1,
        lineTension: 0.25
      };
    }),
    labels: term
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false
        },
        title: {
          display: false
        },
        ticks: {
          font: {
            size: '10px'
          }
        }
      },
      y: {
        // min: measure === 'rank' ? 1 : 0,
        reverse: measure === 'rank',
        ticks: {
          fontColor: theme.palette.text.secondary,
          font: {
            family: "'M PLUS 1p', sans-serif"
          },
          callback: function (value, index, values) {
            if (value === 0) {
              // 0を非表示にする
              return '';
            }
            let returnValue = value;
            if (measure == 'rank') {
              if (value == 0) {
                returnValue = '1位';
              } else {
                returnValue = value + '位';
              }
            } else if (measure == 'price' || measure == 'real_price') {
              returnValue = '¥' + value.toLocaleString();
            } else if (
              measure == 'point' ||
              measure == 'coupon' ||
              measure == 'sale'
            ) {
              returnValue = value + '%';
            }
            return returnValue;
          }
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider
        }
      }
    },
    plugins: {
      tooltip: {
        titleFont: {
          family: "'M PLUS 1p', sans-serif"
        },
        bodyFont: {
          family: "'M PLUS 1p', sans-serif"
        },
        callbacks: {
          label: (tooltipItem) => {
            let value = tooltipItem.raw;
            return `${tooltipItem.dataset.label}: ${value}`;
          }
        }
      },
      title: {
        display: true,
        text: title,
        position: 'left'
      },
      legend: {
        labels: {
          boxWidth: 40,
          boxHeight: 10
          //   usePointStyle: true
          //   pointStyle: 'circle'
        },
        position: 'top'
      },
      datalabels: {
        display: false
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: true,
    cornerRadius: 20,
    layout: {
      maxwidth: '100px',
      padding: {
        left: 0,
        right: 20,
        top: 0,
        bottom: 0
      }
    }
  };

  return (
    <Box
      width="100%"
      bgcolor="#FFFFFF"
      display="flex"
      borderRadius={3}
      p={2}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      {graphloading ? (
        <Box>
          <p>グラフの読み込み中…</p>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box width={'100%'} display={'flex'} justifyContent={'space-between'}>
            <Typography fontWeight={'bold'} fontSize={'18px'}>
              {title}推移
            </Typography>
            <MeasurePullDown premiumStatus={premiumStatus} />
          </Box>
          <Box width="100%" height={200}>
            <Line width="100%" data={data} options={options} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default RankingChangeGraph;
