/* eslint-disable */
import { Outlet } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import MainNavbar from '../../organisms/layout/main/MainNavbar';
import DashboardFooter from '../../organisms/layout/dashboard/DashboardFooter';

const MainLayoutRoot = styled('div')(({ theme }) => ({
  // backgroundColor: theme.palette.background.default,
  display: 'flex',
  flex: '1 1 auto',
  height: '100%',
  // overflow: 'scroll',
  width: '100%'
}));

const MainLayoutWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  height: '100%'
  // overflow: 'hidden'
  // paddingTop: 64
}));

const MainLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column'
});

const MainLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  maxWidth: 'false'
});

const MainLayout = () => (
  <>
    {/* <MainLayoutWrapper> */}
    <MainNavbar />
    <MainLayoutRoot>
      <MainLayoutContainer>
        <MainLayoutContent>
          <Outlet />
          <DashboardFooter />
        </MainLayoutContent>
        {/* <DashboardFooter /> */}
      </MainLayoutContainer>
    </MainLayoutRoot>
    {/* フッター */}
    {/* </MainLayoutWrapper> */}
  </>
);

export default MainLayout;
