/* eslint-disable */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const pageSlice = createSlice({
  name: 'page',
  initialState: {
    sideMenuIsOpen: true
  },
  reducers: {
    changeSideMenuOpen(state, action) {
      state.sideMenuIsOpen = !state.sideMenuIsOpen;
    }
  }
});

export const { changeSideMenuOpen } = pageSlice.actions;

export const sideMenuProps = (state) => state.page.sideMenuIsOpen;

export default pageSlice.reducer;
