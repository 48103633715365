/* eslint-disable*/
import React, { useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { currentchannel } from 'feactures/channel/channelSlice';
import {
  amazonFavoriteCategory,
  rakutenFavoriteCategory,
  yahooFavoriteCategory,
  setSelectedFavoriteCategory,
  selectedFavoriteCategoryAmazon,
  selectedFavoriteCategoryRakuten,
  selectedFavoriteCategoryYahoo
} from 'feactures/category/favoriteCategorySlice';
import { categoriesFavoriteSelect } from 'feactures/category/categorySlice';
import {
  currentAmazonCategoryList,
  currentRakutenCategoryList,
  currentYahooCategoryList,
  fetchParentFavoriteCategory,
  amazonParentFavoriteCategory,
  rakutenParentFavoriteCategory,
  yahooParentFavoriteCategory
} from 'feactures/category/categoryGraphSlice';

import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import theme from 'theme';

const useStyles = makeStyles({
  customSelect: {
    color: '#575757',
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      backgroundColor: '#E6E6E6'
    }
  },
  formControl: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.grey[400],
        borderWidth: 1
      }
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.grey[400]
    }
  }
});

const FavoriteCategoryPullDown = (props) => {
  const { page, graphLoading } = props;
  const channel = useSelector(currentchannel);
  const selectedAmazonFavo = useSelector(selectedFavoriteCategoryAmazon);
  const selectedRakutenFavo = useSelector(selectedFavoriteCategoryRakuten);
  const selectedYahooFavo = useSelector(selectedFavoriteCategoryYahoo);
  const favoriteAmazonCategory = useSelector(amazonFavoriteCategory);
  const favoriteRakutenCategory = useSelector(rakutenFavoriteCategory);
  const favoriteYahooCategory = useSelector(yahooFavoriteCategory);
  const parentFavoriteAmazon = useSelector(amazonParentFavoriteCategory);
  const parentFavoriteRakuten = useSelector(rakutenParentFavoriteCategory);
  const parentFavoriteYahoo = useSelector(yahooParentFavoriteCategory);

  // 現在選択中のカテゴリ
  const currentSelectAmazonCategories = useSelector(currentAmazonCategoryList);
  const currentSelectRakutenCategories = useSelector(
    currentRakutenCategoryList
  );
  const currentSelectYahooCategories = useSelector(currentYahooCategoryList);
  const dispatch = useDispatch();

  // 現在選択中のカテゴリを格納
  let currentSelectCategoryList = [];
  if (channel == 1) {
    currentSelectCategoryList = currentSelectAmazonCategories;
  } else if (channel == 2) {
    currentSelectCategoryList = currentSelectRakutenCategories;
  } else if (channel == 3) {
    currentSelectCategoryList = currentSelectYahooCategories;
  }

  // 現在選択中のお気に入りカテゴリ
  let currentSelectedFavoriteCategory = { label: null, value: null };
  if (channel == 1) {
    currentSelectedFavoriteCategory = selectedAmazonFavo;
  } else if (channel == 2) {
    currentSelectedFavoriteCategory = selectedRakutenFavo;
  } else if (channel == 3) {
    currentSelectedFavoriteCategory = selectedYahooFavo;
  }

  const categoriesByChannel = {
    1: favoriteAmazonCategory,
    2: favoriteRakutenCategory,
    3: favoriteYahooCategory
  };

  const currentFavoriteCategories = categoriesByChannel[channel];

  const handleChange = async (event) => {
    dispatch(
      setSelectedFavoriteCategory({ cate: event.target.value, channel })
    );
    // 選択したお気に入りカテゴリの上位階層も含むカテゴリリストを取得し選択中のカテゴリに設定
    dispatch(
      fetchParentFavoriteCategory({ categoryId: event.target.value.value })
    );
  };

  useEffect(() => {
    // 選択したお気に入りカテゴリの最下層のさらに1つ下の階層までカテゴリリストを取得
    dispatch(categoriesFavoriteSelect());
  }, [parentFavoriteAmazon, parentFavoriteRakuten, parentFavoriteYahoo]);

  const classes = useStyles();

  return (
    <FormControl
      sx={{ width: 250, height: 30 }}
      className={classes.formControl}
    >
      <Select
        className={classes.customSelect}
        value={currentSelectedFavoriteCategory}
        onChange={handleChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        disabled={
          page === 'productMeasures' ||
          graphLoading ||
          currentFavoriteCategories.length === 0
        }
        sx={{
          height: 30,
          bgcolor: '#FFFFFF',
          fontSize: '14px',
          width: '250px'
        }}
      >
        {/* <MenuItem value=""></MenuItem> */}
        {currentFavoriteCategories.map((cat, index) => {
          return (
            <MenuItem value={cat} key={index}>
              {cat.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default FavoriteCategoryPullDown;
