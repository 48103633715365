/* eslint-disable */
//画面で表示されているグラフ値をエクスポートする
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const fetchUpload = createAsyncThunk('upload', async (arg, thunkAPI) => {
  const { csvData } = arg;
  const { userId, accountId } = thunkAPI.getState().user;

  //headerを設定する必要あり。
  let config = { headers: { 'content-type': 'multipart/form-data' } };

  csvData.append('user_id', JSON.stringify(userId));
  csvData.append('account_id', JSON.stringify(accountId));

  const res = await client.post(`${apiUrl}upload`, csvData, config);
  return res.data;
});

const uploadSlice = createSlice({
  name: 'upload',
  initialState: {
    csvData: {}
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUpload.fulfilled, (state, action) => {
      return {
        ...state,
        csvData: action.payload,
        isLoading: false
      };
    });
    builder.addCase(fetchUpload.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        error: false
      };
    });
    builder.addCase(fetchUpload.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    });
  }
});

export const csvData = (state) => state.upload.csvData;
export const uploadStatus = (state) => state.upload.isLoading;

export default uploadSlice.reducer;
