/* eslint-disable*/

import { Container, Grid, colors } from '@material-ui/core'; // eslint-disable-line
import { useSelector } from 'react-redux';
import {
  selectAmazonCategoryGraph,
  selectRakutenCategoryGraph,
  selectYahooCategoryGraph,
  categorygraphStatus
} from 'feactures/brand/categoryBrandGraphSlice';
import {
  amazonCategory,
  rakutenCategory,
  yahooCategory
} from 'feactures/category/categorySlice';
import { currentchannel } from 'feactures/channel/channelSlice';
import ShopCompareLabel from 'components/molecules/dashboard/ShopCompareLabel';
import ShopComparegraph from 'components/molecules/dashboard/ShopComparegraph';
import { otherSelectedBrand } from 'feactures/brand/brandSlice';
import {
  otherSalesGraphdata,
  ownSalesGraphdata
} from 'feactures/measure/measureSlice';
import BrandCompareLabel from 'components/molecules/dashboard/BrandCompareLabel';
import BrandComparegraph from 'components/molecules/dashboard/BrandComparegraph';

const BrandCompare = (props) => {
  // 実際のデータはuseEffectで取得しreduxへ
  const { yokoziku } = props;
  let categoryAmazonGraphData = useSelector(selectAmazonCategoryGraph);
  let categoryRakutenGraphData = useSelector(selectRakutenCategoryGraph);
  const categoryYahooGraphData = useSelector(selectYahooCategoryGraph);
  const amazonCategories = useSelector(amazonCategory);
  const rakutenCategories = useSelector(rakutenCategory);
  const yahooCategories = useSelector(yahooCategory);
  const loading = useSelector(categorygraphStatus);
  const channel = useSelector(currentchannel);
  const selectBrand = useSelector(otherSelectedBrand);
  const ownShopSales = useSelector(ownSalesGraphdata);
  let amazonOwnSales = ownShopSales.amazon;
  let rakutenOwnSales = ownShopSales.rakuten;
  let yoko = [...yokoziku];

  let brandName = 'ブランドが選択されていません';
  let ownGraphdata = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let otherGraphdata = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  let categoryGraphData = {
    graphdata: [],
    totalsalesData: '',
    totalunitData: '',
    selectCategoryLabel: '',
    lastmonthRate: '',
    lastyearRate: ''
  };
  let mall = '';
  let color = '';
  let categories = '';

  if (channel == 1) {
    // Amazonは2023/2以前は表示させない
    const targetDate = '2023/2';
    const index = yokoziku.findIndex((date) => date === targetDate);
    if (index !== -1 && categoryAmazonGraphData.graphdata.length > 0) {
      yoko.splice(0, index + 1);
      let newData = JSON.parse(JSON.stringify(categoryAmazonGraphData)); // オブジェクト全体をコピー
      newData.graphdata.splice(0, index + 1); // graphdataを変更
      categoryAmazonGraphData = newData; // 新しいオブジェクトを代入
      // 自社データでも同様のことを行う
      let newOwnData = JSON.parse(JSON.stringify(ownShopSales.amazon));
      newOwnData.splice(0, index + 1);
      amazonOwnSales = newOwnData;
    }

    categoryGraphData = categoryAmazonGraphData;
    mall = 'Amazon';
    color = colors.amber[700];
    categories = amazonCategories;
    // console.log(categoryGraphData);
  } else if (channel == 2) {
    // 楽天は2023/7以前は表示させない
    const targetDate = '2023/7';
    const index = yokoziku.findIndex((date) => date === targetDate);
    if (
      (index !== -1 && categoryRakutenGraphData.graphdata.length > 0) ||
      ownShopSales.rakuten.length > 0
    ) {
      yoko.splice(0, index + 1);
      let newData = JSON.parse(JSON.stringify(categoryRakutenGraphData)); // オブジェクト全体をコピー
      newData.graphdata.splice(0, index + 1); // graphdataを変更
      categoryRakutenGraphData = newData; // 新しいオブジェクトを代入
      // 自社データでも同様のことを行う
      let newOwnData = JSON.parse(JSON.stringify(ownShopSales.rakuten));
      newOwnData.splice(0, index + 1);
      rakutenOwnSales = newOwnData;
    }

    // categoryGraphData = categoryRakutenGraphData;
    mall = '楽天市場';
    color = '#bf0000';
    categories = rakutenCategories;
    // console.log(categoryGraphData);
  } else if (channel == 3) {
    categoryGraphData = categoryYahooGraphData;
    mall = 'Yahoo';
    color = '#FF0027';
    categories = yahooCategories;
  }

  if (channel == 1) {
    if (selectBrand.amazon === '') {
      brandName = 'ブランドが選択されていません';
    } else {
      brandName = selectBrand.amazon.brand;
    }
    ownGraphdata = amazonOwnSales;
    otherGraphdata = categoryAmazonGraphData.graphdata;
  } else if (channel == 2) {
    if (selectBrand.rakuten === '') {
      brandName = '店舗が選択されていません';
    } else {
      brandName = selectBrand.rakuten.brand;
    }
    ownGraphdata = rakutenOwnSales;
    otherGraphdata = categoryRakutenGraphData.graphdata;
  } else if (channel == 3) {
    if (selectBrand.yahoo === '') {
      brandName = 'ブランドが選択されていません';
    } else {
      brandName = selectBrand.yahoo.brand;
    }
    ownGraphdata = ownShopSales.yahoo;
    otherGraphdata = categoryYahooGraphData.graphdata;
  }

  return (
    <>
      <Container maxWidth={false} label={brandName}>
        <Grid container rowSpacing={0.3} columnSpacing={1.5}>
          <>
            <Grid itemxs={12} mb={1}>
              <BrandCompareLabel />
            </Grid>
            <Grid item xs={12}>
              {/* 棒グラフ */}
              <BrandComparegraph
                term={yoko}
                label={brandName}
                ownGraphdata={ownGraphdata}
                otherGraphdata={otherGraphdata}
              />
            </Grid>
          </>
        </Grid>
      </Container>
    </>
  );
};

export default BrandCompare;
