/* eslint-disable*/
import {
  Link,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { userinfo } from 'feactures/user/userSlice';
import ConnectionStatus2 from 'pages/ConnectionStatus2';

const steps = ['アカウント作成', '審査', 'データ接続', 'Oxcim利用可能'];

//accountStatus
// 0：審査前
// 1：旧フロー審査完了
// 2：新フロー審査完了
// 99：競・反社

const ConnectionDialog = () => {
  const user = useSelector(userinfo);

  return (
    <Dialog
      open={user.accountStatus == 0}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="54%"
      sx={{ p: 2 }}
      scroll="body"
    >
      <DialogTitle
        id="alert-dialog-title"
        fontSize="20px"
        textAlign="center"
        sx={{ mt: 1, pb: 0 }}
      >
        データ接続を進めてください
      </DialogTitle>
      <Typography
        textAlign="center"
        gutterBottom
        variant="body2"
        sx={{ color: 'rgb(191, 0, 0)' }}
      >
        アカウント開設時に無料トライアルを希望されたモールでデータ接続が行われた場合、
        <br />
        データ接続をしたモールを対象にすべての機能を14日間無料でお試しいただけます。
      </Typography>
      <DialogContent>
        <ConnectionStatus2 />
      </DialogContent>
    </Dialog>
  );
};

export default ConnectionDialog;
