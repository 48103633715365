/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';
export const fetchCategoryBrandGraphData = createAsyncThunk(
  'categoryBrand/post',
  async (_, thunkAPI) => {
    const {
      a_cate1,
      a_cate2,
      a_cate3,
      a_cate4,
      a_cate5,
      a_cate6,
      r_cate1,
      r_cate2,
      r_cate3,
      r_cate4,
      r_cate5,
      y_cate1,
      y_cate2,
      y_cate3,
      y_cate4
    } = thunkAPI.getState().categorygraph;
    const { otherSelectedBrand } = thunkAPI.getState().brand;
    const { brandList } = thunkAPI.getState().brand;
    const { yearMonth } = thunkAPI.getState().currentsection;
    const { channel, benchmark } = thunkAPI.getState().currentchannel;

    let amazon_brand_id = null;
    let rakuten_brand_id = null;
    let yahoo_brand_id = null;
    // let brand_id_arr = null;
    // if (channel == 1) {
    //   brand_id_arr = brandList.amazon.filter(
    //     (brand) => brand.brand_name == otherSelectedBrand.amazon.brand
    //   );
    // } else if (channel == 2) {
    //   brand_id_arr = brandList.rakuten.filter(
    //     (brand) => brand.brand_name == otherSelectedBrand.rakuten.brand
    //   );
    // } else if (channel == 3) {
    //   brand_id_arr = brandList.yahoo.filter(
    //     (brand) => brand.brand_name == otherSelectedBrand.yahoo.brand
    //   );
    // }
    // if (brand_id_arr.length != 0) {
    //   brand_id = brand_id_arr[0].brand_id;
    // }
    if (channel === 1 && otherSelectedBrand.amazon !== '') {
      amazon_brand_id = otherSelectedBrand.amazon.id;
    } else if (channel === 2 && otherSelectedBrand.rakuten !== '') {
      rakuten_brand_id = otherSelectedBrand.rakuten.id;
    } else if (channel === 3 && otherSelectedBrand.yahoo !== '') {
      yahoo_brand_id = otherSelectedBrand.yahoo.id;
    }
    const res = await client.post(`${apiUrl}brand/sales`, {
      amazon_category: [a_cate1, a_cate2, a_cate3, a_cate4, a_cate5, a_cate6],
      rakuten_category: [r_cate1, r_cate2, r_cate3, r_cate4, r_cate5],
      yahoo_category: [y_cate1, y_cate2, y_cate3, y_cate4],
      amazon_brand: amazon_brand_id,
      rakuten_brand: rakuten_brand_id,
      yahoo_brand: yahoo_brand_id,
      term: yearMonth,
      channel: channel
    });
    return res.data;
  }
);
export const fetchAllCategoryGraphData = createAsyncThunk(
  'allCategoryBrand/post',
  async (_, thunkAPI) => {
    const { yearMonth } = thunkAPI.getState().currentsection;
    const { channel, benchmark } = thunkAPI.getState().currentchannel;
    const res = await client.post(`${apiUrl}brand/initial_sales`, {
      term: yearMonth,
      channel: channel
    });
    return res.data;
  }
);

const fetchCategoryBrandGraphSlice = createSlice({
  name: 'categorybrandgraph',
  initialState: {
    own: {
      amazon_graphdata: {
        graphdata: [],
        totalsalesData: '',
        totalunitData: '',
        selectCategoryLabel: '',
        lastmonthRate: '',
        lastyearRate: ''
      },
      rakuten_graphdata: {
        graphdata: [],
        totalsalesData: '',
        totalunitData: '',
        selectCategoryLabel: '',
        lastmonthRate: '',
        lastyearRate: ''
      },
      yahoo_graphdata: {
        graphdata: [],
        totalsalesData: '',
        totalunitData: '',
        selectCategoryLabel: '',
        lastmonthRate: '',
        lastyearRate: ''
      }
    },
    other: {
      amazon_graphdata: {
        graphdata: [],
        totalsalesData: '',
        totalunitData: '',
        selectCategoryLabel: '',
        lastmonthRate: '',
        lastyearRate: ''
      },
      rakuten_graphdata: {
        graphdata: [],
        totalsalesData: '',
        totalunitData: '',
        selectCategoryLabel: '',
        lastmonthRate: '',
        lastyearRate: ''
      },
      yahoo_graphdata: {
        graphdata: [],
        totalsalesData: '',
        totalunitData: '',
        selectCategoryLabel: '',
        lastmonthRate: '',
        lastyearRate: ''
      }
    },
    a_cate1: 0,
    a_cate2: 0,
    a_cate3: 0,
    a_cate4: 0,
    a_cate5: 0,
    a_cate6: 0,
    r_cate1: 0,
    r_cate2: 0,
    r_cate3: 0,
    r_cate4: 0,
    r_cate5: 0,
    y_cate1: 0,
    y_cate2: 0,
    y_cate3: 0,
    y_cate4: 0
  },
  reducers: {
    changeAcate1(state, action) {
      return {
        ...state,
        a_cate1: action.payload,
        a_cate2: 0,
        a_cate3: 0,
        a_cate4: 0,
        a_cate5: 0,
        a_cate6: 0
      };
    },
    changeAcate2(state, action) {
      return {
        ...state,
        a_cate2: action.payload,
        a_cate3: 0,
        a_cate4: 0,
        a_cate5: 0,
        a_cate6: 0
      };
    },
    changeAcate3(state, action) {
      return {
        ...state,
        a_cate3: action.payload,
        a_cate4: 0,
        a_cate5: 0,
        a_cate6: 0
      };
    },
    changeAcate4(state, action) {
      return {
        ...state,
        a_cate4: action.payload,
        a_cate5: 0,
        a_cate6: 0
      };
    },
    changeAcate5(state, action) {
      return {
        ...state,
        a_cate5: action.payload,
        a_cate6: 0
      };
    },
    changeAcate6(state, action) {
      return {
        ...state,
        a_cate6: action.payload
      };
    },
    changeRcate1(state, action) {
      return {
        ...state,
        r_cate1: action.payload,
        r_cate2: 0,
        r_cate3: 0,
        r_cate4: 0,
        r_cate5: 0
      };
    },
    changeRcate2(state, action) {
      return {
        ...state,
        r_cate2: action.payload,
        r_cate3: 0,
        r_cate4: 0,
        r_cate5: 0
      };
    },
    changeRcate3(state, action) {
      return {
        ...state,
        r_cate3: action.payload,
        r_cate4: 0,
        r_cate5: 0
      };
    },
    changeRcate4(state, action) {
      return {
        ...state,
        r_cate4: action.payload,
        r_cate5: 0
      };
    },
    changeRcate5(state, action) {
      return {
        ...state,
        r_cate5: action.payload
      };
    },
    changeYcate1(state, action) {
      return {
        ...state,
        y_cate1: action.payload,
        y_cate2: 0,
        y_cate3: 0,
        y_cate4: 0
      };
    },
    changeYcate2(state, action) {
      return {
        ...state,
        y_cate2: action.payload,
        y_cate3: 0,
        y_cate4: 0
      };
    },
    changeYcate3(state, action) {
      return {
        ...state,
        y_cate3: action.payload,
        y_cate4: 0
      };
    },
    changeYcate4(state, action) {
      return {
        ...state,
        y_cate4: action.payload
      };
    },
    resetAgraph(state, action) {
      state.other.amazon_graphdata = {
        graphdata: [],
        totalsalesData: '',
        totalunitData: '',
        selectCategoryLabel: '',
        lastmonthRate: '',
        lastyearRate: ''
      };
      state.own.amazon_graphdata = {
        graphdata: [],
        totalsalesData: '',
        totalunitData: '',
        selectCategoryLabel: '',
        lastmonthRate: '',
        lastyearRate: ''
      };
    },
    resetRgraph(state, action) {
      state.other.rakuten_graphdata = {
        graphdata: [],
        totalsalesData: '',
        totalunitData: '',
        selectCategoryLabel: '',
        lastmonthRate: '',
        lastyearRate: ''
      };
      state.own.rakuten_graphdata = {
        graphdata: [],
        totalsalesData: '',
        totalunitData: '',
        selectCategoryLabel: '',
        lastmonthRate: '',
        lastyearRate: ''
      };
    },
    resetYgraph(state, action) {
      state.other.yahoo_graphdata = {
        graphdata: [],
        totalsalesData: '',
        totalunitData: '',
        selectCategoryLabel: '',
        lastmonthRate: '',
        lastyearRate: ''
      };
      state.own.yahoo_graphdata = {
        graphdata: [],
        totalsalesData: '',
        totalunitData: '',
        selectCategoryLabel: '',
        lastmonthRate: '',
        lastyearRate: ''
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategoryBrandGraphData.fulfilled, (state, action) => {
      if (action.payload.channel == 1) {
        state.other.amazon_graphdata['graphdata'] = action.payload.graphdata;
        state.other.amazon_graphdata['totalsalesData'] = action.payload.sales;
        state.other.amazon_graphdata['totalunitData'] =
          action.payload.unit_sold;
        state.other.amazon_graphdata['selectCategoryLabel'] =
          action.payload.category_name;
        state.other.amazon_graphdata['lastmonthRate'] =
          action.payload.lastmonth_rate;
        state.other.amazon_graphdata['lastyearRate'] =
          action.payload.lastyear_rate;
        state.isLoading = false;
      } else if (action.payload.channel == 2) {
        state.other.rakuten_graphdata['graphdata'] = action.payload.graphdata;
        state.other.rakuten_graphdata['totalsalesData'] = action.payload.sales;
        state.other.rakuten_graphdata['totalunitData'] =
          action.payload.unit_sold;
        state.other.rakuten_graphdata['selectCategoryLabel'] =
          action.payload.category_name;
        state.other.rakuten_graphdata['lastmonthRate'] =
          action.payload.lastmonth_rate;
        state.other.rakuten_graphdata['lastyearRate'] =
          action.payload.lastyear_rate;
        state.isLoading = false;
      } else if (action.payload.channel == 3) {
        state.other.yahoo_graphdata['graphdata'] = action.payload.graphdata;
        state.other.yahoo_graphdata['totalsalesData'] = action.payload.sales;
        state.other.yahoo_graphdata['totalunitData'] = action.payload.unit_sold;
        state.other.yahoo_graphdata['selectCategoryLabel'] =
          action.payload.category_name;
        state.other.yahoo_graphdata['lastmonthRate'] =
          action.payload.lastmonth_rate;
        state.other.yahoo_graphdata['lastyearRate'] =
          action.payload.lastyear_rate;
        state.isLoading = false;
      }
    });
    builder.addCase(fetchCategoryBrandGraphData.pending, (state, action) => {
      return {
        ...state,
        isLoading: true
        // error: false
      };
    });
    builder.addCase(fetchCategoryBrandGraphData.rejected, (state, action) => {
      return {
        ...state,
        graphdata: [0, 0, 0],
        totalsalesData: [0, 0, 0],
        totalunitData: [0, 0, 0],
        selectCategoryLabel: ['', '', ''],
        lastmonthRate: [0, 0, 0],
        lastyearRate: [0, 0, 0],
        isLoading: false
        // error: false
      };
    });
    builder.addCase(fetchAllCategoryGraphData.fulfilled, (state, action) => {
      if (action.payload.channel == 1) {
        state.other.amazon_graphdata['graphdata'] = action.payload.graphdata;
        state.other.amazon_graphdata['totalsalesData'] = action.payload.sales;
        state.other.amazon_graphdata['totalunitData'] =
          action.payload.unit_sold;
        state.other.amazon_graphdata['selectCategoryLabel'] =
          action.payload.category_name;
        state.other.amazon_graphdata['lastmonthRate'] = '';
        state.other.amazon_graphdata['lastyearRate'] = '';
        state.isLoading = false;
      } else if (action.payload.channel == 2) {
        state.other.rakuten_graphdata['graphdata'] = action.payload.graphdata;
        state.other.rakuten_graphdata['totalsalesData'] = action.payload.sales;
        state.other.rakuten_graphdata['totalunitData'] =
          action.payload.unit_sold;
        state.other.rakuten_graphdata['selectCategoryLabel'] =
          action.payload.category_name;
        state.other.rakuten_graphdata['lastmonthRate'] = '';
        state.other.rakuten_graphdata['lastyearRate'] = '';
        state.isLoading = false;
      } else if (action.payload.channel == 3) {
        state.other.yahoo_graphdata['graphdata'] = action.payload.graphdata;
        state.other.yahoo_graphdata['totalsalesData'] = action.payload.sales;
        state.other.yahoo_graphdata['totalunitData'] = action.payload.unit_sold;
        state.other.yahoo_graphdata['selectCategoryLabel'] =
          action.payload.category_name;
        state.other.yahoo_graphdata['lastmonthRate'] = '';
        state.other.yahoo_graphdata['lastyearRate'] = '';
        state.isLoading = false;
      }
    });
    builder.addCase(fetchAllCategoryGraphData.pending, (state, action) => {
      return {
        ...state,
        isLoading: true
        // error: false
      };
    });
    builder.addCase(fetchAllCategoryGraphData.rejected, (state, action) => {
      return {
        ...state,
        graphdata: [0, 0, 0],
        totalsalesData: [0, 0, 0],
        totalunitData: [0, 0, 0],
        selectCategoryLabel: ['', '', ''],
        lastmonthRate: [0, 0, 0],
        lastyearRate: [0, 0, 0],
        isLoading: false
        // error: false
      };
    });
  }
});

export const selectAmazonCategoryGraph = (state) =>
  state.categoryBrandGraph.other.amazon_graphdata;
export const selectRakutenCategoryGraph = (state) =>
  state.categoryBrandGraph.other.rakuten_graphdata;
export const selectYahooCategoryGraph = (state) =>
  state.categoryBrandGraph.other.yahoo_graphdata;

export const selectCategoryFigures = (state) =>
  state.categoryBrandGraph.salesFiguresData;
export const currentAcate1 = (state) => state.categoryBrandGraph.a_cate1;
export const currentAcate2 = (state) => state.categoryBrandGraph.a_cate2;
export const currentAcate3 = (state) => state.categoryBrandGraph.a_cate3;
export const currentAcate4 = (state) => state.categoryBrandGraph.a_cate4;
export const currentAcate5 = (state) => state.categoryBrandGraph.a_cate5;
export const currentAcate6 = (state) => state.categoryBrandGraph.a_cate6;
export const currentAmazonCategoryList = (state) => [
  state.categoryBrandGraph.a_cate1,
  state.categoryBrandGraph.a_cate2,
  state.categoryBrandGraph.a_cate3,
  state.categoryBrandGraph.a_cate4,
  state.categoryBrandGraph.a_cate5,
  state.categoryBrandGraph.a_cate6
];
export const currentRcate1 = (state) => state.categoryBrandGraph.r_cate1;
export const currentRcate2 = (state) => state.categoryBrandGraph.r_cate2;
export const currentRcate3 = (state) => state.categoryBrandGraph.r_cate3;
export const currentRcate4 = (state) => state.categoryBrandGraph.r_cate4;
export const currentRcate5 = (state) => state.categoryBrandGraph.r_cate5;
export const currentRakutenCategoryList = (state) => [
  state.categoryBrandGraph.r_cate1,
  state.categoryBrandGraph.r_cate2,
  state.categoryBrandGraph.r_cate3,
  state.categoryBrandGraph.r_cate4,
  state.categoryBrandGraph.r_cate5
];
export const currentYcate1 = (state) => state.categoryBrandGraph.y_cate1;
export const currentYcate2 = (state) => state.categoryBrandGraph.y_cate2;
export const currentYcate3 = (state) => state.categoryBrandGraph.y_cate3;
export const currentYcate4 = (state) => state.categoryBrandGraph.y_cate4;
export const currentYahooCategoryList = (state) => [
  state.categoryBrandGraph.y_cate1,
  state.categoryBrandGraph.y_cate2,
  state.categoryBrandGraph.y_cate3,
  state.categoryBrandGraph.y_cate4
];
export const categoryLabel = (state) =>
  state.categoryBrandGraph.selectCategoryLabel;
export const selectSalesFigures = (state) =>
  state.categoryBrandGraph.totalsalesData;
export const selectUnitFigures = (state) =>
  state.categoryBrandGraph.totalunitData;
export const selectLastMonthRate = (state) =>
  state.categoryBrandGraph.lastmonthRate;
export const selectLastYearRate = (state) =>
  state.categoryBrandGraph.lastyearRate;
export const categorygraphStatus = (state) =>
  state.categoryBrandGraph.isLoading;

export const {
  changeAcate1,
  changeAcate2,
  changeAcate3,
  changeAcate4,
  changeAcate5,
  changeAcate6,
  changeRcate1,
  changeRcate2,
  changeRcate3,
  changeRcate4,
  changeRcate5,
  changeYcate1,
  changeYcate2,
  changeYcate3,
  changeYcate4,
  resetAgraph,
  resetRgraph,
  resetYgraph
} = fetchCategoryBrandGraphSlice.actions;
export const { changeTerm } = fetchCategoryBrandGraphSlice.actions;
export default fetchCategoryBrandGraphSlice.reducer;
