/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import client from 'index.js';
import { apiUrl } from 'App.js';

//楽天RMSAuthCheck
// export const fetchRmsAuthCheck = createAsyncThunk(
//   'check_rms_key/post',
//   async (arg, thunkAPI) => {
//     const { accountId, userId } = thunkAPI.getState().user;
//     const { serviceSecret, licenseKey, rCsvStatus, rLoginId, rLoginPass } = arg;
//     const res = await client.post(`${apiUrl}check_rms_key`, {
//       account_id: accountId,
//       user_id: userId,
//       serviceSecret: serviceSecret,
//       licenseKey: licenseKey
//       // r_login_id: rLoginId,
//       // r_login_pass: rLoginPass,
//       // r_csv_status: rCsvStatus
//     });
//     return res.data;
//   }
// );
export const fetchYahooKey = createAsyncThunk(
  'get_yahoo_key/post',
  async (arg, thunkAPI) => {
    const { accountId, userId } = thunkAPI.getState().user;
    const res = await client.post(`${apiUrl}get_yahoo_key`, {
      account_id: accountId,
      user_id: userId
    });
    return res.data;
  }
);

// //YahooKey&ID変更
export const fetchChangeYahooKey = createAsyncThunk(
  'change_yahoo_key/post',
  async (arg, thunkAPI) => {
    const { accountId, userId } = thunkAPI.getState().user;
    const {
      yahooId,
      yahooPassword,
      yahooStoreAccount,
      yahooPublicKey,
      yahooPublicKeyVersion
    } = arg;
    const res = await client.post(`${apiUrl}change_yahoo_key`, {
      account_id: accountId,
      user_id: userId,
      yahoo_id: yahooId,
      yahoo_pass: yahooPassword,
      yahoo_store_account: yahooStoreAccount,
      yahoo_public_key: yahooPublicKey,
      yahoo_public_key_version: yahooPublicKeyVersion
    });
    return res.data;
  }
);

export const yahooKeySlice = createSlice({
  name: 'yahooKeyStatus',
  initialState: {
    yahooId: '',
    yahooPassword: '',
    yahooStoreAccount: '',
    yahooPublicKey: '',
    yahooPublicKeyVersion: '',
    licenseExpiryDate: '',
    orderReceivedStatus: ''
  },

  reducers: {
    resetYahooKeyStatus(state, action) {
      state.yahooId = '';
      state.yahooPassword = '';
      state.yahooStoreAccount = '';
      state.yahooPublicKey = '';
      state.yahooPublicKeyVersion = '';
      state.licenseExpiryDate = '';
      state.orderReceivedStatus = '';
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchYahooKey.fulfilled, (state, action) => {
      state.yahooId = action.payload.yahooId;
      state.yahooPassword = action.payload.yahooPass;
      state.yahooStoreAccount = action.payload.yStoreAccount;
      state.yahooPublicKey = action.payload.yPublicKey;
      state.yahooPublicKeyVersion = action.payload.yPublicKeyVer;
      state.licenseExpiryDate = action.payload.licenseExpiryDate;
      state.orderReceivedStatus = action.payload.orderReceivedStatus;

      return state;
    });
    builder.addCase(fetchYahooKey.rejected, (state) => {
      console.log('変更失敗');
      state.licenseCheckLoading = false;
    });
    builder.addCase(fetchYahooKey.pending, (state) => {
      state.licenseCheckLoading = true;
    });

    builder.addCase(fetchChangeYahooKey.fulfilled, (state, action) => {
      state.yahooId = action.payload.yahooId;
      state.yahooPassword = action.payload.yahooPass;
      state.yahooStoreAccount = action.payload.yStoreAccount;
      state.yahooPublicKey = action.payload.yPublicKey;
      state.yahooPublicKeyVersion = action.payload.yPublicKeyVer;
      state.licenseExpiryDate = action.payload.licenseExpiryDate;
      state.orderReceivedStatus = action.payload.orderReceivedStatus;

      return state;
    });
    builder.addCase(fetchChangeYahooKey.rejected, (state) => {
      console.log('変更失敗');
      state.licenseCheckLoading = false;
    });
    builder.addCase(fetchChangeYahooKey.pending, (state) => {
      state.licenseCheckLoading = true;
    });
  }
});

// export const { resetRmsStatus } = yahooKeySlice.actions;
export const yahooKeyInfo = (state) => state.yahooKeyStatus;

export default yahooKeySlice.reducer;
