/* eslint-disable*/
//サイドバー
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  createTheme,
  ThemeProvider
} from '@material-ui/core';

import {
  BarChart as BarChartIcon,
  Grid as GridIcon,
  Home as HomeIcon
} from 'react-feather';
import NavItem from '../../../NavItem';
import NestedList from 'components/atoms/list/NestedList';
import { makeStyles } from '@material-ui/styles';
import Logo from '../../../Logo';

const styles = {
  paper: {
    backgroundColor: '#262C3F',
    contrastText: '#ffffff',
    color: '#ffffff'
  }
};
const useStyles = makeStyles(styles);

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          justifyContent: 'center',
          display: 'flex',
          p: 2
        }}
      >
        <RouterLink to="/" style={{ textDecoration: 'none' }}>
          <Logo />
        </RouterLink>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        {/* <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List> */}
        <NestedList />
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          classes={{ paper: classes.paper }}
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          classes={{ paper: classes.paper }}
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256
              // top: 64
              // height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default DashboardSidebar;
