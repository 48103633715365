/* eslint-disable*/
//amazon項目

export const amazonItems = {
  search_rank_organic: {
    kpi: '閲覧数',
    subKPITitle: 'search_rank_organic',
    jaTitle: '掲載順位（自然）',
    rating: 3,
    action: ['タイトル・商品説明文の見直し', '広告配信キーワードの見直し'],
    sihyou: '平均',
    tanni: '位'
  },
  kpi: '閲覧数',
  search_rank_advertisement: {
    subKPItitle: 'search_rank_advertisement',
    jaTitle: '掲載順位（広告）',
    rating: 2.5,
    action: ['入札単価の調整', '入札戦略の見直し'],
    sihyou: '平均',
    tanni: '位'
  },
  brandword_num: {
    kpi: '閲覧数',
    subKPItitle: 'brandword_num',
    jaTitle: '指名検索数',
    rating: 3,
    action: ['認知拡大施策の実施'],
    sihyou: '平均',
    tanni: '位'
  },

  click: {
    kpi: '閲覧数',
    subKPItitle: 'click',
    jaTitle: 'クリック数',
    rating: 2.5,
    action: ['広告配信量の増加'],
    sihyou: '合計',
    tanni: '回'
  },
  ctr: {
    kpi: '閲覧数',
    subKPItitle: 'ctr',
    jaTitle: 'CTR',
    rating: 2.5,
    action: ['価格の見直し', 'リード分の見直し'],
    sihyou: '平均',
    tanni: '%'
  },
  impression: {
    kpi: '閲覧数',

    subKPItitle: 'impression',
    jaTitle: '広告表示回数',
    rating: 2,
    action: ['広告配信量の増加'],
    sihyou: '合計',
    tanni: '回'
  },
  //   search_organic: // {
  // subKPItitle: 'search_organic',
  //   jaTitle: 'セール有無',
  //   rating: 2,
  //   action: ['セール参加', 'クーポン']
  // },
  price_discount_rate: {
    kpi: '閲覧数',
    subKPItitle: 'price_discount_rate',
    jaTitle: '価格割引率',
    rating: 2,
    action: ['価格割引率の強化'],
    sihyou: '平均',
    tanni: '%'
  },
  ranking: {
    kpi: '閲覧数',
    subKPItitle: 'ranking',
    jaTitle: 'ランキング順位',
    rating: 1,
    action: [''],
    sihyou: '平均',
    tanni: '位'
  },
  price_discount_rate: {
    kpi: 'CVR',
    subKPItitle: 'price_discount_rate',
    jaTitle: '価格割引率',
    rating: 2,
    action: ['価格割引率の強化'],
    sihyou: '平均',
    tanni: '%'
  },
  review_score: {
    kpi: 'CVR',
    subKPItitle: 'review_score',
    jaTitle: 'レビュー平均値',
    rating: 2,
    action: ['悪いレビューに対する対策'],
    sihyou: '平均',
    tanni: ''
  },
  review_num: {
    kpi: 'CVR',
    subKPItitle: 'review_num',
    jaTitle: 'レビュー数',
    rating: 2,
    action: ['Vine参加'],
    sihyou: '平均',
    tanni: ''
  },
  image_num: {
    kpi: 'CVR',
    subKPItitle: 'image_num',
    jaTitle: '商品画像枚数',
    rating: 2,
    action: ['クリエイティブ作成'],
    sihyou: '平均',
    tanni: '枚'
  },
  a_plus: {
    kpi: 'CVR',
    subKPItitle: 'a_plus',
    jaTitle: '商品紹介コンテンツ枚数',
    rating: 2,
    action: ['クリエイティブ作成'],
    sihyou: '1ページ平均',
    tanni: '枚'
  },

  variation_num: {
    kpi: '販売価格',
    subKPItitle: 'variation_num',
    jaTitle: 'バリエーション',
    rating: 1.5,
    action: ['バンドル作成'],
    sihyou: '平均',
    tanni: '個'
  }
};

// // 楽天項目
export const rakutenItems = {
  search_rank_organic: {
    kpi: '閲覧数',
    jaTitle: '掲載順位（自然）',
    rating: 3,
    action: ['タイトル・商品説明文の見直し', '広告配信キーワードの見直し'],
    sihyou: '平均',
    tanni: '位'
  },
  search_rank_advertisement: {
    kpi: '閲覧数',
    jaTitle: '掲載順位（広告）',
    rating: 3,
    action: ['入札単価の調整', '入札戦略の見直し'],
    sihyou: '平均',
    tanni: '位'
  },
  new_recognized_number: {
    kpi: '閲覧数',
    jaTitle: '新規ユーザーの認知数',
    rating: 3,
    action: ['認知拡大施策の実施'],
    sihyou: '平均',
    tanni: ''
  },
  ctr: {
    kpi: '閲覧数',
    jaTitle: 'CTR',
    rating: 2,
    action: ['価格の見直し', 'リード分の見直し'],
    sihyou: '平均',
    tanni: '%'
  },
  click: {
    kpi: '閲覧数',
    jaTitle: '広告クリック数',
    rating: 2,
    action: ['広告配信量の増加'],
    sihyou: '合計',
    tanni: '回'
  },
  impression: {
    kpi: '閲覧数',
    jaTitle: '広告表示回数',
    rating: 2,
    action: ['広告配信量の増加'],
    sihyou: '合計',
    tanni: '回'
  },
  //   {
  //     jaTitle: 'セール有無',
  //     rate: bench === '2' ? '-' : mRate.sale,
  //     rating: 2,
  //     action: ['セール参加', 'クーポン']
  //   },
  price_discount_rate: {
    kpi: '閲覧数',
    jaTitle: '価格割引率',
    rating: 2,
    action: ['価格割引率の強化'],
    sihyou: '平均',
    tanni: '%'
  },
  ranking: {
    kpi: '閲覧数',
    jaTitle: 'ランキング順位',
    rating: 1,
    action: [''],
    tanni: '位'
  },
  ranking_award: {
    kpi: '閲覧数',
    jaTitle: 'ランキング入賞有無', //カラム名聞く
    rating: 1,
    action: [''],
    sihyou: '平均',
    tanni: '回'
  },
  price_discount_rate: {
    kpi: 'CVR',
    jaTitle: '価格割引率',
    rating: 2,
    action: ['価格割引率の強化'],
    sihyou: '平均',
    tanni: '%'
  },
  review_score: {
    kpi: 'CVR',
    jaTitle: 'レビュー平均値',
    rating: 2,
    action: ['悪いレビューに対する対策'],
    sihyou: '平均',
    tanni: ''
  },
  review_num: {
    kpi: 'CVR',
    jaTitle: 'レビュー数',
    rating: 2,
    action: ['Vine参加'],
    sihyou: '平均',
    tanni: ''
  },
  image_num: {
    kpi: 'CVR',
    jaTitle: '商品画像枚数',
    rating: 3,
    action: ['クリエイティブ作成'],
    sihyou: '平均',
    tanni: '枚'
  },
  delivery_days: {
    kpi: 'CVR',
    jaTitle: 'お届け日数（届け日指定のみ）',
    rating: 2,
    action: [''],
    sihyou: '平均',
    tanni: '日'
  },
  withdrawal_rate: {
    kpi: 'CVR',
    jaTitle: '離脱率',
    rating: 2,
    action: [''],
    sihyou: '平均',
    tanni: '%'
  },
  variation_num: {
    kpi: '販売価格',
    jaTitle: 'バリエーション',
    rating: 2,
    action: ['バンドル作成'],
    sihyou: '平均',
    tanni: '個'
  }
};
