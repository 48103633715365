/* eslint-disable */
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  Button,
  Link,
  Tooltip
} from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { currentchannel } from 'feactures/channel/channelSlice';
import ItemDetailDialog from 'components/atoms/modal/ItemDetailDialog';
import {
  fetchItemDetailGraph,
  itemDetailDisplay,
  setItemDetail,
  setItemDetailDisplayFlag
} from 'feactures/category/categoryRankSlice';

const RankingList = (props) => {
  const { top100items, yokoziku } = props;
  const dispatch = useDispatch();

  const combined = top100items.map((top100item, i) => ({
    rank: i + 1,
    ...top100item
  }));

  const [orderDesc, setOrderDesc] = useState(true);
  const [checkedColumn, setCheckedColumn] = useState('rank');
  const [sortArr, setSortArr] = useState(combined);

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  }));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const channel = useSelector(currentchannel);
  const displayFlag = useSelector(itemDetailDisplay);

  const url = 'https://m.media-amazon.com/images/I/';

  const handleChange = (column) => {
    if (column === checkedColumn && orderDesc === true) {
      //最後に並び替えたカラムと同じカラムで、現在の並び順が降順なので昇順に並び替える
      if (column === 'rank') {
        setOrderDesc(false);
        const ascSorted = [...sortArr].sort((a, b) => b[column] - a[column]);
        setSortArr(ascSorted);
      } else {
        setOrderDesc(false);
        const ascSorted = [...sortArr].sort((a, b) => a[column] - b[column]);
        setSortArr(ascSorted);
      }
    } else if (column === checkedColumn && orderDesc === false) {
      //最後に並び替えたカラムと同じカラムで、現在の並び順が昇順なので降順に並び替える
      if (column === 'rank') {
        setOrderDesc(true);
        const descSorted = [...sortArr].sort((a, b) => a[column] - b[column]);
        setSortArr(descSorted);
      } else {
        setOrderDesc(true);
        const descSorted = [...sortArr].sort((a, b) => b[column] - a[column]);
        setSortArr(descSorted);
      }
    } else {
      // 新しいカラムを降順に並び替える
      setCheckedColumn(column);
      if (column === 'rank') {
        setOrderDesc(true);
        const descSorted = [...sortArr].sort((a, b) => a[column] - b[column]);
        setSortArr(descSorted);
      } else {
        setOrderDesc(true);
        const descSorted = [...sortArr].sort((a, b) => b[column] - a[column]);
        setSortArr(descSorted);
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const tableStyles = makeStyles({
    tableHead: {
      height: 44
    }
  });

  const classes = tableStyles();

  const handleClick = (row, shop_code) => {
    let item_id = row.item_id;
    if (channel == 2) {
      item_id = row.item_id.split(':')[1];
    }
    const data = {
      rank: row.rank,
      img: row.img,
      shopCode: shop_code,
      asin: item_id,
      item_name: row.name,
      price: row.price,
      unit_sold: row.unit_sold,
      sales: row.sales
    };
    dispatch(setItemDetailDisplayFlag(true));
    dispatch(setItemDetail(data));
    dispatch(fetchItemDetailGraph(data));
  };
  return (
    <>
      {/* <Paper sx={{ width: '100%', overflow: 'hidden' }}> */}
      <ItemDetailDialog yokoziku={yokoziku} />
      <TableContainer sx={{ maxHeight: 550 }} component={Paper}>
        <Table stickyHeader size="small" aria-label="a dense table">
          <TableHead sx={{ maxHeight: '38px' }}>
            <TableRow>
              {/* <TableCell sx={{ width: '62px' }} component="th" scope="row"> */}
              <TableCell
                align="center"
                component="th"
                scope="row"
                sx={{
                  minWidth: '65px',
                  bgcolor: '#D9D9D9',
                  fontWeight: 'bold'
                }}
              >
                順位
              </TableCell>
              <TableCell
                sx={{ width: '70px', bgcolor: '#D9D9D9', fontWeight: 'bold' }}
              ></TableCell>
              {channel == 1 ? (
                <TableCell
                  align="center"
                  sx={{ bgcolor: '#D9D9D9', fontWeight: 'bold' }}
                >
                  ASIN
                </TableCell>
              ) : (
                <TableCell
                  align="center"
                  sx={{ bgcolor: '#D9D9D9', fontWeight: 'bold' }}
                >
                  ショップ名
                  <br />
                  商品管理番号
                </TableCell>
              )}
              <TableCell
                align="center"
                sx={{ bgcolor: '#D9D9D9', fontWeight: 'bold' }}
              >
                商品名
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  minWidth: '120px',
                  padding: 0,
                  bgcolor: '#D9D9D9'
                }}
              >
                <Button
                  onClick={() => {
                    handleChange('price');
                  }}
                  color="inherit"
                  sx={{
                    padding: 0,
                    height: '32.5px',
                    width: '100%',
                    fontWeight: 'bold'
                  }}
                >
                  価格
                  {checkedColumn !== 'price' && (
                    <Box width="20.5px" height="20.5px"></Box>
                  )}
                  {checkedColumn === 'price' && (
                    <ExpandMore
                      expand={orderDesc}
                      onClick={() => {
                        handleChange('price');
                      }}
                      aria-expanded={orderDesc}
                      aria-label="sort"
                      sx={{ padding: 0 }}
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  )}
                </Button>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  minWidth: '120px',
                  padding: 0,
                  bgcolor: '#D9D9D9'
                }}
              >
                <Button
                  onClick={() => {
                    handleChange('unit_sold');
                  }}
                  color="inherit"
                  sx={{
                    padding: 0,
                    height: '32.5px',
                    width: '100%',
                    fontWeight: 'bold'
                  }}
                >
                  販売個数
                  {checkedColumn !== 'unit_sold' && (
                    <Box width="20.5px" height="20.5px"></Box>
                  )}
                  {checkedColumn === 'unit_sold' && (
                    <ExpandMore
                      expand={orderDesc}
                      aria-expanded={orderDesc}
                      aria-label="sort"
                      sx={{ padding: 0 }}
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  )}
                </Button>
              </TableCell>
              <TableCell align="center" sx={{ padding: 0, bgcolor: '#D9D9D9' }}>
                <Button
                  onClick={() => {
                    handleChange('rank');
                  }}
                  color="inherit"
                  sx={{
                    padding: 0,
                    height: '32.5px',
                    width: '100%',
                    fontWeight: 'bold'
                  }}
                >
                  売上
                  {checkedColumn !== 'rank' && (
                    <Box width="20.5px" height="20.5px"></Box>
                  )}
                  {checkedColumn === 'rank' && (
                    <ExpandMore
                      expand={orderDesc}
                      aria-expanded={orderDesc}
                      aria-label="sort"
                      sx={{ padding: 0 }}
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  )}
                </Button>
              </TableCell>
              <TableCell
                sx={{ bgcolor: '#D9D9D9', fontWeight: 'bold' }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortArr.length !== 0 ? (
              <>
                {sortArr
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const shopCode =
                      channel == 2 ? row.item_id.split(':')[0] : '';
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          '&:nth-of-type(even)': { bgcolor: '#E3E8F4' },
                          height: 100
                        }}
                        className={classes.tableHead}
                      >
                        <TableCell align="center">{row.rank}位</TableCell>
                        <TableCell align="center" maxwidth={40}>
                          {row.item_id == '-:-' ? (
                            <Box>-</Box>
                          ) : (
                            <Link
                              href={
                                channel === 1
                                  ? `https://www.amazon.co.jp/dp/${row.item_id}`
                                  : `https://item.rakuten.co.jp/${shopCode}/${
                                      row.item_id.split(':')[1]
                                    }/`
                              }
                              target="_blank"
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column'
                                }}
                              >
                                <Box
                                  sx={{
                                    height: 70,
                                    width: 70,
                                    display: 'inline-block'
                                  }}
                                >
                                  <img
                                    src={channel == 1 ? url + row.img : row.img}
                                    height="100%"
                                    loading="lazy"
                                    width="100%"
                                  />
                                </Box>
                              </Box>
                            </Link>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {channel == 1 ? (
                            <Box>{row.item_id}</Box>
                          ) : row.item_id == '-:-' ? (
                            <Box>-</Box>
                          ) : (
                            <>
                              <Box>{shopCode}</Box>
                              <br />
                              <Box>{row.item_id.split(':')[1]}</Box>
                            </>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {row.item_id == '-:-' ? (
                            <Box>データが無いため表示できません</Box>
                          ) : (
                            <Link
                              underline="hover"
                              href={
                                channel === 1
                                  ? `https://www.amazon.co.jp/dp/${row.item_id}`
                                  : `https://item.rakuten.co.jp/${shopCode}/${
                                      row.item_id.split(':')[1]
                                    }/`
                              }
                              target="_blank"
                              rel="noopener"
                            >
                              <Box
                                sx={{
                                  height: 60,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'left'
                                  // width: 170,
                                  // display: 'inline-block'
                                }}
                                component="div"
                                my={2}
                                textOverflow="ellipsis"
                                overflow="hidden"
                              >
                                {row.name}
                              </Box>
                            </Link>
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {row.price == 0
                            ? '-'
                            : '¥' + row.price.toLocaleString()}
                        </TableCell>
                        <TableCell align="center">
                          {row.unit_sold == 0
                            ? '-'
                            : row.unit_sold.toLocaleString()}
                        </TableCell>
                        <TableCell align="right">
                          {row.item_id == '-:-'
                            ? '-'
                            : '¥' + row.sales.toLocaleString()}
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            variant="contained"
                            color="detailButton"
                            sx={{ fontWeight: 'bold', borderRadius: 160 }}
                            onClick={() => {
                              handleClick(row, shopCode);
                            }}
                            disabled={row.item_id == '-:-'}
                          >
                            詳細
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </>
            ) : (
              <TableRow>
                <TableCell>データがありません</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 25, 100]}
        count={sortArr.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default RankingList;
