/* eslint-disable*/
import { Typography } from '@material-ui/core';
import { otherSelectedBrand } from 'feactures/brand/brandSlice';
import { currentchannel } from 'feactures/channel/channelSlice';
import { useSelector } from 'react-redux';

const BrandLabel = () => {
  /* eslint-disable*/
  const channel = useSelector(currentchannel);
  const selectBrand = useSelector(otherSelectedBrand);

  let brandName = 'ブランドが選択されていません';

  if (channel == 1) {
    if (selectBrand.amazon === '') {
      brandName = 'ブランドが選択されていません';
    } else {
      brandName = selectBrand.amazon.brand;
    }
  } else if (channel == 2) {
    if (selectBrand.rakuten === '') {
      brandName = '店舗が選択されていません';
    } else {
      brandName = selectBrand.rakuten.brand;
    }
  } else if (channel == 3) {
    if (selectBrand.yahoo === '') {
      brandName = 'ブランドが選択されていません';
    } else {
      brandName = selectBrand.yahoo.brand;
    }
  }

  return (
    <>
      <Typography fontSize="14px" fontWeight="bold">
        ブランド名：{brandName}
      </Typography>
    </>
  );
};

export default BrandLabel;
