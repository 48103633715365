/* eslint-disable*/
import {
  Card,
  CircularProgress,
  Box,
  Typography,
  IconButton,
  Modal,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  deleteKeyword,
  registerKeyword
} from 'feactures/measure/newMeasureSlice';
import { errorResMessage, setError } from 'feactures/errorSlice';

export default function KeywordRegisterModal({
  open,
  setOpen,
  keywordList,
  itemId,
  shopCode
}) {
  const dispatch = useDispatch();
  const graphloading = false;
  const [inputKeyword, setInputKeyword] = useState('');
  const errorMessage = useSelector(errorResMessage); //errorメッセージ

  const handleClose = () => {
    dispatch(setError(''));
    setOpen(false);
  };

  const handleDeleteKeyword = (id) => {
    dispatch(deleteKeyword({ id: id }));
  };

  const handleInputChange = (e) => {
    dispatch(setError(''));
    setInputKeyword(e.target.value);
  };

  const handleRegister = async () => {
    await dispatch(
      registerKeyword({
        keyword: inputKeyword,
        item_id: itemId,
        shop_code: shopCode
      })
    )
      .then(unwrapResult)
      .then(() => {
        setInputKeyword('');
      });
    setInputKeyword('');
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Card
          sx={{
            width: '55%',
            height: '80%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            p: 3,
            borderRadius: '10px',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden' // Card全体にオーバーフローを隠す
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="start"
            sx={{ mb: 2 }}
          >
            <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
              キーワード登録
            </Typography>
            <IconButton sx={{ p: 0, m: 0 }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography>
            注力キーワードを1～10個ほど登録することを推奨しています
          </Typography>
          <Typography>※キーワード登録の翌日から追跡が開始されます</Typography>
          {graphloading ? (
            <Box
              flexGrow={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                overflow: 'hidden'
              }}
            >
              <TextField
                fullWidth
                label="新しいキーワード"
                size="small"
                value={inputKeyword}
                onChange={handleInputChange}
                sx={{ my: 1 }}
              />
              <Box display="flex">
                <Button
                  variant="contained"
                  color="detailButton"
                  sx={{
                    borderRadius: '4px',
                    mb: 2,
                    mr: 1,
                    maxWidth: '50px'
                  }}
                  disabled={inputKeyword == ''}
                  onClick={handleRegister}
                >
                  追加
                </Button>
                {errorMessage && (
                  <Typography color="red" sx={{ my: 1 }}>
                    {errorMessage}
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  overflowY: 'auto',
                  overflowX: 'hidden'
                }}
              >
                <List>
                  {keywordList.map((item) => (
                    <ListItem
                      key={item.id}
                      sx={{
                        bgcolor: '#f5f5f5',
                        borderRadius: '4px',
                        my: 1,
                        boxShadow: '1px 1px lightGray',
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: '16px'
                      }}
                    >
                      <ListItemText>{item.keyword}</ListItemText>
                      <Button
                        sx={{
                          bgcolor: '#f04343',
                          color: 'white',
                          borderRadius: '4px',
                          width: '40px'
                        }}
                        onClick={() => handleDeleteKeyword(item.id)}
                      >
                        削除
                      </Button>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>
          )}
        </Card>
      </Modal>
    </>
  );
}
