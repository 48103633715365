/* eslint-disable*/
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, InputAdornment, TextField, Typography } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import BrandSelectCheckbox from 'components/molecules/dashboard/BrandSelectCheckbox';
import {
  currentAcate1,
  currentRcate1,
  currentYcate1
} from 'feactures/category/categoryGraphSlice';
import {
  brandList,
  ownBrandList,
  brandLoading,
  otherCheckedB,
  otherSelectedBrand,
  ownCheckedB,
  ownSelectedBrand,
  setOtherSelectedBrandList,
  setOwnSelectedBrandList,
  allBrandLength,
  allOwnBrandLength,
  pageCount,
  changePageCount,
  fetchBrandList,
  fetchOwnBrandList,
  fetchSearchBrandList,
  setOtherCheckedBrandList,
  setOwnCheckedBrandList,
  ownBrandLoading
} from 'feactures/brand/brandSlice';
import { fetchCategoryGraphData } from 'feactures/category/categoryGraphSlice';
import { fetchCategoryRankData } from 'feactures/category/categoryRankSlice';
import { fetchCategoryShareGraphData } from 'feactures/category/categoryShareSlice';
import { fetchCategoryBrandGraphData } from 'feactures/brand/categoryBrandGraphSlice';
import {
  fetchCategoryBrandShareGraphData,
  fetchCategoryOwnBrandShareGraphData
} from 'feactures/brand/categoryBrandShareSlice';
import { fetchBrandRankData } from 'feactures/brand/brandRankSlice';
import { fetchOwnSales } from 'feactures/measure/measureSlice';
import OwnBrandSelectCheckbox from 'components/molecules/dashboard/OwnBrandSelectCheckbox';

const dispatchTable = {
  category: [
    fetchCategoryGraphData(),
    fetchCategoryRankData(),
    fetchCategoryShareGraphData()
  ],
  brandAnalysis: [
    fetchCategoryBrandGraphData(),
    fetchBrandRankData(),
    fetchCategoryBrandShareGraphData()
  ],
  measureAnalysis: [fetchOwnSales(), fetchCategoryBrandGraphData()],
  productMeasures: [fetchOwnSales()] // 今後商品施策ページを実装する際に中身を追加する
};

const DialogCheckbox = (props) => {
  const { channel, page, section, graphLoading } = props; // eslint-disable-line
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [searchWord, setSearchWord] = useState('');
  const currentPageCount = useSelector(pageCount);
  const brandListLength = useSelector(allBrandLength);
  const ownBrandListLength = useSelector(allOwnBrandLength);
  const selectOtherBrand = useSelector(otherSelectedBrand);
  const checkedOtherBrand = useSelector(otherCheckedB);
  const selectOwnBrand = useSelector(ownSelectedBrand);
  const checkedOwnBrand = useSelector(ownCheckedB);
  const allBrandList = useSelector(brandList);
  const allOwnBrandList = useSelector(ownBrandList);
  const brandloading = useSelector(brandLoading);
  const ownloading = useSelector(ownBrandLoading);

  const currentSelectAmazonCategory1 = useSelector(currentAcate1);
  const currentSelectRakutenCategory1 = useSelector(currentRcate1);
  const currentSelectYahooCategory1 = useSelector(currentYcate1);

  // 店舗ブランド比較に遷移してきた&カテゴリ選択済み&Amazonの場合、自社のブランドリストを取得する
  useEffect(() => {
    if (
      page === 'brandComparison' &&
      section === 'own' &&
      currentSelectAmazonCategory1 !== 0
    ) {
      dispatch(fetchOwnBrandList({ searchword: '' }));
    }
  }, [page]);

  let categorySelected = false;
  if (
    currentSelectAmazonCategory1 !== 0 ||
    currentSelectRakutenCategory1 !== 0 ||
    currentSelectYahooCategory1 !== 0
  ) {
    categorySelected = true;
  }

  let selectBrand = '';
  let checkedBrand = '';
  if (section == 'other') {
    selectBrand = selectOtherBrand;
    checkedBrand = checkedOtherBrand;
  } else if (section == 'own') {
    selectBrand = selectOwnBrand;
    checkedBrand = checkedOwnBrand;
  }

  let brandSelected = false;
  if (channel === 1 && selectBrand.amazon) {
    brandSelected = true;
  } else if (channel === 2 && selectBrand.rakuten) {
    brandSelected = true;
  } else if (channel === 3 && selectBrand.yahoo) {
    brandSelected = true;
  }

  let chBrandList = [];
  let chBrandListLength = '';
  if (channel == 1) {
    if (section === 'own') {
      chBrandList = allOwnBrandList.amazon;
      chBrandListLength = ownBrandListLength.amazon;
    } else {
      chBrandList = allBrandList.amazon;
      chBrandListLength = brandListLength.amazon;
    }
  } else if (channel == 2) {
    chBrandList = allBrandList.rakuten;
    chBrandListLength = brandListLength.rakuten;
  } else if (channel == 3) {
    chBrandList = allBrandList.yahoo;
    chBrandListLength = brandListLength.yahoo;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOk = () => {
    setOpen(false);
    setSearchWord('');
    dispatch(changePageCount(0));
    if (section === 'own' && channel === 1) {
      dispatch(fetchOwnBrandList({ searchword: '' }));
    } else {
      dispatch(fetchBrandList());
    }
    let chname = '';
    if (channel === 1) {
      chname = 'amazon';
    } else if (channel === 2) {
      chname = 'rakuten';
    } else if (channel === 3) {
      chname = 'yahoo';
    }
    if (section == 'other') {
      dispatch(
        setOtherSelectedBrandList({ channel, list: checkedBrand[chname] })
      );
    } else if (section == 'own') {
      dispatch(
        setOwnSelectedBrandList({ channel, list: checkedBrand[chname] })
      );
    }

    if (page === 'brandComparison') {
      if (section === 'own') {
        dispatch(fetchOwnSales());
        dispatch(fetchCategoryOwnBrandShareGraphData());
      } else if (section === 'other') {
        dispatch(fetchCategoryBrandGraphData());
        dispatch(fetchCategoryBrandShareGraphData());
      }
    } else {
      [...dispatchTable[page]].map((r, index) => dispatch(r)); //dispatchTableのreducerの数だけdispatchさせる
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setSearchWord('');
    dispatch(changePageCount(0));
    if (section === 'own' && channel === 1) {
      dispatch(fetchOwnBrandList({ searchword: '' }));
    } else {
      dispatch(fetchBrandList());
    }
    if (section == 'own') {
      if (channel === 1) {
        dispatch(
          setOwnSelectedBrandList({ channel, list: selectBrand.amazon })
        );
        dispatch(setOwnCheckedBrandList({ channel, list: selectBrand.amazon }));
      } else if (channel === 2) {
        dispatch(
          setOwnSelectedBrandList({ channel, list: selectBrand.rakuten })
        );
        dispatch(
          setOwnCheckedBrandList({ channel, list: selectBrand.rakuten })
        );
      } else if (channel === 3) {
        dispatch(setOwnSelectedBrandList({ channel, list: selectBrand.yahoo }));
        dispatch(setOwnCheckedBrandList({ channel, list: selectBrand.yahoo }));
      }
    } else {
      if (channel === 1) {
        dispatch(
          setOtherSelectedBrandList({ channel, list: selectBrand.amazon })
        );
        dispatch(
          setOtherCheckedBrandList({ channel, list: selectBrand.amazon })
        );
      } else if (channel === 2) {
        dispatch(
          setOtherSelectedBrandList({ channel, list: selectBrand.rakuten })
        );
        dispatch(
          setOtherCheckedBrandList({ channel, list: selectBrand.rakuten })
        );
      } else if (channel === 3) {
        dispatch(
          setOtherSelectedBrandList({ channel, list: selectBrand.yahoo })
        );
        dispatch(
          setOtherCheckedBrandList({ channel, list: selectBrand.yahoo })
        );
      }
    }
  };

  const handleChange = (e) => {
    setSearchWord(e.target.value);
  };

  const clickPagenationButton = (count) => {
    try {
      dispatch(changePageCount(count));
      if (searchWord === '') {
        if (page === 'brandComparison' && channel === 1 && section === 'own') {
          dispatch(fetchOwnBrandList({ searchword: '' }));
        } else {
          dispatch(fetchBrandList());
        }
      } else {
        const hankakuKeyword = zenkakuToHankaku(searchWord);
        if (page === 'brandComparison' && channel === 1 && section === 'own') {
          dispatch(fetchOwnBrandList({ searchword: searchWord }));
        } else {
          dispatch(fetchSearchBrandList({ keyword: hankakuKeyword }));
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const zenkakuToHankaku = (str) => {
    return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
  };

  const searchBrandList = (keyword) => {
    const hankakuKeyword = zenkakuToHankaku(keyword);
    try {
      dispatch(changePageCount(0));
      if (page === 'brandComparison' && channel === 1 && section === 'own') {
        dispatch(fetchOwnBrandList({ searchword: hankakuKeyword }));
      } else {
        dispatch(fetchSearchBrandList({ keyword: hankakuKeyword }));
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <LoadingButton
        onClick={handleClickOpen}
        variant="outlined"
        size="small"
        sx={{
          backgroundColor: '#FFFFFF',
          borderColor: categorySelected && !brandSelected ? 'red' : '#BDBDBD',
          borderWidth: categorySelected && !brandSelected ? '2px' : '1px',
          fontSize: '14px',
          minWidth: '150px',
          height: '30px'
        }}
        color="inherit"
        disabled={
          (channel == 2 && section == 'own') ||
          brandloading ||
          chBrandList.length === 0 ||
          graphLoading
        }
        loading={
          (section === 'other' && brandloading) ||
          (section === 'own' && ownloading)
        }
      >
        {channel === 1 && selectBrand.amazon
          ? selectBrand.amazon.brand
          : channel === 2 && selectBrand.rakuten
          ? selectBrand.rakuten.brand
          : channel === 3 && selectBrand.yahoo
          ? selectBrand.yahoo.brand
          : channel == 1
          ? 'ブランドを選択する'
          : '店舗を選択する'}
      </LoadingButton>

      <Dialog open={open} onClose={handleCancel}>
        <DialogActions>
          <Box
            borderBottom={1}
            borderColor="#AAA9AD"
            width="100%"
            sx={{
              display: 'flex',
              alignItems: 'center',
              pb: 2
            }}
          >
            <TextField
              hiddenLabel
              id="outlined-basic"
              size="small"
              placeholder="検索"
              value={searchWord}
              sx={{ width: '270px' }}
              onChange={(e) => {
                handleChange(e);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
            <LoadingButton
              color="inherit"
              variant="contained"
              onClick={() => {
                searchBrandList(searchWord);
              }}
              disabled={brandloading}
              sx={{
                height: '30px',
                borderRadius: 160,
                minWidth: '35px',
                py: 0,
                px: '10px',
                ml: 1,
                '& .MuiButton-startIcon': { marginRight: '0px' }
              }}
              loading={brandloading}
              startIcon={
                <SearchIcon sx={{ height: '18px', width: '18px', mr: 0 }} />
              }
            >
              検索
            </LoadingButton>
          </Box>
        </DialogActions>
        {brandloading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: '500px',
              alignItems: 'center'
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogContent
              sx={{
                py: 0,
                px: '10px',
                height: '500px'
              }}
            >
              {chBrandList.length === 0 ? (
                <Typography
                  sx={{ fontSize: '14px', py: 15, textAlign: 'center' }}
                >
                  検索に一致するブランドがありません。
                </Typography>
              ) : (
                <>
                  {section === 'own' ? (
                    <OwnBrandSelectCheckbox section={section} />
                  ) : (
                    <BrandSelectCheckbox section={section} />
                  )}
                </>
              )}
            </DialogContent>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Typography>
                {(currentPageCount - 1) * 50 + 1} ~{' '}
                {(currentPageCount - 1) * 50 + 50 > chBrandListLength
                  ? chBrandListLength
                  : (currentPageCount - 1) * 50 + 50}{' '}
                / {chBrandListLength}
                件の結果
              </Typography>
              <Button
                color="inherit"
                variant="contained"
                disabled={currentPageCount <= 1}
                onClick={() => {
                  clickPagenationButton(-1);
                }}
                sx={{
                  height: '20px',
                  borderRadius: 160,
                  minWidth: '30px',
                  p: 0,
                  ml: 1
                }}
              >
                <ArrowBackIosNewIcon sx={{ height: '13px', width: '13px' }} />
              </Button>
              <Button
                color="inherit"
                variant="contained"
                disabled={currentPageCount >= chBrandListLength / 50}
                onClick={() => {
                  clickPagenationButton(1);
                }}
                sx={{
                  height: '20px',
                  borderRadius: 160,
                  minWidth: '30px',
                  p: 0,
                  ml: 1
                }}
              >
                <ArrowForwardIosIcon sx={{ height: '13px', width: '13px' }} />
              </Button>
            </Box>
          </>
        )}
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleClickOk}
            color="primary"
            disabled={
              (channel === 1 && checkedBrand.amazon === '') ||
              (channel === 2 && checkedBrand.rakuten === '') ||
              (channel === 3 && checkedBrand.yahoo === '')
            }
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default DialogCheckbox;
