/* eslint-disable*/

import { Box, Container, Grid, Typography } from '@material-ui/core'; // eslint-disable-line
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { kpi_dict } from 'feactures/overview/overviewSlice';
import { useSelector } from 'react-redux';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { envState } from 'feactures/env/envSlice';
import { styled } from '@material-ui/styles';

//チェックマークにカーソルが当たったときの吹き出し
const HtmlTooltip = styled(({ className, placement, ...props }) => (
  <Tooltip
    {...props}
    arrow
    placement={placement}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#6b778c',
    backgroundColor: '#ffffff'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ffffff',
    color: '#6b778c',
    fontSize: '13px',
    border: '1px solid #dadde9',
    maxWidth: 280
  }
}));

const SalesViewer = (props) => {
  const { channel } = props;
  const kpiData = useSelector(kpi_dict);
  const envList = useSelector(envState);
  const env = envList.filter(
    (item) => item.param === 'overview_fluctuation'
  )[0];
  const envViews = envList.filter(
    (item) => item.param === 'views_fluctuation'
  )[0];
  const envNum = parseInt(env.value);
  const envViewsNum = parseInt(envViews.value);
  let kpi = {
    sales: 0,
    views: 0,
    price: 0,
    cvr: 0,
    salesRate: 0,
    viewsRate: 0,
    priceRate: 0,
    cvrRate: 0
  };
  let label = '';
  if (channel == 0) {
    label = '全体';
    kpi = kpiData.all_kpi_dict;
  } else if (channel == 1) {
    label = 'Amazon';
    kpi = kpiData.amazon_kpi_dict;
  } else if (channel == 2) {
    label = '楽天';
    kpi = kpiData.rakuten_kpi_dict;
  } else if (channel == 3) {
    label = 'ヤフー';
    kpi = kpiData.yahoo_kpi_dict;
  }

  const CheckedIcon = (props) => {
    const { kpiNum } = props;
    return (
      <Box
        bgcolor={kpiNum > 0 ? '#00B050' : '#D02C3A'}
        color="white"
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="18px"
        height="18px"
        borderRadius={10}
        position="absolute"
        top={-11}
        right={-13}
        fontSize="18px"
      >
        <span>✓</span>
      </Box>
    );
  };

  const RateBox = (props) => {
    const { kpiNum, kpi } = props;
    let boxFlag = false;
    if (kpi === 'views') {
      boxFlag = Math.abs(kpiNum) >= envViewsNum;
    } else {
      boxFlag = Math.abs(kpiNum) >= envNum;
    }
    return (
      <HtmlTooltip
        placement="top"
        title={
          <span style={{ whiteSpace: 'pre-line' }}>
            ECコンサル要チェックポイント
          </span>
        }
      >
        <Box
          bgcolor={
            boxFlag &&
            (kpiNum > 0 ? '#D2F6CE' : kpiNum < 0 ? '#F6D4D7' : '#FFFFFF')
          }
          border={boxFlag ? 2 : 0}
          borderColor={
            boxFlag &&
            (kpiNum > 0 ? '#00B050' : kpiNum < 0 ? '#D02C3A' : '#FFFFFF')
          }
          borderRadius={1}
          p="2px"
          display="flex"
          position="relative"
        >
          {kpiNum > 0 && <TrendingUpIcon />}
          {kpiNum == 0 && <TrendingFlatIcon />}
          {kpiNum < 0 && <TrendingDownIcon />}
          <Typography fontWeight="bold" fontSize="13px" ml={1}>
            {Math.abs(kpiNum).toFixed(1)}%
          </Typography>
          {boxFlag && <CheckedIcon kpiNum={kpiNum} />}
        </Box>
      </HtmlTooltip>
    );
  };

  return (
    <Container maxWidth={false}>
      <Grid container spacing={0.5}>
        <Grid item xs={4}>
          <Typography fontWeight="bold" fontSize="12px" color="#7F7F7F">
            {label}売上
          </Typography>
        </Grid>
        <Grid item xs={3} display="flex" justifyContent="center">
          <Typography fontSize="12px" color="#7F7F7F">
            閲覧数
          </Typography>
        </Grid>
        <Grid item xs={3} display="flex" justifyContent="center">
          <Typography fontSize="12px" color="#7F7F7F">
            販売単価
          </Typography>
        </Grid>
        <Grid item xs={2} display="flex" justifyContent="center">
          <Typography fontSize="12px" color="#7F7F7F">
            CVR
          </Typography>
        </Grid>
        {/* ここから2列目 */}
        <Grid item xs={4} display="flex" justifyContent="center">
          <Typography fontWeight="bold" fontSize="17px">
            ￥{kpi.sales.toLocaleString()}
          </Typography>
        </Grid>
        <Grid item xs={3} display="flex" justifyContent="center">
          <Typography fontWeight="bold" fontSize="17px">
            {kpi.views.toLocaleString()}
          </Typography>
        </Grid>
        <Grid item xs={3} display="flex" justifyContent="center">
          <Typography fontWeight="bold" fontSize="17px">
            ￥{kpi.price.toLocaleString()}
          </Typography>
        </Grid>
        <Grid item xs={2} display="flex" justifyContent="center">
          <Typography fontWeight="bold" fontSize="17px">
            {kpi.cvr.toFixed(1)}%
          </Typography>
        </Grid>
        {/* ここから3列目 */}
        <Grid
          item
          xs={4}
          display="flex"
          justifyContent="center"
          color={
            kpi.salesRate > 0
              ? '#5AD17C'
              : kpi.salesRate == 0
              ? '#7F7F7F'
              : '#EC5656'
          }
        >
          {channel == 0 ? (
            <Box p="2px" display="flex" position="relative" alignItems="center">
              {kpi.salesRate > 0 && <TrendingUpIcon />}
              {kpi.salesRate == 0 && <TrendingFlatIcon />}
              {kpi.salesRate < 0 && <TrendingDownIcon />}
              <Typography fontWeight="bold" fontSize="13px" ml={1}>
                {Math.abs(kpi.salesRate).toFixed(1)}%
              </Typography>
            </Box>
          ) : (
            <RateBox kpiNum={kpi.salesRate} kpi="sales" />
          )}
        </Grid>
        <Grid
          item
          xs={3}
          display="flex"
          justifyContent="center"
          color={
            kpi.viewsRate > 0
              ? '#5AD17C'
              : kpi.viewsRate == 0
              ? '#7F7F7F'
              : '#EC5656'
          }
        >
          {channel == 0 ? (
            <Box p="2px" display="flex" position="relative" alignItems="center">
              {kpi.viewsRate > 0 && <TrendingUpIcon />}
              {kpi.viewsRate == 0 && <TrendingFlatIcon />}
              {kpi.viewsRate < 0 && <TrendingDownIcon />}
              <Typography fontWeight="bold" fontSize="13px" ml={1}>
                {Math.abs(kpi.viewsRate).toFixed(1)}%
              </Typography>
            </Box>
          ) : (
            <RateBox kpiNum={kpi.viewsRate} kpi="views" />
          )}
        </Grid>
        <Grid
          item
          xs={3}
          display="flex"
          justifyContent="center"
          color={
            kpi.priceRate > 0
              ? '#5AD17C'
              : kpi.priceRate == 0
              ? '#7F7F7F'
              : '#EC5656'
          }
        >
          {channel == 0 ? (
            <Box p="2px" display="flex" position="relative" alignItems="center">
              {kpi.priceRate > 0 && <TrendingUpIcon />}
              {kpi.priceRate == 0 && <TrendingFlatIcon />}
              {kpi.priceRate < 0 && <TrendingDownIcon />}
              <Typography fontWeight="bold" fontSize="13px" ml={1}>
                {Math.abs(kpi.priceRate).toFixed(1)}%
              </Typography>
            </Box>
          ) : (
            <RateBox kpiNum={kpi.priceRate} kpi="price" />
          )}
        </Grid>
        <Grid
          item
          xs={2}
          display="flex"
          justifyContent="center"
          color={
            kpi.cvrRate > 0
              ? '#5AD17C'
              : kpi.cvrRate == 0
              ? '#7F7F7F'
              : '#EC5656'
          }
        >
          {channel == 0 ? (
            <Box p="2px" display="flex" position="relative" alignItems="center">
              {kpi.cvrRate > 0 && <TrendingUpIcon />}
              {kpi.cvrRate == 0 && <TrendingFlatIcon />}
              {kpi.cvrRate < 0 && <TrendingDownIcon />}
              <Typography fontWeight="bold" fontSize="13px" ml={1}>
                {Math.abs(kpi.cvrRate).toFixed(1)}%
              </Typography>
            </Box>
          ) : (
            <RateBox kpiNum={kpi.cvrRate} kpi="cvr" />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default SalesViewer;
