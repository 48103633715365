/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import client from 'index.js';
import { apiUrl } from 'App.js';
import { useDispatch, useSelector } from 'react-redux';

//全お知らせ情報取得
export const fetchNews = createAsyncThunk(
  'all_news/get',
  async (arg, thunkAPI) => {
    const { userId } = await thunkAPI.getState().user;
    const res = await client.post(`${apiUrl}news`, {
      user_id: userId
    });
    return res.data;
  }
);
export const readedNews = createAsyncThunk(
  'readed_news/port',
  async (arg, thunkAPI) => {
    const { userId } = await thunkAPI.getState().user;
    const { newsId } = arg;
    const res = await client.post(`${apiUrl}readed_news`, {
      user_id: userId,
      news_id: newsId
    });
    return res.data;
  }
);

export const allReadedNews = createAsyncThunk(
  'all_readed_news/port',
  async (arg, thunkAPI) => {
    const { userId } = await thunkAPI.getState().user;
    const res = await client.post(`${apiUrl}all_readed_news`, {
      user_id: userId
    });
    return res.data;
  }
);

export const newsSlice = createSlice({
  name: 'news',
  initialState: {
    all_news: [],
    //   {
    //     id: 2,
    //     title: 'システムメンテタイトル2',
    //     content: 'bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb',
    //     readedStatus: 0, //readed_statusが0のものは未読
    //     date: '2022-07-12'
    //   }
    // ],
    isLoading: false,
    error: false
  },
  //   reducers: {},読んだReducer追加する
  extraReducers: (builder) => {
    builder.addCase(fetchNews.fulfilled, (state, action) => {
      // console.log(action.payload);
      return {
        ...state,
        all_news: action.payload.all_news_list,
        isLoading: false,
        error: false
      };
    });
    builder.addCase(fetchNews.pending, (state, action) => {
      // console.log(action.payload);
      return {
        ...state,
        isLoading: true
      };
    });
    builder.addCase(fetchNews.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        error: true
      };
    });
    builder.addCase(readedNews.fulfilled, (state, action) => {
      return {
        ...state,
        all_news: action.payload.all_news_list,
        isLoading: false,
        error: false
      };
    });
    builder.addCase(readedNews.pending, (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    });
    builder.addCase(readedNews.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        error: true
      };
    });
    builder.addCase(allReadedNews.fulfilled, (state, action) => {
      return {
        ...state,
        all_news: action.payload.all_news_list,
        isLoading: false,
        error: false
      };
    });
    builder.addCase(allReadedNews.pending, (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    });
    builder.addCase(allReadedNews.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        error: true
      };
    });
  }
});

export const allNews = (state) => state.news.all_news;

export default newsSlice.reducer;
