/* eslint-disable*/
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  IconButton,
  Box,
  Button,
  Link,
  Checkbox,
  Container,
  FormHelperText,
  TextField,
  Typography,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio
} from '@material-ui/core';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { fetchRegister, fetchUser } from 'feactures/user/userSlice';
import { errorResMessage, errorResStatus } from 'feactures/errorSlice';
import { makeStyles } from '@material-ui/styles';
import { createSubscriptionSession } from 'feactures/plan/planSlice';
import {
  fetchIndustry,
  fetchPremiumRegister,
  industryList
} from 'feactures/plan/premiumRegister';
import StickyButton from 'components/atoms/button/StickyButton';

const PremiumRegister = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const errorMessage = useSelector(errorResMessage);
  const industries = useSelector(industryList);
  const [error, setError] = useState(null);

  useEffect(() => {
    dispatch(fetchIndustry());
  }, []);

  // パスワードの表示・非表示処理
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (values) => {
    // console.log(values);
    setError(null);
    await dispatch(fetchPremiumRegister(values))
      .then(unwrapResult) //unwrapResult で,dispatch結果がfulfilledかrejectedかで条件分岐できる
      .then(() => {
        navigate('/premiumregister/complete', { replace: false });
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const RequiredBox = () => {
    return (
      <Box
        color="#FFFFFF"
        bgcolor="#F26B6B"
        borderRadius={5}
        fontSize="12px"
        fontWeight="bold"
        px="8px"
        mb="1px"
        ml="8px"
      >
        必須
      </Box>
    );
  };
  const OptionBox = () => {
    return (
      <Box
        color="#ffffff"
        bgcolor="#669AF5"
        borderRadius={5}
        fontSize="12px"
        fontWeight="bold"
        px="8px"
        mb="1px"
        ml="8px"
      >
        任意
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Container maxWidth="md">
          <Formik
            initialValues={{
              plan: {
                amazon: '',
                rakuten: '',
                yahoo: ''
              },
              amazonPeriod: '',
              rakutenPeriod: '',
              yahooPeriod: '',
              userId: '',
              password: '',
              paymentMethod: '',
              corporateNumber: '',
              legalStatus: '',
              industry: '',
              campaign: {
                initialFee: false,
                firstMonth: false,
                midMonth: false
              }
            }}
            validationSchema={Yup.object().shape({
              userId: Yup.string().max(255).required('ユーザーIDが未入力です'),
              password: Yup.string()
                .max(100)
                .required('パスワードが未入力です'),
              plan: Yup.object()
                .shape({
                  amazon: Yup.string(),
                  rakuten: Yup.string(),
                  yahoo: Yup.string()
                })
                .test(
                  'plan',
                  '少なくとも1つ以上のプランを選択してください',
                  function (value) {
                    const { amazon, rakuten, yahoo } = value;
                    return amazon || rakuten || yahoo;
                  }
                ),
              amazonPeriod: Yup.string().when('plan.amazon', {
                is: (value) => value && value.length > 0, // plan.amazonが選択済の場合
                then: Yup.string().required('未選択です'), // 必須項目にする
                otherwise: Yup.string() // そうでなければ必須にしない
              }),
              rakutenPeriod: Yup.string().when('plan.rakuten', {
                is: (value) => value && value.length > 0, // plan.rakutenが選択済の場合
                then: Yup.string().required('未選択です'), // 必須項目にする
                otherwise: Yup.string() // そうでなければ必須にしない
              }),
              paymentMethod:
                Yup.string().required('決済方法を選択してください'),
              legalStatus: Yup.string().required('選択してください'),
              corporateNumber: Yup.number().when('legalStatus', {
                is: '2', // paymentMethodが'bank_transfer'のとき
                then: Yup.number()
                  .typeError('半角の数字を入力してください')
                  .required('法人の場合入力必須です'), // industryは必須
                otherwise: Yup.number() // そうでなければ、industryは任意
              }),
              industry: Yup.string().required('業種を選択してください')
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Box sx={{ mb: 3, mt: 3, width: 600 }}>
                    <Typography color="textPrimary" variant="h2">
                      Oxcim有料版利用申し込みフォーム
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                    >
                      <br />
                      Oxcim【有料版】を利用申し込みする場合は下記よりお願いいたします。
                      <br />
                      3営業日以内にカスタマーサポ―トからご案内いたします。
                    </Typography>
                  </Box>
                  <Box width="600px">
                    <InputLabel>
                      <Box display="flex" alignItems="center">
                        申込希望プラン
                        <RequiredBox />
                        {touched.plan && errors.plan && (
                          <FormHelperText sx={{ m: 0, ml: 1 }} error>
                            {errors.plan}
                          </FormHelperText>
                        )}
                      </Box>
                    </InputLabel>
                    <Box display="flex" alignItems="center">
                      <Box>
                        <InputLabel>Amazon</InputLabel>
                        <FormControl sx={{ minWidth: 450, mb: 1 }} size="small">
                          <Select
                            id="demo-select-small"
                            error={Boolean(touched.plan && errors.plan)}
                            name="plan.amazon"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              // plan.amazonの値が1の場合、amazonPeriodを初期化
                              if (
                                e.target.value === '1' &&
                                values.amazonPeriod === '1'
                              ) {
                                setFieldValue('amazonPeriod', ''); // amazonPeriodを初期化
                              }
                            }}
                            value={values.plan.amazon}
                            variant="outlined"
                            sx={{ backgroundColor: '#fff' }}
                          >
                            <MenuItem value={'1'}>
                              基本活用(有料/機能制限なし)
                            </MenuItem>
                            <MenuItem value={'2'}>
                              広告販促おまかせ(有料/広告販促施策 実行支援)
                            </MenuItem>
                            <MenuItem value={'3'}>
                              戦略パートナー(有料/戦略策定～実行支援)
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      <Box ml={1}>
                        <Box display="flex" alignItems="center">
                          <InputLabel>契約期間</InputLabel>
                          {touched.amazonPeriod && errors.amazonPeriod && (
                            <FormHelperText sx={{ m: 0, ml: 1 }} error>
                              {errors.amazonPeriod}
                            </FormHelperText>
                          )}
                        </Box>
                        <FormControl sx={{ minWidth: 150 }} size="small">
                          <Select
                            id="demo-select-small"
                            error={Boolean(
                              touched.amazonPeriod && errors.amazonPeriod
                            )}
                            name="amazonPeriod"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.amazonPeriod}
                            variant="outlined"
                            sx={{ backgroundColor: '#fff', mb: 1 }}
                          >
                            {(values.plan.amazon == '2' ||
                              values.plan.amazon == '3') && (
                              <MenuItem value={'1'}>3か月</MenuItem>
                            )}
                            <MenuItem value={'2'}>6か月</MenuItem>
                            <MenuItem value={'3'}>12か月</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Box>
                        <InputLabel>楽天</InputLabel>
                        <FormControl sx={{ minWidth: 450, mb: 1 }} size="small">
                          <Select
                            id="demo-select-small"
                            error={Boolean(touched.plan && errors.plan)}
                            name="plan.rakuten"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              // plan.rakutenの値が1の場合、rakutenPeriodを初期化
                              if (
                                e.target.value === '1' &&
                                values.rakutenPeriod === '1'
                              ) {
                                setFieldValue('rakutenPeriod', ''); // rakutenPeriodを初期化
                              }
                            }}
                            value={values.plan.rakuten}
                            variant="outlined"
                            sx={{ backgroundColor: '#fff' }}
                          >
                            <MenuItem value={'1'}>
                              基本活用(有料/機能制限なし)
                            </MenuItem>
                            <MenuItem value={'2'}>
                              広告販促おまかせ(有料/広告販促施策 実行支援)
                            </MenuItem>
                            <MenuItem value={'3'}>
                              戦略パートナー(有料/戦略策定～実行支援)
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      <Box ml={1}>
                        <Box display="flex" alignItems="center">
                          <InputLabel>契約期間</InputLabel>
                          {touched.rakutenPeriod && errors.rakutenPeriod && (
                            <FormHelperText sx={{ m: 0, ml: 1 }} error>
                              {errors.rakutenPeriod}
                            </FormHelperText>
                          )}
                        </Box>
                        <FormControl sx={{ minWidth: 150 }} size="small">
                          <Select
                            id="demo-select-small"
                            error={Boolean(
                              touched.rakutenPeriod && errors.rakutenPeriod
                            )}
                            name="rakutenPeriod"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.rakutenPeriod}
                            variant="outlined"
                            sx={{ backgroundColor: '#fff', mb: 1 }}
                          >
                            {(values.plan.rakuten == '2' ||
                              values.plan.rakuten == '3') && (
                              <MenuItem value={'1'}>3か月</MenuItem>
                            )}
                            <MenuItem value={'2'}>6か月</MenuItem>
                            <MenuItem value={'3'}>12か月</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                    {/* <InputLabel sx={{ display: 'flex', alignItems: 'center' }}>
                  Yahoo
                  <Typography ml={2} color="red">
                    現在選択できません。
                  </Typography>
                </InputLabel>
                <FormControl sx={{ minWidth: 250, mb: 1 }} size="small">
                  <Select
                    id="demo-select-small"
                    error={Boolean(touched.plan && errors.plan)}
                    name="plan.yahoo"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.plan.yahoo}
                    variant="outlined"
                    sx={{ backgroundColor: '#fff' }}
                    disabled={true}
                  >
                    <MenuItem value={'1'}>基本活用</MenuItem>
                    <MenuItem value={'2'}>広告販促おまかせ</MenuItem>
                    <MenuItem value={'3'}>戦略パートナー</MenuItem>
                  </Select>
                </FormControl> */}
                    {/* <Box display="flex" alignItems="center">
                  <InputLabel>モール*</InputLabel>
                  {touched.channel && errors.channel && (
                    <FormHelperText sx={{ m: 0, ml: 1 }} error>
                      {errors.channel}
                    </FormHelperText>
                  )}
                </Box>
                <FormControl error={Boolean(touched.channel && errors.channel)}>
                  <Box display="flex">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.channel.amazon}
                          onChange={handleChange}
                          name="channel.amazon"
                        />
                      }
                      label="Amazon"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.channel.rakuten}
                          onChange={handleChange}
                          name="channel.rakuten"
                        />
                      }
                      label="Rakuten"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={values.plan == 1}
                          checked={values.channel.yahoo}
                          onChange={handleChange}
                          name="channel.yahoo"
                        />
                      }
                      label="Yahoo"
                    />
                  </Box>
                </FormControl> */}
                    <Box display="flex" alignItems="center">
                      <InputLabel>
                        <Box display="flex" alignItems="center">
                          OxcimユーザーID
                          <RequiredBox />
                          {touched.userId && errors.userId && (
                            <FormHelperText sx={{ m: 0, ml: 1 }} error>
                              {errors.userId}
                            </FormHelperText>
                          )}
                        </Box>
                      </InputLabel>
                    </Box>
                    <TextField
                      sx={{ mb: 1, backgroundColor: '#fff', width: 440 }}
                      error={Boolean(touched.userId && errors.userId)}
                      fullWidth
                      //   helperText={touched.userId && errors.userId}
                      name="userId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.userId}
                      variant="outlined"
                      size="small"
                      autoComplete="new-user-id"
                      inputProps={{ autoComplete: 'off' }}
                    />
                    <Box display="flex" alignItems="center">
                      <InputLabel>
                        <Box display="flex" alignItems="center">
                          Oxcimパスワード
                          <RequiredBox />
                          {touched.password && errors.password && (
                            <FormHelperText sx={{ m: 0, ml: 1 }} error>
                              {errors.password}
                            </FormHelperText>
                          )}
                        </Box>
                      </InputLabel>
                    </Box>
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      sx={{ mb: 1, backgroundColor: '#fff' }}
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      variant="outlined"
                      size="small"
                      autoComplete="new-password"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        autoComplete: 'off',
                        endAdornment: (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        )
                      }}
                    />
                    <Box display="flex" alignItems="center">
                      <FormLabel component="legend">
                        <Box display="flex" alignItems="center">
                          決済方法
                          <RequiredBox />
                          {touched.paymentMethod && errors.paymentMethod && (
                            <FormHelperText sx={{ m: 0, ml: 1 }} error>
                              {errors.paymentMethod}
                            </FormHelperText>
                          )}
                        </Box>
                      </FormLabel>
                    </Box>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        name="paymentMethod"
                        value={values.paymentMethod}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="カード決済"
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="銀行振込"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Box display="flex" alignItems="center">
                      <InputLabel>
                        <Box display="flex" alignItems="center">
                          法人番号
                          <RequiredBox />
                          {touched.corporateNumber &&
                            errors.corporateNumber && (
                              <FormHelperText sx={{ m: 0, ml: 1 }} error>
                                {errors.corporateNumber}
                              </FormHelperText>
                            )}
                        </Box>
                      </InputLabel>

                      {touched.legalStatus && errors.legalStatus && (
                        <FormHelperText sx={{ m: 0, ml: 1 }} error>
                          {errors.legalStatus}
                        </FormHelperText>
                      )}
                    </Box>
                    <Typography>
                      ※法人番号は
                      <a
                        href="https://www.houjin-bangou.nta.go.jp/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        こちら
                      </a>
                      よりご確認いただけます。
                    </Typography>
                    <Typography>
                      ※個人事業主の方は法人番号の入力は不要です。
                    </Typography>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        name="legalStatus"
                        value={values.legalStatus}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="個人事業主"
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="法人"
                        />
                      </RadioGroup>
                    </FormControl>
                    <Box>
                      <TextField
                        error={Boolean(
                          touched.corporateNumber && errors.corporateNumber
                        )}
                        sx={{ mt: 1, mb: 1, backgroundColor: '#fff' }}
                        name="corporateNumber"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.corporateNumber}
                        variant="outlined"
                        size="small"
                        type="text"
                        label="法人番号"
                      />
                    </Box>
                    <Box display="flex" alignItems="center">
                      <InputLabel>
                        <Box display="flex" alignItems="center">
                          業種
                          <RequiredBox />
                          {touched.industry && errors.industry && (
                            <FormHelperText sx={{ m: 0, ml: 1 }} error>
                              {errors.industry}
                            </FormHelperText>
                          )}
                        </Box>
                      </InputLabel>
                    </Box>
                    <FormControl sx={{ minWidth: 450 }} size="small">
                      <Select
                        id="demo-select-small"
                        error={Boolean(touched.industry && errors.industry)}
                        name="industry"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.industry}
                        variant="outlined"
                        sx={{ backgroundColor: '#fff', mb: 1 }}
                      >
                        {industries.map((industry, index) => (
                          <MenuItem key={index} value={industry.id}>
                            {industry.industry_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {/* <InputLabel>キャンペーン</InputLabel>
                <FormControl
                  error={Boolean(touched.campaign && errors.campaign)}
                  sx={{ mb: 1 }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.campaign.initialFee}
                        onChange={handleChange}
                        name="campaign.initialFee"
                      />
                    }
                    label="初期費用無料キャンペーン"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.campaign.firstMonth}
                        onChange={handleChange}
                        name="campaign.firstMonth"
                      />
                    }
                    label="初月無料キャンペーン"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.campaign.midMonth}
                        onChange={handleChange}
                        name="campaign.midMonth"
                      />
                    }
                    label="月中開始割引キャンペーン"
                  />
                </FormControl> */}
                    <Box sx={{ py: 2 }} onSubmit={handleSubmit}>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        sx={{ mb: 1 }}
                      >
                        申し込む
                      </Button>
                      {error && <Alert severity="error">{errorMessage}</Alert>}
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default PremiumRegister;
