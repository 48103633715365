/* eslint-disable */
import React from 'react';
import { Pie } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  useTheme,
  colors,
  Typography
} from '@material-ui/core';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

const GroupSharegraph = (props) => {
  const { graphdata } = props; // eslint-disable-line
  const theme = useTheme();

  const hexToRgb = (hex) =>
    hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => '#' + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));

  const calculateLighterColor = (color, weight) => {
    const [r, g, b] = color;
    const w = weight;
    const rw = Math.round((255 - r) * w + r);
    const gw = Math.round((255 - g) * w + g);
    const bw = Math.round((255 - b) * w + b);

    return `rgba(${rw},${gw},${bw},1)`;
  };

  const generateGradientColors = (startColor, steps) => {
    const baseRgb = hexToRgb(startColor);
    const colorArray = [];
    for (let step = 0; step < steps; step++) {
      colorArray.push(calculateLighterColor(baseRgb, step / steps));
    }
    return colorArray;
  };

  const data = {
    labels: graphdata.label,
    datasets: [
      {
        label: 'My First Dataset',
        data: graphdata.graphdata,
        backgroundColor: generateGradientColors(
          '#3B5790',
          graphdata.graphdata.length
        ),
        hoverOffset: 4
      }
    ]
  };
  const options = {
    cutoutPercentage: 90,
    layout: { padding: { top: 10, bottom: 10 } },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    height: '100%',
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    },
    plugins: {
      legend: {
        display: false,
        position: 'bottom', // 凡例の表示位置を設定
        labels: {
          boxWidth: 10,
          boxHeight: 8,
          usePointStyle: true,
          pointStyle: 'circle'
        }
      },
      datalabels: {
        display: true,
        align: 'center',
        anchor: 'center',
        color: '#ffffff',
        connectors: {
          color: 'gray'
        },
        formatter: (value, context) => {
          if (value == 0 || context.dataIndex >= 5) {
            return '';
          }
          // ラベルのフォーマットを指定
          return `${graphdata.label[context.dataIndex]} ${value.toFixed(0)}%`;
        }
      },
      tooltip: {
        filter: function (item) {
          return item.dataIndex >= 5;
        },
        callbacks: {
          label: function (tooltipItem, y) {
            let data = tooltipItem.raw;
            if (tooltipItem.dataIndex <= 4) {
              return null;
            } else {
              let label = `${graphdata.label[tooltipItem.dataIndex]} ${data}%`;
              return label;
            }
          }
        }
      }
    }
  };

  return (
    <Card {...props} sx={{ padding: 0 }}>
      <CardContent
        sx={{
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          ':last-child': { pb: 0 }
        }}
      >
        <Box
          sx={{
            height: '300px',
            width: '70%'
          }}
        >
          <Pie data={data} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default GroupSharegraph;
