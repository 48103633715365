/* eslint-disable  */
import { useEffect, useState, useCallback, useRef } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  fabClasses
} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { apiUrl } from 'App.js';
import { fetchUpload, uploadStatus } from 'feactures/monitoring/uploadSlice';
import { fetchMonitorPost } from 'feactures/monitoring/monitoringSlice';
import { fetchProcessKPIPost } from 'feactures/monitoring/processKPISlice';

import ColorButton from 'components/atoms/button/ColorButton';
import { makeStyles } from '@material-ui/styles';
import { errorResMessage, resetError } from 'feactures/errorSlice';

const UploadRakutenCSV = (props) => {
  const { open, setOpen } = props;
  const ref = useRef(null);
  console.log(ref);
  // const [open, setOpen] = useState(true);
  const [err, setErr] = useState(false);
  const dispatch = useDispatch();
  const sendLoading = useSelector(uploadStatus);
  const errorMessage = useSelector(errorResMessage);

  const handleClose = () => {
    // dispatch(resetError());
    setOpen(false);
    setErr(false);
    dispatch(resetError());
  };

  const onPostForm = useCallback(async (data) => {
    // Object の data を FormData 形式に変換する
    const params = new FormData();
    Object.keys(data).forEach(function (key) {
      params.append(key, this[key]);
    }, data);
    await dispatch(fetchUpload({ csvData: params }))
      .then(unwrapResult) //unwrapResult で,dispatch結果がfulfilledかrejectedかで条件分岐できる
      .then(() => {
        // console.log('upload成功');
        // dispatch(fetchMonitorPost());
        dispatch(fetchProcessKPIPost());
        setErr(false);
        handleClose();
      })
      .catch((err) => {
        setErr(err.message);
        // console.log('sippai');
        // setErr(true);
      });
  }, []);

  const onSubmitHandler = useCallback(
    (e) => {
      e.preventDefault();
      onPostForm({
        file: ref.current.files[0]
      });
    },
    [onPostForm]
  );

  return (
    <Dialog
      m={10}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="54%"
      sx={{ p: 2 }}
    >
      <DialogTitle
        id="alert-dialog-title"
        fontSize="22px"
        // textAlign="center"
        sx={{ my: 1 }}
      >
        楽天CSVアップロード
      </DialogTitle>
      <DialogContent sx={{ px: 10 }}>
        <DialogContentText
          id="alert-dialog-description"
          style={{ whiteSpace: 'pre-line', color: '#000000' }}
        >
          楽天のCSVレポートをアップロードしてください
        </DialogContentText>
        <Box m={5}>
          <form type="POST" onSubmit={onSubmitHandler}>
            <input type="file" name="csv" accept=".csv" ref={ref} />
            <LoadingButton
              size="small"
              type="submit"
              loading={sendLoading}
              variant="contained"
            >
              送信
            </LoadingButton>
            {/* {err &&  */}
            {err && <Alert severity="error">{errorMessage}</Alert>}
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default UploadRakutenCSV;
