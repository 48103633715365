/* eslint-disable */
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  useTheme,
  colors,
  Typography
} from '@material-ui/core';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { kpi_dict } from 'feactures/overview/overviewSlice';
import { useSelector } from 'react-redux';

Chart.register(ChartDataLabels);

const MallSharegraph = (props) => {
  const { graphdata } = props; // eslint-disable-line
  const kpiData = useSelector(kpi_dict);
  const theme = useTheme();

  const hexToRgb = (hex) =>
    hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => '#' + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));

  const calculateLighterColor = (color, weight) => {
    const [r, g, b] = color;
    const w = weight;
    const rw = Math.round((255 - r) * w + r);
    const gw = Math.round((255 - g) * w + g);
    const bw = Math.round((255 - b) * w + b);

    return `rgba(${rw},${gw},${bw},1)`;
  };

  const generateGradientColors = (startColor, steps) => {
    const baseRgb = hexToRgb(startColor);
    const colorArray = [];
    for (let step = 0; step < steps; step++) {
      colorArray.push(calculateLighterColor(baseRgb, step / steps));
    }
    return colorArray;
  };

  const data = {
    labels: graphdata.label,
    datasets: [
      {
        label: 'My First Dataset',
        data: graphdata.graphdata,
        backgroundColor: generateGradientColors(
          '#3B5790',
          graphdata.graphdata.length
        ),
        hoverOffset: 4
      }
    ]
  };
  const options = {
    cutoutPercentage: 90,
    layout: { padding: { top: 10, bottom: 10 } },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    height: '100%',
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    },
    plugins: {
      legend: {
        display: false,
        position: 'left' // 凡例の表示位置を設定
      },
      datalabels: {
        display: true,
        align: 'center',
        anchor: 'center',
        color: '#ffffff',
        connectors: {
          color: 'gray'
        },
        formatter: (value, context) => {
          if (value == 0) {
            return '';
          }
          // ラベルのフォーマットを指定
          return `${value.toFixed(0)}%`;
        }
      }
    }
  };

  const notZeroData = graphdata.graphdata.filter((data) => data !== 0);
  let graphNum;
  let graphNumStr;
  let calcNum;
  if (kpiData.all_kpi_dict.sales > 1000000000000) {
    calcNum = kpiData.all_kpi_dict.sales / 1000000000000;
    graphNum = Math.round(calcNum * 10) / 10;
    graphNumStr = `${graphNum.toLocaleString()}兆`;
  } else if (kpiData.all_kpi_dict.sales > 100000000) {
    calcNum = kpiData.all_kpi_dict.sales / 100000000;
    graphNum = Math.round(calcNum * 10) / 10;
    graphNumStr = `${graphNum.toLocaleString()}億`;
  } else if (kpiData.all_kpi_dict.sales > 10000) {
    graphNum = Math.round(kpiData.all_kpi_dict.sales / 10000);
    graphNumStr = `${graphNum.toLocaleString()}万`;
  }

  return (
    <Card {...props} sx={{ padding: 0 }}>
      <CardContent
        sx={{
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          ':last-child': { pb: 0 }
        }}
      >
        <Box
          sx={{
            height: 180,
            width: '70%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {notZeroData.length === 0 ? (
            <Typography align="center">
              全モールの売上が0のため表示できません
            </Typography>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Box
                    sx={{
                      width: '30px',
                      height: '10px',
                      backgroundColor: generateGradientColors('#3B5790', 3)[0],
                      mr: 1
                    }}
                  ></Box>
                  <Typography fontSize={'12px'}>Amazon</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Box
                    sx={{
                      width: '30px',
                      height: '10px',
                      backgroundColor: generateGradientColors('#3B5790', 3)[1],
                      mr: 1
                    }}
                  ></Box>
                  <Typography fontSize={'12px'}>楽天</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Box
                    sx={{
                      width: '30px',
                      height: '10px',
                      backgroundColor: generateGradientColors('#3B5790', 3)[2],
                      mr: 1
                    }}
                  ></Box>
                  <Typography fontSize={'12px'}>ヤフー</Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  height: '100%',
                  width: '200px',
                  position: 'relative'
                }}
              >
                <Doughnut data={data} options={options} />
                <Box
                  sx={{
                    position: 'absolute', // Boxのpositionをabsoluteに指定
                    top: '50%', // 上から50%の位置
                    left: '50%', // 左から50%の位置
                    transform: 'translate(-50%, -50%)', // 自身の50%分移動して最終的に中央に配置
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  {/* <Typography fontSize="14px">
                  {yearmonth.replace('-', '年')}月
                </Typography> */}
                  <Box display="flex" alignItems="end" justifyContent="center">
                    <Typography
                      fontWeight="bold"
                      color="#7288C5"
                      fontSize="14px"
                    >
                      {graphNumStr}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default MallSharegraph;
