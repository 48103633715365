/* eslint-disable*/
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  amazonCategory,
  fetchAsyncCategory1,
  fetchAsyncCategory2,
  fetchAsyncCategory3,
  isLoading,
  rakutenCategory
} from 'feactures/category/categorySlice';
import {
  Box,
  Typography,
  List,
  ListItemButton,
  Collapse,
  ListItemText,
  IconButton
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { currentchannel } from 'feactures/channel/channelSlice';
import {
  validAmazonCategoryList,
  validRakutenCategoryList
} from 'feactures/category/validCategoryListSlice';
import {
  changeSelectedCategory,
  fetchRankingProductList,
  resetSelectedCategory,
  productIsLoading,
  rankingProductIsLoading,
  resetRankingProductPage
} from 'feactures/measure/newMeasureSlice';

const Category1ListRow = ({
  category,
  handleClick,
  handleClick2,
  handleClick3,
  openId,
  setOpenId
}) => {
  const dispatch = useDispatch();
  const channel = useSelector(currentchannel);
  const rakutenCate = useSelector(rakutenCategory);
  const amazonCate = useSelector(amazonCategory);
  const rankingLoading = useSelector(rankingProductIsLoading);
  const registerLoading = useSelector(productIsLoading);
  // disabledにしないカテゴリのリスト
  const rakutenValidCategoryList = useSelector(validRakutenCategoryList);
  const amazonValidCategoryList = useSelector(validAmazonCategoryList);
  const rakutenCategory2List = rakutenCate.category2;
  const amazonCategory2List = amazonCate.category2;
  const [openId2, setOpenId2] = useState(null);

  let category2List;
  if (channel == 1) {
    category2List = amazonCategory2List;
  } else if (channel == 2) {
    category2List = rakutenCategory2List;
  }

  const handleExpand = () => {
    if (openId === category.value) {
      setOpenId(null);
    } else {
      setOpenId(category.value);
      dispatch(fetchAsyncCategory2({ channel, category1Id: category.value }));
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ListItemButton
          disabled={
            registerLoading ||
            rankingLoading ||
            (channel == 1
              ? !amazonValidCategoryList.includes(category.value)
              : channel == 2
              ? !rakutenValidCategoryList.includes(category.value)
              : false)
          }
          onClick={() => {
            handleExpand();
          }}
        >
          <ListItemText primary={category.label} />
        </ListItemButton>
        <IconButton
          disabled={
            registerLoading ||
            rankingLoading ||
            (channel == 1
              ? !amazonValidCategoryList.includes(category.value)
              : channel == 2
              ? !rakutenValidCategoryList.includes(category.value)
              : false)
          }
          onClick={handleExpand}
          sx={{
            transform:
              openId === category.value ? 'rotate(0deg)' : 'rotate(-90deg)',
            transition: '0.2s'
          }}
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      </Box>
      <Collapse in={openId === category.value} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {category2List.map((cate, index) => (
            <Category2ListRow
              category1={category}
              category2={cate}
              key={`cate2-${index}`}
              handleClick2={handleClick2}
              handleClick3={handleClick3}
              openId={openId2}
              setOpenId={setOpenId2}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

const Category2ListRow = ({
  category1,
  category2,
  handleClick2,
  handleClick3,
  openId,
  setOpenId
}) => {
  const dispatch = useDispatch();
  const channel = useSelector(currentchannel);
  const rankingLoading = useSelector(rankingProductIsLoading);
  const registerLoading = useSelector(productIsLoading);
  // disabledにしないカテゴリのリスト
  const rakutenValidCategoryList = useSelector(validRakutenCategoryList);
  const amazonValidCategoryList = useSelector(validAmazonCategoryList);
  const rakutenCate = useSelector(rakutenCategory);
  const amazonCate = useSelector(amazonCategory);
  const rakutenCategory3List = rakutenCate.category3;
  const amazonCategory3List = amazonCate.category3;

  let category3List;
  if (channel == 1) {
    category3List = amazonCategory3List;
  } else if (channel == 2) {
    category3List = rakutenCategory3List;
  }

  const handleExpand = async () => {
    if (openId === category2.value) {
      setOpenId(null);
    } else {
      setOpenId(category2.value);
      await dispatch(
        fetchAsyncCategory3({ channel: channel, category2Id: category2.value })
      )
        .then(unwrapResult)
        .then((res) => {
          // console.log(res.CategoryAmazonResultList);
          if (channel == 1 && res.CategoryAmazonResultList.length == 0) {
            handleClick2(category1, category2);
          } else if (
            channel == 2 &&
            res.CategoryRakutenResultList.length == 0
          ) {
            handleClick2(category1, category2);
          }
        });
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ListItemButton
          disabled={
            registerLoading ||
            rankingLoading ||
            (channel == 1
              ? !amazonValidCategoryList.includes(category2.value)
              : channel == 2
              ? !rakutenValidCategoryList.includes(category2.value)
              : false)
          }
          sx={{ pl: 4 }}
          onClick={() => {
            handleExpand();
          }}
        >
          <ListItemText primary={category2.label} />
        </ListItemButton>
        <IconButton
          disabled={
            registerLoading ||
            rankingLoading ||
            (channel == 1
              ? !amazonValidCategoryList.includes(category2.value)
              : channel == 2
              ? !rakutenValidCategoryList.includes(category2.value)
              : false)
          }
          onClick={handleExpand}
          sx={{
            transform:
              openId === category2.value ? 'rotate(0deg)' : 'rotate(-90deg)',
            transition: '0.2s'
          }}
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      </Box>
      <Collapse in={openId === category2.value} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {category3List.map((cate, index) => (
            <Category3ListRow
              category1={category1}
              category2={category2}
              category3={cate}
              key={`cate3-${index}`}
              handleClick3={handleClick3}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

const Category3ListRow = ({
  category1,
  category2,
  category3,
  handleClick3
}) => {
  const channel = useSelector(currentchannel);
  // disabledにしないカテゴリのリスト
  const rakutenValidCategoryList = useSelector(validRakutenCategoryList);
  const amazonValidCategoryList = useSelector(validAmazonCategoryList);
  const rankingLoading = useSelector(rankingProductIsLoading);
  const registerLoading = useSelector(productIsLoading);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <ListItemButton
        sx={{ pl: 8 }}
        onClick={() => handleClick3(category1, category2, category3)}
        disabled={
          registerLoading ||
          rankingLoading ||
          (channel == 1
            ? !amazonValidCategoryList.includes(category3.value)
            : channel == 2
            ? !rakutenValidCategoryList.includes(category3.value)
            : false)
        }
      >
        <ListItemText primary={category3.label} />
      </ListItemButton>
    </Box>
  );
};

export default function CategoryList() {
  const rakutenCate = useSelector(rakutenCategory);
  const amazonCate = useSelector(amazonCategory);
  const channel = useSelector(currentchannel);
  const amazonCategory1List = amazonCate.category1;
  const rakutenCategory1List = rakutenCate.category1;
  const [openId, setOpenId] = useState(null); // 現在開かれているカテゴリのID

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAsyncCategory1());
  }, []);

  let category1List;
  if (channel == 1) {
    category1List = amazonCategory1List;
  } else if (channel == 2) {
    category1List = rakutenCategory1List;
  }

  const handleClick = (category) => {
    dispatch(resetRankingProductPage());
    dispatch(resetSelectedCategory());
    dispatch(
      changeSelectedCategory([
        {
          level: 1,
          categoryId: category.value,
          name: category.label
        }
      ])
    );
    dispatch(fetchRankingProductList());
  };

  const handleClick2 = (category1, category2) => {
    dispatch(resetRankingProductPage());
    dispatch(resetSelectedCategory());
    dispatch(
      changeSelectedCategory([
        {
          level: 1,
          categoryId: category1.value,
          name: category1.label
        },
        {
          level: 2,
          categoryId: category2.value,
          name: category2.label
        }
      ])
    );
    dispatch(fetchRankingProductList());
  };

  const handleClick3 = (category1, category2, category3) => {
    dispatch(resetRankingProductPage());
    dispatch(resetSelectedCategory());
    dispatch(
      changeSelectedCategory([
        {
          level: 1,
          categoryId: category1.value,
          name: category1.label
        },
        {
          level: 2,
          categoryId: category2.value,
          name: category2.label
        },
        {
          level: 3,
          categoryId: category3.value,
          name: category3.label
        }
      ])
    );
    dispatch(fetchRankingProductList());
  };

  return (
    <Box>
      <List component="nav" sx={{ width: '100%', bgcolor: 'background.paper' }}>
        {category1List.map((cate, index) => (
          <Category1ListRow
            category={cate}
            key={`cate1-${index}`}
            handleClick={handleClick}
            handleClick2={handleClick2}
            handleClick3={handleClick3}
            openId={openId}
            setOpenId={setOpenId}
          />
        ))}
      </List>
    </Box>
  );
}
