/* eslint-disable*/
import { ComposedChart, Bar, Line } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  useTheme,
  Typography,
  Button,
  IconButton,
  colors
  // DeleteIcon
} from '@material-ui/core';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Settings, HelpOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'chartjs-adapter-moment';
import { useNavigate } from 'react-router-dom';
import { benchmark } from 'feactures/channel/channelSlice';

//「？」マークにカーソルが当たったときの吹き出し
export const HtmlTooltip = styled(({ className, placement, ...props }) => (
  <Tooltip
    {...props}
    arrow
    placement={placement}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper
    // border: '1px solid #dadde9'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    maxWidth: 280
  }
}));

const Monitorgraph = (props) => {
  const {
    title,
    color,
    benchData, //競合グラフ
    graphdata,
    graphTitle,
    graphtype,
    term, //[,,,]
    setting,
    setopen,
    num,
    rate,
    sihyou,
    tanni,
    asinTitle,
    info,
    disabled,
    annotation
  } = props; // eslint-disable-line
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bench = useSelector(benchmark);

  const numEn = num?.toLocaleString(); // eslint-disable-line

  const asinhandle = (title) => {
    navigate('/app/asin', {
      state: {
        title: title,
        sihyou: sihyou,
        asinTitle: asinTitle,
        tanni: tanni,
        term: term,
        graphtype,
        color
      }
    });
  };

  const adhandle = (title) => {
    navigate('/app/advertise', {
      state: {
        title: title,
        sihyou: sihyou,
        graphTitle: graphTitle,
        tanni: tanni,
        term: term,
        info,
        graphtype,
        color
      }
    });
  };

  const kwhandle = (title) => {
    navigate('/app/keywords/detail', {
      state: {
        title: title,
        sihyou: sihyou,
        asinTitle: asinTitle,
        tanni: tanni,
        term: term,
        graphtype,
        color
      }
    });
  };

  const options = {
    interaction: {
      mode: 'nearest',
      intersect: false
    },
    scales: {
      x: {
        scaleLabel: {
          // 軸ラベル
          display: true, // 表示設定
          labelString: '横軸ラベル', // ラベル
          fontColor: 'red', // 文字の色
          fontSize: 16 // フォントサイズ
        },
        grid: {
          display: false,
          drawBorder: false
        },
        title: {
          display: false
          // text: 'x',
          // font: {
          //   size: 14
          // }
        }
      },
      y: {
        reverse: tanni === '位',
        // scaleLabel: {
        //   display: true,
        //   labelString: tanni
        // },
        ticks: {
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          min: 1
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem, y) {
            let label = tooltipItem.dataset.label;
            let data = tooltipItem.raw;
            label += ' : ' + data.toLocaleString() + tanni;
            return label;
          }
        }
      },
      datalabels: {
        display: false
      },
      legend: { display: false } //ラベル名(凡例)非表示
      // tooltips: {
      //   mode: 'x'
      // }
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: true,
    cornerRadius: 20,
    layout: {
      maxwidth: '100px',
      padding: {
        left: 0,
        right: 20,
        top: 0,
        bottom: 0
      }
    }
  };
  const data =
    bench == 2 && benchData //競合ボタンが押されてかつ競合データがある時のみ分身グラフを表示
      ? {
          datasets: [
            {
              label: title,
              barThickness: 'flex',
              backgroundColor: color,
              data: graphdata
            },
            {
              label: '競合' + title,
              barThickness: 'flex',
              backgroundColor: color + 'B3',
              data: benchData
            }
          ],
          labels: term
        }
      : {
          datasets: [
            {
              label: title,
              barThickness: 'flex',
              backgroundColor: color,
              data: graphdata,
              maxBarThickness: 20
            }
          ],
          labels: term
        };

  let button;
  // console.log(asinTitle);
  if (asinTitle === 'brandedSearch') {
    button = (
      <button disabled={disabled} onClick={() => kwhandle(title)}>
        KW詳細
      </button>
    );
  } else if (
    graphTitle === 'ctr' ||
    graphTitle === 'impression' ||
    graphTitle === 'click'
  ) {
    button = (
      <button disabled={disabled} onClick={() => adhandle(title)}>
        広告詳細
      </button>
    );
  } else {
    button = (
      <button disabled={disabled} onClick={() => asinhandle(title)}>
        商品詳細
      </button>
    );
  }

  // /* prettier-ignore */
  return (
    <>
      <Card>
        <CardContent>
          {/* <Box style={{ overflowX: 'auto' }}>
            <Box style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
              <Box sx={{ display: 'inline-block' }}> */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              flex: 'auto 0 0',
              overflowX: 'auto'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 'auto 0 0',
                mr: '5px',
                width: '250px'
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Typography component="div" variant="h5" mb={1}>
                  {title}
                </Typography>

                {asinTitle == 'brandedSearch' ? (
                  <Settings
                    color="action"
                    fontSize="small"
                    sx={{ ml: 1 }}
                    onClick={() => kwhandle(title)} //指名検索時のみ歯車マーク
                  />
                ) : (
                  ''
                )}
              </Box>
              <Typography sx={{ paddingBottom: '4px' }}>
                {annotation}
              </Typography>
              {button}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'flex-end',
                  boxsizing: 'border-box',
                  mt: 2
                }}
              >
                <Typography
                  variant="h5"
                  color="text.secondary"
                  component="div"
                  mt={'10px'}
                  mr={0.5}
                >
                  {sihyou}
                </Typography>
                {tanni == '円' || '回' ? (
                  <Typography
                    variant="h2"
                    color="text.secondary"
                    component="div"
                  >
                    {numEn}
                  </Typography>
                ) : (
                  <Typography
                    variant="h2"
                    color="text.secondary"
                    component="div"
                  >
                    {num}
                  </Typography>
                )}
                <Typography
                  variant="h5"
                  color="text.secondary"
                  component="div"
                  mt={'10px'}
                  ml={'3px'}
                >
                  {tanni}
                </Typography>
                <HtmlTooltip
                  placement="top"
                  title={<span style={{ whiteSpace: 'pre-line' }}>{info}</span>}
                >
                  <span>
                    <IconButton>
                      <HelpOutlined style={{ fontSize: '15px', mr: 3 }} />
                    </IconButton>
                  </span>
                </HtmlTooltip>
                <Box textAlign="center">
                  {bench == 2 ? '競合比較' : '期間比較'}
                  {bench == 2 ? (
                    <>
                      {benchData ? (
                        <>
                          {rate < 0 ? (
                            <Typography
                              variant="h4"
                              color="red"
                              component="div"
                            >
                              {rate}%
                            </Typography>
                          ) : (
                            <Typography
                              variant="h4"
                              color="green"
                              component="div"
                            >
                              {rate}%
                            </Typography>
                          )}
                        </>
                      ) : (
                        <Typography
                          variant="h4"
                          color="text.secondary"
                          component="div"
                        >
                          -
                        </Typography>
                      )}
                    </>
                  ) : (
                    <>
                      {rate < 0 ? (
                        <Typography variant="h4" color="red" component="div">
                          {rate}%
                        </Typography>
                      ) : (
                        <Typography variant="h4" color="green" component="div">
                          {rate}%
                        </Typography>
                      )}
                    </>
                  )}
                </Box>
                {/* )} */}
              </Box>
            </Box>

            <Box
              sx={{
                height: 150,
                width: '90%'
              }}
            >
              {graphtype === 'Bar' ? (
                <Bar
                  width="100%"
                  boxsizing="border-box"
                  data={data}
                  options={options}
                />
              ) : (
                ''
              )}
              {graphtype === 'Line' ? (
                <Line
                  width="100%"
                  boxsizing="border-box"
                  data={data}
                  options={options}
                />
              ) : (
                ''
              )}
            </Box>
          </Box>
          {/* </Box>
            </Box>
          </Box> */}
        </CardContent>
      </Card>
    </>
  );
};

export default Monitorgraph;
