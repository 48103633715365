/* eslint-disable*/
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  Snackbar,
  Alert
} from '@material-ui/core';
import {
  fetchChangePass,
  fetchUpdatePass,
  userinfo
} from 'feactures/user/userSlice';

const UpdatePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const userInfo = useSelector(userinfo);
  const imaPass = userInfo.passwordExpiration.currentPW;
  const expirationStatus = userInfo.passwordExpiration.status;

  useEffect(() => {
    if (!expirationStatus) {
      navigate('/login');
    }
  }, []);

  const modoru = () => {
    navigate(-1);
  };

  const onSubmit = async (values) => {
    setError(null);
    await dispatch(fetchUpdatePass(values))
      .then(unwrapResult) //unwrapResult で,dispatch結果がfulfilledかrejectedかで条件分岐できる
      .then(() => {
        setSuccess(true);
        setTimeout(function () {
          navigate('/login');
        }, 1000);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const passwordExp =
    /^(?=.*?[a-z])(?=.*?\d)(?=.*?[!-\/:-@[-`{-~])[!-~]{8,100}$/i;

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          height: 'auto',
          minHeight: '100vh',
          width: '100%',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Container sx={{ width: 'auto', py: 3 }}>
          <Typography fontSize="16px" fontWeight="bold">
            パスワード変更
          </Typography>
          <Typography
            color="error"
            gutterBottom
            variant="body2"
            fontWeight="bold"
          >
            パスワードの有効期限が切れています。
          </Typography>
          <Typography color="textSecondary" gutterBottom variant="body2">
            新しいパスワードを入力し、「パスワードを変更する」ボタンをクリックしてください。
          </Typography>
          <Formik
            initialValues={{
              newPass: '',
              kakuninPass: ''
            }}
            validationSchema={Yup.object().shape({
              newPass: Yup.string()
                .required('未入力です。')
                .min(8, 'パスワードは最低8文字です。')
                .matches(
                  passwordExp,
                  '半角英数字記号を組み合わせて入力してください'
                )
                .test(
                  'not-same-as-imaPass',
                  '現在と同じパスワードは使用できません',
                  function (value) {
                    return value !== imaPass;
                  }
                ),
              kakuninPass: Yup.string()
                .oneOf([Yup.ref('newPass')], 'パスワードが一致しません')
                .required('未入力です。')
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box>
                  <Container mt={7} maxWidth="sm">
                    新しいパスワード：
                    <TextField
                      error={Boolean(touched.newPass && errors.newPass)}
                      fullWidth
                      helperText={touched.newPass && errors.newPass}
                      margin="normal"
                      name="newPass"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.newPass}
                      variant="outlined"
                    />
                    新しいパスワード（再入力）：
                    <TextField
                      error={Boolean(touched.kakuninPass && errors.kakuninPass)}
                      fullWidth
                      helperText={touched.kakuninPass && errors.kakuninPass}
                      margin="normal"
                      name="kakuninPass"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.kakuninPass}
                      variant="outlined"
                    />
                    <Box sx={{ py: 2 }}>
                      <Button
                        color="primary"
                        disabled={isSubmitting || success}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        パスワードを変更する
                      </Button>
                      {error && (
                        <Alert severity="error">
                          入力に誤りがあります。パスワードをご確認ください。
                        </Alert>
                      )}
                    </Box>
                  </Container>
                </Box>
              </form>
            )}
          </Formik>
          <Typography color="textSecondary" variant="body1">
            <button onClick={() => modoru()}>←戻る</button>
          </Typography>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={success}
            message="パスワードを変更しました"
          />
        </Container>
      </Box>
    </>
  );
};

export default UpdatePassword;
