/* eslint-disable */
import * as React from 'react';
import { useEffect, useState, useCallback, useContext, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subMonths,
  subWeeks
} from 'date-fns';
import { Button, Box, Typography, Divider, Stack } from '@material-ui/core'; // eslint-disable-line
import { DateRangePicker } from 'rsuite';
import { fetchProcessKPIPost } from 'feactures/monitoring/processKPISlice';
import { fetchViewsPost } from 'feactures/monitoring/viewsSlice';
import { fetchCVRPost } from 'feactures/monitoring/CVRSlice';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  fetchMonitorPost,
  fetchMonitorPost2,
  fetchAsinMonitor,
  fetchGroupMonitor,
  fetchAsinMonitor2,
  fetchGroupMonitor2,
  currentAsin
} from 'feactures/monitoring/monitoringSlice';
import {
  fetchGroups,
  fetchGroupDataList,
  fetchGroupAsinDataList,
  resetGroupAsinPage,
  resetGroupDataPage,
  changeGroupDataSearchword
} from 'feactures/monitoring/groupSlice';
import SwitchButton from './SwitchButton';
import {
  currentTerm,
  currentTerm2,
  startDate,
  endDate,
  changeDate,
  changePage,
  changeDate2,
  startDate2,
  endDate2,
  changeTempDate,
  changeTempDate2,
  confirmDate,
  confirmDate2,
  resetTempDate,
  resetTempDate2,
  tempEnd,
  tempStart,
  tempEnd2,
  tempStart2,
  tempTerm,
  tempTerm2
} from 'feactures/date/dateSlice';
import { setError } from 'feactures/errorSlice';
import * as moment from 'moment/moment';
import 'moment/locale/ja';
import 'rsuite/dist/rsuite.min.css';
import './KpiDatePickerStyle.css';
import { ReloadLayoutContext } from 'components/templates/dashboard/DashboardLayout';
import { fetchAsyncMonitorAsin } from 'feactures/monitoring/monitorAsinSlice';
import {
  fetchAsyncAsinSales,
  fetchOverview,
  resetAsinPage,
  resetGroupPage,
  setOvAsinSalesDataPerPage,
  setOvAsinSalesDataSearchword,
  fetchOverviewGroupData
} from 'feactures/overview/overviewSlice';
import {
  termCompare,
  changeTermCompareStatus
} from 'feactures/monitoring/monitoringSlice';
import {
  changeYearMonth2,
  changeYearMonth
} from 'feactures/section/sectionSlice';
moment.locale('ja');

const year = [
  '1月',
  '2月',
  '3月',
  '4月',
  '5月',
  '6月',
  '7月',
  '8月',
  '9月',
  '10月',
  '11月',
  '12月'
];

const yesterday = new Date().setDate(new Date().getDate() - 1);
const sevenDaysAgo = new Date().setDate(new Date().getDate() - 7);
const fourteenDaysAgo = new Date().setDate(new Date().getDate() - 14);

const KpiDatePicker = memo(({ setTerm, page }) => {
  const { reloadStatus, setReloadStatus } = useContext(ReloadLayoutContext);
  const dispatch = useDispatch();
  const containerRef = React.useRef();
  const containerRef2 = React.useRef();
  const alignment = useSelector(currentTerm); // 現在選択中の期間1の種類
  // const alignment2 = useSelector(currentTerm2); // 現在選択中の期間2の種類
  const termCompareStatus = useSelector(termCompare);
  // const dateStart = useSelector(startDate);
  // const dateEnd = useSelector(endDate);
  // const dateStart2 = useSelector(startDate2);
  // const dateEnd2 = useSelector(endDate2);
  const tempDateStart = useSelector(tempStart);
  const tempDateEnd = useSelector(tempEnd);
  const tempDateStart2 = useSelector(tempStart2);
  const tempDateEnd2 = useSelector(tempEnd2);
  const tempDateTerm = useSelector(tempTerm);
  const tempDateTerm2 = useSelector(tempTerm2);
  const [display, setDisplay] = useState('none');
  const [calenderIsOpen, setCalenderIsOpen] = useState(true);

  var customS = new Date(tempDateStart);
  var customE = new Date(tempDateEnd);
  var customS2 = tempDateStart2 === '' ? new Date() : new Date(tempDateStart2);
  var customE2 = tempDateEnd2 === '' ? new Date() : new Date(tempDateEnd2);

  let predefinedBottomRanges = [];
  if (tempDateTerm2 == 'year') {
    predefinedBottomRanges = [
      {
        label: '今年',
        value: [
          new Date(new Date().getFullYear(), 0, 1),
          new Date(new Date().getFullYear(), 11, 31)
        ]
      },
      {
        label: '昨年',
        value: [
          new Date(new Date().getFullYear() - 1, 0, 1),
          new Date(new Date().getFullYear() - 1, 11, 31)
        ]
      }
    ];
  } else {
    predefinedBottomRanges = [
      {
        label: '今週',
        value: [startOfWeek(new Date()), endOfWeek(new Date())]
      },
      {
        label: '過去7日',
        value: [sevenDaysAgo, yesterday]
      },
      {
        label: '先週',
        value: [
          startOfWeek(subWeeks(new Date(), 1)),
          endOfWeek(subWeeks(new Date(), 1))
        ]
      },
      {
        label: '過去14日',
        value: [fourteenDaysAgo, yesterday]
      },
      {
        label: '今月',
        value: [startOfMonth(new Date()), endOfMonth(new Date())]
      },
      {
        label: '先月',
        value: [
          startOfMonth(subMonths(new Date(), 1)),
          endOfMonth(subMonths(new Date(), 1))
        ]
      },
      {
        label: '今年',
        value: [
          new Date(new Date().getFullYear(), 0, 1),
          new Date(new Date().getFullYear(), 11, 31)
        ]
      }
    ];
  }

  let comparePreDifinedRanges = [];
  if (tempDateTerm == 'year') {
    comparePreDifinedRanges = [
      {
        label: '今年',
        value: [
          new Date(new Date().getFullYear(), 0, 1),
          new Date(new Date().getFullYear(), 11, 31)
        ]
      },
      {
        label: '昨年',
        value: [
          new Date(new Date().getFullYear() - 1, 0, 1),
          new Date(new Date().getFullYear() - 1, 11, 31)
        ]
      }
    ];
  } else {
    comparePreDifinedRanges = [
      {
        label: '今週',
        value: [startOfWeek(new Date()), endOfWeek(new Date())]
      },
      {
        label: '過去7日',
        value: [sevenDaysAgo, yesterday]
      },
      {
        label: '先週',
        value: [
          startOfWeek(subWeeks(new Date(), 1)),
          endOfWeek(subWeeks(new Date(), 1))
        ]
      },
      {
        label: '過去14日',
        value: [fourteenDaysAgo, yesterday]
      },
      {
        label: '今月',
        value: [startOfMonth(new Date()), endOfMonth(new Date())]
      },
      {
        label: '先月',
        value: [
          startOfMonth(subMonths(new Date(), 1)),
          endOfMonth(subMonths(new Date(), 1))
        ]
      }
    ];
  }

  useEffect(() => {
    if (reloadStatus == true) {
      // dispatchHandle(page);
      //リロードステータスを元に戻す
      setReloadStatus(false);
    }
  }, [reloadStatus]);

  let label = [];
  const list_all_date = (params_start, params_end) => {
    var start = moment(params_start),
      end = moment(params_end);
    while (start.diff(end) <= 0) {
      label.push(start.format('M/D'));
      start.add(1, 'days');
    }
  };

  let monthSet = new Set();
  const list_all_months = (params_start, params_end) => {
    var start = moment(params_start),
      end = moment(params_end);
    while (start.diff(end) <= 0) {
      monthSet.add(start.format('M月'));
      start.add(1, 'days');
    }
  };

  const dispatchTable = {
    overview: [
      resetAsinPage(),
      resetGroupPage(),
      setOvAsinSalesDataPerPage(10),
      setOvAsinSalesDataSearchword(''),
      fetchOverview(),
      fetchOverviewGroupData({ searchword: '' }),
      fetchGroups(),
      setOvAsinSalesDataSearchword(''),
      resetAsinPage(),
      setOvAsinSalesDataPerPage(10),
      fetchAsyncAsinSales({ searchword: '' })
    ],
    monitoring: [
      resetAsinPage(),
      resetGroupDataPage(),
      changeGroupDataSearchword(''),
      fetchGroups(),
      fetchGroupDataList({ searchword: '' }),
      fetchAsyncAsinSales({ searchword: '' })
      // fetchMonitorPost()
    ],
    monitoring2: [fetchMonitorPost2()],
    monitoringAsin: [fetchAsinMonitor()],
    monitoringAsin2: [fetchAsinMonitor2()],
    monitoringGroup: [
      fetchGroups(),
      fetchGroupMonitor(),
      fetchGroupAsinDataList({
        searchword: ''
      }),
      resetGroupAsinPage(),
      resetGroupDataPage()
    ],
    monitoringGroup2: [fetchGroupMonitor2()],
    sales: [fetchAsyncMonitorAsin({ pageNum: 1 })],
    views: [fetchProcessKPIPost(), fetchViewsPost()],
    cvr: [fetchProcessKPIPost(), fetchCVRPost()]
  };

  const dispatchHandle = useCallback((currentPage) => {
    return [...dispatchTable[currentPage]].map((r, index) => dispatch(r));
  }, []);

  // ページが変わった時
  useEffect(() => {
    dispatch(changePage({ page }));
    dispatchHandle(page);
    dispatch(changeTermCompareStatus(false));

    //今選択されている日付で横軸をセット
    // if (alignment == 'year') {
    //   setTerm(year);
    // } else {
    //   list_all_date(customS, customE);
    //   setTerm(label);
    // }
  }, [page]);

  // ↓カスタムか月かの判定↓
  let isOneMonth;
  const checkIsOneMonth = (startDate, endDate) => {
    const startMonthFristDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      1
    );
    const endMonthLastDate = new Date(
      endDate.getFullYear(),
      endDate.getMonth() + 1,
      0
    );
    if (
      startDate.setHours(0, 0, 0, 0) ===
        startMonthFristDate.setHours(0, 0, 0, 0) &&
      endDate.setHours(23, 59, 59, 999) ===
        endMonthLastDate.setHours(23, 59, 59, 999)
    ) {
      isOneMonth = true;
    } else {
      isOneMonth = false;
    }
  };

  const handleChange = (value, e) => {
    const startDate = new Date(value[0]);
    const endDate = new Date(value[1]);
    checkIsOneMonth(startDate, endDate);

    if (e.target.textContent == '今年' || e.target.textContent == '昨年') {
      dispatch(
        changeTempDate({
          start: moment(value[0]).format('YYYY-MM-DD'),
          end: moment(value[1]).format('YYYY-MM-DD'),
          term: 'year',
          termArray: year
        })
      );
    } else if (
      e.target.textContent == '先月' ||
      e.target.textContent == '今月' ||
      isOneMonth
    ) {
      const start = moment(value[0]).format('YYYY-MM-DD');
      const end = moment(value[1]).format('YYYY-MM-DD');
      list_all_date(start, end);
      setTerm(label);
      dispatch(
        changeTempDate({
          start: moment(value[0]).format('YYYY-MM-DD'),
          end: moment(value[1]).format('YYYY-MM-DD'),
          term: 'month',
          termArray: label
        })
      );
    } else if (
      e.target.textContent == '今週' ||
      e.target.textContent == '先週'
    ) {
      const start = moment(value[0]).format('YYYY-MM-DD');
      const end = moment(value[1]).format('YYYY-MM-DD');
      list_all_date(start, end);
      setTerm(label);
      dispatch(
        changeTempDate({
          start: moment(value[0]).format('YYYY-MM-DD'),
          end: moment(value[1]).format('YYYY-MM-DD'),
          term: 'week',
          termArray: label
        })
      );
    } else if (
      e.target.textContent == '過去7日' ||
      e.target.textContent == '過去14日'
    ) {
      const start = moment(value[0]).format('YYYY-MM-DD');
      const end = moment(value[1]).format('YYYY-MM-DD');
      list_all_date(start, end);
      setTerm(label);
      dispatch(
        changeTempDate({
          start: moment(value[0]).format('YYYY-MM-DD'),
          end: moment(value[1]).format('YYYY-MM-DD'),
          term: 'day',
          termArray: label
        })
      );
    }
  };

  const handleChange2 = (value, e) => {
    const startDate = new Date(value[0]);
    const endDate = new Date(value[1]);
    checkIsOneMonth(startDate, endDate);
    if (e.target.textContent == '今年' || e.target.textContent === '昨年') {
      dispatch(
        changeTempDate2({
          start: moment(value[0]).format('YYYY-MM-DD'),
          end: moment(value[1]).format('YYYY-MM-DD'),
          term: 'year',
          termArray: year
        })
      );
    } else if (
      e.target.textContent == '先月' ||
      e.target.textContent == '今月' ||
      isOneMonth
    ) {
      const start = moment(value[0]).format('YYYY-MM-DD');
      const end = moment(value[1]).format('YYYY-MM-DD');
      list_all_date(start, end);
      setTerm(label);
      dispatch(
        changeTempDate2({
          start: moment(value[0]).format('YYYY-MM-DD'),
          end: moment(value[1]).format('YYYY-MM-DD'),
          term: 'month',
          termArray: label
        })
      );
    } else if (
      e.target.textContent == '今週' ||
      e.target.textContent == '先週'
    ) {
      const start = moment(value[0]).format('YYYY-MM-DD');
      const end = moment(value[1]).format('YYYY-MM-DD');
      list_all_date(start, end);
      setTerm(label);
      dispatch(
        changeTempDate2({
          start: moment(value[0]).format('YYYY-MM-DD'),
          end: moment(value[1]).format('YYYY-MM-DD'),
          term: 'week',
          termArray: label
        })
      );
    } else if (
      e.target.textContent == '過去7日' ||
      e.target.textContent == '過去14日'
    ) {
      const start = moment(value[0]).format('YYYY-MM-DD');
      const end = moment(value[1]).format('YYYY-MM-DD');
      list_all_date(start, end);
      setTerm(label);
      dispatch(
        changeTempDate2({
          start: moment(value[0]).format('YYYY-MM-DD'),
          end: moment(value[1]).format('YYYY-MM-DD'),
          term: 'day',
          termArray: label
        })
      );
    }
  };

  // 日付の幅が１か月以上あるかどうかの判定
  let isMoreOneMonth;
  const checkMoreOneMonth = (startDate, endDate) => {
    const oneMonthTime = 1000 * 60 * 60 * 24 * 30; // 1ヶ月のミリ秒数（30日と仮定）
    const diffTime = Math.abs(endDate - startDate); // 二つの日付の差（ミリ秒）

    if (
      startDate.getFullYear() === endDate.getFullYear() &&
      startDate.getMonth() === endDate.getMonth()
    ) {
      isMoreOneMonth = false;
    } else if (diffTime < oneMonthTime) {
      isMoreOneMonth = false;
    } else {
      isMoreOneMonth = true;
    }
  };

  // 手動で日付を選択した場合
  const handleSelect = (value, e) => {
    if (customS !== customE) {
      customS = value;
      customE = value;
    } else {
      if (value <= customS) {
        customS = value;
        const start = moment(customS).format('YYYY-MM-DD');
        const end = moment(customE).format('YYYY-MM-DD');

        list_all_date(start, end);
        setTerm(label);
        dispatch(
          changeTempDate({
            start: moment(customS).format('YYYY-MM-DD'),
            end: moment(customE).format('YYYY-MM-DD'),
            term: 'custom',
            termArray: label
          })
        );
        // 期間2が年単位の比較になっている場合、一度リセットする
        if (tempDateTerm2 == 'year') {
          dispatch(
            changeTempDate2({
              start: moment(customS).format('YYYY-MM-DD'),
              end: moment(customE).format('YYYY-MM-DD'),
              term: 'custom',
              termArray: label
            })
          );
        }
      } else {
        customE = value;
        const start = moment(customS).format('YYYY-MM-DD');
        const end = moment(customE).format('YYYY-MM-DD');

        list_all_date(start, end);
        setTerm(label);
        dispatch(
          changeTempDate({
            start: moment(customS).format('YYYY-MM-DD'),
            end: moment(customE).format('YYYY-MM-DD'),
            term: 'custom',
            termArray: label
          })
        );
        // 期間2が年単位の比較になっている場合、一度リセットする
        if (tempDateTerm2 == 'year') {
          dispatch(
            changeTempDate2({
              start: moment(customS).format('YYYY-MM-DD'),
              end: moment(customE).format('YYYY-MM-DD'),
              term: 'custom',
              termArray: label
            })
          );
        }
      }
    }
  };

  const handleSelect2 = (value, e) => {
    // 期間1で年単位を選択していた場合、期間2でも年単位でしか選択不可とする
    if (alignment == 'year' || tempDateTerm == 'year') {
      const selectedDate = new Date(value);
      const selectedYear = selectedDate.getFullYear();
      customS2 = new Date(selectedYear, 0, 1);
      customE2 = new Date(selectedYear, 11, 31);
      setTerm(year);
      dispatch(
        changeTempDate2({
          start: moment(customS2).format('YYYY-MM-DD'),
          end: moment(customE2).format('YYYY-MM-DD'),
          term: 'year',
          termArray: year
        })
      );
    } else if (customS2 !== customE2) {
      customS2 = value;
      customE2 = value;
    } else {
      if (value <= customS2) {
        customS2 = value;
        const start = moment(customS2).format('YYYY-MM-DD');
        const end = moment(customE2).format('YYYY-MM-DD');
        list_all_date(start, end);
        setTerm(label);
        dispatch(
          changeTempDate2({
            start: moment(customS2).format('YYYY-MM-DD'),
            end: moment(customE2).format('YYYY-MM-DD'),
            term: 'custom',
            termArray: label
          })
        );
      } else {
        customE2 = value;
        const start = moment(customS2).format('YYYY-MM-DD');
        const end = moment(customE2).format('YYYY-MM-DD');
        list_all_date(start, end);
        setTerm(label);
        dispatch(
          changeTempDate2({
            start: moment(customS2).format('YYYY-MM-DD'),
            end: moment(customE2).format('YYYY-MM-DD'),
            term: 'custom',
            termArray: label
          })
        );
      }
    }
  };

  const handleOkClick = () => {
    dispatch(setError({ state: null, data: null }));
    if (tempDateTerm == 'year' && label.length === 0) {
      setTerm(year);
    }
    dispatch(confirmDate());

    // 期間比較がONの場合のみ、monitoring2のdispatchtableを実行する
    if (termCompareStatus) {
      dispatch(confirmDate2());
      const year2 = customS2.getFullYear();
      const month2 = customS2.getMonth() + 1;
      const year = customS.getFullYear();
      const month = customS.getMonth() + 1;
      if (page === 'monitoring') {
        dispatchHandle('monitoring2');
      } else if (page === 'monitoringAsin') {
        dispatchHandle('monitoringAsin2');
      } else if (page === 'monitoringGroup') {
        dispatchHandle('monitoringGroup2');
      }
      dispatch(changeYearMonth2(`${year2}年${month2}月`));
      dispatch(changeYearMonth(`${year}年${month}月`));
    }
    dispatchHandle(page);
    if (page == 'monitoring') {
      dispatch(fetchMonitorPost());
    }

    setCalenderIsOpen(false);
    setDisplay('none');
  };

  return (
    <>
      <Stack direction={'row'} spacing={2}>
        <Stack>
          <DateRangePicker
            ranges={predefinedBottomRanges}
            showOneCalendar
            caretAs={ExpandMoreIcon}
            character=" ー "
            onChange={(value, e) => handleChange(value, e)}
            onSelect={(value, e) => handleSelect(value, e)}
            value={[customS, customE]}
            format="yyyy年MM月dd日"
            placement="bottomEnd"
            renderTitle={(customS) => {
              const year = customS.getFullYear();
              const month = customS.getMonth() + 1;
              return (
                <Typography>
                  {year}年{month}月
                </Typography>
              );
            }}
            placeholder="日付を選択してください"
            locale={{
              year: '年',
              month: '月',
              sunday: '日',
              monday: '月',
              tuesday: '火',
              wednesday: '水',
              thursday: '木',
              friday: '金',
              saturday: '土',
              ok: '選択する'
            }}
            container={() => containerRef.current}
            onClick={() => {
              setCalenderIsOpen(true);
              setDisplay('block');
            }}
            open={calenderIsOpen}
          />
        </Stack>
        {(page === 'monitoring' ||
          page === 'monitoringAsin' ||
          page === 'monitoringGroup') &&
        termCompareStatus ? (
          <Stack>
            {/* <Typography fontSize={'13px'}>比較</Typography> */}
            <DateRangePicker
              ranges={comparePreDifinedRanges}
              showOneCalendar
              caretAs={ExpandMoreIcon}
              character=" ー "
              onChange={(value, e) => handleChange2(value, e)}
              onSelect={(value, e) => handleSelect2(value, e)}
              value={[customS2, customE2]}
              format="yyyy年MM月dd日"
              renderTitle={(customS2) => {
                const year = customS2.getFullYear();
                const month = customS2.getMonth() + 1;
                return (
                  <Typography>
                    {year}年{month}月
                  </Typography>
                );
              }}
              placement="bottomEnd"
              placeholder="日付を選択してください"
              locale={{
                year: '年',
                month: '月',
                sunday: '日',
                monday: '月',
                tuesday: '火',
                wednesday: '水',
                thursday: '木',
                friday: '金',
                saturday: '土',
                ok: '選択する'
              }}
              container={() => containerRef2.current}
              onClick={() => {
                setCalenderIsOpen(true);
                setDisplay('block');
              }}
              open={calenderIsOpen}
            />
          </Stack>
        ) : (
          <></>
        )}
      </Stack>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'white',
          width: !termCompareStatus ? '265px' : '530px',
          maxHeight: '70vh',
          overflowY: 'scroll',
          overflowX: 'hidden',
          display: display,
          position: 'absolute',
          left: '-10px',
          boxShadow: '0 4px 4px rgba(0,0,0,.12), 0 0 10px rgba(0,0,0,.06)',
          borderRadius: 1
        }}
      >
        <Stack direction="row">
          <Box
            ref={containerRef}
            sx={{ height: '400px', width: '255px', position: 'relative' }}
          ></Box>
          <Box
            ref={containerRef2}
            sx={{ height: '400px', width: '255px', position: 'relative' }}
          ></Box>
        </Stack>
        <Divider sx={{ mt: 1 }} />
        {page === 'monitoring' ||
        page === 'monitoringAsin' ||
        page === 'monitoringGroup' ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              pt: 1
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                width: '255px'
              }}
            >
              <Typography sx={{ fontSize: '14px' }}>指定期間比較</Typography>
              <SwitchButton />
            </Box>
            {termCompareStatus && (
              <Typography sx={{ fontSize: '12px', width: '255px', px: 1 }}>
                ※どちらかの期間で年単位の期間を選択した場合、比較対象も年単位の期間しか選択できません
              </Typography>
            )}
            <Divider />
          </Box>
        ) : (
          <></>
        )}
        <Stack
          direction={'row'}
          spacing={3}
          sx={{ justifyContent: 'flex-end', my: '10px', mr: '25px' }}
        >
          <Button
            variant="outlined"
            sx={{
              borderColor: 'gray',
              color: 'gray'
            }}
            onClick={() => {
              dispatch(resetTempDate());
              dispatch(resetTempDate2());
              setCalenderIsOpen(false);
              setDisplay('none');
            }}
          >
            キャンセル
          </Button>
          <Button
            variant="contained"
            sx={{
              borderColor: '#718cc6',
              backgroundColor: '#718cc6',
              width: '90px'
            }}
            onClick={() => handleOkClick()}
          >
            選択する
          </Button>
        </Stack>
      </Box>
    </>
  );
});

export default KpiDatePicker;
