/* eslint-disable*/
import {
  Box,
  Chip,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { currentchannel } from 'feactures/channel/channelSlice';
import {
  performancereviewAmazonOwnImprovements,
  performancereviewRakutenOwnImprovements,
  performancereviewOwnDataLoading,
  performancereviewOrder,
  setPriorityOrder,
  fetchReviewReportChangeOrder,
  performancereviewOrderLoading
} from 'feactures/performancereview/performancereviewSlice';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const ImprovementSuggestions = () => {
  const isLoading = useSelector(performancereviewOwnDataLoading);
  const orderLoading = useSelector(performancereviewOrderLoading);
  const order = useSelector(performancereviewOrder);
  const dispatch = useDispatch();
  const handleChange = (e) => {
    dispatch(setPriorityOrder(e.target.value));
    dispatch(fetchReviewReportChangeOrder());
  };

  const amazonDataList = useSelector(performancereviewAmazonOwnImprovements);
  const rakutenDataList = useSelector(performancereviewRakutenOwnImprovements);
  const channel = useSelector(currentchannel);
  let dataList = [];
  if (channel == 1) {
    dataList = amazonDataList;
  } else if (channel == 2) {
    dataList = rakutenDataList;
  }

  const PriorityChip = (props) => {
    const { priority } = props;
    let label;
    let bgcolor;
    let fontColor;
    if (priority == 3) {
      label = 'High';
      bgcolor = '#FF0027';
      fontColor = '#ffffff';
    } else if (priority == 2) {
      label = 'Medium';
      bgcolor = '#2b2b2b';
      fontColor = '#ffffff';
    } else if (priority == 1) {
      label = 'Low';
      bgcolor = '#E6E6E6';
      fontColor = '#2b2b2b';
    }
    return (
      <Box bgcolor={bgcolor} py="2px" px="10px" borderRadius={5}>
        <Typography color={fontColor} fontWeight="bold" fontSize="11px">
          {label}
        </Typography>
      </Box>
    );
  };
  return (
    <Box
      width="49%"
      bgcolor="#FFFFFF"
      borderRadius={3}
      p={2}
      flexShrink={0}
      minHeight="200px"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          fontWeight={'bold'}
          fontSize={'18px'}
          color="rgba(113,140,198,1)"
          pl={1}
          sx={{
            fontSize: 'clamp(5px, 1.2vw, 18px)',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          ■あなたは今から何をするべきか
        </Typography>
        <Select
          value={order}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'Without label' }}
          size="small"
          sx={{
            margin: 0,
            padding: 0,
            width: 170,
            height: 30,
            backgroundColor: '#FFFFFF',
            fontSize: '14px'
          }}
          disabled={isLoading || orderLoading || dataList.length == 0}
        >
          <MenuItem value="high">優先度高い順</MenuItem>
          <MenuItem value="low">優先度低い順</MenuItem>
        </Select>
      </Box>
      {isLoading || orderLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="200px"
        >
          <CircularProgress />
        </Box>
      ) : dataList.length == 0 ? (
        <Box
          height="200px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography>改善項目がありません</Typography>
        </Box>
      ) : (
        dataList.map((data, index) => {
          return (
            <Box p={2} borderBottom={1} borderColor="#E0E0E0" key={index}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={1}
              >
                <Typography fontSize="13px" fontWeight="bold" color="black">
                  {data.title} - {data.subtitle}
                </Typography>
                <PriorityChip priority={data.priority} />
              </Box>
              <Typography color="text.secondary">{data.detail}</Typography>
            </Box>
          );
        })
      )}
    </Box>
  );
};

export default ImprovementSuggestions;
