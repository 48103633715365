/* eslint-disable */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index';
import { apiUrl } from 'App';

export const fetchDeleteUser = createAsyncThunk(
  'user/delete',
  async (arg, thunkAPI) => {
    const deleteUserList = thunkAPI.getState().deleteUsers.deleteUserList;
    // console.log('fetchdeleteuser', deleteUserList);
    const res = await client.post(`${apiUrl}logical_delete_user`, {
      delete_list: deleteUserList
    });
    return res.data;
  }
);

const deleteUserSlice = createSlice({
  name: 'deleteUser',
  initialState: {
    deleteUserList: []
  },
  reducers: {
    addDeleteUser(state, action) {
      // console.log('add delete user');
      state.deleteUserList = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDeleteUser.fulfilled, (state, action) => {
      state.deleteUserList = [];
    });
  }
});

export const { addDeleteUser } = deleteUserSlice.actions;
export const deleteUserList = (state) => state.deleteUsers.deleteUserList;
export default deleteUserSlice.reducer;
