/* eslint-disable*/
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import {
  Paper,
  CardContent,
  Box,
  Typography,
  CircularProgress,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core'; /* eslint-disable*/
import IconButton from '@mui/material/IconButton';
import {
  currentchannel,
  benchmark,
  switchBenchmark
} from 'feactures/channel/channelSlice';
import { checkGroup } from 'feactures/monitoring/groupSlice';
import {
  fetchMonitorPost,
  a_group_summary,
  r_group_summary,
  y_group_summary
} from 'feactures/monitoring/monitoringSlice';
import { fetchProcessKPIPost } from 'feactures/monitoring/processKPISlice';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    verticalAlign: 'center',
    maxHeight: '20px',
    paddingTop: '1px',
    paddingBottom: '1px',
    fontSize: '11px',
    fontWeight: '600',
    border: '0px',

    // backgroundColor: theme.palette.common.black,
    backgroundColor: '#F2F2F2',
    color: theme.palette.text.secondary
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.text.secondary,
    fontSize: '11px'
  }
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginRight: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

const GroupSummaryCollapse = () => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const channel = useSelector(currentchannel);
  const bench = useSelector(benchmark);

  const amazonGroupSummaries = useSelector(a_group_summary);
  const rakutenGroupSummaries = useSelector(r_group_summary);
  const yahooGroupSummaries = useSelector(y_group_summary);

  let groupSummaryInfo = [];
  if (channel == 1) {
    groupSummaryInfo = amazonGroupSummaries;
  } else if (channel == 2) {
    groupSummaryInfo = rakutenGroupSummaries;
  } else if (channel == 3) {
    groupSummaryInfo = yahooGroupSummaries;
  }

  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const groupClick = (group_id) => {
    dispatch(checkGroup({ checkGroupList: [group_id] }));
    window.scrollTo(0, 0);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          width: '100%',
          overflow: 'auto',
          alignItems: 'center',
          borderRadius: '0px',
          boxShadow: '0'
        }}
      >
        {/* <Collapse in={checked} collapsedSize={40}> */}
        <Table
          size="small"
          stickyHeader
          aria-label="purchases"
          sx={{ minWidth: 'max-content' }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">No</StyledTableCell>
              <StyledTableCell align="center">グループ</StyledTableCell>
              <StyledTableCell align="center">売上</StyledTableCell>
              <StyledTableCell align="center" component="th" scope="row">
                {bench == 1 ? '期間比較' : '競合比較'}
              </StyledTableCell>
              <StyledTableCell align="center" component="th" scope="row">
                閲覧数
              </StyledTableCell>
              <StyledTableCell align="center" component="th" scope="row">
                {bench == 1 ? '期間比較' : '競合比較'}
              </StyledTableCell>

              <StyledTableCell component="th" scope="row" align="center">
                CVR
              </StyledTableCell>
              <StyledTableCell align="center" component="th" scope="row">
                {bench == 1 ? '期間比較' : '競合比較'}
              </StyledTableCell>
              <StyledTableCell align="center" component="th" scope="row">
                販売単価
              </StyledTableCell>
              <StyledTableCell align="center" component="th" scope="row">
                {bench == 1 ? '期間比較' : '競合比較'}
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupSummaryInfo.length !== 0 ? (
              groupSummaryInfo.map((row, index) => {
                return (
                  <TableRow key={index} border={0}>
                    <StyledTableCell align="center" sx={{ fontWeight: 'bold' }}>
                      {index + 1}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      onClick={() => groupClick(row.group_id)}
                    >
                      {row.group_name}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      ￥{row.sales.toLocaleString()}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Box display="flex" flexWrap="wrap" alignItems="baseline">
                        <Typography
                          m={0.2}
                          p={0.2}
                          px={1}
                          variant="h6"
                          color={row.salesRate > 0 ? '#4BACC6' : '#D02C3A'}
                        >
                          {row.salesRate > 0 && '+'}
                          {row.salesRate}%
                        </Typography>
                        (前期間 ￥{row.preSales.toLocaleString()})
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.views.toLocaleString()}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Box display="flex" flexWrap="wrap" alignItems="baseline">
                        <Typography
                          m={0.2}
                          p={0.2}
                          px={1}
                          variant="h6"
                          color={row.viewsRate > 0 ? '#4BACC6' : '#D02C3A'}
                        >
                          {row.viewsRate > 0 && '+'}
                          {row.viewsRate}%
                        </Typography>
                        (前期間 {row.preViews.toLocaleString()})
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.cvr}%</StyledTableCell>
                    <StyledTableCell align="left">
                      <Box display="flex" flexWrap="wrap" alignItems="baseline">
                        <Typography
                          m={0.2}
                          p={0.2}
                          px={1}
                          variant="h6"
                          color={row.cvrRate > 0 ? '#4BACC6' : '#D02C3A'}
                        >
                          {row.cvrRate > 0 && '+'}
                          {row.cvrRate}%
                        </Typography>
                        (前期間 {row.preCvr}%)
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      ￥{row.price.toLocaleString()}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Box display="flex" flexWrap="wrap" alignItems="baseline">
                        <Typography
                          m={0.2}
                          p={0.2}
                          px={1}
                          variant="h6"
                          color={row.priceRate > 0 ? '#4BACC6' : '#D02C3A'}
                        >
                          {row.priceRate > 0 && '+'}
                          {row.priceRate}%
                        </Typography>
                        (前期間 ￥{row.prePrice.toLocaleString()})
                      </Box>
                    </StyledTableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow border={0} sx={{ color: 'text.secondary' }}>
                <StyledTableCell>-</StyledTableCell>
                <StyledTableCell align="center">-</StyledTableCell>
                <StyledTableCell align="center">-</StyledTableCell>
                <StyledTableCell align="center">-</StyledTableCell>
                <StyledTableCell>-</StyledTableCell>
                <StyledTableCell>-</StyledTableCell>
                <StyledTableCell>-</StyledTableCell>
                <StyledTableCell align="center">-</StyledTableCell>
                <StyledTableCell align="center">-</StyledTableCell>
                <StyledTableCell align="center">-</StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {/* </Collapse> */}
      </TableContainer>
      {/* <ExpandMore
        expand={checked}
        onClick={handleChange}
        aria-expanded={checked}
        aria-label="show more"
      >
        <ExpandMoreIcon />
      </ExpandMore> */}
    </>
  );
};
export default GroupSummaryCollapse;
