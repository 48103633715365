/* eslint-disable*/
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Card,
  Container,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Typography,
  Divider,
  Snackbar,
  Checkbox,
  Paper
} from '@material-ui/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  userinfo,
  fetchUserList,
  fetchChangeAuthority
} from 'feactures/user/userSlice';
import {
  addDeleteUser,
  deleteUserList,
  fetchDeleteUser
} from 'feactures/user/deleteUserSlice';

const StyledTableCell = (props) => (
  <TableCell
    sx={{
      textAlign: 'center',
      fontSize: '14px',
      height: '50px',
      width: 'fit-content'
    }}
  >
    {props.children}
  </TableCell>
);

const UserList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [err, setErr] = useState(false);
  const [popMessage, setPopMessage] = useState('');

  const user = useSelector(userinfo);
  const deleteList = useSelector(deleteUserList);
  const userList = user.userList;
  const exceptLoginUserList = userList.filter(
    (item) => item.userId !== user.userId
  );
  const [addDeleteUserList, setAddDeleteUserList] = useState([]);
  const display = user.authority === 1 ? 'block' : 'none'; // ログインしているユーザーが管理者であればblock,一般ユーザーであればnone

  const snackbarHandleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setErr(false);
  };

  const changeCheckbox = (login_id) => {
    // チェックされたユーザーが既に削除リストに存在するか確認する
    const checkDeleteList = addDeleteUserList.filter(
      (listItem) => listItem === login_id
    );
    if (checkDeleteList.length === 0) {
      const newList = addDeleteUserList.concat([login_id]);
      // console.log('new', newList);
      setAddDeleteUserList(newList);
      dispatch(addDeleteUser(newList));
    } else {
      const newList = addDeleteUserList.filter((item) => item !== login_id);
      // console.log('new', newList);
      setAddDeleteUserList(newList);
      dispatch(addDeleteUser(newList));
    }
  };

  const allUserSelect = (isChecked) => {
    if (isChecked) {
      const userIdList = userList.map((user) => user.userId);
      const exceptLoginUserIdList = userIdList.filter(
        (item) => item !== user.userId
      );
      const newList = Array.from(
        new Set([...addDeleteUserList, ...exceptLoginUserIdList])
      );
      setAddDeleteUserList(newList);
      dispatch(addDeleteUser(newList));
    } else {
      setAddDeleteUserList([]);
      dispatch(addDeleteUser([]));
    }
  };

  const deleteUsers = async () => {
    if (window.confirm('選択したユーザーを削除しますか？')) {
      await dispatch(fetchDeleteUser(addDeleteUserList))
        .then(() => {
          dispatch(fetchUserList(user.accountId));
          setPopMessage('選択したユーザーを削除しました');
          setErr(false);
          setSuccess(true);
        })
        .catch((e) => {
          console.error('ユーザーの削除に失敗しました', e);
          setPopMessage('選択したユーザーの削除に失敗しました');
          setSuccess(false);
          setErr(true);
        });
    }
  };

  const changeAuthority = async (user_id, authority) => {
    if (authority === 1) {
      if (
        window.confirm(
          `ログインID:${user_id}のユーザーを一般ユーザーに設定しますか？`
        )
      ) {
        await dispatch(
          fetchChangeAuthority({ user_id: user_id, authority: authority })
        )
          .then(() => {
            dispatch(fetchUserList(user.accountId));
            setPopMessage(
              '選択したユーザーの権限を"一般ユーザー"に変更しました'
            );
            setErr(false);
            setSuccess(true);
          })
          .catch((e) => {
            console.error('ユーザー権限の変更に失敗しました', e);
            setPopMessage('選択したユーザーの権限変更に失敗しました');
            setSuccess(false);
            setErr(true);
          });
      }
    } else {
      if (
        window.confirm(
          `ログインID:${user_id}のユーザーを管理者に設定しますか？`
        )
      ) {
        await dispatch(
          fetchChangeAuthority({ user_id: user_id, authority: authority })
        )
          .then(() => {
            dispatch(fetchUserList(user.accountId));
            setPopMessage('選択したユーザーの権限を"管理者"に変更しました');
            setErr(false);
            setSuccess(true);
          })
          .catch((e) => {
            console.error('ユーザー権限の変更に失敗しました', e);
            setPopMessage('選択したユーザーの権限変更に失敗しました');
            setSuccess(false);
            setErr(true);
          });
      }
    }
  };

  const modoru = () => {
    navigate(-1);
  };

  const navigateAddUserPage = () => {
    navigate('/app/sys/user/new');
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          py: 15
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 5
            }}
          >
            <Typography
              fontSize={22}
              sx={{
                mb: 0
              }}
            >
              ユーザー
            </Typography>
            <Box
              sx={{
                display: display
              }}
            >
              <Button aria-label="add new user" onClick={navigateAddUserPage}>
                <AddCircleIcon />
                <Typography fontSize={'14px'}>新しいユーザー</Typography>
              </Button>
              <Button
                aria-label="delete user"
                variant="outlined"
                color="detailButton"
                sx={{ mx: 3, fontSize: '14px' }}
                disabled={!deleteList || deleteList.length === 0}
                onClick={deleteUsers}
              >
                削除
              </Button>
            </Box>
          </Box>
          <Card>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'start',
                justifyContent: 'space-between'
              }}
            >
              <TableContainer
                component={Paper}
                sx={{ border: 1, borderRadius: 1, borderColor: 'lightgray' }}
              >
                <Table stickyHeader size="large" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell sx={{ display: display }}>
                        <Checkbox
                          onClick={(e) => allUserSelect(e.target.checked)}
                        />
                      </StyledTableCell>
                      <StyledTableCell>ログインID</StyledTableCell>
                      <StyledTableCell>姓</StyledTableCell>
                      <StyledTableCell>名</StyledTableCell>
                      <StyledTableCell>権限</StyledTableCell>
                      <StyledTableCell>作成日</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key="login_user">
                      <StyledTableCell
                        sx={{ display: 'none' }}
                      ></StyledTableCell>
                      <StyledTableCell>{user.userId}</StyledTableCell>
                      <StyledTableCell>{user.last_name}</StyledTableCell>
                      <StyledTableCell>{user.first_name}</StyledTableCell>
                      {user.authority == 1 ? (
                        <StyledTableCell>管理者</StyledTableCell>
                      ) : (
                        <StyledTableCell>一般ユーザー</StyledTableCell>
                      )}
                      <StyledTableCell>
                        {new Date(user.createdAt).toLocaleDateString()}
                      </StyledTableCell>
                    </TableRow>
                    {exceptLoginUserList.map((u, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0
                          }
                        }}
                      >
                        <StyledTableCell sx={{ display: display }}>
                          <Checkbox
                            onClick={(e) => changeCheckbox(u.userId)}
                            checked={
                              deleteList.filter((user) => user == u.userId)
                                .length > 0
                            }
                          />
                        </StyledTableCell>
                        <StyledTableCell>{u.userId}</StyledTableCell>
                        <StyledTableCell>{u.lastName}</StyledTableCell>
                        <StyledTableCell>{u.firstName}</StyledTableCell>

                        {u.authority === 1 ? (
                          <StyledTableCell>
                            {user.authority === 1 ? (
                              <Button
                                onClick={() =>
                                  changeAuthority(u.userId, u.authority)
                                }
                                sx={{
                                  fontSize: '14px',
                                  padding: 0,
                                  textAlign: 'center'
                                }}
                              >
                                管理者
                              </Button>
                            ) : (
                              '管理者'
                            )}
                          </StyledTableCell>
                        ) : (
                          <StyledTableCell>
                            {user.authority === 1 ? (
                              <Button
                                onClick={() =>
                                  changeAuthority(u.userId, u.authority)
                                }
                                sx={{
                                  fontSize: '14px',
                                  padding: 0,
                                  textAlign: 'center'
                                }}
                              >
                                一般ユーザー
                              </Button>
                            ) : (
                              '一般ユーザー'
                            )}
                          </StyledTableCell>
                        )}
                        <StyledTableCell>
                          {new Date(u.createdAt).toLocaleDateString()}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Card>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={success || err}
            severity={success ? 'success' : 'error'}
            autoHideDuration={4000}
            onClose={snackbarHandleClose}
            message={popMessage}
          />
          <br />
          <Button
            onClick={modoru}
            color="detailButton"
            sx={{ width: 'xs', fontSize: '14px' }}
          >
            ← 戻る
          </Button>
        </Container>
      </Box>
    </>
  );
};

export default UserList;
