/* eslint-disable*/
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useEffect, useState, createContext } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import { styled } from '@mui/material/styles';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  TextField,
  Link,
  Paper,
  Button,
  Card,
  CardContent,
  Container,
  InputBase,
  FormHelperText,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Typography,
  Snackbar,
  Divider,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';

import LoadingButton from '@mui/lab/LoadingButton';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import {
  colors,
  CircularProgress
} from '@material-ui/core'; /* eslint-disable*/
import { fetchChangePass } from 'feactures/user/userSlice';
import { userinfo, fetchChangeRmsKey } from 'feactures/user/userSlice';
import {
  rmsKeyInfo,
  fetchRmsAuthCheck,
  resetRmsStatus
} from 'feactures/user/rmsSlice';
import {
  yahooKeyInfo,
  fetchChangeYahooKey,
  fetchYahooKey
} from 'feactures/user/yahooKeySlice';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useUpdatedRef } from 'rsuite/esm/utils';

const SettingYahoo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const yahooKeyInfos = useSelector(yahooKeyInfo);
  const [success, setSuccess] = useState(false);
  const [err, setErrMessage] = useState(false); //登録できなかったときのエラー
  const infomation = '　上記５項目を入力後、Yahoo!ログインを行ってください。';

  const useStyles = makeStyles((theme) => ({
    transform: {
      fontSize: '8px',
      animation: `$show 0.3s both`,
      backgroundColor: 'rgba(255, 212, 0, 0.8)',
      borderRadius: '4px'
    },
    '@keyframes show': {
      '0%': {
        transform: 'translate(0,2em)',
        opacity: 0
      },
      '100%': {
        transform: 'translate(0,0)',
        opacity: 1
      }
    },
    table: {
      '& .MuiTableCell-root': {
        borderLeft: '1px solid rgba(224, 224, 224, 1)'
      }
    }
  }));
  const classes = useStyles();

  const snackbarHandleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setErrMessage(false);
  };

  const CustomInput = (props) => {
    const { value, item, onChange, name, touched, errors, onBlur, readOnly } =
      props;
    return (
      <Box display="flex" alignItems="center" mb={1}>
        <Box flex="1 1 auto" maxWidth="370px">
          <InputBase
            sx={{
              pl: 2,
              backgroundColor: colors.grey[300]
            }}
            fullWidth
            htmlFor="component-outlined"
            value={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            readOnly={readOnly}
          />
          {Boolean(touched[name]) && errors[name] ? (
            <FormHelperText error={true}>{errors[name]}</FormHelperText>
          ) : null}
        </Box>
      </Box>
    );
  };

  //認証チェック
  const yahooLoginClick = async (values) => {
    await dispatch(fetchChangeYahooKey(values))
      .then(unwrapResult)
      .then(() => {
        setSuccess(true);
        const url =
          'https://auth.login.yahoo.co.jp/yconnect/v2/authorization?response_type=code&client_id=dj00aiZpPTBqaU51VlZiWkxDeCZzPWNvbnN1bWVyc2VjcmV0Jng9YjU-&redirect_uri=https://www.oxcim.jp/login&scope=openid+profile&bail=1';
        window.open(url, '_blank');
      })
      .catch((err) => {
        console.log(err);
        setErrMessage(true);
      });
  };

  const onSubmit = async (values) => {
    await dispatch(fetchChangeYahooKey(values))
      .then(unwrapResult)
      .then(() => {
        // setTimeout(function () {
        window.scrollTo(0, 0);
        setSuccess(true);
        // }, 1000);
        dispatch(fetchYahooKey());
      })
      .catch((err) => {
        console.log(err);
        setErrMessage(true);
      });
  };

  const modoru = () => {
    navigate(-1);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          height: 'auto',
          minHeight: '100vh',
          width: '100%',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Container sx={{ width: 'auto', py: 3 }}>
          <Typography fontSize="16px" fontWeight="bold">
            Yahoo! API連携設定
          </Typography>
          <Typography color="textSecondary" gutterBottom variant="body2">
            Yahoo!JAPANのアカウントをお持ちの方は下記項目を入力してください
            <br />
            　*印は必須項目です
          </Typography>
          <Formik
            initialValues={{
              yahooId: yahooKeyInfos.yahooId ?? '',
              yahooPassword: yahooKeyInfos.yahooPassword ?? '',
              yahooStoreAccount: yahooKeyInfos.yahooStoreAccount ?? '',
              yahooPublicKey: yahooKeyInfos.yahooPublicKey ?? '',
              yahooPublicKeyVersion:
                String(yahooKeyInfos.yahooPublicKeyVersion) ?? '',
              licenseExpiryDate: yahooKeyInfos.licenseExpiryDate ?? '',
              orderReceivedStatus: yahooKeyInfos.orderReceivedStatus ?? ''
            }}
            validationSchema={Yup.object().shape({
              yahooId: Yup.string()
                .typeError('半角英数字で入力してください')
                .min(0, '0文字以上の英数字を入れてください')
                .required('必須項目です'),
              yahooPassword: Yup.string()
                .typeError('半角英数字で入力してください')
                .min(0, '0文字以上の英数字を入れてください')
                .required('必須項目です'),
              yahooPublicKeyVersion: Yup.number()
                .label('数値入力項目')
                .typeError('数値を入力してください。')
                .min(1, '0以上の数値を入力してください')
                .required('必須項目です')
                .integer('整数で入力してください'),
              yahooStoreAccount: Yup.string()
                .typeError('半角英数字で入力してください')
                .min(0, '0文字以上の英数字を入れてください')
                .required('必須項目です'),
              yahooPublicKey: Yup.string().required('必須項目です')
            })}
            // onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              setFieldValue,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              resetForm
            }) => (
              <form onSubmit={handleSubmit}>
                <Divider />
                <Card sx={{ mb: 3, minHeight: '250px' }}>
                  <CardContent sx={{ px: 5, pt: '30px' }}>
                    <Typography
                      paragraph={true}
                      fontWeight="bold"
                      fontSize="13px"
                      sx={{ textDecoration: 'underline', mb: '0px' }}
                    >
                      Yahoo | APIキー情報
                    </Typography>
                    <FormHelperText error sx={{ fontWeight: 'bold' }}>
                      ※APIの認証情報は、Oxcimでも定期的に更新が頂く必要がございます（約28日）
                      <br />
                      ※新規作成したYahoo!Japanアカウントは、パスワード認証でのログイン設定をお願いします
                      <br />
                    </FormHelperText>
                    <br />
                    ・Yahoo! JAPAN ID*
                    <FormHelperText error sx={{ fontWeight: 'bold' }}>
                      Oxcim用に作成頂いたものを設定してください
                    </FormHelperText>
                    <CustomInput
                      id={'yahooId'}
                      name={'yahooId'}
                      value={values.yahooId}
                      onChange={(e) => {
                        const targetString = e.target.value.replace(/\s+/g, '');
                        setFieldValue('yahooId', targetString);
                      }}
                      onBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      // readOnly={values.status == 1 ? false : true}
                    />
                    ・Yahoo! JAPAN PW*
                    <CustomInput
                      id={'yahooPassword'}
                      name={'yahooPassword'}
                      value={values.yahooPassword}
                      onChange={(e) => {
                        const targetString = e.target.value.replace(/\s+/g, '');
                        setFieldValue('yahooPassword', targetString);
                      }}
                      onBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                    />
                    <br />
                    ・ストアアカウント*
                    <CustomInput
                      id={'yahooStoreAccount'}
                      name={'yahooStoreAccount'}
                      value={values.yahooStoreAccount}
                      onChange={(e) => {
                        const targetString = e.target.value.replace(/\s+/g, '');
                        setFieldValue('yahooStoreAccount', targetString);
                      }}
                      onBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                    />
                    ・公開鍵*
                    {Boolean(touched['yahooPublicKey']) &&
                    errors['yahooPublicKey'] ? (
                      <FormHelperText error={true} component={'span'}>
                        {errors['yahooPublicKey']}
                      </FormHelperText>
                    ) : null}
                    <br />
                    <TextField
                      sx={{ mb: 1 }}
                      id={'yahooPublicKey'}
                      name={'yahooPublicKey'}
                      value={values.yahooPublicKey}
                      inputProps={{ style: { fontSize: 10 } }}
                      hiddenLabel
                      size="small"
                      fullWidth
                      multiline
                      rows={4}
                      onChange={handleChange}
                      // onChange={(e) => {
                      //   const targetString = e.target.value.replace(/\s+/g, '');
                      //   setFieldValue('yahooPublicKey', targetString);
                      // }}
                      placeholder="テキストをそのまま貼り付けてください"
                      onBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                    />
                    ・公開鍵のバージョン*
                    <Typography fontSize="8px" component={'span'}>
                      (整数で入力してください)
                    </Typography>
                    <CustomInput
                      id={'yahooPublicKeyVersion'}
                      name={'yahooPublicKeyVersion'}
                      value={values.yahooPublicKeyVersion}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                    />
                    <br />
                    <Divider />
                    <Box
                      sx={{
                        width: '480px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        my: 2
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <>
                          <img
                            src="\static\images\yahoo\yahoo_japan_icon_64.png"
                            style={{ width: '24px' }}
                          />
                          {!values.yahooId ||
                          !values.yahooPassword ||
                          !values.yahooStoreAccount ||
                          !values.yahooPublicKey ||
                          !values.yahooPublicKeyVersion ? (
                            <>
                              <a
                                tabindex="-1"
                                style={{ pointerEvents: 'none', color: 'grey' }}
                              >
                                Yahoo!JAPAN IDでログイン
                              </a>
                            </>
                          ) : (
                            <a
                              hover="always"
                              href="javascript:void(0)"
                              onClick={() => yahooLoginClick(values)}
                            >
                              Yahoo!JAPAN IDでログイン
                            </a>
                          )}
                          &nbsp; &nbsp;
                          <Typography
                            component="div"
                            className={classes.transform}
                            variant="caption"
                          >
                            {infomation}
                          </Typography>
                        </>
                      </Box>
                    </Box>
                    <TableContainer sx={{ my: 2, maxWidth: '400px' }}>
                      <Table size="small" className={classes.table}>
                        <TableHead
                          sx={{
                            backgroundColor: colors.grey[200]
                          }}
                        >
                          <TableRow>
                            <TableCell align="center" sx={{ minWidth: '80px' }}>
                              API
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ minWidth: '100px' }}
                            >
                              ステータス
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ minWidth: '100px' }}
                            >
                              有効期限
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell align="left">受注API</TableCell>
                            <TableCell align="center">
                              <Typography
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  px: '2px',
                                  height: '24px',
                                  borderRadius: '20px',
                                  color: 'white',
                                  backgroundColor:
                                    values.orderReceivedStatus == 0
                                      ? 'red'
                                      : 'green'
                                }}
                              >
                                {values.orderReceivedStatus == 0
                                  ? '未接続'
                                  : '接続中'}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              {values.licenseExpiryDate}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>

                <Box sx={{ py: 4, display: 'flex', justifyContent: 'center' }}>
                  <Button
                    sx={{ mr: 2, height: '36px' }}
                    type="reset"
                    variant="outlined"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      resetForm();
                      dispatch(resetRmsStatus());
                    }}
                  >
                    キャンセル
                  </Button>
                  <Button
                    sx={{ width: '150px', height: '36px' }}
                    color="primary"
                    size="midium"
                    // disabled={isSubmitting}
                    disabled={
                      !values.yahooId ||
                      !values.yahooPassword ||
                      !values.yahooStoreAccount ||
                      !values.yahooPublicKey ||
                      !values.yahooPublicKeyVersion
                    }
                    type="button"
                    variant="contained"
                    onClick={() => {
                      const err = Object.keys(errors);
                      if (err.length) {
                        const input = document.querySelector(
                          `input[name=${err[0]}]`
                        );

                        input.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                          inline: 'start'
                        });
                      } else {
                        onSubmit(values);
                      }
                    }}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={22} />
                    ) : (
                      '変更を保存する'
                    )}
                  </Button>

                  <br />
                </Box>
              </form>
            )}
          </Formik>
          <Typography color="textSecondary" variant="body1">
            <button onClick={() => modoru()}>←戻る</button>
          </Typography>
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            open={success || err}
            severity={success ? 'success' : 'error'}
            autoHideDuration={4000}
            onClose={snackbarHandleClose}
            message={err ? '変更できませんでした' : '利用情報を変更しました'}
          />
        </Container>
      </Box>
    </>
  );
};

export default SettingYahoo;
