/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import * as moment from 'moment/moment';
import 'moment/locale/ja';
import { apiUrl } from 'App.js';

//先月
export const lastmonth = moment()
  .add(-1, 'month')
  .startOf('month')
  .format('YYYY-MM');

// 先々月
export const twoMonthsAgo = moment()
  .add(-2, 'month')
  .startOf('month')
  .format('YYYY-MM');

export const resetKubunYearMonth = createAsyncThunk(
  'checkMarketData/post',
  async (arg, thunkAPI) => {
    const { channel } = thunkAPI.getState().currentchannel;
    // console.log('叩く', channel);
    const res = await client.post(`${apiUrl}check_market_data`, {
      channel
    });
    return res.data;
  }
);

export const sectionSlice = createSlice({
  name: 'section',
  initialState: {
    section: '', //1 自社 2 競合
    kubun: 'direct',
    yearMonth: lastmonth, // 選択月
    yearMonth2: twoMonthsAgo,
    amazonCurrentLastMonth: '', // マーケットテーブルのデータがある最新の月
    rakutenCurrentLastMonth: '',
    amazonMonthList: [], // 期間選択リスト
    rakutenMonthList: []
  },
  reducers: {
    registerMy: (state) => {
      state.section = 1;
    },
    registerOther: (state) => {
      state.section = 2;
    },
    changeKubun: (state, action) => {
      state.kubun = action.payload;
    },
    // resetKubunYearMonth: (state) => {
    //   return {
    //     ...state,
    //     kubun: 'direct',
    //     yearMonth: lastmonth
    //   };
    // },
    changeYearMonth: (state, action) => {
      return {
        ...state,
        yearMonth: action.payload
      };
    },
    changeYearMonth2: (state, action) => {
      return {
        ...state,
        yearMonth2: action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(resetKubunYearMonth.fulfilled, (state, action) => {
      // console.log('yearMonth更新', action.payload.last_month);
      return {
        ...state,
        kubun: 'direct',
        yearMonth: action.payload.year_month,
        amazonCurrentLastMonth: action.payload.amazon_last_month,
        rakutenCurrentLastMonth: action.payload.rakuten_last_month,
        amazonMonthList: action.payload.amazon_month_list,
        rakutenMonthList: action.payload.rakuten_month_list
      };
    });
    builder.addCase(resetKubunYearMonth.pending, (state, action) => {
      return { ...state };
    });
    builder.addCase(resetKubunYearMonth.rejected, (state, action) => {
      return { ...state };
    });
  }
});

export const {
  registerMy,
  registerOther,
  changeKubun,
  changeYearMonth,
  // resetKubunYearMonth,
  changeYearMonth2
} = sectionSlice.actions;
export const currentsection = (state) => state.currentsection.section;
export const currentkubun = (state) => state.currentsection.kubun;
export const currentmonth = (state) => state.currentsection.yearMonth;
export const comparemonth = (state) => state.currentsection.yearMonth2;
export const amazonCurrentlastmonth = (state) =>
  state.currentsection.amazonCurrentLastMonth;
export const rakutenCurrentlastmonth = (state) =>
  state.currentsection.rakutenCurrentLastMonth;
export const amazonMonthList = (state) => state.currentsection.amazonMonthList;
export const rakutenMonthList = (state) =>
  state.currentsection.rakutenMonthList;

export default sectionSlice.reducer;
