/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  errorMessage: null,
  errorStatus: null
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError: (state, action) => {
      return {
        ...state,
        errorMessage: action.payload.data,
        errorStatus: action.payload.status
      };
    },
    resetError: (state) => {
      return {
        ...state,
        errorMessage: null,
        errorStatus: null
      };
    }
  }
});
export const { setError, resetError } = errorSlice.actions;
export const errorResMessage = (state) => state.error.errorMessage;
export const errorResStatus = (state) => state.error.errorStatus;

export default errorSlice.reducer;
