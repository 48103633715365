/* eslint-disable */
import {
  Modal,
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  Card,
  CardContent,
  Stack,
  Snackbar,
  CircularProgress
} from '@material-ui/core';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import { makeStyles, styled } from '@material-ui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  changeGroupName,
  editGroup,
  editGroupPost,
  addGroupPost,
  groupStatus,
  groupError,
  fetchGroupDataList,
  groupSuccessMessage,
  resetSuccessMessage
} from 'feactures/monitoring/groupSlice';
import { fetchOverviewGroupData } from 'feactures/overview/overviewSlice';
import GroupEditField from 'components/atoms/field/GroupEditField';
import { errorResMessage, resetError } from 'feactures/errorSlice';

const useStyles = makeStyles({
  modalComponent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-43%) translateY(-50%)'
  }
});

const GroupEditModal = ({ open: open, setOpen: setOpen, isEdit: isEdit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const currentEditGroup = useSelector(editGroup);
  const isLoading = useSelector(groupStatus);
  const error = useSelector(groupError);
  const errorMessage = useSelector(errorResMessage);
  const [currentTab, setCurrentTab] = useState('basic');
  const [nameError, setNameError] = useState(null);
  const [listError, setListError] = useState(null);

  const handleClose = () => {
    setNameError(null);
    setListError(null);
    setOpen(false);
    dispatch(resetError());
  };

  // グループ登録・更新後のポップアップメッセージ
  const popMessage = useSelector(groupSuccessMessage);

  const snackbarHandleClose = (event, reason) => {
    dispatch(resetSuccessMessage());
    setOpen(false);
  };

  const groupName = currentEditGroup.name;

  const amazonAsinList = currentEditGroup.amazon;
  const rakutenAsinList = currentEditGroup.rakuten;
  const yahooAsinList = currentEditGroup.yahoo;

  const useEffectCustom = (func, dependencyList) => {
    const fisrtFlgRef = useRef(true);

    useEffect(() => {
      if (!fisrtFlgRef.current) {
        func();
      } else {
        fisrtFlgRef.current = false;
      }
    }, dependencyList);
  };

  useEffectCustom(() => {
    if (
      amazonAsinList.length + rakutenAsinList.length + yahooAsinList.length ==
      0
    ) {
      setListError('少なくとも一つ以上の商品コードを入力してください');
    } else {
      setListError(null);
    }
  }, [amazonAsinList, rakutenAsinList, yahooAsinList]);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleGroupNameChange = (e) => {
    dispatch(changeGroupName(e.target.value));
    if (e.target.value == '') {
      setNameError('グループ名は必須です。');
    } else {
      setNameError(null);
    }
  };

  const checkGroupName = () => {
    if (groupName == '') {
      setNameError('グループ名は必須です。');
    }
  };

  const handleRegister = () => {
    dispatch(addGroupPost());
  };

  const handleEdit = () => {
    dispatch(editGroupPost());
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Card
          className={classes.modalComponent}
          sx={{
            borderRadius: 6,
            pb: 0,
            my: 2,
            border: '1px solid #A6A6A6',
            height: '550px',
            width: '70%'
          }}
        >
          <CardContent>
            <Stack sx={{ justifyContent: 'flex-start', px: 1, pt: '4px' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Typography
                  fontSize={'16px'}
                  color={'#595959'}
                  fontWeight={'bold'}
                >
                  グループ名
                </Typography>
                <IconButton onClick={handleClose} sx={{ padding: '2px' }}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <TextField
                  size={'small'}
                  sx={{
                    fontSize: '16px',
                    width: '200px',
                    '& .css-1jeq7d8-MuiInputBase-root-MuiOutlinedInput-root': {
                      borderRadius: 2
                    }
                  }}
                  value={groupName}
                  onChange={handleGroupNameChange}
                  onBlur={checkGroupName}
                />
                <Typography ml={3} color="red">
                  {nameError}
                </Typography>
              </Box>
            </Stack>
            <Stack>
              <TabContext value={currentTab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    onChange={handleTabChange}
                    aria-label="group-register-tabs"
                    sx={{
                      color: 'lightgray',
                      '& .Mui-selected': {
                        color: '#718CC6'
                      },
                      '& .MuiTabs-indicator': {
                        bgcolor: '#718CC6'
                      }
                    }}
                  >
                    <Tab
                      label="リスト登録"
                      value={'basic'}
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '14px',
                        padding: '4px'
                      }}
                    />
                    {/* <Tab
                    label="検索"
                    value={'search'}
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '14px',
                      padding: '4px'
                    }}
                    disabled
                  />
                  <Tab
                    label="CSV一括登録"
                    value={'csv'}
                    sx={{
                      fontWeight: 'bold',
                      fontSize: '14px',
                      padding: '4px'
                    }}
                    disabled
                  /> */}
                  </TabList>
                </Box>
                <TabPanel value="basic">
                  <Stack direction={'row'} spacing={2}>
                    <GroupEditField channel={'Amazon'} />
                    <GroupEditField channel={'楽天'} />
                    <GroupEditField channel={'Yahoo'} />
                  </Stack>
                </TabPanel>
                {/* <TabPanel value="search">Item Two</TabPanel> */}
                {/* <TabPanel value="csv">Item Three</TabPanel> */}
              </TabContext>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
              >
                {error && (
                  <Typography color="red" mr={3}>
                    {errorMessage}
                  </Typography>
                )}
                {listError && (
                  <Typography color="red" mr={3}>
                    {listError}
                  </Typography>
                )}

                <Button
                  variant="contained"
                  color={'cancelButton'}
                  sx={{
                    borderRadius: 5,
                    mr: 3,
                    fontSize: '16px',
                    py: '4px'
                  }}
                  onClick={handleClose}
                >
                  キャンセルする
                </Button>
                {isEdit ? (
                  <Button
                    variant="contained"
                    color={'KPISelected'}
                    sx={{
                      borderRadius: 5,
                      mr: 3,
                      fontSize: '16px',
                      py: '4px'
                    }}
                    onClick={() => {
                      handleEdit();
                    }}
                    disabled={groupName == '' || isLoading}
                  >
                    更新する
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color={'KPISelected'}
                    sx={{
                      borderRadius: 5,
                      mr: 3,
                      fontSize: '16px',
                      py: '4px'
                    }}
                    onClick={() => {
                      handleRegister();
                    }}
                    disabled={
                      groupName == '' ||
                      amazonAsinList.length +
                        rakutenAsinList.length +
                        yahooAsinList.length ==
                        0 ||
                      isLoading
                    }
                  >
                    登録する
                  </Button>
                )}
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={popMessage.length > 0}
        severity={'success'}
        autoHideDuration={1000}
        onClose={snackbarHandleClose}
        message={popMessage}
      />
      <Modal open={open && isLoading}>
        <Card
          className={classes.modalComponent}
          sx={{
            borderRadius: 6,
            pb: 0,
            my: 2,
            border: '1px solid #A6A6A6',
            height: '550px',
            width: '70%',
            opacity: 0.1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress size={100} />
        </Card>
      </Modal>
    </>
  );
};

export default GroupEditModal;
