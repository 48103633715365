/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const fetchAsyncMarket = createAsyncThunk('market/get', async () => {
  const res = await client.get(`${apiUrl}market`);
  return res.data;
});

const fetchMarketSlice = createSlice({
  name: 'market',
  initialState: {
    graphdata: [0, 0, 0],
    salesFiguresData: [0, 0, 0],
    term13: []
    // selectMonth: 12
  },
  reducers: {
    changeTerm(state, action) {
      state.selectMonth = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAsyncMarket.fulfilled, (state, action) => {
      return {
        ...state,
        graphdata: [
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          // action.payload.amazon_graphdata,
          // action.payload.rakuten_graphdata,
          // action.payload.yahoo_graphdata
        ],
        salesFiguresData: [
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
          // action.payload.amazon_unit_sold,
          // action.payload.rakuten_unit_sold,
          // action.payload.yahoo_unit_sold
        ],
        term13: action.payload.term_13
      };
    });
  }
});

export const selectMarket = (state) => state.market.graphdata;
export const selectSalesFigures = (state) => state.market.salesFiguresData;
export const selectTerm13 = (state) => state.market.term13;
export const selectMonth = (state) => state.market.selectMonth;

export const { changeTerm } = fetchMarketSlice.actions;
export default fetchMarketSlice.reducer;
