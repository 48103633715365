/* eslint-disable*/
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  IconButton,
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  useTheme,
  FormGroup,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery
} from '@material-ui/core';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  fetchRegister,
  fetchUser,
  registerLogin
} from 'feactures/user/userSlice';
import { errorResMessage, errorResStatus } from 'feactures/errorSlice';
import { makeStyles } from '@material-ui/styles';
import TosDialog from 'components/atoms/modal/TosDialog';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: 4,
    right: 4
  }
}));

const StepRegister = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const errorMessage = useSelector(errorResMessage);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [readFlag, setReadFlag] = useState(false);

  // パスワードの表示・非表示処理
  const [showPassword, setShowPassword] = useState(false);
  const [showComfirmPassword, setShowComfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowComfirmPassword = () =>
    setShowComfirmPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownComfirmPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (values) => {
    setError(null);
    await dispatch(fetchRegister(values))
      .then(unwrapResult) //unwrapResult で,dispatch結果がfulfilledかrejectedかで条件分岐できる
      .then(() => {
        navigate('/register/complete', { replace: false });
        dispatch(
          registerLogin({
            userId: values.userId
          })
        );
        setTimeout(function () {
          navigate('/app/monitoring');
        }, 3000);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const handleClick = () => {
    setModalOpen(true);
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const passwordExp =
    /^(?=.*?[a-z])(?=.*?\d)(?=.*?[!-\/:-@[-`{-~])[!-~]{8,100}$/i;

  const RequiredBox = () => {
    return (
      <Box
        color="#FFFFFF"
        bgcolor="#F26B6B"
        borderRadius={5}
        fontSize="12px"
        fontWeight="bold"
        // px="8px"
        mb="1px"
        mr="8px"
        width="40px"
        height="23px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        必須
      </Box>
    );
  };
  const OptionBox = () => {
    return (
      <Box
        color="#ffffff"
        bgcolor="#669AF5"
        borderRadius={5}
        fontSize="12px"
        fontWeight="bold"
        // px="8px"
        mb="1px"
        mr="8px"
        width="40px"
        height="23px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        任意
      </Box>
    );
  };

  const steps = ['基本情報', '店舗情報', 'その他'];

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'background.default',
        pb: 15
      }}
    >
      <TosDialog
        open={modalOpen}
        setOpen={setModalOpen}
        setFlag={setReadFlag}
      />
      <Box sx={{ mt: 3, width: '80%', maxWidth: '1000px' }}>
        <Typography color="textPrimary" variant="h1">
          【新規開設】 Oxcim利用申し込みフォーム
        </Typography>
        <Typography color="textSecondary" variant="body2">
          <br />
          ・ご不明な点がございましたら下記までご連絡ください。
          <br />
          　introduction_oxcim@mg.opt.ne.jp
        </Typography>
      </Box>
      <Box
        position="sticky"
        top="0px"
        zIndex={1}
        height="auto"
        backgroundColor="background.default"
        py={3}
        width="80%"
        maxWidth="900px"
      >
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            // if (isStepOptional(index)) {
            //   labelProps.optional = (
            //     <Typography variant="caption">Optional</Typography>
            //   );
            // }
            // if (isStepSkipped(index)) {
            //   stepProps.completed = false;
            // }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>
                  <Typography fontSize="16px">{label}</Typography>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
      <Formik
        initialValues={{
          lastName: '',
          firstName: '',
          userId: '',
          confirmPassword: '',
          company: '',
          tel: '',
          mobileNumber: '',
          url: '',
          accountName: '', //ショップ名
          amazonSellerType: '',
          itemUrl: '',
          ecMall: [],
          ecMallOther: '',
          trialMall: [],
          otherTools: '',
          background: [],
          backgroundOther: '',
          source: [],
          sourceOther: '',
          policy: false,
          privacyPolicy: false,
          importantSubjects: false
        }}
        validationSchema={Yup.object().shape({
          company: Yup.string().max(255).required('会社名が未入力です。'),
          url: Yup.string().max(255).required('企業URLが未入力です。'),
          accountName: Yup.string()
            .max(255)
            .required('ショップ名が未入力です。'),
          lastName: Yup.string()
            .max(16, '16文字以内で入力してください。')
            .required('姓が未入力です。'),
          firstName: Yup.string()
            .max(16, '16文字以内で入力してください。')
            .required('名が未入力です。'),
          userId: Yup.string()
            .required('メールアドレスが未入力です。')
            .email('メールアドレスの形式が正しくありません。')
            .max(255),
          tel: Yup.string().matches(
            phoneRegExp,
            '電話番号の形式が正しくありません。'
          ),
          mobileNumber: Yup.string()
            .matches(phoneRegExp, '電話番号の形式が正しくありません。')
            .required('携帯電話番号が未入力です。'),
          itemUrl: Yup.string()
            .max(255, '255文字以内で入力してください。')
            .required('商品URLが未入力です。'),
          otherTools: Yup.string()
            .max(255)
            .required(
              'Oxcim以外でECモール分析に使用しているツールが未入力です。'
            ),
          background: Yup.array()
            .min(1, 'お申込み背景を選択してください')
            .of(Yup.string().required()),
          source: Yup.array()
            .min(1, '選択してください')
            .of(Yup.string().required()),
          ecMall: Yup.array()
            .min(1, '選択してください')
            .of(Yup.string().required()),
          trialMall: Yup.array()
            .min(1, '選択してください')
            .of(Yup.string().required()),
          // amazonPlan: Yup.string().required('選択してください。'),
          // rakutenPlan: Yup.string().required('選択してください。'),
          policy: Yup.boolean().oneOf([true], '利用規約の同意が必要です。'),
          privacyPolicy: Yup.boolean().oneOf(
            [true],
            'プライバシーポリシーの同意が必要です。'
          ),
          importantSubjects: Yup.boolean().oneOf(
            [true],
            '重要事項への同意が必要です。'
          )
        })}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          isValid,
          touched,
          values
        }) => (
          <Box width="70%" maxWidth="800px">
            <form onSubmit={handleSubmit}>
              {activeStep == 0 && (
                <Box width="100%" mb={1}>
                  <InputLabel>
                    <Box display="flex">
                      <RequiredBox />
                      <Typography fontSize="16px">名前</Typography>
                    </Box>
                  </InputLabel>
                  <Box display="flex" mb={1} justifyContent={'space-between'}>
                    {/* 苗字　lastName */}
                    <TextField
                      error={Boolean(touched.lastName && errors.lastName)}
                      fullWidth
                      helperText={touched.lastName && errors.lastName}
                      name="lastName"
                      placeholder="姓"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lastName}
                      variant="outlined"
                      size="small"
                      sx={{ backgroundColor: '#fff' }}
                    />
                    {/* 名前　firstName */}
                    <TextField
                      error={Boolean(touched.firstName && errors.firstName)}
                      fullWidth
                      helperText={touched.firstName && errors.firstName}
                      placeholder="名"
                      name="firstName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.firstName}
                      variant="outlined"
                      size="small"
                      sx={{ ml: 1, backgroundColor: '#fff' }}
                    />
                  </Box>
                  <InputLabel>
                    <Box display="flex">
                      <RequiredBox />
                      <Typography fontSize="16px">
                        会社メールアドレス
                      </Typography>
                    </Box>
                  </InputLabel>
                  <TextField
                    sx={{ mb: 1, backgroundColor: '#fff' }}
                    error={Boolean(touched.userId && errors.userId)}
                    fullWidth
                    helperText={touched.userId && errors.userId}
                    name="userId"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.userId}
                    variant="outlined"
                    size="small"
                    autoComplete="new-user-id"
                    inputProps={{ autoComplete: 'off' }}
                  />
                  <InputLabel>
                    <Box display="flex">
                      <OptionBox />
                      <Typography fontSize="16px">電話番号</Typography>
                    </Box>
                    <Typography fontSize={'12px'}>
                      ※ハイフンなしで数字のみ入力してください。
                    </Typography>
                  </InputLabel>
                  <TextField
                    error={Boolean(touched.tel && errors.tel)}
                    fullWidth
                    helperText={touched.tel && errors.tel}
                    name="tel"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.tel}
                    variant="outlined"
                    size="small"
                    sx={{ mb: 1, backgroundColor: '#fff' }}
                  />
                  <InputLabel>
                    <Box display="flex">
                      <RequiredBox />
                      <Typography fontSize="16px">携帯電話番号</Typography>
                    </Box>
                    <Typography fontSize={'12px'}>
                      ※ハイフンなしで数字のみ入力してください。
                    </Typography>
                  </InputLabel>
                  <TextField
                    error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                    fullWidth
                    helperText={touched.mobileNumber && errors.mobileNumber}
                    name="mobileNumber"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.mobileNumber}
                    variant="outlined"
                    size="small"
                    sx={{ backgroundColor: '#fff' }}
                  />
                </Box>
              )}
              {activeStep == 1 && (
                <Box width="100%" mb={1}>
                  <InputLabel>
                    <Box display="flex">
                      <RequiredBox />
                      <Typography fontSize="16px">会社名</Typography>
                    </Box>
                  </InputLabel>
                  <TextField
                    error={Boolean(touched.company && errors.company)}
                    fullWidth
                    helperText={touched.company && errors.company}
                    name="company"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.company}
                    variant="outlined"
                    size="small"
                    sx={{ mb: 1, backgroundColor: '#fff' }}
                  />
                  <InputLabel>
                    <Box display="flex">
                      <RequiredBox />
                      <Typography fontSize="16px">企業URL</Typography>
                    </Box>
                  </InputLabel>
                  <TextField
                    error={Boolean(touched.url && errors.url)}
                    fullWidth
                    helperText={touched.url && errors.url}
                    name="url"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.url}
                    variant="outlined"
                    size="small"
                    sx={{ mb: 1, backgroundColor: '#fff' }}
                  />
                  <InputLabel>
                    <Box display="flex">
                      <RequiredBox />
                      <Typography fontSize="16px">ショップ名</Typography>
                    </Box>
                  </InputLabel>
                  <Box display="flex" mb={1} justifyContent={'space-between'}>
                    <TextField
                      error={Boolean(touched.accountName && errors.accountName)}
                      helperText={touched.accountName && errors.accountName}
                      fullWidth
                      name="accountName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.accountName}
                      variant="outlined"
                      size="small"
                      sx={{ mb: 1, backgroundColor: '#fff' }}
                    />
                  </Box>
                  <InputLabel>
                    <Box display="flex">
                      <RequiredBox />
                      <Typography fontSize="16px">商品URL</Typography>
                    </Box>
                  </InputLabel>
                  <TextField
                    error={Boolean(touched.itemUrl && errors.itemUrl)}
                    fullWidth
                    helperText={touched.itemUrl && errors.itemUrl}
                    sx={{ mb: 1, backgroundColor: '#fff' }}
                    name="itemUrl"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.itemUrl}
                    variant="outlined"
                    size="small"
                  />
                  <InputLabel>
                    <Box display="flex" alignItems="center">
                      <RequiredBox />
                      <Typography fontSize="16px">出店ECモール</Typography>
                      {touched.ecMall && errors.ecMall && (
                        <FormHelperText sx={{ m: 0, ml: 1 }} error>
                          {errors.ecMall}
                        </FormHelperText>
                      )}
                    </Box>
                  </InputLabel>
                  <FormControl error={Boolean(touched.ecMall && errors.ecMall)}>
                    <FormGroup
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        width: '100%',
                        justifyContent: 'space-between'
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="1"
                            onChange={handleChange}
                            name="ecMall"
                            checked={values.ecMall.includes('1')}
                          />
                        }
                        label="Amazon"
                        sx={{
                          flexBasis: 'calc(33.33% - 10px)',
                          ml: '-11',
                          mr: '5px'
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="2"
                            onChange={handleChange}
                            name="ecMall"
                            checked={values.ecMall.includes('2')}
                          />
                        }
                        label="楽天"
                        sx={{
                          flexBasis: 'calc(33.33% - 10px)',
                          ml: '-11',
                          mr: '5px'
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="3"
                            onChange={handleChange}
                            name="ecMall"
                            checked={values.ecMall.includes('3')}
                          />
                        }
                        label="Yahoo"
                        sx={{
                          flexBasis: 'calc(33.33% - 10px)',
                          ml: '-11',
                          mr: '5px'
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="4"
                            onChange={handleChange}
                            name="ecMall"
                            checked={values.ecMall.includes('4')}
                          />
                        }
                        label="その他"
                      />
                    </FormGroup>
                  </FormControl>
                  {values.ecMall.includes('4') && (
                    <TextField
                      sx={{ mb: 1, backgroundColor: '#fff' }}
                      fullWidth
                      placeholder="その他の場合はこちらにご記入ください。"
                      name="ecMallOther"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.ecMallOther}
                      variant="outlined"
                      size="small"
                    />
                  )}
                  <InputLabel>
                    <Box display="flex" alignItems="center">
                      <RequiredBox />
                      <Typography fontSize="16px">
                        14日間無料トライアル 希望モール
                      </Typography>
                      {touched.trialMall && errors.trialMall && (
                        <FormHelperText sx={{ m: 0, ml: 1 }} error>
                          {errors.trialMall}
                        </FormHelperText>
                      )}
                    </Box>
                    <Typography fontSize={'12px'}>
                      ※トライアルにはデータの接続が必要です
                    </Typography>
                  </InputLabel>
                  <FormControl
                    error={Boolean(touched.trialMall && errors.trialMall)}
                    sx={{ mb: 1, width: '100%' }}
                  >
                    <FormGroup
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        gap: '12px'
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="1"
                            onChange={handleChange}
                            name="trialMall"
                            checked={values.trialMall.includes('1')}
                          />
                        }
                        label="Amazon"
                        sx={{
                          ml: '-11',
                          mr: '5px',
                          width: '93px'
                        }}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="2"
                            onChange={handleChange}
                            name="trialMall"
                            checked={values.trialMall.includes('2')}
                          />
                        }
                        label="楽天"
                        sx={{
                          ml: '-11'
                        }}
                      />
                    </FormGroup>
                  </FormControl>
                </Box>
              )}
              {activeStep == 2 && (
                <Box width="100%" mb={1}>
                  <Box display="flex" flexWrap="wrap">
                    <RequiredBox />
                    <Typography fontSize="16px">
                      Oxcim以外でECモール分析に使用しているツール
                    </Typography>
                  </Box>
                  <Typography fontSize={'12px'}>
                    ※無い場合「なし」とご入力ください。
                  </Typography>
                  <TextField
                    error={Boolean(touched.otherTools && errors.otherTools)}
                    fullWidth
                    helperText={touched.otherTools && errors.otherTools}
                    sx={{ mb: 1, backgroundColor: '#fff' }}
                    name="otherTools"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.otherTools}
                    variant="outlined"
                    size="small"
                  />
                  <Box mb={3} width="100%">
                    <InputLabel>
                      <Box display="flex" alignItems="center" flexWrap="wrap">
                        <RequiredBox />
                        <Typography fontSize="16px">お申込み背景</Typography>
                        {touched.background && errors.background && (
                          <FormHelperText sx={{ m: 0, ml: 1 }} error>
                            {errors.background}
                          </FormHelperText>
                        )}
                      </Box>
                    </InputLabel>
                    <FormControl component="fieldset" sx={{ width: '100%' }}>
                      <FormGroup
                        sx={{
                          display: 'flex',
                          flexDirection: isMobile ? 'column' : 'row',
                          flexWrap: isMobile ? 'nowrap' : 'wrap',
                          width: '100%'
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="background"
                              value="1"
                              onChange={handleChange}
                              checked={values.background.includes('1')}
                            />
                          }
                          label="ECモールの市場調査・競合分析がしたい"
                          sx={{
                            flexBasis: isMobile ? '100%' : 'calc(50% - 5px)',
                            mr: isMobile ? '0px' : '5px'
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="background"
                              value="2"
                              onChange={handleChange}
                              checked={values.background.includes('2')}
                            />
                          }
                          label="複数モールの売り上げデータを一元管理したい"
                          sx={{
                            flexBasis: isMobile ? '100%' : 'calc(50% -5px)',
                            mr: isMobile ? '0px' : '5px'
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="background"
                              value="3"
                              onChange={handleChange}
                              checked={values.background.includes('3')}
                            />
                          }
                          label="運用をアウトソースしたい"
                          sx={{
                            flexBasis: isMobile ? '100%' : 'calc(50% - 5px)',
                            mr: isMobile ? '0px' : '5px'
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="background"
                              value="4"
                              onChange={handleChange}
                              checked={values.background.includes('4')}
                            />
                          }
                          label="売り上げ拡大のコンサルティングをしてほしい"
                          sx={{
                            flexBasis: isMobile ? '100%' : 'calc(50% - 5px)',
                            mr: isMobile ? '0px' : '5px'
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="background"
                              value="5"
                              onChange={handleChange}
                              checked={values.background.includes('5')}
                            />
                          }
                          label="その他"
                        />
                      </FormGroup>
                    </FormControl>
                    {values.background.includes('5') && (
                      <TextField
                        name="backgroundOther"
                        placeholder="その他の内容を記入してください"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.backgroundOther}
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ backgroundColor: '#fff' }}
                      />
                    )}
                    <InputLabel sx={{ mt: 1 }}>
                      <Box display="flex" alignItems="center" flexWrap="wrap">
                        <RequiredBox />
                        <Typography fontSize="16px">
                          Oxcimを何で知りましたか？
                        </Typography>
                        {touched.source && errors.source && (
                          <FormHelperText sx={{ m: 0, ml: 1 }} error>
                            {errors.source}
                          </FormHelperText>
                        )}
                      </Box>
                    </InputLabel>
                    <FormControl component="fieldset" sx={{ width: '100%' }}>
                      <FormGroup
                        sx={{
                          display: 'flex',
                          flexDirection: isMobile ? 'column' : 'row',
                          flexWrap: isMobile ? 'nowrap' : 'wrap',
                          width: '100%'
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="source"
                              value="1"
                              onChange={handleChange}
                              checked={values.source.includes('1')}
                            />
                          }
                          label="Web検索"
                          sx={{
                            flexBasis: isMobile ? '100%' : 'calc(50% - 5px)',
                            mr: '5px'
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="source"
                              value="2"
                              onChange={handleChange}
                              checked={values.source.includes('2')}
                            />
                          }
                          label="SNS"
                          sx={{
                            flexBasis: isMobile ? '100%' : 'calc(50% - 5px)',
                            mr: isMobile ? '0px' : '5px'
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="source"
                              value="3"
                              onChange={handleChange}
                              checked={values.source.includes('3')}
                            />
                          }
                          label="Webニュース、プレスリリース"
                          sx={{
                            flexBasis: isMobile ? '100%' : 'calc(50% - 5px)',
                            mr: isMobile ? '0px' : '5px'
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="source"
                              value="4"
                              onChange={handleChange}
                              checked={values.source.includes('4')}
                            />
                          }
                          label="外部メディア（記事掲載等）"
                          sx={{
                            flexBasis: isMobile ? '100%' : 'calc(50% - 5px)',
                            mr: isMobile ? '0px' : '5px'
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="source"
                              value="5"
                              onChange={handleChange}
                              checked={values.source.includes('5')}
                            />
                          }
                          label="知人からの紹介"
                          sx={{
                            flexBasis: isMobile ? '100%' : '100%',
                            mr: isMobile ? '0px' : '5px'
                          }}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="source"
                              value="6"
                              onChange={handleChange}
                              checked={values.source.includes('6')}
                            />
                          }
                          label="その他"
                        />
                      </FormGroup>
                    </FormControl>
                    {values.source.includes('6') && (
                      <TextField
                        name="sourceOther"
                        placeholder="その他の内容を記入してください"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.sourceOther}
                        variant="outlined"
                        size="small"
                        fullWidth
                        sx={{ backgroundColor: '#fff' }}
                      />
                    )}
                  </Box>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      ml: -1
                    }}
                  >
                    <Checkbox
                      disabled={!readFlag}
                      checked={values.policy}
                      name="policy"
                      onChange={handleChange}
                      sx={{ py: 1 }}
                    />
                    <Typography color="textSecondary" variant="body1">
                      <Button
                        onClick={handleClick}
                        sx={{
                          position: 'relative',
                          p: 0,
                          m: 0,
                          minWidth: '48px',
                          maxHeight: '18px',
                          textTransform: 'none', // textTransform: 'none' to keep the text case as is
                          backgroundColor: 'transparent',
                          color: '#1574D1', // Use a lighter color initially
                          '&:hover': {
                            color: '#1A68C7', // Darker color on hover
                            backgroundColor: 'transparent',
                            '&::before': {
                              borderColor: '#1A68C7' // underline color on hover
                            }
                          },
                          '&::before': {
                            content: '""',
                            position: 'absolute',
                            left: 0,
                            bottom: 2,
                            width: '100%',
                            height: '1px', // Adjust the height of the underline
                            backgroundColor: '#9DC5ED', // underline color
                            borderBottom: '1px solid #9DC5ED',
                            pointerEvents: 'none' // Ensure the underline does not interfere with pointer events
                          }
                        }}
                      >
                        利用規約
                      </Button>
                      に同意します。
                    </Typography>
                    {!readFlag && (
                      <FormHelperText error>
                        利用規約を開いてご確認ください。
                      </FormHelperText>
                    )}
                    {Boolean(touched.policy && errors.policy) && (
                      <FormHelperText error>{errors.policy}</FormHelperText>
                    )}
                  </Box>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      ml: -1
                    }}
                  >
                    <Checkbox
                      checked={values.privacyPolicy}
                      name="privacyPolicy"
                      onChange={handleChange}
                      sx={{ py: 0 }}
                    />
                    <Typography color="textSecondary" variant="body1">
                      <Link
                        color="primary"
                        target="_blank"
                        href="https://www.opt.ne.jp/privacy/"
                        underline="always"
                        variant="h6"
                      >
                        プライバシーポリシー
                      </Link>
                      に同意します。
                    </Typography>
                    {Boolean(touched.privacyPolicy && errors.privacyPolicy) && (
                      <FormHelperText error>
                        {errors.privacyPolicy}
                      </FormHelperText>
                    )}
                  </Box>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      ml: -1
                    }}
                  >
                    <Checkbox
                      checked={values.importantSubjects}
                      name="importantSubjects"
                      onChange={handleChange}
                      sx={{ py: 1 }}
                    />
                    <Typography color="textSecondary" variant="body1">
                      下記の重要事項に同意します。
                    </Typography>
                    {Boolean(
                      touched.importantSubjects && errors.importantSubjects
                    ) && (
                      <FormHelperText error>
                        {errors.importantSubjects}
                      </FormHelperText>
                    )}
                  </Box>
                  <Box
                    sx={{
                      border: 1,
                      p: 1,
                      backgroundColor: '#fff'
                    }}
                    height="100%"
                    width="100%"
                  >
                    <Typography variant="body2" gutterBottom>
                      ・利用者は、当社が付与したアカウントのID及び自らが設定したパスワードを第三者に開示、貸与又は共有しないとともに、第三者に漏洩することのないよう厳重に管理するものとします。
                      <br />
                      ・Oxcimを利用する上で必要な貴社ECモールのアカウントデータを収集いたします。
                      <br />
                      ・販売データについては、本サービスの提供に必要な範囲において利用するほか、OPTグループ内でも利用する可能性があります。いずれの場合も利用者が特定又は推測できないデータとして加工を施した上で利用いたします。
                      <br />
                      ・一部のデータについては、弊社独自集計により実際の数値と乖離がある可能性がございます。
                      <br />
                      ・事前に通知することなく、サービスの変更/追加/中断の可能性がございます。
                      <br />
                      ・ご利用企業様として会社ロゴを使用させていただく可能性がございます。
                      <br />
                    </Typography>
                  </Box>
                  <Box
                    border={2}
                    borderColor="red"
                    mt={2}
                    mx="auto"
                    p={1}
                    borderRadius={3}
                    bgcolor="#FFFFFF"
                    width="fit-content"
                  >
                    <Typography fontWeight="bold">
                      お申込み完了後、「【Oxcim】アカウント申込み完了のお知らせ」という件名の
                      メールが送付されます。
                      <br />
                      ※メールが届いていない場合は、一度迷惑メールフォルダをご確認頂きそれでも届いていない場合は、
                      <br />
                      　お手数ですが再度登録をお願いします。
                    </Typography>
                  </Box>
                </Box>
              )}
              <React.Fragment>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2,
                    pt: 2
                  }}
                >
                  <Button
                    color="inherit"
                    variant="contained"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    size="large"
                  >
                    戻る
                  </Button>
                  <Box />
                  {/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                スキップ
              </Button>
            )} */}
                  {activeStep === steps.length - 1 ? (
                    <Box onSubmit={handleSubmit}>
                      <Button
                        color="neutral"
                        variant="contained"
                        disabled={
                          isSubmitting ||
                          !isValid ||
                          !Object.keys(touched).length
                        }
                        type="submit"
                        size="large"
                      >
                        申し込む
                      </Button>
                    </Box>
                  ) : (
                    <Button
                      onClick={handleNext}
                      color="primary"
                      variant="contained"
                      size="large"
                      disabled={
                        activeStep === 0
                          ? values.lastName === '' ||
                            values.firstName === '' ||
                            values.userId === '' ||
                            values.mobileNumber === '' ||
                            Boolean(errors.lastName) ||
                            Boolean(errors.firstName) ||
                            Boolean(errors.userId) ||
                            Boolean(errors.tel) ||
                            Boolean(errors.mobileNumber)
                          : activeStep === 1
                          ? values.company === '' ||
                            values.url === '' ||
                            values.account_name === '' ||
                            values.itemUrl === '' ||
                            values.ecMall.length === 0 ||
                            Boolean(errors.company) ||
                            Boolean(errors.url) ||
                            Boolean(errors.account_name) ||
                            Boolean(errors.itemUrl) ||
                            Boolean(errors.ecMall)
                          : !Object.keys(touched).length
                      }
                    >
                      次へ
                    </Button>
                  )}
                </Box>
              </React.Fragment>
              {error && (
                <Alert severity="error" sx={{ mt: 3 }}>
                  登録済みのアカウントがあります
                </Alert>
              )}
            </form>
          </Box>
        )}
      </Formik>
    </Box>
  );
};

export default StepRegister;
