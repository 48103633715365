/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';
export const fetchCategoryBrandShareGraphData = createAsyncThunk(
  'categorybrandshare/post',
  async (_, thunkAPI) => {
    const {
      a_cate1,
      a_cate2,
      a_cate3,
      a_cate4,
      a_cate5,
      a_cate6,
      r_cate1,
      r_cate2,
      r_cate3,
      r_cate4,
      r_cate5,
      y_cate1,
      y_cate2,
      y_cate3,
      y_cate4
    } = thunkAPI.getState().categorygraph;
    const { otherSelectedBrand } = thunkAPI.getState().brand;
    const { brandList } = thunkAPI.getState().brand;
    const { yearMonth } = thunkAPI.getState().currentsection;
    const { channel, benchmark } = thunkAPI.getState().currentchannel;
    // let brand_id = null;
    // let brand_id_arr = null;
    // if (channel == 1) {
    //   brand_id_arr = brandList.amazon.filter(
    //     (brand) => brand.brand_name == otherSelectedBrand.amazon.brand
    //   );
    // } else if (channel == 2) {
    //   brand_id_arr = brandList.rakuten.filter(
    //     (brand) => brand.brand_name == otherSelectedBrand.rakuten.brand
    //   );
    // } else if (channel == 3) {
    //   brand_id_arr = brandList.yahoo.filter(
    //     (brand) => brand.brand_name == otherSelectedBrand.yahoo.brand
    //   );
    // }
    // if (brand_id_arr.length != 0) {
    //   brand_id = brand_id_arr[0].brand_id;
    // }

    let amazon_brand_id = null;
    let rakuten_brand_id = null;
    let yahoo_brand_id = null;
    if (channel === 1 && otherSelectedBrand.amazon !== '') {
      amazon_brand_id = otherSelectedBrand.amazon.id;
    } else if (channel === 2 && otherSelectedBrand.rakuten !== '') {
      rakuten_brand_id = otherSelectedBrand.rakuten.id;
    } else if (channel === 3 && otherSelectedBrand.yahoo !== '') {
      yahoo_brand_id = otherSelectedBrand.yahoo.id;
    }
    const res = await client.post(`${apiUrl}brand/sub_category_sales`, {
      amazon_category: [a_cate1, a_cate2, a_cate3, a_cate4, a_cate5, a_cate6],
      rakuten_category: [r_cate1, r_cate2, r_cate3, r_cate4, r_cate5],
      yahoo_category: [y_cate1, y_cate2, y_cate3, y_cate4],
      amazon_brand: amazon_brand_id,
      rakuten_brand: rakuten_brand_id,
      yahoo_brand: yahoo_brand_id,
      term: yearMonth,
      channel: channel
    });
    return res.data;
  }
);
export const fetchCategoryOwnBrandShareGraphData = createAsyncThunk(
  'owncategoryshare/post',
  async (_, thunkAPI) => {
    const {
      a_cate1,
      a_cate2,
      a_cate3,
      a_cate4,
      a_cate5,
      a_cate6,
      r_cate1,
      r_cate2,
      r_cate3,
      r_cate4,
      r_cate5,
      y_cate1,
      y_cate2,
      y_cate3,
      y_cate4
    } = thunkAPI.getState().categorygraph;
    const { ownSelectedBrand } = thunkAPI.getState().brand;
    const { yearMonth } = thunkAPI.getState().currentsection;
    const { channel } = thunkAPI.getState().currentchannel;
    const { accountId } = thunkAPI.getState().user;
    let amazon_brand = null;
    let rakuten_brand_id = null;
    let yahoo_brand_id = null;

    if (channel === 1 && ownSelectedBrand.amazon !== '') {
      amazon_brand = ownSelectedBrand.amazon.brand;
    } else if (channel === 2 && ownSelectedBrand.rakuten !== '') {
      rakuten_brand_id = ownSelectedBrand.rakuten.id;
    } else if (channel === 3 && ownSelectedBrand.yahoo !== '') {
      yahoo_brand_id = ownSelectedBrand.yahoo.id;
    }
    const res = await client.post(`${apiUrl}measure/get_own_itemshare`, {
      amazon_category: [a_cate1, a_cate2, a_cate3, a_cate4, a_cate5, a_cate6],
      rakuten_category: [r_cate1, r_cate2, r_cate3, r_cate4, r_cate5],
      yahoo_category: [y_cate1, y_cate2, y_cate3, y_cate4],
      amazon_brand: amazon_brand,
      rakuten_brand: rakuten_brand_id,
      yahoo_brand: yahoo_brand_id,
      term: yearMonth,
      channel: channel,
      account_id: accountId
    });
    return res.data;
  }
);

const fetchCategoryBrandShareSlice = createSlice({
  name: 'categoryshare',
  initialState: {
    own: {
      amazon_graphdata: {
        graphdata: {
          percentage: [],
          sales: [],
          label: [],
          unit: [],
          name: [],
          price: []
        }
      },
      shopCode: '',
      rakuten_graphdata: {
        graphdata: {
          percentage: [],
          sales: [],
          label: [],
          unit: [],
          name: [],
          price: []
        }
      },
      yahoo_graphdata: {
        graphdata: {
          percentage: [],
          sales: [],
          label: [],
          unit: [],
          name: [],
          price: []
        }
      },
      isLoading: false
    },
    other: {
      amazon_graphdata: {
        graphdata: {
          percentage: [],
          sales: [],
          label: [],
          unit: [],
          name: [],
          price: []
        }
      },
      rakuten_graphdata: {
        graphdata: {
          percentage: [],
          sales: [],
          label: [],
          unit: [],
          name: [],
          price: []
        }
      },
      yahoo_graphdata: {
        graphdata: {
          percentage: [],
          sales: [],
          label: [],
          unit: [],
          name: [],
          price: []
        }
      },
      isLoading: false
    }
  },
  reducers: {
    resetAgraph(state, action) {
      state.own.amazon_graphdata = {
        graphdata: {
          percentage: [],
          sales: [],
          label: [],
          unit: [],
          name: [],
          price: []
        }
      };
      state.other.amazon_graphdata = {
        graphdata: {
          percentage: [],
          sales: [],
          label: [],
          unit: [],
          name: [],
          price: []
        }
      };
    },
    resetRgraph(state, action) {
      state.own.rakuten_graphdata = {
        graphdata: {
          percentage: [],
          sales: [],
          label: [],
          unit: [],
          name: [],
          price: []
        }
      };
      state.other.rakuten_graphdata = {
        graphdata: {
          percentage: [],
          sales: [],
          label: [],
          unit: [],
          name: [],
          price: []
        }
      };
    },
    resetYgraph(state, action) {
      state.own.yahoo_graphdata = {
        graphdata: {
          percentage: [],
          sales: [],
          label: [],
          unit: [],
          name: [],
          price: []
        }
      };
      state.other.yahoo_graphdata = {
        graphdata: {
          percentage: [],
          sales: [],
          label: [],
          unit: [],
          name: [],
          price: []
        }
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchCategoryBrandShareGraphData.fulfilled,
      (state, action) => {
        if (action.payload.channel == 1) {
          state.other.amazon_graphdata['graphdata'] = action.payload.data;
          state.other.isLoading = false;
        } else if (action.payload.channel == 2) {
          state.other.rakuten_graphdata['graphdata'] = action.payload.data;
          state.other.isLoading = false;
        } else if (action.payload.channel == 3) {
          state.other.yahoo_graphdata['graphdata'] = action.payload.data;
          state.other.isLoading = false;
        }
      }
    );
    builder.addCase(
      fetchCategoryBrandShareGraphData.pending,
      (state, action) => {
        return {
          other: {
            amazon_graphdata: state.other.amazon_graphdata,
            rakuten_graphdata: state.other.rakuten_graphdata,
            yahoo_graphdata: state.other.yahoo_graphdata,
            isLoading: true
          },
          own: {
            amazon_graphdata: state.own.amazon_graphdata,
            rakuten_graphdata: state.own.rakuten_graphdata,
            yahoo_graphdata: state.own.yahoo_graphdata,
            isLoading: state.own.isLoading
          }
          // error: false
        };
      }
    );
    builder.addCase(
      fetchCategoryBrandShareGraphData.rejected,
      (state, action) => {
        return {
          other: {
            amazon_graphdata: state.other.amazon_graphdata,
            rakuten_graphdata: state.other.rakuten_graphdata,
            yahoo_graphdata: state.other.yahoo_graphdata,
            isLoading: false
          },
          own: {
            amazon_graphdata: state.own.amazon_graphdata,
            rakuten_graphdata: state.own.rakuten_graphdata,
            yahoo_graphdata: state.own.yahoo_graphdata,
            isLoading: state.own.isLoading
          }
          // error: false
        };
      }
    );
    builder.addCase(
      fetchCategoryOwnBrandShareGraphData.fulfilled,
      (state, action) => {
        if (action.payload.channel == 1) {
          state.own.amazon_graphdata['graphdata'] = action.payload.data;
          state.own.shopCode = '';
          state.own.isLoading = false;
        } else if (action.payload.channel == 2) {
          state.own.rakuten_graphdata['graphdata'] = action.payload.data;
          state.own.shopCode = action.payload.shop_code;
          state.own.isLoading = false;
        } else if (action.payload.channel == 3) {
          state.own.yahoo_graphdata['graphdata'] = action.payload.data;
          state.own.shopCode = '';
          state.own.isLoading = false;
        }
      }
    );
    builder.addCase(
      fetchCategoryOwnBrandShareGraphData.pending,
      (state, action) => {
        state.own.isLoading = true;
      }
    );
    builder.addCase(
      fetchCategoryOwnBrandShareGraphData.rejected,
      (state, action) => {
        state.own.isLoading = false;
      }
    );
  }
});

export const selectAmazonCategoryShareGraph = (state) =>
  state.categoryBrandShare.other.amazon_graphdata;
export const selectRakutenCategoryShareGraph = (state) =>
  state.categoryBrandShare.other.rakuten_graphdata;
export const selectYahooCategoryShareGraph = (state) =>
  state.categoryBrandShare.other.yahoo_graphdata;
export const ownAmazonCategoryShareGraph = (state) =>
  state.categoryBrandShare.own.amazon_graphdata;
export const ownRakutenCategoryShareGraph = (state) =>
  state.categoryBrandShare.own.rakuten_graphdata;
export const ownYahooCategoryShareGraph = (state) =>
  state.categoryBrandShare.own.yahoo_graphdata;
export const rakutenShopCode = (state) => state.categoryBrandShare.own.shopCode;

export const categoryOtherBrandShareStatus = (state) =>
  state.categoryBrandShare.other.isLoading;
export const categoryOwnBrandgraphShareStatus = (state) =>
  state.categoryBrandShare.own.isLoading;

export const { resetAgraph, resetRgraph, resetYgraph } =
  fetchCategoryBrandShareSlice.actions;
export default fetchCategoryBrandShareSlice.reducer;
