/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const fetchAsyncTrends = createAsyncThunk(
  'trends/get',
  async (arg, thunkAPI) => {
    const { graphTitle, asin, keywordId } = arg;
    const { term, start, end } = await thunkAPI.getState().date;
    const { AmazonTrackingData } = thunkAPI.getState().tracking;
    const { channel, channelName } = thunkAPI.getState().currentchannel;
    const { accountId, accountName, userId, isLoggedIn, userName } =
      thunkAPI.getState().user;
    const { checkGroupList } = thunkAPI.getState().group;

    const res = await client.post(`${apiUrl}tracking_trend`, {
      start: start,
      end: end,
      term: term,
      channel: channel,
      channel_name: channelName,
      graph_name: graphTitle,
      asin_code: asin,
      keyword_id: keywordId,
      account_id: accountId,
      group_list: checkGroupList
    });
    return res.data;
  }
);

export const trendsDelete = createAsyncThunk(
  'trends/delete/post',
  async (arg, thunkAPI) => {
    const { graphTitle, keywordId } = arg;
    // console.log(graphTitle);
    const { alignment, startDate, endDate } = thunkAPI.getState().asinDate;
    const { AmazonTrackingData } = thunkAPI.getState().tracking;
    const { channel, channelName } = thunkAPI.getState().currentchannel;
    const { accountId, accountName, userId, isLoggedIn, userName } =
      thunkAPI.getState().user;

    const res = await client.post(`${apiUrl}tracking_words_logical_delete`, {
      start: startDate,
      end: endDate,
      term: alignment,
      channel: channel,
      channel_name: channelName,
      graph_name: graphTitle,
      keyword_id: keywordId,
      account_id: accountId
    });
    return res.data;
  }
);

const fetchTrendsSlice = createSlice({
  name: 'trends',
  initialState: {
    keywords: [
      // {
      //   keyword: '',
      //   graph: [1, 2, 3, 4, 5, 6, 7, 6, 5, 4, 3, 2, 1]
      // },
      // { keyword: '', graph: [] },
      // { keyword: '', graph: [] }
    ]
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAsyncTrends.fulfilled, (state, action) => {
      return {
        ...state,
        keywords: action.payload.keywords,
        isLoading: false
      };
    });
    builder.addCase(fetchAsyncTrends.pending, (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    });
    builder.addCase(fetchAsyncTrends.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    });
    builder.addCase(trendsDelete.fulfilled, (state, action) => {
      return {
        ...state,
        deleteLoading: false
      };
    });
    builder.addCase(trendsDelete.pending, (state, action) => {
      return {
        ...state,
        deleteLoading: true
      };
    });
    builder.addCase(trendsDelete.rejected, (state, action) => {
      return {
        ...state,
        deleteLoading: false
      };
    });
  }
});

export const trendsData = (state) => state.trends.keywords;

export const trends = (state) => state.trends.isLoading;
export const deleteloading = (state) => state.trends.deleteLoading;

export default fetchTrendsSlice.reducer;
