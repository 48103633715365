/* eslint-disable*/
import { Typography } from '@material-ui/core';
import {
  currentAcate1,
  currentAcate2,
  currentAcate3,
  currentAcate4,
  currentAcate5,
  currentAcate6,
  currentRcate1,
  currentRcate2,
  currentRcate3,
  currentRcate4,
  currentRcate5,
  currentYcate1,
  currentYcate2,
  currentYcate3,
  currentYcate4
} from 'feactures/category/categoryGraphSlice';
import { currentchannel } from 'feactures/channel/channelSlice';
import { useSelector } from 'react-redux';

const MarketLabel = () => {
  /* eslint-disable*/
  const channel = useSelector(currentchannel);

  const amazonCate1 = useSelector(currentAcate1);
  const amazonCate2 = useSelector(currentAcate2);
  const amazonCate3 = useSelector(currentAcate3);
  const amazonCate4 = useSelector(currentAcate4);
  const amazonCate5 = useSelector(currentAcate5);
  const amazonCate6 = useSelector(currentAcate6);

  const rakutenCate1 = useSelector(currentRcate1);
  const rakutenCate2 = useSelector(currentRcate2);
  const rakutenCate3 = useSelector(currentRcate3);
  const rakutenCate4 = useSelector(currentRcate4);
  const rakutenCate5 = useSelector(currentRcate5);

  const yahooCate1 = useSelector(currentYcate1);
  const yahooCate2 = useSelector(currentYcate2);
  const yahooCate3 = useSelector(currentYcate3);
  const yahoooCate4 = useSelector(currentYcate4);

  let categories = ['全てのカテゴリ'];
  function addToArray(...values) {
    let arr = ['全てのカテゴリ'];
    for (const value of values) {
      if (value !== 0) {
        arr.push(value.label);
      }
    }
    return arr;
  }
  if (channel == 1) {
    categories = addToArray(
      amazonCate1,
      amazonCate2,
      amazonCate3,
      amazonCate4,
      amazonCate5,
      amazonCate6
    );
  } else if (channel == 2) {
    categories = addToArray(
      rakutenCate1,
      rakutenCate2,
      rakutenCate3,
      rakutenCate4,
      rakutenCate5
    );
  } else if (channel == 3) {
    categories = addToArray(yahooCate1, yahooCate2, yahooCate3, yahoooCate4);
  }

  return (
    <>
      <Typography fontSize="14px" fontWeight="bold" sx={{ mb: 1 }}>
        {categories.join('>')}
      </Typography>
    </>
  );
};

export default MarketLabel;
