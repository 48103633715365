/* eslint-disable*/
import { Typography } from '@material-ui/core';
import { selectedBrand } from 'feactures/brand/brandSlice';
import { currentchannel } from 'feactures/channel/channelSlice';
import { useSelector } from 'react-redux';

const BrandCompareLabel = () => {
  /* eslint-disable*/
  const channel = useSelector(currentchannel);
  // const selectBrand = useSelector(selectedBrand);

  // let brandName = 'ブランドが選択されていません';

  // if (channel == 1) {
  //   if (selectBrand.amazon === '') {
  //     brandName = 'ブランドが選択されていません';
  //   } else {
  //     brandName = selectBrand.amazon;
  //   }
  // } else if (channel == 2) {
  //   if (selectBrand.rakuten === '') {
  //     brandName = '店舗が選択されていません';
  //   } else {
  //     brandName = selectBrand.rakuten;
  //   }
  // } else if (channel == 3) {
  //   if (selectBrand.yahoo === '') {
  //     brandName = 'ブランドが選択されていません';
  //   } else {
  //     brandName = selectedBrand.yahoo;
  //   }
  // }

  return (
    <Typography fontSize="14px" fontWeight="bold">
      競合ブランド比較：グラフ
    </Typography>
  );
};

export default BrandCompareLabel;
