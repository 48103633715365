import * as React from 'react';
import { Stack, Button, Typography } from '@material-ui/core';
// import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const ColorCircleMark = styled('div')(({ theme, bcolor }) => ({
  '&.circle3': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '0.4rem solid',
    borderColor: bcolor,
    borderRadius: '50%',
    width: '100px',
    height: '100px',
    color: bcolor,
    fontSize: '24px',
    fontWeight: 'bold',
    fontFamily: 'auto'
  }
}));

const CircleMark = (props) => {
  const { children, onClick, color } = props; // eslint-disable-line
  return (
    <Stack spacing={2} direction="row" sx={{ mb: 10 }}>
      <ColorCircleMark className="circle3" bcolor={color}>
        {children}
      </ColorCircleMark>
    </Stack>
  );
};

export default CircleMark;
