/* eslint-disable */
import { Box, Typography, IconButton, Button } from '@material-ui/core';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { groupListLength } from 'feactures/monitoring/groupSlice';
import { useSelector } from 'react-redux';
import React from 'react';

const BreadList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [pathName, setPathName] = useState([]);
  const groupLength = useSelector(groupListLength);

  const homeButtonClick = () => {
    navigate('/app/overview');
  };

  const pathNameClick = (path) => {
    navigate(path);
  };

  useEffect(() => {
    switch (location.pathname) {
      case '/app/overview':
        setPathName([{ path: '/app/overview', name: 'オーバービュー' }]);
        break;
      case '/app/monitoring':
        setPathName([{ path: '/app/monitoring', name: 'KPIビュー' }]);
        break;
      case '/app/monitoring/group':
        setPathName([
          { path: '/app/monitoring', name: 'KPIビュー' },
          { path: '/app/monitoring/group', name: 'グループ' }
        ]);
        break;
      case '/app/monitoring/asin':
        if (groupLength > 0) {
          setPathName([
            { path: '/app/monitoring', name: 'KPIビュー' },
            { path: '/app/monitoring/group', name: 'グループ' },
            { path: '/app/monitoring/asin', name: '商品' }
          ]);
        } else {
          setPathName([
            { path: '/app/monitoring', name: 'KPIビュー' },
            { path: '/app/monitoring/asin', name: '商品' }
          ]);
        }
        break;
      case '/app/newcategoryboard':
        setPathName([
          { path: '/app/newcategoryboard', name: 'マーケットビュー' },
          { path: '/app/newcategoryboard', name: 'カテゴリ分析' }
        ]);
        break;
      case '/app/brandanalysis':
        setPathName([
          { path: '/app/newcategoryboard', name: 'マーケットビュー' },
          { path: '/app/brandanalysis', name: '店舗/ブランド分析' }
        ]);
        break;
      case '/app/brandcomparison':
        setPathName([
          { path: '/app/newcategoryboard', name: 'マーケットビュー' },
          { path: '/app/brandcomparison', name: '店舗/ブランド比較' }
        ]);
        break;
      case '/app/measureanalysis':
        setPathName([
          { path: '/app/newcategoryboard', name: 'マーケットビュー' },
          { path: '/app/measureanalysis', name: '店舗/ブランド施策' }
        ]);
        break;
      case '/app/productmeasures':
        setPathName([
          { path: '/app/newcategoryboard', name: 'マーケットビュー' },
          { path: '/app/productmeasures', name: '商品施策' }
        ]);
        break;
      case '/app/otherproductboard':
        setPathName([
          { path: '/app/newcategoryboard', name: 'マーケットビュー' },
          { path: '/app/otherproductboard', name: '商品分析' }
        ]);
        break;
    }
  }, [location.pathname]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <IconButton
        onClick={homeButtonClick}
        sx={{ p: 0, width: '30px', height: '30px' }}
      >
        <HomeOutlinedIcon
          sx={{ fontSize: '24px', mx: 1, color: 'lightgray' }}
        />
      </IconButton>
      {pathName.map((path, index) => (
        <React.Fragment key={index}>
          <ArrowForwardIosOutlinedIcon
            sx={{ fontSize: '16px', mx: 1, color: 'gray' }}
          />
          <Button
            onClick={() => pathNameClick(path.path)}
            disabled={index >= pathName.length - 1}
          >
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: 'bold',
                // bgcolor: 'white',
                // borderRadius: 1,
                color: 'gray'
              }}
            >
              {path.name}
            </Typography>
          </Button>
        </React.Fragment>
      ))}
    </Box>
  );
};

export default BreadList;
