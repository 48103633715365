/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

//グループ標示
export const fetchGroups = createAsyncThunk(
  'monitoring/group',
  async (arg, thunkAPI) => {
    const { accountId } = thunkAPI.getState().user;
    const { pageCount } = thunkAPI.getState().group;
    const res = await client.post(`${apiUrl}group`, {
      account_id: accountId,
      pageCount: pageCount
    });
    return res.data;
  }
);

//グループ追加
export const addGroupPost = createAsyncThunk(
  'monitoring/group/post',
  async (arg, thunkAPI) => {
    const { userId, accountId } = thunkAPI.getState().user;
    const { editGroup } = thunkAPI.getState().group;
    const res = await client.post(`${apiUrl}add_group`, {
      account_id: accountId,
      data: editGroup
    });
    return res.data;
  }
);

export const deleteGroup = createAsyncThunk(
  'monitoring/group/delete',
  async (arg, thunkAPI) => {
    const { groupId, deleteAll } = arg;
    const { accountId } = thunkAPI.getState().user;

    const res = await client.post(`${apiUrl}delete_group`, {
      account_id: accountId,
      group_id: groupId,
      delete_all: deleteAll
    });
    return res.data;
  }
);

//グループ情報変更
export const editGroupPost = createAsyncThunk(
  'monitoring/group/edit/post',
  async (arg, thunkAPI) => {
    const { accountId } = thunkAPI.getState().user;
    const { editGroup } = thunkAPI.getState().group;
    const res = await client.post(`${apiUrl}edit_group`, {
      account_id: accountId,
      data: editGroup
    });
    return res.data;
  }
);

// グループごとの売上等のデータ取得
export const fetchGroupDataList = createAsyncThunk(
  'group/salesData',
  async (arg, thunkApi) => {
    const { searchword } = arg;
    const { term, start, end } = thunkApi.getState().date;
    const { userId } = thunkApi.getState().user;
    const { channel } = thunkApi.getState().currentchannel;
    const { pageCount, order, orderby } =
      thunkApi.getState().group.groupDataList;

    const res = await client.post(`${apiUrl}group/salesList`, {
      user_id: userId,
      term: term,
      start: start,
      end: end,
      channel: channel,
      pageCount: pageCount,
      per_page: 10,
      searchword: searchword,
      order: order,
      orderby: orderby
    });
    return res.data;
  }
);

// グループに含まれるasinの売上データ取得
export const fetchGroupAsinDataList = createAsyncThunk(
  'group/asin/salesData',
  async (arg, thunkApi) => {
    const { searchword } = arg;
    const { term, start, end } = thunkApi.getState().date;
    const { userId } = thunkApi.getState().user;
    const { pageCount, order, orderby, perpage } =
      thunkApi.getState().group.asinDataList;
    const { currentGroup } = thunkApi.getState().monitoring;
    const { channel } = thunkApi.getState().currentchannel;

    const res = await client.post(`${apiUrl}group/asinSales`, {
      user_id: userId,
      term: term,
      start: start,
      end: end,
      pageCount: pageCount,
      per_page: perpage,
      searchword: searchword,
      order: order,
      orderby: orderby,
      group_id: currentGroup.id,
      channel: channel
    });
    return res.data;
  }
);

const groupSlice = createSlice({
  name: 'group',
  initialState: {
    groups: [],
    checkGroupList: [],
    checkGroupNameList: [],
    selectableCampaignList: [],
    error: false,
    successMessage: '',
    isLoading: false,
    registeredASIN: { amazon: [], rakuten: [], yahoo: [] },
    canceledASIN: { amazon: [], rakuten: [], yahoo: [] },
    editGroup: {
      name: '',
      amazon: [],
      rakuten: [],
      yahoo: []
    },
    pageCount: 1,
    groupListLength: '',
    isGroup: false,
    groupDataList: {
      pageCount: 1,
      length: 0,
      orderby: 'sales',
      order: 'desc',
      data: [],
      searchword: ''
    },
    asinDataList: {
      pageCount: 0,
      perpage: 10,
      length: 0,
      orderby: 'sales',
      order: 'desc',
      isLoading: false,
      error: false,
      data: [],
      searchword: ''
    }
  },
  reducers: {
    checkGroup(state, action) {
      return {
        ...state,
        checkGroupList: action.payload.checkGroupList,
        checkGroupNameList: action.payload.checkGroupNameList
      };
    },
    resetCheckGroup(state) {
      return {
        ...state,
        checkGroupList: [],
        checkGroupNameList: []
      };
    },
    resetDisplayedASIN(state) {
      return {
        ...state,
        registeredASIN: [],
        canceledASIN: []
      };
    },
    changeGroupName(state, action) {
      state.editGroup.name = action.payload;
    },
    addAsinToEditGroup(state, action) {
      if (action.payload.channel === 'Amazon') {
        state.editGroup.amazon = action.payload.asinList;
      } else if (action.payload.channel === '楽天') {
        state.editGroup.rakuten = action.payload.asinList;
      } else if (action.payload.channel === 'Yahoo') {
        state.editGroup.yahoo = action.payload.asinList;
      }
    },
    changeEditGroup(state, action) {
      state.editGroup = action.payload;
    },
    // グループ選択プルダウンのページネーション用
    changePageCount(state, action) {
      state.pageCount += action.payload;
    },
    // グループ売上テーブルのページネーション用
    changeGroupDataPageCount(state, action) {
      state.groupDataList.pageCount += action.payload;
    },
    // グループ売上テーブルのページリセット用
    resetGroupDataPage(state, action) {
      state.groupDataList.pageCount = 1;
    },
    // グループ売上データの並び替え用(基準となるカラムの変更)
    changeOrderBy(state, action) {
      state.groupDataList.orderby = action.payload;
    },
    // グループ売上データの並び替え用(昇順・降順の変更)
    changeOrder(state, action) {
      state.groupDataList.order = action.payload;
    },
    // グループ売上テーブルの検索用
    changeGroupDataSearchword(state, action) {
      state.groupDataList.searchword = action.payload;
    },
    // グループ別ASINテーブルのページネーション用
    changeGroupAsinPageCount(state, action) {
      state.asinDataList.pageCount = action.payload;
    },
    // グループ別ASINテーブルのページリセット用
    resetGroupAsinPage(state, action) {
      state.asinDataList.pageCount = 0;
    },
    // グループ別ASINテーブルの1ページの表示件数変更
    changeGroupAsinPerpage(state, action) {
      state.asinDataList.perpage = action.payload;
    },
    // グループ別ASINテーブルの並び替え用(基準となるカラムの変更)
    changeGroupAsinOrderBy(state, action) {
      state.asinDataList.orderby = action.payload;
    },
    // グループ別ASINテーブルの並び替え用(昇順・降順の変更)
    changeGroupAsinOrder(state, action) {
      state.asinDataList.order = action.payload;
    },
    // グループ別ASINテーブルの検索用
    changeGroupAsinSearchword(state, action) {
      state.asinDataList.searchword = action.payload;
    },
    // サクセスメッセージの初期化
    resetSuccessMessage(state, action) {
      state.successMessage = '';
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGroups.fulfilled, (state, action) => {
      return {
        ...state,
        groups: action.payload.groupList,
        selectableCampaignList: action.payload.campaignsList,
        groupListLength: action.payload.groupListLength,
        isGroup: action.payload.groupListLength > 0,
        isLoading: false,
        error: false
      };
    });
    builder.addCase(fetchGroups.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        error: false
      };
    });
    builder.addCase(fetchGroups.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: true
      };
    });

    builder.addCase(addGroupPost.fulfilled, (state, action) => {
      console.log(action.payload);
      return {
        ...state,
        groups: action.payload.groupList,
        selectableCampaignList: action.payload.campaignsList,
        groupListLength: action.payload.groupListLength,
        isLoading: false,
        error: false,
        successMessage: 'グループの新規追加が完了しました',
        // 登録できたASINリスト、できなかったASINリストを受け取る
        registeredASIN: action.payload.registered_asin,
        canceledASIN: action.payload.canceled_asin
      };
    });
    builder.addCase(addGroupPost.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        error: false,
        successMessage: ''
      };
    });
    builder.addCase(addGroupPost.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: true,
        successMessage: ''
      };
    });
    builder.addCase(deleteGroup.fulfilled, (state, action) => {
      return {
        ...state,
        groups: action.payload.groupList,
        selectableCampaignList: action.payload.campaignsList,
        groupListLength: action.payload.groupListLength,
        isLoading: false,
        error: false
      };
    });
    builder.addCase(deleteGroup.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        error: false
      };
    });
    builder.addCase(deleteGroup.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: true
      };
    });
    builder.addCase(editGroupPost.fulfilled, (state, action) => {
      return {
        ...state,
        groups: action.payload.groupList,
        selectableCampaignList: action.payload.campaignsList,
        groupListLength: action.payload.groupListLength,
        isLoading: false,
        error: false,
        registeredASIN: action.payload.registered_asin,
        canceledASIN: action.payload.canceled_asin,
        successMessage: 'グループの編集が完了しました'
      };
    });
    builder.addCase(editGroupPost.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        error: false,
        successMessage: ''
      };
    });
    builder.addCase(editGroupPost.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: true,
        successMessage: ''
      };
    });
    builder.addCase(fetchGroupDataList.fulfilled, (state, action) => {
      return {
        ...state,
        groupDataList: {
          ...state.groupDataList,
          length: action.payload.length,
          data: action.payload.data
        },
        isLoading: false,
        error: false
      };
    });
    builder.addCase(fetchGroupDataList.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        error: false
      };
    });
    builder.addCase(fetchGroupDataList.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: true
      };
    });
    builder.addCase(fetchGroupAsinDataList.fulfilled, (state, action) => {
      return {
        ...state,
        asinDataList: {
          ...state.asinDataList,
          length: action.payload.length,
          data: action.payload.data,
          isLoading: false,
          error: false
        }
      };
    });
    builder.addCase(fetchGroupAsinDataList.pending, (state, action) => {
      return {
        ...state,
        asinDataList: {
          ...state.asinDataList,
          isLoading: true,
          error: false
        }
      };
    });
    builder.addCase(fetchGroupAsinDataList.rejected, (state, action) => {
      return {
        ...state,
        asinDataList: {
          ...state.asinDataList,
          isLoading: false,
          error: true
        }
      };
    });
  }
});

export const {
  checkGroup,
  resetCheckGroup,
  resetDisplayedASIN,
  changeGroupName,
  addAsinToEditGroup,
  changeEditGroup,
  changePageCount,
  changeGroupDataPageCount,
  changeOrder,
  changeOrderBy,
  changeGroupAsinOrder,
  changeGroupAsinOrderBy,
  changeGroupAsinPageCount,
  changeGroupAsinPerpage,
  resetSuccessMessage,
  resetGroupAsinPage,
  resetGroupDataPage,
  changeGroupAsinSearchword,
  changeGroupDataSearchword
} = groupSlice.actions;

export const groups = (state) => state.group.groups;

export const groupStatus = (state) => state.group.isLoading;

export const groupError = (state) => state.group.error;
export const groupSuccessMessage = (state) => state.group.successMessage;
export const groupList = (state) => state.group.checkGroupList;
export const campaignsList = (state) => state.group.selectableCampaignList;

export const groupRegisteredASIN = (state) => state.group.registeredASIN;
export const groupCanceledASIN = (state) => state.group.canceledASIN;

export const editGroup = (state) => state.group.editGroup;

// グループ別売上データ用
export const groupSalesData = (state) => state.group.groupDataList.data;
export const groupSalesDataPage = (state) =>
  state.group.groupDataList.pageCount;
export const groupSalesDataLength = (state) => state.group.groupDataList.length;
export const groupSalesDataOrderBy = (state) =>
  state.group.groupDataList.orderby;
export const groupSalesDataOrder = (state) => state.group.groupDataList.order;
export const groupDataSearchword = (state) =>
  state.group.groupDataList.searchword;

// グループ別ASIN売上データ用
export const groupAsinData = (state) => state.group.asinDataList.data;
export const groupAsinDataPage = (state) => state.group.asinDataList.pageCount;
export const groupAsinDataLength = (state) => state.group.asinDataList.length;
export const groupAsinDataOrderBy = (state) => state.group.asinDataList.orderby;
export const groupAsinDataOrder = (state) => state.group.asinDataList.order;
export const groupAsinDataLoading = (state) =>
  state.group.asinDataList.isLoading;
export const groupAsinDataPerpage = (state) => state.group.asinDataList.perpage;
export const groupAsinDataSearchword = (state) =>
  state.group.asinDataList.searchword;
export const groupPageCount = (state) => state.group.pageCount;
export const groupListLength = (state) => state.group.groupListLength;

export default groupSlice.reducer;
