/* eslint-disable*/
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography
} from '@material-ui/core'; // eslint-disable-line
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

const TermsOfService = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          // minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid container mb={2} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h4" mb={3}>
                利用規約
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            overflow="scroll"
            height="calc(100vh - 150px)"
            width="100%"
          >
            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <>
                  <p>Oxcimサービス利用規約</p>
                  <br />
                  <p>
                    Oxcimサービス利用規約（以下「本規約」といいます。）は、株式会社オプト（以下「当社」といいます。）が提供する本サービス（次条にて定義します。）を利用するうえで遵守すべき事項を定めたものです。利用者（次条にて定義します。）は、本規約の内容を十分理解し、これを遵守することにつき同意の上、本サービスを利用するものとします。
                  </p>
                  <br />
                  <p>第１条（定義）</p>
                  <p>
                    本規約において使用する用語の定義は、以下の各号に定めるとおりとします。
                  </p>
                  <p>
                    （１）「本サービス」とは、当社がOxcimの名称を冠して提供する、ECサイト（次号にて定義します。）における売上促進を目的としたSaaS形式（ソフトウェアの機能をネットワーク経由で提供するサービス形式を指します。）による情報分析サービス（理由の如何を問わずサービスの名称が変更された場合は、当該変更後のサービスを含みます。）をいいます。本サービスの詳細な内容、仕様、提供条件等は、別途当社が指定するウェブサイトに記載します。
                  </p>
                  <p>
                    （２）「ECサイト」とは、利用者自身又は第三者が運営する、利用者が行う電子商取引に用いるウェブサイトをいいます。
                  </p>
                  <p>
                    （３）「利用者」とは、本契約に基づき、本サービスを利用する法人をいいます。
                  </p>
                  <p>
                    （４）「登録情報」とは、本サービスの申込みにあたり利用者が当社に提供した利用者の属性に関する情報（「個人情報の保護に関する法律」に定める「個人情報」を含みます。）をいいます。
                  </p>
                  <p>
                    （５）「プライバシーポリシー」とは、当社が指定するウェブサイト（https://www.opt.ne.jp/privacy/）に掲載する、当社のプライバシーポリシーをいいます。
                  </p>
                  <p>
                    （６）「ユーザー」とは、ECサイトを訪れて利用者が販売する商品等の購入を行った者をいいます。
                  </p>
                  <p>
                    （７）「販売データ」とは、ECサイト上での利用者とユーザー間の電子商取引における購買実績（商品データ、決済データ、ユーザーデータ等を指しますがこれらに限りません。）に関するデータをいいます。
                  </p>
                  <p>
                    （８）「分析データ」とは、当社が、販売データを統合、加工及び分析した結果を可視化したデータをいいます。
                  </p>
                  <p>
                    （９）「本システム」とは、本サービスの提供のために当社が管理又は使用するシステム、ソフトウェア、サーバー、その他の設備をいいます。
                  </p>
                  <p>
                    （１０）「本契約」とは、本規約に基づき、本規約をその内容とする、当社と利用者との間で成立する契約をいいます。
                  </p>
                  <p>
                    （１１）「反社会的勢力」とは、暴力団、暴力団員、暴力団準構成員、暴力団関係企業・団体、総会屋、社会運動等標ぼうゴロ、特殊知能暴力集団、その他これらに準ずる者をいいます。
                  </p>
                  <p>
                    （１２）「親会社」、「子会社」及び「関係会社」とは、財務諸表等の用語、様式及び作成方法に関する規則に定める定義をいいます。
                  </p>
                  <p>
                    （１３）「利用料金」とは、本サービスの対価として利用者が支払う金額をいいます。
                  </p>
                  <p>
                    （１４）「有料プラン」とは、利用者が本サービスを利用料金を支払って利用する場合のプランをいいます。
                  </p>
                  <p>
                    （１５）「申込書等」とは、利用料金その他当社が定める利用条件等を記載した「申込書」、その他当社が指定する本サービスの利用申込みにかかる書類その他のフォーマットをいいます。
                  </p>
                  <p>
                    （１６）「利用期間」とは、利用者が本サービスを利用できる期間をいいます。
                  </p>
                  <p>
                    （１７）「付随サービス」とは、本サービスの提供に付随して当社が利用者に対して提供する、本サービスの設定、運用、コンサルティング等のサービスをいいます。
                  </p>
                  <br />
                  <p>第２条（申込手続）</p>
                  <p>
                    １.
                    本サービスの利用を希望する法人（以下「申込希望者」といいます。）は、本規約及びプライバシーポリシーに同意の上、本サービス利用のために当社所定の手続（以下「申込手続」といいます。）を行うものとします。なお、申込希望者が申込手続のために入力した登録情報について、当社は、当該申込手続の処理のために使用するものとします。
                  </p>
                  <p>
                    ２.
                    当社は、申込手続を行った申込希望者に本サービスの利用を許諾する場合、当社所定の方法によりその旨を当該申込希望者に通知します。当該通知時点で、申込希望者と当社との間に本契約が成立し、申込手続が完了したものとします。なお、当社は、申込手続が完了した後、本規約に別段の定めがある場合を除き、一切の利用料金の返還の義務を負いません。
                  </p>
                  <p>
                    ３.
                    利用者は、登録情報について、常に正確な情報が登録されるよう管理する責任を負い、登録情報の全部又は一部に変更が生じた場合は、速やかに当社所定の手続によりこれを修正するものとします。
                  </p>
                  <p>
                    ４.
                    当社は、申込希望者が以下のいずれかに該当すると判断した場合、申込希望者に対する事前の通知又は理由の説明なしに、申込希望者の申込手続を拒否することができるものとします。
                  </p>
                  <p>
                    （１）過去に当社から利用者たる資格を抹消又は停止されたことがある場合。
                  </p>
                  <p>（２）登録情報に虚偽、誤記又は記入漏れがある場合。</p>
                  <p>
                    （３）反社会的勢力に該当する、又はそのおそれがある場合。
                  </p>
                  <p>（４）前各号に定める他、当社が不適当と判断した場合。</p>
                  <p>
                    ５.
                    申込書等に定める事項と本規約に定める事項が矛盾する場合には、本規約に特段の定めのない限り、申込書等の定めが優先するものとします。
                  </p>
                  <br />
                  <p>第３条（ID・パスワードの管理）</p>
                  <p>
                    １.
                    当社は、本契約成立後遅滞なく、利用者に対してアカウントを設定します。
                  </p>
                  <p>
                    ２.
                    利用者は、当社が付与したアカウントのID及び自らが設定したパスワードを第三者に開示、貸与又は共有しないとともに、第三者に漏洩することのないよう厳重に管理（パスワードを適宜変更する措置を含みます。）するものとします。
                  </p>
                  <p>
                    ３.
                    第三者が前項のID及びパスワードを用いて本サービスを利用した場合、当該利用は利用者による利用とみなされ、利用者及び第三者が被った損害について、当社はその責任を負わないものとします。なお、利用者が第三者に対し前項のID及びパスワードを故意に開示、貸与又は共有した場合、当社は当該利用者に対し本サービスの利用を永続的に停止すること又は本契約を解約することその他当社が必要と判断する措置をとることができます。
                  </p>
                  <br />
                  <p>第４条（連携設定）</p>
                  <p>
                    １.
                    利用者は、本契約成立後、当社の指示に従って、ECサイトにおいて販売データを本サービスと連携させるための設定作業（以下「連携設定」といいます。）を行うものとし、当社は、利用者による連携設定に基づいて、ECサイトから販売データを取得することができるものとします。
                  </p>
                  <p>
                    ２.
                    利用者は、本サービスの利用期間中、正しく連携設定がされた状態を維持するものとします。
                  </p>
                  <p>
                    ３.
                    利用者は、第三者が運営するECサイトにおいて連携設定を行う場合、ECサイトが定める利用規約その他の定めを遵守するものとし、利用者が当該定めを遵守しなかったことにより当社に生じた損害を賠償するものとします。
                  </p>
                  <p>
                    ４.
                    利用者が連携設定を行ったことで利用者に発生した損害又は不利益について、当社はその責任を負わないものとします。
                  </p>
                  <br />
                  <p>第５条（販売データ）</p>
                  <p>
                    １.
                    利用者は、当社に対し、販売データに関して次の各号に定める事項を行うことを許諾します。
                  </p>
                  <p>
                    （１）本サービスの提供に必要な範囲（本サービスの拡充及び新機能の開発に向けた調査分析を行う場合を含みます。）において、販売データを複製、加工、改変、編集、その他の利用（分析データの作成を含みます。）をすること。
                  </p>
                  <p>（２）販売データを統計的資料として利用すること。</p>
                  <p>
                    （３）販売データについて、利用者を特定又は推測できないデータとして加工した上で、当社が複製、加工、改変、編集、自己の親会社若しくは子会社又はこれらの関係会社に対する提供、その他の利用をすること。
                  </p>
                  <p>
                    ２.
                    ECサイトの運営が第三者による場合、利用者は、当社に対し、前項各号に定める事項を当社が何ら制限なく行うことができることを保証するものとします。
                  </p>
                  <br />
                  <p>第６条（分析データ）</p>
                  <p>
                    １.
                    分析データの権利は、利用者と当社との関係においては当社に帰属します。
                  </p>
                  <p>
                    ２.
                    利用者は、当社の事前の書面による許諾なく、ECサイトにおける売上促進の目的の範囲を超えて、分析データを複製、加工、改変、編集、その他の利用をすることはできません。
                  </p>
                  <br />
                  <p>第７条（プライバシー）</p>
                  <p>
                    １.
                    利用者は、販売データがユーザーのプライバシーに影響を与えるおそれのある情報であることを認識し、その取得、加工、分析、第三者とのデータ連携、その他の取扱い（以下「利活用等」といいます。）について、ユーザーに対する責任を負うものとします。利用者は、利用者が本サービスを用いて、販売データ（分析データを含みます。）の利活用等を行う場合には、その利活用等が各種法令、ガイドライン及び利用者のプライバシーポリシーに適合していることを事前に確認し、また、ユーザーに対する利用目的の通知及び同意取得等の必要な手続を行うものとします。
                  </p>
                  <p>
                    ２.
                    利用者は、本サービスの利用にあたって、当社にとって個人情報の保護に関する法律に定める個人情報に該当するユーザーの情報及び一般データ保護規則（REGULATION
                    （EU）2016/679 OF THE EUROPEAN PARLIAMENT AND OF THE COUNCIL
                    of 27 April 2016 on the protection of natural persons with
                    regard to the processing of personal data and on the free
                    movement of such data, and repealing Directive
                    95/46/EC）その他諸外国の法令が適用されるユーザーの情報の利活用等を行わないものとします。利用者が本項に違反したことによって当社が第三者から苦情又は請求を受けた場合、利用者の責任と負担においてこれを処理解決し、当該苦情又は請求につき当社を防御及び免責し、またその解決のために当社に生じた損害（弁護士費用を含みます。）を賠償するものとします。
                  </p>
                  <br />
                  <p>第８条（利用料金及び支払方法）</p>
                  <p>
                    １.
                    本条は、利用者が有料プランを利用する場合のみ、適用されます。
                  </p>
                  <p>
                    ２.
                    利用料金は、申込書等又は当社が指定するウェブサイトその他当社が別途提示する書面に定めるものとします。
                  </p>
                  <p>
                    ３.
                    利用者は、利用料金及びその消費税相当額を、当社所定の期日までに、当社が指定する方法により支払うものとします。なお、当該支払方法により当社に対して利用料金及びその消費税相当額を支払う際にかかる手数料は利用者の負担とし、また、当該支払方法が第三者が提供するサービスを用いるものである場合、利用者は当該第三者が定める利用規約（名称の如何を問わず、当該サービスに関する利用条件をいいます。）等の定めに同意するものとします。
                  </p>
                  <p>
                    ４.
                    当社は、利用者に対して事前に通知することなく、利用料金を変更できるものとします。本項に従い利用料金が変更された場合、当該変更日直後の支払分より新たな利用料金が適用されるものとします。
                  </p>
                  <br />
                  <p>第９条（利用期間）</p>
                  <p>
                    １.
                    利用期間は、申込書等その他当社が別途提示する書面に定めるものとします。
                  </p>
                  <p>
                    ２.
                    利用者又は当社が、利用期間満了の１か月前までに相手方に対して本契約を更新しない旨の書面又は当社が別途定める方法による通知を行わなかった場合、本契約は、同一の期間及び条件をもって自動的に延長されるものとし、その後も同様とします。
                  </p>
                  <br />
                  <p>第１０条（本サービスの提供中断）</p>
                  <p>
                    １.
                    当社は、次の各号に該当する場合、利用者に対して事前に通知することなく、本サービスの全部又は一部を一時的に中断するときがあります。但し、有料プランの利用者は本サービスの中断中であっても、次項に定める場合を除き、利用料金の支払い義務を免れないものとします。
                  </p>
                  <p>（１）本システムの保守点検、工事等を行う場合。</p>
                  <p>（２）本システムに障害が発生した場合。</p>
                  <p>
                    （３）電気通信事業者の提供する役務に起因して、本サービスの提供が困難となった場合。
                  </p>
                  <p>（４）前各号に準ずる事由が生じた場合。</p>
                  <p>
                    ２.
                    当社の故意又は過失により本サービスの全部又は一部の提供が中断された場合であって、利用者が本サービス利用の目的を達することができないと当社が認めた状態（以下「利用不能状態」といいます。）が生じ、当該利用不能状態が２４時間以上連続した場合には、利用者は、下記の計算式に基づいて算出した金額（一円未満切り捨て）の減額を請求することができます。但し、利用者の利用不能状態が回復した日から１か月以内に利用者が減額を請求しないときには、利用者の当社に対する減額請求権は消滅します。
                  </p>
                  <br />
                  <p style={{ textAlign: 'center' }}>記</p>
                  <p style={{ textAlign: 'center' }}>
                    減額される金額＝利用不能状態が生じた本サービスの１月当たりの利用料金×
                  </p>
                  <p style={{ textAlign: 'center' }}>
                    （利用不能状態の時間数÷２４） ÷当該月の日数
                  </p>
                  <p style={{ textAlign: 'end' }}>以上</p>
                  <br />
                  <p>第１１条（本サービスの変更）</p>
                  <p>
                    当社は、利用者に対して事前に通知することなく、本サービスの内容や仕様を変更することができるものとし、当社は、本条に基づく事項によって利用者に発生した損害又は不利益について、一切その責任を負わないものとします。
                  </p>
                  <br />
                  <p>第１２条（本サービスの終了）</p>
                  <p>
                    １.
                    当社は、利用者に対して、１か月前までにその旨を通知又は当社が指定するウェブサイトに掲載することによって、本サービスの全部又は一部を終了することができるものとします。
                  </p>
                  <p>
                    ２.
                    前項により本サービスの全部が終了したときは、その終了の日に本契約も同時に終了するものとし、当社は有料プランの利用者に対し、受領済みの利用料金のうち、本サービスが終了した日が属する月の翌月以降の利用料金を当社指定の方法により返還するものとします。なお、利用料金の返還にかかる手数料は当社の負担とします。また、当該終了の日までに未払いの利用料金がある場合には、利用者は、第８条（利用料金及び支払方法）の定めに従って未払いの利用料金を支払うものとします。
                  </p>
                  <br />
                  <p>第１３条（解約及び本サービスの停止）</p>
                  <p>
                    １.
                    有料プランの利用者が、第２条第２項に定める申込手続の完了後、利用期間の途中で本サービスの利用終了を希望する場合、既に支払った利用料金の累計額が申込書等その他当社が別途提示する書面に定める利用期間分に満たないときは当該不足分を支払うこと、又は利用終了後の期間について利用料金を既に支払っているときは、当該支払済の利用料金が返還されないことを承諾したうえで、当社所定の本サービスの解約手続を行うことにより本契約を終了することができます。利用者が当該解約手続を行い、当社が解約手続完了の通知を発した時点で、当社と当該利用者間の本契約は終了するものとします。
                  </p>
                  <p>
                    ２.
                    当社は、次の各号に定める事由が生じた場合又は当該事由が生じるおそれがあると当社が合理的に判断した場合、利用者に対する事前の通知又は理由の説明なしに、当該利用者による本サービスの利用を一時的に若しくは永続的に停止すること又は本契約を解約することができるものとします。なお、当社は、これにより利用者に生じた結果について、その責任を負わないものとし、既に受領済みの利用料金があっても、一切の返還の義務を負いません。
                  </p>
                  <p>
                    （１）利用者が本規約に違反した、又は違反するおそれがある場合。
                  </p>
                  <p>
                    （２）利用者が第２条第４項各号に該当することが事後的に判明した場合。
                  </p>
                  <p>
                    （３）有料プランの利用者が利用料金の支払を遅延し、又は拒否した場合。
                  </p>
                  <p>
                    （４）その他当社が本サービスの提供が困難であると判断した場合。
                  </p>
                  <p>
                    ３.
                    事由の如何を問わず本契約が終了した場合、当社は、本サービスにおける利用者のアカウントを削除しますが、一定の期間、当該利用者の登録情報及び販売データを保持する場合があります。
                  </p>
                  <br />
                  <p>第１４条（付随サービス）</p>
                  <p>
                    利用者が付随サービスを利用する場合、当社と利用者との間で別途契約を締結するものとし、付随サービスは、当該契約の定めに従って提供されるものとします。また、付随サービスの利用及び提供に関しては、本規約の定めは適用されないものとします。
                  </p>
                  <br />
                  <p>第１５条（権利の帰属等）</p>
                  <p>
                    １.
                    本サービスに関連して当社が提供したすべての情報（有形無形及びその提供方法を問いません。）及び本システムに関する、著作権（著作権法第２７条及び第２８条に定める権利を含み、以下同様とします。）、商標権、特許権、意匠権等の知的財産権（受ける権利を含みます。）、その他の一切の権利（以下「知的財産権等」といいます。）は、当社又は当社にライセンスを許諾した第三者に帰属します。利用者は、当社の事前の書面による許諾なく知的財産権等を利用（複製、頒布、上映、配信、販売などを含みますが、これらに限りません。）することはできません。
                  </p>
                  <p>
                    ２.
                    利用者は、本システムの複製、改変、リバースエンジニアリング、逆コンパイル、逆アセンブルその他本サービス及び本システムにかかる知的財産権等を侵害する行為を一切行ってはならないものとします。
                  </p>
                  <p>
                    ３.
                    利用者は、当社の承諾なく当社の商号、商標及びロゴマークを使用してはならないものとします。
                  </p>
                  <br />
                  <p>第１６条（禁止行為）</p>
                  <p>
                    利用者は、本サービスの利用にあたり、以下各号に該当する行為を行ってはならないものとします。利用者が本項の定めに違反した場合、当社は、当該利用者に対し本サービスの全部又は一部の利用停止等の措置をとることができます。
                  </p>
                  <p>（１）法令の定めに違反する行為</p>
                  <p>（２）詐欺その他の犯罪に結びつく行為</p>
                  <p>
                    （３）当社又は第三者の著作権、商標権等の知的財産権その他の権利を侵害する行為
                  </p>
                  <p>
                    （４）当社若しくは第三者の設備又は本サービスを提供するにあたり必要な設備等の利用若しくは業務の運営・維持に支障を与える行為
                  </p>
                  <p>（５）第三者になりすまして本サービスを利用する行為</p>
                  <p>（６）第三者に本サービスを利用させる行為</p>
                  <p>
                    （７）本サービスの利用を通じて得られた情報、技術及びノウハウ等を用いて、本サービスと類似又は同様のサービスを開発する行為
                  </p>
                  <p>（８）その他、当社若しくは第三者に不利益を与える行為</p>
                  <br />
                  <p>第１７条（秘密保持）</p>
                  <p>
                    １.
                    利用者は、本サービスを提供又は利用するにあたり、相手方から知得した一切の情報（以下「秘密情報」といいます。）を秘密として扱うものとします。
                  </p>
                  <p>
                    ２.
                    当社及び利用者は、相手方の事前の書面による承諾を得ることなく秘密情報を第三者に開示又は漏洩してはなりません。但し、本契約の履行のため秘密情報を知る必要がある自己、自己の親会社若しくは子会社又はこれらの関係会社の役員（取締役、監査役、執行役、業務を執行する社員又はこれらに準ずる者をいいます。以下同じ。）若しくは従業員、又は弁護士、会計士若しくは税理士等法令の定めるところに従い守秘義務を負う第三者その他委託先に対し秘密情報を開示する場合を除きます。
                  </p>
                  <p>
                    ３.
                    第１項の定めにかかわらず、次の各号に定める情報は、秘密情報には含まれません。
                  </p>
                  <p>
                    （１）相手方による開示の時点において公知となっていた情報
                  </p>
                  <p>
                    （２）相手方による開示の時点において、秘密保持義務を負担することなく既に自己が所有していた情報
                  </p>
                  <p>
                    （３）相手方による開示の後に、自己の契約違反、不作為、懈怠又は過失等によらずに公知となった情報
                  </p>
                  <p>
                    （４）相手方から開示されたいかなる情報にもよらずに独自に開発した情報
                  </p>
                  <p>
                    （５）何らの秘密保持義務を負担することなく正当な権限を有する第三者から合法的に開示された情報
                  </p>
                  <p>
                    ４.
                    当社及び利用者は、相手方の書面による承諾を得て秘密情報を第三者に開示する場合、当該第三者に対し、自己の責任において、本条の定めと同等の秘密保持義務を負わせるものとします。また、当該第三者の秘密情報の取扱いにかかる行為について、一切の責任を負わなければなりません。
                  </p>
                  <p>
                    ５.
                    当社及び利用者は、相手方の事前の承諾なしに秘密情報を本契約の履行に必要な範囲を超えて使用又は複製してはなりません。なお、秘密情報を複製した場合、その複製物も秘密情報として扱うものとします。
                  </p>
                  <p>
                    ６.
                    当社及び利用者は、法令の定めるところに従い、裁判所その他の公的機関等から秘密情報の開示を要求された場合、又は金融商品取引所の規則に基づき開示を要求された場合、かかる要求に対応するために必要な範囲で秘密情報を開示することができます。但し、かかる要求を受けた当事者は、その旨を速やかに相手方に対して通知し、相手方の秘密情報を保護するために必要となる措置を可能な限り執るものとします。
                  </p>
                  <p>
                    ７.
                    本契約が終了した場合、又は相手方から要求があった場合には、当社及び利用者は、相手方の指示に従って、秘密情報を直ちに相手方に返還し、又は破棄若しくは消去しなければなりません。但し、法令の定めるところに従い当社及び利用者が秘密情報を保管、並びに当社において当社に帰属する分析データを保存し利用する場合を除きます。
                  </p>
                  <p>
                    ８.
                    当社及び利用者は、相手方が本条に違反した場合又は違反する恐れがある場合、相手方に対し、当該違反の停止又は予防を請求することができ、相手方は、これに応じる義務を負います。
                  </p>
                  <p>
                    ９.
                    事由の如何を問わず、本契約の終了後であっても、本条の定めはその終了後１年間存続するものとします。なお、第７項但書に基づき秘密情報を保管する場合、当該保管に関しては、これが終了するまで本条の定めが有効に適用されるものとします。
                  </p>
                  <br />
                  <p>第１８条（反社会的勢力の排除）</p>
                  <p>
                    １.
                    当社及び利用者は、それぞれ、相手方に対し、次の各号に掲げる事項を表明し、本契約の有効期間中、これを保証します。
                  </p>
                  <p>
                    （１）自らが、反社会的勢力ではないこと、又は反社会的勢力でなくなった時から5年を経過していること
                  </p>
                  <p>
                    （２）自らの役員、従業員及び顧問その他のアドバイザーが反社会的勢力ではないこと、及び反社会的勢力と社会的に非難される関係を有していないこと
                  </p>
                  <p>
                    （３）自らが反社会的勢力と次のいずれかの関係を有していないこと
                  </p>
                  <p>
                    (ア)
                    自ら若しくは第三者の不正の利益を図る目的、又は第三者に損害を与える目的をもって反社会的勢力を利用していると認められる関係
                  </p>
                  <p>
                    (イ)
                    反社会的勢力に対して資金等を提供し、又は便宜を供与するなどして反社会的勢力の維持又は運営に協力していると認められる関係
                  </p>
                  <p>
                    （４）反社会的勢力に自己の名義を利用させ、本契約を締結するものでないこと
                  </p>
                  <p>
                    （５）自ら又は第三者を利用して本契約に関して、脅迫的な言動若しくは暴力を用いる行為、風説を流布し、又は偽計若しくは威力を用いて、相手方の業務を妨害する行為、信用を毀損する行為その他不法な行為をしないこと
                  </p>
                  <p>
                    ２.
                    当社及び利用者は、相手方が前項に違反した場合には、催告を要することなく相手方に書面で通知することにより、本契約を解除することができます。当該解除は、解除した当事者による相手方に対する損害賠償請求を妨げません。
                  </p>
                  <p>
                    ３.
                    前項の規定により本契約が解除された場合には、解除された者は、解除により生じた自己の損害について相手方に対して一切の請求を行えません。
                  </p>
                  <br />
                  <p>第１９条（利用実績）</p>
                  <p>
                    当社は、本サービスの宣伝を目的として、利用者の商号、商品若しくは役務の固有名称又はこれらに関する商標及びロゴマーク等を、当社発行のパンフレット及びウェブサイト上の情報媒体等へ掲載その他の公表又は開示（利用者が本サービスを利用した事実を含みます。）を行うことができるものとします。
                  </p>
                  <br />
                  <p>第２０条（免責及び非保証）</p>
                  <p>
                    １.
                    利用者は、本サービスの内容（分析データを含みます。）の信頼性、正確性、完全性及び有用性等について利用者自身で判断し、本サービスを利用者自身の責任で利用するものとします。
                  </p>
                  <p>
                    ２.
                    当社は、以下各号記載のいずれの事項についても保証するものではなく、利用者は、当該非保証について同意のうえ、自己の責任において本サービスを利用するものとします。また、有料プランの利用者は、本規約に別段の定めがある場合を除き、理由のいかんを問わず、以下各号のいずれの事項が満たされない場合であっても利用料金の減免はなされず、有料プランの契約期間満了までの利用料金を支払うものとします。
                  </p>
                  <p>
                    （１）本サービスから得られる情報（主に分析データを指しますがこれに限りません。）が利用者にとって有用なものであること。
                  </p>
                  <p>
                    （２）本サービスの利用が、ECサイトにおける売上の向上等、何らかの成果を上げ又は目的を達成するものであること。
                  </p>
                  <p>
                    （３）本サービスから得られる情報に不適切又は不正確な内容が含まれないこと。
                  </p>
                  <p>
                    （４）本システムに不具合、エラー、又は提供の中断若しくは中止その他の障害が生じないこと。
                  </p>
                  <p>
                    （５）本システムにおいて、第三者がウイルス等の有害なコンピュータープログラム等を配信していないこと。
                  </p>
                  <p>
                    ３.
                    当社は、天災地変、火事、労働争議、戦争、暴動、内乱、テロ行為、疫病、委託先の債務不履行、法令又は規則の制定又は改廃、公権力による命令又は処分、争議行為、輸送機関又は通信回線等の事故、第三者によるハッキング又はクラッキング、ウイルス等の有害なコンピュータープログラム、その他当社の管理の及ばない事項などの不可抗力による本規約上の債務不履行につき、その責任を負わないものとします。
                  </p>
                  <p>
                    ４.
                    当社は、いかなる場合でも、利用者の逸失利益、間接損害、拡大損害、特別損害、弁護士費用、その他の本規約に定めのない損害について、賠償しないものとします。
                  </p>
                  <p>
                    ５.
                    利用者が本サービスの利用に起因して、ユーザーを含む第三者に対し不利益又は損害を与えた場合、当該不利益又は損害が当社の故意又は重過失による場合を除き、利用者の責任と負担においてこれを解決するものとし、当社は、第三者に生じた不利益及び損害について責任を負わないものとします。
                  </p>
                  <br />
                  <p>第２１条（譲渡等）</p>
                  <p>
                    １.
                    利用者は、当社の事前の書面による承諾がない限り、本契約上の地位、本契約に基づく権利又は義務の全部又は一部を、第三者に譲渡し、承継させ、又は担保に供してはなりません。
                  </p>
                  <p>
                    ２.
                    当社は、利用者に通知することにより本契約上の地位並びに本契約に基づく権利及び義務の全部又は一部を第三者に譲渡し、承継させ、又は担保に供することができるものとし、利用者は、かかる譲渡等につき本項においてあらかじめ同意するものとします。
                  </p>
                  <br />
                  <p>第２２条（損害賠償）</p>
                  <p>
                    １.
                    利用者は、本契約に違反して当社に損害を与えた場合、その損害（弁護士費用を含みます。）を賠償するものとします。
                  </p>
                  <p>
                    ２.
                    当社が有料プランの利用者に対して負う損害賠償額は、故意若しくは重過失による場合又は第１８条（反社会的勢力の排除）違反の場合を除き、当該原因となった本契約における直近６か月の本サービスの当社が受領済の利用料金を合算した額を上限とします。なお、利用者が無償で本サービスを利用する場合、当社の故意若しくは重過失による場合又は第１８条（反社会的勢力の排除）違反の場合を除き
                    当社は一切の責任を負いません。
                  </p>
                  <br />
                  <p>第２３条（本規約の変更）</p>
                  <p>
                    当社は、本規約について内容の変更をすることができるものとします。当社は、変更後の内容について事前に利用者に通知又は当社が指定するウェブサイトに掲載するものとし、当該通知又は掲載から７日以内に利用者が異議を述べず、本サービスの利用を継続した場合、利用者は当該変更に同意したものとみなします。
                  </p>
                  <br />
                  <p>第２４条（通知）</p>
                  <p>
                    本規約に別段の定めがある場合を除き、本契約に基づく又はこれに関連する全ての通知、書面の交付及び情報の提供は、電子メール、当社が指定するウェブサイトへの掲載又はその他の電磁的方法を含むものとします。
                  </p>
                  <br />
                  <p>第２５条（準拠法）</p>
                  <p>
                    本規約及び本契約は、日本法に準拠し、日本法に従って解釈されます。
                  </p>
                  <br />
                  <p>第２６条（専属的合意管轄）</p>
                  <p>
                    本契約に関して紛争が生じた場合、訴額に応じて、東京簡易裁判所又は東京地方裁判所を第一審の専属的合意管轄裁判所とします。
                  </p>
                  <br />
                  <p>第２７条（協議等）</p>
                  <p>
                    本規約に定めのない事項又は本規約の解釈について疑義が生じた事項については、当社と利用者が誠意をもって協議の上、信義に即して解決するものとします。
                  </p>
                  <br />
                  <p>（以下、正文なし。）</p>
                  <br />
                  <p>２０２２年１月１７日　制定</p>
                  <p>２０２４年４月１６日　改定</p>
                  <p>株式会社オプト</p>
                </>
              </CardContent>
            </Card>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default TermsOfService;
