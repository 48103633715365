/* eslint-disable */
import { useState, createContext } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import DashboardNavbar from '../../organisms/layout/dashboard/DashboardNavbar';
import DashboardSidebar from '../../organisms/layout/dashboard/DashboardSidebar';
import DashboardFooter from '../../organisms/layout/dashboard/DashboardFooter';
import SideMenu from '../../organisms/layout/dashboard/SideMenu';
// import MenuNavbar from '../../organisms/layout/dashboard/MenuNavbar';
import { Box, AppBar } from '@material-ui/core';
import DashboardHeader from 'components/organisms/layout/dashboard/DashboardHeader';
import { sideMenuProps } from 'feactures/section/pageSlice';
import { useSelector } from 'react-redux';

export const ReloadLayoutContext = createContext();

const DashboardLayout = () => {
  const [reloadStatus, setReloadStatus] = useState(false);
  const sideMenuOpen = useSelector(sideMenuProps);
  // ヘッダーサイドメニュー以外のスタイル
  const DashboardLayoutRoot = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    height: 'auto'
  }));
  const DashboardLayoutContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: sideMenuOpen ? 'calc(100vw - 240px)' : '100vw',
    transition: 'width 0.5s',
    height: 'auto'
  }));
  const DashboardLayoutSidemenu = styled('div')(({ theme }) => ({
    display: 'flex',
    width: sideMenuOpen ? '240px' : '0px',
    height: '100vh',
    transition: 'width 0.5s'
  }));
  const DashboardLayoutOutlet = styled('div')(({ theme }) => ({
    marginTop: '40px'
  }));

  return (
    <>
      <ReloadLayoutContext.Provider
        value={{
          reloadStatus,
          setReloadStatus
        }}
      >
        <DashboardLayoutRoot>
          <DashboardLayoutSidemenu>
            <SideMenu />
          </DashboardLayoutSidemenu>
          <DashboardLayoutContainer>
            {/* ヘッダー */}
            <DashboardHeader />
            <DashboardLayoutOutlet>
              <Outlet />
            </DashboardLayoutOutlet>
            {/* フッター */}
            <DashboardFooter />
          </DashboardLayoutContainer>
        </DashboardLayoutRoot>
      </ReloadLayoutContext.Provider>
    </>
  );
};
export default DashboardLayout;
