/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';
import { redirectFetchUser } from 'feactures/user/userSlice';
import { useDispatch } from 'react-redux';

export const createCheckoutSession = createAsyncThunk(
  'checkout/post',
  async (arg, thunkApi) => {
    const login_id = thunkApi.getState().user.userId;
    const { accountId } = thunkApi.getState().user;
    const { plan_id, channel, period, payment_method } = arg;
    try {
      const res = await client.post(`${apiUrl}create_checkout_session`, {
        login_id: login_id,
        account_id: accountId,
        plan_id: plan_id,
        channel: channel,
        period: period,
        payment_method: payment_method
      });
      const url = res.data.checkout_session_url;
      window.location.href = url;
      // window.open(url, '_blank');
    } catch (error) {
      console.error('checkout_session_error', error);
    }
  }
);

export const createSubscriptionSession = createAsyncThunk(
  'checkout/post',
  async (arg, thunkApi) => {
    // const login_id = thunkApi.getState().user.userId;
    // const { accountId } = thunkApi.getState().user;
    // const { plan_id } = arg;
    const {
      plan,
      period,
      userId,
      password,
      paymentMethod,
      corporateNumber,
      industry
    } = arg;
    const res = await client.post(`${apiUrl}create_subscription_session`, {
      plan,
      period,
      login_id: userId,
      password,
      paymentMethod,
      corporateNumber,
      industry
    });
    const url = res.data.checkout_session_url;
    window.location.href = url;
  }
);

export const confirmCheckoutSession = createAsyncThunk(
  'confirm_checkout/post',
  async (arg, thunkApi) => {
    const { session_id } = arg;
    try {
      const res = await client.post(`${apiUrl}confirm_checkout`, {
        session_id: session_id
      });
    } catch (error) {
      console.error('confirm_sessoin_error', error);
    }
  }
);

const planSlice = createSlice({
  name: 'plan',
  initialState: {
    confirmModal: {
      isOpen: false
    },
    changeModal: {
      isOpen: false
    },
    checkout: {
      isLoading: false
    }
  },
  reducers: {
    openConfirmModal(state, action) {
      state.confirmModal.isOpen = true;
    },
    closeConfirmModal(state, action) {
      state.confirmModal.isOpen = false;
    },
    openChangeModal(state, action) {
      state.changeModal.isOpen = true;
    },
    closeChangeModal(state, action) {
      state.changeModal.isOpen = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createCheckoutSession.fulfilled, (state, action) => {
      state.checkout.isLoading = false;
      console.log('checkout ok');
      console.log('checkout_data', action.payload);
    }),
      builder.addCase(createCheckoutSession.pending, (state, action) => {
        state.checkout.isLoading = true;
      }),
      builder.addCase(createCheckoutSession.rejected, (state, action) => {
        state.checkout.isLoading = false;
        console.log('checkout error');
        console.log('error_data', action.payload);
      });
  }
});

export const confirmModalIsOpen = (state) => state.plan.confirmModal.isOpen;
export const changeModalIsOpen = (state) => state.plan.changeModal.isOpen;
export const checkoutIsLoading = (state) => state.plan.checkout.isLoading;

export const {
  openChangeModal,
  closeChangeModal,
  openConfirmModal,
  closeConfirmModal
} = planSlice.actions;

export default planSlice.reducer;
