import * as React from 'react';
import { Stack, Button, Typography } from '@material-ui/core';
// import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { amber } from '@mui/material/colors';

const ColorButtons = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(amber[300]),
  backgroundColor: amber[300],
  '&:hover': {
    backgroundColor: amber[300]
  }
}));

const ColorButton = (props) => {
  const { children, disabled, onClick } = props; // eslint-disable-line
  return (
    <Stack spacing={2} direction="row">
      <ColorButtons disabled={disabled} onClick={onClick} variant="contained">
        {children}
      </ColorButtons>
    </Stack>
  );
};

export default ColorButton;
