/* eslint-disable */
import * as React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App.js';
import { store } from 'app/store';
import { Provider } from 'react-redux';
import axios from 'axios';
import ScrollToTop from 'components/organisms/layout/ScrollToTop';
import { setError, resetError } from 'feactures/errorSlice';

// Axios インスタンス
const client = axios.create();

// 成功の場合は何もしない
const onSuccess = (response) => {
  // console.log('seikouなのでエラーをリセットします');
  store.dispatch(resetError());
  return response;
};

// エラーの場合はストアにエラーをセットする
const onError = (err) => {
  console.log(err);
  // エラーの中身responseがある場合
  if (err.response) {
    const { status, data } = err.response;
    store.dispatch(setError({ status, data: data.message }));
  } else {
    //エラーの中身なしのとき(flask側のエラー)は
    err.message = 'データがありません';
    store.dispatch(setError({ status, data: err.message }));
  }

  // 関数や Promise オブジェクトなど、JSON にシリアライズできない値を
  // アクションのペイロード（引数）に含めるとエラーになるため、
  // 必要なキーのみ取り出してアクションに渡す
  // const { status, data } = err.response;

  // コンポーネントの外でアクションを呼び出したい場合は、
  // store の dispatch メソッドを利用する
  // store.dispatch(setError({ status, data }));
  // エラーをスローしたい場合は...
  // Promise.reject(err);
};

client.interceptors.response.use(onSuccess, onError);

export default client;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
