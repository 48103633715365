/* eslint-disable */
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useEffect, useState } from 'react';
import { Box, Button, IconButton } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles({
  table: {
    minWidth: 350
  }
});

const CategoryTotalTable = (props) => {
  const { totalsalesData, totalunitData, yokoziku } = props; // eslint-disable-line
  const classes = useStyles();
  const periods = yokoziku;
  const sales = totalsalesData;
  const quantities = totalunitData;

  const combined = periods.map((period, i) => ({
    period,
    sale: sales[i],
    quantity: quantities[i],
    key: i
  }));

  const orderedCombined = combined.slice().sort((a, b) => {
    const dateA = new Date(a.period.replace('/', '-'));
    const dateB = new Date(b.period.replace('/', '-'));
    return dateB - dateA;
  });

  const [orderDesc, setOrderDesc] = useState(true);
  const [checkedColumn, setCheckedColumn] = useState('period');
  const [sortArr, setSortArr] = useState(orderedCombined);

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  }));

  const handleChange = (column) => {
    if (column === checkedColumn && orderDesc === true) {
      //最後に並び替えたカラムと同じカラムで、現在の並び順が降順なので昇順に並び替える
      setOrderDesc(false);
      if (column === 'period') {
        const ascSorted = sortArr.slice().sort((a, b) => {
          const dateA = new Date(a.period.replace('/', '-'));
          const dateB = new Date(b.period.replace('/', '-'));
          return dateA - dateB;
        });
        setSortArr(ascSorted);
      } else {
        const ascSorted = [...sortArr].sort((a, b) => a[column] - b[column]);
        setSortArr(ascSorted);
      }
    } else if (column === checkedColumn && orderDesc === false) {
      //最後に並び替えたカラムと同じカラムで、現在の並び順が昇順なので降順に並び替える
      setOrderDesc(true);
      if (column === 'period') {
        const descSorted = sortArr.slice().sort((a, b) => {
          const dateA = new Date(a.period.replace('/', '-'));
          const dateB = new Date(b.period.replace('/', '-'));
          return dateB - dateA;
        });
        setSortArr(descSorted);
      } else {
        const descSorted = [...sortArr].sort((a, b) => b[column] - a[column]);
        setSortArr(descSorted);
      }
    } else {
      // 新しいカラムを降順に並び替える
      setCheckedColumn(column);
      setOrderDesc(true);
      if (column === 'period') {
        const descSorted = sortArr.slice().sort((a, b) => {
          const dateA = new Date(a.period.replace('/', '-'));
          const dateB = new Date(b.period.replace('/', '-'));
          return dateB - dateA;
        });
        setSortArr(descSorted);
      } else {
        const descSorted = [...sortArr].sort((a, b) => b[column] - a[column]);
        setSortArr(descSorted);
      }
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table" size="small">
        <TableHead sx={{ bgcolor: '#D9D9D9' }}>
          <TableRow>
            <TableCell align="center" sx={{ padding: 0 }}>
              <Button
                onClick={() => {
                  handleChange('period');
                }}
                color="inherit"
                sx={{
                  padding: 0,
                  height: '32.5px',
                  width: '100%',
                  fontWeight: 'bold'
                }}
              >
                期間
                {checkedColumn !== 'period' && (
                  <Box width="20.5px" height="20.5px"></Box>
                )}
                {checkedColumn === 'period' && (
                  <ExpandMore
                    expand={orderDesc}
                    aria-expanded={orderDesc}
                    aria-label="sort"
                    sx={{ padding: 0 }}
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                )}
              </Button>
            </TableCell>
            <TableCell align="center" sx={{ padding: 0 }}>
              <Button
                onClick={() => {
                  handleChange('sale');
                }}
                color="inherit"
                sx={{
                  padding: 0,
                  height: '32.5px',
                  width: '100%',
                  fontWeight: 'bold'
                }}
              >
                売上
                {checkedColumn !== 'sale' && (
                  <Box width="20.5px" height="20.5px"></Box>
                )}
                {checkedColumn === 'sale' && (
                  <ExpandMore
                    expand={orderDesc}
                    aria-expanded={orderDesc}
                    aria-label="sort"
                    sx={{ padding: 0 }}
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                )}
              </Button>
            </TableCell>
            <TableCell align="center" sx={{ padding: 0 }}>
              <Button
                onClick={() => {
                  handleChange('quantity');
                }}
                color="inherit"
                sx={{
                  padding: 0,
                  height: '32.5px',
                  width: '100%',
                  fontWeight: 'bold'
                }}
              >
                販売個数
                {checkedColumn !== 'quantity' && (
                  <Box width="20.5px" height="20.5px"></Box>
                )}
                {checkedColumn === 'quantity' && (
                  <ExpandMore
                    expand={orderDesc}
                    aria-expanded={orderDesc}
                    aria-label="sort"
                    sx={{ padding: 0 }}
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                )}
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortArr.map((element) => (
            <TableRow
              key={element.key}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                '&:nth-of-type(even)': { bgcolor: '#E3E8F4' }
              }}
            >
              <TableCell align="center" component="th" scope="row">
                {element.period}
              </TableCell>
              <TableCell align="right">
                ¥{parseInt(element.sale).toLocaleString()}
              </TableCell>
              <TableCell align="right">
                {parseInt(element.quantity).toLocaleString()}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CategoryTotalTable;
