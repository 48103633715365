/* eslint-disable*/
import { Box, Typography } from '@material-ui/core';

const CategoryTotalSales = (props) => {
  /* eslint-disable*/
  const { sales } = props;
  let total = 0;
  let calcTotal = 0;
  let fixedTotal = 0;
  let tanni = '円';
  const salesIsArray = sales instanceof Array;
  const calculateAverageSalesWithoutZero = (arr) => {
    let sum = 0;
    let count = 0;
    let average = 0;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== 0) {
        sum += arr[i];
        count++;
      }
    }
    if (count === 0) {
      return 0; // ゼロしかない場合はゼロを返す
    }
    average = sum / count;
    return average * arr.length;
  };
  if (salesIsArray) {
    total = calculateAverageSalesWithoutZero(sales);
    if (total >= 1000000000000) {
      calcTotal = total / 1000000000000;
      fixedTotal = calcTotal.toFixed(1);
      tanni = '兆円';
    } else if (total >= 100000000) {
      fixedTotal = Math.round(total / 100000000);
      tanni = '億円';
    } else if (total >= 10000) {
      fixedTotal = Math.round(total / 10000);
      tanni = '万円';
    } else if (total == 0) {
      fixedTotal = 0;
      tanni = '円';
    } else if (total < 10000) {
      fixedTotal = 1;
      tanni = '万円';
    }
  }
  return (
    <Box display="flex" alignItems="end" justifyContent="center">
      <Typography fontSize="30px" color="#758FC8" fontWeight="bold">
        {fixedTotal.toLocaleString()}
      </Typography>
      <Typography fontSize="14px" ml="4px">
        {tanni}/年
      </Typography>
    </Box>
  );
};

export default CategoryTotalSales;
