/* eslint-disable*/
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useState, useEffect, memo, useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Container,
  Link,
  Typography,
  Stepper,
  Step,
  StepLabel
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { userinfo, fetchUserStatus } from 'feactures/user/userSlice';

const confirmHandle = () => {
  let result = confirm(
    '外部ページ【amazon SellerCentral】に遷移してもよろしいですか？'
  );
  if (result) {
    const url =
      'https://sellercentral-japan.amazon.com/ap/signin?clientContext=355-1277058-6537715&openid.return_to=https%3A%2F%2Fsellercentral-japan.amazon.com%2Fapps%2Fauthorize%2Fconsent%3Fapplication_id%3Damzn1.sp.solution.cfdea873-d3d0-427e-a6df-1b8cd26baf93&openid.identity=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.assoc_handle=sc_jp_amazon_com_v2&openid.mode=checkid_setup&openid.claimed_id=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0%2Fidentifier_select&openid.ns=http%3A%2F%2Fspecs.openid.net%2Fauth%2F2.0&mons_redirect=sign_in&ssoResponse=eyJ6aXAiOiJERUYiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiQTI1NktXIn0.-h9DZ6d6DlMbnP-jV-VSVKLsVkA14NzOrmhrimfn1pIXcCrjvRvHMQ._rQ5DJOqogEZ81Ix.sAw-sTOWccturC6cQDb1iYKF0qBcEpX2wlxxF-5k7Il6M3GDsW76REzgELGSCUfkUhVX6TesWqlwQBzMxQyKrKhusdb1T00ARCwCL3CWTnSo8-ZySvr_0-gDhuDc8yz2X7qU8hn9uaLyxmeqpupuAPTb-K-0bpbfZH4xc0lrPXi5pTzEXnu386fnbwILMpw2yJGsI8-DElg.v6n1EmY5WsyVcEJCJZk2dg';
    window.open(url);
  }
};

const steps = [
  'アカウント作成',
  '審査中\n(約2営業日)',
  'データ接続\n(審査期間中も設定可)',
  'Oxcim利用可能'
];
const amazonSteps = [
  '承認設定',
  '承認完了',
  'データ取得中\n(約1時間)',
  '接続完了'
];
const rakutenSteps = [
  'ユーザー追加',
  '承認(弊社)',
  '権限付与',
  'API権限設定',
  '情報登録',
  'データ取得中\n(約1営業日)',
  '接続完了'
];
const yahooSteps = [
  'アカウント作成',
  'ユーザー追加',
  '権限付与\nID連携',
  'Oxcim承認',
  '承認完了',
  'データ取得中\n(約1営業日)',
  '接続完了'
];

const useStyles = makeStyles(() => ({
  speed: {
    position: 'relative',
    alignItems: 'center',
    // display: 'inline-block',
    marginBottom: '13px',
    // padding: '1rem 1rem',
    color: '#929292',
    fontSize: '16px',
    border: 'solid #929292',
    borderWidth: '2px',
    // width: '944px',
    marginTop: '13px',
    background: '#FFF',
    boxSizing: 'border-box',
    '&::before': {
      //中の白い三角形
      content: `''`,
      position: 'absolute',
      top: '-23px',
      left: '63%',
      marginLeft: '-15px',
      border: '12px solid transparent',
      borderBottom: '12px solid #FFF',
      zIndex: 2
    },
    '&::after': {
      //外側の三角形
      content: `''`,
      position: 'absolute',
      top: '-28px',
      left: '63%',
      marginLeft: '-17px',
      border: '14px solid transparent',
      borderBottom: '14px solid #929292',
      zIndex: 1
    }
  }
}));

const ConnectionStatus2 = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(userinfo);
  const amazonStatus = user.amazonStatus;
  const rakutenStatus = user.rakutenStatus;
  const yahooStatus = user.yahooStatus;

  // useEffect(() => {
  //   dispatch(fetchUserStatus());
  // }, []);

  // amazonStatus：
  // 1-3：承認完了、データ取得中
  // 4：データ取得が完了したとき
  // 審査の状況で
  // 5:メール送付完了

  // rakutenStatus  1-2：ユーザー追加の承認が完了したとき(鈴木さん実装分）
  // 3-5：API連携設定が完了したとき　(初回登録完了のメール送信前に5にする)
  // 6：データ取得中のとき(今はまだ手動だが、データ取得中past_report開始時に6)
  // 7：データ取得が完了(データ取得完了時に7にしてかつ審査ステータスをみる。完了時に審査
  // 8: メール送付完了

  // ヤフー：
  // 1-5：承認が完了したとき
  // 6：データ取得中のとき
  // 7：データ取得が完了したとき
  // 8: メール送付完了

  return (
    <Box
      sx={{
        // backgroundColor: 'background.default',
        // minHeight: '100%',
        // width: '1220px',
        display: 'flex'
        // alignItems: 'center'
      }}
    >
      <Container sx={{ width: 'auto' }}>
        {/* <Typography fontSize="16px" fontWeight="bold" mb={2}>
          データ接続状況
        </Typography> */}
        <Box sx={{ mb: 1, fontWeight: 'bold' }}>
          {/* このポップアップは審査中(データ接続中)しかでないのでactiveStepは「接続中」で固定 */}
          <Stepper activeStep={2} alternativeLabel>
            {steps.map((label, index) => (
              <Step
                key={label}
                sx={{
                  '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                    {
                      // color: 'grey.700', // Just text label (COMPLETED)
                      marginTop: '10px'
                    },
                  '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
                    // color: 'grey.700', // Just text label (ACTIVE)
                    marginTop: '10px'
                  },
                  '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                    {
                      color: '#000000', // Just text label (ACTIVE)
                      marginTop: '10px'
                    },
                  '& .MuiStepLabel-root .Mui-active': {
                    color: 'rgba(0, 0, 0, 0.38)'
                  }
                }}
              >
                <StepLabel style={{ whiteSpace: 'pre-line' }}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <div></div>
        <Box sx={{ width: '100%' }} className={classes.speed}>
          <Box
            display="flex"
            justifyContent="space-between"
            borderBottom="1px solid"
            alignItems="center"
          >
            <Box
              width={'185px'}
              display="flex"
              alignItems="center"
              borderRight="1px solid"
              fontSize="13px"
              textAlign="center"
            >
              <Box width="113px">Amazon</Box>
              <Box textAlign="center" fontSize="11px">
                接続方法は
                <br />
                <Link
                  target="_blank"
                  // href="https://www.oxcim.net/"
                  href="https://www.oxcim.net/amazon%E3%83%87%E3%83%BC%E3%82%BF%E6%8E%A5%E7%B6%9A%E6%96%B9%E6%B3%95"
                  variant="h6"
                  // underline="hover"
                >
                  こちら
                </Link>
              </Box>
            </Box>
            <Box flex="1" mt="13px">
              <Stepper activeStep={amazonStatus} alternativeLabel>
                {amazonSteps.map((label, index) => (
                  <Step
                    key={label}
                    sx={{
                      '& .MuiStepLabel-root .Mui-completed': {
                        color: '#517ecb' // circle color (COMPLETED),
                      },
                      '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                        {
                          color: 'grey.700', // Just text label (COMPLETED)
                          fontSize: '7px',
                          marginTop: '10px',
                          whiteSpace: 'pre'
                        },
                      '& .MuiStepLabel-root .Mui-active': {
                        // color: '#517ecb' // circle color (ACTIVE)
                        color: 'rgba(0, 0, 0, 0.38)'
                      },
                      '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                        {
                          color: 'grey.700', // Just text label (ACTIVE)
                          fontSize: '7px',
                          marginTop: '10px',
                          whiteSpace: 'pre'
                        },
                      '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
                        color: 'grey.700', // Just text label (ACTIVE)
                        fontSize: '7px',
                        marginTop: '10px',
                        whiteSpace: 'pre'
                      }
                    }}
                  >
                    <StepLabel style={{ whiteSpace: 'pre-line' }}>
                      {label}
                      <br />
                      {index == 0 && (
                        <Button
                          sx={{
                            p: 0,
                            mt: '3px',
                            borderRadius: '0px',
                            color: 'grey.800',
                            backgroundColor: 'orange',
                            fontSize: '7px',
                            whiteSpace: 'nowrap'
                          }}
                          textDecoration="none"
                          onClick={() => confirmHandle()}
                        >
                          {'承認設定'}
                        </Button>
                      )}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            borderBottom="1px solid"
            alignItems="center"
          >
            <Box
              width={'185px'}
              display="flex"
              alignItems="center"
              borderRight="1px solid"
              fontSize="13px"
              textAlign="center"
            >
              <Box width="113px">楽天市場</Box>
              <Box textAlign="center" fontSize="11px">
                接続方法は
                <br />
                <Link
                  target="_blank"
                  href="https://www.oxcim.net/%E6%A5%BD%E5%A4%A9%E3%83%87%E3%83%BC%E3%82%BF%E6%8E%A5%E7%B6%9A"
                  variant="h6"
                  // underline="hover"
                >
                  こちら
                </Link>
              </Box>
            </Box>
            <Box flex="1" mt="13px">
              <Stepper activeStep={rakutenStatus} alternativeLabel>
                {rakutenSteps.map((label, index) => (
                  <Step
                    key={label}
                    sx={{
                      '& .MuiStepLabel-root .Mui-completed': {
                        color: '#517ecb' // circle color (COMPLETED)
                      },
                      '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                        {
                          color: 'grey.700', // Just text label (COMPLETED)
                          fontSize: '7px',
                          marginTop: '10px',
                          whiteSpace: 'pre'
                        },
                      '& .MuiStepLabel-root .Mui-active': {
                        // color: '#517ecb' // circle color (ACTIVE)
                        color: 'rgba(0, 0, 0, 0.38)'
                      },
                      '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                        {
                          color: 'grey.700', // Just text label (ACTIVE)
                          fontSize: '7px',
                          marginTop: '10px',
                          whiteSpace: 'pre'
                        },
                      '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
                        color: 'grey.700', // Just text label (ACTIVE)
                        fontSize: '7px',
                        marginTop: '10px',
                        whiteSpace: 'pre'
                      }
                    }}
                  >
                    <StepLabel style={{ whiteSpace: 'pre-line' }}>
                      {label}
                      <br />
                      {index == 4 && (
                        <Button
                          sx={{
                            p: 0,
                            px: 1,
                            mt: '3px',
                            borderRadius: '0px',
                            color: 'grey.800',
                            backgroundColor: 'orange',
                            fontSize: '7px',
                            whiteSpace: 'nowrap'
                          }}
                          onClick={() => navigate('/app/sys/rms')}
                        >
                          {'API連携設定'}
                        </Button>
                      )}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              width={'185px'}
              display="flex"
              alignItems="center"
              borderRight="1px solid"
              fontSize="13px"
              textAlign="center"
            >
              <Box width="113px">
                Yahoo!
                <br />
                ショッピング
              </Box>
              <Box textAlign="center" fontSize="11px">
                接続方法は
                <br />
                <Link
                  target="_blank"
                  href="https://www.oxcim.net/%E3%83%A4%E3%83%95%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%83%E3%83%94%E3%83%B3%E3%82%B0%E3%83%87%E3%83%BC%E3%82%BF%E6%8E%A5%E7%B6%9A"
                  variant="h6"
                  // underline="hover"
                >
                  こちら
                </Link>
              </Box>
            </Box>

            <Box flex="1" mt="13px" sx={{ position: 'relative' }}>
              <Box
                textAlign="center"
                backgroundColor="grey.700"
                color="#ffff"
                width="95%"
                height="80%"
                pt={2}
                sx={{
                  position: 'absolute',
                  opacity: '0.5',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  margin: 'auto'
                }}
                zIndex={5}
              >
                現在データ接続停止中
              </Box>

              <Stepper alternativeLabel>
                {yahooSteps.map((label, index) => (
                  <Step
                    key={label}
                    sx={{
                      '& .MuiStepLabel-root .Mui-completed': {
                        color: '#517ecb' // circle color (COMPLETED)
                      },
                      '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                        {
                          color: 'grey.700', // Just text label (COMPLETED)
                          fontSize: '7px',
                          marginTop: '10px',
                          whiteSpace: 'pre'
                        },
                      '& .MuiStepLabel-root .Mui-active': {
                        // color: '#517ecb' // circle color (ACTIVE)
                        color: 'rgba(0, 0, 0, 0.38)'
                      },
                      '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                        {
                          color: 'grey.700', // Just text label (ACTIVE)
                          fontSize: '7px',
                          marginTop: '10px',
                          whiteSpace: 'pre'
                        },
                      '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
                        color: 'grey.700', // Just text label (ACTIVE)
                        fontSize: '7px',
                        marginTop: '10px',
                        whiteSpace: 'pre'
                      }
                    }}
                  >
                    <StepLabel style={{ whiteSpace: 'pre-line' }}>
                      {label}
                      <br />
                      {index == 3 && (
                        <Button
                          sx={{
                            p: 0,
                            px: 1,
                            mt: '3px',
                            borderRadius: '0px',
                            color: 'grey.800',
                            backgroundColor: 'orange',
                            fontSize: '7px',
                            whiteSpace: 'nowrap'
                          }}
                          onClick={() => navigate('/app/sys/yahoo')}
                        >
                          {'API連携設定'}
                        </Button>
                      )}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Box>
        </Box>
        {/* </StyledTooltip> */}

        <Box
          id="alert-dialog-description"
          style={{
            whiteSpace: 'pre-line',
            color: '#000000',
            fontSize: '0.8rem'
          }}
        >
          【ご留意点】
          <br />
          <Typography>
            ・審査完了まで約2営業日かかります。データ接続をしても審査落ちとなるとご利用ができませんので、ご注意ください。
          </Typography>
          <Typography>
            　審査完了後は、
            <Link variant="h6" underline="hover" sx={{ pr: 1 }}>
              system@oxcim.jp
            </Link>
            からご連絡させていただきます。
          </Typography>
          <Typography>
            　※弊社及び弊社グループ各社と同様又は類似のサービスを運営されている場合、弊社独自の判断でご利用お断りすることがございます。
          </Typography>
          <br />
          <Typography>
            ・稀にご案内メールが迷惑メールフォルダに入ってしまう場合がございますのでメールが届かない場合は迷惑メールフォルダをご確認ください。
            <br />
            　※件名：【Oxcim】アカウント申込み完了のお知らせ
            <br />
          </Typography>
          <br />
          その他気になる点がございましたらお手数ではございますが、「
          <span style={{ textDecoration: 'underline', color: 'blue' }}>
            introduction_oxcim@mg.opt.ne.jp
          </span>
          」までご連絡下さい。
          <br />
          <br />
          <Typography color="text.secondary" variant="h5" textAlign="center">
            <Link
              component={RouterLink}
              to="/login"
              variant="h5"
              underline="hover"
            >
              ログイン画面にもどる
            </Link>
          </Typography>
        </Box>
        {/* </Box> */}
      </Container>
    </Box>
  );
};

export default ConnectionStatus2;
