/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { apiUrl } from 'App';

export const fetchValidCategoryList = createAsyncThunk(
  '/validCategory/post',
  async (arg, thunkAPI) => {
    const response = await axios.post(`${apiUrl}display_category`);
    return response.data;
  }
);

const validCategoryListSlice = createSlice({
  name: 'validCategoryList',
  initialState: {
    validAmazonCategories: [],
    validRakutenCategories: [],
    validYahooCategoryList: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchValidCategoryList.fulfilled, (state, action) => {
      return {
        ...state,
        validAmazonCategories: action.payload.amazon,
        validRakutenCategories: action.payload.rakuten,
        validYahooCategoryList: action.payload.yahoo
      };
    });
  }
});

export const validAmazonCategoryList = (state) =>
  state.validCategoryList.validAmazonCategories;
export const validRakutenCategoryList = (state) =>
  state.validCategoryList.validRakutenCategories;
export const validYahooCategoryList = (state) =>
  state.validCategoryList.validYahooCategories;

export default validCategoryListSlice.reducer;
