/* eslint-disable*/
//フッター
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userinfo, fetchLogout } from 'feactures/user/userSlice';
import { Link } from 'react-router-dom';
import { Toolbar, Typography, Box } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';

const DashboardFooter = () => {
  const { pathname } = useLocation();

  const isExcludedPath = (path) =>
    [
      '/premium_register',
      '/premiumregister/complete',
      '/trial_premium_register',
      '/trial_campaign_register/complete',
      '/trial_register',
      '/trialregister/complete'
    ].includes(path);

  return (
    <Toolbar>
      <Box sx={{ flexGrow: 1 }} />

      {!isExcludedPath(pathname) &&
        (pathname !== '/s/tos' ? (
          <Link
            to="/s/tos"
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <Typography variant="h7" maxWidth="sm" sx={{ color: '#6b778c' }}>
              Oxcim利用規約
            </Typography>
          </Link>
        ) : (
          <button
            style={{ textDecoration: 'none' }}
            onClick={() => window.close()}
          >
            ←戻る
          </button>
        ))}

      <Box sx={{ flexGrow: 1 }} />
    </Toolbar>
  );
};

// DashboardFooter.propTypes = {
//   onMobileNavOpen: PropTypes.func
// };

export default DashboardFooter;
