/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const fetchGroupSummaryPost = createAsyncThunk(
  'group_summary/post',
  async (arg, thunkAPI) => {
    const { term, start, end } = await thunkAPI.getState().date;
    const { userId } = thunkAPI.getState().user;
    const { channel, benchmark } = thunkAPI.getState().currentchannel;
    const { checkGroupList } = thunkAPI.getState().group;

    const res = await client.post(`${apiUrl}group_summary`, {
      start: start,
      end: end,
      term: term,
      channel: channel, //検討
      user_id: userId,
      group_list: checkGroupList,
      comparing_type: Number(benchmark)
    });
    // console.log(res.data);
    return res.data;
  }
);

const initialState = [
  {
    group_id: 5,
    group_name: 'グループA',
    sales: 400210,
    salesRate: 2,
    preSales: 39220,
    views: 7200,
    viewsRate: 2,
    preViews: 7120,
    cvr: 1.5,
    cvrRate: 2,
    preCvr: 1.4,
    price: 4100,
    priceRate: 2,
    prePrice: 39220
  },
  {
    group_id: 4,
    group_name: 'グループB',
    sales: 311100,
    salesRate: 30,
    preSales: 216770,
    views: 3000,
    viewsRate: 2,
    preViews: 7120,
    cvr: 3.2,
    cvrRate: 2,
    preCvr: 3.0,
    price: 45800,
    priceRate: 2,
    prePrice: 42700
  },
  {
    group_id: 5,
    group_name: 'グループC',
    sales: 20000,
    salesRate: -8,
    preSales: 217390,
    views: 1000,
    viewsRate: -10,
    preViews: 920,
    cvr: 3.2,
    cvrRate: 2,
    preCvr: 3.0,
    price: 39800,
    priceRate: -10,
    prePrice: 41391
  },
  {
    group_id: 4,
    group_name: 'グループD',
    sales: 20000,
    salesRate: -2,
    preSales: 39220,
    views: 9000,
    viewsRate: 2,
    preViews: 8000,
    cvr: 1.5,
    cvrRate: 2,
    preCvr: 1.4,
    price: 1200,
    priceRate: 2,
    prePrice: 1200
  }
];

const groupSummarySlice = createSlice({
  name: 'groupSummary',
  initialState: {
    amazon: initialState,
    rakuten: initialState,
    yahoo: initialState
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGroupSummaryPost.fulfilled, (state, action) => {
      if (action.payload.kpi.channel == 'amazon') {
        return {
          amazon: {
            ...state.amazon,
            // salesGraph: action.payload.group_summamry.sales,
            // viewsGraph: action.payload.group_summamry.views,
            // CVRGraph: action.payload.group_summamry.CVR,
            // priceGraph: action.payload.group_summamry.price,

            // //競合
            // r_sales: action.payload.group_summamry.rival_sales,
            // r_views: action.payload.group_summamry.rival_views,
            // r_cvr: action.payload.group_summamry.rival_CVR,
            // r_price: action.payload.group_summamry.rival_price,

            // rate: action.payload.kpi.rate,
            // num: action.payload.kpi.num,
            isLoading: false,
            error: false
          },
          rakuten: { ...state.rakuten },
          yahoo: { ...state.yahoo },
          isLoading: false,
          error: false
        };
      }
      if (action.payload.kpi.channel == 'rakuten') {
        return {
          rakuten: {
            ...state.rakuten,
            salesGraph: action.payload.kpi.sales,
            viewsGraph: action.payload.kpi.views,
            CVRGraph: action.payload.kpi.CVR,
            priceGraph: action.payload.kpi.price,

            //競合
            g_sales: action.payload.group_kpi.sales,
            g_views: action.payload.group_kpi.views,
            g_cvr: action.payload.group_kpi.CVR,
            g_price: action.payload.group_kpi.price,

            rate: action.payload.kpi.rate,
            num: action.payload.kpi.num,
            isLoading: false,
            error: false
          },
          amazon: { ...state.amazon },
          yahoo: { ...state.yahoo },
          isLoading: false,
          error: false
        };
      }
      if (action.payload.kpi.channel == 'yahoo') {
        return {
          yahoo: {
            ...state.yahoo,
            salesGraph: action.payload.kpi.sales,
            viewsGraph: action.payload.kpi.views,
            CVRGraph: action.payload.kpi.CVR,
            priceGraph: action.payload.kpi.price,

            //競合
            g_sales: action.payload.group_kpi.sales,
            g_views: action.payload.group_kpi.views,
            g_cvr: action.payload.group_kpi.CVR,
            g_price: action.payload.group_kpi.price,

            rate: action.payload.kpi.rate,
            num: action.payload.kpi.num,
            isLoading: false,
            error: false
          },
          rakuten: { ...state.rakuten },
          amazon: { ...state.amazon },
          isLoading: false,
          error: false
        };
      }
    });
    builder.addCase(fetchGroupSummaryPost.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        error: false
      };
    });
    builder.addCase(fetchGroupSummaryPost.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: true
      };
    });
  }
});

export const amazonGroupSummary = (state) => state.groupSummary.amazon;
export const rakutenGroupSummary = (state) => state.groupSummary.rakuten;
export const yahooGroupSummary = (state) => state.groupSummary.yahoo;

export default groupSummarySlice.reducer;
