/* eslint-disable  */
import { Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';
import {
  brandList,
  ownBrandList,
  ownCheckedB,
  ownSelectedBrand,
  setOwnCheckedBrandList
} from 'feactures/brand/brandSlice';
import { currentchannel } from 'feactures/channel/channelSlice';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const OwnBrandSelectCheckbox = (props) => {
  const { section } = props;
  const dispatch = useDispatch();
  const channel = useSelector(currentchannel);
  const allBrandList = useSelector(brandList);
  const allOwnBrandList = useSelector(ownBrandList);
  const selectOwnBrand = useSelector(ownSelectedBrand);
  const checkedOwnBrand = useSelector(ownCheckedB);
  let chBrandList = [];
  let current_channel_checked_brand = '';
  if (channel == 1) {
    chBrandList = allOwnBrandList.amazon;
    current_channel_checked_brand = checkedOwnBrand.amazon.brand;
  } else if (channel == 2) {
    chBrandList = allBrandList.rakuten;
    current_channel_checked_brand = checkedOwnBrand.rakuten.brand;
  } else if (channel == 3) {
    chBrandList = allBrandList.yahoo;
    current_channel_checked_brand = checkedOwnBrand.yahoo.brand;
  }

  const [checked, setChecked] = useState(
    chBrandList.map((item) => ({
      brand: item.brand_name,
      isChecked: current_channel_checked_brand === item.brand_name
    }))
  );

  useEffect(() => {
    if (channel == 1) {
      chBrandList = allOwnBrandList.amazon;
    } else if (channel == 2) {
      chBrandList = allBrandList.rakuten;
    } else if (channel == 3) {
      chBrandList = allBrandList.yahoo;
    }

    setChecked(
      chBrandList.map((item) => ({
        brand: item.brand_name,
        isChecked: current_channel_checked_brand === item.brand_name
      }))
    );
  }, [allOwnBrandList]);

  useEffect(() => {
    setChecked(
      chBrandList.map((item) => ({
        brand: item.brand_name,
        isChecked: current_channel_checked_brand === item.brand_name
      }))
    );
  }, [selectOwnBrand, checkedOwnBrand]);

  const handleChange = (e, brand) => {
    if (e.target.checked) {
      dispatch(setOwnCheckedBrandList({ channel, list: { brand: brand } }));
    } else {
      dispatch(setOwnCheckedBrandList({ channel, list: '' }));
    }
  };

  // チャンネルを変えて戻ってきてもチェックが入るようにする
  useEffect(() => {
    if (channel == 1) {
      checked.forEach(function (elem) {
        if (selectOwnBrand.amazon.brand === elem.brand) {
          elem.isChecked = true;
        }
      });
    } else if (channel == 2) {
      checked.forEach(function (elem) {
        if (selectOwnBrand.rakuten.brand === elem.brand) {
          elem.isChecked = true;
        }
      });
    } else if (channel == 3) {
      checked.forEach(function (elem) {
        if (selectOwnBrand.yahoo.brand === elem.brand) {
          elem.isChecked = true;
        }
      });
    }
    setChecked(checked);
  }, [channel]);

  return (
    <div>
      <FormGroup>
        {checked.map((item, index) => {
          return (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={item.isChecked}
                  onClick={(e) => handleChange(e, item.brand)}
                  name={item.brand}
                />
              }
              label={item.brand}
            />
          );
        })}
      </FormGroup>
    </div>
  );
};

export default OwnBrandSelectCheckbox;
