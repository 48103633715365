/* eslint-disable*/

import { Container, Grid, colors, Typography } from '@material-ui/core'; // eslint-disable-line
import { useSelector } from 'react-redux';
import {
  selectAmazonCategoryGraph,
  selectRakutenCategoryGraph,
  selectYahooCategoryGraph,
  categorygraphStatus
} from 'feactures/category/categoryGraphSlice';
import {
  amazonCategory,
  rakutenCategory,
  yahooCategory
} from 'feactures/category/categorySlice';
import { currentchannel } from 'feactures/channel/channelSlice';
import MarketLabel from 'components/molecules/dashboard/MarketLabel';
import CategoryTotalTable from 'components/molecules/dashboard/CategoryTotalTable';

const MarketTable = (props) => {
  // 実際のデータはuseEffectで取得しreduxへ
  const { yokoziku } = props;
  let categoryAmazonGraphData = useSelector(selectAmazonCategoryGraph);
  let categoryRakutenGraphData = useSelector(selectRakutenCategoryGraph);
  const categoryYahooGraphData = useSelector(selectYahooCategoryGraph);
  const amazonCategories = useSelector(amazonCategory);
  const rakutenCategories = useSelector(rakutenCategory);
  const yahooCategories = useSelector(yahooCategory);
  const loading = useSelector(categorygraphStatus);
  const channel = useSelector(currentchannel);
  let yoko = [...yokoziku];

  let categoryGraphData = {
    graphdata: [],
    totalsalesData: '',
    totalunitData: '',
    selectCategoryLabel: '',
    lastmonthRate: '',
    lastyearRate: ''
  };
  let mall = '';
  let color = '';
  let categories = '';

  if (channel == 1) {
    const targetDate = '2023/2';
    const index = yokoziku.findIndex((date) => date === targetDate);
    if (index !== -1 && categoryAmazonGraphData.totalsalesData.length > 0) {
      yoko.splice(0, index + 1);
      let newData = JSON.parse(JSON.stringify(categoryAmazonGraphData)); // オブジェクト全体をコピー
      newData.totalsalesData.splice(0, index + 1); // graphdataを変更
      newData.totalunitData.splice(0, index + 1); // graphdataを変更
      categoryAmazonGraphData = newData; // 新しいオブジェクトを代入
    }

    categoryGraphData = categoryAmazonGraphData;
    mall = 'Amazon';
    color = colors.amber[700];
    categories = amazonCategories;
    // console.log(categoryGraphData);
  } else if (channel == 2) {
    const targetDate = '2023/7';
    const index = yokoziku.findIndex((date) => date === targetDate);
    if (index !== -1 && categoryRakutenGraphData.totalsalesData.length > 0) {
      yoko.splice(0, index + 1);
      let newData = JSON.parse(JSON.stringify(categoryRakutenGraphData)); // オブジェクト全体をコピー
      newData.totalsalesData.splice(0, index + 1); // graphdataを変更
      newData.totalunitData.splice(0, index + 1); // graphdataを変更
      categoryRakutenGraphData = newData; // 新しいオブジェクトを代入
    }

    categoryGraphData = categoryRakutenGraphData;
    mall = '楽天市場';
    color = '#bf0000';
    categories = rakutenCategories;
    // console.log(categoryGraphData);
  } else if (channel == 3) {
    categoryGraphData = categoryYahooGraphData;
    mall = 'Yahoo';
    color = '#FF0027';
    categories = yahooCategories;
  }

  return (
    <>
      <Container maxWidth={false}>
        <Grid container rowSpacing={0.3} columnSpacing={1.5} padding={1} my={1}>
          <>
            <Grid itemxs={12} mb={1}>
              {/* <MarketLabel /> */}
              <Typography fontSize="14px" fontWeight="bold">
                市場規模
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {/* テーブル */}
              <CategoryTotalTable
                totalsalesData={categoryGraphData.totalsalesData}
                totalunitData={categoryGraphData.totalunitData}
                yokoziku={yoko}
              />
            </Grid>
          </>
        </Grid>
      </Container>
    </>
  );
};

export default MarketTable;
