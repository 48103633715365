/* eslint-disable*/
import * as React from 'react';
import Box from '@material-ui/core/Box';
// import DatePicker from '@mui/lab/DatePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import DateFnsUtils from '@date-io/date-fns';
// import jaLocale from 'date-fns/locale/ja';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import TextField from '@mui/material/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useState, useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
import {
  changeYearMonth,
  resetKubunYearMonth,
  currentmonth,
  currentlastmonth,
  amazonCurrentlastmonth
  // lastmonth
} from 'feactures/section/sectionSlice';
import * as moment from 'moment/moment';
import 'moment/locale/ja';
import {
  fetchCategoryGraphData,
  categoryLabel
} from 'feactures/category/categoryGraphSlice';
import { fetchCategoryRankData } from 'feactures/category/categoryRankSlice';
import { fetchAsyncMyproducts } from 'feactures/product/myproductSlice';
import { fetchAsyncOtherproducts } from 'feactures/product/otherproductSlice';
import { fetchCategoryNo1Sales } from 'feactures/category/categoryNo1Slice';

const dispatchTable = {
  ownProduct: [fetchAsyncMyproducts()],
  otherProduct: [fetchAsyncOtherproducts()],
  categoryTop100: [fetchCategoryGraphData(), fetchCategoryRankData()],
  categoryNo1: [fetchCategoryNo1Sales()]
  // } else if (page == 'otherProduct') {
  //   dispatch(fetchAsyncOtherproducts());
  // } else if (page == 'categoryTop100') {
  //   dispatch(fetchCategoryGraphData()); //100商品をもらう
  //   dispatch(fetchCategoryRankData());　//グラフデータをもらう　一緒でいい、、
  // ownProduct:

  // kpi: fetchProcessKPIPost(),
  // views: fetchViewsPost(),
  // cvr: fetchCVRPost(),
  // customerUnitPrice: fetchUnitPricePost(),
  // keywordsDetail: fetchAsyncKeywordsDetail(),
  // asin: fetchAsyncAsin({
  //   // startDate: asinSdate,
  //   // endDate: asinEdate,
  //   // term: asinAlignment,
  //   // asinTitle: asinGraphTitle,
  //   // pageNum: 1
  // }),
  // tracking: fetchAsyncTrackingWords(),
  // advertise: fetchAsyncAdvertiseData({ graphTitle })
};

const PullDown = (props) => {
  const dispatch = useDispatch();
  let yokoziku = [];
  const { page, label, setYokoziku } = props;
  const selectMonthDate = useSelector(currentmonth);
  const lastmonth = useSelector(amazonCurrentlastmonth);

  const lastMonth = moment().add(-1, 'month');
  let selectMonth = []; //選択年月リスト
  const start = moment('2022-06');
  for (let d = start; start.diff(lastMonth) <= 0; ) {
    selectMonth.push(d.format('YYYY-MM'));
    d.add(1, 'month');
  }
  selectMonth = selectMonth.reverse();

  const list_13_term = (endmonth) => {
    yokoziku = [];
    var start = moment(endmonth).subtract(12, 'month'),
      end = moment(endmonth);
    while (start.diff(end) <= 0) {
      yokoziku.push(start.format('Y/M'));
      start.add(1, 'month');
    }
    setYokoziku(yokoziku);
  };

  useEffect(() => {
    //初期表示　区分：直接　年月：先月
    dispatch(resetKubunYearMonth());
    list_13_term(lastmonth);
  }, [dispatch]);

  const handleChange = (e) => {
    list_13_term(e.target.value);
    dispatch(changeYearMonth(e.target.value));
    [...dispatchTable[page]].map((r, index) => dispatch(r)); //dispatchTableのreducerの数だけdispatchさせる
  };

  return (
    <Box sx={{ minWidth: 120, width: '180px' }} mb={1}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectMonthDate}
          label={label}
          onChange={handleChange}
        >
          {selectMonth.map((yearMonth, index) => {
            return (
              <MenuItem value={yearMonth} key={index}>
                {yearMonth}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};
export default PullDown;
