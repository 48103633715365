/* eslint-disable*/
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListSubheader,
  ListItemText,
  Collapse
} from '@material-ui/core';
import { ExpandLess, ExpandMore, StarBorder } from '@material-ui/icons';

import {
  BarChart as BarChartIcon,
  Grid as GridIcon,
  Home as HomeIcon,
  Monitor as MonitorIcon
} from 'react-feather';
// import SsidChartRoundedIcon from '@mui/icons-material/SsidChartRounded';

const NestedList = () => {
  const [openMarket, setOpenMarket] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [openmonitoring, setOpenmonitoring] = useState(false);
  const [openProcess, setOpenProcess] = useState(false);
  const [openItems, setOpenItems] = useState(false);

  const marketClick = () => {
    setOpenMarket(!openMarket);
    // setOpen3(!open3);
  };
  const monitoringKPIClick = () => {
    setOpenmonitoring(!openmonitoring);
  };

  const categoryClick = () => {
    setOpenCategory(!openCategory);
  };

  const itemsClick = () => {
    setOpenItems(!openItems);
    // setOpen3(!open3);
  };

  const navigate = useNavigate();

  const toMarket = () => {
    navigate('/app/marketboard');
  };
  const toCategory = () => {
    navigate('/app/categoryboard');
  };
  const toOwnProduct = () => {
    navigate('/app/ownproductboard');
  };
  const toOtherProduct = () => {
    navigate('/app/otherproductboard');
  };

  const tomonitoring = () => {
    // setOpenProcess(!openProcess);
    navigate('/app/monitoring');
  };

  const toKeywords = () => {
    // setOpenProcess(!openProcess);
    navigate('/app/keywords');
  };

  const toProcessKPI = () => {
    setOpenProcess(!openProcess);
    navigate('/app/processkpi');
  };
  const toNumberViews = () => {
    navigate('/app/numberviews');
  };
  const toCVR = () => {
    navigate('/app/cvr');
  };
  const toCustomerUnitPrice = () => {
    navigate('/app/unitprice');
  };

  const toExample = () => {
    navigate('/app/example');
  };

  return (
    <>
      <List
        sx={{ width: '100%', maxWidth: 360 }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={tomonitoring}>
          <ListItemIcon sx={{ color: '#ffffff' }}>
            <MonitorIcon />
          </ListItemIcon>
          <ListItemText primary="モニタリング" />
        </ListItemButton>

        {/* ListItemIconはアイコンを挟むためのもの */}
        <ListItemButton onClick={toProcessKPI}>
          {/* <ListItemIcon sx={{ color: '#ffffff' }}>
            <MonitorIcon />
          </ListItemIcon> */}
          <ListItemText primary="プロセスKPI" />
          {openProcess ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        {/* <Collapse in={openProcess} timeout="auto" unmountOnExit> */}
        <Collapse in={openProcess} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} onClick={toNumberViews}>
              <ListItemIcon>{/* <StarBorder /> */}</ListItemIcon>
              <ListItemText primary="閲覧数" />
            </ListItemButton>

            <ListItemButton sx={{ pl: 4 }} onClick={toCVR}>
              <ListItemIcon>{/* <StarBorder /> */}</ListItemIcon>
              <ListItemText primary="CVR" />
            </ListItemButton>

            <ListItemButton sx={{ pl: 4 }} onClick={toCustomerUnitPrice}>
              <ListItemIcon>{/* <StarBorder /> */}</ListItemIcon>
              <ListItemText primary="販売価格" />
            </ListItemButton>
          </List>
        </Collapse>
        {/* </Collapse> */}

        <ListItemButton onClick={toKeywords}>
          <ListItemText primary="キーワード登録" />
        </ListItemButton>

        <ListItemButton onClick={marketClick}>
          <ListItemIcon sx={{ color: '#ffffff' }}>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary="市場データ" />
          {openMarket ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={openMarket} timeout="auto" unmountOnExit>
          {/* <ListItemButton onClick={toMarket}>
            <ListItemText primary="マーケット" />
          </ListItemButton> */}

          <ListItemButton onClick={categoryClick}>
            <ListItemIcon>{/* <GridIcon /> */}</ListItemIcon>
            <ListItemText primary="カテゴリ" />
            {openCategory ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={openCategory} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }} onClick={toCategory}>
                <ListItemIcon>{/* <StarBorder /> */}</ListItemIcon>
                <ListItemText primary="指定カテゴリ" />
              </ListItemButton>
            </List>
          </Collapse>

          <ListItemButton onClick={itemsClick}>
            <ListItemIcon>{/* <HomeIcon /> */}</ListItemIcon>
            <ListItemText primary="商品" />
            {openItems ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={openItems} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }} onClick={toOwnProduct}>
                <ListItemIcon>{/* <StarBorder /> */}</ListItemIcon>
                <ListItemText primary="自社商品" />
              </ListItemButton>

              <ListItemButton sx={{ pl: 4 }} onClick={toOtherProduct}>
                <ListItemIcon>{/* <StarBorder /> */}</ListItemIcon>
                <ListItemText primary="競合商品" />
              </ListItemButton>
            </List>
          </Collapse>
        </Collapse>

        {/* 
        <ListItemButton onClick={toExample}>
          <ListItemIcon>
            <GridIcon />
          </ListItemIcon>
          <ListItemText primary="Example" />
        </ListItemButton> */}
      </List>
    </>
  );
};

export default NestedList;
