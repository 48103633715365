const Logo = (props) => (
  <img
    width="120"
    // height="52"
    alt="Logo"
    src="/static/logo_oxcim.png"
    {...props}
  />
);

export default Logo;
