/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const fetchAsyncKeywordsDetail = createAsyncThunk(
  'keywords/post',
  async (arg, thunkAPI) => {
    const { term, start, end } = await thunkAPI.getState().date;
    const { channel } = thunkAPI.getState().currentchannel;
    const { accountId, accountName, userId, isLoggedIn, userName } =
      thunkAPI.getState().user;
    const { checkGroupList } = thunkAPI.getState().group;

    const res = await client.post(`${apiUrl}keywords_detail`, {
      //KWグラフ値準備できたらurl変更する
      user_id: userId,
      account_id: accountId,
      start: start,
      end: end,
      term: term,
      group_list: checkGroupList,
      channel: channel
    });
    return res.data;
  }
);

// export const fetchAsyncKeywords = createAsyncThunk(
//   'keywords/get',
//   async (_, thunkAPI) => {
//     const { accountId, accountName, userId, isLoggedIn, userName } =
//       thunkAPI.getState().user;
//     const res = await client.post(`${apiUrl}keywords`, {
//       user_id: userId,
//       account_id: accountId
//     });
//     return res.data;
//   }
// );

export const registerKeywords = createAsyncThunk(
  'regitster_keywords/post',
  async (arg, thunkAPI) => {
    const { keyword } = arg;
    const { userId, accountId } = thunkAPI.getState().user;
    const { channel, benchmark } = thunkAPI.getState().currentchannel;
    const res = await client.post(`${apiUrl}register_keywords`, {
      user_id: userId,
      account_id: accountId,
      keyword: keyword,
      channel: channel
    });
    return res.data;
  }
);

//過去分取得処理 重い
export const registerKeywordsInsertData = createAsyncThunk(
  'regitster_keywords_insert_data/post',
  async (arg, thunkAPI) => {
    const { keyword } = arg;
    const { userId, accountId } = thunkAPI.getState().user;
    const { channel } = thunkAPI.getState().currentchannel;
    const res = await client.post(`${apiUrl}register_keywords_insert_data `, {
      user_id: userId,
      account_id: accountId,
      keyword: keyword,
      channel: channel
    });
    return res.data;
  }
);

export const deleteKeywords = createAsyncThunk(
  'delete_keywords/post',
  async (arg, thunkAPI) => {
    const { checkList } = arg;
    const { userId, accountId } = thunkAPI.getState().user;
    const res = await client.post(`${apiUrl}delete_keywords`, {
      user_id: userId,
      account_id: accountId,
      delete_id: checkList
    });
    return res.data;
  }
);

const keywordsSlice = createSlice({
  name: 'keywords',
  initialState: {
    keywordsDetail: [],
    isLoading: false,
    registerLoading: false
  },
  reducers: {
    addKeyword: (state, action) => {
      const { name } = action.payload;
      state.keywordsDetail.push(name);
    },
    removeKeywords: (state, action) => {
      const { checkList } = action.payload;
      state.keywordsDetail = state.keywordsDetail.filter(
        (item) => !checkList.includes(item.id)
      );
    }
  },
  extraReducers: (builder) => {
    // builder.addCase(fetchAsyncKeywords.fulfilled, (state, action) => {
    //   return {
    //     ...state,
    //     keywords: action.payload.keywords,
    //     isLoading: false
    //   };
    // });
    // builder.addCase(fetchAsyncKeywords.pending, (state, action) => {
    //   return {
    //     ...state,
    //     isLoading: true
    //   };
    // });
    // builder.addCase(fetchAsyncKeywords.rejected, (state, action) => {
    //   return {
    //     ...state,
    //     isLoading: false,
    //     registerLoading: false
    //   };
    // });
    builder.addCase(registerKeywords.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        // keywordsDetail: action.payload.keywords_info,
        registerLoading: false
      };
    });
    builder.addCase(registerKeywords.pending, (state, action) => {
      return {
        ...state,
        registerLoading: true
      };
    });
    builder.addCase(registerKeywords.rejected, (state, action) => {
      return {
        ...state,
        registerLoading: false
      };
    });
    builder.addCase(deleteKeywords.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    });
    builder.addCase(deleteKeywords.pending, (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    });
    builder.addCase(deleteKeywords.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    });
    builder.addCase(fetchAsyncKeywordsDetail.fulfilled, (state, action) => {
      return {
        ...state,
        keywordsDetail: action.payload.keywords_info,
        isLoading: false
      };
    });
    builder.addCase(fetchAsyncKeywordsDetail.pending, (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    });
  }
});

// export const { setError, resetError } = errorSlice.actions;
// export const errorResMessage = (state) => state.error.errorMessage;

export const { removeKeywords } = keywordsSlice.actions;
export const keywordsStatus = (state) => state.keywords.isLoading;
export const keywordsResisterStatus = (state) => state.keywords.registerLoading;

export const keywordsDetail = (state) => state.keywords.keywordsDetail;

export default keywordsSlice.reducer;
