/* eslint-disable*/
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import ItemBox from './ItemBox';
import { useSelector } from 'react-redux';
import {
  performancereviewOtherDataLoading,
  performancereviewOwnDataLoading
} from 'feactures/performancereview/performancereviewSlice';
import MeasuresButtongroup from 'components/atoms/group/MeasuresButtongroup';
import PerformancePullDown from 'components/atoms/button/PerformancePullDown';

const TotalScore = () => {
  const ownLoading = useSelector(performancereviewOwnDataLoading);
  const otherLoading = useSelector(performancereviewOtherDataLoading);
  // let isLoading = false;
  // if (ownLoading || otherLoading) {
  //   isLoading = true;
  // } else {
  //   isLoading = false;
  // }
  return (
    <Box>
      <Box
        width="100%"
        bgcolor="#FFFFFF"
        display="flex"
        borderRadius={3}
        p={2}
        mb={2}
        sx={{
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'column'
        }}
        minHeight="200px"
      >
        <Box
          width={'100%'}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems="center"
          pl={1}
        >
          <Typography
            fontWeight={'bold'}
            fontSize={'18px'}
            color="rgba(113,140,198,1)"
          >
            ■総合スコア
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gap={1}
          >
            <MeasuresButtongroup amazonOnly={true} page="performanceReview" />
            <PerformancePullDown graphLoading={ownLoading || otherLoading} />
          </Box>
        </Box>
        {ownLoading || otherLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="200px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box width="100%" display="flex" justifyContent="space-between">
            {/* 自社 */}
            <ItemBox section="own" />
            {/* 競合 */}
            <ItemBox section="other" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TotalScore;
