/* eslint-disable*/
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState, memo, useRef } from 'react';
import { Box, Typography, Link } from '@material-ui/core'; // eslint-disable-line

const RegisterComplete = memo(() => {
  const modoru = () => {
    navigate(-1);
  };

  const once = useRef(true);

  // <!-- Event snippet for 問い合わせ conversion page -->
  useEffect(() => {
    // console.log(location.pathname);
    if (!window.gtag) return;
    window.gtag('event', 'conversion', {
      send_to: 'AW-10908079243/0HU8CI7J6cADEIupsNEo'
    });
  }, []);

  return (
    <>
      <Box xs={12} textAlign="center" backgroundColor="#F7FCFF" pt={5}>
        <Typography variant="h1" fontWeight={'bold'}>
          お申し込みありがとうございました
        </Typography>
        <Typography variant="h6" mb={3}>
          入力されたメールアドレスに【申込受付完了メール】を送信しました。このページは約3秒後に自動的に移動します。
          <br />
          もし、移らない場合は恐れ入りますが、
          <Link
            component={RouterLink}
            to="/app/monitoring"
            variant="h5"
            underline="hover"
          >
            こちら
          </Link>
          をクリックしてください。
        </Typography>
      </Box>
    </>
  );
});

export default RegisterComplete;
