/* eslint-disable*/

import { Container, Typography } from '@material-ui/core'; // eslint-disable-line
import MallSharegraph from 'components/molecules/dashboard/MallSharegraph';
import { sales_share_graph } from 'feactures/overview/overviewSlice';
import { useSelector } from 'react-redux';

const SalesshareByMall = (props) => {
  const { yokoziku } = props;
  const graphdata = useSelector(sales_share_graph);

  return (
    <Container maxWidth={false}>
      <Typography fontSize="12px" fontWeight="bold" sx={{ paddingTop: '8px' }}>
        モール別売上シェア
      </Typography>
      <MallSharegraph graphdata={graphdata} />
    </Container>
  );
};

export default SalesshareByMall;
