/* eslint-disable*/
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState, memo, useRef } from 'react';
import { Box, Typography, Link } from '@material-ui/core'; // eslint-disable-line

const TrialCampaignRegisterComplete = memo(() => {
  const [searchParams] = useSearchParams();

  const start = searchParams.get('start');
  const payment_num = searchParams.get('paynum');
  const payment_method = searchParams.get('method');

  let stripe_link = '';
  let total = 0;

  if (start == 'this') {
    if (payment_num == 1) {
      // 今月開始＆一括払い
      stripe_link = 'https://buy.stripe.com/5kAaGY0NXaHMbLy7sT';
      total = 27500 * 6;
    } else if (payment_num == 2) {
      // 今月開始＆月払い
      stripe_link = 'https://buy.stripe.com/4gw16o8gpbLQ6rebJ8';
      total = 27500 * 2;
    }
  } else if (start == 'next') {
    if (payment_num == 1) {
      // 来月開始＆一括払い
      stripe_link = 'https://buy.stripe.com/5kAaGY0NXaHMbLy7sT';
      total = 27500 * 6;
    } else if (payment_num == 2) {
      // 来月開始＆月払い
      stripe_link = 'https://buy.stripe.com/aEU8yQ9ktdTY9Dq28x';
      total = 27500;
    }
  }
  useEffect(() => {
    // 3秒後に別のURLに遷移
    const timer = setTimeout(() => {
      if (stripe_link !== '' && payment_method == 1) {
        window.location.replace(stripe_link);
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Box xs={12} textAlign="center" backgroundColor="#F7FCFF" pt={5}>
        <Typography variant="h5" fontSize={'14px'}>
          お申込みありがとうございました。
        </Typography>
        <Typography variant="h5" mb={3} fontSize={'14px'}>
          まだ入金の手続きが完了しておりません。
        </Typography>
        {payment_method == '1' ? (
          <Typography variant="h4" mb={3}>
            次に
            {stripe_link !== '' ? (
              <Link href={stripe_link} underline="hover" fontSize="25px">
                カード決済手続き
              </Link>
            ) : (
              'カード決済手続き'
            )}
            をお願いします。
          </Typography>
        ) : payment_method == '2' ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mb: 3
            }}
          >
            <Typography variant="h5" fontSize={'16px'} mb={1}>
              次に銀行振込手続きをお願いします。
            </Typography>
            <Box>
              <Typography fontSize={'16px'} align="left">
                - 金額
              </Typography>
              <Typography fontSize={'16px'} align="left" mb={2}>
                {total.toLocaleString()}円(税込)
              </Typography>
              <Typography fontSize={'16px'} align="left">
                - 振込先
              </Typography>
              <Typography fontSize={'16px'} align="left">
                　金融機関名：三井住友銀行
              </Typography>
              <Typography fontSize={'16px'} align="left">
                　支店名：赤坂支店
              </Typography>
              <Typography fontSize={'16px'} align="left">
                　口座種別/番号：普通 9173926
              </Typography>
              <Typography fontSize={'16px'} align="left">
                　振込先名：ｶ)ｵﾌﾟﾄ
              </Typography>
            </Box>
          </Box>
        ) : (
          <></>
        )}

        <Typography variant="h5" fontSize={'14px'}>
          ※お申込み完了のご連絡メールにも振込先の記載がございます
        </Typography>
        <Typography variant="h5" fontSize={'14px'} pb={3}>
          お手続き完了後、カスタマーサポートから利用開始のご案内を致します。
        </Typography>
      </Box>
    </>
  );
});

export default TrialCampaignRegisterComplete;
