/* eslint-disable */

import {
  Card,
  Paper,
  Typography,
  CardContent,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Container,
  InputBase,
  CircularProgress,
  Button,
  IconButton
} from '@material-ui/core';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SearchIcon from '@mui/icons-material/Search';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectMonitoringGroup } from 'feactures/monitoring/monitoringSlice';
import GroupListTable from 'components/molecules/dashboard/GroupListTable';
import {
  groupSalesData,
  groups,
  groupStatus,
  groupSalesDataPage,
  changeGroupDataPageCount,
  groupSalesDataLength,
  fetchGroupDataList,
  changeOrder,
  changeOrderBy,
  groupSalesDataOrder,
  groupSalesDataOrderBy,
  resetGroupDataPage,
  groupDataSearchword,
  changeGroupDataSearchword
} from 'feactures/monitoring/groupSlice';
import { makeStyles, styled } from '@material-ui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState, useEffect } from 'react';

const RateDataIcon = ({ rateNum }) => {
  let colorCode;
  if (rateNum === 0) {
    colorCode = '#000000';
  } else if (rateNum > 0) {
    colorCode = '#4C34FF';
  } else if (rateNum < 0) {
    colorCode = '#FF1C17';
  }

  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      {/* {rateNum > 0 && <TrendingUpIcon sx={{ color: colorCode }} />}
      {rateNum == 0 && <TrendingFlatIcon sx={{ color: colorCode }} />}
      {rateNum < 0 && <TrendingDownIcon sx={{ color: colorCode }} />} */}

      <Typography
        variant="h6"
        color={colorCode}
        component="div"
        sx={{ fontSize: '12px', fontWeight: 'bold' }}
      >
        {rateNum > 0 && '↑'}
        {rateNum == 0 && '±'}
        {rateNum < 0 && '↓'}
        {Math.abs(rateNum)}%
      </Typography>
    </Box>
  );
};

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: expand == 'asc' ? 'rotate(0deg)' : 'rotate(180deg)'
  // transition: theme.transitions.create('transform', {
  //   duration: theme.transitions.duration.shortest
  // })
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
  padding: 0
}));

const KpiSummaryGroupTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const group = useSelector(groups);
  const data = useSelector(groupSalesData);
  const isLoading = useSelector(groupStatus);
  const pageCount = useSelector(groupSalesDataPage);
  const dataLength = useSelector(groupSalesDataLength);
  const checkedColumn = useSelector(groupSalesDataOrderBy);
  const orderDesc = useSelector(groupSalesDataOrder);
  const searchword = useSelector(groupDataSearchword);
  const [open, setOpen] = useState(false);

  // useEffect(() => {
  //   dispatch(changeGroupDataSearchword(''));
  //   dispatch(resetGroupDataPage());
  //   dispatch(fetchGroupDataList({ searchword: '' }));
  // }, []);

  // useEffect(() => {
  //   dispatch(fetchGroupDataList({ searchword: '' }));
  // }, [group, pageCount]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChangeWord = (e) => {
    dispatch(changeGroupDataSearchword(e.target.value));
  };

  const handleSearch = () => {
    dispatch(resetGroupDataPage());
    dispatch(fetchGroupDataList({ searchword: searchword }));
  };

  const handleChange = (column) => {
    if (column === checkedColumn && orderDesc === 'desc') {
      //最後に並び替えたカラムと同じカラムで、現在の並び順が降順なので昇順に並び替える
      dispatch(changeOrder('asc'));
      dispatch(fetchGroupDataList({ searchword: '' }));
    } else if (column === checkedColumn && orderDesc === 'asc') {
      //最後に並び替えたカラムと同じカラムで、現在の並び順が昇順なので降順に並び替える
      dispatch(changeOrder('desc'));
      dispatch(fetchGroupDataList({ searchword: '' }));
    } else {
      // 新しいカラムを降順に並び替える
      dispatch(changeOrderBy(column));
      dispatch(changeOrder('desc'));
      dispatch(fetchGroupDataList({ searchword: '' }));
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          pr: 2,
          pl: 1
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            component="div"
            fontSize="1rem"
            sx={{
              color: '#595959',
              fontWeight: 'bold',
              fontSize: '14px',
              mr: 1
            }}
          >
            グループ売上
          </Typography>
          <Paper
            component={'div'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: 300,
              height: '30px'
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="検索"
              value={searchword}
              onChange={(e) => {
                handleChangeWord(e);
              }}
            />
            <IconButton
              type="button"
              sx={{ p: '5px', mr: 1 }}
              onClick={() => {
                handleSearch();
              }}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </Box>

        <IconButton
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 0
          }}
          onClick={handleOpen}
        >
          <SettingsOutlinedIcon />
          <Typography fontSize={'10px'}>設定</Typography>
        </IconButton>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <TableContainer sx={{ position: 'relative', mt: 1 }}>
              <Table sx={{ border: 'none' }}>
                <TableHead>
                  <TableRow sx={{ bgcolor: '#D9D9D9' }}>
                    <StyledTableCell sx={{ fontWeight: 'bold' }}>
                      グループ
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        onClick={() => {
                          handleChange('sales');
                        }}
                        color="inherit"
                        sx={{
                          padding: 0,
                          height: '32.5px',
                          width: '100%',
                          fontWeight: 'bold'
                        }}
                      >
                        全体売上
                        {checkedColumn === 'sales' && (
                          <ExpandMore
                            expand={orderDesc}
                            aria-expanded={orderDesc}
                            aria-label="sort"
                            sx={{ padding: 0 }}
                          >
                            <ExpandMoreIcon />
                          </ExpandMore>
                        )}
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: 'bold' }}>
                      前期比
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        onClick={() => {
                          handleChange('unit');
                        }}
                        color="inherit"
                        sx={{
                          padding: 0,
                          height: '32.5px',
                          width: '100%',
                          fontWeight: 'bold'
                        }}
                      >
                        販売個数
                        {checkedColumn === 'unit' && (
                          <ExpandMore
                            expand={orderDesc}
                            aria-expanded={orderDesc}
                            aria-label="sort"
                            sx={{ padding: 0 }}
                          >
                            <ExpandMoreIcon />
                          </ExpandMore>
                        )}
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: 'bold' }}>
                      前期比
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        onClick={() => {
                          handleChange('views');
                        }}
                        color="inherit"
                        sx={{
                          padding: 0,
                          height: '32.5px',
                          width: '100%',
                          fontWeight: 'bold'
                        }}
                      >
                        閲覧数
                        {checkedColumn === 'views' && (
                          <ExpandMore
                            expand={orderDesc}
                            aria-expanded={orderDesc}
                            aria-label="sort"
                            sx={{ padding: 0 }}
                          >
                            <ExpandMoreIcon />
                          </ExpandMore>
                        )}
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: 'bold' }}>
                      前期比
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        onClick={() => {
                          handleChange('cvr');
                        }}
                        color="inherit"
                        sx={{
                          padding: 0,
                          height: '32.5px',
                          width: '100%',
                          fontWeight: 'bold'
                        }}
                      >
                        CVR
                        {checkedColumn === 'cvr' && (
                          <ExpandMore
                            expand={orderDesc}
                            aria-expanded={orderDesc}
                            aria-label="sort"
                            sx={{ padding: 0 }}
                          >
                            <ExpandMoreIcon />
                          </ExpandMore>
                        )}
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: 'bold' }}>
                      前期比
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        onClick={() => {
                          handleChange('price');
                        }}
                        color="inherit"
                        sx={{
                          padding: 0,
                          height: '32.5px',
                          width: '100%',
                          fontWeight: 'bold'
                        }}
                      >
                        販売単価
                        {checkedColumn === 'price' && (
                          <ExpandMore
                            expand={orderDesc}
                            aria-expanded={orderDesc}
                            aria-label="sort"
                            sx={{ padding: 0 }}
                          >
                            <ExpandMoreIcon />
                          </ExpandMore>
                        )}
                      </Button>
                    </StyledTableCell>
                    <StyledTableCell sx={{ fontWeight: 'bold' }}>
                      前期比
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ border: 'none' }}>
                  {data.length > 0 &&
                    data.map((item, index) => (
                      <TableRow
                        key={`${item.name}-${index}`}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          '&:nth-of-type(even)': { bgcolor: '#E3E8F4' }
                        }}
                      >
                        <StyledTableCell>
                          <Button
                            sx={{ color: 'inherit' }}
                            onClick={() => {
                              dispatch(
                                selectMonitoringGroup({
                                  id: item.id,
                                  name: item.name
                                })
                              );
                              navigate(`/app/monitoring/group`);
                            }}
                          >
                            {item.name}
                          </Button>
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.sales.num === 0
                            ? '-'
                            : '¥' + item.sales.num.toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell>
                          <RateDataIcon rateNum={item.sales.rate} />
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.unit.num === 0
                            ? '-'
                            : item.unit.num.toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell>
                          <RateDataIcon rateNum={item.unit.rate} />
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.views.num === 0
                            ? '-'
                            : item.views.num.toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell>
                          <RateDataIcon rateNum={item.views.rate} />
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.cvr.num === 0
                            ? '-'
                            : item.cvr.num.toLocaleString() + '%'}
                        </StyledTableCell>
                        <StyledTableCell>
                          <RateDataIcon rateNum={item.cvr.rate} />
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.price.num === 0
                            ? '-'
                            : '¥' + item.price.num.toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell>
                          <RateDataIcon rateNum={item.price.rate} />
                        </StyledTableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>

              {/* {data.length !== 0 ? (
                <></>
              ) : (
                <Card
                  sx={{
                    borderRadius: 6,
                    pb: 0,
                    my: 2,
                    border: '1px solid lightgray',
                    display: 'flex',
                    alignItems: 'center',
                    width: '70%',
                    height: '150px',
                    position: 'absolute',
                    top: '25%',
                    left: '15%'
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: '24px',
                      color: 'gray',
                      width: '100%'
                    }}
                  >
                    「全体」はグループ編集後に表示されます。
                  </Typography>
                </Card>
              )} */}
            </TableContainer>
          </>
        )}
      </Box>
      {!isLoading && dataLength === 0 ? (
        <Box py={1} sx={{ textAlign: 'center' }}>
          データがありません
        </Box>
      ) : (
        <></>
      )}
      {/* ページネーション */}
      {!isLoading && dataLength > 0 ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ py: 1 }}
        >
          <Typography>
            {(pageCount - 1) * 10 + 1} ~{' '}
            {(pageCount - 1) * 10 + 10 > dataLength
              ? dataLength
              : (pageCount - 1) * 10 + 10}{' '}
            / {dataLength}
            件の結果
          </Typography>
          <Button
            color="inherit"
            variant="contained"
            disabled={pageCount <= 1}
            onClick={() => {
              dispatch(changeGroupDataPageCount(-1));
              dispatch(fetchGroupDataList({ searchword: '' }));
            }}
            sx={{
              height: '20px',
              borderRadius: 160,
              minWidth: '30px',
              p: 0,
              ml: 1
            }}
          >
            <ArrowBackIosNewIcon sx={{ height: '13px', width: '13px' }} />
          </Button>
          <Button
            color="inherit"
            variant="contained"
            disabled={pageCount >= dataLength / 10}
            onClick={() => {
              dispatch(changeGroupDataPageCount(1));
              dispatch(fetchGroupDataList({ searchword: '' }));
            }}
            sx={{
              height: '20px',
              borderRadius: 160,
              minWidth: '30px',
              p: 0,
              ml: 1
            }}
          >
            <ArrowForwardIosIcon sx={{ height: '13px', width: '13px' }} />
          </Button>
        </Box>
      ) : (
        <></>
      )}
      <GroupListTable open={open} setOpen={setOpen} />
    </>
  );
};

export default KpiSummaryGroupTable;
