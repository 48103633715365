/* eslint-disable */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@material-ui/styles/makeStyles';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Box,
  Button,
  Link,
  Checkbox,
  Typography
} from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { currentchannel } from 'feactures/channel/channelSlice';
import { addDeleteItem } from 'feactures/product/deleteProductSlice';
import {
  fetchItemDetailGraph,
  itemDetailDisplay,
  setItemDetail,
  setItemDetailDisplayFlag
} from 'feactures/product/productAnalysisSlice';
import ItemAnalysisDetailDialog from 'components/atoms/modal/ItemAnalysisDetailDialog';

const ProductAnalysisList = (props) => {
  const { productList, yokoziku, section } = props;
  const dispatch = useDispatch();
  const combined = productList.map((product, i) => ({
    rank: i + 1,
    ...product
  }));

  const [orderDesc, setOrderDesc] = useState(true);
  const [checkedColumn, setCheckedColumn] = useState('rank');
  const [sortArr, setSortArr] = useState(combined);
  const [deleteList, setDeleteList] = useState([]);

  useEffect(() => {
    setOrderDesc(true);
    setCheckedColumn('rank');
    setSortArr(
      productList.map((product, i) => ({
        rank: i + 1,
        ...product
      }))
    );
  }, [productList]);

  useEffect(() => {
    dispatch(addDeleteItem({ deleteList }));
  }, [deleteList]);

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  }));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const channel = useSelector(currentchannel);

  const url = 'https://m.media-amazon.com/images/I/';

  const handleChange = (column) => {
    if (column === checkedColumn && orderDesc === true) {
      //最後に並び替えたカラムと同じカラムで、現在の並び順が降順なので昇順に並び替える
      if (column === 'rank') {
        setOrderDesc(false);
        const ascSorted = [...sortArr].sort((a, b) => b[column] - a[column]);
        setSortArr(ascSorted);
      } else {
        setOrderDesc(false);
        const ascSorted = [...sortArr].sort((a, b) => a[column] - b[column]);
        setSortArr(ascSorted);
      }
    } else if (column === checkedColumn && orderDesc === false) {
      //最後に並び替えたカラムと同じカラムで、現在の並び順が昇順なので降順に並び替える
      if (column === 'rank') {
        setOrderDesc(true);
        const descSorted = [...sortArr].sort((a, b) => a[column] - b[column]);
        setSortArr(descSorted);
      } else {
        setOrderDesc(true);
        const descSorted = [...sortArr].sort((a, b) => b[column] - a[column]);
        setSortArr(descSorted);
      }
    } else {
      // 新しいカラムを降順に並び替える
      setCheckedColumn(column);
      if (column === 'rank') {
        setOrderDesc(true);
        const descSorted = [...sortArr].sort((a, b) => a[column] - b[column]);
        setSortArr(descSorted);
      } else {
        setOrderDesc(true);
        const descSorted = [...sortArr].sort((a, b) => b[column] - a[column]);
        setSortArr(descSorted);
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const tableStyles = makeStyles({
    tableHead: {
      height: 44
    }
  });

  const classes = tableStyles();

  const handleClick = (row, shop_code) => {
    const data = {
      rank: row.rank,
      img: row.img,
      shopCode: shop_code,
      asin: row.jan,
      item_name: row.name,
      price: row.price,
      unit_sold: row.unit_sold,
      sales: row.sales,
      section
    };
    dispatch(setItemDetailDisplayFlag(true));
    dispatch(setItemDetail(data));
    dispatch(fetchItemDetailGraph(data));
  };

  const removeDeleteList = (selectedId) => {
    const newDeleteList = deleteList.filter((x) => x !== selectedId);
    setDeleteList(newDeleteList);
  };

  const changeBox = (e) => {
    if (e.target.checked) {
      // console.log(`リストに追加するid：${e.target.value}`);
      setDeleteList([...deleteList, Number(e.target.value)]);
    } else {
      // console.log(`リストから外すid：${e.target.value}`);
      removeDeleteList(Number(e.target.value));
    }
  };

  return (
    <>
      {/* <Paper sx={{ width: '100%', overflow: 'hidden' }}> */}
      <ItemAnalysisDetailDialog yokoziku={yokoziku} />

      <TableContainer sx={{ maxHeight: 550 }} component={Paper}>
        <Table stickyHeader size="small" aria-label="a dense table">
          <TableHead sx={{ maxHeight: '38px' }}>
            <TableRow>
              {/* <TableCell sx={{ width: '62px' }} component="th" scope="row"> */}
              {/* 画像 */}
              <TableCell
                sx={{ bgcolor: '#D9D9D9', fontWeight: 'bold' }}
              ></TableCell>
              <TableCell
                sx={{
                  width: sortArr.length !== 0 ? '70px' : '150px',
                  bgcolor: '#D9D9D9',
                  fontWeight: 'bold'
                }}
              ></TableCell>
              {channel == 1 ? (
                <TableCell
                  align="center"
                  sx={{ bgcolor: '#D9D9D9', fontWeight: 'bold' }}
                >
                  ASIN
                </TableCell>
              ) : (
                <TableCell
                  align="center"
                  sx={{
                    width: '120px',
                    bgcolor: '#D9D9D9',
                    fontWeight: 'bold'
                  }}
                >
                  ショップ名
                  <br />
                  商品管理番号
                </TableCell>
              )}
              <TableCell
                align="center"
                sx={{ bgcolor: '#D9D9D9', fontWeight: 'bold' }}
              >
                商品名
              </TableCell>
              <TableCell
                align="center"
                sx={{ minWidth: '120px', padding: 0, bgcolor: '#D9D9D9' }}
              >
                <Button
                  onClick={() => {
                    handleChange('price');
                  }}
                  color="inherit"
                  sx={{
                    padding: 0,
                    height: '32.5px',
                    width: '100%',
                    fontWeight: 'bold'
                  }}
                >
                  平均価格
                  {checkedColumn !== 'price' && (
                    <Box width="20.5px" height="20.5px"></Box>
                  )}
                  {checkedColumn === 'price' && (
                    <ExpandMore
                      expand={orderDesc}
                      aria-expanded={orderDesc}
                      aria-label="sort"
                      sx={{ padding: 0 }}
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  )}
                </Button>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  minWidth: '120px',
                  padding: 0,
                  bgcolor: '#D9D9D9'
                }}
              >
                <Button
                  onClick={() => {
                    handleChange('unit_sold');
                  }}
                  color="inherit"
                  sx={{
                    padding: 0,
                    height: '32.5px',
                    width: '100%',
                    fontWeight: 'bold'
                  }}
                >
                  販売個数
                  {checkedColumn !== 'unit_sold' && (
                    <Box width="20.5px" height="20.5px"></Box>
                  )}
                  {checkedColumn === 'unit_sold' && (
                    <ExpandMore
                      expand={orderDesc}
                      aria-expanded={orderDesc}
                      aria-label="sort"
                      sx={{ padding: 0 }}
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  )}
                </Button>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  minWidth: '120px',
                  padding: 0,
                  bgcolor: '#D9D9D9'
                }}
              >
                <Button
                  onClick={() => {
                    handleChange('rank');
                  }}
                  color="inherit"
                  sx={{
                    padding: 0,
                    height: '32.5px',
                    width: '100%',
                    fontWeight: 'bold'
                  }}
                >
                  {checkedColumn !== 'rank' && (
                    <Box width="20.5px" height="20.5px"></Box>
                  )}
                  売上
                  {checkedColumn === 'rank' && (
                    <ExpandMore
                      expand={orderDesc}
                      aria-expanded={orderDesc}
                      aria-label="sort"
                      sx={{ padding: 0 }}
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                  )}
                </Button>
              </TableCell>
              <TableCell
                sx={{ bgcolor: '#D9D9D9', fontWeight: 'bold' }}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortArr.length !== 0 ? (
              <>
                {sortArr
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const shopCode = channel == 2 ? row.jan.split(':')[0] : '';
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          '&:nth-of-type(even)': { bgcolor: '#E3E8F4' },
                          height: 100
                        }}
                        className={classes.tableHead}
                      >
                        <TableCell>
                          <Checkbox value={row.id} onChange={changeBox} />
                        </TableCell>
                        <TableCell align="center" maxwidth={40}>
                          <Link
                            href={
                              channel === 1
                                ? `https://www.amazon.co.jp/dp/${row.jan}`
                                : `https://item.rakuten.co.jp/${shopCode}/${
                                    row.jan.split(':')[1]
                                  }/`
                            }
                            target="_blank"
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column'
                              }}
                            >
                              <Box
                                sx={{
                                  height: 70,
                                  width: 70,
                                  display: 'inline-block'
                                }}
                              >
                                <img
                                  src={channel == 1 ? url + row.img : row.img}
                                  height="100%"
                                  loading="lazy"
                                  width="100%"
                                />
                              </Box>
                            </Box>
                          </Link>
                        </TableCell>
                        <TableCell align="center">
                          {channel === 1 ? (
                            <Box>{row.jan}</Box>
                          ) : (
                            <Box>
                              {row.jan.split(':')[0]}
                              <br />
                              {row.jan.split(':')[1]}
                            </Box>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <Link
                            underline="hover"
                            href={
                              channel === 1
                                ? `https://www.amazon.co.jp/dp/${row.jan}`
                                : `https://item.rakuten.co.jp/${shopCode}/${
                                    row.jan.split(':')[1]
                                  }/`
                            }
                            target="_blank"
                            rel="noopener"
                          >
                            <Box
                              sx={{
                                height: '60px',
                                display: 'flex',
                                alignItems: 'start',
                                justifyContent: 'left',
                                lineHeight: '15px'
                                // width: 170,
                                // display: 'inline-block'
                              }}
                              component="div"
                              my={2}
                              textOverflow="ellipsis"
                              overflow="hidden"
                            >
                              {row.name}
                            </Box>
                          </Link>
                        </TableCell>
                        <TableCell align="right">
                          {row.price == 0 ? (
                            <Typography fontSize="10px" align="left">
                              データがないので表示できません。
                            </Typography>
                          ) : (
                            '¥' + row.price.toLocaleString()
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {row.unit_sold == 0
                            ? '-'
                            : row.unit_sold.toLocaleString()}
                        </TableCell>
                        <TableCell align="right">
                          {row.sales == 0
                            ? '-'
                            : '¥' + row.sales.toLocaleString()}
                        </TableCell>
                        {/* <TableCell align="right">
                          ¥{row.sales.toLocaleString()}
                        </TableCell> */}
                        <TableCell align="center">
                          <Button
                            variant="contained"
                            color="detailButton"
                            sx={{ fontWeight: 'bold', borderRadius: 160 }}
                            onClick={() => {
                              handleClick(row, shopCode);
                            }}
                          >
                            詳細
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </>
            ) : (
              <TableRow>
                <TableCell>データがありません</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 25, 100]}
        count={sortArr.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default ProductAnalysisList;
