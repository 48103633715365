/* eslint-disable*/
import { ComposedChart, Bar, Line } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  useTheme,
  Typography,
  Button,
  IconButton
  // DeleteIcon
} from '@material-ui/core';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Settings, HelpOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'chartjs-adapter-moment';
import { useNavigate } from 'react-router-dom';
import { fetchAsyncTrackingWords } from 'feactures/monitoring/trackingSlice';
import { benchmark } from 'feactures/channel/channelSlice';
import { currentchannel } from 'feactures/channel/channelSlice';

// import { selectAsins } from 'feactures/monitoring/asinSlice';

//「？」マークにカーソルが当たったときの吹き出し
export const HtmlTooltip = styled(({ className, placement, ...props }) => (
  <Tooltip
    {...props}
    arrow
    placement={placement}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper
    // border: '1px solid #dadde9'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    maxWidth: 280
  }
}));

const Keywordgraph = (props) => {
  const {
    title,
    color,
    graphdata,
    graphTitle,
    graphtype,
    term, //[,,,]
    setting,
    setopen,
    num,
    rate,
    sihyou,
    tanni,
    asinTitle,
    info,
    disabled,
    benchData,
    annotation
  } = props; // eslint-disable-line
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bench = useSelector(benchmark);
  const channel = useSelector(currentchannel);

  const settingClick = () => {
    navigate('/app/trends', {
      state: {
        title: title,
        sihyou: sihyou,
        graphTitle: graphTitle,
        tanni: tanni,
        term: term,
        info,
        graphtype,
        color
      }
    });
    // setopen();
    dispatch(fetchAsyncTrackingWords({ graphTitle: graphTitle }));
  };

  const numEn = num?.toLocaleString(); // eslint-disable-line

  const options = {
    interaction: {
      mode: 'nearest',
      intersect: false
    },
    scales: {
      x: {
        scaleLabel: {
          // 軸ラベル
          display: true, // 表示設定
          labelString: '横軸ラベル', // ラベル
          fontColor: 'red', // 文字の色
          fontSize: 16 // フォントサイズ
        },
        grid: {
          display: false,
          drawBorder: false
        },
        title: {
          display: false
          // text: 'x',
          // font: {
          //   size: 14
          // }
        }
      },
      y: {
        reverse: tanni === '位',
        ticks: {
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          min: 0
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider
        }
      }
    },
    plugins: {
      // title: {
      //   display: true,
      //   text: title
      // },
      legend: { display: false }, //ラベル名(凡例)非表示
      tooltip: {
        callbacks: {
          label: function (tooltipItem, y) {
            let label = tooltipItem.dataset.label;
            let data = tooltipItem.raw;
            label += ' : ' + data.toLocaleString() + tanni;
            return label;
          }
        }
      },
      datalabels: {
        display: false
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: true,
    cornerRadius: 20,
    layout: {
      maxwidth: '100px',
      padding: {
        left: '30px',
        right: 0,
        top: 0,
        bottom: 0
      }
    }
  };
  const data = {
    datasets: [
      {
        // グラフの色
        backgroundColor: color,
        borderRadius: 0,
        data: graphdata,
        label: title,
        barThickness: 'flex',
        maxBarThickness: 20
      }
    ],
    labels: term
  };

  // /* prettier-ignore */
  return (
    <>
      {/* <Card sx={{ flex: 1 }}> */}
      <Card>
        <CardContent>
          {/* <Box style={{ overflowX: 'auto' }}>
            <Box style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
              <Box sx={{ display: 'inline-block' }}> */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              flex: 'auto 0 0',
              overflowX: 'auto'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 'auto 0 0',
                mr: '5px',
                width: '250px',
                height: 'auto',
                overflow: 'hidden'
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Typography component="div" variant="h5" mb={1}>
                  {title}
                </Typography>
                {setting === 1 ? (
                  <Settings
                    color="action"
                    fontSize="small"
                    sx={{ ml: 1 }}
                    // onClick={setopen}
                    onClick={settingClick}
                  />
                ) : (
                  ''
                )}
              </Box>
              {channel === 1 && (
                <Typography sx={{ fontSIze: '12px' }}>{annotation}</Typography>
              )}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  boxsizing: 'border-box',
                  mt: 2
                }}
              >
                <Typography
                  variant="h5"
                  color="text.secondary"
                  component="div"
                  mt={'10px'}
                  mr={0.5}
                >
                  {sihyou}
                </Typography>
                {tanni == '円' || '回' ? (
                  <Typography
                    variant="h2"
                    color="text.secondary"
                    component="div"
                  >
                    {numEn}
                  </Typography>
                ) : (
                  <Typography
                    variant="h2"
                    color="text.secondary"
                    component="div"
                  >
                    {num}
                  </Typography>
                )}
                <Typography
                  variant="h5"
                  color="text.secondary"
                  component="div"
                  mt={'10px'}
                  ml={'3px'}
                >
                  {tanni}
                </Typography>
                <HtmlTooltip
                  placement="top"
                  title={<span style={{ whiteSpace: 'pre-line' }}>{info}</span>}
                >
                  <span>
                    <IconButton>
                      <HelpOutlined style={{ fontSize: '15px', mr: 3 }} />
                    </IconButton>
                  </span>
                </HtmlTooltip>
                {/* <Box sx={{ flexGrow: 1 }} /> */}
                <Box textAlign="center">
                  {bench == 2 ? '競合比較' : '期間比較'}
                  {bench == 2 ? (
                    <>
                      {benchData ? (
                        <>
                          {rate < 0 ? (
                            <Typography
                              variant="h4"
                              color="red"
                              component="div"
                            >
                              {rate}%
                            </Typography>
                          ) : (
                            <Typography
                              variant="h4"
                              color="green"
                              component="div"
                            >
                              {rate}%
                            </Typography>
                          )}
                        </>
                      ) : (
                        <Typography
                          variant="h4"
                          color="text.secondary"
                          component="div"
                        >
                          -
                        </Typography>
                      )}
                    </>
                  ) : (
                    <>
                      {rate < 0 ? (
                        <Typography variant="h4" color="red" component="div">
                          {rate}%
                        </Typography>
                      ) : (
                        <Typography variant="h4" color="green" component="div">
                          {rate}%
                        </Typography>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </Box>
            {/* <Box sx={{ flexGrow: 1 }} /> */}

            <Box
              sx={{
                height: 100,
                width: '90%'
              }}
            >
              {graphtype === 'Bar' ? (
                <Bar
                  width="100%"
                  boxsizing="border-box"
                  data={data}
                  options={options}
                />
              ) : (
                ''
              )}
              {graphtype === 'Line' ? (
                <Line
                  width="100%"
                  boxsizing="border-box"
                  data={data}
                  options={options}
                />
              ) : (
                ''
              )}
            </Box>
          </Box>
          {/* </Box>
            </Box>
          </Box> */}
        </CardContent>
      </Card>
    </>
  );
};

export default Keywordgraph;
