/* eslint-disable */
import { Box } from '@material-ui/core';
import React from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { useSelector } from 'react-redux';
import {
  performancereviewAmazonOtherGraphData,
  performancereviewAmazonOtherScore,
  performancereviewAmazonOwnGraphData,
  performancereviewAmazonOwnScore,
  performancereviewRakutenOtherGraphData,
  performancereviewRakutenOtherScore,
  performancereviewRakutenOwnGraphData,
  performancereviewRakutenOwnScore
} from 'feactures/performancereview/performancereviewSlice';
import { currentchannel } from 'feactures/channel/channelSlice';
Chart.register(...registerables);

const PerformanceRadar = (props) => {
  const { section } = props;
  const amazonOwnPerformanceData = useSelector(
    performancereviewAmazonOwnGraphData
  );
  const rakutenOwnPerformanceData = useSelector(
    performancereviewRakutenOwnGraphData
  );
  const amazonOtherPerformanceData = useSelector(
    performancereviewAmazonOtherGraphData
  );
  const rakutenOtherPerformanceData = useSelector(
    performancereviewRakutenOtherGraphData
  );
  const amazonOwnScore = useSelector(performancereviewAmazonOwnScore);
  const amazonOtherScore = useSelector(performancereviewAmazonOtherScore);
  const rakutenOwnScore = useSelector(performancereviewRakutenOwnScore);
  const rakutenOtherScore = useSelector(performancereviewRakutenOtherScore);
  const channel = useSelector(currentchannel);
  let bgcolor;
  let bordercolor;
  let graphData;
  let labelcolor;
  let label;
  let labels;
  let score;
  if ((section == 'own') & (channel == 1)) {
    bgcolor = 'rgba(113, 140, 198, 0.5)';
    bordercolor = 'rgba(2, 62, 190, 0.5)';
    labelcolor = 'rgba(113,140,198,1)';
    graphData = amazonOwnPerformanceData;
    label = '自社商品';
    score = amazonOwnScore;
  } else if ((section == 'other') & (channel == 1)) {
    bgcolor = 'rgba(255, 191, 35, 0.5)';
    bordercolor = 'rgba(251, 115, 3, 0.5)';
    labelcolor = '#FFC231';
    graphData = amazonOtherPerformanceData;
    label = '競合商品';
    score = amazonOtherScore;
  } else if ((section == 'own') & (channel == 2)) {
    bgcolor = 'rgba(113, 140, 198, 0.5)';
    bordercolor = 'rgba(2, 62, 190, 0.5)';
    labelcolor = 'rgba(113,140,198,1)';
    graphData = rakutenOwnPerformanceData;
    label = '自社商品';
    score = rakutenOwnScore;
  } else if ((section == 'other') & (channel == 2)) {
    bgcolor = 'rgba(255, 191, 35, 0.5)';
    bordercolor = 'rgba(251, 115, 3, 0.5)';
    labelcolor = '#FFC231';
    graphData = rakutenOtherPerformanceData;
    label = '競合商品';
    score = rakutenOtherScore;
  }

  if (channel == 1) {
    labels = ['No.1 集客施策', 'No.2 価格', 'No.3 商品ページ', 'No.4 出品'];
  } else if (channel == 2) {
    labels = [
      'No.1 集客施策',
      'No.2 検索',
      'No.3 価格',
      'No.4 商品ページ',
      'No.5 出品',
      'No.6 ストア'
    ];
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: label,
        data: graphData,
        fill: true,
        backgroundColor: bgcolor, // 背景色
        borderColor: bordercolor, // 境界線色
        borderWidth: 1, // 境界線の太さを1に設定
        pointBackgroundColor: bordercolor, // 点
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: bordercolor
      }
    ]
  };

  const options = {
    scales: {
      r: {
        angleLines: {
          display: true
        },
        min: 0, // 最小値を0に設定
        suggestedMax: 5, // 最大値を5に設定
        ticks: {
          stepSize: 1, // 1単位ごとの目盛り
          backdropColor: 'transparent', // 背景を透明にする
          callback: function (value) {
            const ranks = ['', 'D', 'C', 'B', 'A', 'S'];
            return ranks[value] || ''; // 0～5の値に対応するラベルを表示
          },
          font: {
            size: 14 // 目盛りラベルのフォントサイズ
          }
        },
        pointLabels: {
          font: {
            size: 12 // ラベルのフォントサイズ
          }
        }
      }
    },
    plugins: {
      legend: {
        display: false,
        position: 'bottom', // ラベルをチャートの下に表示
        labels: {
          font: {
            size: 12
          },
          color: labelcolor // ラベルの色
        }
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const ranks = ['', 'D', 'C', 'B', 'A', 'S'];
            let value = tooltipItem.raw; // データ値を取得
            return ranks[value] || ''; // 1～5の値をD～Sに変換して表示
          }
        }
      },
      datalabels: {
        display: false
      }
    }
  };

  // プラグインでグラフの順序をカスタマイズ
  const chartPlugin = {
    id: 'reverseStack',
    beforeDatasetsDraw: (chart, args, options) => {
      chart.ctx.globalCompositeOperation = 'destination-over'; // 背面に描画
    },
    afterDatasetsDraw: (chart, args, options) => {
      chart.ctx.globalCompositeOperation = 'source-over'; // 通常の描画に戻す
    }
  };

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      sx={{ position: 'relative' }}
    >
      <Box width="80%" maxWidth="400px" p={3}>
        <Radar data={data} options={options} plugins={[chartPlugin]} />
      </Box>
      {score == 'nodata' && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'rgba(255, 255, 255, 0.8)',
            color: 'black',
            fontWeight: 'bold',
            fontSize: '15px',
            zIndex: 1
          }}
        >
          新規登録時はデータは翌日反映されます
        </Box>
      )}
    </Box>
  );
};

export default PerformanceRadar;
