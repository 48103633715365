/* eslint-disable*/
import { useState } from 'react';
import {
  Table,
  colors,
  TableContainer,
  Paper,
  Button,
  Box,
  Card,
  CardContent,
  Typography,
  Tooltip,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core'; /* eslint-disable*/
import { tableCellClasses } from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import { useSelector, useDispatch } from 'react-redux';
import Rating from '@mui/material/Rating';
import {
  switchBenchmark,
  benchmark,
  currentchannel
} from 'feactures/channel/channelSlice';
import {
  a_sales_up_action,
  r_sales_up_action,
  y_sales_up_action
} from 'feactures/monitoring/monitoringSlice';
import { currentTerm } from 'feactures/date/dateSlice';
import { ViewSidebarRounded } from '@material-ui/icons';
import { rangeLabel } from './impactRange';
import {
  amazonItems,
  rakutenItems
} from 'components/organisms/content/processkpi/RatingSetting';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    verticalAlign: 'center',
    maxHeight: '20px',
    paddingTop: '0px',
    paddingBottom: '0px',
    fontSize: '11px',
    fontWeight: '600',
    borderBottom: 'none',
    borderRadius: '0px',
    // backgroundColor: theme.palette.common.black,
    backgroundColor: '#fff',
    color: theme.palette.text.secondary
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '11px',
    borderRight: 'none',
    borderLeft: 'none'
  }
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginRight: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

const AnalyseChart2 = (props) => {
  const { disabled, onClick, viewItems, cvrItems, cupItems } = props; // eslint-disable-line
  const amazon_sales_up_action = useSelector(a_sales_up_action);
  const rakuten_sales_up_action = useSelector(r_sales_up_action);
  const yahoo_sales_up_action = useSelector(y_sales_up_action);
  const channel = useSelector(currentchannel);

  const [checked, setChecked] = useState(false);

  let sales_up_action = '';

  const initial = [
    {
      group_id: 1,
      group_name: 'ぐる１',
      sub_kpi_rate_dict: {
        image_num: { num: 16080, rate: -100, impact: 100000, pre: 1600 }
      }
    },
    {
      group_id: 2,
      group_name: 'ぐる2',
      sub_kpi_rate_dict: {
        image_num: { num: 16080, rate: -100, impact: 100000, pre: 1600 }
      }
    }
  ];
  let rateItems = [];
  if (channel == 1) {
    sales_up_action = amazon_sales_up_action;
    rateItems = amazonItems;
  } else if (channel == 2) {
    sales_up_action = rakuten_sales_up_action;
    rateItems = rakutenItems;
    // } else if (channel == 3) {
    //   sales_up_action = yahoo_sales_up_action;
  }
  // sales_up_action = initial;

  //予想最大インパクトをrangeLabelに当てはめる
  const impactResult = (impact) => {
    for (let range of rangeLabel) {
      // console.log(impact);
      if (range.min <= impact && impact <= range.max) {
        // console.log(range.label);
        return range.label;
      }
    }
  };

  let analysesArr = [];
  // console.log(sales_up_action);
  if (sales_up_action.length !== 0) {
    sales_up_action.map((groupInfos, index) => {
      const subKPITitleArr = Object.keys(groupInfos.sub_kpi_rate);
      subKPITitleArr.map((t, index) => {
        let groupInfo = {};
        groupInfo['group_name'] = groupInfos.group_name;
        groupInfo['plan'] = rateItems[t].kpi;
        groupInfo['action'] = rateItems[t].action;
        groupInfo['title'] = rateItems[t].jaTitle;
        groupInfo['sihyou'] = rateItems[t].sihyou;
        groupInfo['tanni'] = rateItems[t].tanni;
        const impactNum = groupInfos.sub_kpi_rate[t].impact;
        groupInfo['impact'] = impactResult(impactNum);
        groupInfo['num'] = groupInfos.sub_kpi_rate[t].num;
        groupInfo['rate'] = groupInfos.sub_kpi_rate[t].rate;
        groupInfo['pre'] = groupInfos.sub_kpi_rate[t].pre;
        groupInfo['rival'] = groupInfos.sub_kpi_rate[t].rival;

        analysesArr.push(groupInfo);
      });
    });
  }

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const infomation = '競合比較は予測値です。\n精度は±30%を想定しております。';

  return (
    <Card sx={{ mb: '5px' }}>
      <CardContent
        sx={{
          p: '8px',
          '&:last-child': {
            paddingBottom: '8px'
          }
        }}
      >
        <Typography
          component="div"
          gutterBottom
          variant="h5"
          fontWeight={600}
          color="text.secondary"
        >
          収益拡大が見込めるアクション
          <Typography variant="h6" component={'span'} sx={{ ml: 2 }}>
            ※直近30日のデータを元に算出
          </Typography>
          　
        </Typography>

        <TableContainer
          component={Paper}
          sx={{
            width: '100%',
            overflow: 'auto',
            alignItems: 'center',
            borderRadius: '0px',
            boxShadow: '0'
          }}
        >
          <Collapse in={checked} collapsedSize={50}>
            <Table
              size="small"
              stickyHeader
              aria-label="purchases"
              sx={{ minWidth: 'max-content' }}
              // sx={{ tableLayout: 'fixed' }}
            >
              {/* <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table"> */}
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left"></StyledTableCell>
                  <StyledTableCell align="left">グループ</StyledTableCell>
                  <StyledTableCell align="left">推奨情報の理由</StyledTableCell>
                  <StyledTableCell
                    align="center"
                    // component="th"
                    // scope="row"
                    // sx={{ minWidth: '180px' }}
                  >
                    改善するべき指標
                  </StyledTableCell>
                  <StyledTableCell align="center">Sub KPI指標</StyledTableCell>
                  <StyledTableCell align="center">
                    <Box align="center">
                      競合比較
                      <Tooltip
                        title={
                          <span
                            style={{
                              whiteSpace: 'pre-line'
                            }}
                          >
                            {infomation}
                          </span>
                        }
                        placement="top"
                      >
                        <InfoOutlined sx={{ fontSize: '1rem' }} />
                      </Tooltip>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ fontSize: '6px', lineHeight: '0.8rem' }}
                  >
                    改善後の予想インパクト
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    推奨アクション
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    コンサルサービス
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {analysesArr.length !== 0 ? (
                  <>
                    {analysesArr.map((row, index) => {
                      return (
                        <TableRow
                          key={index}
                          border={0}
                          sx={{ verticalAlign: checked ? 'center' : 'initial' }}
                        >
                          <StyledTableCell
                            align="left"
                            fontWeight="bold"
                            sx={{ p: '0px', verticalAlign: 'initial' }}
                          >
                            {index == 0 && (
                              <ExpandMore
                                expand={checked}
                                onClick={handleChange}
                                aria-expanded={checked}
                                aria-label="show more"
                              >
                                <ExpandMoreIcon />
                              </ExpandMore>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center" fontWeight="bold">
                            {row.group_name}
                          </StyledTableCell>
                          <StyledTableCell align="center" fontWeight="bold">
                            {row.plan}
                          </StyledTableCell>
                          <StyledTableCell sx={{ color: '#0F8799' }}>
                            <li key={index}>{row.title}</li>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {row.sihyou}
                            <Typography
                              fontSize="13px"
                              component="span"
                              mx="2px"
                            >
                              {row.num}
                            </Typography>
                            {row.tanni}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            sx={{ color: '#D02C3A' }}
                          >
                            <li style={{ listStyle: 'none' }} key={index}>
                              {row.rate}% &nbsp; (競合 {row.rival})
                            </li>
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            sx={{ color: '#4BACC6', p: '0px' }}
                          >
                            {row.impact}／月
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Box flexWrap="wrap">
                              {row.action.map((act, index) => {
                                return (
                                  <Typography
                                    m={0.2}
                                    px={'4px'}
                                    key={index}
                                    width="fit-content"
                                    fontSize="10px"
                                    color="text.contrastText"
                                    bgcolor="#0F8799"
                                  >
                                    {act}
                                  </Typography>
                                );
                              })}
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell align="center" sx={{ p: 0 }}>
                            <Button
                              sx={{
                                color: 'text.contrastText',
                                backgroundColor: '#EB9940',
                                borderRadius: '0px',
                                height: '25px'
                              }}
                              href="https://www.ecps.jp"
                            >
                              ECPSで解決
                            </Button>
                          </StyledTableCell>
                        </TableRow>
                      );
                    })}
                  </>
                ) : (
                  <TableRow border={0} sx={{ color: 'text.secondary' }}>
                    <StyledTableCell>-</StyledTableCell>
                    <StyledTableCell align="center">-</StyledTableCell>
                    <StyledTableCell align="center">-</StyledTableCell>
                    <StyledTableCell align="center">-</StyledTableCell>
                    <StyledTableCell align="center">-</StyledTableCell>
                    <StyledTableCell align="center">-</StyledTableCell>
                    <StyledTableCell align="center">-</StyledTableCell>
                    <StyledTableCell align="center">-</StyledTableCell>
                    <StyledTableCell align="center">-</StyledTableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Collapse>
          <Box sx={{ padding: '4px' }}>
            <Typography
              sx={{
                fontSize: '4px'
              }}
            >
              ※手順：上部の対象グループに☑、競合比較ボタンを選択すると反映されます
            </Typography>
            <Typography
              sx={{
                fontSize: '4px'
              }}
            >
              ※自社及び競合のグループ登録をしないと反映されません
            </Typography>
            <Typography
              sx={{
                fontSize: '4px'
              }}
            >
              ※データが集計されてない場合反映されません
            </Typography>
          </Box>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default AnalyseChart2;
