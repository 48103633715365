/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const fetchProcessKPIPost = createAsyncThunk(
  'processkpi/post',
  async (arg, thunkAPI) => {
    const { term, start, end } = await thunkAPI.getState().date;
    const { userId } = thunkAPI.getState().user;
    const { channel, benchmark } = thunkAPI.getState().currentchannel;
    const { checkGroupList } = thunkAPI.getState().group;

    const res = await client.post(`${apiUrl}kpi`, {
      start: start,
      end: end,
      term: term,
      channel: channel, //検討
      user_id: userId,
      group_list: checkGroupList,
      comparing_type: Number(benchmark)
    });
    // console.log(res.data);
    return res.data;
  }
);

const initialState = {
  salesGraph: [],
  viewsGraph: [],
  CVRGraph: [],
  priceGraph: [],
  rate: [],
  num: [],
  g_sales: [],
  g_views: [],
  g_cvr: [],
  g_price: []
};

const processKPISlice = createSlice({
  name: 'processkpi',
  initialState: {
    amazon: initialState,
    rakuten: initialState,
    yahoo: initialState
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProcessKPIPost.fulfilled, (state, action) => {
      if (action.payload.kpi.channel == 'amazon') {
        return {
          amazon: {
            ...state.amazon,
            salesGraph: action.payload.kpi.sales,
            viewsGraph: action.payload.kpi.views,
            CVRGraph: action.payload.kpi.CVR,
            priceGraph: action.payload.kpi.price,

            //競合
            g_sales: action.payload.group_kpi.sales,
            g_views: action.payload.group_kpi.views,
            g_cvr: action.payload.group_kpi.CVR,
            g_price: action.payload.group_kpi.price,

            rate: action.payload.kpi.rate,
            num: action.payload.kpi.num,
            isLoading: false,
            error: false
          },
          rakuten: { ...state.rakuten },
          yahoo: { ...state.yahoo },
          isLoading: false,
          error: false
        };
      }
      if (action.payload.kpi.channel == 'rakuten') {
        return {
          rakuten: {
            ...state.rakuten,
            salesGraph: action.payload.kpi.sales,
            viewsGraph: action.payload.kpi.views,
            CVRGraph: action.payload.kpi.CVR,
            priceGraph: action.payload.kpi.price,

            //競合
            g_sales: action.payload.group_kpi.sales,
            g_views: action.payload.group_kpi.views,
            g_cvr: action.payload.group_kpi.CVR,
            g_price: action.payload.group_kpi.price,

            rate: action.payload.kpi.rate,
            num: action.payload.kpi.num,
            isLoading: false,
            error: false
          },
          amazon: { ...state.amazon },
          yahoo: { ...state.yahoo },
          isLoading: false,
          error: false
        };
      }
      if (action.payload.kpi.channel == 'yahoo') {
        return {
          yahoo: {
            ...state.yahoo,
            salesGraph: action.payload.kpi.sales,
            viewsGraph: action.payload.kpi.views,
            CVRGraph: action.payload.kpi.CVR,
            priceGraph: action.payload.kpi.price,

            //競合
            g_sales: action.payload.group_kpi.sales,
            g_views: action.payload.group_kpi.views,
            g_cvr: action.payload.group_kpi.CVR,
            g_price: action.payload.group_kpi.price,

            rate: action.payload.kpi.rate,
            num: action.payload.kpi.num,
            isLoading: false,
            error: false
          },
          rakuten: { ...state.rakuten },
          amazon: { ...state.amazon },
          isLoading: false,
          error: false
        };
      }

      // return {
      //   ...state,
      //   salesGraph: action.payload.kpi.sales,
      //   viewsGraph: action.payload.kpi.views,
      //   CVRGraph: action.payload.kpi.CVR,
      //   priceGraph: action.payload.kpi.price,

      //   //競合
      //   g_sales: action.payload.group_kpi.sales,
      //   g_views: action.payload.group_kpi.views,
      //   g_cvr: action.payload.group_kpi.CVR,
      //   g_price: action.payload.group_kpi.price,

      //   rate: action.payload.kpi.rate,
      //   num: action.payload.kpi.num,
      //   isLoading: false,
      //   error: false
      // };
    });
    builder.addCase(fetchProcessKPIPost.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        error: false
      };
    });
    builder.addCase(fetchProcessKPIPost.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: true
      };
    });
  }
});

export const a_salesGraph = (state) => state.processKPI.amazon.salesGraph;
export const a_viewsGraph = (state) => state.processKPI.amazon.viewsGraph;
export const a_CVRGraph = (state) => state.processKPI.amazon.CVRGraph;
export const a_priceGraph = (state) => state.processKPI.amazon.priceGraph;
export const a_groupSalesGraph = (state) => state.processKPI.amazon.g_sales;
export const a_groupViewsGraph = (state) => state.processKPI.amazon.g_views;
export const a_groupCVRGraph = (state) => state.processKPI.amazon.g_cvr;
export const a_groupPriceGraph = (state) => state.processKPI.amazon.g_price;
export const a_kpirate = (state) => state.processKPI.amazon.rate;
export const a_kpiStatus = (state) => state.processKPI.amazon.isLoading;
export const a_kpinum = (state) => state.processKPI.amazon.num;

export const r_salesGraph = (state) => state.processKPI.rakuten.salesGraph;
export const r_viewsGraph = (state) => state.processKPI.rakuten.viewsGraph;
export const r_CVRGraph = (state) => state.processKPI.rakuten.CVRGraph;
export const r_priceGraph = (state) => state.processKPI.rakuten.priceGraph;
export const r_groupSalesGraph = (state) => state.processKPI.rakuten.g_sales;
export const r_groupViewsGraph = (state) => state.processKPI.rakuten.g_views;
export const r_groupCVRGraph = (state) => state.processKPI.rakuten.g_cvr;
export const r_groupPriceGraph = (state) => state.processKPI.rakuten.g_price;
export const r_kpirate = (state) => state.processKPI.rakuten.rate;
export const r_kpiStatus = (state) => state.processKPI.rakuten.isLoading;
export const r_kpinum = (state) => state.processKPI.rakuten.num;

export const y_salesGraph = (state) => state.processKPI.yahoo.salesGraph;
export const y_viewsGraph = (state) => state.processKPI.yahoo.viewsGraph;
export const y_CVRGraph = (state) => state.processKPI.yahoo.CVRGraph;
export const y_priceGraph = (state) => state.processKPI.yahoo.priceGraph;
export const y_groupSalesGraph = (state) => state.processKPI.yahoo.g_sales;
export const y_groupViewsGraph = (state) => state.processKPI.yahoo.g_views;
export const y_groupCVRGraph = (state) => state.processKPI.yahoo.g_cvr;
export const y_groupPriceGraph = (state) => state.processKPI.yahoo.g_price;
export const y_kpirate = (state) => state.processKPI.yahoo.rate;
export const y_kpiStatus = (state) => state.processKPI.yahoo.isLoading;
export const y_kpinum = (state) => state.processKPI.yahoo.num;

// export const salesGraph = (state) => state.processKPI.salesGraph;
// export const viewsGraph = (state) => state.processKPI.viewsGraph;
// export const CVRGraph = (state) => state.processKPI.CVRGraph;
// export const priceGraph = (state) => state.processKPI.priceGraph;

// export const groupSalesGraph = (state) => state.processKPI.g_sales;
// export const groupViewsGraph = (state) => state.processKPI.g_views;
// export const groupCVRGraph = (state) => state.processKPI.g_cvr;
// export const groupPriceGraph = (state) => state.processKPI.g_price;

// export const kpirate = (state) => state.processKPI.rate;
// export const kpiStatus = (state) => state.processKPI.isLoading;

// export const kpinum = (state) => state.processKPI.num;

export default processKPISlice.reducer;
