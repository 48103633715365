/* eslint-disable*/
import {
  Box,
  Dialog,
  Typography,
  DialogContent,
  Link
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkSurvey,
  displayFlag,
  onceOpened,
  error,
  loading,
  setDisplayFlag,
  setOnceOpenedFlag
} from 'feactures/survey/surveySlice';
import { Link as RouterLink } from 'react-router-dom';
import { userinfo } from 'feactures/user/userSlice';

const NoticeDialog = () => {
  const openFlag = useSelector(displayFlag);
  const onceOpenedFlag = useSelector(onceOpened);
  const userInfo = useSelector(userinfo);
  const trialStatus = userInfo.trial;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!onceOpenedFlag) {
      //初回レンダリング時のみ起動（閉じた後にチャンネルを移動しても表示されないように）
      dispatch(setOnceOpenedFlag);
    } else {
      return;
    }
  }, []);

  // ×ボタンクリックでポップアップを非表示にする。
  const handleClick = () => {
    dispatch(setDisplayFlag(false));
  };

  // 時刻部分を00:00:00にリセットする関数
  const resetTime = (date) =>
    new Date(date.getFullYear(), date.getMonth(), date.getDate());

  // 日本語形式で日付をフォーマットする関数
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${month}月${day}日`;
  };

  const today = resetTime(new Date());

  return (
    <>
      {openFlag && (
        <Dialog open={openFlag} onClose={handleClick} maxWidth="700px">
          <DialogContent
            sx={{
              display: 'flex',
              padding: '20px 20px',
              bgcolor: userInfo.trial.trial_notice ? '#FFE4DC' : '#FFF8E5'
            }}
          >
            {userInfo.trial.trial_notice && (
              <Box
                sx={{ padding: '10px 10px' }}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                my={1}
                width="700px"
                height="350px"
              >
                <Box
                  m={1}
                  px={2}
                  py={8}
                  width="650px"
                  height="300px"
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  bgcolor="#FFFFFF"
                >
                  <Typography fontSize={30} fontWeight="bold" mb={2}>
                    トライアルプランをご利用中です！
                  </Typography>
                  {Object.entries(trialStatus)
                    .filter(([mall, info]) => {
                      if (!info.end_date) return false;
                      const endDate = new Date(info.end_date);
                      return info.trial && endDate >= today;
                    })
                    .map(([mall, info]) => {
                      const endDate = new Date(info.end_date);
                      const remainingDays = Math.ceil(
                        (endDate - today) / (1000 * 60 * 60 * 24)
                      );
                      return (
                        <Box
                          key={mall}
                          display="flex"
                          flexDirection="row"
                          alignItems="end"
                          justifyContent="center"
                          gap={3}
                          mb={1}
                        >
                          <Typography fontSize={25} fontWeight="bold">
                            モール：{mall}
                          </Typography>
                          <Typography fontSize={28} fontWeight="bold">
                            残り{remainingDays}日
                          </Typography>
                          <Typography fontSize={25} fontWeight="bold">
                            {formatDate(endDate)} 23:59まで
                          </Typography>
                        </Box>
                      );
                    })}
                </Box>
              </Box>
            )}
            {userInfo.trial.advertise && (
              <Box
                sx={{ padding: '10px 10px' }}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                my={1}
                width="800px"
                height="350px"
              >
                <Box
                  m={1}
                  px={2}
                  py={8}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  bgcolor="#FFFFFF"
                >
                  <Typography fontSize={27} fontWeight="bold" mb={1}>
                    トライアルのご利用ありがとうございました。
                  </Typography>
                  <Typography fontSize={27} fontWeight="bold" mb={1}>
                    この機会にぜひ有料プランへの切り替えをお願いします。
                  </Typography>
                  <Typography fontSize={24} fontWeight="bold" mb={1}>
                    有料プラン：5万円(税抜)/月
                  </Typography>
                  <Typography fontSize={24} fontWeight="bold" mb={1}>
                    有料プランへの切り替えは
                    <Link
                      component="a"
                      target="_blank"
                      rel="noopener noreferrer"
                      fontSize={24}
                      href="https://www.oxcim.net/plan-change"
                    >
                      こちら
                    </Link>
                  </Typography>
                </Box>
              </Box>
            )}
            <CloseIcon
              onClick={handleClick}
              sx={{ alignSelf: 'flex-start', cursor: 'pointer' }}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default NoticeDialog;
