/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const fetchAsyncMyproducts = createAsyncThunk(
  'myproducts/get',
  async (_, thunkAPI) => {
    const { yearMonth, kubun } = thunkAPI.getState().currentsection;
    const { channel } = thunkAPI.getState().currentchannel;
    const { accountId, accountName, userId, isLoggedIn, userName } =
      thunkAPI.getState().user;
    const res = await client.post(`${apiUrl}my_products`, {
      channel: channel,
      account_id: accountId,
      sales_type: kubun,
      select_month: yearMonth
    });
    return res.data;
  }
);

const fetchMyproductsSlice = createSlice({
  name: 'myproducts',
  initialState: {
    myproducts: [[], [], []],
    graphdata: [0, 0, 0],
    totalsalesData: [0, 0, 0],
    totalunitData: [0, 0, 0],
    lastmonthRate: [0, 0, 0],
    lastyearRate: [0, 0, 0]
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAsyncMyproducts.fulfilled, (state, action) => {
      return {
        ...state,
        myproducts: [
          action.payload.amazon_product_list,
          action.payload.rakuten_product_list,
          action.payload.yahoo_product_list
        ],
        graphdata: [
          action.payload.amazon_myproducts_graphdata,
          action.payload.rakuten_myproducts_graphdata,
          action.payload.yahoo_myproducts_graphdata
        ],
        totalsalesData: [
          action.payload.amazon_myproducts_total_sales,
          action.payload.rakuten_myproducts_total_sales,
          action.payload.yahoo_myproducts_total_sales
        ],
        totalunitData: [
          action.payload.amazon_myproducts_total_unit,
          action.payload.rakuten_myproducts_total_unit,
          action.payload.yahoo_myproducts_total_unit
        ],
        lastmonthRate: [
          action.payload.amazon_myproducts_lastmonth_rate,
          action.payload.rakuten_myaproducts_lastmonth_rate,
          action.payload.yahoo_myproducts_lastmonth_rate
        ],
        lastyearRate: [
          action.payload.amazon_myproducts_lastyear_rate,
          action.payload.rakuten_myproducts_lastyear_rate,
          action.payload.yahoo_myproducts_lastyear_rate
        ],
        isLoading: false
      };
    });
    builder.addCase(fetchAsyncMyproducts.pending, (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    });
    builder.addCase(fetchAsyncMyproducts.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    });
  }
});

export const selectMyproducts = (state) => state.myproducts.myproducts;
export const selectGraphdata = (state) => state.myproducts.graphdata;
export const selectSalesFigures = (state) => state.myproducts.totalsalesData;
export const selectUnitFigures = (state) => state.myproducts.totalunitData;
export const selectLastMonthRate = (state) => state.myproducts.lastmonthRate;
export const selectLastYearRate = (state) => state.myproducts.lastyearRate;
export const myproductsStatus = (state) => state.myproducts.isLoading;

export default fetchMyproductsSlice.reducer;
