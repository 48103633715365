/* eslint-disable */
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 350
  }
});

const OwnMeasureTableRakuten = (props) => {
  const { sales, yokoziku } = props; // eslint-disable-line
  const classes = useStyles();
  // const sales = [
  //   5678, 5432, 8765, 2109, 4321, 7890, 1234, 9012, 3456, 2345, 6789, 4567
  // ];
  const getMaxSales = Math.max(...sales);
  const getMinSales = Math.min(...sales);
  const sortedSales = [...sales].sort((a, b) => a - b);
  const getMedianSales = sortedSales[Math.floor(sortedSales.length / 2)];
  const titleChange = [7, 23, 45, 12, 34, 56, 89, 3, 72, 18, 91, 5];
  const getMaxTitleChange = Math.max(...titleChange);
  const getMinTitleChange = Math.min(...titleChange);
  const sortedTitleChange = [...titleChange].sort((a, b) => a - b);
  const getMedianTitleChange =
    sortedTitleChange[Math.floor(sortedTitleChange.length / 2)];
  const rpp = [14, 68, 27, 40, 11, 63, 92, 20, 33, 6, 88, 2];
  const getMaxRpp = Math.max(...rpp);
  const getMinRpp = Math.min(...rpp);
  const sortedRpp = [...rpp].sort((a, b) => a - b);
  const getMedianRpp = sortedRpp[Math.floor(sortedRpp.length / 2)];
  const priceChange = [30, 8, 57, 19, 75, 46, 10, 82, 64, 13, 53, 1];
  const getMaxPriceChange = Math.max(...priceChange);
  const getMinPriceChange = Math.min(...priceChange);
  const sortedPriceChange = [...priceChange].sort((a, b) => a - b);
  const getMedianPriceChange =
    sortedPriceChange[Math.floor(sortedPriceChange.length / 2)];
  const point = [41, 87, 25, 60, 38, 77, 9, 15, 70, 4, 29, 78];
  const getMaxPoint = Math.max(...point);
  const getMinPoint = Math.min(...point);
  const sortedPoint = [...point].sort((a, b) => a - b);
  const getMedianPoint = sortedPoint[Math.floor(sortedPoint.length / 2)];
  const rankin = [83, 26, 61, 28, 71, 17, 42, 50, 35, 76, 32, 59];
  const getMaxRankin = Math.max(...rankin);
  const getMinRankin = Math.min(...rankin);
  const sortedRankin = [...rankin].sort((a, b) => a - b);
  const getMedianRankin = sortedRankin[Math.floor(sortedRankin.length / 2)];

  const columnList = [
    '売上',
    'タイトル変更',
    '価格変更',
    'ポイント',
    'ランキング入り'
  ];

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{ color: '#666666' }}>
              自社店舗
            </TableCell>
            {yokoziku.map((term) => (
              <TableCell align="center" sx={{ color: '#666666' }} key={term}>
                {term.split('/')[1]}月
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={columnList[0]}>
            <TableCell
              align="center"
              component="th"
              scope="row"
              sx={{ color: '#666666' }}
            >
              {columnList[0]}
            </TableCell>
            {sales.map((element, index) => (
              <TableCell
                key={index}
                align="center"
                component="th"
                scope="row"
                sx={{
                  backgroundColor:
                    element === 0
                      ? '#FFFFFF'
                      : element === getMaxSales
                      ? '#003366'
                      : element === getMedianSales
                      ? '#0066CC'
                      : element === getMinSales
                      ? '#99CCFF'
                      : '#FFFFFF',
                  color:
                    element === 0
                      ? '#666666'
                      : element === getMaxSales
                      ? '#FFFFFF'
                      : element === getMedianSales
                      ? '#FFFFFF'
                      : element === getMinSales
                      ? '#FFFFFF'
                      : '#666666'
                }}
              >
                {element.toLocaleString()}
              </TableCell> //売上
            ))}
          </TableRow>
          <TableRow key={columnList[1]}>
            <TableCell
              align="center"
              component="th"
              scope="row"
              sx={{ color: '#666666' }}
            >
              {columnList[1]}
            </TableCell>
            {titleChange.map((element, index) => (
              <TableCell
                key={index}
                align="center"
                component="th"
                scope="row"
                sx={{
                  backgroundColor:
                    element === 0
                      ? '#FFFFFF'
                      : element === getMaxTitleChange
                      ? '#003366'
                      : element === getMedianTitleChange
                      ? '#0066CC'
                      : element === getMinTitleChange
                      ? '#99CCFF'
                      : '#FFFFFF',
                  color:
                    element === 0
                      ? '#666666'
                      : element === getMaxTitleChange
                      ? '#FFFFFF'
                      : element === getMedianTitleChange
                      ? '#FFFFFF'
                      : element === getMinTitleChange
                      ? '#FFFFFF'
                      : '#666666'
                }}
              >
                {element}
              </TableCell> //タイトル変更
            ))}
          </TableRow>
          <TableRow key={columnList[2]}>
            <TableCell
              align="center"
              component="th"
              scope="row"
              sx={{ color: '#666666' }}
            >
              {columnList[2]}
            </TableCell>
            {priceChange.map((element, index) => (
              <TableCell
                key={index}
                align="center"
                component="th"
                scope="row"
                sx={{
                  backgroundColor:
                    element === 0
                      ? '#FFFFFF'
                      : element === getMaxPriceChange
                      ? '#003366'
                      : element === getMedianPriceChange
                      ? '#0066CC'
                      : element === getMinPriceChange
                      ? '#99CCFF'
                      : '#FFFFFF',
                  color:
                    element === 0
                      ? '#666666'
                      : element === getMaxPriceChange
                      ? '#FFFFFF'
                      : element === getMedianPriceChange
                      ? '#FFFFFF'
                      : element === getMinPriceChange
                      ? '#FFFFFF'
                      : '#666666'
                }}
              >
                {element}
              </TableCell> //価格変更
            ))}
          </TableRow>
          <TableRow key={columnList[3]}>
            <TableCell
              align="center"
              component="th"
              scope="row"
              sx={{ color: '#666666' }}
            >
              {columnList[3]}
            </TableCell>
            {point.map((element, index) => (
              <TableCell
                key={index}
                align="center"
                component="th"
                scope="row"
                sx={{
                  backgroundColor:
                    element === 0
                      ? '#FFFFFF'
                      : element === getMaxPoint
                      ? '#003366'
                      : element === getMedianPoint
                      ? '#0066CC'
                      : element === getMinPoint
                      ? '#99CCFF'
                      : '#FFFFFF',
                  color:
                    element === 0
                      ? '#666666'
                      : element === getMaxPoint
                      ? '#FFFFFF'
                      : element === getMedianPoint
                      ? '#FFFFFF'
                      : element === getMinPoint
                      ? '#FFFFFF'
                      : '#666666'
                }}
              >
                {element}
              </TableCell> //ポイント
            ))}
          </TableRow>
          <TableRow key={columnList[4]}>
            <TableCell
              align="center"
              component="th"
              scope="row"
              sx={{ color: '#666666' }}
            >
              {columnList[4]}
            </TableCell>
            {rankin.map((element, index) => (
              <TableCell
                key={index}
                align="center"
                component="th"
                scope="row"
                sx={{
                  backgroundColor:
                    element === 0
                      ? '#FFFFFF'
                      : element === getMaxRankin
                      ? '#003366'
                      : element === getMedianRankin
                      ? '#0066CC'
                      : element === getMinRankin
                      ? '#99CCFF'
                      : '#FFFFFF',
                  color:
                    element === 0
                      ? '#666666'
                      : element === getMaxRankin
                      ? '#FFFFFF'
                      : element === getMedianRankin
                      ? '#FFFFFF'
                      : element === getMinRankin
                      ? '#FFFFFF'
                      : '#666666'
                }}
              >
                {element}
              </TableCell> //ランキング
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OwnMeasureTableRakuten;
