/* eslint-disable */
import { Bar } from 'react-chartjs-2';
import { Box, Card, CardContent, useTheme } from '@material-ui/core';
import { useEffect } from 'react';

const CategoryTotalgraph = (props) => {
  const { mall, color, graphdata, categories, channel, yokoziku } = props; // eslint-disable-line
  const theme = useTheme();
  const data = {
    datasets: [
      {
        // グラフの色
        backgroundColor: 'rgba(113, 140, 198, 0.5)',
        borderColor: '#718CC6',
        titleColor: '#718CC6',
        data: graphdata,
        // label: mall,
        maxBarThickness: 30,
        barThickness: 'flex'
      }
    ],
    labels: yokoziku
  };
  const options = {
    interaction: {
      mode: 'nearest',
      intersect: false
    },
    animation: true,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        ticks: {
          fontColor: theme.palette.text.secondary,
          font: {
            family: "'M PLUS 1p', sans-serif",
            size: '10px'
          }
        },
        grid: {
          display: false,
          drawBorder: false
        },
        title: {
          display: false
        }
      },
      y: {
        ticks: {
          fontColor: theme.palette.text.secondary,
          font: {
            family: "'M PLUS 1p', sans-serif"
          },
          beginAtZero: true,
          min: 1,
          callback: function (value, index, values) {
            let returnValue = value;
            let returnStr = '';
            if (value >= 100000000) {
              returnValue = value / 100000000;
              returnStr = returnValue.toLocaleString() + '億円';
            } else if (value >= 1000000) {
              returnValue = value / 1000000;
              returnStr = returnValue.toLocaleString() + '百万円';
            } else if (value >= 10000) {
              returnValue = value / 10000;
              returnStr = returnValue.toLocaleString() + '万円';
            } else {
              returnValue = value;
              returnStr = returnValue.toLocaleString() + '円';
            }
            // ラベルの前に￥マークを追加
            return returnStr;
          }
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider
        }
      }
    },
    plugins: {
      tooltip: {
        titleFont: {
          family: "'M PLUS 1p', sans-serif"
        },
        bodyFont: {
          family: "'M PLUS 1p', sans-serif"
        }
        // callbacks: {
        //   label: function (tooltipItem, y) {
        //     let label = tooltipItem.dataset.label;
        //     let data = tooltipItem.raw;
        //     label += ' : ' + data.toLocaleString() + tanni;
        //     return label;
        //   }
        // }
      },
      legend: {
        display: false,
        labels: {
          boxWidth: 10,
          boxHeight: 10
        }
      }, //ラベル名(凡例)非表示
      // tooltips: {
      //   mode: 'x'
      // },
      datalabels: {
        display: false
      }
    }
  };
  // prettier-ignore
  return (
    <Card {...props} sx={{padding: 0}}>
      <CardContent sx={{padding: 0, display:"flex", justifyContent: "center", alignItems:'center', ":last-child":{pb: 0}}}>
        <Box
          sx={{
            height: 300,
            width: "100%"
           
          }}
        >
          <Bar
            data={data}
            options={options}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default CategoryTotalgraph;
