/* eslint-disable */
import { Switch } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import { useState, useEffect, memo, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { currentchannel } from 'feactures/channel/channelSlice';
import {
  changeTermCompareStatus,
  termCompare,
  resetCompareGraph
} from 'feactures/monitoring/monitoringSlice';

const StyledSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2
  }
}));

const SwitchButton = () => {
  const dispatch = useDispatch();
  const channel = useSelector(currentchannel);
  const termCompareStatus = useSelector(termCompare);

  const radioHandle = (e) => {
    if (!e.target.checked) {
      //期間比較OFF
      dispatch(changeTermCompareStatus(false));
      dispatch(resetCompareGraph());
      // dispatchHandle(page);
    } else {
      //期間比較ON
      dispatch(changeTermCompareStatus(true));
      dispatch(resetCompareGraph());
      // dispatchHandle(page);
    }
  };
  return (
    <>
      <StyledSwitch
        onClick={radioHandle}
        sx={{ zIndex: 100 }}
        checked={termCompareStatus}
      />
    </>
  );
};

export default SwitchButton;
