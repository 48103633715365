/* eslint-disable*/
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  Snackbar,
  Alert
} from '@material-ui/core';
import { fetchChangePass } from 'feactures/user/userSlice';

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const modoru = () => {
    navigate(-1);
  };

  const onSubmit = async (values) => {
    setError(null);
    await dispatch(fetchChangePass(values))
      .then(unwrapResult) //unwrapResult で,dispatch結果がfulfilledかrejectedかで条件分岐できる
      .then(() => {
        setSuccess(true);
        setTimeout(function () {
          navigate(-1);
        }, 1000);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const passwordExp =
    /^(?=.*?[a-z])(?=.*?\d)(?=.*?[!-\/:-@[-`{-~])[!-~]{8,100}$/i;

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#FFF8E5',
          height: 'auto',
          minHeight: '100vh',
          width: '100%',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Container sx={{ width: 'auto', py: 3 }}>
          <Typography fontSize="16px" fontWeight="bold">
            パスワード変更
          </Typography>
          <Typography color="textSecondary" gutterBottom variant="body2">
            下記に入力し、「パスワードを変更する」ボタンをクリックしてください。
          </Typography>
          <Formik
            initialValues={{
              imaPass: '',
              newPass: '',
              kakuninPass: ''
            }}
            validationSchema={Yup.object().shape({
              imaPass: Yup.string()
                .required('未入力です。')
                .min(8, 'パスワードは最低8文字です。'),
              // .matches(/^[0-9a-zA-Z]+$/, '半額英数字のみ有効です'),
              newPass: Yup.string()
                .required('未入力です。')
                .min(8, 'パスワードは最低8文字です。')
                .matches(
                  passwordExp,
                  '半角英数字記号を組み合わせて入力してください'
                ),
              kakuninPass: Yup.string()
                .oneOf([Yup.ref('newPass')], 'パスワードが一致しません')
                .required('未入力です。')
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box>
                  <Container mt={7} maxWidth="sm">
                    現在のパスワード：
                    <TextField
                      error={Boolean(touched.imaPass && errors.imaPass)}
                      fullWidth
                      helperText={touched.imaPass && errors.imaPass}
                      margin="normal"
                      name="imaPass"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.imaPass}
                      sx={{ bgcolor: '#FFFFFF' }}
                    />
                    新しいパスワード：
                    <TextField
                      error={Boolean(touched.newPass && errors.newPass)}
                      fullWidth
                      helperText={touched.newPass && errors.newPass}
                      margin="normal"
                      name="newPass"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.newPass}
                      sx={{ bgcolor: '#FFFFFF' }}
                    />
                    新しいパスワード（再入力）：
                    <TextField
                      error={Boolean(touched.kakuninPass && errors.kakuninPass)}
                      fullWidth
                      helperText={touched.kakuninPass && errors.kakuninPass}
                      margin="normal"
                      name="kakuninPass"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.kakuninPass}
                      sx={{ bgcolor: '#FFFFFF' }}
                    />
                    <Box sx={{ py: 2 }}>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        パスワードを変更する
                      </Button>
                      {error && (
                        <Alert severity="error">
                          入力に誤りがあります。パスワードをご確認ください。
                        </Alert>
                      )}
                    </Box>
                  </Container>
                </Box>
              </form>
            )}
          </Formik>
          <Typography color="textSecondary" variant="body1">
            <button onClick={() => modoru()}>←戻る</button>
          </Typography>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={success}
            message="パスワードを変更しました"
          />
        </Container>
      </Box>
    </>
  );
};

export default ChangePassword;
