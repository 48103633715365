/* eslint-disable */
//画面で表示されているグラフ値をエクスポートする
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const fetchExport = createAsyncThunk('export', async (arg, thunkAPI) => {
  const { graph_name, page, titleJa, graph_name2 } = arg;
  const { userId, accountId } = thunkAPI.getState().user;
  const { channel, benchmark } = thunkAPI.getState().currentchannel;
  const { alignment, startDate, endDate } = thunkAPI.getState().asinDate;
  const { searchAsin } = thunkAPI.getState().asins;
  const { checkGroupList } = thunkAPI.getState().group;

  const res = await client.post(`${apiUrl}download`, {
    start: startDate,
    end: endDate,
    term: alignment,
    channel: channel,
    user_id: userId,
    account_id: accountId,
    page: page,
    graph_name: graph_name,
    graph_name2: graph_name2,
    graph_name_ja: titleJa,
    search_asin: searchAsin,
    group_list: checkGroupList,
    comparing_type: Number(benchmark)
  });
  var bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const url = window.URL.createObjectURL(new Blob([bom, res.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'download.csv');
  document.body.appendChild(link);
  link.click();
  link.remove();
  return res.data;
});

export const fetchExport_salesByAsin = createAsyncThunk(
  'export_salesByAsin',
  async (arg, thunkAPI) => {
    const { userId, accountId } = thunkAPI.getState().user;
    const { channel } = thunkAPI.getState().currentchannel;
    const { alignment, startDate, endDate } = thunkAPI.getState().asinDate;

    const res = await client.post(`${apiUrl}download/salesByAsin`, {
      start: startDate,
      end: endDate,
      term: alignment,
      channel: channel,
      user_id: userId,
      account_id: accountId
    });
    var bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const url = window.URL.createObjectURL(new Blob([bom, res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'download.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
    return res.data;
  }
);

export const fetchExport_categoryRank = createAsyncThunk(
  'export_categoryRank',
  async (arg, thunkAPI) => {
    const {
      a_cate1,
      a_cate2,
      a_cate3,
      a_cate4,
      a_cate5,
      a_cate6,
      r_cate1,
      r_cate2,
      r_cate3,
      r_cate4,
      r_cate5,
      y_cate1,
      y_cate2,
      y_cate3,
      y_cate4
    } = thunkAPI.getState().categorygraph;
    const { yearMonth } = thunkAPI.getState().currentsection;
    const { channel } = thunkAPI.getState().currentchannel;
    const { userId } = thunkAPI.getState().user;
    const res = await client.post(`${apiUrl}download/categoryRank`, {
      amazon_category: [a_cate1, a_cate2, a_cate3, a_cate4, a_cate5, a_cate6],
      rakuten_category: [r_cate1, r_cate2, r_cate3, r_cate4, r_cate5],
      yahoo_category: [y_cate1, y_cate2, y_cate3, y_cate4],
      term: yearMonth,
      channel: channel,
      user_id: userId
    });
    var bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const url = window.URL.createObjectURL(new Blob([bom, res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `category_top100_${yearMonth.replace('-', '_')}.csv`
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
    return res.data;
  }
);

export const fetchExport_brandAnalysis = createAsyncThunk(
  'export_brandAnalysis',
  async (arg, thunkAPI) => {
    const {
      a_cate1,
      a_cate2,
      a_cate3,
      a_cate4,
      a_cate5,
      a_cate6,
      r_cate1,
      r_cate2,
      r_cate3,
      r_cate4,
      r_cate5,
      y_cate1,
      y_cate2,
      y_cate3,
      y_cate4
    } = thunkAPI.getState().categorygraph;
    const { yearMonth } = thunkAPI.getState().currentsection;
    const { channel } = thunkAPI.getState().currentchannel;
    const { userId } = thunkAPI.getState().user;
    const { otherSelectedBrand } = thunkAPI.getState().brand;
    let amazon_brand_id = null;
    let rakuten_brand_id = null;
    let yahoo_brand_id = null;
    let amazon_brand_name = null;
    let rakuten_brand_name = null;
    let yahoo_brand_name = null;
    if (channel === 1 && otherSelectedBrand.amazon !== '') {
      amazon_brand_id = otherSelectedBrand.amazon.id;
      amazon_brand_name = otherSelectedBrand.amazon.brand;
    } else if (channel === 2 && otherSelectedBrand.rakuten !== '') {
      rakuten_brand_id = otherSelectedBrand.rakuten.id;
      rakuten_brand_name = otherSelectedBrand.rakuten.brand;
    } else if (channel === 3 && otherSelectedBrand.yahoo !== '') {
      yahoo_brand_id = otherSelectedBrand.yahoo.id;
      yahoo_brand_name = otherSelectedBrand.yahoo.brand;
    }
    const res = await client.post(`${apiUrl}download/brandAnalysis`, {
      amazon_category: [a_cate1, a_cate2, a_cate3, a_cate4, a_cate5, a_cate6],
      rakuten_category: [r_cate1, r_cate2, r_cate3, r_cate4, r_cate5],
      yahoo_category: [y_cate1, y_cate2, y_cate3, y_cate4],
      amazon_brand: amazon_brand_id,
      rakuten_brand: rakuten_brand_id,
      yahoo_brand: yahoo_brand_id,
      amazon_brand_name: amazon_brand_name,
      rakuten_brand_name: rakuten_brand_name,
      yahoo_brand_name: yahoo_brand_name,
      term: yearMonth,
      channel: channel,
      user_id: userId
    });
    var bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const url = window.URL.createObjectURL(new Blob([bom, res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `brand_items_${yearMonth.replace('-', '_')}.csv`
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
    return res.data;
  }
);

export const fetchExport_productAnalysis = createAsyncThunk(
  'export_productAnalysis',
  async (arg, thunkAPI) => {
    const { yearMonth } = thunkAPI.getState().currentsection;
    const { channel } = thunkAPI.getState().currentchannel;
    const { accountId } = thunkAPI.getState().user;
    const res = await client.post(`${apiUrl}download/productAnalysis`, {
      channel: channel,
      account_id: accountId,
      select_month: yearMonth
    });
    var bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const url = window.URL.createObjectURL(new Blob([bom, res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `items_${yearMonth.replace('-', '_')}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
    return res.data;
  }
);

const exportSlice = createSlice({
  name: 'export',
  initialState: {
    csvData: {},
    csvData_salesByasin: {},
    csvData_categoryRank: {},
    csvData_BrandAnalysis: {},
    csvData_ProductAnalysis: {}
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchExport.fulfilled, (state, action) => {
      return {
        ...state,
        csvData: action.payload.csv_data,
        isLoading: false
      };
    });
    builder.addCase(fetchExport.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        error: false
      };
    });
    builder.addCase(fetchExport.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    });
    builder.addCase(fetchExport_salesByAsin.fulfilled, (state, action) => {
      return {
        ...state,
        csvData_salesByasin: action.payload.csv_data,
        isLoading_salesByasin: false
      };
    });
    builder.addCase(fetchExport_salesByAsin.pending, (state, action) => {
      return {
        ...state,
        isLoading_salesByasin: true,
        error: false
      };
    });
    builder.addCase(fetchExport_salesByAsin.rejected, (state, action) => {
      return {
        ...state,
        isLoading_salesByasin: false
      };
    });
    builder.addCase(fetchExport_categoryRank.fulfilled, (state, action) => {
      return {
        ...state,
        csvData_categoryRank: action.payload.csv_data,
        isLoading_categoryRank: false
      };
    });
    builder.addCase(fetchExport_categoryRank.pending, (state, action) => {
      return {
        ...state,
        isLoading_categoryRank: true,
        error: false
      };
    });
    builder.addCase(fetchExport_categoryRank.rejected, (state, action) => {
      return {
        ...state,
        isLoading_categoryRank: false
      };
    });
    builder.addCase(fetchExport_brandAnalysis.fulfilled, (state, action) => {
      return {
        ...state,
        csvData_brandAnalysis: action.payload.csv_data,
        isLoading_brandAnalysis: false
      };
    });
    builder.addCase(fetchExport_brandAnalysis.pending, (state, action) => {
      return {
        ...state,
        isLoading_brandAnalysis: true,
        error: false
      };
    });
    builder.addCase(fetchExport_brandAnalysis.rejected, (state, action) => {
      return {
        ...state,
        isLoading_brandAnalysis: false
      };
    });
    builder.addCase(fetchExport_productAnalysis.fulfilled, (state, action) => {
      return {
        ...state,
        csvData_productAnalysis: action.payload.csv_data,
        isLoading_productAnalysis: false
      };
    });
    builder.addCase(fetchExport_productAnalysis.pending, (state, action) => {
      return {
        ...state,
        isLoading_productAnalysis: true,
        error: false
      };
    });
    builder.addCase(fetchExport_productAnalysis.rejected, (state, action) => {
      return {
        ...state,
        isLoading_productAnalysis: false
      };
    });
  }
});

export const csvData = (state) => state.export.csvData;
export const exportStatus = (state) => state.export.isLoading;
export const csvData_salesByasin = (state) => state.export.csvData;
export const exportStatus_salesByasin = (state) => state.export.isLoading;
export const exportStatus_categoryRank = (state) =>
  state.export.isLoading_categoryRank;
export const exportStatus_brandAnalysis = (state) =>
  state.export.isLoading_brandAnalysis;
export const exportStatus_productAnalysis = (state) =>
  state.export.isLoading_productAnalysis;

export default exportSlice.reducer;
