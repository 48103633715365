/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const fetchBrandList = createAsyncThunk(
  'brandlist/post',
  async (_, thunkAPI) => {
    const { channel } = thunkAPI.getState().currentchannel;
    const { yearMonth } = thunkAPI.getState().currentsection;
    const currentCategories = thunkAPI.getState().categorygraph;
    const pageCount = thunkAPI.getState().brand.pageCount;
    const res = await client.post(`${apiUrl}brand/list`, {
      channel: channel,
      term: yearMonth,
      categories: currentCategories,
      pageCount: pageCount
    });
    return res.data;
  }
);

export const fetchOwnBrandList = createAsyncThunk(
  'brandlist/own',
  async (arg, thunkAPI) => {
    const { searchword } = arg;
    const { channel } = thunkAPI.getState().currentchannel;
    const pageCount = thunkAPI.getState().brand.pageCount;
    const {
      a_cate1,
      a_cate2,
      a_cate3,
      a_cate4,
      a_cate5,
      a_cate6,
      r_cate1,
      r_cate2,
      r_cate3,
      r_cate4,
      r_cate5,
      r_cate6,
      y_cate1,
      y_cate2,
      y_cate3,
      y_cate4,
      y_cate5,
      y_cate6
    } = thunkAPI.getState().categorygraph;
    const { accountId } = thunkAPI.getState().user;
    let categories = [];
    if (channel === 1) {
      categories = [a_cate1, a_cate2, a_cate3, a_cate4, a_cate5, a_cate6];
    } else if (channel === 2) {
      categories = [r_cate1, r_cate2, r_cate3, r_cate4, r_cate5, r_cate6];
    } else if (channel === 3) {
      categories = [y_cate1, y_cate2, y_cate3, y_cate4, y_cate5, y_cate6];
    }
    let level = 0;
    let currentId = 'all';

    for (let i = 0; i < 6; i++) {
      if (categories[i] === 0) {
        continue;
      } else {
        level = i + 1;
        currentId = categories[i].value;
      }
    }

    const res = await client.post(`${apiUrl}brand/list/own`, {
      channel: channel,
      pageCount: pageCount,
      selected_category: currentId,
      level: level,
      searchword: searchword,
      account_id: accountId
    });
    return res.data;
  }
);

export const fetchSearchBrandList = createAsyncThunk(
  '/brandlist/search',
  async (arg, thunkAPI) => {
    const { keyword } = arg;
    const { channel } = thunkAPI.getState().currentchannel;
    const { yearMonth } = thunkAPI.getState().currentsection;
    const currentCategories = thunkAPI.getState().categorygraph;
    const pageCount = thunkAPI.getState().brand.pageCount;
    const res = await client.post(`${apiUrl}brand/list/search`, {
      channel: channel,
      term: yearMonth,
      categories: currentCategories,
      pageCount: pageCount,
      keyword: keyword
    });
    return res.data;
  }
);

const brandSlice = createSlice({
  name: 'brand',
  initialState: {
    brandList: {
      amazon: [],
      rakuten: [],
      yahoo: []
    },
    ownBrandList: {
      amazon: [],
      rakuten: [],
      yahoo: []
    },
    ownSelectedBrand: {
      amazon: '',
      rakuten: '',
      yahoo: ''
    },
    ownCheckedBrand: {
      amazon: '',
      rakuten: '',
      yahoo: ''
    },
    otherSelectedBrand: {
      amazon: '',
      rakuten: '',
      yahoo: ''
    },
    otherCheckedBrand: {
      amazon: '',
      rakuten: '',
      yahoo: ''
    },
    allBrandLength: {
      amazon: '',
      rakuten: '',
      yahoo: ''
    },
    allOwnBrandLength: {
      amazon: '',
      rakuten: '',
      yahoo: ''
    },
    pageCount: 1,
    isLoading: false,
    isOwnLoading: false
  },
  reducers: {
    setOwnSelectedBrandList(state, action) {
      if (action.payload.channel == 1) {
        state.ownSelectedBrand.amazon = action.payload.list;
      } else if (action.payload.channel == 2) {
        state.ownSelectedBrand.rakuten = action.payload.list;
      } else if (action.payload.channel == 3) {
        state.ownSelectedBrand.yahoo = action.payload.list;
      }
    },
    setOtherSelectedBrandList(state, action) {
      if (action.payload.channel == 1) {
        state.otherSelectedBrand.amazon = action.payload.list;
      } else if (action.payload.channel == 2) {
        state.otherSelectedBrand.rakuten = action.payload.list;
      } else if (action.payload.channel == 3) {
        state.otherSelectedBrand.yahoo = action.payload.list;
      }
    },
    resetSelectedBrandList(state, action) {
      if (action.payload.channel == 1) {
        state.ownSelectedBrand.amazon = '';
        state.otherSelectedBrand.amazon = '';
      } else if (action.payload.channel == 2) {
        state.ownSelectedBrand.rakuten = '';
        state.otherSelectedBrand.rakuten = '';
      } else if (action.payload.channel == 3) {
        state.ownSelectedBrand.yahoo = '';
        state.otherSelectedBrand.yahoo = '';
      }
    },
    resetCheckedBrandList(state, action) {
      if (action.payload.channel == 1) {
        state.ownCheckedBrand.amazon = '';
        state.otherCheckedBrand.amazon = '';
      } else if (action.payload.channel == 2) {
        state.ownCheckedBrand.rakuten = '';
        state.otherCheckedBrand.rakuten = '';
      } else if (action.payload.channel == 3) {
        state.ownCheckedBrand.yahoo = '';
        state.otherCheckedBrand.yahoo = '';
      }
    },
    setOwnCheckedBrandList(state, action) {
      if (action.payload.channel == 1) {
        state.ownCheckedBrand.amazon = action.payload.list;
      } else if (action.payload.channel == 2) {
        state.ownCheckedBrand.rakuten = action.payload.list;
      } else if (action.payload.channel == 3) {
        state.ownCheckedBrand.yahoo = action.payload.list;
      }
    },
    setOtherCheckedBrandList(state, action) {
      if (action.payload.channel == 1) {
        state.otherCheckedBrand.amazon = action.payload.list;
      } else if (action.payload.channel == 2) {
        state.otherCheckedBrand.rakuten = action.payload.list;
      } else if (action.payload.channel == 3) {
        state.otherCheckedBrand.yahoo = action.payload.list;
      }
    },
    changePageCount(state, action) {
      if (action.payload === 0) {
        state.pageCount = 1;
      } else {
        state.pageCount += action.payload;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBrandList.fulfilled, (state, action) => {
      if (action.payload.channel == 1) {
        state.brandList.amazon = action.payload.brandList;
        state.isLoading = false;
        state.allBrandLength.amazon = action.payload.brandListLength;
      } else if (action.payload.channel == 2) {
        state.brandList.rakuten = action.payload.brandList;
        state.isLoading = false;
        state.allBrandLength.rakuten = action.payload.brandListLength;
      } else if (action.payload.channel == 3) {
        state.brandList.yahoo = action.payload.brandList;
        state.isLoading = false;
        state.allBrandLength.yahoo = action.payload.brandListLength;
      }
    });
    builder.addCase(fetchBrandList.pending, (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    });
    builder.addCase(fetchBrandList.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    });
    builder.addCase(fetchOwnBrandList.fulfilled, (state, action) => {
      if (action.payload.channel == 1) {
        state.ownBrandList.amazon = action.payload.brandList;
        state.isOwnLoading = false;
        state.allOwnBrandLength.amazon = action.payload.brandListLength;
      } else if (action.payload.channel == 2) {
        state.ownBrandList.rakuten = action.payload.brandList;
        state.isOwnLoading = false;
        state.allOwnBrandLength.rakuten = action.payload.brandListLength;
      } else if (action.payload.channel == 3) {
        state.ownBrandList.yahoo = action.payload.brandList;
        state.isOwnLoading = false;
        state.allOwnBrandLength.yahoo = action.payload.brandListLength;
      }
    });
    builder.addCase(fetchOwnBrandList.pending, (state, action) => {
      return {
        ...state,
        isOwnLoading: true
      };
    });
    builder.addCase(fetchOwnBrandList.rejected, (state, action) => {
      return {
        ...state,
        isOwnLoading: false
      };
    });
    builder.addCase(fetchSearchBrandList.fulfilled, (state, action) => {
      if (action.payload.channel === 1) {
        state.brandList.amazon = action.payload.brandList;
        state.isLoading = false;
        state.allBrandLength.amazon = action.payload.brandListLength;
      } else if (action.payload.channel === 2) {
        state.brandList.rakuten = action.payload.brandList;
        state.isLoading = false;
        state.allBrandLength.rakuten = action.payload.brandListLength;
      } else if (action.payload.channel === 3) {
        state.brandList.yahoo = action.payload.brandList;
        state.isLoading = false;
        state.allBrandLength.yahoo = action.payload.brandListLength;
      }
    });
    builder.addCase(fetchSearchBrandList.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSearchBrandList.rejected, (state, action) => {
      state.isLoading = false;
    });
  }
});

export const ownSelectedBrand = (state) => state.brand.ownSelectedBrand;
export const ownCheckedB = (state) => state.brand.ownCheckedBrand;
export const otherSelectedBrand = (state) => state.brand.otherSelectedBrand;
export const otherCheckedB = (state) => state.brand.otherCheckedBrand;

export const {
  setOwnSelectedBrandList,
  setOwnCheckedBrandList,
  setOtherSelectedBrandList,
  setOtherCheckedBrandList,
  resetSelectedBrandList,
  resetCheckedBrandList,
  changePageCount
} = brandSlice.actions;
export const brandList = (state) => state.brand.brandList;
export const ownBrandList = (state) => state.brand.ownBrandList;
export const allBrandLength = (state) => state.brand.allBrandLength;
export const allOwnBrandLength = (state) => state.brand.allOwnBrandLength;
export const pageCount = (state) => state.brand.pageCount;
export const brandLoading = (state) => state.brand.isLoading;
export const ownBrandLoading = (state) => state.brand.isOwnLoading;
export default brandSlice.reducer;
