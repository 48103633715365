/* eslint-disable*/
import {
  Box,
  Container,
  Grid,
  Typography,
  Item,
  CircularProgress,
  Divider
} from '@material-ui/core';
import KpiSummaryMonitorGraph from 'components/molecules/dashboard/KpiSummaryMonitorGraph';
import { useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  all_sales,
  all_views,
  all_cvr,
  all_price,
  all_kpiGraph,
  all_compareKpiGraph,
  all_compareSales,
  all_compareViews,
  all_compareCvr,
  all_comparePrice,
  a_sales,
  a_views,
  a_cvr,
  a_price,
  a_kpiGraph,
  a_compareKpiGraph,
  a_compareCvr,
  a_comparePrice,
  a_compareSales,
  a_compareViews,
  r_sales,
  r_views,
  r_cvr,
  r_price,
  r_kpiGraph,
  r_compareKpiGraph,
  r_compareCvr,
  r_comparePrice,
  r_compareSales,
  r_compareViews,
  y_sales,
  y_views,
  y_cvr,
  y_price,
  y_kpiGraph,
  y_compareKpiGraph,
  y_compareCvr,
  y_comparePrice,
  y_compareSales,
  y_compareViews,
  monitorStatus
} from 'feactures/monitoring/monitoringSlice';
import { errorResMessage } from 'feactures/errorSlice';
import { currentchannel } from 'feactures/channel/channelSlice';
import { termContext } from 'components/pages/kpi_view/KpiMonitoring';

const KpiSummaryGraph = ({ page }) => {
  const channel = useSelector(currentchannel);
  const term = useContext(termContext);
  const loading = useSelector(monitorStatus);

  const allMall_kpigraph = useSelector(all_kpiGraph);
  const allMall_sales = useSelector(all_sales);
  const allMall_views = useSelector(all_views);
  const allMall_cvr = useSelector(all_cvr);
  const allMall_price = useSelector(all_price);
  const allMall_compareKpiGraph = useSelector(all_compareKpiGraph);
  const allMall_compareSales = useSelector(all_compareSales);
  const allMall_compareViews = useSelector(all_compareViews);
  const allMall_compareCvr = useSelector(all_compareCvr);
  const allMall_comparePrice = useSelector(all_comparePrice);

  const amazon_kpigraph = useSelector(a_kpiGraph);
  const amazon_sales = useSelector(a_sales);
  const amazon_views = useSelector(a_views);
  const amazon_cvr = useSelector(a_cvr);
  const amazon_price = useSelector(a_price);
  const amazon_compareKpiGraph = useSelector(a_compareKpiGraph);
  const amazon_compareSales = useSelector(a_compareSales);
  const amazon_compareCvr = useSelector(a_compareCvr);
  const amazon_comparePrice = useSelector(a_comparePrice);
  const amazon_compareViews = useSelector(a_compareViews);

  const rakuten_kpigraph = useSelector(r_kpiGraph);
  const rakuten_sales = useSelector(r_sales);
  const rakuten_views = useSelector(r_views);
  const rakuten_cvr = useSelector(r_cvr);
  const rakuten_price = useSelector(r_price);
  const rakuten_compareKpiGraph = useSelector(r_compareKpiGraph);
  const rakuten_compareSales = useSelector(r_compareSales);
  const rakuten_compareCvr = useSelector(r_compareCvr);
  const rakuten_comparePrice = useSelector(r_comparePrice);
  const rakuten_compareViews = useSelector(r_compareViews);

  const yahoo_kpigraph = useSelector(y_kpiGraph);
  const yahoo_sales = useSelector(y_sales);
  const yahoo_views = useSelector(y_views);
  const yahoo_cvr = useSelector(y_cvr);
  const yahoo_price = useSelector(y_price);
  const yahoo_compareKpiGraph = useSelector(y_compareKpiGraph);
  const yahoo_compareSales = useSelector(y_compareSales);
  const yahoo_compareCvr = useSelector(y_compareCvr);
  const yahoo_comparePrice = useSelector(y_comparePrice);
  const yahoo_compareViews = useSelector(y_compareViews);

  let kpigraph = '';
  let compareKpiGraph = '';
  let sales = '';
  let views = '';
  let cvr = '';
  let price = '';
  let compareSales = '';
  let compareViews = '';
  let compareCvr = '';
  let comparePrice = '';
  let cvrInfo = '';

  if (channel == 0) {
    kpigraph = allMall_kpigraph;
    compareKpiGraph = allMall_compareKpiGraph;
    sales = allMall_sales;
    views = allMall_views;
    cvr = allMall_cvr;
    price = allMall_price;
    compareSales = allMall_compareSales;
    compareViews = allMall_compareViews;
    compareCvr = allMall_compareCvr;
    comparePrice = allMall_comparePrice;
    cvrInfo =
      '日別の平均CVRを表示。\n該当期間に販売実績のある全ての商品の平均値です。';
  } else if (channel == 1) {
    kpigraph = amazon_kpigraph;
    compareKpiGraph = amazon_compareKpiGraph;
    sales = amazon_sales;
    views = amazon_views;
    cvr = amazon_cvr;
    price = amazon_price;
    compareSales = amazon_compareSales;
    compareCvr = amazon_compareCvr;
    comparePrice = amazon_comparePrice;
    compareViews = amazon_compareViews;
    cvrInfo =
      '日別の平均CVRを表示。\n該当期間に販売実績のある全ての商品の平均値です。\nCVRは「 注文数 / PV 」で算出していますが、Amazonの仕様上PVと注文数の日付が異なることもあり、グラフと平均値が一致しない場合もあります。';
  } else if (channel == 2) {
    kpigraph = rakuten_kpigraph;
    compareKpiGraph = rakuten_compareKpiGraph;
    sales = rakuten_sales;
    views = rakuten_views;
    cvr = rakuten_cvr;
    price = rakuten_price;
    compareSales = rakuten_compareSales;
    compareCvr = rakuten_compareCvr;
    comparePrice = rakuten_comparePrice;
    compareViews = rakuten_compareViews;
    cvrInfo =
      '日別の平均CVRを表示。\n該当期間に販売実績のある全ての商品の平均値です。';
  } else if (channel == 3) {
    kpigraph = yahoo_kpigraph;
    compareKpiGraph = yahoo_compareKpiGraph;
    sales = yahoo_sales;
    views = yahoo_views;
    cvr = yahoo_cvr;
    price = yahoo_price;
    compareSales = yahoo_compareSales;
    compareCvr = yahoo_compareCvr;
    comparePrice = yahoo_comparePrice;
    compareViews = yahoo_compareViews;
    cvrInfo =
      '日別の平均CVRを表示。\n該当期間に販売実績のある全ての商品の平均値です。';
  }

  const errorMessage = useSelector(errorResMessage); //errorメッセージ
  return (
    <>
      <Box
        sx={{
          backgroundColor: '#FFF8E5',
          py: 1
        }}
      >
        {errorMessage ? (
          <Typography variant="h5" sx={{ textAlign: 'center' }}>
            {errorMessage}
          </Typography>
        ) : (
          <>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            ) : (
              <Grid container rowSpacing={1} justifyContent={'flex-end'}>
                <Grid item xs={12}>
                  <KpiSummaryMonitorGraph
                    title="全体売上"
                    titleColor="#595959"
                    graphColor="rgba(113, 140, 198, 0.5)"
                    hvGraphColor="rgba(2, 62, 190, 0.5)"
                    tanni="円"
                    graphdata={kpigraph.sales}
                    compareGraphData={compareKpiGraph.sales}
                    compareNum={compareSales.num}
                    num={sales.num}
                    rate={sales.rate}
                    graphtype="Bar"
                    info={'選択期間の売上を合計した値になります。'}
                    page={page}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'relative'
                  }}
                >
                  <Divider
                    orientation="vertical"
                    sx={{
                      height: '250%',
                      borderColor: '#7F7F7F',
                      position: 'absolute',
                      top: 0
                    }}
                  />
                  <Divider
                    sx={{
                      width: '50%',
                      borderColor: '#7F7F7F',
                      position: 'absolute',
                      top: '50%',
                      left: '50%'
                    }}
                  />
                </Grid>
                <Grid item xs={11}>
                  <KpiSummaryMonitorGraph
                    title="閲覧数"
                    titleColor="#595959"
                    graphColor="rgba(113, 140, 198, 0.5)"
                    hvGraphColor="rgba(2, 62, 190, 0.5)"
                    tanni="回"
                    graphdata={kpigraph.views}
                    compareGraphData={compareKpiGraph.views}
                    compareNum={compareViews.num}
                    num={views.num}
                    rate={views.rate}
                    graphtype="Bar"
                    info={'選択期間の閲覧数を合計した値になります。'}
                    page={page}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'relative'
                  }}
                >
                  <Divider
                    sx={{
                      width: '50%',
                      borderColor: '#7F7F7F',
                      position: 'absolute',
                      top: '50%',
                      left: '50%'
                    }}
                  />
                </Grid>
                <Grid item xs={11}>
                  <KpiSummaryMonitorGraph
                    title="CVR"
                    titleColor="#595959"
                    graphColor="rgba(113, 140, 198, 0.5)"
                    hvGraphColor="rgba(2, 62, 190, 0.5)"
                    tanni="%"
                    graphdata={kpigraph.cvr}
                    compareGraphData={compareKpiGraph.cvr}
                    compareNum={compareCvr.num}
                    num={cvr.num}
                    rate={cvr.rate}
                    graphtype="Line"
                    info={cvrInfo}
                    page={page}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'relative'
                  }}
                >
                  <Divider
                    sx={{
                      width: '50%',
                      borderColor: '#7F7F7F',
                      position: 'absolute',
                      top: '50%',
                      left: '50%'
                    }}
                  />
                </Grid>
                <Grid item xs={11}>
                  <KpiSummaryMonitorGraph
                    title="販売単価"
                    titleColor="#595959"
                    graphColor="rgba(113, 140, 198, 0.5)"
                    hvGraphColor="rgba(2, 62, 190, 0.5)"
                    tanni="円"
                    graphdata={kpigraph.price}
                    compareGraphData={compareKpiGraph.price}
                    compareNum={comparePrice.num}
                    num={price.num}
                    rate={price.rate}
                    graphtype="Bar"
                    info={
                      '日別の平均販売価格を表示。\n選択期間に販売実績のある全ての商品の平均値です。'
                    }
                    page={page}
                  />
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default KpiSummaryGraph;
