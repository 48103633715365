/* eslint-disable*/
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState, memo, useRef } from 'react';
import { Box, Typography, Link } from '@material-ui/core'; // eslint-disable-line

const PremiumRegisterComplete = memo(() => {
  return (
    <>
      <Box xs={12} textAlign="center" backgroundColor="#F7FCFF" pt={5}>
        <Typography variant="h1" fontWeight={'bold'}>
          お申し込みありがとうございました。
        </Typography>
        <Typography variant="h4" mb={3}>
          3営業日以内にカスタマーサポ―トからご案内いたします。
        </Typography>
        <Typography variant="h6" mb={3}>
          ログイン画面は
          <Link
            component={RouterLink}
            to="/login"
            variant="h5"
            underline="hover"
          >
            こちら
          </Link>
        </Typography>
      </Box>
    </>
  );
});

export default PremiumRegisterComplete;
