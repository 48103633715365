/* eslint-disable*/
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  Snackbar,
  Alert
} from '@material-ui/core';
import { fetchReregisterPass } from 'feactures/user/userSlice';

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = async (values) => {
    setError(null);
    await dispatch(fetchReregisterPass(values))
      .then(unwrapResult) //unwrapResult で,dispatch結果がfulfilledかrejectedかで条件分岐できる
      .then(() => {
        setSuccess(true),
          setTimeout(function () {
            navigate('/login');
          }, 10000);
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              userId: ''
            }}
            validationSchema={Yup.object().shape({
              userId: Yup.string()
                .email('メールアドレスの形式が正しくありません')
                .max(255)
                .required('未入力です')
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" variant="h2">
                    パスワード再発行
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    入力されたメールアドレスに再発行パスワードを送付します
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.userId && errors.userId)}
                  fullWidth
                  helperText={touched.userId && errors.userId}
                  label="メールアドレス"
                  margin="normal"
                  name="userId"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.userId}
                  variant="outlined"
                />

                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>{errors.policy}</FormHelperText>
                )}
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    再発行
                  </Button>
                  {error && (
                    <Alert severity="error">
                      ご登録情報がありません。メールアドレスをご確認ください
                    </Alert>
                  )}
                </Box>
                <Typography color="textSecondary" variant="body1">
                  ログインは
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="h6"
                    underline="hover"
                  >
                    こちら
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={success}
            message="パスワードメールを送信しました。このページは10秒後にログインページに遷移します。"
          />
        </Container>
      </Box>
    </>
  );
};

export default Register;
