/* eslint-disable*/
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
  Alert,
  FormControl,
  InputLabel,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
import { errorResMessage } from 'feactures/errorSlice';
import {
  fetchMeasuresTrialRegister,
  fetchTrialRegister
} from 'feactures/plan/premiumRegister';

const MeasuresTrialRegister = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const errorMessage = useSelector(errorResMessage);
  const [error, setError] = useState(null);
  const [searchParams] = useSearchParams();

  const channel = searchParams.get('ch');

  let ch_name = '';
  if (channel == 'amazon') {
    ch_name = 'Amazon';
  } else if (channel == 'rakuten') {
    ch_name = '楽天';
  }

  const onSubmit = async (values) => {
    setError(null);
    await dispatch(fetchMeasuresTrialRegister(values))
      .then(unwrapResult) //unwrapResult で,dispatch結果がfulfilledかrejectedかで条件分岐できる
      .then(() => {
        // if (channel == 'amazon') {
        //   navigate(`/trialregister/complete?ch=${channel}`, { replace: false });
        // }
        // if (channel == 'rakuten') {
        //   navigate(`/trialregister/complete?ch=${channel}`, { replace: false });
        // }
        navigate('/measurestrialregister/complete', { replace: false });
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const RequiredBox = () => {
    return (
      <Box
        color="#FFFFFF"
        bgcolor="#F26B6B"
        borderRadius={5}
        fontSize="12px"
        fontWeight="bold"
        px="8px"
        mb="1px"
        ml="8px"
      >
        必須
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box sx={{ mt: 3, width: '80%', maxWidth: '1000px' }}>
          <Formik
            initialValues={{
              mall: {
                amazon: channel == 'amazon' ? true : false,
                rakuten: channel == 'rakuten' ? true : false,
                yahoo: false
              },
              userId: ''
            }}
            validationSchema={Yup.object().shape({
              userId: Yup.string().max(255).required('ユーザーIDが未入力です'),
              mall: Yup.object()
                .shape({
                  amazon: Yup.boolean(),
                  rakuten: Yup.boolean(),
                  yahoo: Yup.boolean()
                })
                .test('mall', 'モールを選択してください', (value) => {
                  return value.amazon || value.rakuten || value.yahoo;
                })
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box sx={{ mb: 3, mt: 3, width: '100%', maxWidth: '650px' }}>
                    <Typography color="textPrimary" variant="h2">
                      Oxcim有料版［{ch_name}］トライアル利用申込
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                      ml={1}
                    >
                      <br />
                      Oxcim【有料版トライアル】を利用申し込みする場合は下記よりお願いいたします。
                      <br />
                      既にこのトライアルをご利用いただいたモールでは、再度トライアルをお試しいただくことはできません。
                    </Typography>
                  </Box>
                  <Box width="100%" maxWidth="600px">
                    <Box display="flex" alignItems="center">
                      <InputLabel>
                        <Box display="flex" alignItems="center" flexWrap="wrap">
                          <Typography
                            variant="body1"
                            sx={{ fontSize: { xs: '12px', sm: 'inherit' } }}
                          >
                            OxcimユーザーID（ご登録のメールアドレス）
                          </Typography>
                          <RequiredBox />
                          {touched.userId && errors.userId && (
                            <FormHelperText sx={{ m: 0, ml: 1 }} error>
                              {errors.userId}
                            </FormHelperText>
                          )}
                        </Box>
                      </InputLabel>
                    </Box>
                    <TextField
                      sx={{
                        mb: 1,
                        backgroundColor: '#fff',
                        maxWidth: '440px'
                      }}
                      error={Boolean(touched.userId && errors.userId)}
                      fullWidth
                      name="userId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.userId}
                      variant="outlined"
                      size="small"
                      autoComplete="new-user-id"
                      inputProps={{ autoComplete: 'off' }}
                    />
                    <Box sx={{ py: 2 }} onSubmit={handleSubmit}>
                      <Button
                        color="primary"
                        disabled={isSubmitting || ch_name == ''}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        sx={{ mb: 1 }}
                      >
                        申し込む
                      </Button>
                      {error && <Alert severity="error">{errorMessage}</Alert>}
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
};

export default MeasuresTrialRegister;
