/* eslint-disable*/
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAsyncCategory1 } from 'feactures/category/categorySlice';
import {
  Box,
  Container,
  Typography,
  CircularProgress,
  Modal
} from '@material-ui/core'; // eslint-disable-line
import NewButtongroup from 'components/atoms/group/NewButtongroup';
import NewPullDown from 'components/atoms/button/NewPullDown';
import RankingTotal from 'components/organisms/content/category/RankingTotal';
import { categoryRankStatus } from 'feactures/category/categoryRankSlice';
import {
  categorygraphStatus,
  fetchAllCategoryGraphData,
  fetchCategoryBrandGraphData
} from 'feactures/brand/categoryBrandGraphSlice';
import { currentchannel } from 'feactures/channel/channelSlice';
import { userinfo } from 'feactures/user/userSlice';
import {
  currentAcate1,
  currentRcate1,
  currentYcate1
} from 'feactures/category/categoryGraphSlice';
import {
  amazonCategory,
  rakutenCategory,
  yahooCategory
} from 'feactures/category/categorySlice';
import {
  fetchFavoriteCategory,
  favoriteCategoryStatus
} from 'feactures/category/favoriteCategorySlice';
import StickyAnnotation from 'components/atoms/annotation/StickyAnnotation';
import NewDialogSelect from 'components/atoms/select/NewDialogSelect';
import FavoriteCategoryPullDown from 'components/atoms/button/FavoriteCategoryPullDown';
import DialogCheckbox from 'components/atoms/select/DialogCheckbox';
import BrandTotal from 'components/organisms/content/brand/BrandTotal';
import BrandItemShare from 'components/organisms/content/brand/BrandItemShare';
import BrandTable from 'components/organisms/content/brand/BrandTable';
import BrandItemTable from 'components/organisms/content/brand/BrandItemTable';
import BrandItemShareTable from 'components/organisms/content/brand/BrandItemShareTable';
import {
  categoryOtherBrandShareStatus,
  fetchCategoryBrandShareGraphData
} from 'feactures/brand/categoryBrandShareSlice';
import { fetchBrandRankData } from 'feactures/brand/brandRankSlice';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { changePage } from 'feactures/date/dateSlice';

const BrandAnalysis = () => {
  const channel = useSelector(currentchannel);
  const userInfo = useSelector(userinfo);
  const top100loading = useSelector(categoryRankStatus);
  const graphloading = useSelector(categorygraphStatus); //こっちの方がloading時間が長いので一旦こっち
  // const brandloading = useSelector(brandLoading);
  const categoryShareLoading = useSelector(categoryOtherBrandShareStatus);
  const currentSelectAmazonCategory1 = useSelector(currentAcate1);
  const currentSelectRakutenCategory1 = useSelector(currentRcate1);
  const currentSelectYahooCategory1 = useSelector(currentYcate1);
  const amazonCategories = useSelector(amazonCategory);
  const rakutenCategories = useSelector(rakutenCategory);
  const yahooCategories = useSelector(yahooCategory);
  const favoriteCategoryLoading = useSelector(favoriteCategoryStatus);
  // const getBrandList = useSelector(brandList);
  const [yokoziku, setYokoziku] = useState([]);
  const dispatch = useDispatch();

  // 戻るボタン押されたとき用
  useEffect(() => {
    dispatch(changePage('brandAnalysis'));
  }, []);

  // useEffect(() => {
  //   // '選択済みの第一カテゴリがない時のみfetchAsyncCategory1をする
  //   if (
  //     Boolean(
  //       currentSelectAmazonCategory1 ||
  //         currentSelectRakutenCategory1 ||
  //         currentSelectYahooCategory1
  //     ) == false
  //   ) {
  //     dispatch(fetchFavoriteCategory());
  //     // dispatch(fetchAllCategoryGraphData());
  //     // dispatch(fetchCategoryShareGraphData());
  //     dispatch(fetchAsyncCategory1());
  //     // dispatch(fetchCategoryRankData());
  //   }
  //   dispatch(fetchCategoryBrandShareGraphData());
  //   dispatch(fetchCategoryBrandGraphData());
  //   dispatch(fetchBrandRankData());
  // }, [channel]);

  let categories = '';

  if (channel == 1) {
    categories = amazonCategories;
  } else if (channel == 2) {
    categories = rakutenCategories;
  } else if (channel == 3) {
    categories = yahooCategories;
  }

  // let channelBrandList = [];
  // if (channel == 1) {
  //   channelBrandList = getBrandList.amazon;
  // } else if (channel == 2) {
  //   channelBrandList = getBrandList.rakuten;
  // } else if (channel == 3) {
  //   channelBrandList = getBrandList.yahoo;
  // }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '100'
  };

  return (
    <>
      {favoriteCategoryLoading && (
        <Modal open={favoriteCategoryLoading}>
          <Box sx={style}>
            <p>カテゴリ読み込み中...</p>
            <CircularProgress />
          </Box>
        </Modal>
      )}
      <Box
        sx={{
          backgroundColor: '#FFF8E5',
          height: 'auto',
          minHeight: '100vh',
          width: '100%',
          pb: '80px'
        }}
      >
        <Container maxWidth={false} sx={{ width: 'auto', p: 0 }}>
          <Box
            position="sticky"
            top="42px"
            bgcolor="#FFF8E5"
            zIndex={3}
            height="auto"
          >
            {channel == 2 && <StickyAnnotation />}
            <Box display="flex" alignItems="center" height="33px">
              <Typography fontSize="14px">マーケットビュー</Typography>
              <ArrowForwardIosOutlinedIcon
                sx={{ fontSize: '16px', mx: 1, color: 'gray' }}
              />
              <Typography fontSize="14px" fontWeight="bold">
                店舗/ブランド分析
              </Typography>
            </Box>
            <Box
              mb={2}
              sx={{
                display: 'flex',
                borderTop: 1,
                borderColor: '#FFFFFF',
                alignItems: 'end'
              }}
            >
              <Box mr={3}>
                {/* <Typography fontSize="14px">モール</Typography> */}
                <NewButtongroup amazonOnly={true} page="brandAnalysis" />
              </Box>
              <Box mr={3}>
                {/* <Typography fontSize="14px">期間</Typography> */}
                <NewPullDown
                  page="brandAnalysis"
                  label="期間"
                  setYokoziku={setYokoziku}
                  graphLoading={
                    graphloading || categoryShareLoading || top100loading
                  }
                />
              </Box>
              <Box mr={3}>
                <Typography fontSize="14px">カテゴリを選択する</Typography>
                <NewDialogSelect
                  categories={categories}
                  channel={channel}
                  page="brandAnalysis"
                  graphLoading={
                    graphloading || categoryShareLoading || top100loading
                  }
                />
              </Box>
              <Box mr={3}>
                <Typography fontSize="14px">お気に入りカテゴリ</Typography>
                <FavoriteCategoryPullDown
                  page="brandAnalysis"
                  graphLoading={
                    graphloading || categoryShareLoading || top100loading
                  }
                />
              </Box>
              <Box>
                <Typography fontSize="14px">競合を選択</Typography>
                <DialogCheckbox
                  channel={channel}
                  page="brandAnalysis"
                  section="other"
                  graphLoading={
                    graphloading || categoryShareLoading || top100loading
                  }
                />
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Box
              width="48%"
              bgcolor="#FFFFFF"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius={3}
              height="400px" //仮
            >
              {graphloading ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <p>グラフの読み込み中…</p>
                  <CircularProgress />
                </Box>
              ) : (
                <BrandTotal yokoziku={yokoziku} />
                // <PointCateTotal yokoziku={yokoziku} />
              )}
            </Box>
            <Box
              width="48%"
              bgcolor="#FFFFFF"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderRadius={3}
              height="400px" //仮
            >
              {categoryShareLoading ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <p>グラフの読み込み中…</p>
                  <CircularProgress />
                </Box>
              ) : (
                <BrandItemShare />
              )}
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="start"
            justifyContent="space-between"
            mb={2}
          >
            <Box
              width="48%"
              height="460px"
              bgcolor="#FFFFFF"
              display="flex"
              justifyContent="center"
              borderRadius={3}
            >
              {graphloading ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  <p>テーブルの読み込み中…</p>
                  <CircularProgress />
                </Box>
              ) : (
                <BrandTable yokoziku={yokoziku} />
              )}
            </Box>
            <Box
              width="48%"
              height="460px"
              bgcolor="#FFFFFF"
              display="flex"
              justifyContent="center"
              borderRadius={3}
              minHeight={100}
            >
              {categoryShareLoading ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                >
                  <p>テーブルの読み込み中…</p>
                  <CircularProgress />
                </Box>
              ) : (
                <BrandItemShareTable />
              )}
            </Box>
          </Box>
          <Box mb={2} display="flex" justifyContent="center">
            <Box width="100%" bgcolor="#FFFFFF" borderRadius={3}>
              {top100loading ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <p>ランキングの読み込み中…</p>
                  <CircularProgress />
                </Box>
              ) : (
                <BrandItemTable yokoziku={yokoziku} />
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default BrandAnalysis;
