/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const fetchCategoryNo1Sales = createAsyncThunk(
  'category_no1_sales/post',
  async (_, thunkAPI) => {
    const { amazon, rakuten, yahoo } = thunkAPI.getState().categoryNo1;
    const { yearMonth } = thunkAPI.getState().currentsection;
    const { channel } = thunkAPI.getState().currentchannel;
    const list_dict = {
      1: amazon.selectCategory,
      2: rakuten.selectCategory,
      3: yahoo.selectCategory
    };
    const res = await client.post(`${apiUrl}category_no1_sales`, {
      category_list: {
        category_1:
          list_dict[channel][0] != 0 ? list_dict[channel][0].value : 0,
        category_2:
          list_dict[channel][1] != 0 ? list_dict[channel][1].value : 0,
        category_3:
          list_dict[channel][2] != 0 ? list_dict[channel][2].value : 0,
        category_4:
          list_dict[channel][3] != 0 ? list_dict[channel][3].value : 0,
        category_5:
          list_dict[channel][4] != 0 ? list_dict[channel][4].value : 0,
        category_6: list_dict[channel][5] != 0 ? list_dict[channel][5].value : 0
      },
      channel: channel,
      term: yearMonth
    });
    return res.data;
  }
);

const initialState = {
  selectCategory: [
    0, 0, 0, 0, 0, 0
    // { label: '', value: 0 },
    // { label: '', value: 0 },
    // { label: '', value: 0 },
    // { label: '', value: 0 },
    // { label: '', value: 0 },
    // { label: '', value: 0 }
  ],
  category1: { category_name: '-' },
  category2: { category_name: '-' },
  category3: { category_name: '-' },
  category4: { category_name: '-' },
  category5: { category_name: '-' },
  category6: { category_name: '-' }
};

const categoryNo1SalesSlice = createSlice({
  name: 'categoryNo1Sales',
  initialState: {
    amazon: initialState,
    rakuten: initialState,
    yahoo: initialState,
    isLoading: false
  },
  reducers: {
    resetCategoryLabel(state, action) {
      const index = action.payload.index;
      if (action.payload.channel == 1) {
        state.amazon.selectCategory[index] = 0;
      } else if (action.payload.channel == 2) {
        state.rakuten.selectCategory[index] = 0;
      } else if (action.payload.channel == 3) {
        state.yahoo.selectCategory[index] = 0;
      }
    },
    //カテゴリラベルの変更のみ。表示が押された時にこの6カテゴリをflaskへ送信
    changeCategory(state, action) {
      // console.log('チェンジカテゴリ', action.payload);
      // console.log(action.payload);
      const index = action.payload.index; //何番目のラベルを変更するか
      if (action.payload.channel == 1) {
        state.amazon.selectCategory[index] = action.payload.value;
      } else if (action.payload.channel == 2) {
        state.rakuten.selectCategory[index] = action.payload.value;
      } else if (action.payload.channel == 3) {
        state.yahoo.selectCategory[index] = action.payload.value;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategoryNo1Sales.fulfilled, (state, action) => {
      if (action.payload.channel == 1) {
        // console.log('fulfill後', action.payload.category_no1_sales);
        state.amazon.category1 = action.payload.category_no1_sales[0];
        state.amazon.category2 = action.payload.category_no1_sales[1];
        state.amazon.category3 = action.payload.category_no1_sales[2];
        state.amazon.category4 = action.payload.category_no1_sales[3];
        state.amazon.category5 = action.payload.category_no1_sales[4];
        state.amazon.category6 = action.payload.category_no1_sales[5];
      } else if (action.payload.channel == 2) {
        state.rakuten.category1 = action.payload.category_no1_sales[0];
        state.rakuten.category2 = action.payload.category_no1_sales[1];
        state.rakuten.category3 = action.payload.category_no1_sales[2];
        state.rakuten.category4 = action.payload.category_no1_sales[3];
        state.rakuten.category5 = action.payload.category_no1_sales[4];
        state.rakuten.category6 = action.payload.category_no1_sales[5];
      } else if (action.payload.channel == 3) {
        state.yahoo.category1 = action.payload.category_no1_sales[0];
        state.yahoo.category2 = action.payload.category_no1_sales[1];
        state.yahoo.category3 = action.payload.category_no1_sales[2];
        state.yahoo.category4 = action.payload.category_no1_sales[3];
        state.yahoo.category5 = action.payload.category_no1_sales[4];
        state.yahoo.category6 = action.payload.category_no1_sales[5];
      }
      state.isLoading = false;
      state.error = false;
    });
    builder.addCase(fetchCategoryNo1Sales.pending, (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    });
    builder.addCase(fetchCategoryNo1Sales.rejected, (state, action) => {
      console.log(action.payload, 'rejected');
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    });
  }
});

//選択カテゴリラベルのみ
export const selectAmazonCategoryList = (state) =>
  state.categoryNo1.amazon.selectCategory;
export const selectRakutenCategoryList = (state) =>
  state.categoryNo1.rakuten.selectCategory;
export const selectYahooCategoryList = (state) =>
  state.categoryNo1.yahoo.selectCategory;
//バックエンドからの情報
export const amazonCategoryGraphList = (state) => [
  state.categoryNo1.amazon.category1,
  state.categoryNo1.amazon.category2,
  state.categoryNo1.amazon.category3,
  state.categoryNo1.amazon.category4,
  state.categoryNo1.amazon.category5,
  state.categoryNo1.amazon.category6
];
export const rakutenCategoryGraphList = (state) => [
  state.categoryNo1.rakuten.category1,
  state.categoryNo1.rakuten.category2,
  state.categoryNo1.rakuten.category3,
  state.categoryNo1.rakuten.category4,
  state.categoryNo1.rakuten.category5,
  state.categoryNo1.rakuten.category6
];
export const yahooCategoryGraphList = (state) => [
  state.categoryNo1.yahoo.category1,
  state.categoryNo1.yahoo.category2,
  state.categoryNo1.yahoo.category3,
  state.categoryNo1.yahoo.category4,
  state.categoryNo1.yahoo.category5,
  state.categoryNo1.yahoo.category6
];

export const categoryNo1SalesStatus = (state) => state.categoryNo1.isLoading;
export const categoryNo1Error = (state) => state.categoryNo1.error;

export const { changeCategory, resetCategoryLabel } =
  categoryNo1SalesSlice.actions;

export default categoryNo1SalesSlice.reducer;
