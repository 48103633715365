/* eslint-disable*/

import { Container, Grid, Box, Typography } from '@material-ui/core'; // eslint-disable-line
import { useSelector } from 'react-redux';
import { currentchannel } from 'feactures/channel/channelSlice';
import {
  ownAmazonCategoryShareGraph,
  ownRakutenCategoryShareGraph,
  ownYahooCategoryShareGraph,
  rakutenShopCode,
  selectAmazonCategoryShareGraph,
  selectRakutenCategoryShareGraph,
  selectYahooCategoryShareGraph
} from 'feactures/brand/categoryBrandShareSlice';
import Top10TableByitem from 'components/molecules/dashboard/Top10TableByitem';
import { otherSelectedBrand } from 'feactures/brand/brandSlice';

const Top10Table = (props) => {
  const { section, yokoziku } = props;
  // 実際のデータはuseEffectで取得しreduxへ
  const categoryShareAmazonGraphData = useSelector(
    selectAmazonCategoryShareGraph
  );
  const categoryShareRakutenGraphData = useSelector(
    selectRakutenCategoryShareGraph
  );
  const categoryShareYahooGraphData = useSelector(
    selectYahooCategoryShareGraph
  );
  const ownCategoryShareAmazonGraphData = useSelector(
    ownAmazonCategoryShareGraph
  );
  const ownCategoryShareRakutenGraphData = useSelector(
    ownRakutenCategoryShareGraph
  );
  const ownCategoryShareYahooGraphData = useSelector(
    ownYahooCategoryShareGraph
  );
  const channel = useSelector(currentchannel);
  const ownShopCode = useSelector(rakutenShopCode);
  const otherShopCode = useSelector(otherSelectedBrand);

  let categoryGraphData = {
    sales: [],
    label: [],
    percentage: [],
    unit: [],
    price: [],
    name: []
  };
  let shopCode = '';

  if (section == '競合') {
    if (channel == 1) {
      categoryGraphData = categoryShareAmazonGraphData.graphdata;
      shopCode = '';
    } else if (channel == 2) {
      categoryGraphData = categoryShareRakutenGraphData.graphdata;
      shopCode = otherShopCode.rakuten.brand;
    } else if (channel == 3) {
      categoryGraphData = categoryShareYahooGraphData.graphdata;
      shopCode = '';
    }
  } else if (section == '自社') {
    if (channel == 1) {
      categoryGraphData = ownCategoryShareAmazonGraphData.graphdata;
      shopCode = '';
    } else if (channel == 2) {
      categoryGraphData = ownCategoryShareRakutenGraphData.graphdata;
      shopCode = ownShopCode;
    } else if (channel == 3) {
      categoryGraphData = ownCategoryShareYahooGraphData.graphdata;
      shopCode = '';
    }
  }

  return (
    <>
      <Container maxWidth={false}>
        <Grid container rowSpacing={0.3} columnSpacing={1.5} padding={1} my={1}>
          <>
            <Grid itemxs={12} mb={1}>
              <Typography fontSize="14px" fontWeight="bold">
                {section}店舗：商品別売上TOP10
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {categoryGraphData.label.length === 0 ? (
                <Box
                  height="400px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography fontSize={15}>
                    表示できるデータがありません
                  </Typography>
                </Box>
              ) : (
                <>
                  {/* テーブル */}
                  <Top10TableByitem
                    category={categoryGraphData.label}
                    sales={categoryGraphData.sales}
                    unit={categoryGraphData.unit}
                    price={categoryGraphData.price}
                    percentage={categoryGraphData.percentage}
                    name={categoryGraphData.name}
                    yokoziku={yokoziku}
                    shopCode={shopCode}
                    section={section}
                  />
                </>
              )}
            </Grid>
          </>
        </Grid>
      </Container>
    </>
  );
};

export default Top10Table;
