/* eslint-disable*/
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Divider
} from '@material-ui/core';
import Logo from '../../../Logo';
//ログインヘッダー

const MainNavbar = (props) => (
  // <AppBar elevation={0} {...props}>
  <>
    <Toolbar>
      <RouterLink to="/" style={{ textDecoration: 'none' }}>
        {/* <Typography
        variant="h2"
        sx={{ color: 'text.secondary', fontFamily: 'Book Antiqua' }}
      >
        Oxcim
      </Typography> */}
        <Logo />
      </RouterLink>
      <Typography
        mt={1.5}
        ml={0.5}
        sx={{
          fontSize: '10px',
          color: 'text.secondary',
          fontFamily: 'Book Antiqua'
        }}
      >
        Ⓡ
      </Typography>
      {/* <RouterLink to="/login">
        <Typography variant="h6" ml={3}>
          Login
        </Typography>
      </RouterLink> */}
    </Toolbar>
    <Divider />
  </>
  // </AppBar>
);

export default MainNavbar;
