/* eslint-disable*/
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CategoryForm from 'components/molecules/dashboard/CategoryForm';
import {
  fetchCategoryGraphData,
  // categoryLabel,
  selectAmazonCategoryGraph,
  selectRakutenCategoryGraph,
  selectYahooCategoryGraph
} from 'feactures/category/categoryGraphSlice';
import { fetchCategoryRankData } from 'feactures/category/categoryRankSlice';
import { fetchAsyncCategory1 } from 'feactures/category/categorySlice';
import {
  currentAcate1,
  currentRcate1,
  currentYcate1,
  changeAcate1
} from 'feactures/category/categoryGraphSlice';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  container2: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
}));

const DialogSelect = (props) => {
  const { categories, channel } = props; // eslint-disable-line
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [age, setAge] = useState('');
  const selectAmazoncate1 = useSelector(selectAmazonCategoryGraph);
  const selectRakutencate1 = useSelector(selectRakutenCategoryGraph);
  const selectYahoocate1 = useSelector(selectYahooCategoryGraph);

  // console.log(selectAcate, selectRcate, selectYcate);

  const handleChange = (event) => {
    setAge(Number(event.target.value) || '');
  };

  const handleClickOpen = () => {
    setOpen(true);
    if (
      Boolean(
        currentSelectAmazonCategory1 ||
          currentSelectRakutenCategory1 ||
          currentSelectYahooCategory1
      ) == false
    ) {
      dispatch(fetchAsyncCategory1());
    }
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(fetchCategoryGraphData());
    dispatch(fetchCategoryRankData());
  };

  const handleCancel = () => {
    setOpen(false);
    // dispatch(fetchCategoryGraphData());
    // dispatch(fetchCategoryRankData());
  };

  const handleReset = () => {
    console.log('入力したカテゴリをリセットします');
    dispatch(fetchAsyncCategory1());
    // ↓「カテゴリを選択してください」が表示される
    dispatch(changeAcate1(0));
    // 削除した後にカテゴリ[N]を表示したい
    handleClickOpen();
  };

  const currentSelectAmazonCategory1 = useSelector(currentAcate1);
  const currentSelectRakutenCategory1 = useSelector(currentRcate1);
  const currentSelectYahooCategory1 = useSelector(currentYcate1);

  return (
    <>
      {/* {一旦amazonのみ表示} */}
      {/* {channel != 3 ? ( */}
      <Button
        onClick={handleClickOpen}
        variant="outlined"
        size="small"
        sx={{ m: 1 }}
      >
        カテゴリ選択
      </Button>
      {/* ) : (
        <>
          <Button disabled variant="outlined" size="small" sx={{ m: 1 }}>
            Comming soon...
          </Button>
        </>
      )} */}

      {channel === 1 ? selectAmazoncate1.selectCategoryLabel : ''}
      {channel === 2 ? selectRakutencate1.selectCategoryLabel : ''}
      {channel === 3 ? selectYahoocate1.selectCategoryLabel : ''}
      <Dialog open={open} onClose={handleClose} maxWidth="200">
        <DialogTitle>カテゴリ選択</DialogTitle>
        <DialogContent>
          <CategoryForm categories={categories} channel={channel} />
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleReset} color="primary">
            Reset
          </Button>           */}
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default DialogSelect;
