/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const fetchBrandRankData = createAsyncThunk(
  'brand/rank/post',
  async (_, thunkAPI) => {
    const {
      a_cate1,
      a_cate2,
      a_cate3,
      a_cate4,
      a_cate5,
      a_cate6,
      r_cate1,
      r_cate2,
      r_cate3,
      r_cate4,
      r_cate5,
      y_cate1,
      y_cate2,
      y_cate3,
      y_cate4
    } = thunkAPI.getState().categorygraph;
    const { yearMonth } = thunkAPI.getState().currentsection;
    const { channel } = thunkAPI.getState().currentchannel;
    const { userId } = thunkAPI.getState().user;
    const { otherSelectedBrand } = thunkAPI.getState().brand;
    const { brandList } = thunkAPI.getState().brand;
    // let brand_id = null;
    // let brand_id_arr = null;
    // if (channel == 1) {
    //   brand_id_arr = brandList.amazon.filter(
    //     (brand) => brand.brand_name == otherSelectedBrand.amazon.brand
    //   );
    // } else if (channel == 2) {
    //   brand_id_arr = brandList.rakuten.filter(
    //     (brand) => brand.brand_name == otherSelectedBrand.rakuten.brand
    //   );
    //   console.log('brand_id_arr', brand_id_arr);
    // } else if (channel == 3) {
    //   brand_id_arr = brandList.yahoo.filter(
    //     (brand) => brand.brand_name == otherSelectedBrand.yahoo.brand
    //   );
    // }
    // if (brand_id_arr.length != 0) {
    //   brand_id = brand_id_arr[0].brand_id;
    // }
    let amazon_brand_id = null;
    let rakuten_brand_id = null;
    let yahoo_brand_id = null;
    if (channel === 1 && otherSelectedBrand.amazon !== '') {
      amazon_brand_id = otherSelectedBrand.amazon.id;
    } else if (channel === 2 && otherSelectedBrand.rakuten !== '') {
      rakuten_brand_id = otherSelectedBrand.rakuten.id;
    } else if (channel === 3 && otherSelectedBrand.yahoo !== '') {
      yahoo_brand_id = otherSelectedBrand.yahoo.id;
    }
    const res = await client.post(`${apiUrl}brand/rank`, {
      amazon_category: [a_cate1, a_cate2, a_cate3, a_cate4, a_cate5, a_cate6],
      rakuten_category: [r_cate1, r_cate2, r_cate3, r_cate4, r_cate5],
      yahoo_category: [y_cate1, y_cate2, y_cate3, y_cate4],
      amazon_brand: amazon_brand_id,
      rakuten_brand: rakuten_brand_id,
      yahoo_brand: yahoo_brand_id,
      term: yearMonth,
      channel: channel,
      user_id: userId
    });
    return res.data;
  }
);
export const fetchItemDetailGraph = createAsyncThunk(
  'brand/item/post',
  async (arg, thunkAPI) => {
    const {
      a_cate1,
      a_cate2,
      a_cate3,
      a_cate4,
      a_cate5,
      a_cate6,
      r_cate1,
      r_cate2,
      r_cate3,
      r_cate4,
      r_cate5,
      y_cate1,
      y_cate2,
      y_cate3,
      y_cate4
    } = thunkAPI.getState().categorygraph;
    const { yearMonth } = thunkAPI.getState().currentsection;
    const { channel } = thunkAPI.getState().currentchannel;
    const { accountId } = thunkAPI.getState().user;
    const { otherSelectedBrand } = thunkAPI.getState().brand;
    const res = await client.post(`${apiUrl}brand/item`, {
      amazon_category: [a_cate1, a_cate2, a_cate3, a_cate4, a_cate5, a_cate6],
      rakuten_category: [r_cate1, r_cate2, r_cate3, r_cate4, r_cate5],
      yahoo_category: [y_cate1, y_cate2, y_cate3, y_cate4],
      amazon_brand: otherSelectedBrand.amazon.brand,
      rakuten_brand: otherSelectedBrand.rakuten.brand,
      yahoo_brand: otherSelectedBrand.yahoo.brand,
      term: yearMonth,
      channel: channel,
      user_id: accountId,
      item_id: arg.asin,
      shop_code: arg.shopCode,
      section: arg.section
    });
    return res.data;
  }
);

const fetchBrandRankSlice = createSlice({
  name: 'brandRank',
  initialState: {
    amazon_100items: [],
    rakuten_100items: [],
    yahoo_100items: [],
    itemDetail: {
      rank: '',
      img: '',
      shopCode: '',
      asin: '',
      item_name: '',
      price: '',
      unit_sold: '',
      sales: '',
      graphData: []
    },
    itemDetailLoading: false,
    itemDetailDisplay: false
  },
  reducers: {
    setItemDetailDisplayFlag(state, action) {
      return {
        ...state,
        itemDetailDisplay: action.payload
      };
    },
    setItemDetail(state, action) {
      return {
        ...state,
        itemDetail: {
          rank: action.payload.rank,
          img: action.payload.img,
          shopCode: action.payload.shopCode,
          asin: action.payload.asin,
          item_name: action.payload.item_name,
          price: action.payload.price,
          unit_sold: action.payload.unit_sold,
          sales: action.payload.sales,
          graphData: state.itemDetail.graphData
        }
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBrandRankData.fulfilled, (state, action) => {
      return {
        ...state,
        amazon_100items: action.payload.amazon_100items,
        rakuten_100items: action.payload.rakuten_100items,
        yahoo_100items: action.payload.yahoo_100items,
        isLoading: false
      };
    });
    builder.addCase(fetchBrandRankData.pending, (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    });
    builder.addCase(fetchBrandRankData.rejected, (state, action) => {
      return {
        ...state,
        amazon_100items: [],
        rakuten_100items: [],
        yahoo_100items: [],
        isLoading: false
      };
    });
    builder.addCase(fetchItemDetailGraph.fulfilled, (state, action) => {
      return {
        ...state,
        itemDetail: {
          ...state.itemDetail,
          graphData: action.payload.graphdata
        },
        itemDetailLoading: false
      };
    });
    builder.addCase(fetchItemDetailGraph.pending, (state, action) => {
      return {
        ...state,
        itemDetailLoading: true
      };
    });
    builder.addCase(fetchItemDetailGraph.rejected, (state, action) => {
      return {
        ...state,
        itemDetail: {
          ...state.itemDetail,
          graphData: []
        },
        itemDetailLoading: false
      };
    });
  }
});

export const { setItemDetailDisplayFlag } = fetchBrandRankSlice.actions;
export const { setItemDetail } = fetchBrandRankSlice.actions;
export const amazon_100items = (state) => state.brandrank.amazon_100items;
export const rakuten_100items = (state) => state.brandrank.rakuten_100items;
export const yahoo_100items = (state) => state.brandrank.yahoo_100items;
export const brandRankStatus = (state) => state.brandrank.isLoading;
export const itemDetail = (state) => state.brandrank.itemDetail;
export const itemDetailLoading = (state) => state.brandrank.itemDetailLoading;
export const itemDetailDisplay = (state) => state.brandrank.itemDetailDisplay;

export default fetchBrandRankSlice.reducer;
