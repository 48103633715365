/* eslint-disable */
import { DatePicker } from 'rsuite';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import 'rsuite/dist/rsuite.min.css';
import { useRef, useState, useEffect, useContext, useCallback } from 'react';
import { Box } from '@material-ui/core';
import {
  fetchRankingProductList,
  rankingProductSelectedDate,
  changeRankingProductDate,
  rankingProductPageCount,
  rankingProductIsLoading,
  resetRankingProductPage,
  productIsLoading,
  rankingProductCategory
} from 'feactures/measure/newMeasureSlice';
import { useDispatch, useSelector } from 'react-redux';
import * as moment from 'moment/moment';
import { ReloadLayoutContext } from 'components/templates/dashboard/DashboardLayout';
import { currentchannel } from 'feactures/channel/channelSlice';

export default function RankingProductDatePicker({ page }) {
  const { reloadStatus, setReloadStatus } = useContext(ReloadLayoutContext);
  const dispatch = useDispatch();
  const selectDate = useSelector(rankingProductSelectedDate);
  const pageCount = useSelector(rankingProductPageCount);
  const isLoading = useSelector(rankingProductIsLoading);
  const registerLoading = useSelector(productIsLoading);
  const channel = useSelector(currentchannel);
  const category = useSelector(rankingProductCategory);
  var dateValue = new Date(selectDate);
  const [display, setDisplay] = useState('none');
  const containerRef = useRef();
  const [calenderIsOpen, setCalenderIsOpen] = useState(true);

  // ページが変わった時
  useEffect(() => {
    // カテゴリ選択済みの場合のみランキング商品を取得する
    if (category.length > 0 && category[0].categoryId !== '') {
      dispatch(fetchRankingProductList());
    }
  }, [pageCount]);

  useEffect(() => {
    if (reloadStatus == true) {
      //リロードステータスを元に戻す
      setReloadStatus(false);
    }
  }, [reloadStatus]);

  const handleDateChange = (date) => {
    const selectedDate = moment(date).format('YYYY-MM-DD');
    dispatch(resetRankingProductPage());
    dispatch(changeRankingProductDate(selectedDate));
    setDisplay('none');
    setCalenderIsOpen(false);
    // カテゴリ選択済みの場合のみランキング商品を取得する
    if (category.length > 0 && category[0].categoryId !== '') {
      dispatch(fetchRankingProductList());
    }
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <DatePicker
        format={channel == 1 ? 'yyyy年MM月' : 'yyyy年MM月dd日'}
        caretAs={CalendarTodayIcon}
        style={{ width: 160 }}
        onChange={handleDateChange}
        value={dateValue}
        container={() => containerRef.current}
        placeholder="日付を選択してください"
        locale={{
          year: '年',
          month: '月',
          sunday: '日',
          monday: '月',
          tuesday: '火',
          wednesday: '水',
          thursday: '木',
          friday: '金',
          saturday: '土',
          ok: '選択する'
        }}
        onClick={() => {
          setCalenderIsOpen(true);
          setDisplay('block');
        }}
        open={calenderIsOpen}
        oneTap
        disabled={isLoading || registerLoading}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'white',
          width: '265px',
          maxHeight: '50vh',
          overflowY: 'scroll',
          overflowX: 'hidden',
          display: display,
          position: 'absolute',
          top: '40px',
          left: 0,
          boxShadow: '0 4px 4px rgba(0,0,0,.12), 0 0 10px rgba(0,0,0,.06)',
          borderRadius: 1,
          zIndex: 10
        }}
      >
        <Box ref={containerRef} sx={{ height: '280px', width: '265px' }}></Box>
      </Box>
    </Box>
  );
}
