/* eslint-disable*/

import { Container, Typography } from '@material-ui/core'; // eslint-disable-line
import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  amazon_100items,
  rakuten_100items,
  yahoo_100items
} from 'feactures/brand/brandRankSlice';
import { currentchannel } from 'feactures/channel/channelSlice';
import { otherSelectedBrand } from 'feactures/brand/brandSlice';
import BrandItemList from './BranditemList';

const BrandItemTable = (props) => {
  const { yokoziku } = props;
  const a_100items = useSelector(amazon_100items);
  const r_100items = useSelector(rakuten_100items);
  const y_100items = useSelector(yahoo_100items);
  const channel = useSelector(currentchannel);
  const selectBrand = useSelector(otherSelectedBrand);

  let label = '';
  if (channel === 1) {
    if (selectBrand.amazon === '') {
      label = 'ブランド名：ブランドが選択されていません';
    } else {
      label = `ブランド名：${selectBrand.amazon.brand}＞商品別売上`;
    }
  } else if (channel === 2) {
    if (selectBrand.rakuten === '') {
      label = 'ブランド名：店舗が選択されていません';
    } else {
      label = `ブランド名：${selectBrand.rakuten.brand}＞商品別売上`;
    }
  } else if (channel === 3) {
    if (selectBrand.yahoo === '') {
      label = 'ブランド名：ブランドが選択されていません';
    } else {
      label = `ブランド名：${selectBrand.yahoo.brand}＞商品別売上`;
    }
  }

  return (
    <Container maxWidth={false} sx={{ mt: 3 }}>
      <Typography
        fontSize="14px"
        fontWeight="bold"
        sx={{ paddingTop: '12px', paddingBottom: '12px' }}
      >
        {label}
      </Typography>

      {channel == 1 ? (
        <BrandItemList
          top100items={a_100items !== [] ? a_100items : []}
          yokoziku={yokoziku}
          section="競合"
        />
      ) : null}
      {channel == 2 ? (
        <BrandItemList
          top100items={r_100items !== [] ? r_100items : []}
          yokoziku={yokoziku}
          section="競合"
        />
      ) : null}
      {channel == 3 ? (
        <BrandItemList
          top100items={y_100items !== [] ? y_100items : []}
          yokoziku={yokoziku}
          section="競合"
        />
      ) : null}
    </Container>
  );
};

export default BrandItemTable;
