/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import client from 'index.js';
import { apiUrl } from 'App.js';

//楽天RMSAuthCheck
export const fetchRmsAuthCheck = createAsyncThunk(
  'check_rms_key/post',
  async (arg, thunkAPI) => {
    const { accountId, userId } = thunkAPI.getState().user;
    const { serviceSecret, licenseKey, rCsvStatus, rLoginId, rLoginPass } = arg;
    const res = await client.post(`${apiUrl}check_rms_key`, {
      account_id: accountId,
      user_id: userId,
      serviceSecret: serviceSecret,
      licenseKey: licenseKey
      // r_login_id: rLoginId,
      // r_login_pass: rLoginPass,
      // r_csv_status: rCsvStatus
    });
    return res.data;
  }
);

export const fetchRmsAuthScraping = createAsyncThunk(
  'check_rms_outh_scraping/post',
  async (arg, thunkAPI) => {
    const { accountId, userId } = thunkAPI.getState().user;
    const { serviceSecret, licenseKey, rCsvStatus, rLoginId, rLoginPass } = arg;
    const res = await client.post(`${apiUrl}check_rms_outh_scraping`, {
      account_id: accountId,
      // user_id: userId,
      // serviceSecret: serviceSecret,
      // licenseKey: licenseKey
      r_login_id: rLoginId,
      r_login_pass: rLoginPass
      // r_csv_status: rCsvStatus
    });
    return res.data;
  }
);

// //楽天RMSKey&ID変更
// export const fetchChangeRmsKey = createAsyncThunk(
//   'change_rms_id/post',
//   async (arg, thunkAPI) => {
//     const { accountId, userId } = thunkAPI.getState().user;
//     const { serviceSecret, licenseKey, rCsvStatus, rLoginId, rLoginPass } = arg;
//     const res = await client.post(`${apiUrl}change_rms_id`, {
//       account_id: accountId,
//       user_id: userId,
//       serviceSecret: serviceSecret,
//       licenseKey: licenseKey,
//       r_login_id: rLoginId,
//       r_login_pass: rLoginPass,
//       r_csv_status: rCsvStatus
//     });
//     return res.data;
//   }
// );

export const rmsSlice = createSlice({
  name: 'rmsKeyStatus',
  initialState: {
    licenseStatus: '',
    licenseExpiryDate: '',
    purchaseAPIStatus: '',
    itemAPIStatus: '',
    licenseCheckLoading: false,
    rmsAuthCheckStatus: true
  },

  reducers: {
    resetRmsStatus(state, action) {
      state.licenseStatus = '';
      state.licenseExpiryDate = '';
      state.purchaseAPIStatus = '';
      state.itemAPIStatus = '';
      state.licenseCheckLoading = false;
    }
  },
  extraReducers: (builder) => {
    // builder.addCase(fetchChangeRmsKey.fulfilled, (state, action) => {
    //   state.serviceSecret = action.payload.serviceSecret;
    //   state.licenseKey = action.payload.licenseKey;
    //   state.rLoginId = action.payload.rLoginId;
    //   state.rLoginPass = action.payload.rLoginPass;
    //   state.rCsvStatus = action.payload.rCsvStatus;
    //   return state;
    // });
    // builder.addCase(fetchChangeRmsKey.rejected, (state) => {
    //   console.log('変更失敗');
    // });
    builder.addCase(fetchRmsAuthCheck.fulfilled, (state, action) => {
      state.licenseStatus = action.payload.license_status;
      state.licenseExpiryDate = action.payload.license_expiry_date;
      state.purchaseAPIStatus = action.payload.purchase_API_status;
      state.itemAPIStatus = action.payload.item_API_status;
      state.licenseCheckLoading = false;
      state.rmsAuthCheckStatus = action.payload.rms_auth_check_process_status;
      return state;
    });
    builder.addCase(fetchRmsAuthCheck.rejected, (state) => {
      console.log('変更失敗');
      state.licenseCheckLoading = false;
    });
    builder.addCase(fetchRmsAuthCheck.pending, (state) => {
      state.licenseCheckLoading = true;
    });
    // スクレイピングチェック
    builder.addCase(fetchRmsAuthScraping.fulfilled, (state, action) => {
      state.rmsAuthCheckStatus = action.payload.rms_auth_check_process_status;
      // console.log(action.payload.rms_auth_check_process_status); ---> false返ってくる
      return state;
    });
    builder.addCase(fetchRmsAuthScraping.rejected, (state) => {
      // state.licenseCheckLoading = false;
    });
    builder.addCase(fetchRmsAuthScraping.pending, (state) => {
      // state.licenseCheckLoading = true;
    });
  }
});

export const { resetRmsStatus } = rmsSlice.actions;
export const rmsKeyInfo = (state) => state.rmsKeyStatus;
export const rmsAuthStatus = (state) => state.rmsKeyStatus.rmsAuthCheckStatus;

export default rmsSlice.reducer;
