// import * as React from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { unwrapResult } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  DeleteOutlined,
  RemoveRedEyeOutlined,
  TrendingUp,
  ArrowForward,
  ArrowDownward,
  ArrowUpward
} from '@material-ui/icons';
import {
  colors,
  CircularProgress
} from '@material-ui/core'; /* eslint-disable*/
import Trendgraph from 'components/molecules/dashboard/Trendgraph';
import {
  amazonTrackingData,
  fetchAsyncTrackingWords
} from 'feactures/monitoring/trackingSlice';
import {
  fetchAsyncTrends,
  trendsDelete,
  deleteloading
} from 'feactures/monitoring/trendsSlice';
import { useSelector, useDispatch } from 'react-redux';
import { currentchannel } from 'feactures/channel/channelSlice';

import DialogSelect from 'components/atoms/select/DialogSelect';

const amazonData = [
  12, 10, 11, 7, 8, 12, 9, 10, 16, 14, 12, 13, 11, 12, 10, 11, 7, 8, 12, 9, 10,
  16, 14, 12, 13, 11
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  backgroundColor: colors.grey[300],
  borderRadius: 'none'
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const url = 'https://m.media-amazon.com/images/I/';

//子テーブル
function Row(props) {
  const {
    row,
    term,
    eyeopen,
    selectedId,
    setSelectid,
    modalopen,
    setModalOpen,
    graphTitle,
    channel
  } = props;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [checkList, setCheckList] = useState([]);
  const deleteLoading = useSelector(deleteloading);

  const removeCheckList = (selectedId) => {
    const newCheckList = checkList.filter((x) => x !== selectedId);
    setCheckList(newCheckList);
  };

  const changeBox = (e) => {
    if (e.target.checked) {
      // console.log(`リストに追加するid：${e.target.value}`);
      setCheckList([...checkList, Number(e.target.value)]);
    } else {
      // console.log(`リストから外すid：${e.target.value}`);
      removeCheckList(Number(e.target.value));
    }
  };

  const trendbutton = (asin) => {
    dispatch(
      fetchAsyncTrends({
        asin: asin,
        keywordId: checkList,
        graphTitle: graphTitle
      })
    );
    setSelectid(asin);
    setModalOpen(!modalopen);
  };

  const deletehandle = async (id) => {
    await dispatch(
      trendsDelete({
        keywordId: id,
        graphTitle: graphTitle
      })
    )
      .then(unwrapResult) //削除成功（fullfilled）
      .then(() => {
        dispatch(fetchAsyncTrackingWords({ graphTitle: graphTitle }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.asin}
        </TableCell>
        <TableCell align="left">
          <Box
            sx={{
              height: 40,
              display: 'inline-block'
            }}
            component="div"
            my={2}
            textOverflow="ellipsis"
            overflow="hidden"
            bgcolor="background.paper"
          >
            {row.describe}
          </Box>
        </TableCell>
        <TableCell align="center" maxwidth={40}>
          <Box
            sx={{
              height: 70,
              display: 'inline-block'
            }}
          >
            <img
              src={channel == 1 ? url + row.item_image : row.item_image}
              height="100%"
              maxwidth="100%"
              loading="lazy"
            />
          </Box>
        </TableCell>
        <TableCell align="center" sx={{ fontSize: 18 }}>
          {row.keywordnum}
        </TableCell>
        <TableCell align="center" sx={{ fontSize: 16 }}>
          <Box display="flex" justifyContent="space-around">
            {/* <Chip icon={<ArrowForward />} label={row.rank_change.stay} /> */}
            <ArrowForward fontSize="small" color="primary" />
            <Typography>{row.rank_change.stay}</Typography>
            <ArrowUpward fontSize="small" color="success" />
            <Typography> {row.rank_change.up}</Typography>
            <ArrowDownward fontSize="small" color="secondary" />
            <Typography> {row.rank_change.down}</Typography>
          </Box>
        </TableCell>
        <TableCell align="center">
          <TrendingUp
            onClick={() => {
              trendbutton(row.asin);
            }}
          />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ my: 2, ml: 20 }}>
              {/* <Typography variant="h6" gutterBottom component="div">
                History
              </Typography> */}
              <Table
                stickyHeader
                size="small"
                aria-label="purchases"
                sx={{ align: 'right' }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center"> </TableCell>
                    <TableCell align="center">キーワード</TableCell>
                    <TableCell align="center"> </TableCell>
                    <TableCell align="center"> </TableCell>
                    <TableCell align="center">検索順位</TableCell>
                    <TableCell align="center">表示ページ</TableCell>
                    <TableCell align="center">　</TableCell>
                  </TableRow>
                </TableHead>
                {deleteLoading ? (
                  <CircularProgress size={15} />
                ) : (
                  <TableBody>
                    {row.keywords.map((keywordRow, index) => (
                      <TableRow key={index}>
                        <TableCell align="center" component="th" scope="row">
                          {/* {keywordRow.keyword} */}
                          <Checkbox
                            size="small"
                            sx={{ height: '20px' }}
                            value={keywordRow.id}
                            onChange={changeBox}
                          />
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {keywordRow.keyword}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {/* {keywordRow.keyword} */}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {/* {keywordRow.keyword} */}
                        </TableCell>
                        <TableCell align="center">
                          {keywordRow.rank == 0
                            ? '圏外です'
                            : keywordRow.rank + '位'}
                        </TableCell>
                        <TableCell align="center">
                          {keywordRow.rank == 0
                            ? '-'
                            : keywordRow.page + 'ページ'}
                        </TableCell>

                        <TableCell align="center">
                          <button onClick={() => deletehandle(keywordRow.id)}>
                            削除
                          </button>
                          {/* <TrendingUp
                          onClick={() => trendbutton(keywordRow.keyword)} //キーワード名を渡す
                        /> */}

                          {/* {graphopen ? <DialogSelect /> : ''} */}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

//親テーブル
const ItemDetailTable = (props) => {
  const { term, graphTitle } = props;
  const aTrackingDataInfo = useSelector(amazonTrackingData);
  const [selectid, setSelectid] = useState('');
  const [modalopen, setModalOpen] = useState(false);
  const handleClose = () => setModalOpen(false);
  const channel = useSelector(currentchannel);

  const rows = aTrackingDataInfo;

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead position="sticky">
            <TableRow>
              <StyledTableCell />
              <StyledTableCell align="center">
                {channel == 1 ? 'ASIN' : '商品管理番号'}
              </StyledTableCell>
              <StyledTableCell align="center">商品名</StyledTableCell>
              <StyledTableCell align="center">　　　</StyledTableCell>
              <StyledTableCell align="center" sx={{ minWidth: '160px' }}>
                登録キーワード数
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ minWidth: '160px' }}>
                順位変動
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ minWidth: '100px' }}>
                トレンド
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {/* </TableContainer> */}
          <TableBody>
            {rows.length !== 0 ? (
              <>
                {rows.map((row, index) => (
                  <Row
                    term={term}
                    graphTitle={graphTitle}
                    key={index}
                    row={row}
                    setSelectid={setSelectid}
                    setModalOpen={setModalOpen}
                    modalopen={modalopen}
                    channel={channel}
                  />
                ))}
                <Modal
                  open={modalopen}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  bgcolor={colors.grey[500]}
                  //   overflow="hidden"
                >
                  <Box sx={style}>
                    <Trendgraph
                      title={selectid}
                      graphdata={amazonData}
                      term={term}
                    />
                  </Box>
                </Modal>
              </>
            ) : (
              <TableRow>
                <TableCell>データがありません</TableCell>
              </TableRow>
            )}
          </TableBody>
          {/* </Table> */}
        </Table>
      </TableContainer>
    </>
  );
};

export default ItemDetailTable;
