/* eslint-disable*/
import { ComposedChart, Bar, Line } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  useTheme,
  Typography,
  Link
  // DeleteIcon
} from '@material-ui/core';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Launch } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'chartjs-adapter-moment';
import { currentchannel } from 'feactures/channel/channelSlice';
import { termContext } from 'components/pages/processKPI/Monitoringboard2';

//「？」マークにカーソルが当たったときの吹き出し
export const HtmlTooltip = styled(({ className, placement, ...props }) => (
  <Tooltip
    {...props}
    arrow
    placement={placement}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper
    // border: '1px solid #dadde9'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    maxWidth: 280
  }
}));

const SalesByAsingraph = (props) => {
  const {
    pre,
    title,
    titleColor,
    graphdata,
    graphtype,
    term,
    num,
    rate,
    tanni,
    asin,
    name
  } = props; // eslint-disable-line
  const theme = useTheme();
  const dispatch = useDispatch();
  const bench = '1';
  const channel = useSelector(currentchannel);
  // const term = useContext(termContext);
  let rateLabel = '期間比較';
  if (bench === '2') {
    rateLabel = '競合比較';
  }
  const numEn = num?.toLocaleString(); // eslint-disable-line
  const preEn = pre?.toLocaleString(); // eslint-disable-line

  const options = {
    interaction: {
      mode: 'nearest',
      intersect: false
    },
    scales: {
      x: {
        scaleLabel: {
          // 軸ラベル
          display: true, // 表示設定
          labelString: '横軸ラベル', // ラベル
          fontColor: 'red', // 文字の色
          fontSize: 16 // フォントサイズ
        },
        grid: {
          display: false,
          drawBorder: false
        },
        title: {
          display: false
          // text: 'x',
          // font: {
          //   size: 14
          // }
        }
      },
      y: {
        reverse: tanni === '位',
        // scaleLabel: {
        //   display: true,
        //   labelString: tanni
        // },
        ticks: {
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          min: 1
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider
        }
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem, y) {
            let label = tooltipItem.dataset.label;
            let data = tooltipItem.raw;
            label += ' : ' + data.toLocaleString() + tanni;
            return label;
          }
        }
      },
      legend: {
        display: bench != 2 ? false : true,
        labels: { boxWidth: 10, boxHeight: 10 }
      }, //ラベル名(凡例)非表示
      // tooltips: {
      //   mode: 'x'
      // }
      datalabels: {
        display: false
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: true,
    cornerRadius: 20,
    layout: {
      maxwidth: '100px',
      padding: {
        left: 0,
        right: 20,
        top: 0,
        bottom: 0
      }
    }
  };
  const data = {
    datasets: [
      {
        label: title,
        radius: 0,
        barThickness: 'flex',
        backgroundColor: titleColor,
        borderColor: titleColor,
        data: graphdata,
        maxBarThickness: 20
      }
    ],
    labels: term
  };

  // /* prettier-ignore */
  return (
    <>
      <Card sx={{ borderRadius: '0px', pb: 0 }}>
        <CardContent
          sx={{
            p: '8px',
            '&:last-child': {
              paddingBottom: '0px'
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              flex: 'auto 0 0',
              overflowX: 'auto',
              alignItems: 'center'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: 'auto 0 0',
                minWidth: '280px',
                maxWidth: '280px',
                m: '8px 0px',
                justifyContent: 'center'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Link
                  underline="hover"
                  href={
                    channel == 1
                      ? 'https://www.amazon.co.jp/dp/' + asin
                      : 'https://search.rakuten.co.jp/search/mall/' + asin
                  }
                  target="_blank"
                  color="text.secondary"
                  fontSize={15}
                  fontWeight="bold"
                >
                  {asin}
                  <Launch sx={{ ml: 0.5 }} fontSize="inherit" />
                </Link>
                {name != 0 && (
                  <Tooltip title={name} placement="right">
                    <Typography
                      component="div"
                      fontSize="0.8rem"
                      overflow="hidden"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      width="100%"
                      // mb={1}
                      sx={{
                        color: titleColor,
                        fontWeight: 'bold',
                        overflowWrap: 'anywhere'
                      }}
                    >
                      {name}
                    </Typography>
                  </Tooltip>
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'flex-end',
                  boxsizing: 'border-box',
                  mt: 1,
                  mb: 2
                }}
              >
                <Typography
                  variant="h5"
                  color="text.secondary"
                  component="div"
                  mt={'10px'}
                  mr={0.5}
                >
                  合計
                </Typography>
                {tanni == '円' || '回' ? (
                  <Typography
                    variant="h2"
                    fontSize="2.6rem"
                    fontWeight="600"
                    color="text.secondary"
                    component="div"
                  >
                    {numEn}
                  </Typography>
                ) : (
                  <Typography
                    fontSize="2.6rem"
                    fontWeight="500"
                    color="text.secondary"
                    component="div"
                  >
                    {num}
                  </Typography>
                )}
                <Typography
                  variant="h5"
                  color="text.secondary"
                  component="div"
                  mt={'10px'}
                  ml={'3px'}
                >
                  {tanni}
                </Typography>
                {/* <HtmlTooltip
                  placement="top"
                  title={<span style={{ whiteSpace: 'pre-line' }}>{info}</span>}
                >
                  <span>
                    <IconButton>
                      <HelpOutlined style={{ fontSize: '15px', mr: 3 }} />
                    </IconButton>
                  </span>
                </HtmlTooltip> */}
              </Box>
              <Box
                ml={2}
                textAlign="center"
                alignItems="baseline"
                fontSize="11px"
                display="flex"
                justifyContent="center"
              >
                <Typography fontSize="11px">{rateLabel}&nbsp;</Typography>
                {/* {bench == 2 ? ( */}
                {rate < 0 ? (
                  <Typography variant="h6" color="#D02C3A" component="div">
                    {rate}%
                  </Typography>
                ) : (
                  <Typography variant="h6" color="#4BACC6" component="div">
                    +{rate}%
                  </Typography>
                )}
                <Typography fontSize="11px">
                  &nbsp; ({bench != 2 ? '前期間 ' : '競合 '}
                  {tanni == '円' || '回' ? preEn : pre}
                  {tanni})
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                height: 150,
                width: '90%'
              }}
            >
              {graphtype === 'Bar' ? (
                <Bar
                  width="100%"
                  boxsizing="border-box"
                  data={data}
                  options={options}
                />
              ) : (
                ''
              )}
              {graphtype === 'Line' ? (
                <Line
                  width="100%"
                  boxsizing="border-box"
                  data={data}
                  options={options}
                />
              ) : (
                ''
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default SalesByAsingraph;
