/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useEffect, useState, useCallback } from 'react';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const fetchMonitorPost = createAsyncThunk(
  'monitor/post',
  async (arg, thunkAPI) => {
    const { term, start, end } = await thunkAPI.getState().date;
    const { userId } = thunkAPI.getState().user;
    const { channel, benchmark } = thunkAPI.getState().currentchannel;
    const { checkGroupList, checkGroupNameList } = thunkAPI.getState().group;
    // console.log(checkGroupNameList);

    const res = await client.post(`${apiUrl}monitoring`, {
      start: start,
      end: end,
      term: term,
      channel: channel,
      user_id: userId,
      group_list: checkGroupList,
      group_name_list: checkGroupNameList,
      comparing_type: Number(benchmark)
    });
    return res.data;
  }
);

export const fetchMonitorPost2 = createAsyncThunk(
  'monitor2/post',
  async (arg, thunkAPI) => {
    const { term, start2, end2 } = await thunkAPI.getState().date;
    const { userId } = thunkAPI.getState().user;
    const { channel, benchmark } = thunkAPI.getState().currentchannel;
    const { checkGroupList, checkGroupNameList } = thunkAPI.getState().group;

    const res = await client.post(`${apiUrl}monitoring`, {
      start: start2,
      end: end2,
      term: term,
      channel: channel,
      user_id: userId,
      group_list: checkGroupList,
      group_name_list: checkGroupNameList,
      comparing_type: Number(benchmark)
    });
    return res.data;
  }
);

export const fetchAsinMonitor = createAsyncThunk(
  'asin/monitor/post',
  async (arg, thunkApi) => {
    const { term, start, end } = thunkApi.getState().date;
    const { userId } = thunkApi.getState().user;
    const { currentAsin } = thunkApi.getState().monitoring;
    const { channel } = thunkApi.getState().currentchannel;

    let postChannel = channel;
    if (channel == 0) {
      postChannel = currentAsin.channel;
    }

    const res = await client.post(`${apiUrl}monitoring/asin`, {
      start: start,
      end: end,
      term: term,
      user_id: userId,
      asin: currentAsin.code,
      channel: postChannel
    });
    return res.data;
  }
);

export const fetchAsinMonitor2 = createAsyncThunk(
  'asin/monitor2/post',
  async (arg, thunkApi) => {
    const { term, start2, end2 } = thunkApi.getState().date;
    const { userId } = thunkApi.getState().user;
    const { currentAsin } = thunkApi.getState().monitoring;
    const { channel } = thunkApi.getState().currentchannel;

    let postChannel = channel;
    if (channel == 0) {
      postChannel = currentAsin.channel;
    }

    const res = await client.post(`${apiUrl}monitoring/asin`, {
      start: start2,
      end: end2,
      term: term,
      user_id: userId,
      channel: postChannel,
      asin: currentAsin.code
    });
    return res.data;
  }
);

export const fetchGroupMonitor = createAsyncThunk(
  'group/monitor/post',
  async (arg, thunkApi) => {
    const { term, start, end } = thunkApi.getState().date;
    const { userId } = thunkApi.getState().user;
    const { currentGroup } = thunkApi.getState().monitoring;
    const { channel } = thunkApi.getState().currentchannel;

    const res = await client.post(`${apiUrl}monitoring/group`, {
      start: start,
      end: end,
      term: term,
      user_id: userId,
      group_id: currentGroup.id,
      channel: channel
    });
    return res.data;
  }
);

export const fetchGroupMonitor2 = createAsyncThunk(
  'group/monitor2/post',
  async (arg, thunkApi) => {
    const { term, start2, end2 } = thunkApi.getState().date;
    const { userId } = thunkApi.getState().user;
    const { currentGroup } = thunkApi.getState().monitoring;
    const { channel } = thunkApi.getState().currentchannel;

    const res = await client.post(`${apiUrl}monitoring/group`, {
      start: start2,
      end: end2,
      term: term,
      user_id: userId,
      group_id: currentGroup.id,
      channel: channel
    });
    return res.data;
  }
);

const initialstate = {
  sales: { num: 0, rate: 0, pre: 0 },
  views: { num: 0, rate: 0, pre: 0 },
  cvr: { num: 0, rate: 0, pre: 0 },
  price: { num: 0, rate: 0, pre: 0 },
  kpiGraph: { sales: [], views: [], price: [], cvr: [] },
  rivalKpiGraph: { sales: [], views: [], price: [], cvr: [] },
  compareKpiGraph: {
    sales: [],
    views: [],
    price: [],
    cvr: []
  },
  compareSales: { num: 0, rate: 0, pre: 0 },
  compareViews: { num: 0, rate: 0, pre: 0 },
  compareCvr: { num: 0, rate: 0, pre: 0 },
  comparePrice: { num: 0, rate: 0, pre: 0 },
  sales_up_action: [],
  group_summary: [],
  subKPI_summary: [],
  asin_summary: []
};

const monitoring = createSlice({
  name: 'monitoring',
  initialState: {
    allMall: initialstate,
    amazon: initialstate,
    rakuten: initialstate,
    yahoo: initialstate,
    termCompare: false,
    currentAsin: '',
    currentGroup: '',
    isLoading: false,
    error: false
  },
  reducers: {
    setPendingUserFirstName(state, action) {
      state.pendingUserFirstName = action.payload;
    },
    changeTermCompareStatus(state, action) {
      state.termCompare = action.payload;
    },
    selectMonitoringAsin(state, action) {
      state.currentAsin = action.payload;
    },
    selectMonitoringGroup(state, action) {
      state.currentGroup = action.payload;
    },
    resetCompareGraph(state, action) {
      return {
        ...state,
        amazon: {
          ...state.amazon,
          compareKpiGraph: {
            sales: [],
            views: [],
            price: [],
            cvr: []
          },
          compareSales: { num: 0, rate: 0, pre: 0 },
          compareViews: { num: 0, rate: 0, pre: 0 },
          compareCvr: { num: 0, rate: 0, pre: 0 },
          comparePrice: { num: 0, rate: 0, pre: 0 }
        },
        rakuten: {
          ...state.rakuten,
          compareKpiGraph: {
            sales: [],
            views: [],
            price: [],
            cvr: []
          },
          compareSales: { num: 0, rate: 0, pre: 0 },
          compareViews: { num: 0, rate: 0, pre: 0 },
          compareCvr: { num: 0, rate: 0, pre: 0 },
          comparePrice: { num: 0, rate: 0, pre: 0 }
        },
        yahoo: {
          ...state.yahoo,
          compareKpiGraph: {
            sales: [],
            views: [],
            price: [],
            cvr: []
          },
          compareSales: { num: 0, rate: 0, pre: 0 },
          compareViews: { num: 0, rate: 0, pre: 0 },
          compareCvr: { num: 0, rate: 0, pre: 0 },
          comparePrice: { num: 0, rate: 0, pre: 0 }
        },
        allMall: {
          ...state.allMall,
          compareKpiGraph: {
            sales: [],
            views: [],
            price: [],
            cvr: []
          },
          compareSales: { num: 0, rate: 0, pre: 0 },
          compareViews: { num: 0, rate: 0, pre: 0 },
          compareCvr: { num: 0, rate: 0, pre: 0 },
          comparePrice: { num: 0, rate: 0, pre: 0 }
        }
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMonitorPost.fulfilled, (state, action) => {
      if (action.payload.channel == 'rakuten') {
        return {
          ...state,
          rakuten: {
            ...state.rakuten,
            sales: action.payload.kpi_num.sales,
            views: action.payload.kpi_num.views,
            cvr: action.payload.kpi_num.cvr,
            price: action.payload.kpi_num.price,
            kpiGraph: action.payload.kpi_graph,
            rivalKpiGraph: action.payload.rival_kpi,
            sales_up_action: action.payload.sales_up_action,
            group_summary: action.payload.group_summary,
            subKPI_summary: action.payload.subKPI_summary,
            asin_summary: action.payload.asin_summary
          },
          allMall: { ...state.allMall },
          amazon: { ...state.amazon },
          yahoo: { ...state.yahoo },
          isLoading: false,
          error: false
        };
      }
      if (action.payload.channel == 'amazon') {
        return {
          ...state,
          amazon: {
            ...state.amazon,
            sales: action.payload.kpi_num.sales,
            views: action.payload.kpi_num.views,
            cvr: action.payload.kpi_num.cvr,
            price: action.payload.kpi_num.price,
            kpiGraph: action.payload.kpi_graph,
            rivalKpiGraph: action.payload.rival_kpi,
            sales_up_action: action.payload.sales_up_action,
            group_summary: action.payload.group_summary,
            subKPI_summary: action.payload.subKPI_summary,
            asin_summary: action.payload.asin_summary
          },
          allMall: { ...state.allMall },
          rakuten: { ...state.rakuten },
          yahoo: { ...state.yahoo },
          isLoading: false,
          error: false
        };
      }
      if (action.payload.channel == 'allMall') {
        return {
          ...state,
          allMall: {
            ...state.allMall,
            sales: action.payload.kpi_num.sales,
            views: action.payload.kpi_num.views,
            cvr: action.payload.kpi_num.cvr,
            price: action.payload.kpi_num.price,
            kpiGraph: action.payload.kpi_graph,
            rivalKpiGraph: action.payload.rival_kpi,
            sales_up_action: action.payload.sales_up_action,
            group_summary: action.payload.group_summary,
            subKPI_summary: action.payload.subKPI_summary,
            asin_summary: action.payload.asin_summary
          },
          amazon: { ...state.amazon },
          rakuten: { ...state.rakuten },
          yahoo: { ...state.yahoo },
          isLoading: false,
          error: false
        };
      }
    });
    builder.addCase(fetchMonitorPost.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        error: false
      };
    });
    builder.addCase(fetchMonitorPost.rejected, (state, action) => {
      return {
        ...state,
        allMall: initialstate,
        amazon: initialstate,
        rakuten: initialstate,
        yahoo: initialstate,
        isLoading: false,
        error: true
      };
    });
    builder.addCase(fetchMonitorPost2.fulfilled, (state, action) => {
      if (action.payload.channel == 'rakuten') {
        return {
          ...state,
          rakuten: {
            ...state.rakuten,
            compareSales: action.payload.kpi_num.sales,
            compareViews: action.payload.kpi_num.views,
            compareCvr: action.payload.kpi_num.cvr,
            comparePrice: action.payload.kpi_num.price,
            compareKpiGraph: action.payload.kpi_graph
          },
          allMall: { ...state.allMall },
          amazon: { ...state.amazon },
          yahoo: { ...state.yahoo },
          isLoading: false,
          error: false
        };
      }
      if (action.payload.channel == 'amazon') {
        return {
          ...state,
          amazon: {
            ...state.amazon,
            compareSales: action.payload.kpi_num.sales,
            compareViews: action.payload.kpi_num.views,
            compareCvr: action.payload.kpi_num.cvr,
            comparePrice: action.payload.kpi_num.price,
            compareKpiGraph: action.payload.kpi_graph
          },
          allMall: { ...state.allMall },
          rakuten: { ...state.rakuten },
          yahoo: { ...state.yahoo },
          isLoading: false,
          error: false
        };
      }
      if (action.payload.channel == 'allMall') {
        return {
          ...state,
          allMall: {
            ...state.allMall,
            compareSales: action.payload.kpi_num.sales,
            compareViews: action.payload.kpi_num.views,
            compareCvr: action.payload.kpi_num.cvr,
            comparePrice: action.payload.kpi_num.price,
            compareKpiGraph: action.payload.kpi_graph
          },
          amazon: { ...state.amazon },
          rakuten: { ...state.rakuten },
          yahoo: { ...state.yahoo },
          isLoading: false,
          error: false
        };
      }
    });
    builder.addCase(fetchMonitorPost2.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        error: false
      };
    });
    builder.addCase(fetchMonitorPost2.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: true
      };
    });
    builder.addCase(fetchAsinMonitor.fulfilled, (state, action) => {
      if (action.payload.channel == 'rakuten') {
        return {
          ...state,
          rakuten: {
            ...state.rakuten,
            sales: action.payload.kpi_num.sales,
            views: action.payload.kpi_num.views,
            cvr: action.payload.kpi_num.cvr,
            price: action.payload.kpi_num.price,
            kpiGraph: action.payload.kpi_graph
          },
          allMall: {
            ...state.allMall,
            sales: action.payload.kpi_num.sales,
            views: action.payload.kpi_num.views,
            cvr: action.payload.kpi_num.cvr,
            price: action.payload.kpi_num.price,
            kpiGraph: action.payload.kpi_graph
          },
          amazon: { ...state.amazon },
          yahoo: { ...state.yahoo },
          isLoading: false,
          error: false
        };
      }
      if (action.payload.channel == 'amazon') {
        return {
          ...state,
          amazon: {
            ...state.amazon,
            sales: action.payload.kpi_num.sales,
            views: action.payload.kpi_num.views,
            cvr: action.payload.kpi_num.cvr,
            price: action.payload.kpi_num.price,
            kpiGraph: action.payload.kpi_graph
          },
          allMall: {
            ...state.allMall,
            sales: action.payload.kpi_num.sales,
            views: action.payload.kpi_num.views,
            cvr: action.payload.kpi_num.cvr,
            price: action.payload.kpi_num.price,
            kpiGraph: action.payload.kpi_graph
          },
          rakuten: { ...state.rakuten },
          yahoo: { ...state.yahoo },
          isLoading: false,
          error: false
        };
      }
      if (action.payload.channel == 'allMall') {
        return {
          ...state,
          allMall: {
            ...state.allMall,
            sales: action.payload.kpi_num.sales,
            views: action.payload.kpi_num.views,
            cvr: action.payload.kpi_num.cvr,
            price: action.payload.kpi_num.price,
            kpiGraph: action.payload.kpi_graph
          },
          amazon: { ...state.amazon },
          rakuten: { ...state.rakuten },
          yahoo: { ...state.yahoo },
          isLoading: false,
          error: false
        };
      }
    });
    builder.addCase(fetchAsinMonitor.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        error: false
      };
    });
    builder.addCase(fetchAsinMonitor.rejected, (state, action) => {
      return {
        ...state,
        allMall: initialstate,
        amazon: initialstate,
        rakuten: initialstate,
        yahoo: initialstate,
        isLoading: false,
        error: true
      };
    });
    builder.addCase(fetchAsinMonitor2.fulfilled, (state, action) => {
      if (action.payload.channel == 'rakuten') {
        return {
          ...state,
          rakuten: {
            ...state.rakuten,
            compareSales: action.payload.kpi_num.sales,
            compareViews: action.payload.kpi_num.views,
            compareCvr: action.payload.kpi_num.cvr,
            comparePrice: action.payload.kpi_num.price,
            compareKpiGraph: action.payload.kpi_graph
          },
          allMall: {
            ...state.allMall,
            compareSales: action.payload.kpi_num.sales,
            compareViews: action.payload.kpi_num.views,
            compareCvr: action.payload.kpi_num.cvr,
            comparePrice: action.payload.kpi_num.price,
            compareKpiGraph: action.payload.kpi_graph
          },
          amazon: { ...state.amazon },
          yahoo: { ...state.yahoo },
          isLoading: false,
          error: false
        };
      }
      if (action.payload.channel == 'amazon') {
        return {
          ...state,
          amazon: {
            ...state.amazon,
            compareSales: action.payload.kpi_num.sales,
            compareViews: action.payload.kpi_num.views,
            compareCvr: action.payload.kpi_num.cvr,
            comparePrice: action.payload.kpi_num.price,
            compareKpiGraph: action.payload.kpi_graph
          },
          allMall: {
            ...state.allMall,
            compareSales: action.payload.kpi_num.sales,
            compareViews: action.payload.kpi_num.views,
            compareCvr: action.payload.kpi_num.cvr,
            comparePrice: action.payload.kpi_num.price,
            compareKpiGraph: action.payload.kpi_graph
          },
          rakuten: { ...state.rakuten },
          yahoo: { ...state.yahoo },
          isLoading: false,
          error: false
        };
      }
      if (action.payload.channel == 'allMall') {
        return {
          ...state,
          allMall: {
            ...state.allMall,
            compareSales: action.payload.kpi_num.sales,
            compareViews: action.payload.kpi_num.views,
            compareCvr: action.payload.kpi_num.cvr,
            comparePrice: action.payload.kpi_num.price,
            compareKpiGraph: action.payload.kpi_graph
          },
          amazon: { ...state.amazon },
          rakuten: { ...state.rakuten },
          yahoo: { ...state.yahoo },
          isLoading: false,
          error: false
        };
      }
    });
    builder.addCase(fetchAsinMonitor2.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        error: false
      };
    });
    builder.addCase(fetchAsinMonitor2.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: true
      };
    });
    builder.addCase(fetchGroupMonitor.fulfilled, (state, action) => {
      if (action.payload.channel == 'rakuten') {
        return {
          ...state,
          rakuten: {
            ...state.rakuten,
            sales: action.payload.kpi_num.sales,
            views: action.payload.kpi_num.views,
            cvr: action.payload.kpi_num.cvr,
            price: action.payload.kpi_num.price,
            kpiGraph: action.payload.kpi_graph
          },
          allMall: { ...state.allMall },
          amazon: { ...state.amazon },
          yahoo: { ...state.yahoo },
          isLoading: false,
          error: false
        };
      } else if (action.payload.channel == 'amazon') {
        return {
          ...state,
          amazon: {
            ...state.amazon,
            sales: action.payload.kpi_num.sales,
            views: action.payload.kpi_num.views,
            cvr: action.payload.kpi_num.cvr,
            price: action.payload.kpi_num.price,
            kpiGraph: action.payload.kpi_graph
          },
          allMall: { ...state.allMall },
          rakuten: { ...state.rakuten },
          yahoo: { ...state.yahoo },
          isLoading: false,
          error: false
        };
      }
      if (action.payload.channel == 'allMall') {
        return {
          ...state,

          allMall: {
            ...state.allMall,
            sales: action.payload.kpi_num.sales,
            views: action.payload.kpi_num.views,
            cvr: action.payload.kpi_num.cvr,
            price: action.payload.kpi_num.price,
            kpiGraph: action.payload.kpi_graph
          },
          amazon: { ...state.amazon },
          rakuten: { ...state.rakuten },
          yahoo: { ...state.yahoo },
          isLoading: false,
          error: false
        };
      }
    });
    builder.addCase(fetchGroupMonitor.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        error: false
      };
    });
    builder.addCase(fetchGroupMonitor.rejected, (state, action) => {
      return {
        ...state,
        allMall: initialstate,
        amazon: initialstate,
        rakuten: initialstate,
        yahoo: initialstate,
        isLoading: false,
        error: true
      };
    });
    builder.addCase(fetchGroupMonitor2.fulfilled, (state, action) => {
      if (action.payload.channel == 'rakuten') {
        return {
          ...state,
          rakuten: {
            ...state.rakuten,
            compareSales: action.payload.kpi_num.sales,
            compareViews: action.payload.kpi_num.views,
            compareCvr: action.payload.kpi_num.cvr,
            comparePrice: action.payload.kpi_num.price,
            compareKpiGraph: action.payload.kpi_graph
          },
          allMall: { ...state.allMall },
          amazon: { ...state.amazon },
          yahoo: { ...state.yahoo },
          isLoading: false,
          error: false
        };
      } else if (action.payload.channel == 'amazon') {
        return {
          ...state,
          amazon: {
            ...state.amazon,
            compareSales: action.payload.kpi_num.sales,
            compareViews: action.payload.kpi_num.views,
            compareCvr: action.payload.kpi_num.cvr,
            comparePrice: action.payload.kpi_num.price,
            compareKpiGraph: action.payload.kpi_graph
          },
          allMall: { ...state.allMall },
          rakuten: { ...state.rakuten },
          yahoo: { ...state.yahoo },
          isLoading: false,
          error: false
        };
      }
      if (action.payload.channel == 'allMall') {
        return {
          ...state,
          allMall: {
            ...state.allMall,
            compareSales: action.payload.kpi_num.sales,
            compareViews: action.payload.kpi_num.views,
            compareCvr: action.payload.kpi_num.cvr,
            comparePrice: action.payload.kpi_num.price,
            compareKpiGraph: action.payload.kpi_graph
          },
          amazon: { ...state.amazon },
          rakuten: { ...state.rakuten },
          yahoo: { ...state.yahoo },
          isLoading: false,
          error: false
        };
      }
    });
    builder.addCase(fetchGroupMonitor2.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        error: false
      };
    });
    builder.addCase(fetchGroupMonitor2.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: true
      };
    });
  }
});

export const all_sales = (state) => state.monitoring.allMall.sales;
export const all_views = (state) => state.monitoring.allMall.views;
export const all_cvr = (state) => state.monitoring.allMall.cvr;
export const all_price = (state) => state.monitoring.allMall.price;
export const all_kpiGraph = (state) => state.monitoring.allMall.kpiGraph;
export const all_rivalKpiGraph = (state) =>
  state.monitoring.allMall.rivalKpiGraph;
export const all_sales_up_action = (state) =>
  state.monitoring.allMall.sales_up_action;
export const all_group_summary = (state) =>
  state.monitoring.allMall.group_summary;
export const all_subKPI_summary = (state) =>
  state.monitoring.allMall.subKPI_summary;
export const all_asin_summary = (state) =>
  state.monitoring.allMall.asin_summary;

export const a_sales = (state) => state.monitoring.amazon.sales;
export const a_views = (state) => state.monitoring.amazon.views;
export const a_cvr = (state) => state.monitoring.amazon.cvr;
export const a_price = (state) => state.monitoring.amazon.price;
export const a_kpiGraph = (state) => state.monitoring.amazon.kpiGraph;
export const a_rivalKpiGraph = (state) => state.monitoring.amazon.rivalKpiGraph;
export const a_sales_up_action = (state) =>
  state.monitoring.amazon.sales_up_action;
export const a_group_summary = (state) => state.monitoring.amazon.group_summary;
export const a_subKPI_summary = (state) =>
  state.monitoring.amazon.subKPI_summary;
export const a_asin_summary = (state) => state.monitoring.amazon.asin_summary;

export const r_sales = (state) => state.monitoring.rakuten.sales;
export const r_views = (state) => state.monitoring.rakuten.views;
export const r_cvr = (state) => state.monitoring.rakuten.cvr;
export const r_price = (state) => state.monitoring.rakuten.price;
export const r_kpiGraph = (state) => state.monitoring.rakuten.kpiGraph;
export const r_rivalKpiGraph = (state) =>
  state.monitoring.rakuten.rivalKpiGraph;
export const r_sales_up_action = (state) =>
  state.monitoring.rakuten.sales_up_action;
export const r_group_summary = (state) =>
  state.monitoring.rakuten.group_summary;
export const r_subKPI_summary = (state) =>
  state.monitoring.rakuten.subKPI_summary;
export const r_asin_summary = (state) => state.monitoring.rakuten.asin_summary;

export const y_sales = (state) => state.monitoring.yahoo.sales;
export const y_views = (state) => state.monitoring.yahoo.views;
export const y_cvr = (state) => state.monitoring.yahoo.cvr;
export const y_price = (state) => state.monitoring.yahoo.price;
export const y_kpiGraph = (state) => state.monitoring.yahoo.kpiGraph;
export const y_rivalKpiGraph = (state) => state.monitoring.yahoo.rivalKpiGraph;
export const y_sales_up_action = (state) =>
  state.monitoring.yahoo.sales_up_action;
export const y_group_summary = (state) => state.monitoring.yahoo.group_summary;
export const y_subKPI_summary = (state) =>
  state.monitoring.yahoo.subKPI_summary;
export const y_asin_summary = (state) => state.monitoring.yahoo.asin_summary;
export const termCompare = (state) => state.monitoring.termCompare;
export const a_compareKpiGraph = (state) =>
  state.monitoring.amazon.compareKpiGraph;
export const r_compareKpiGraph = (state) =>
  state.monitoring.rakuten.compareKpiGraph;
export const y_compareKpiGraph = (state) =>
  state.monitoring.yahoo.compareKpiGraph;
export const all_compareKpiGraph = (state) =>
  state.monitoring.allMall.compareKpiGraph;
export const a_compareSales = (state) => state.monitoring.amazon.compareSales;
export const r_compareSales = (state) => state.monitoring.rakuten.compareSales;
export const y_compareSales = (state) => state.monitoring.yahoo.compareSales;
export const all_compareSales = (state) =>
  state.monitoring.allMall.compareSales;
export const a_compareViews = (state) => state.monitoring.amazon.compareViews;
export const r_compareViews = (state) => state.monitoring.rakuten.compareViews;
export const y_compareViews = (state) => state.monitoring.yahoo.compareViews;
export const all_compareViews = (state) =>
  state.monitoring.allMall.compareViews;
export const a_compareCvr = (state) => state.monitoring.amazon.compareCvr;
export const r_compareCvr = (state) => state.monitoring.rakuten.compareCvr;
export const y_compareCvr = (state) => state.monitoring.yahoo.compareCvr;
export const all_compareCvr = (state) => state.monitoring.allMall.compareCvr;
export const a_comparePrice = (state) => state.monitoring.amazon.comparePrice;
export const r_comparePrice = (state) => state.monitoring.rakuten.comparePrice;
export const y_comparePrice = (state) => state.monitoring.yahoo.comparePrice;
export const all_comparePrice = (state) =>
  state.monitoring.allMall.comparePrice;

// export const a_rate = (state) => state.monitoring.amazon.rate;
// export const a_viewImpacts = (state) => state.monitoring.amazon.viewsImpact;
// export const a_cvrImpacts = (state) => state.monitoring.amazon.cvrImpact;
// export const a_priceImpacts = (state) => state.monitoring.amazon.priceImpact;

// export const r_sales = (state) => state.monitoring.rakuten.sales;
// export const r_viewsNum = (state) => state.monitoring.rakuten.views;
// export const r_cvrNum = (state) => state.monitoring.rakuten.cvr;
// export const r_priceNum = (state) => state.monitoring.rakuten.price;
// export const r_rate = (state) => state.monitoring.rakuten.rate;
// export const r_viewImpacts = (state) => state.monitoring.rakuten.viewsImpact;
// export const r_cvrImpacts = (state) => state.monitoring.rakuten.cvrImpact;
// export const r_priceImpacts = (state) => state.monitoring.rakuten.priceImpact;

// export const y_sales = (state) => state.monitoring.yahoo.sales;
// export const y_viewsNum = (state) => state.monitoring.yahoo.views;
// export const y_cvrNum = (state) => state.monitoring.yahoo.cvr;
// export const y_priceNum = (state) => state.monitoring.yahoo.price;
// export const y_rate = (state) => state.monitoring.yahoo.rate;
// export const y_viewImpacts = (state) => state.monitoring.yahoo.viewsImpact;
// export const y_cvrImpacts = (state) => state.monitoring.yahoo.cvrImpact;
// export const y_priceImpacts = (state) => state.monitoring.yahoo.priceImpact;

export const monitorStatus = (state) => state.monitoring.isLoading;
export const currentAsin = (state) => state.monitoring.currentAsin;
export const currentGroup = (state) => state.monitoring.currentGroup;
export const {
  setPendingUserFirstName,
  changeTermCompareStatus,
  selectMonitoringAsin,
  selectMonitoringGroup,
  resetCompareGraph
} = monitoring.actions;

export default monitoring.reducer;
