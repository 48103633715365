/* eslint-disable */
import * as React from 'react';
import { useEffect, useState, useCallback, useContext, memo } from 'react';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Box, Typography, Stack } from '@material-ui/core'; // eslint-disable-line
import {
  changeTempDate,
  confirmDate,
  tempEnd,
  tempStart,
  termArray
} from 'feactures/date/dateSlice';
import { setError } from 'feactures/errorSlice';
import * as moment from 'moment/moment';
import 'moment/locale/ja';
import 'rsuite/dist/rsuite.min.css';
import './DatePickerStyle.css';
import { ReloadLayoutContext } from 'components/templates/dashboard/DashboardLayout';
import {
  fetchKeywordList,
  fetchMeasureAnalysisData,
  reportIsLoading,
  productIsLoading
} from 'feactures/measure/newMeasureSlice';
import { DatePicker } from 'rsuite';

moment.locale('ja');

const MeasureAnalysisDatePicker = memo(({ setTerm, page }) => {
  const { reloadStatus, setReloadStatus } = useContext(ReloadLayoutContext);
  const dispatch = useDispatch();
  const containerRef = React.useRef();
  const tempDateStart = useSelector(tempStart);
  const tempDateEnd = useSelector(tempEnd);
  const dateArray = useSelector(termArray);
  const isLoading = useSelector(reportIsLoading);
  const registerIsLoading = useSelector(productIsLoading);
  const [calenderIsOpen, setCalenderIsOpen] = useState(true);
  const [display, setDisplay] = useState('none');
  const now = new Date();
  const yesterday = now.setDate(now.getDate() - 1);

  var customS = new Date(tempDateStart);
  var customE = new Date(tempDateEnd);

  const dispatchTable = {
    measureAnalysis: [fetchKeywordList(), fetchMeasureAnalysisData()]
  };

  const dispatchHandle = useCallback((currentPage) => {
    return [...dispatchTable[currentPage]].map((r, index) => dispatch(r));
  }, []);

  // ページが変わった時
  useEffect(() => {
    handleDateChange(yesterday);
  }, []);

  useEffect(() => {
    if (reloadStatus == true) {
      //リロードステータスを元に戻す
      setReloadStatus(false);
    }
  }, [reloadStatus]);

  let label = [];
  const list_all_date = (params_start, params_end) => {
    label = [];
    var start = moment(params_start),
      end = moment(params_end);
    while (start.diff(end) <= 0) {
      label.push(start.format('M/D'));
      start.add(1, 'days');
    }
  };

  // 選択した日付から過去30日分の日付を選択
  const handleDateChange = (value) => {
    dispatch(setError({ state: null, data: null }));
    customE = value;
    customS = moment(value).subtract(29, 'days');
    const start = moment(customS).format('YYYY-MM-DD');
    const end = moment(customE).format('YYYY-MM-DD');
    list_all_date(start, end);
    setTerm(label);
    dispatch(
      changeTempDate({
        start: start,
        end: end,
        term: 'custom',
        termArray: label
      })
    );
    dispatch(confirmDate());
    dispatchHandle(page);
    setCalenderIsOpen(false);
    setDisplay('none');
  };

  return (
    <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
      <DatePicker
        format="yyyy年MM月dd日"
        caretAs={CalendarTodayIcon}
        style={{ width: 160 }}
        onChange={handleDateChange}
        value={customE}
        container={() => containerRef.current}
        placeholder="日付を選択してください"
        locale={{
          year: '年',
          month: '月',
          sunday: '日',
          monday: '月',
          tuesday: '火',
          wednesday: '水',
          thursday: '木',
          friday: '金',
          saturday: '土',
          ok: '選択する'
        }}
        onClick={() => {
          setCalenderIsOpen(true);
          setDisplay('block');
        }}
        open={calenderIsOpen}
        oneTap
        disabled={isLoading || registerIsLoading}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'white',
          width: '265px',
          maxHeight: '50vh',
          overflowY: 'scroll',
          overflowX: 'hidden',
          display: display,
          position: 'absolute',
          top: '40px',
          left: 0,
          boxShadow: '0 4px 4px rgba(0,0,0,.12), 0 0 10px rgba(0,0,0,.06)',
          borderRadius: 1,
          zIndex: 10
        }}
      >
        <Box ref={containerRef} sx={{ height: '280px', width: '265px' }}></Box>
      </Box>
      <Typography sx={{ ml: 1 }}>までの過去30日間の推移を確認する</Typography>
    </Box>
  );
});

export default MeasureAnalysisDatePicker;
