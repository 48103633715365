/* eslint-disable */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SimpleModal from 'components/atoms/modal/SimpleModal';
import {
  registerAmazon,
  registerRakuten,
  registerYahoo
} from 'feactures/channel/channelSlice';

const useStyles = makeStyles({
  root: {
    flexGrow: 1
  }
});

/* eslint-disable */
const CenteredTabs = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { labels, children } = props;
  const [value, setValue] = useState('0');

  const handleChange = (e, newValue) => {
    //引数e必要　消さない
    setValue(newValue);
    if (newValue == '0') {
      dispatch(registerAmazon());
    } else if (newValue == '1') {
      dispatch(registerRakuten());
    } else if (newValue == '2') {
      dispatch(registerYahoo());
    }
  };

  return (
    <div>
      <Paper>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                centered
              >
                {labels.map((label, index) => (
                  <Tab label={label} value={String(index)} key={index}></Tab>
                ))}
                {/* 一旦Amazonのみ表示 */}

                {/* {labels.map((label, index) => (
                  <div key={index}>
                    {index == 0 ? (
                      <Tab label={label} value={String(index)}></Tab>
                    ) : (
                      <Tab disabled label={label} value={String(index)}></Tab>
                    )}
                  </div>
                ))} */}
              </TabList>
            </Box>
            <SimpleModal />

            {children.map((child, index) => (
              <TabPanel value={String(index)} index={index} key={index}>
                {child}
              </TabPanel>
            ))}
          </TabContext>
        </Box>
      </Paper>
    </div>
  );
};

export default CenteredTabs;
