/* eslint-disable*/
import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  Paper,
  CardContent,
  Box,
  Typography,
  CircularProgress,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  TableSortLabel
} from '@material-ui/core'; /* eslint-disable*/
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import Rating from '@mui/material/Rating';
import {
  themeContext,
  kpithemeContext
} from 'components/pages/processKPI/Monitoringboard2';
import {
  currentchannel,
  benchmark,
  switchBenchmark
} from 'feactures/channel/channelSlice';
import { checkGroup } from 'feactures/monitoring/groupSlice';
import {
  fetchMonitorPost,
  a_subKPI_summary,
  r_subKPI_summary,
  y_subKPI_summary
} from 'feactures/monitoring/monitoringSlice';
import { amazonItems, rakutenItems } from './RatingSetting';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    verticalAlign: 'center',
    maxHeight: '20px',
    paddingTop: '1px',
    paddingBottom: '1px',
    fontSize: '11px',
    fontWeight: '600',
    border: '0px',

    // backgroundColor: theme.palette.common.black,
    backgroundColor: '#F2F2F2',
    color: theme.palette.text.secondary
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.text.secondary,
    fontSize: '11px'
  }
}));

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginRight: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell align="center">No</StyledTableCell>
        <StyledTableCell align="center">KPI</StyledTableCell>
        <StyledTableCell align="center">重要度</StyledTableCell>
        <StyledTableCell align="center">Sub KPI</StyledTableCell>
        <StyledTableCell align="center">Sub KPI指標</StyledTableCell>
        <StyledTableCell align="center">
          <TableSortLabel
            sx={{ color: 'text.secondary' }}
            active={true}
            direction={order}
            onClick={createSortHandler('termRate')}
            IconComponent={ArrowDropDownIcon}
          >
            期間比較
          </TableSortLabel>
        </StyledTableCell>
        <StyledTableCell align="center">競合比較</StyledTableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired
};

const SubKPICollapse = () => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('termRate');
  const channel = useSelector(currentchannel);
  const bench = useSelector(benchmark);
  // const channelColor = useContext(themeContext);
  const kpiTitleColor = useContext(kpithemeContext);

  const amazonSubKPISummaries = useSelector(a_subKPI_summary);
  const rakutenSubKPISummaries = useSelector(r_subKPI_summary);
  const yahooSubKPISummaries = useSelector(y_subKPI_summary);

  let subKPISummaryInfo = [];
  let rateItems = 0;
  if (channel == 1) {
    subKPISummaryInfo = amazonSubKPISummaries;
    rateItems = amazonItems;
  } else if (channel == 2) {
    subKPISummaryInfo = rakutenSubKPISummaries;
    rateItems = rakutenItems;
    // } else if (channel == 3) {
    //   subKPISummaryInfo = yahooSubKPISummaries;
    //   rateItems = yahooItems;
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      {/* <ExpandMore
        expand={expanded}
        onClick={handleExpandClick}
        aria-expanded={expanded}
        aria-label="show more"
      >
        <ExpandMoreIcon />
      </ExpandMore> */}

      <TableContainer
        component={Paper}
        sx={{
          width: '100%',
          overflow: 'auto',
          alignItems: 'center',
          borderRadius: '0px',
          boxShadow: '0'
        }}
      >
        <Table
          size="small"
          stickyHeader
          aria-label="purchases"
          sx={{ minWidth: 'max-content' }}
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {subKPISummaryInfo.length !== 0 ? (
              // subKPISummaryInfo.map((row, index) => {
              stableSort(subKPISummaryInfo, getComparator(order, orderBy)).map(
                (row, index) => {
                  return (
                    <TableRow key={index} border={0}>
                      <StyledTableCell align="center">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography color={kpiTitleColor[row.kpi]} variant="h5">
                          {row.kpi == 'views' && '閲覧数'}
                          {row.kpi == 'cvr' && 'CVR'}
                          {row.kpi == 'price' && '販売価格'}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Rating
                          name="half-rating-read"
                          value={rateItems[row.title].rating}
                          precision={0.5}
                          readOnly
                          max={3}
                          size="small"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {rateItems[row.title].jaTitle}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{ fontSize: '14px', fontWeight: 'bold' }}
                      >
                        {rateItems[row.title].sihyou}
                        <Typography fontSize="13px" component="span" mx="2px">
                          {row.subKPINum}
                        </Typography>
                        {rateItems[row.title].tanni}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box
                          display="flex"
                          flexWrap="wrap"
                          alignItems="baseline"
                          justifyContent="center"
                        >
                          <Typography
                            m={0.2}
                            p={0.2}
                            px={1}
                            variant="h6"
                            color={row.termRate > 0 ? '#4BACC6' : '#D02C3A'}
                          >
                            {row.termRate > 0 && '+'}
                            {row.termRate}%
                          </Typography>
                          (前期間　
                          {row.preTermNum.toLocaleString() +
                            rateItems[row.title].tanni}
                          )
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box
                          display="flex"
                          flexWrap="wrap"
                          alignItems="baseline"
                          justifyContent="center"
                        >
                          <Typography
                            m={0.2}
                            p={0.2}
                            px={1}
                            variant="h6"
                            color={row.rivalRate > 0 ? '#4BACC6' : '#D02C3A'}
                          >
                            {row.rivalRate > 0 && '+'}
                            {row.rivalRate}%
                          </Typography>
                          (競合　
                          {row.rivalNum.toLocaleString() +
                            rateItems[row.title].tanni}
                          )
                        </Box>
                      </StyledTableCell>
                    </TableRow>
                  );
                }
              )
            ) : (
              <TableRow border={0} sx={{ color: 'text.secondary' }}>
                <StyledTableCell>-</StyledTableCell>
                <StyledTableCell align="center">-</StyledTableCell>
                <StyledTableCell align="center">-</StyledTableCell>
                <StyledTableCell align="center">-</StyledTableCell>
                <StyledTableCell align="center">-</StyledTableCell>
                <StyledTableCell>-</StyledTableCell>
                <StyledTableCell>-</StyledTableCell>
              </TableRow>
            )}
          </TableBody>
          {/* </Collapse> */}
        </Table>
      </TableContainer>
    </>
  );
};

export default SubKPICollapse;
