/* eslint-disable*/
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  IconButton,
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  TextField,
  Typography,
  Alert,
  FormControl,
  InputLabel,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup
} from '@material-ui/core';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { fetchUser } from 'feactures/user/userSlice';
import { errorResMessage } from 'feactures/errorSlice';
import { fetchTrialRegister } from 'feactures/plan/premiumRegister';

const TrialRegister = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const errorMessage = useSelector(errorResMessage);
  const [error, setError] = useState(null);
  const [searchParams] = useSearchParams();

  const channel = searchParams.get('ch');

  let ch_name = '';
  if (channel == 'amazon') {
    ch_name = 'Amazon';
  } else if (channel == 'rakuten') {
    ch_name = '楽天';
  }

  // パスワードの表示・非表示処理
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async (values) => {
    setError(null);
    await dispatch(fetchTrialRegister(values))
      .then(unwrapResult) //unwrapResult で,dispatch結果がfulfilledかrejectedかで条件分岐できる
      .then(() => {
        if (channel == 'amazon') {
          navigate(
            `/trialregister/complete?ch=${channel}&st=${values.amazonSellerType}`,
            { replace: false }
          );
        }
        if (channel == 'rakuten') {
          navigate(`/trialregister/complete?ch=${channel}`, { replace: false });
        }
      })
      .catch((err) => {
        setError(err.message);
      });
  };

  const RequiredBox = () => {
    return (
      <Box
        color="#FFFFFF"
        bgcolor="#F26B6B"
        borderRadius={5}
        fontSize="12px"
        fontWeight="bold"
        px="8px"
        mb="1px"
        ml="8px"
      >
        必須
      </Box>
    );
  };
  const OptionBox = () => {
    return (
      <Box
        color="#ffffff"
        bgcolor="#669AF5"
        borderRadius={5}
        fontSize="12px"
        fontWeight="bold"
        px="8px"
        mb="1px"
        ml="8px"
      >
        任意
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box sx={{ mt: 3, width: '80%', maxWidth: '1000px' }}>
          <Formik
            initialValues={{
              mall: {
                amazon: channel == 'amazon' ? true : false,
                rakuten: channel == 'rakuten' ? true : false,
                yahoo: false
              },
              amazonPeriod: '',
              rakutenPeriod: '',
              yahooPeriod: '',
              userId: '',
              password: '',
              rLoginId: '',
              rLoginPass: '',
              amazonSellerType: ''
            }}
            validationSchema={Yup.object().shape({
              userId: Yup.string().max(255).required('ユーザーIDが未入力です'),
              mall: Yup.object()
                .shape({
                  amazon: Yup.boolean(),
                  rakuten: Yup.boolean(),
                  yahoo: Yup.boolean()
                })
                .test('mall', 'モールを選択してください', (value) => {
                  return value.amazon || value.rakuten || value.yahoo;
                }),
              rLoginId: Yup.string()
                .typeError('半角英数字で入力してください')
                .when(['mall', 'mall.rakuten'], (mall, rakuten, schema) => {
                  return mall && mall.rakuten
                    ? schema
                        .min(1, 'R-Login IDを入力してください')
                        .notOneOf(
                          ['NULL', 'null'],
                          'エラー：この文字列は登録できません'
                        )
                        .matches(/^\S*$/, 'スペースは入力できません')
                        .required('R-Login IDが未入力です')
                    : schema.min(0, '0文字以上の英数字を入れてください');
                }),
              rLoginPass: Yup.string()
                .typeError('半角英数字で入力してください')
                .when(['mall', 'mall.rakuten'], (mall, rakuten, schema) => {
                  return mall && mall.rakuten
                    ? schema
                        .min(1, 'R-Login パスワードを入力してください')
                        .notOneOf(
                          ['NULL', 'null'],
                          'エラー：この文字列は登録できません'
                        )
                        .matches(/^\S*$/, 'スペースは入力できません')
                        .required('R-Login パスワードが未入力です')
                    : schema.min(0, '0文字以上の英数字を入れてください');
                }),
              amazonSellerType: Yup.string().when('mall.amazon', {
                is: true,
                then: Yup.string().required('出品タイプが未選択です'),
                otherwise: Yup.string()
              })
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box sx={{ mb: 3, mt: 3, width: '100%', maxWidth: '650px' }}>
                    <Typography color="textPrimary" variant="h2">
                      Oxcim有料版［{ch_name}］トライアル利用申込
                    </Typography>
                    <Typography
                      color="textSecondary"
                      gutterBottom
                      variant="body2"
                      ml={1}
                    >
                      <br />
                      Oxcim【有料版トライアル】を利用申し込みする場合は下記よりお願いいたします。
                      <br />
                      トライアルプランの利用期間はデータ接続完了後1週間です。
                      <br />
                      データ接続完了時に弊社からご連絡します。
                      <br />
                      既にトライアルをご利用済みのモールでは再度トライアルをご利用頂く事はできません。
                    </Typography>
                  </Box>
                  <Box width="100%" maxWidth="600px">
                    <Box display="flex" alignItems="center">
                      <InputLabel>
                        <Box display="flex" alignItems="center" flexWrap="wrap">
                          <Typography
                            variant="body1"
                            sx={{ fontSize: { xs: '12px', sm: 'inherit' } }}
                          >
                            OxcimユーザーID（ご登録のメールアドレス）
                          </Typography>
                          <RequiredBox />
                          {touched.userId && errors.userId && (
                            <FormHelperText sx={{ m: 0, ml: 1 }} error>
                              {errors.userId}
                            </FormHelperText>
                          )}
                        </Box>
                      </InputLabel>
                    </Box>
                    <TextField
                      sx={{
                        mb: 1,
                        backgroundColor: '#fff',
                        maxWidth: '440px'
                      }}
                      error={Boolean(touched.userId && errors.userId)}
                      fullWidth
                      name="userId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.userId}
                      variant="outlined"
                      size="small"
                      autoComplete="new-user-id"
                      inputProps={{ autoComplete: 'off' }}
                    />
                    {channel == 'amazon' && (
                      <Box mt={3} mb={3}>
                        <Box display="flex" alignItems="center">
                          <InputLabel>
                            <Box
                              display="flex"
                              alignItems="center"
                              flexWrap="wrap"
                            >
                              <Typography
                                variant="body1"
                                sx={{ fontSize: { xs: '12px', sm: 'inherit' } }}
                              >
                                出品タイプ
                              </Typography>
                              <RequiredBox />
                              {touched.amazonSellerType &&
                                errors.amazonSellerType && (
                                  <FormHelperText sx={{ m: 0, ml: 1 }} error>
                                    {errors.amazonSellerType}
                                  </FormHelperText>
                                )}
                            </Box>
                          </InputLabel>
                        </Box>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="amazonSellerType"
                            name="amazonSellerType"
                            value={values.amazonSellerType}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          >
                            <Box display="flex" gap={4}>
                              <FormControlLabel
                                value="seller"
                                control={<Radio />}
                                label="セラーセントラル"
                              />
                              <FormControlLabel
                                value="vender"
                                control={<Radio />}
                                label="ベンダーセントラル"
                              />
                            </Box>
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    )}
                    {channel == 'rakuten' && (
                      <Box mt={3}>
                        <Box display="flex" alignItems="center">
                          <InputLabel>
                            <Box
                              display="flex"
                              alignItems="center"
                              flexWrap="wrap"
                            >
                              <Typography
                                variant="body1"
                                sx={{ fontSize: { xs: '12px', sm: 'inherit' } }}
                              >
                                R-Login ID
                              </Typography>
                              <RequiredBox />
                              {touched.rLoginId && errors.rLoginId && (
                                <FormHelperText sx={{ m: 0, ml: 1 }} error>
                                  {errors.rLoginId}
                                </FormHelperText>
                              )}
                            </Box>
                          </InputLabel>
                        </Box>
                        <TextField
                          sx={{ mb: 1, backgroundColor: '#fff', maxWidth: 440 }}
                          error={Boolean(touched.rLoginId && errors.rLoginId)}
                          fullWidth
                          name="rLoginId"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          value={values.rLoginId}
                          variant="outlined"
                          size="small"
                          autoComplete="new-user-id"
                          inputProps={{ autoComplete: 'off' }}
                        />
                        <Box display="flex" alignItems="center">
                          <InputLabel>
                            <Box
                              display="flex"
                              alignItems="center"
                              flexWrap="wrap"
                            >
                              <Typography
                                variant="body1"
                                sx={{ fontSize: { xs: '12px', sm: 'inherit' } }}
                              >
                                R-Login パスワード
                              </Typography>
                              <RequiredBox />
                              {touched.rLoginPass && errors.rLoginPass && (
                                <FormHelperText sx={{ m: 0, ml: 1 }} error>
                                  {errors.rLoginPass}
                                </FormHelperText>
                              )}
                            </Box>
                          </InputLabel>
                        </Box>
                        <TextField
                          sx={{ mb: 1, backgroundColor: '#fff', maxWidth: 440 }}
                          error={Boolean(
                            touched.rLoginPass && errors.rLoginPass
                          )}
                          fullWidth
                          name="rLoginPass"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          value={values.rLoginPass}
                          variant="outlined"
                          size="small"
                          autoComplete="new-user-id"
                          inputProps={{ autoComplete: 'off' }}
                        />
                        <Box py={2}>
                          <Typography fontSize="13px">注意事項</Typography>
                          <Typography>
                            申し込み時にR-Login
                            IDとパスワードの認証チェックを行います。
                            <br />
                            認証チェックに失敗した場合は「【Oxcim
                            から重要なお知らせ】楽天RMSのIDとパスワード更新のお願い」
                            <br />
                            という件名のメールが送信されます。
                            メールの内容に従いIDまたはパスワードの更新をお願いします。
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    <Box sx={{ py: 2 }} onSubmit={handleSubmit}>
                      <Button
                        color="primary"
                        disabled={isSubmitting || ch_name == ''}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        sx={{ mb: 1 }}
                      >
                        申し込む
                      </Button>
                      {error && <Alert severity="error">{errorMessage}</Alert>}
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
};

export default TrialRegister;
