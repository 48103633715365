/* eslint-disable*/
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { unwrapResult } from '@reduxjs/toolkit';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  IconButton,
  Box,
  Button,
  Link,
  Checkbox,
  Container,
  FormHelperText,
  TextField,
  Typography,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Modal,
  Card,
  CardContent
} from '@material-ui/core';
import { errorResMessage } from 'feactures/errorSlice';
import { fetchTrialPremiumRegister } from 'feactures/plan/premiumRegister';
import { userinfo } from 'feactures/user/userSlice';

const useStyles = makeStyles({
  modalComponent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-43%) translateY(-50%)'
  }
});

const TrialPremiumRegister = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const errorMessage = useSelector(errorResMessage);
  const user = useSelector(userinfo);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const [searchParams] = useSearchParams();
  const trial = searchParams.get('trial');

  const onSubmit = async (values) => {
    // console.log(values);
    values.campaign.trial = true;
    const payment_num = values.paymentNumber;
    const payment_method = values.paymentMethod;
    let navigate_url = '';
    if (values.startMonth == 'this_month') {
      navigate_url = `/trial_campaign_register/prev/complete?start=this&paynum=${payment_num}&method=${payment_method}`;
    } else if (values.startMonth == 'next_month') {
      navigate_url = `/trial_campaign_register/prev/complete?start=next&paynum=${payment_num}&method=${payment_method}`;
    }

    setError(null);
    await dispatch(fetchTrialPremiumRegister(values))
      .then(unwrapResult) //unwrapResult で,dispatch結果がfulfilledかrejectedかで条件分岐できる
      .then(() => {
        navigate(navigate_url, {
          replace: false
        });
      })
      .catch((err) => {
        setError(err.message);
        setOpen(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const RequiredBox = () => {
    return (
      <Box
        color="#FFFFFF"
        bgcolor="#F26B6B"
        borderRadius={5}
        fontSize="12px"
        fontWeight="bold"
        px="8px"
        mb="1px"
        ml="8px"
      >
        必須
      </Box>
    );
  };
  const OptionBox = () => {
    return (
      <Box
        color="#ffffff"
        bgcolor="#669AF5"
        borderRadius={5}
        fontSize="12px"
        fontWeight="bold"
        px="8px"
        mb="1px"
        ml="8px"
      >
        任意
      </Box>
    );
  };

  const ConfirmModal = ({ handleSubmit, isSubmitting, values }) => {
    const mall_count = values.ecMall.length;
    const price = 25000 * mall_count * 1.1;
    let mall_name = '';
    if (values.ecMall.includes('1') && values.ecMall.includes('2')) {
      mall_name = 'Amazon、楽天';
    } else if (values.ecMall.includes('1')) {
      mall_name = 'Amazon';
    } else if (values.ecMall.includes('2')) {
      mall_name = '楽天';
    }

    return (
      <Modal open={open} onClose={handleClose}>
        <Card
          className={classes.modalComponent}
          sx={{
            borderRadius: 6,
            py: 2,
            my: 2,
            border: '1px solid #A6A6A6',
            width: '45%'
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ mb: 2 }}>
                <Typography fontSize={'16px'} sx={{ mb: 2 }}>
                  料金と契約期間：1モールあたり&nbsp;金額25,000円/月(税抜)&nbsp;金額27,500円/月(税込)&nbsp;契約期間6ヶ月
                </Typography>
                <Typography fontSize={'16px'}>・モール：{mall_name}</Typography>
                <Typography fontSize={'16px'}>
                  ・開始月：
                  {values.startMonth == 'this_month'
                    ? '今すぐ利用開始'
                    : '来月1日～'}
                </Typography>
                <Typography fontSize={'16px'}>
                  ・決済方法：
                  {values.paymentMethod == '1' ? 'カード' : '銀行振込'}
                </Typography>
                <Typography fontSize={'16px'}>
                  ・支払回数：
                  {values.paymentNumber == '1' ? '一括払い' : '月払い'}
                </Typography>
                <Typography fontSize={'16px'}>
                  ・法人/個人：
                  {values.legalStatus == '1' ? '法人' : '個人'}
                </Typography>
              </Box>
              <Typography fontSize={'18px'}>
                金額：{price.toLocaleString()}円(税込)/月
              </Typography>
              <Typography fontSize={'16px'} sx={{ my: 2 }}>
                上記内容にお間違いがないかご確認いただき、お申し込みをお願いします。
              </Typography>

              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={handleSubmit}
              >
                お申し込み
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Modal>
    );
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {trial === 'rakuten' || trial === 'amazon' ? (
          <Container maxWidth="md">
            <Formik
              initialValues={{
                plan: {
                  amazon: trial === 'amazon' ? '1' : '',
                  rakuten: trial === 'rakuten' ? '1' : '',
                  yahoo: ''
                },
                ecMall:
                  trial === 'amazon' ? ['1'] : trial === 'rakuten' ? ['2'] : [],
                amazonPeriod: trial === 'amazon' ? '2' : '',
                rakutenPeriod: trial === 'rakuten' ? '2' : '',
                yahooPeriod: '',
                userId: user.userId,
                paymentMethod: '1',
                paymentNumber: '',
                corporateNumber: '',
                legalStatus: '',
                industry: '99',
                campaign: {
                  initialFee: false,
                  firstMonth: false,
                  midMonth: false
                },
                startMonth: ''
              }}
              validationSchema={Yup.object().shape({
                userId: Yup.string()
                  .max(255)
                  .required('ユーザーIDが未入力です'),
                paymentMethod:
                  Yup.string().required('決済方法を選択してください'),
                paymentNumber:
                  Yup.string().required('支払回数を選択してください'),
                legalStatus: Yup.string().required('選択してください'),
                startMonth: Yup.string().required('選択してください')
              })}
              onSubmit={onSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                isValid
              }) => (
                <>
                  <form onSubmit={handleSubmit}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Box sx={{ mb: 3, mt: 3, width: 600 }}>
                        <Typography color="textPrimary" variant="h2">
                          {trial === 'rakuten' &&
                            'Oxcim有料版［楽天］利用申し込み'}
                          {trial === 'amazon' &&
                            'Oxcim有料版［Amazon］利用申し込み'}
                        </Typography>
                      </Box>
                      <Box width="600px">
                        <Box display="flex" alignItems="center">
                          <InputLabel
                            sx={{ fontSize: '18px', fontWeight: 'bold', mr: 2 }}
                          >
                            申し込み希望プラン：基本活用プラン
                          </InputLabel>
                          <InputLabel
                            sx={{ fontSize: '18px', fontWeight: 'bold' }}
                          >
                            契約期間：6か月
                          </InputLabel>
                        </Box>
                        <InputLabel sx={{ fontSize: '14px' }}>
                          ※マーケットビューも制限なくご利用頂けるプランになります
                        </InputLabel>
                        <Box sx={{ mt: 1 }}>
                          <InputLabel
                            sx={{ fontSize: '18px', fontWeight: 'bold' }}
                          >
                            金額：2.5万(税抜)/月
                          </InputLabel>
                        </Box>
                        <Box sx={{ mt: 3 }}>
                          <InputLabel>
                            <Box display="flex" alignItems="center">
                              開始月
                              <RequiredBox />
                              {errors.startMonth && (
                                <FormHelperText sx={{ m: 0, ml: 1 }} error>
                                  {errors.startMonth}
                                </FormHelperText>
                              )}
                            </Box>
                            <Typography variant="h5" mt={1}>
                              ※月の途中で申し込みされた場合でも当月分の月額料金が発生いたします
                            </Typography>
                            <Typography
                              variant="h5"
                              mt={1}
                              sx={{ fontWeight: 'bold', color: 'red' }}
                            >
                              ※来月を選択した場合はトライアル終了後から翌月1日までは利用できません
                            </Typography>
                          </InputLabel>
                          <FormControl component="fieldset">
                            <RadioGroup
                              row
                              name="startMonth"
                              value={values.startMonth}
                              onChange={handleChange}
                              error={Boolean(errors.startMonth)}
                            >
                              <FormControlLabel
                                value="this_month"
                                control={<Radio />}
                                label="今すぐ利用開始"
                              />
                              <FormControlLabel
                                value="next_month"
                                control={<Radio />}
                                label="来月1日～"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                        <Box display="flex" alignItems="center" mt={2}>
                          <FormLabel component="legend">
                            <Box display="flex" alignItems="center">
                              決済方法
                              <RequiredBox />
                              {touched.paymentMethod &&
                                errors.paymentMethod && (
                                  <FormHelperText sx={{ m: 0, ml: 1 }} error>
                                    {errors.paymentMethod}
                                  </FormHelperText>
                                )}
                            </Box>
                          </FormLabel>
                        </Box>
                        <FormControl component="fieldset">
                          <RadioGroup
                            row
                            name="paymentMethod"
                            value={values.paymentMethod}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label="カード"
                            />
                            <FormControlLabel
                              value="2"
                              control={<Radio />}
                              label="銀行振込"
                            />
                          </RadioGroup>
                        </FormControl>
                        <Typography variant="h5">
                          ※カードのほうが早く開始できます
                        </Typography>
                        <Typography variant="h5">
                          ※銀行振込をご希望の場合は弊社からご連絡します
                        </Typography>
                        <Box display="flex" alignItems="center" mt={2}>
                          <FormLabel component="legend">
                            <Box display="flex" alignItems="center">
                              支払回数
                              <RequiredBox />
                              {touched.paymentMethod &&
                                errors.paymentMethod && (
                                  <FormHelperText sx={{ m: 0, ml: 1 }} error>
                                    {errors.paymentMethod}
                                  </FormHelperText>
                                )}
                            </Box>
                          </FormLabel>
                        </Box>
                        <FormControl component="fieldset">
                          <RadioGroup
                            row
                            name="paymentNumber"
                            value={values.paymentNumber}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label="一括払い"
                            />
                            <FormControlLabel
                              value="2"
                              control={<Radio />}
                              label="月払い"
                            />
                          </RadioGroup>
                        </FormControl>
                        <Box display="flex" alignItems="center" mt={1}>
                          <InputLabel>
                            <Box display="flex" alignItems="center">
                              法人/個人
                              <RequiredBox />
                              {touched.corporateNumber &&
                                errors.corporateNumber && (
                                  <FormHelperText sx={{ m: 0, ml: 1 }} error>
                                    {errors.corporateNumber}
                                  </FormHelperText>
                                )}
                            </Box>
                          </InputLabel>

                          {touched.legalStatus && errors.legalStatus && (
                            <FormHelperText sx={{ m: 0, ml: 1 }} error>
                              {errors.legalStatus}
                            </FormHelperText>
                          )}
                        </Box>
                        <FormControl component="fieldset">
                          <RadioGroup
                            row
                            name="legalStatus"
                            value={values.legalStatus}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value="1"
                              control={<Radio />}
                              label="法人"
                            />
                            <FormControlLabel
                              value="2"
                              control={<Radio />}
                              label="個人"
                            />
                          </RadioGroup>
                        </FormControl>
                        {user.isLoggedIn ? (
                          <></>
                        ) : (
                          <>
                            <Box
                              display="flex"
                              alignItems="center"
                              sx={{ mt: 1 }}
                            >
                              <InputLabel>
                                <Box display="flex" alignItems="center">
                                  OxcimユーザーID（ご登録のメールアドレス）
                                  <RequiredBox />
                                  {touched.userId && errors.userId && (
                                    <FormHelperText sx={{ m: 0, ml: 1 }} error>
                                      {errors.userId}
                                    </FormHelperText>
                                  )}
                                </Box>
                              </InputLabel>
                            </Box>
                            <TextField
                              sx={{
                                mb: 1,
                                backgroundColor: '#fff',
                                width: 440
                              }}
                              error={Boolean(touched.userId && errors.userId)}
                              fullWidth
                              //   helperText={touched.userId && errors.userId}
                              name="userId"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="email"
                              value={values.userId}
                              variant="outlined"
                              size="small"
                              autoComplete="new-user-id"
                              inputProps={{ autoComplete: 'off' }}
                            />
                          </>
                        )}

                        <Box sx={{ py: 2 }}>
                          <Button
                            color="primary"
                            fullWidth
                            size="large"
                            variant="contained"
                            sx={{ mb: 1 }}
                            onClick={() => {
                              setOpen(true);
                            }}
                            disabled={
                              isSubmitting ||
                              values.userId === '' ||
                              values.ecMall.length == 0 ||
                              values.paymentNumber == '' ||
                              values.paymentMethod == '' ||
                              values.legalStatus == '' ||
                              values.startMonth == ''
                            }
                          >
                            申し込み内容確認
                          </Button>
                          {error && (
                            <Alert severity="error">{errorMessage}</Alert>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </form>
                  <ConfirmModal
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                    values={values}
                  />
                </>
              )}
            </Formik>
          </Container>
        ) : (
          <Box
            sx={{
              my: 5
            }}
          >
            <Typography variant="h5" mb={3} fontSize={'14px'}>
              パラメータが不正です。ログイン画面に戻ってやり直してください。
            </Typography>
            <Typography
              variant="h5"
              mb={3}
              textAlign={'center'}
              fontSize={'14px'}
            >
              <Link
                component={RouterLink}
                to="/login"
                variant="h5"
                underline="hover"
                fontSize={'14px'}
              >
                ログイン画面
              </Link>
              に戻る
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default TrialPremiumRegister;
