/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index';
import { apiUrl } from 'App';

export const fetchSearchAsin = createAsyncThunk(
  'searchAsin/post',
  async (arg, thunkAPI) => {
    const { asin } = arg;
    const { accountId } = thunkAPI.getState().user;
    const { channel } = thunkAPI.getState().currentchannel;
    const res = await client.post(`${apiUrl}searchAsin`, {
      asin: asin,
      account_id: accountId,
      channel: channel
    });
    return res.data;
  }
);

const searchAsin = createSlice({
  name: 'searchAsins',
  initialState: {
    asin: '',
    title: '',
    price: ''
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSearchAsin.fulfilled, (state, action) => {
      return {
        asin: action.payload.asin,
        price: action.payload.price,
        isLoading: false,
        error: false
      };
    });
    builder.addCase(fetchSearchAsin.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        error: false
      };
    });
    builder.addCase(fetchSearchAsin.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: true
      };
    });
  }
});

export default searchAsin.reducer;
