/* eslint-disable*/
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { useEffect, useState, memo, useRef } from 'react';
import { Box, Typography, Link, Container } from '@material-ui/core'; // eslint-disable-line
import { useDispatch, useSelector } from 'react-redux';
import { confirmCheckoutSession } from 'feactures/plan/planSlice';

const CheckoutComplete = memo(() => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const success = searchParams.get('success');
  const cancel = searchParams.get('canceled');
  const session_id = searchParams.get('session_id');

  if (success) {
    try {
      dispatch(
        confirmCheckoutSession({
          session_id: session_id
        })
      );
    } catch (error) {
      console.error('stripe_sessionが確認できませんでした', error);
    }
  }

  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bgColor: '#F7FCFF'
      }}
    >
      <Box textAlign="center" pt={5} my={10}>
        {success ? (
          <>
            <Typography variant="h1" fontWeight={'bold'} sx={{ my: 2 }}>
              決済が完了いたしました。
            </Typography>
            <Typography variant="h4" mb={3}>
              <Link
                component={RouterLink}
                to="/login"
                variant="h4"
                underline="hover"
              >
                こちら
              </Link>
              をクリックして再度ログインを行ってください。
            </Typography>
          </>
        ) : (
          <></>
        )}
        {cancel ? (
          <>
            <Typography variant="h1" fontWeight={'bold'} sx={{ my: 2 }}>
              決済がキャンセルされました。
            </Typography>
            <Typography variant="h4" mb={3}>
              恐れ入りますが、
              <Link
                component={RouterLink}
                to="/top"
                variant="h4"
                underline="hover"
              >
                こちら
              </Link>
              をクリックして再度申込みを行ってください。
            </Typography>
          </>
        ) : (
          <></>
        )}
      </Box>
    </Container>
  );
});

export default CheckoutComplete;
