/* eslint-disable */
// KPIサマリの商品別売上表示、指定商品登録・編集関連のslice
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const fetchAsyncMonitorAsin = createAsyncThunk(
  'monitorAsin/post',
  async (arg, thunkAPI) => {
    const { pageNum } = arg;
    const { userId } = thunkAPI.getState().user;
    const { term, start, end } = await thunkAPI.getState().date;
    const { channel } = thunkAPI.getState().currentchannel;
    const { displayObject } = thunkAPI.getState().monitoringAsin;
    const { existingASIN } = thunkAPI.getState().monitoringAsin;
    const { perPage } = thunkAPI.getState().monitoringAsin;

    if (channel == 0) return;

    const res = await client.post(`${apiUrl}salesByAsin`, {
      start: start,
      end: end,
      term: term,
      channel: channel,
      user_id: userId,
      page: pageNum,
      display_object: displayObject,
      existing_asin: existingASIN,
      per_page: perPage
    });
    return res.data;
  }
);

export const fetchAsinList = createAsyncThunk(
  'getAsinList/post',
  async (arg, thunkAPI) => {
    const { userId, accountId } = thunkAPI.getState().user;
    const { channel } = thunkAPI.getState().currentchannel;

    if (channel == 0) return;

    const res = await client.post(`${apiUrl}getAsinList`, {
      channel: channel,
      account_id: accountId
    });
    return res.data;
  }
);

export const fetchEditMonitorAsinList = createAsyncThunk(
  'editMonitorAsinList/post',
  async (arg, thunkAPI) => {
    const { asinList } = arg;
    const { userId, accountId } = thunkAPI.getState().user;
    const { channel } = thunkAPI.getState().currentchannel;

    if (channel == 0) return;

    const res = await client.post(`${apiUrl}editMonitorAsinList`, {
      channel: channel,
      // user_id: userId,
      account_id: accountId,
      asin_list: asinList
    });
    return res.data;
  }
);

const initialstate = {
  asins: []
};

const monitoringAsin = createSlice({
  name: 'monitorAsins',
  initialState: {
    amazon: initialstate,
    rakuten: initialstate,
    yahoo: initialstate,
    existingASIN: [],
    registeredASIN: [],
    canceledASIN: [],
    displayObject: 'top10',
    perPage: 10
  },
  reducers: {
    resetMonitorAsin(state, action) {
      return {
        ...state,
        amazon: initialstate,
        rakuten: initialstate,
        yahoo: initialstate,
        existingASIN: [],
        registeredASIN: [],
        canceledASIN: [],
        displayObject: 'top10',
        isLoading: false,
        error: false
      };
    },
    resetDisplayedASIN(state) {
      return {
        ...state,
        registeredASIN: [],
        canceledASIN: []
      };
    },
    setDisplayObject(state, action) {
      return {
        ...state,
        displayObject: action.payload,
        isLoading: false,
        error: false
      };
    },
    setPerPage(state, action) {
      return {
        ...state,
        perPage: action.payload,
        isLoading: false,
        error: false
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAsyncMonitorAsin.fulfilled, (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          isLoading: false,
          error: false
        };
      }
      if (action.payload.channel == 'amazon') {
        return {
          amazon: {
            asins: action.payload.asin
          },
          rakuten: { ...state.rakuten },
          yahoo: { ...state.yahoo },
          existingASIN: [...state.existingASIN],
          registeredASIN: [...state.registeredASIN],
          canceledASIN: [...state.canceledASIN],
          displayObject: state.displayObject,
          perPage: state.perPage,
          isLoading: false,
          error: false
        };
      }
      if (action.payload.channel == 'rakuten') {
        return {
          rakuten: {
            asins: action.payload.asin
          },
          amazon: { ...state.amazon },
          yahoo: { ...state.yahoo },
          existingASIN: [...state.existingASIN],
          registeredASIN: [...state.registeredASIN],
          canceledASIN: [...state.canceledASIN],
          displayObject: state.displayObject,
          perPage: state.perPage,
          isLoading: false,
          error: false
        };
      }
      if (action.payload.channel == 'yahoo') {
        return {
          yahoo: {
            asins: action.payload.asin
          },
          amazon: { ...state.amazon },
          rakuten: { ...state.rakuten },
          existingASIN: [...state.existingASIN],
          registeredASIN: [...state.registeredASIN],
          canceledASIN: [...state.canceledASIN],
          displayObject: state.displayObject,
          perPage: state.perPage,
          isLoading: false,
          error: false
        };
      }
    });
    builder.addCase(fetchAsyncMonitorAsin.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        error: false
      };
    });
    builder.addCase(fetchAsyncMonitorAsin.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: true
      };
    });
    builder.addCase(fetchEditMonitorAsinList.fulfilled, (state, action) => {
      return {
        ...state,
        registeredASIN: action.payload.registered_asin,
        canceledASIN: action.payload.canceled_asin,
        existingASIN: action.payload.existing_asin,
        isLoading: false,
        error: false
      };
    });
    builder.addCase(fetchEditMonitorAsinList.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
        error: false
      };
    });
    builder.addCase(fetchEditMonitorAsinList.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: true
      };
    });
    builder.addCase(fetchAsinList.fulfilled, (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          error: false
        };
      } else {
        return {
          ...state,
          existingASIN: action.payload.existing_asin,
          error: false
        };
      }
    });
    builder.addCase(fetchAsinList.pending, (state, action) => {
      return {
        ...state,
        error: false
      };
    });
    builder.addCase(fetchAsinList.rejected, (state, action) => {
      return {
        ...state,
        error: true
      };
    });
  }
});

export const a_monitor_Asins = (state) => state.monitoringAsin.amazon.asins;
export const r_monitor_Asins = (state) => state.monitoringAsin.rakuten.asins;
export const y_monitor_Asins = (state) => state.monitoringAsin.yahoo.asins;
export const monitor_asinStatus = (state) => state.monitoringAsin.isLoading;
// 指定商品に登録されてるASIN
export const existing_asin = (state) => state.monitoringAsin.existingASIN;
// 商品登録できたASIN
export const registered_asin = (state) => state.monitoringAsin.registeredASIN;
// 商品登録できなかったasin
export const canceled_asin = (state) => state.monitoringAsin.canceledASIN;
export const asin_error = (state) => state.monitoringAsin.error;
// 売上上位か指定商品か
export const display_object = (state) => state.monitoringAsin.displayObject;
// 表示件数
export const asin_perPage = (state) => state.monitoringAsin.perPage;

export const {
  resetMonitorAsin,
  resetDisplayedASIN,
  setDisplayObject,
  setDisplayMoitorAsin,
  setPerPage
} = monitoringAsin.actions;

export default monitoringAsin.reducer;
