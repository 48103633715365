/* eslint-disable */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client from 'index.js';
import { apiUrl } from 'App.js';

export const registerProducts = createAsyncThunk(
  'product_mark/post',
  async (arg, thunkAPI) => {
    const { asin_code } = arg;
    const { userId, accountId, accountName, plan } = thunkAPI.getState().user;
    const { channel, channelName } = thunkAPI.getState().currentchannel;
    const { section } = thunkAPI.getState().currentsection;

    const res = await client.post(`${apiUrl}product_mark`, {
      use_id: userId,
      account_id: accountId,
      asin_code: asin_code,
      channel: channel,
      channel_name: channelName,
      section: section,
      user_plan: plan
    });
    return res.data;
  }
);

const registerProductSlice = createSlice({
  name: 'registerProducts',
  initialState: {
    message: '',
    isLoading: false
  },
  extraReducers: (builder) => {
    builder.addCase(registerProducts.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(registerProducts.pending, (state, action) => {
      return {
        ...state,
        isLoading: true
      };
    });
    builder.addCase(registerProducts.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false
      };
    });
  }
});

export const { addDeleteItem } = registerProductSlice.actions;
export const registerMessage = (state) => state.registerProducts.message;
export const registerStatus = (state) => state.registerProducts.isLoading;

export default registerProductSlice.reducer;
