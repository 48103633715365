/* eslint-disable */
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@material-ui/styles/makeStyles';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import {
  selectMonitoringAsin,
  selectMonitoringGroup
} from 'feactures/monitoring/monitoringSlice';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Box,
  Button,
  Typography,
  CircularProgress,
  Paper,
  InputBase
} from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { currentchannel, changeChannel } from 'feactures/channel/channelSlice';
import { groups } from 'feactures/monitoring/groupSlice';
import {
  fetchAsyncAsinSales,
  ovAllMallAsinList,
  ovAmazonAsinList,
  ovAsinLoading,
  ovAsinOrder,
  ovAsinOrderby,
  ovAsinPage,
  ovAsinPerPage,
  ovAsinlen,
  ovAsinSearchword,
  ovRakutenAsinList,
  ovYahoonAsinList,
  setOvAsinSalesDataPage,
  setOvAsinSalesDataPerOrder,
  setOvAsinSalesDataPerPage,
  setOvAsinSalesDataPerOrderby,
  setOvAsinSalesDataSearchword,
  resetAsinPage
} from 'feactures/overview/overviewSlice';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: expand == 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

const ItemSalesList = ({ pathName }) => {
  const allMallAsinList = useSelector(ovAllMallAsinList);
  const amazonAsinList = useSelector(ovAmazonAsinList);
  const rakutenAsinList = useSelector(ovRakutenAsinList);
  const yahooAsinList = useSelector(ovYahoonAsinList);
  const asinListLength = useSelector(ovAsinlen);
  const channel = useSelector(currentchannel);
  const orderDesc = useSelector(ovAsinOrder);
  const checkedColumn = useSelector(ovAsinOrderby);
  const page = useSelector(ovAsinPage);
  const rowsPerPage = useSelector(ovAsinPerPage);
  const isLoading = useSelector(ovAsinLoading);
  const groupList = useSelector(groups);
  const searchword = useSelector(ovAsinSearchword);

  let items = [];
  if (channel == 0) {
    items = allMallAsinList;
  } else if (channel == 1) {
    items = amazonAsinList;
  } else if (channel == 2) {
    items = rakutenAsinList;
  } else if (channel == 3) {
    items = yahooAsinList;
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // ページとモール切り替えのタイミングで初期化
  // useEffect(() => {
  //   dispatch(setOvAsinSalesDataSearchword(''));
  //   dispatch(resetAsinPage());
  //   dispatch(setOvAsinSalesDataPerPage(10));
  //   dispatch(fetchAsyncAsinSales({ searchword: searchword }));
  // }, [pathName, channel]);

  const handleChange = (column) => {
    if (column === checkedColumn && orderDesc === 'desc') {
      //最後に並び替えたカラムと同じカラムで、現在の並び順が降順なので昇順に並び替える
      dispatch(setOvAsinSalesDataPerOrder('asc'));
      dispatch(fetchAsyncAsinSales({ searchword: searchword }));
    } else if (column === checkedColumn && orderDesc === 'asc') {
      //最後に並び替えたカラムと同じカラムで、現在の並び順が昇順なので降順に並び替える
      dispatch(setOvAsinSalesDataPerOrder('desc'));
      dispatch(fetchAsyncAsinSales({ searchword: searchword }));
    } else {
      // 新しいカラムを降順に並び替える
      dispatch(setOvAsinSalesDataPerOrderby(column));
      dispatch(setOvAsinSalesDataPerOrder('desc'));
      dispatch(fetchAsyncAsinSales({ searchword: searchword }));
    }
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setOvAsinSalesDataPage(newPage));
    dispatch(fetchAsyncAsinSales({ searchword: searchword }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setOvAsinSalesDataPerPage(event.target.value));
    dispatch(fetchAsyncAsinSales({ searchword: searchword }));
  };

  const handleChangeWord = (e) => {
    dispatch(setOvAsinSalesDataSearchword(e.target.value));
  };

  const handleSearch = () => {
    dispatch(resetAsinPage());
    dispatch(fetchAsyncAsinSales({ searchword: searchword }));
  };

  const asinNameClick = (asin, name, channel, group) => {
    // グループ別KPIサマリ以外のページからASIN別KPIサマリに遷移する際は、
    // そのASINがグループに属していればグループ情報をセットし、属していなければグループを選択していない状態にする
    if (pathName !== 'monitoringGroup') {
      if (group === 'no-group') {
        dispatch(
          selectMonitoringGroup({
            name: '',
            id: ''
          })
        );
      } else {
        dispatch(
          selectMonitoringGroup({
            name: group.name,
            id: group.id
          })
        );
      }
    }

    if (channel == 'Amazon') {
      dispatch(
        selectMonitoringAsin({
          code: asin,
          name: name,
          channel: 1
        })
      );
    } else if (channel == '楽天') {
      dispatch(
        selectMonitoringAsin({
          code: asin,
          name: name,
          channel: 2
        })
      );
    } else if (channel == 'Yahoo') {
      dispatch(
        selectMonitoringAsin({
          code: asin,
          name: name,
          channel: 3
        })
      );
    }

    navigate('/app/monitoring/asin');
  };

  const tableStyles = makeStyles({
    tableHead: {
      height: 44
    },
    container: {
      border: 'none'
    }
  });

  const classes = tableStyles();

  const RateCell = (props) => {
    const { rateNum } = props;
    return (
      <TableCell
        align="center"
        sx={{
          color: rateNum > 0 ? '#4C34FF' : rateNum == 0 ? '#000000' : '#FF1C17',
          alignItems: 'center',
          fontWeight: 'bold'
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="center">
          {/* {rateNum > 0 && <TrendingUpIcon />}
          {rateNum == 0 && <TrendingFlatIcon />}
          {rateNum < 0 && <TrendingDownIcon />} */}
          {rateNum > 0 && '↑'}
          {rateNum == 0 && '±'}
          {rateNum < 0 && '↓'}
          {Math.abs(rateNum).toFixed(1)}%
        </Box>
      </TableCell>
    );
  };

  return (
    <>
      <Box display="flex" alignItems="center" ml={1} mb={1}>
        <Typography fontWeight="bold" fontSize="14px" mr={2}>
          商品売上
        </Typography>
        <Paper
          component="div"
          sx={{
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            width: 300,
            height: '30px'
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="検索"
            value={searchword}
            onChange={(e) => {
              handleChangeWord(e);
            }}
          />
          <IconButton
            type="button"
            sx={{ p: '5px', mr: 1 }}
            onClick={() => {
              handleSearch();
            }}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <TableContainer className={classes.container}>
              <Table
                size="small"
                aria-label="a dense table"
                className={classes.container}
                sx={{ border: 'none' }}
              >
                <TableHead sx={{ maxHeight: '38px' }}>
                  <TableRow sx={{ bgcolor: '#D9D9D9' }}>
                    <TableCell
                      align="center"
                      sx={{ minWidth: '75px', fontWeight: 'bold' }}
                    >
                      モール
                    </TableCell>
                    {groupList.length > 0 && (
                      <TableCell
                        align="center"
                        sx={{ minWidth: '100px', fontWeight: 'bold' }}
                      >
                        グループ
                      </TableCell>
                    )}
                    <TableCell
                      align="center"
                      sx={{ minWidth: '100px', fontWeight: 'bold' }}
                    >
                      商品コード
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                      商品名
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ minWidth: '120px', padding: 0, fontWeight: 'bold' }}
                    >
                      <Button
                        onClick={() => {
                          handleChange('sales');
                        }}
                        color="inherit"
                        sx={{
                          padding: 0,
                          height: '32.5px',
                          width: '100%',
                          fontWeight: 'bold'
                        }}
                      >
                        全体売上
                        {checkedColumn !== 'sales' && (
                          <Box width="20.5px" height="20.5px"></Box>
                        )}
                        {checkedColumn === 'sales' && (
                          <ExpandMore
                            expand={orderDesc}
                            aria-expanded={orderDesc}
                            aria-label="sort"
                            sx={{ padding: 0 }}
                          >
                            <ExpandMoreIcon />
                          </ExpandMore>
                        )}
                      </Button>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ minWidth: '120px', padding: 0, fontWeight: 'bold' }}
                    >
                      前期比
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ minWidth: '120px', padding: 0, fontWeight: 'bold' }}
                    >
                      <Button
                        onClick={() => {
                          handleChange('unit');
                        }}
                        color="inherit"
                        sx={{
                          padding: 0,
                          height: '32.5px',
                          width: '100%',
                          fontWeight: 'bold'
                        }}
                      >
                        {checkedColumn !== 'unit' && (
                          <Box width="20.5px" height="20.5px"></Box>
                        )}
                        販売個数
                        {checkedColumn === 'unit' && (
                          <ExpandMore
                            expand={orderDesc}
                            aria-expanded={orderDesc}
                            aria-label="sort"
                            sx={{ padding: 0 }}
                          >
                            <ExpandMoreIcon />
                          </ExpandMore>
                        )}
                      </Button>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ minWidth: '120px', padding: 0, fontWeight: 'bold' }}
                    >
                      前期比
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ border: 'none' }}>
                  {items.map((row, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          '&:nth-of-type(even)': { bgcolor: '#E3E8F4' },
                          height: 100
                        }}
                        className={classes.tableHead}
                      >
                        <TableCell align="center">{row.channel}</TableCell>
                        {groupList.length > 0 && (
                          <>
                            {row.group.length > 0 ? (
                              <TableCell align="center">
                                {row.group.map((group, index) => (
                                  <Typography key={index}>
                                    {group === '-' ? group : group.name}
                                  </Typography>
                                ))}
                              </TableCell>
                            ) : (
                              <TableCell align="center">-</TableCell>
                            )}
                          </>
                        )}
                        <TableCell align="center">{row.asin}</TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              px: 0
                            }}
                          >
                            <Typography sx={{ textAlign: 'left' }}>
                              {row.name}
                            </Typography>
                            <IconButton
                              onClick={() => {
                                if (
                                  row.group.length > 0 &&
                                  row.group[0] !== '-'
                                ) {
                                  asinNameClick(
                                    row.asin,
                                    row.name,
                                    row.channel,
                                    row.group[0]
                                  );
                                } else {
                                  asinNameClick(
                                    row.asin,
                                    row.name,
                                    row.channel,
                                    'no-group'
                                  );
                                }
                              }}
                            >
                              <OpenInNewIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {row.sales == 0
                            ? '-'
                            : '¥' + row.sales.toLocaleString()}
                        </TableCell>
                        <RateCell rateNum={row.sales_rate} />
                        <TableCell align="center">
                          {row.unit == 0 ? '-' : row.unit.toLocaleString()}
                        </TableCell>
                        <RateCell rateNum={row.unit_rate} />
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        {!isLoading & (items.length == 0) ? (
          <Box pt={1}>
            <Typography>データがありません</Typography>
          </Box>
        ) : (
          <></>
        )}
      </Box>
      {!isLoading && (
        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 25, 100]}
          count={asinListLength}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

export default ItemSalesList;
