/* eslint-disable */
import { makeStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { setDetailOpen } from 'feactures/measure/measureSlice';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
  table: {
    minWidth: 350
  }
});

const OtherItemMeasureTableAmazon = (props) => {
  const { sales, yokoziku, label } = props; // eslint-disable-line
  const classes = useStyles();
  const dispatch = useDispatch();
  // const sales = [
  //   5678, 5432, 8765, 2109, 4321, 7890, 1234, 9012, 3456, 2345, 6789, 4567
  // ];
  const getMaxSales = Math.max(...sales);
  const getMinSales = Math.min(...sales);
  const sortedSales = [...sales].sort((a, b) => a - b);
  const getMedianSales = sortedSales[Math.floor(sortedSales.length / 2)];
  const cartGot = [7, 23, 45, 12, 34, 56, 89, 3, 72, 18, 91, 5];
  const getMaxCartGot = Math.max(...cartGot);
  const getMinCartGot = Math.min(...cartGot);
  const sortedCartGot = [...cartGot].sort((a, b) => a - b);
  const getMedianCartGot = sortedCartGot[Math.floor(sortedCartGot.length / 2)];
  const sponsorProduct = [14, 68, 27, 40, 11, 63, 92, 20, 33, 6, 88, 2];
  const getMaxSponsorProduct = Math.max(...sponsorProduct);
  const getMinSponsorProduct = Math.min(...sponsorProduct);
  const sortedSponsorProduct = [...sponsorProduct].sort((a, b) => a - b);
  const getMedianSponsorProduct =
    sortedSponsorProduct[Math.floor(sortedSponsorProduct.length / 2)];
  const sponsorBrand = [30, 8, 57, 19, 75, 46, 10, 82, 64, 13, 53, 1];
  const getMaxSponsorBrand = Math.max(...sponsorBrand);
  const getMinSponsorBrand = Math.min(...sponsorBrand);
  const sortedSposorBrand = [...sponsorBrand].sort((a, b) => a - b);
  const getMedianSponsorBrand =
    sortedSposorBrand[Math.floor(sortedSposorBrand.length / 2)];
  const sale = [83, 26, 61, 28, 71, 17, 42, 50, 35, 76, 32, 59];
  const getMaxSale = Math.max(...sale);
  const getMinSale = Math.min(...sale);
  const sortedSale = [...sale].sort((a, b) => a - b);
  const getMedianSale = sortedSale[Math.floor(sortedSale.length / 2)];
  const point = [41, 87, 25, 60, 38, 77, 9, 15, 70, 4, 29, 78];
  const getMaxPoint = Math.max(...point);
  const getMinPoint = Math.min(...point);
  const sortedPoint = [...point].sort((a, b) => a - b);
  const getMedianPoint = sortedPoint[Math.floor(sortedPoint.length / 2)];

  const columnList = [
    '売上',
    'カート取得',
    'スポンサープロダクト',
    'スポンサーブランド',
    'セール',
    'ポイント'
  ];

  const handleClick = (measure, i) => {
    const term = yokoziku[i];
    dispatch(setDetailOpen({ section: '他社', detail: measure, term }));
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{ color: '#666666' }}>
              {label}
            </TableCell>
            {yokoziku.map((term) => (
              <TableCell align="center" sx={{ color: '#666666' }} key={term}>
                {term.split('/')[1]}月
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key={columnList[0]}>
            <TableCell
              align="center"
              component="th"
              scope="row"
              sx={{ color: '#666666' }}
            >
              {columnList[0]}
            </TableCell>
            {sales.map((element, index) => (
              <TableCell
                key={index}
                align="center"
                component="th"
                scope="row"
                sx={{
                  backgroundColor:
                    element === 0
                      ? '#FFFFFF'
                      : element === getMaxSales
                      ? '#FF8C00'
                      : element === getMedianSales
                      ? '#FFA500'
                      : element === getMinSales
                      ? '#FFD700'
                      : '#FFFFFF',
                  color:
                    element === 0
                      ? '#666666'
                      : element === getMaxSales
                      ? '#FFFFFF'
                      : element === getMedianSales
                      ? '#FFFFFF'
                      : element === getMinSales
                      ? '#FFFFFF'
                      : '#666666'
                }}
              >
                {element.toLocaleString()}
              </TableCell>
            ))}
          </TableRow>
          <TableRow key={columnList[1]}>
            <TableCell
              align="center"
              component="th"
              scope="row"
              sx={{ color: '#666666' }}
            >
              {columnList[1]}
            </TableCell>
            {cartGot.map((element, index) => (
              <TableCell
                key={index}
                align="center"
                component="th"
                scope="row"
                onClick={() => handleClick('カート取得', index)}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    opacity: 0.8
                  },
                  backgroundColor:
                    element === 0
                      ? '#FFFFFF'
                      : element === getMaxCartGot
                      ? '#FF8C00'
                      : element === getMedianCartGot
                      ? '#FFA500'
                      : element === getMinCartGot
                      ? '#FFD700'
                      : '#FFFFFF',
                  color:
                    element === 0
                      ? '#666666'
                      : element === getMaxCartGot
                      ? '#FFFFFF'
                      : element === getMedianCartGot
                      ? '#FFFFFF'
                      : element === getMinCartGot
                      ? '#FFFFFF'
                      : '#666666'
                }}
              >
                {element.toLocaleString()}
              </TableCell>
            ))}
          </TableRow>
          <TableRow key={columnList[2]}>
            <TableCell
              align="center"
              component="th"
              scope="row"
              sx={{ color: '#666666' }}
            >
              {columnList[2]}
            </TableCell>
            {sponsorProduct.map((element, index) => (
              <TableCell
                key={index}
                align="center"
                component="th"
                scope="row"
                onClick={() => handleClick('スポンサープロダクト', index)}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    opacity: 0.8
                  },
                  backgroundColor:
                    element === 0
                      ? '#FFFFFF'
                      : element === getMaxSponsorProduct
                      ? '#FF8C00'
                      : element === getMedianSponsorProduct
                      ? '#FFA500'
                      : element === getMinSponsorProduct
                      ? '#FFD700'
                      : '#FFFFFF',
                  color:
                    element === 0
                      ? '#666666'
                      : element === getMaxSponsorProduct
                      ? '#FFFFFF'
                      : element === getMedianSponsorProduct
                      ? '#FFFFFF'
                      : element === getMinSponsorProduct
                      ? '#FFFFFF'
                      : '#666666'
                }}
              >
                {element.toLocaleString()}
              </TableCell>
            ))}
          </TableRow>
          <TableRow key={columnList[3]}>
            <TableCell
              align="center"
              component="th"
              scope="row"
              sx={{ color: '#666666' }}
            >
              {columnList[3]}
            </TableCell>
            {sponsorBrand.map((element, index) => (
              <TableCell
                key={index}
                align="center"
                component="th"
                scope="row"
                onClick={() => handleClick('スポンサーブランド', index)}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    opacity: 0.8
                  },
                  backgroundColor:
                    element === 0
                      ? '#FFFFFF'
                      : element === getMaxSponsorBrand
                      ? '#FF8C00'
                      : element === getMedianSponsorBrand
                      ? '#FFA500'
                      : element === getMinSponsorBrand
                      ? '#FFD700'
                      : '#FFFFFF',
                  color:
                    element === 0
                      ? '#666666'
                      : element === getMaxSponsorBrand
                      ? '#FFFFFF'
                      : element === getMedianSponsorBrand
                      ? '#FFFFFF'
                      : element === getMinSponsorBrand
                      ? '#FFFFFF'
                      : '#666666'
                }}
              >
                {element.toLocaleString()}
              </TableCell>
            ))}
          </TableRow>
          <TableRow key={columnList[4]}>
            <TableCell
              align="center"
              component="th"
              scope="row"
              sx={{ color: '#666666' }}
            >
              {columnList[4]}
            </TableCell>
            {sale.map((element, index) => (
              <TableCell
                key={index}
                align="center"
                component="th"
                scope="row"
                onClick={() => handleClick('セール', index)}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    opacity: 0.8
                  },
                  backgroundColor:
                    element === 0
                      ? '#FFFFFF'
                      : element === getMaxSale
                      ? '#FF8C00'
                      : element === getMedianSale
                      ? '#FFA500'
                      : element === getMinSale
                      ? '#FFD700'
                      : '#FFFFFF',
                  color:
                    element === 0
                      ? '#666666'
                      : element === getMaxSale
                      ? '#FFFFFF'
                      : element === getMedianSale
                      ? '#FFFFFF'
                      : element === getMinSale
                      ? '#FFFFFF'
                      : '#666666'
                }}
              >
                {element.toLocaleString()}
              </TableCell>
            ))}
          </TableRow>
          <TableRow key={columnList[5]}>
            <TableCell
              align="center"
              component="th"
              scope="row"
              sx={{ color: '#666666' }}
            >
              {columnList[5]}
            </TableCell>
            {point.map((element, index) => (
              <TableCell
                key={index}
                align="center"
                component="th"
                scope="row"
                onClick={() => handleClick('ポイント', index)}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    opacity: 0.8
                  },
                  backgroundColor:
                    element === 0
                      ? '#FFFFFF'
                      : element === getMaxPoint
                      ? '#FF8C00'
                      : element === getMedianPoint
                      ? '#FFA500'
                      : element === getMinPoint
                      ? '#FFD700'
                      : '#FFFFFF',
                  color:
                    element === 0
                      ? '#666666'
                      : element === getMaxPoint
                      ? '#FFFFFF'
                      : element === getMedianPoint
                      ? '#FFFFFF'
                      : element === getMinPoint
                      ? '#FFFFFF'
                      : '#666666'
                }}
              >
                {element.toLocaleString()}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OtherItemMeasureTableAmazon;
