import * as React from 'react';
import { makeStyles } from '@material-ui/styles';

import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: 4,
    right: 4,
    zIndex: 10
  }
}));

const StickyButton = () => {
  const classes = useStyles();
  const location = useLocation();
  const clickHandle = () => {
    let url = 'https://www.oxcim.net/manual';
    if (location.pathname === '/premium_register') {
      url = 'https://oxcim.net/help';
    }
    window.open(url, '_blank');
  };
  return (
    <Box sx={{ '& > :not(style)': { m: 1 } }}>
      <Fab
        variant="extended"
        color="primary"
        aria-label="add"
        className={classes.fab}
        onClick={clickHandle}
        sx={{ width: '132px' }}
      >
        <NavigationIcon sx={{ mr: 1 }} />
        ヘルプ
      </Fab>
    </Box>
  );
};

export default StickyButton;
