/* eslint-disable*/
import {
  Box,
  Button,
  Snackbar,
  InputBase,
  colors,
  CircularProgress,
  FormHelperText,
  Typography,
  InputLabel,
  Container,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userRegister, fetchUserList } from 'feactures/user/userSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { useState } from 'react';
import { userinfo } from 'feactures/user/userSlice';

import * as Yup from 'yup';

const AddNewUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [err, setErrMessage] = useState(false); //登録できなかったときのエラー
  const user = useSelector(userinfo);

  const snackbarHandleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setErrMessage(false);
  };

  const CustomInput = (props) => {
    const {
      value,
      item,
      onChange,
      name,
      touched,
      errors,
      onBlur,
      placeholder,
      type,
      caution
    } = props;
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'start',
          m: 1
        }}
      >
        <InputLabel htmlFor={name}>{item}*</InputLabel>
        <Typography variant="caption">{caution}</Typography>
        <Box flex="1 1 auto" maxWidth="100%" width={'100%'}>
          <InputBase
            sx={{
              pl: 2,
              backgroundColor: 'white',
              border: '1px solid',
              borderColor: colors.grey[300],
              borderRadius: '4px'
            }}
            fullWidth
            value={value}
            id={name}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            type={type}
          />
          {Boolean(touched[name]) && errors[name] ? (
            <FormHelperText error={true}>{errors[name]}</FormHelperText>
          ) : null}
        </Box>
      </Box>
    );
  };

  const onSubmit = async (values, resetForm) => {
    await dispatch(userRegister(values))
      .then(unwrapResult)
      .then(() => {
        dispatch(fetchUserList(user.accountId));
        setSuccess(true);
        window.scrollTo(0, 0);
        resetForm();
      })
      .catch((err) => {
        console.log(err);
        setErrMessage(true);
      });
  };

  const modoru = () => {
    navigate(-1);
  };

  const passwordExp =
    /^(?=.*?[a-z])(?=.*?\d)(?=.*?[!-\/:-@[-`{-~])[!-~]{8,100}$/i;

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          py: 3
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              lastname: '',
              firstname: '',
              email: '',
              password: '',
              confirmPassword: '',
              accountId: user.accountId,
              authority: '1'
            }}
            validationSchema={Yup.object().shape({
              lastname: Yup.string()
                .max(16, '16文字以内')
                .required('未入力です'),
              firstname: Yup.string()
                .max(16, '16文字以内')
                .required('未入力です'),
              email: Yup.string()
                .required('未入力です')
                .email('無効なメールアドレスです'),
              password: Yup.string()
                .required('未入力です')
                .max(100)
                .min(8, 'パスワードは最低8文字以上で入力してください')
                .matches(
                  passwordExp,
                  'パスワードは半角英数字記号を組み合わせて入力してください'
                ),
              confirmPassword: Yup.string()
                .max(100)
                .required('未入力です')
                .oneOf([Yup.ref('password')], '確認用パスワードが一致しません'),
              authority: Yup.string().required('未入力です')
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              resetForm
            }) => (
              <form onSubmit={handleSubmit}>
                <Typography
                  variant="h2"
                  sx={{ mb: 3, textAlign: 'start', mx: 'auto' }}
                >
                  ユーザー作成
                </Typography>
                <Typography
                  paragraph={true}
                  sx={{ textAlign: 'start', mx: 'auto' }}
                >
                  ※ご留意点※
                  <br />
                  ・ご不明な点がございましたら下記までご連絡ください。
                  <br />
                  　Mail：introduction_oxcim@mg.opt.ne.jp
                </Typography>
                <Box sx={{ my: 5 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',

                      m: 1
                    }}
                  >
                    <InputLabel htmlFor="lastname">名前*</InputLabel>
                    <Box flex="1 1 auto" maxWidth={'100%'}>
                      <InputBase
                        sx={{
                          pl: 2,
                          mr: '2%',
                          backgroundColor: 'white',
                          width: '49%',
                          border: '1px solid',
                          borderColor: colors.grey[300],
                          borderRadius: '4px'
                        }}
                        value={values.lastname}
                        id={'lastname'}
                        name={'lastname'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={'姓'}
                        type={'text'}
                      />
                      <InputBase
                        sx={{
                          pl: 2,
                          backgroundColor: 'white',
                          width: '49%',
                          border: '1px solid',
                          borderColor: colors.grey[300],
                          borderRadius: '4px'
                        }}
                        fullWidth
                        value={values.firstname}
                        id={'firstname'}
                        name={'firstname'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={'名'}
                        type={'text'}
                      />
                      {Boolean(touched['lastname']) && errors['lastname'] ? (
                        <FormHelperText error={true}>
                          {errors['lastname']}
                        </FormHelperText>
                      ) : null}
                      {Boolean(touched['firstname']) && errors['firstname'] ? (
                        <FormHelperText error={true}>
                          {errors['firstname']}
                        </FormHelperText>
                      ) : null}
                    </Box>
                  </Box>
                  <CustomInput
                    id={'email'}
                    name={'email'}
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    item={'会社メールアドレス'}
                    touched={touched}
                    errors={errors}
                    placeholder={'会社メールアドレス'}
                    type={'text'}
                  />
                  <CustomInput
                    id={'password'}
                    name={'password'}
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    item={'パスワード'}
                    touched={touched}
                    errors={errors}
                    placeholder={'パスワード'}
                    type={'password'}
                    caution={
                      '※半角英字、数字、記号を組み合わせた８文字以上で入力してください。'
                    }
                  />
                  <CustomInput
                    id={'confirmPassword'}
                    name={'confirmPassword'}
                    value={values.confirmPassword}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    item={'パスワード確認'}
                    touched={touched}
                    errors={errors}
                    placeholder={'確認用パスワード'}
                    type={'password'}
                    cautioin={''}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'start',
                      m: 1
                    }}
                  >
                    <InputLabel htmlFor="authority">ユーザー権限*</InputLabel>
                    <Box
                      sx={{
                        flex: '1 1 auto',
                        maxWidth: '100%'
                      }}
                    >
                      <RadioGroup
                        value={values.authority}
                        defaultValue={'1'}
                        name="authority"
                        id="authority"
                        row
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value={'1'}
                          label={'管理者'}
                          control={<Radio />}
                        />
                        <FormControlLabel
                          value={'0'}
                          label={'一般ユーザー'}
                          control={<Radio />}
                        />
                      </RadioGroup>
                      {Boolean(touched['authority']) && errors['authority'] ? (
                        <FormHelperText error={true}>
                          {errors['authority']}
                        </FormHelperText>
                      ) : null}
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ py: 4, display: 'flex', justifyContent: 'center' }}>
                  <Button
                    sx={{ mr: 2, height: '36px' }}
                    type="reset"
                    variant="outlined"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      resetForm();
                    }}
                  >
                    キャンセル
                  </Button>
                  <Button
                    sx={{ width: '150px', height: '36px' }}
                    color="primary"
                    size="midium"
                    disabled={isSubmitting || Object.keys(errors).length > 0}
                    type="button"
                    variant="contained"
                    onClick={() => {
                      const err = Object.keys(errors);
                      if (err.length) {
                        const input = document.querySelector(
                          `input[name=${err[0]}]`
                        );

                        input.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                          inline: 'start'
                        });
                      } else {
                        onSubmit(values, resetForm);
                      }
                    }}
                  >
                    {isSubmitting ? <CircularProgress size={22} /> : '新規作成'}
                  </Button>
                  <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={success || err}
                    severity={success ? 'success' : 'error'}
                    autoHideDuration={4000}
                    onClose={snackbarHandleClose}
                    message={
                      err
                        ? '新規ユーザーを作成できませんでした'
                        : '新規ユーザーを作成しました'
                    }
                  />
                  <br />
                </Box>
              </form>
            )}
          </Formik>
          <Button onClick={modoru} color="detailButton" sx={{ width: 'xs' }}>
            ← 戻る
          </Button>
        </Container>
      </Box>
    </>
  );
};

export default AddNewUser;
