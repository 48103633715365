/* eslint-disable*/

import { Container, Grid, Typography, Box } from '@material-ui/core'; // eslint-disable-line
import { useSelector } from 'react-redux';
import { currentchannel } from 'feactures/channel/channelSlice';
import {
  categorygraphShareStatus,
  selectAmazonCategoryShareGraph,
  selectRakutenCategoryShareGraph,
  selectYahooCategoryShareGraph
} from 'feactures/category/categoryShareSlice';
import CategorySharegraph from 'components/molecules/dashboard/CategorySharegraph';
import CategoryShareLabel from 'components/molecules/dashboard/CategoryShareLabel';

const CategoryShare = () => {
  // 実際のデータはuseEffectで取得しreduxへ
  const categoryShareAmazonGraphData = useSelector(
    selectAmazonCategoryShareGraph
  );
  const categoryShareRakutenGraphData = useSelector(
    selectRakutenCategoryShareGraph
  );
  const categoryShareYahooGraphData = useSelector(
    selectYahooCategoryShareGraph
  );
  const loading = useSelector(categorygraphShareStatus);
  const channel = useSelector(currentchannel);

  let categoryShareGraphData = {
    sales: [],
    percentage: [],
    label: [],
    cumulative_percentages: []
  };

  if (channel == 1) {
    categoryShareGraphData = categoryShareAmazonGraphData.graphdata;
  } else if (channel == 2) {
    categoryShareGraphData = categoryShareRakutenGraphData.graphdata;
  } else if (channel == 3) {
    categoryShareGraphData = categoryShareYahooGraphData.graphdata;
  }

  const sales = categoryShareGraphData.sales;
  const count = sales.filter((item) => item >= 1).length;
  const hasOneOrMore = count >= 1;

  return (
    <>
      <Container maxWidth={false}>
        <Grid container rowSpacing={0.3} columnSpacing={1.5} height={364.9}>
          <>
            <Grid itemxs={12} mb={1}>
              <CategoryShareLabel />
            </Grid>
            <Grid item xs={12}>
              {hasOneOrMore ? (
                <>
                  {/* 円グラフ */}
                  <CategorySharegraph graphdata={categoryShareGraphData} />
                </>
              ) : (
                <Box display="flex" justifyContent="center">
                  <Typography fontSize={15}>
                    最下層のためデータがありません
                  </Typography>
                </Box>
              )}
            </Grid>
          </>
        </Grid>
      </Container>
    </>
  );
};

export default CategoryShare;
