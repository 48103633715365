/* eslint-disable  */
import { Checkbox, FormGroup, FormControlLabel } from '@material-ui/core';
import {
  brandList,
  otherCheckedB,
  otherSelectedBrand,
  ownCheckedB,
  ownSelectedBrand,
  setOtherCheckedBrandList,
  setOwnCheckedBrandList
} from 'feactures/brand/brandSlice';
import { currentchannel } from 'feactures/channel/channelSlice';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const BrandSelectCheckbox = (props) => {
  const { section } = props;
  const dispatch = useDispatch();
  const channel = useSelector(currentchannel);
  const allBrandList = useSelector(brandList);
  const selectOwnBrand = useSelector(ownSelectedBrand);
  const checkedOtherBrand = useSelector(otherCheckedB);
  const selectOtherBrand = useSelector(otherSelectedBrand);
  const checkedOwnBrand = useSelector(ownCheckedB);
  let selectBrand = '';
  let checkedBrand = '';
  if (section == 'other') {
    selectBrand = selectOtherBrand;
    checkedBrand = checkedOtherBrand;
  } else {
    selectBrand = selectOwnBrand;
    checkedBrand = checkedOwnBrand;
  }
  let chBrandList = [];
  let current_channel_checked_brand = '';
  if (channel == 1) {
    chBrandList = allBrandList.amazon;
    current_channel_checked_brand = checkedBrand.amazon.brand;
  } else if (channel == 2) {
    chBrandList = allBrandList.rakuten;
    current_channel_checked_brand = checkedBrand.rakuten.brand;
  } else if (channel == 3) {
    chBrandList = allBrandList.yahoo;
    current_channel_checked_brand = checkedBrand.yahoo.brand;
  }

  const [checked, setChecked] = useState(
    chBrandList.map((item) => ({
      id: item.brand_id,
      brand: item.brand_name,
      isChecked: current_channel_checked_brand === item.brand_name
    }))
  );

  useEffect(() => {
    if (channel == 1) {
      chBrandList = allBrandList.amazon;
    } else if (channel == 2) {
      chBrandList = allBrandList.rakuten;
    } else if (channel == 3) {
      chBrandList = allBrandList.yahoo;
    }

    setChecked(
      chBrandList.map((item) => ({
        id: item.brand_id,
        brand: item.brand_name,
        isChecked: current_channel_checked_brand === item.brand_name
      }))
    );
  }, [allBrandList]);

  useEffect(() => {
    setChecked(
      chBrandList.map((item) => ({
        id: item.brand_id,
        brand: item.brand_name,
        isChecked: current_channel_checked_brand === item.brand_name
      }))
    );
  }, [selectOwnBrand, checkedOwnBrand, selectOtherBrand, checkedOtherBrand]);

  const handleChange = (e, brand, id) => {
    if (e.target.checked) {
      if (section == 'own') {
        dispatch(
          setOwnCheckedBrandList({ channel, list: { brand: brand, id: id } })
        );
      } else {
        dispatch(
          setOtherCheckedBrandList({ channel, list: { brand: brand, id: id } })
        );
      }
    } else {
      if (section == 'own') {
        dispatch(setOwnCheckedBrandList({ channel, list: '' }));
      } else {
        dispatch(setOtherCheckedBrandList({ channel, list: '' }));
      }
    }
  };

  // チャンネルを変えて戻ってきてもチェックが入るようにする
  useEffect(() => {
    if (channel == 1) {
      checked.forEach(function (elem) {
        if (selectBrand.amazon.brand === elem.brand) {
          elem.isChecked = true;
        }
      });
    } else if (channel == 2) {
      checked.forEach(function (elem) {
        if (selectBrand.rakuten.brand === elem.brand) {
          elem.isChecked = true;
        }
      });
    } else if (channel == 3) {
      checked.forEach(function (elem) {
        if (selectBrand.yahoo.brand === elem.brand) {
          elem.isChecked = true;
        }
      });
    }
    setChecked(checked);
  }, [channel]);

  return (
    <div>
      <FormGroup>
        {checked.map((item, index) => {
          return (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={item.isChecked}
                  onClick={(e) => handleChange(e, item.brand, item.id)}
                  name={item.brand}
                />
              }
              label={item.brand}
            />
          );
        })}
      </FormGroup>
    </div>
  );
};

export default BrandSelectCheckbox;
