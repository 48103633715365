/* eslint-disable*/

import { Box, Typography, IconButton, Card } from '@material-ui/core'; // eslint-disable-line
import { ovGroupShareGraph } from 'feactures/overview/overviewSlice';
import GroupListTable from 'components/molecules/dashboard/GroupListTable';
import GroupSharegraph from 'components/molecules/dashboard/GroupSharegraph';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const OverviewGroupShare = () => {
  const graphdata = useSelector(ovGroupShareGraph);
  const [open, setOpen] = useState(false);
  const [graphDisplay, setGraphDisplay] = useState(false);

  useEffect(() => {
    const filteredGraphData = graphdata.graphdata.filter((data) => data !== 0);
    if (filteredGraphData.length > 0) {
      setGraphDisplay(true);
    }
  }, [graphdata]);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Box sx={{ width: '47%' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'space-between',
            px: 1
          }}
        >
          <Typography
            component="div"
            fontSize="1rem"
            sx={{
              color: '#595959',
              fontWeight: 'bold',
              fontSize: '14px'
            }}
          >
            グループ売上シェア
          </Typography>
          <IconButton
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              p: 0
            }}
            onClick={handleOpen}
          >
            <SettingsOutlinedIcon />
            <Typography fontSize={'10px'}>設定</Typography>
          </IconButton>
        </Box>
        {graphDisplay ? (
          <GroupSharegraph graphdata={graphdata} />
        ) : (
          <Card
            sx={{
              minHeight: '250px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography textAlign={'center'}>
              登録済みグループの売上が0のため、グラフを表示できません。
            </Typography>
          </Card>
        )}
      </Box>
      <GroupListTable open={open} setOpen={setOpen} />
    </>
  );
};

export default OverviewGroupShare;
