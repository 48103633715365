/* eslint-disable*/
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  CircularProgress,
  Button,
  Backdrop
} from '@material-ui/core';
import {
  rankingProductData,
  changeRankingProductPage,
  rankingProductPageCount,
  rankingProductLength,
  rankingProductIsLoading,
  rankingProductCategory,
  productIsLoading
} from 'feactures/measure/newMeasureSlice';
import RankingProductTableRow from 'components/molecules/dashboard/RankingProductTableRow';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useSelector } from 'react-redux';
import { currentchannel } from 'feactures/channel/channelSlice';

const HeaderCell = styled(TableCell)(({ theme }) => ({
  fontSize: '12px',
  color: '#ffffff',
  textAlign: 'center',
  padding: '8px 2px',
  wordBreak: 'keep-all'
}));

const Overlay = styled(Backdrop)(({ theme }) => ({
  opacity: '0.4 !important',
  color: '#ffffff',
  zIndex: 5
}));

export default function ProductRankingTable({ handleClose }) {
  const dispatch = useDispatch();
  const data = useSelector(rankingProductData);
  const isLoading = useSelector(rankingProductIsLoading);
  const registerLoading = useSelector(productIsLoading);
  const pageCount = useSelector(rankingProductPageCount);
  const length = useSelector(rankingProductLength);
  const channel = useSelector(currentchannel);
  const category = useSelector(rankingProductCategory);

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            width: '100%',
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {data.length === 0 &&
          (category.length == 0 || category[0].categoryId == '') ? (
            <Typography
              sx={{
                textAlign: 'center',
                color: 'red',
                fontSize: '20px',
                my: 4
              }}
            >
              第三階層のカテゴリを選択してください
            </Typography>
          ) : (
            <TableContainer component={Paper} sx={{ borderRadius: '10px' }}>
              <Table
                sx={{
                  tableLayout: 'fixed',
                  overflow: 'auto',
                  width: 'auto',
                  minWidth: '100%'
                }}
              >
                {/* width: autoをつけると、詳細テーブルを開いた時にテーブル幅が広がってしまう */}
                <TableHead
                  sx={{ backgroundColor: '#728DC4', height: '60px' }}
                  key={'measureAnalysisTableHead'}
                >
                  <TableRow>
                    <HeaderCell>商品画像</HeaderCell>
                    <HeaderCell width={'30%'}>商品名</HeaderCell>
                    <HeaderCell>カテゴリ</HeaderCell>
                    <HeaderCell>価格</HeaderCell>
                    <HeaderCell>ランキング</HeaderCell>
                    {channel == 2 && (
                      <>
                        <HeaderCell sx={{ textAlign: 'center' }} colSpan={2}>
                          レビュー
                        </HeaderCell>
                      </>
                    )}
                    <HeaderCell></HeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.length === 0 ? (
                    <TableCell colSpan={8} sx={{ textAlign: 'center' }}>
                      検索条件に当てはまる商品はありません
                    </TableCell>
                  ) : (
                    data.map((item, index) => (
                      <RankingProductTableRow
                        item={item}
                        handleClose={handleClose}
                      />
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {/* ページネーション */}
          {!isLoading && length > 0 ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              sx={{ pt: 1, pb: 5 }}
            >
              <Typography>
                {(pageCount - 1) * 10 + 1} ~{' '}
                {(pageCount - 1) * 10 + 10 > length
                  ? length
                  : (pageCount - 1) * 10 + 10}{' '}
                / {length}
                件の結果
              </Typography>
              <Button
                color="inherit"
                variant="contained"
                disabled={pageCount <= 1}
                onClick={() => {
                  dispatch(changeRankingProductPage(-1));
                }}
                sx={{
                  height: '20px',
                  borderRadius: 160,
                  minWidth: '30px',
                  p: 0,
                  ml: 1
                }}
              >
                <ArrowBackIosNewIcon sx={{ height: '13px', width: '13px' }} />
              </Button>
              <Button
                color="inherit"
                variant="contained"
                disabled={pageCount >= length / 10}
                onClick={() => {
                  dispatch(changeRankingProductPage(1));
                }}
                sx={{
                  height: '20px',
                  borderRadius: 160,
                  minWidth: '30px',
                  p: 0,
                  ml: 1
                }}
              >
                <ArrowForwardIosIcon sx={{ height: '13px', width: '13px' }} />
              </Button>
            </Box>
          ) : (
            <></>
          )}
          <Overlay open={registerLoading}>
            <CircularProgress sx={{ color: 'inherit' }} />
          </Overlay>
        </>
      )}
    </>
  );
}
