/* eslint-disable*/
import {
  Card,
  colors,
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Button,
  Snackbar,
  List,
  ListItem,
  InputAdornment
} from '@material-ui/core'; /* eslint-disable*/
import * as Yup from 'yup';
import { Formik } from 'formik';
import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { errorResMessage, resetError } from 'feactures/errorSlice';
import { currentchannel } from 'feactures/channel/channelSlice';
import { userinfo } from 'feactures/user/userSlice';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import {
  resetDisplayedASIN,
  fetchEditMonitorAsinList,
  canceled_asin,
  registered_asin,
  existing_asin,
  asin_error,
  monitor_asinStatus,
  fetchAsinList,
  fetchAsyncMonitorAsin
} from 'feactures/monitoring/monitorAsinSlice';
import SearchIcon from '@mui/icons-material/Search';
import { currentPage } from 'feactures/date/dateSlice';
import AsinComfirmDialog from 'components/atoms/modal/AsinComfirmDialog';
import { fetchSearchAsin } from 'feactures/monitoring/searchAsinSlice';
import { apiUrl } from 'App';
import axios from 'axios';

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  // font-family: IBM Plex Sans, sans-serif;
  // font-size: 0.875rem;
`;

const EditAsinList = () => {
  // console.log('editGroup render');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [successState, setSuccessState] = useState(false);
  // const [err, setErr] = useState(''); //登録できなかったとき
  const [dialogOpen, setDialogOpen] = useState(false); //グループエラー
  const groupErrorStatus = useSelector(asin_error); //グループエラー
  const errorMessage = useSelector(errorResMessage); //アプリ全体error
  const channel = useSelector(currentchannel);
  const user = useSelector(userinfo);
  const loading = useSelector(monitor_asinStatus);
  const [fixFlag, setFixFlag] = useState(false); //新規登録か変更か
  const page = useSelector(currentPage);
  const canceledASIN = useSelector(canceled_asin);
  const registeredASIN = useSelector(registered_asin);
  const existingASIN = useSelector(existing_asin);
  // 検索機能関連
  const [searchText, setSearchText] = useState('');
  const [products, setProducts] = useState([]); //検索してヒットした商品一覧（変数名変える）
  const [ownProductsValue, setOwnProductsValue] = useState([]); //自社：登録済み商品 + 検索から追加した商品 + 入力した商品？
  const [searchLoading, setSearchLoading] = useState(false);
  const [serchErrorMsg, setSerchErrorMsg] = useState('');
  const [inputFlag, setInputFlag] = useState(true); //登録方法が検索かリスト入力か

  const myCallback = (run) => {
    return run();
  };

  //作成前確認画面
  const comfirmHandle = () => {
    setDialogOpen(true);
  };

  //作成
  const onSubmit = async (values) => {
    await dispatch(fetchEditMonitorAsinList(values))
      .then(unwrapResult)
      .then(() => {
        setSuccess(true);
        setSuccessState(true);
      })
      .catch((error) => {
        console.log(error);
      });
    window.scrollTo(0, 0);
    setDialogOpen(false);
  };

  const snackbarHandleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
  };

  const editHandle = async (values) => {
    // console.log('hensyuボタン');
    await dispatch(fetchEditMonitorAsinList(values))
      .then(unwrapResult)
      .then(() => {
        setSuccess(true);
        setSuccessState(true);
        window.scrollTo(0, 0);
      })
      .catch((err) => {
        console.log(err);
      });
    setDialogOpen(false);
  };

  // 検索ボタンを押したときの処理
  const handleSearch = async () => {
    setProducts([]);
    setSerchErrorMsg('');
    setSearchLoading(true);
    const res = await axios.post(`${apiUrl}searchAsin`, {
      asin: searchText,
      account_id: user.accountId,
      channel: channel
    });
    const data = await res.data;
    if (data.result.length === 0) {
      setSerchErrorMsg('商品が見つかりませんでした');
    } else {
      setProducts(data.result);
    }
    setSearchLoading(false);
  };

  // 商品検索欄に文字を入力したときの処理（テスト用）
  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  };

  // 検索した商品をリストに追加する
  const onAddProduct = (p) => {
    setOwnProductsValue([...ownProductsValue, p]);
  };
  // ↑商品検索↑

  const handleChangeMethod = () => {
    // console.log('入力方法が切り替わりました');
    setSerchErrorMsg('');
    setInputFlag(!inputFlag);
  };

  const maxLength = 25; // 商品名の最大文字数
  const santenLeader = '...';

  useEffect(() => {
    // console.log('ここでリセットする');
    setSuccessState(false);
    dispatch(resetDisplayedASIN());
    setOwnProductsValue(existingASIN);
  }, [page]);

  // コンポーネント分け
  const InputTab = () => {
    return (
      <Box
        display="flex"
        // justifyContent="space-around"
        height="32px"
        margin="32px 0px 32px 0px"
        width="250px"
      >
        <Button
          style={{
            width: '125px',
            borderRadius: '4px 0px 0px 4px'
            // backgroundColor: '#515152'
          }}
          variant={inputFlag ? 'contained' : 'outlined'}
          color="primary"
          onClick={handleChangeMethod}
        >
          商品検索
        </Button>
        <Button
          style={{
            width: '125px',
            borderRadius: '0px 4px 4px 0px'
            // backgroundColor: '#515152'
          }}
          variant={!inputFlag ? 'contained' : 'outlined'}
          color="primary"
          onClick={handleChangeMethod}
        >
          リスト入力
        </Button>
      </Box>
    );
  };

  const ResisterAsinPop = (props) => {
    const rState = props.resisteredState;
    const cState = props.canceledState;
    return (
      <>
        {successState && (
          <Box width="600px" margin="15px 0px 30px 0px" marginBottom="40px">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around'
              }}
            >
              <Grid
                sx={{
                  backgroundColor: '#dce3de',
                  width: '40%',
                  textAlign: 'center',
                  paddingTop: '5px'
                }}
              >
                <Typography
                  borderBottom="solid 1px"
                  display="inline-block"
                  fontWeight="bold"
                >
                  {channel == 1 ? '未登録ASIN' : '未登録商品管理番号'}
                </Typography>
                <List>
                  {/* 競合の場合はなし */}
                  {cState.map((cASIN, index) => {
                    return <ListItem key={index}>{cASIN}</ListItem>;
                  })}
                </List>
              </Grid>
              <Grid
                sx={{
                  backgroundColor: '#dce3de',
                  width: '40%',
                  textAlign: 'center',
                  paddingTop: '5px'
                }}
              >
                <Typography
                  borderBottom="solid 1px"
                  display="inline-block"
                  fontWeight="bold"
                >
                  {channel == 1 ? '登録済みASIN' : '登録済み商品管理番号'}
                </Typography>
                <List>
                  {rState.map((compProValue, index) => {
                    return <ListItem key={index}>{compProValue}</ListItem>;
                  })}
                </List>
              </Grid>
            </Box>
            {cState.length > 0 && (
              <Typography color="red" marginLeft="30px">
                ※登録できなかった商品情報が存在します
              </Typography>
            )}
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      <Box
        sx={{
          height: 'auto',
          width: '100%',
          pt: '2px'
        }}
      >
        <Container sx={{ width: 'auto', p: 0 }}>
          <Typography
            fontSize="16px"
            fontWeight="bold"
            sx={{ ml: 2, p: '4px' }}
          >
            KPIサマリ【指定商品登録】
          </Typography>
          {/* <Buttongroup amazonOnly={true} /> */}
          <>
            <button
              style={{ textDecoration: 'none', marginTop: '30px' }}
              onClick={() => navigate(-1)}
            >
              ←戻る
            </button>

            <Formik
              initialValues={{
                asinList: ownProductsValue
                // asinList: selectId === '' ? [] : [...addProduct],
              }}
              validationSchema={Yup.object().shape({
                asinList: Yup.array()
                  .of(Yup.string().required('未入力です'))
                  .min(1, '未入力です')
                  .max(200, '200商品以上入力されています。')
              })}
              enableReinitialize={true} //initialValue を動的に変えられる
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                touched,
                values,
                resetForm,
                isValid
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box>
                    <Container mt={7} maxWidth="md">
                      {/* <Typography variant="h4">グループ登録</Typography> */}
                      <Box
                        sx={{
                          mt: 6,
                          width: '100%',
                          typography: 'body1'
                        }}
                      >
                        <Typography color="red">
                          ※最大200商品登録可能です。
                        </Typography>
                        {channel == 1 && (
                          <Typography color="red">
                            ※親ASINではなく、子ASINで登録をお願いします。
                          </Typography>
                        )}
                        <Typography color="red">
                          ※自社に登録されていない商品は登録することができません。
                        </Typography>
                        <Typography color="red">
                          ※売上がない商品は登録することができません。
                        </Typography>
                        <TabsUnstyled defaultValue={0}>
                          <Card
                            sx={{
                              backgroundColor: colors.indigo[50],
                              minHeight: '500px',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center'
                            }}
                          >
                            <TabPanel value={0}>
                              <Grid
                                container
                                direction="column"
                                alignItems="center"
                                p={3}
                              >
                                <Box
                                  maxWidth="sm"
                                  width="600px"
                                  display="flex"
                                  flexDirection="column"
                                >
                                  <InputTab />
                                  {/* 検索機能は取り急ぎここに直書き */}
                                  {/* <SearchArea flag={0} /> */}
                                  {inputFlag && (
                                    <Box
                                      sx={{
                                        '& .MuiTextField-root': {
                                          backgroundColor: '#ffffff',
                                          borderRadius: '4px',
                                          mb: 4
                                        }
                                      }}
                                      noValidate
                                      autoComplete="off"
                                    >
                                      <Box>
                                        <TextField
                                          label="商品を検索"
                                          variant="outlined"
                                          size="small"
                                          value={searchText}
                                          InputProps={{
                                            style: {
                                              width: 'calc(100% + 40px)'
                                            },
                                            endAdornment: (
                                              <InputAdornment
                                                position="end"
                                                style={{
                                                  position: 'absolute',
                                                  right: 0
                                                }}
                                              >
                                                <Button
                                                  sx={{
                                                    height: '40px',
                                                    borderRadius:
                                                      '0px 4px 4px 0px',
                                                    backgroundColor: '#515152',
                                                    // 効いてないかも
                                                    padding: '0',
                                                    minWidth: '50px'
                                                  }}
                                                  variant="contained"
                                                  color="primary"
                                                  onClick={handleSearch}
                                                >
                                                  <SearchIcon />
                                                </Button>
                                              </InputAdornment>
                                            )
                                          }}
                                          onChange={handleInputChange}
                                          style={{ maxWidth: '100%' }}
                                        />
                                      </Box>
                                      <Box sx={{ marginBottom: '30px' }}>
                                        <Typography>{serchErrorMsg}</Typography>
                                        {searchLoading && (
                                          <CircularProgress size={15} />
                                        )}
                                      </Box>
                                      {products.length > 0 && (
                                        <Box
                                          display="flex"
                                          bgcolor="#ffffff"
                                          p={2}
                                          borderRadius={3}
                                          mb={3}
                                        >
                                          <Grid container>
                                            {products[0].image_url !== '' && (
                                              <Grid
                                                item
                                                xs={3}
                                                display="flex"
                                                alignItems="center"
                                              >
                                                <img
                                                  src={products[0].image_url}
                                                  alt="商品画像"
                                                  style={{
                                                    width: '100%'
                                                  }}
                                                />
                                              </Grid>
                                            )}
                                            <Grid
                                              item
                                              xs={
                                                products[0].image_url !== ''
                                                  ? 9
                                                  : 12
                                              }
                                              paddingLeft="20px"
                                              display="flex"
                                              flexDirection="column"
                                              justifyContent="center"
                                            >
                                              {products[0].title != 0 && (
                                                <Typography
                                                  color="#2586A6"
                                                  fontSize="14px"
                                                >
                                                  {products[0].title}
                                                </Typography>
                                              )}
                                              {products[0].price > 0 && (
                                                <Typography
                                                  color="#CF2F2F"
                                                  fontSize="12px"
                                                >
                                                  ￥{' '}
                                                  {Math.floor(
                                                    products[0].price
                                                  ).toLocaleString()}
                                                </Typography>
                                              )}
                                              <Box
                                                display="flex"
                                                sx={{
                                                  justifyContent:
                                                    'space-between'
                                                }}
                                              >
                                                {channel == 1 ? (
                                                  <Typography
                                                    color={
                                                      products[0].title != 0 &&
                                                      products[0].price > 0
                                                        ? '#999999'
                                                        : '#2586A6'
                                                    }
                                                    fontSize={
                                                      products[0].title != 0 &&
                                                      products[0].price > 0
                                                        ? '12px'
                                                        : '16px'
                                                    }
                                                  >
                                                    ASIN:{products[0].asin}
                                                  </Typography>
                                                ) : (
                                                  <>
                                                    <Typography
                                                      color={
                                                        products[0].title !=
                                                          0 &&
                                                        products[0].price > 0
                                                          ? '#999999'
                                                          : '#2586A6'
                                                      }
                                                      fontSize={
                                                        products[0].title !=
                                                          0 &&
                                                        products[0].price > 0
                                                          ? '12px'
                                                          : '16px'
                                                      }
                                                    >
                                                      商品番号：
                                                      {products[0].asin}
                                                    </Typography>
                                                  </>
                                                )}
                                                <Button
                                                  size="small"
                                                  type="button"
                                                  variant="contained"
                                                  color="primary"
                                                  onClick={() =>
                                                    onAddProduct(
                                                      products[0].asin
                                                    )
                                                  }
                                                >
                                                  追加
                                                </Button>
                                              </Box>
                                            </Grid>
                                          </Grid>
                                        </Box>
                                      )}
                                    </Box>
                                  )}
                                  {/* 自社 - リストに入力して登録 */}
                                  {!inputFlag && (
                                    <>
                                      <Typography variant="h4">
                                        {channel == 1 && '★ASIN登録'}
                                        {channel == 2 && '★商品管理番号登録'}
                                      </Typography>
                                      <Box
                                        sx={{
                                          '& .MuiTextField-root': {
                                            backgroundColor: '#ffffff',
                                            borderRadius: '4px',
                                            mb: 4
                                          }
                                        }}
                                        noValidate
                                        autoComplete="off"
                                      >
                                        <TextField
                                          multiline
                                          fullWidth
                                          value={values.asinList}
                                          rows={4}
                                          id={'asinList'}
                                          name={'asinList'}
                                          onBlur={handleBlur}
                                          placeholder={
                                            'カンマ, 半角スペース,改行区切りで複数登録可能'
                                          }
                                          error={Boolean(
                                            touched.asinList && errors.asinList
                                          )}
                                          helperText={
                                            touched.asinList && errors.asinList
                                          }
                                          // onChange={handleChange}
                                          onChange={(e) => {
                                            const strData = e.target.value;
                                            const ary =
                                              strData.split(/[,\s\n]+/);
                                            setFieldValue('asinList', ary);
                                          }}
                                        />
                                      </Box>
                                    </>
                                  )}
                                  {/* 自社 - 検索して登録 */}
                                  {inputFlag && (
                                    <>
                                      <Typography variant="h4">
                                        {channel == 1 && '★ASIN登録'}
                                        {channel == 2 && '★商品管理番号登録'}
                                      </Typography>
                                      <Box
                                        sx={{
                                          '& .MuiTextField-root': {
                                            backgroundColor: '#ffffff',
                                            borderRadius: '4px',
                                            mb: 4
                                          }
                                        }}
                                        noValidate
                                        autoComplete="off"
                                      >
                                        <TextField
                                          multiline
                                          fullWidth
                                          value={
                                            values.asinList
                                            // ownProductsValue
                                          }
                                          rows={4}
                                          id={'asinList'}
                                          name={'asinList'}
                                          onBlur={handleBlur}
                                          placeholder="検索欄から選択して複数登録可能"
                                          error={Boolean(
                                            touched.asinList && errors.asinList
                                          )}
                                          helperText={
                                            touched.asinList && errors.asinList
                                          }
                                          onChange={(e) => {
                                            const strData = e.target.value;
                                            let ary = strData;
                                            // strDataが空の時は配列にしない
                                            if (strData.length !== 0) {
                                              ary = strData.split(/[,\s\n]+/);
                                            }
                                            // setFieldValue('asinList', ary);
                                            setOwnProductsValue(ary);
                                            // setFieldValue(
                                            //   'asinList',
                                            //   ownProductsValue
                                            // );
                                          }}
                                        />
                                      </Box>
                                    </>
                                  )}
                                  <ResisterAsinPop
                                    canceledState={canceledASIN}
                                    resisteredState={registeredASIN}
                                  />
                                </Box>
                              </Grid>
                            </TabPanel>
                            <Box
                              sx={{
                                py: 2,
                                display: 'flex',
                                justifyContent: 'center'
                              }}
                            >
                              {fixFlag ? (
                                <>
                                  {/* <Button
                                    sx={{ mr: 2, height: '36px' }}
                                    type="reset"
                                    variant="outlined"
                                    onClick={() => {
                                      window.scrollTo(0, 0);
                                      resetForm();
                                      // cancelHandle();
                                    }}
                                  >
                                    キャンセル
                                  </Button> */}
                                  <Button
                                    sx={{ width: '150px', height: '36px' }}
                                    color="primary"
                                    size="midium"
                                    type="button"
                                    variant="contained"
                                    onClick={comfirmHandle}
                                  >
                                    {loading ? (
                                      <CircularProgress size={15} />
                                    ) : (
                                      '変更'
                                    )}
                                  </Button>
                                </>
                              ) : (
                                <Box>
                                  <Box display="flex" justifyContent="center">
                                    {/* <Button
                                      sx={{ mr: 2, height: '36px' }}
                                      type="reset"
                                      variant="outlined"
                                      onClick={() => {
                                        window.scrollTo(0, 0);
                                        resetForm();
                                      }}
                                    >
                                      キャンセル
                                    </Button> */}
                                    <Button
                                      sx={{ width: '150px', height: '36px' }}
                                      disabled={
                                        !isValid || values.asinList.length == 0
                                      }
                                      color="primary"
                                      size="midium"
                                      type="button"
                                      variant="contained"
                                      // onClick={() => onSubmit(values)}
                                      onClick={() => {
                                        comfirmHandle(values.groupName);
                                      }}
                                    >
                                      {loading ? (
                                        <CircularProgress size={15} />
                                      ) : (
                                        '商品登録'
                                      )}
                                    </Button>
                                  </Box>
                                  <Box alignContent="center">
                                    {values.asinList.length == 0 && (
                                      <Typography color="red" fontSize="small">
                                        商品管理番号を入力してください
                                      </Typography>
                                    )}
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          </Card>
                        </TabsUnstyled>
                      </Box>
                    </Container>
                  </Box>
                  {dialogOpen && (
                    <AsinComfirmDialog
                      open={dialogOpen}
                      fixFlag={fixFlag}
                      setOpen={setDialogOpen}
                      values={values}
                      onSubmit={() => {
                        onSubmit(values);
                      }}
                      editHandle={() => editHandle(values)}
                    />
                  )}
                </form>
              )}
            </Formik>
          </>
        </Container>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={success}
        severity={'success'}
        autoHideDuration={3000}
        onClose={() => {
          window.scrollTo(0, 0);
          snackbarHandleClose();
        }}
        message={
          canceledASIN.length > 0
            ? channel == 1
              ? '登録できないASINが入力されています'
              : '登録できない商品番号が入力されています'
            : '登録商品を更新しました'
        }
      />
    </>
  );
};

export default EditAsinList;
