/* eslint-disable*/
import { ComposedChart, Bar, Line } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  useTheme,
  Typography,
  Button,
  IconButton,
  colors
  // DeleteIcon
} from '@material-ui/core';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Settings, HelpOutlined } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import 'chartjs-adapter-moment';
import { useNavigate } from 'react-router-dom';
import { termCompare } from 'feactures/monitoring/monitoringSlice';
import { currentmonth, comparemonth } from 'feactures/section/sectionSlice';
import { currentAsin } from 'feactures/monitoring/monitoringSlice';
import { currentchannel } from 'feactures/channel/channelSlice';
import GroupSelectPulldown from 'components/atoms/button/GroupSelectPullDown';
import AsinSelectPulldown from 'components/atoms/button/AsinSelectPullDown';
import { groupList, groups } from 'feactures/monitoring/groupSlice';
import { termArray, termArray2 } from 'feactures/date/dateSlice';

//「？」マークにカーソルが当たったときの吹き出し
export const HtmlTooltip = styled(({ className, placement, ...props }) => (
  <Tooltip
    {...props}
    arrow
    placement={placement}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.paper
    // border: '1px solid #dadde9'
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    maxWidth: 280
  }
}));

const KpiSummaryMonitorGraph = (props) => {
  const {
    title,
    titleColor,
    graphColor,
    hvGraphColor,
    compareGraphData, //期間2のグラフ
    graphdata,
    graphtype,
    setopen,
    compareNum,
    num,
    rate,
    tanni,
    info,
    disabled,
    page
  } = props; // eslint-disable-line
  const theme = useTheme();
  const dispatch = useDispatch();
  const termCompareStatus = useSelector(termCompare);
  const grouopList = useSelector(groups);
  const selectMonthDate = useSelector(currentmonth);
  const compareMonthDate = useSelector(comparemonth);
  const channel = useSelector(currentchannel);
  const asin = useSelector(currentAsin);
  // const [page, setPage] = useState('');
  const location = useLocation();
  const term = useSelector(termArray);
  const term2 = useSelector(termArray2);

  const windowWidith = window.innerWidth;
  const [pageWidth, setPageWidth] = useState(windowWidith - 300);

  // // グループ別KPIサマリまたは商品別KPIサマリページの場合
  // useEffect(() => {
  //   if (location.pathname === '/app/monitoring/asin') {
  //     setPage('monitoringAsin');
  //   } else if (location.pathname === '/app/monitoring/group') {
  //     setPage('monitoringGroup');
  //   } else if (location.pathname === '/app/monitoring') {
  //     setPage('monitoring');
  //   }
  // }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth - 300);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let graphRate = '-';
  if (termCompareStatus) {
    if (compareNum !== 0) {
      graphRate = Math.round((num / compareNum) * 1000 - 1000) / 10;
    }
  } else {
    graphRate = rate;
  }

  const options = {
    interaction: {
      mode: 'nearest',
      intersect: false
    },
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false
        },
        title: {
          display: false
        },
        ticks: {
          font: {
            size: '10px'
          }
        }
      },
      y: {
        reverse: tanni === '位',
        ticks: {
          fontColor: theme.palette.text.secondary,
          font: {
            family: "'M PLUS 1p', sans-serif"
          },
          callback: function (value, index, values) {
            let returnValue = value.toLocaleString();
            if (title !== 'CVR') {
              if (value >= 100000000) {
                const num = Math.round(value / 100000000);
                if (String(num).length === 2) {
                  returnValue = num.toLocaleString() + '億';
                } else if (String(num).length === 1) {
                  returnValue = num.toLocaleString() + '億';
                } else {
                  returnValue = num.toLocaleString() + '億';
                }
              } else if (value >= 10000000) {
                returnValue =
                  Math.round(value / 10000000).toLocaleString() + '千万';
              } else if (value >= 10000) {
                const num = Math.round(value / 10000);
                if (String(num).length == 2) {
                  returnValue = num.toLocaleString() + '万';
                } else if (String(num).length == 1) {
                  returnValue = num.toLocaleString() + '万';
                } else {
                  returnValue = num.toLocaleString() + '万';
                }
              } else if (value >= 1000) {
                returnValue = (value / 1000).toLocaleString() + '千';
              } else {
                if (String(value).length === 3) {
                  returnValue = value.toLocaleString();
                } else if (String(value).length === 2) {
                  returnValue = +value.toLocaleString();
                } else {
                  returnValue = +value.toLocaleString();
                }
              }
            } else {
              // ここからCVRのグラフの時
              const num = Math.round(value * 100) / 100;
              if (String(num).length >= 3) {
                returnValue = num.toLocaleString();
              } else if (String(num).length === 2) {
                returnValue = num.toLocaleString();
              } else if (String(num).length === 1) {
                returnValue = num.toLocaleString();
              }
            }
            return returnValue;
          }
        },
        afterFit: (scaleInstance) => {
          scaleInstance.width = 50; // 設定したい幅
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider
        }
      }
    },
    plugins: {
      tooltip: {
        titleFont: {
          family: "'M PLUS 1p', sans-serif"
        },
        bodyFont: {
          family: "'M PLUS 1p', sans-serif"
        },
        callbacks: {
          label: function (context) {
            const dataset = context.datasetIndex;
            let labelArray = [];
            if (dataset === 0) {
              labelArray = term;
            } else if (dataset === 1) {
              labelArray = term2;
            }
            let label = labelArray[context.parsed.x];
            let data = context.raw;
            label += ' : ' + data.toLocaleString() + tanni;
            return label;
          }
        }
      },
      legend: {
        display: termCompareStatus,
        labels: {
          boxWidth: 6,
          boxHeight: 6,
          usePointStyle: true,
          pointStyle: 'circle'
        },
        position: 'left'
      },
      datalabels: {
        display: false
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: true,
    cornerRadius: 20,
    layout: {
      maxwidth: '100px',
      padding: {
        left: 0,
        right: 20,
        top: 0,
        bottom: 0
      }
    }
  };
  const data = termCompareStatus // 期間比較ボタンが押された場合
    ? {
        datasets: [
          {
            label: `期間1:${selectMonthDate}`,
            barThickness: 'flex',
            radius: 0,
            backgroundColor: graphColor,
            hoverBackgroundColor: hvGraphColor,
            borderColor: graphColor,
            data: graphdata,
            titleColor,
            pointRadius: 4,
            pointStyle: 'circle',
            pointBorderColor: graphColor,
            pointBackgroundColor: graphColor,
            pointBorderWidth: 1
          },
          {
            label: `期間2:${compareMonthDate}`,
            radius: 0,
            barThickness: 'flex',
            backgroundColor: 'rgba(255, 191, 35, 0.5)',
            hoverBackgroundColor: 'rgba(206, 153, 21,0.7)',
            borderColor: 'rgba(255, 191, 35, 0.5)',
            data: compareGraphData,
            titleColor,
            pointRadius: 4,
            pointStyle: 'circle',
            pointBorderColor: 'rgba(255, 191, 35, 0.5)',
            pointBackgroundColor: 'rgba(255, 191, 35, 0.5)',
            pointBorderWidth: 1
          }
        ],
        labels:
          term.length >= term2.length
            ? term.map((item) => '')
            : term2.map((item) => '')
      }
    : {
        datasets: [
          {
            label: title,
            radius: 0,
            barThickness: 'flex',
            backgroundColor: graphColor,
            hoverBackgroundColor: hvGraphColor,
            borderColor: graphColor,
            data: graphdata,
            maxBarThickness: 20,
            pointRadius: 4,
            pointStyle: 'circle',
            pointBorderColor: graphColor,
            pointBackgroundColor: graphColor,
            pointBorderWidth: 1
          }
        ],
        labels: term
      };

  const cvrData = termCompareStatus // 期間比較ボタンが押された場合
    ? {
        datasets: [
          {
            label: `期間1:${selectMonthDate}`,
            barThickness: 'flex',
            radius: 0,
            backgroundColor: graphColor,
            hoverBackgroundColor: hvGraphColor,
            borderColor: graphColor,
            data: graphdata,
            titleColor,
            pointRadius: 4,
            pointStyle: 'circle',
            pointBorderColor: graphColor,
            pointBackgroundColor: graphColor,
            pointBorderWidth: 1
          },
          {
            label: `期間2:${compareMonthDate}`,
            radius: 0,
            barThickness: 'flex',
            backgroundColor: 'rgba(255, 191, 35, 0.5)',
            hoverBackgroundColor: 'rgba(206, 153, 21,0.7)',
            borderColor: 'rgba(255, 191, 35, 0.5)',
            data: compareGraphData,
            titleColor,
            pointRadius: 4,
            pointStyle: 'circle',
            pointBorderColor: 'rgba(255, 191, 35, 0.5)',
            pointBackgroundColor: 'rgba(255, 191, 35, 0.5)',
            pointBorderWidth: 1
          },
          {
            data: [],
            type: 'bar',
            label: '',
            backgroundColor: '#fff'
          }
        ],
        labels:
          term.length >= term2.length
            ? term.map((item) => '')
            : term2.map((item) => '')
      }
    : {
        datasets: [
          {
            label: title,
            radius: 0,
            barThickness: 'flex',
            backgroundColor: graphColor,
            hoverBackgroundColor: hvGraphColor,
            borderColor: graphColor,
            data: graphdata,
            maxBarThickness: 20,
            pointRadius: 4,
            pointStyle: 'circle',
            pointBorderColor: graphColor,
            pointBackgroundColor: graphColor,
            pointBorderWidth: 1
          },
          {
            data: [],
            type: 'bar',
            label: '',
            backgroundColor: '#fff'
          }
        ],
        labels: term
      };

  return (
    <>
      <Card
        sx={{
          borderRadius: 2,
          py: '12px',
          width: '100%',
          height: '120px',
          boxShadow: '2px 2px 5px -3px #777777'
        }}
      >
        <CardContent
          sx={{
            p: '4px',
            '&:last-child': {
              paddingBottom: '0px'
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flex: 'auto 0 0',
              overflowX: 'auto'
            }}
          >
            {/* ここからテキスト部分 */}
            <Box
              sx={{
                width:
                  title === '全体売上' || title === '売上'
                    ? `${pageWidth * 0.26}px`
                    : `${pageWidth * 0.19}px`,
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 'auto 0 0',
                  minWidth: '150px',
                  m: '2px 0px'
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    px: 2
                  }}
                >
                  <Typography
                    component="div"
                    fontSize="14px"
                    sx={{ color: titleColor, fontWeight: 'bold', mr: 3 }}
                  >
                    {page === 'monitoringAsin' && title === '全体売上'
                      ? '売上'
                      : title}
                  </Typography>
                  {title === '全体売上' &&
                  (page === 'monitoring' || page === 'monitoringGroup') &&
                  groupList.length > 0 ? (
                    <GroupSelectPulldown pathName={page} />
                  ) : (
                    <></>
                  )}
                  {title === '全体売上' && page === 'monitoringAsin' ? (
                    <AsinSelectPulldown />
                  ) : (
                    <></>
                  )}
                </Box>
                {title === '全体売上' && page === 'monitoringAsin' ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'start',
                      alignItems: 'center',
                      width: `${pageWidth * 0.2}px`
                    }}
                  >
                    <Typography
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: '12px',
                        width: '100%'
                      }}
                    >
                      商品名：{asin.name}
                    </Typography>
                  </Box>
                ) : (
                  <></>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    boxsizing: 'border-box',
                    mt:
                      title === '全体売上' && page === 'monitoringAsin'
                        ? '0px'
                        : '4px',
                    mb:
                      title === '全体売上' && page === 'monitoringAsin'
                        ? '0px'
                        : '4px'
                  }}
                >
                  {tanni === '円' ? (
                    <Typography
                      variant="h3"
                      fontSize="15px"
                      fontWeight="600"
                      color="text.primary"
                      component="div"
                    >
                      &yen; {num.toLocaleString()}
                    </Typography>
                  ) : (
                    <></>
                  )}
                  {tanni === '%' ? (
                    <Typography
                      variant="h3"
                      fontSize="16px"
                      fontWeight="600"
                      color="text.primary"
                      component="div"
                    >
                      {num.toLocaleString()}%
                    </Typography>
                  ) : (
                    <></>
                  )}
                  {tanni !== '円' && tanni !== '%' ? (
                    <Typography
                      variant="h3"
                      fontSize="16px"
                      fontWeight="600"
                      color="text.primary"
                      component="div"
                    >
                      {num.toLocaleString()}
                    </Typography>
                  ) : (
                    <></>
                  )}
                  <HtmlTooltip
                    placement="top"
                    title={
                      <span style={{ whiteSpace: 'pre-line' }}>{info}</span>
                    }
                  >
                    <span>
                      <IconButton>
                        <HelpOutlined style={{ fontSize: '15px', mr: 3 }} />
                      </IconButton>
                    </span>
                  </HtmlTooltip>
                </Box>
                <Box
                  ml={2}
                  textAlign="center"
                  alignItems="baseline"
                  fontSize="10px"
                  display="flex"
                  justifyContent="center"
                >
                  <>
                    {graphRate === 0 ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* <TrendingFlatIcon sx={{ color: '#595959' }} /> */}
                        <Typography
                          color="#595959"
                          component="div"
                          sx={{ fontWeight: 'bold', fontSize: '12px' }}
                        >
                          ±{graphRate}%
                        </Typography>
                      </Box>
                    ) : (
                      <></>
                    )}
                    {graphRate < 0 ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* <TrendingDownIcon sx={{ color: '#FF1C17' }} /> */}
                        <Typography
                          color="#FF1C17"
                          component="div"
                          sx={{ fontWeight: 'bold', fontSize: '12px' }}
                        >
                          ▼ {graphRate}%
                        </Typography>
                      </Box>
                    ) : (
                      <></>
                    )}
                    {graphRate > 0 ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* <TrendingUpIcon sx={{ color: '#6AA84F' }} /> */}
                        <Typography
                          color="#6AA84F"
                          component="div"
                          sx={{ fontWeight: 'bold', fontSize: '12px' }}
                        >
                          ▲ +{graphRate}%
                        </Typography>
                      </Box>
                    ) : (
                      <></>
                    )}
                    {graphRate === '-' ? (
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          variant="h6"
                          color="#595959"
                          component="div"
                          sx={{ fontWeight: 'bold', fontSize: '12px' }}
                        >
                          - %
                        </Typography>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </>
                </Box>
              </Box>
            </Box>
            {/* ここからグラフ部分 */}
            <Box
              sx={{
                height: '98px',
                width: `${pageWidth * 0.72}px`,
                pb: '2px'
              }}
            >
              {graphtype === 'Bar' ? (
                <Bar
                  width="100%"
                  boxsizing="border-box"
                  data={data}
                  options={options}
                />
              ) : (
                ''
              )}
              {graphtype === 'Line' ? (
                <Line
                  width="100%"
                  boxsizing="border-box"
                  data={cvrData}
                  options={options}
                />
              ) : (
                ''
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default KpiSummaryMonitorGraph;
